import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { ToolTip } from 'components';

const BarChart = props => {
  const { segments, filled, title } = props;
  const colors = useColors();

  const segmentSum = segments.reduce(
    (total, segment) => segment.portion + total,
    0
  );

  const invalid = !almostEqual(segmentSum, 1, 1e-9);

  const height = 24;
  const overflow = filled && filled.portion > 1 ? filled.portion - 1 : null;

  return (
    <div style={{ position: 'relative', marginTop: 8 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {invalid && (
        <Typography
          variant="body2"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: colors.grey,
            height: height
          }}
        >
          Update the project numbers to view this chart.
        </Typography>
      )}

      <div style={{ display: invalid ? 'none' : 'flex' }}>
        {segments.map((segment, idx) => {
          const left = segments
            .slice(0, idx)
            .reduce((sum, seg) => seg.portion + sum, 0);
          return (
            <div
              key={segment.title}
              style={{ width: `${(segment.portion || 0) * 100}%` }}
            >
              {/* White Dividers */}
              <div
                key={segment.title}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: `calc(${left * 100}% - 2px)`,
                  height: height,
                  width: 2,
                  backgroundColor: 'white',
                  zIndex: 99
                }}
              />
              {/* Segment Title */}

              <ToolTip value={segment.title}>
                {segment.alignRight ? (
                  <div
                    style={{
                      height: 25,
                      overflowX: 'visible',
                      position: 'relative'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        right: 0
                      }}
                    >
                      <Typography
                        variant="body2"
                        gutterBottom
                        align="right"
                        noWrap
                        style={{ cursor: 'default' }}
                      >
                        {segment.title}
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <Typography
                    variant="body2"
                    noWrap
                    gutterBottom
                    style={{ cursor: 'default' }}
                  >
                    {segment.title}
                  </Typography>
                )}
              </ToolTip>
              {/* Grey Segment */}
              <div
                style={{
                  height: height,
                  backgroundColor: colors.grey,
                  overflowX: 'hidden',
                  display: 'flex'
                }}
              >
                {/* Inner Segment */}
                {segment.filled > 0 && (
                  <ToolTip
                    style={{
                      background:
                        segment.filled > 1
                          ? colors.error
                          : segment.color
                          ? segment.color
                          : colors.success,
                      height: '100%',
                      width: `${segment.filled * 100}%`,
                      maxWidth: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      minWidth: 30
                    }}
                    value={segment.label}
                  >
                    <Typography
                      style={{
                        color: 'white',
                        fontSize: 12,
                        paddingRight: 8,
                        whiteSpace: 'nowrap',
                        cursor: 'default',
                        ...segment.filledStyle
                      }}
                    >
                      {segment.filledText !== undefined
                        ? segment.filledText
                        : `${Math.floor(segment.filled * 100)}%`}
                    </Typography>
                  </ToolTip>
                )}
                <ToolTip
                  style={{ flex: 1 }}
                  value={
                    filled && filled.offLabel
                      ? filled.offLabel
                      : segment.offLabel
                  }
                />
              </div>
            </div>
          );
        })}

        {/* Filled Segment */}
        {Boolean(filled) && filled.portion > 0 && (
          <ToolTip
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: `${filled.portion * 100}%`,
              minWidth: 30,
              maxWidth: '100%',
              height: height,
              backgroundColor: colors.success,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
            value={filled.label}
          >
            {!overflow && (
              <Typography
                style={{
                  color: 'white',
                  fontSize: 12,
                  paddingRight: 8,
                  whiteSpace: 'nowrap',
                  cursor: 'default'
                }}
              >
                {Math.floor(filled.portion * 100)}%
              </Typography>
            )}
          </ToolTip>
        )}

        {/* Filled Overflow */}
        {overflow && (
          <ToolTip
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: `${overflow * 100}%`,
              minWidth: 50,
              maxWidth: '100%',
              height: height,
              backgroundColor: colors.error,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 8
            }}
            value={filled.label}
          >
            <Typography
              style={{
                color: 'white',
                fontSize: 12,
                paddingRight: 8,
                whiteSpace: 'nowrap',
                cursor: 'default'
              }}
            >
              ({Math.floor(filled.portion * 100)}%)
            </Typography>
          </ToolTip>
        )}
      </div>
    </div>
  );
};

function almostEqual(a, b, diff) {
  if (Math.abs(a - b) <= Math.abs(diff)) return true;
  return false;
}

export default BarChart;
