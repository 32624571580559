import React from 'react';
import { Paper } from 'helpers/themeSafeMui.helper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledPaper = styled(Paper)`
  padding: 30px;
  margin: 15vh auto 15vh auto;
`;

const Logo = styled.img`
  width: 280px;
  max-width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 15px;
`;

export default function CardWrapper(props) {
  return (
    <StyledPaper style={{ maxWidth: props.onboarding ? '1200px' : '400px' }}>
      <Link to="/">
        <Logo
          src="https://storage.googleapis.com/steps-files/assets/logo-with-subtitle.png"
          alt="STEPS Logo"
        />
      </Link>

      {props.children}
    </StyledPaper>
  );
}
