import React, { useState, useContext } from 'react';
import { Grid, Typography, Divider, Button } from 'helpers/themeSafeMui.helper';
import EditableRow from '../../../Home/components/EditableRow.component';
import toCurrency from 'helpers/currency.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { EstimateContext } from '../../../../context/Estimate.context';
import CalculatedDetails from '../tables/CalculatedDetails.component';
import PhasesTable from '../tables/PhasesTable.component';

export default function DetailsPanel(props) {
  const { estimate, setEstimate } = useContext(EstimateContext);

  const { selectedTab, index } = props;
  const [editing, setEditing] = useState(false);

  const [numberOfCables, setNumberOfCables] = useState(estimate.numberOfCables);
  const [materialHandling, setMaterialHandling] = useState(
    estimate.materialHandling
  );
  const [laborMarkUp, setLaborMarkUp] = useState(estimate.laborMarkUp);
  const [tax, setTax] = useState(estimate.tax);
  const [overhead, setOverhead] = useState(estimate.overhead);
  const [perdiemRate, setPerdiemRate] = useState(estimate.perdiemRate);
  const [bondingRate, setBondingRate] = useState(estimate.bondingRate);
  const [materialDiscount, setMaterialDiscount] = useState(
    estimate.materialDiscount
  );
  const [laborDiscount, setLaborDiscount] = useState(estimate.laborDiscount);
  const [rawHourlyRate, setRawHourlyRate] = useState(estimate.rawHourlyRate);

  const [permitCost, setPermitCost] = useState(estimate.permitCost);
  const [additionalBlocks, setAdditionalBlocks] = useState(
    estimate.additionalBlocks
  );

  const [isPerdiem, setIsPerdiem] = useState(estimate.isPerdiem);
  const [subcontractAmount, setSubcontractAmount] = useState(
    estimate.subcontractAmount
  );
  const [subcontractMarkup, setSubcontractMarkup] = useState(
    estimate.subcontractMarkup
  );
  const [overtimeMultiplier, setOvertimeMultiplier] = useState(
    estimate.overtimeMultiplier
  );

  const onCancel = () => {
    setEditing(false);
    setNumberOfCables(estimate.numberOfCables);
    setMaterialHandling(estimate.materialHandling);
    setLaborMarkUp(estimate.laborMarkUp);
    setTax(estimate.tax);
    setOverhead(estimate.overhead);
    setPerdiemRate(estimate.perdiemRate);
    setBondingRate(estimate.bondingRate);
    setMaterialDiscount(estimate.materialDiscount);
    setLaborDiscount(estimate.laborDiscount);
    setRawHourlyRate(estimate.rawHourlyRate);
    setPermitCost(estimate.permitCost);
    setAdditionalBlocks(estimate.additionalBlocks);
    setIsPerdiem(estimate.isPerdiem);
    setSubcontractAmount(estimate.subcontractAmount);
    setSubcontractMarkup(estimate.subcontractMarkup);
    setOvertimeMultiplier(estimate.overtimeMultiplier);
  };

  const save = () => {
    http()
      .put(`/projects/${estimate.projectId}/estimates/${estimate.id}`, {
        numberOfCables: numberOfCables,
        materialHandling: materialHandling,
        laborMarkUp: laborMarkUp,
        tax: tax,
        overhead: overhead,
        rawHourlyRate: rawHourlyRate,
        perdiemRate: perdiemRate,
        bondingRate: bondingRate,
        materialDiscount: materialDiscount,
        laborDiscount: laborDiscount,
        additionalBlocks: additionalBlocks,
        permitCost: permitCost,
        isPerdiem,
        subcontractAmount,
        subcontractMarkup,
        overtimeMultiplier
      })
      .then(res => {
        setEditing(false);
        setEstimate(res);
        Toast.show('Saved details for estimate.');
      })
      .catch(err => Toast.show(err.message));
  };

  if (selectedTab !== index) {
    return null;
  } else {
    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: 24,
          margin: 0
        }}
      >
        <Typography variant="h5" style={{ paddingBottom: 12 }}>
          Estimate Details
        </Typography>
        <CalculatedDetails />

        <Typography variant="h5" style={{ paddingBottom: 12, paddingTop: 12 }}>
          Phase Breakdown
        </Typography>
        <PhasesTable />

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: -20,
                right: 0
              }}
            >
              {editing ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    style={{ marginRight: 16 }}
                    onClick={onCancel}
                  >
                    cancel
                  </Button>
                  <Button variant="contained" color="secondary" onClick={save}>
                    save
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setEditing(true)}
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
          <Typography color="textSecondary">General Information</Typography>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Number of Cables"
            value={numberOfCables}
            required={true}
            onChange={numberOfCables => setNumberOfCables(numberOfCables)}
            editing={editing}
          />

          <EditableRow
            label="Tax"
            value={tax}
            required={true}
            onChange={tax => setTax(tax)}
            editing={editing}
            type="percent"
          />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Permit"
            value={permitCost}
            required={true}
            onChange={permit => setPermitCost(permit)}
            editing={editing}
            type="currency"
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <Typography color="textSecondary">Perdiem</Typography>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Is Perdiem"
            value={isPerdiem}
            required={true}
            onChange={isPerdiem => {
              setIsPerdiem(isPerdiem);
            }}
            editing={editing}
            type="boolean"
          />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Perdiem Rate / Hour"
            value={toCurrency(perdiemRate)}
            required={true}
            onChange={perdiemRate => setPerdiemRate(perdiemRate)}
            editing={editing}
            type="dropdown"
            options={[
              { value: 80.0, label: '(2 guys) 10 hour day - $80.00' },
              { value: 80.0, label: '(2 guys) 8 hour day - $100.00' },
              { value: 80.0, label: '(1 guy) 10 hour day - $130.00' }
            ]}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <Typography color="textSecondary">Labor</Typography>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Raw Hourly Rate"
            value={rawHourlyRate}
            required={true}
            onChange={setRawHourlyRate}
            editing={editing}
            type="currency"
          />

          <EditableRow
            label="Labor Mark Up"
            value={laborMarkUp}
            required={true}
            onChange={laborMarkUp => setLaborMarkUp(laborMarkUp)}
            editing={editing}
            type="percent"
          />

          <EditableRow
            label="Labor Discount"
            value={laborDiscount}
            required={true}
            onChange={laborDiscount => setLaborDiscount(laborDiscount)}
            editing={editing}
            type="percent"
          />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Overhead"
            value={overhead}
            required={true}
            onChange={overhead => setOverhead(overhead)}
            editing={editing}
            type="percent"
          />
          <EditableRow
            label="Overtime Multiplier"
            value={overtimeMultiplier}
            required={true}
            onChange={newOvertimeMultiplier =>
              setOvertimeMultiplier(newOvertimeMultiplier)
            }
            editing={editing}
            type="percent"
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <Typography color="textSecondary">Materials</Typography>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Material Discount"
            value={materialDiscount}
            required={true}
            onChange={materialDiscount => setMaterialDiscount(materialDiscount)}
            editing={editing}
            type="currency"
          />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Material Handling"
            value={materialHandling}
            required={true}
            onChange={materialHandling => setMaterialHandling(materialHandling)}
            editing={editing}
            type="percent"
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <Typography color="textSecondary">Subcontract Details</Typography>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Subcontract Amount"
            value={subcontractAmount}
            required={true}
            onChange={subcontractAmount =>
              setSubcontractAmount(subcontractAmount)
            }
            editing={editing}
            type="currency"
          />
        </Grid>

        <Grid item xs={6}>
          <EditableRow
            label="Subcontract Mark Up"
            value={subcontractMarkup}
            required={true}
            onChange={newSubcontractMarkup =>
              setSubcontractMarkup(newSubcontractMarkup)
            }
            editing={editing}
            type="percent"
          />
        </Grid>
      </Grid>
    );
  }
}
