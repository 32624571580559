import React, { useContext } from 'react';
import {
  Button,
  ListItem,
  ListItemSecondaryAction
} from 'helpers/themeSafeMui.helper';
import { ChildProjectsContext } from '../../../context/ChildProjectsContext.context';
import { Link } from 'react-router-dom';

export default function RelatedProject({ projectId, onRemove = null }) {
  const { projectMap } = useContext(ChildProjectsContext);
  if (!projectId) return null;
  const project = projectMap[projectId];
  if (!project) return null;

  return (
    <div style={{ position: 'relative' }}>
      <Link
        style={{
          color: 'inherit',
          textDecoration: 'inherit'
        }}
        to={`/projects/${projectId}`}
      >
        <ListItem
          style={onRemove ? { paddingTop: 16, paddingBottom: 16 } : undefined}
          button
        >
          {project.projectNumber} {project.name}
        </ListItem>
      </Link>
      {onRemove && (
        <ListItemSecondaryAction style={{ padding: 5 }}>
          <Button
            variant="contained"
            onClick={e => {
              e.stopPropagation();
              onRemove(projectId);
            }}
          >
            Remove
          </Button>
        </ListItemSecondaryAction>
      )}
    </div>
  );
}
