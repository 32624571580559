import React, { useState } from 'react';
import {
  ListItemText,
  Typography,
  Divider,
  Grid,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, CollapseListItem } from 'components';
import { useColors } from 'helpers/theme.helper';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';
import moment from 'moment';
import RfiModal from '../modals/Rfi.modal';
import { DriveFolderViewer } from 'components';
import RfiComments from './StepsRfiComments.component';
import RfiEmailModal from '../modals/RfiEmail.modal';

const Rfi = ({ rfi, users, setRfi }) => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const colors = useColors();

  const updateRfi = async data => {
    try {
      const copy = { ...data };
      const assignedUserIds = copy.assignedUserIds;
      delete copy.assignedUserIds;

      if (!copy.dueDate) copy.dueDate = null;
      let updatedRfi = await http().put(
        `/projects/${rfi.projectId}/rfis/${rfi.id}`,
        copy
      );
      updatedRfi = await http().put(
        `/projects/${rfi.projectId}/rfis/${rfi.id}/users`,
        {
          assignedUserIds
        }
      );

      setRfi(updatedRfi);
      setUpdateModalOpen(false);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  return (
    <>
      <RfiEmailModal
        open={emailModalOpen}
        onClose={() => setEmailModalOpen()}
        rfiId={rfi.id}
      />
      <RfiModal
        formTitle="Update RFI"
        rfi={rfi}
        users={users}
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onSubmit={updateRfi}
      />
      <CollapseListItem
        headerItemContent={
          <ListItemText
            primary={`#${rfi.number} - ${rfi.title}`}
            primaryTypographyProps={{ component: 'h1', variant: 'h5' }}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item container spacing={2} sm={12} md={7}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Question
              </Typography>
              <Divider />
              <div
                style={{
                  paddingTop: 10,
                  paddingLeft: 16
                }}
                dangerouslySetInnerHTML={{ __html: rfi.questionHtml }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Answer
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10 }} />
              <Typography variant="body1" style={{ paddingLeft: 16 }}>
                {rfi.answer ? rfi.answer : 'No answer yet'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Documents
              </Typography>
              <Divider />
              <DriveFolderViewer
                style={{ padding: '10px 16px' }}
                apiPath={`/projects/${rfi.projectId}/rfis/${rfi.id}/docs`}
                gridSizes={{
                  xl: 4,
                  lg: 6,
                  md: 6,
                  xs: 12
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} sm={12} md={5}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Update
              </Typography>
              <Divider />
              <div
                style={{
                  paddingTop: 10,
                  display: 'flex'
                }}
              >
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => setUpdateModalOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Update Rfi
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => setEmailModalOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Create Email
                </Button>
                <Button
                  href={`/rfi?id=${rfi.id}`}
                  target="_blank"
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                >
                  Download
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Info
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10 }} />

              <Typography variant="body1" style={{ paddingLeft: 16 }}>
                {rfi.locked ? (
                  <span style={{ color: colors.error }}>
                    <BsLockFill style={{ marginRight: 5 }} />
                    locked
                  </span>
                ) : (
                  <span style={{ color: colors.success }}>
                    <BsUnlockFill style={{ marginRight: 5 }} />
                    unlocked
                  </span>
                )}
              </Typography>
              {rfi.dueDate && (
                <>
                  <Typography variant="body1" style={{ paddingLeft: 16 }}>
                    Due:
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ paddingLeft: 32 }}
                  >
                    {moment(rfi.dueDate).format('LL')}
                  </Typography>
                </>
              )}
              {!!rfi.users.length && (
                <>
                  <Typography variant="body1" style={{ paddingLeft: 16 }}>
                    Users:
                  </Typography>
                  {rfi.users.map(user => (
                    <Typography
                      key={user.id}
                      variant="caption"
                      component="div"
                      style={{ paddingLeft: 32 }}
                    >
                      {user.name}
                    </Typography>
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Comments
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10, paddingLeft: 16 }}>
                <RfiComments rfi={rfi} setRfi={setRfi} users={users} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CollapseListItem>
    </>
  );
};

export default Rfi;
