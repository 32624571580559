import React, { useCallback, useEffect, useState } from 'react';
import { Button, Paper } from 'helpers/themeSafeMui.helper';
import { useRouteMatch } from 'react-router';
import { NoItemsResult, Toast } from 'components';
import http from 'helpers/http.helper';
import CreatePoModal from './modals/CreatePo.modal';
import Po from './components/Po.component';
import safeParse from 'helpers/safeParse.helper';

import { Typography } from 'helpers/themeSafeMui.helper';

export const SuppliersContext = React.createContext([]);
export const StatusesContext = React.createContext([]);

export default function PosTab() {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const [nextPoNumber, setNextPoNumber] = useState('Loading...');
  const [projectMaterials, setProjectMaterials] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [materialStatuses, setMaterialStatuses] = useState({});
  const [shipToOptions, setShipToOptions] = useState([]);
  const [pos, setPos] = useState([]);

  const getPos = useCallback(() => {
    if (projectId) {
      Promise.all([
        http().get(`/projects/${projectId}/pos`),
        http().get(`/projects/${projectId}/pos/next-po`),
        http().get('/companies/suppliers'),
        http().get(`/projects/${projectId}/materials/statuses`),
        http().get(`/po-ship-to-options`)
      ])
        .then(([pos, nextPoNumber, suppliers, statuses, shipToOptions]) => {
          setPos(pos);
          setNextPoNumber(nextPoNumber);
          setSuppliers(suppliers);
          setMaterialStatuses(statuses);
          setShipToOptions(shipToOptions);
        })
        .catch(Toast.showErr);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      http()
        .get(`/projects/${projectId}/materials`)
        .then(setProjectMaterials)
        .catch(Toast.showErr);
      getPos();
    }
  }, [projectId, getPos]);

  function getTotal(items) {
    const result = items
      .map(mat => {
        return (
          Math.round(safeParse(mat.unitCost) * safeParse(mat.quantity) * 100) /
          100
        );
      })
      .reduce((sum, num) => sum + num, 0);
    return result;
  }

  const total = parseFloat(
    pos.reduce((sum, num) => sum + getTotal(num.items), 0).toFixed(2)
  );

  return (
    <SuppliersContext.Provider value={suppliers}>
      <StatusesContext.Provider value={materialStatuses}>
        <div>
          <CreatePoModal
            open={createOpen}
            onClose={() => setCreateOpen(false)}
            getPos={getPos}
            materials={projectMaterials}
            nextPoNumber={nextPoNumber}
            shipToOptions={shipToOptions}
          />
          <div style={{ padding: '12px 0' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateOpen(true)}
            >
              Create PO
            </Button>
          </div>
          {!pos.length ? (
            <NoItemsResult type="Purchase Order" />
          ) : (
            <>
              <Typography component="span" variant="h5">
                {`Total: $${total}`}
              </Typography>

              {pos.map(({ po, items }, idx) => (
                <Paper
                  elevation={3}
                  key={po.id}
                  style={{ marginTop: idx === 0 ? 0 : 24 }}
                >
                  <Po
                    getPos={getPos}
                    po={po}
                    items={items}
                    shipToOptions={shipToOptions}
                  />
                </Paper>
              ))}
            </>
          )}
        </div>
      </StatusesContext.Provider>
    </SuppliersContext.Provider>
  );
}
