import React from 'react';
import { DriveFolderViewer } from 'components';

const DocumentTab = props => {
  const { userId } = props;

  return (
    <DriveFolderViewer
      style={{ padding: 24, margin: -24 }}
      apiPath={`/users/${userId}/docs`}
    />
  );
};

export default DocumentTab;
