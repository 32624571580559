import React, { useState, useContext } from 'react';
import { Toast } from 'components';
import {
  Typography,
  Button,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  TextField
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import http from 'helpers/http.helper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  ProjectNotesContext,
  ProjectContext
} from '../../../context/Project.context';

function ProjectNote({ note }) {
  const [editText, setEditText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector(state => state.user);
  const { project } = useContext(ProjectContext);
  const { projectNotes: notes, setProjectNotes: setNotes } = useContext(
    ProjectNotesContext
  );

  const updateNote = () => {
    http()
      .put(`/projects/${project.id}/notes/${note.id}`, { note: editText })
      .then(() => {
        const updatedNote = { ...note, note: editText };
        setIsEditing(false);
        setNotes(notes.map(n => (n.id === updatedNote.id ? updatedNote : n)));
        Toast.show('Note updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  const archiveNote = () => {
    http()
      .delete(`/projects/${project.id}/notes/${note.id}`)
      .then(() => {
        setNotes(notes.filter(n => n.id !== note.id));
        Toast.show('The note was removed.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      <ListItem>
        <ListItemText
          primary={
            <span>
              <Typography
                component="span"
                variant="body1"
                style={{ whiteSpace: 'pre-line' }}
              >
                {isEditing ? (
                  <>
                    <TextField
                      label="Project Note"
                      multiline
                      style={{
                        marginBottom: 6,
                        marginRight: 6,
                        width: 'calc(100% - 275px)'
                      }}
                      maxRows="4"
                      value={editText}
                      fullWidth
                      onChange={e => setEditText(e.target.value)}
                    />
                    <Button
                      style={{ margin: '9px 6px' }}
                      color="primary"
                      variant="outlined"
                      onClick={updateNote}
                    >
                      save
                    </Button>
                    <Button
                      style={{ margin: '9px 6px' }}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setIsEditing(false);
                      }}
                    >
                      cancel
                    </Button>
                  </>
                ) : (
                  note.note
                )}
              </Typography>
            </span>
          }
          secondary={note.name + ' - ' + moment(note.created).format('LLL')}
        />

        {user.id === note.createdBy && (
          <>
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  setEditText(note.note);
                  setIsEditing(!isEditing);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={archiveNote}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
      <Divider />
    </div>
  );
}

export default ProjectNote;
