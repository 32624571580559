import React, { useState } from 'react';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import OneDriveFolderIcon from 'assets/folder_icon.svg';
import { useColors } from 'helpers/theme.helper';
import { CopyText, CustomInput } from 'components';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import SmallIcon from './SmallIcon.component';
import moment from 'moment';

const OneDriveFolderCard = ({
  folder,
  showIds,
  onSelect,
  onEnter,
  onDelete,
  onNameChange,
  disabled
}) => {
  const colors = useColors();
  const [editingName, setEditingName] = useState(null);
  return (
    <div
      style={{
        border: `1px solid ${colors.grey}`,
        borderRadius: 5,
        padding: 10
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <img
          style={{ height: 30, marginRight: 5 }}
          src={OneDriveFolderIcon}
          alt="folder"
        />
        <div>
          {editingName !== null ? (
            <CustomInput
              label="Name"
              value={editingName}
              onChange={setEditingName}
            />
          ) : (
            <Typography>{folder.name}</Typography>
          )}
          {folder && showIds && (
            <div>
              <CopyText text={folder.id} type="File ID">
                ID: {folder.id}
              </CopyText>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {folder && (
          <div>
            <Typography variant="caption" component="div" color="textSecondary">
              Modified: {moment(folder.lastModifiedDateTime).fromNow()}
            </Typography>
          </div>
        )}
        <div style={{ whiteSpace: 'nowrap' }}>
          {editingName !== null ? (
            <>
              <SmallIcon
                disabled={disabled}
                Component={CancelIcon}
                onClick={() => setEditingName(null)}
              />
              <SmallIcon
                disabled={disabled}
                Component={SaveIcon}
                onClick={() =>
                  onNameChange(editingName).then(() => setEditingName(null))
                }
              />
            </>
          ) : (
            <>
              <SmallIcon
                disabled={disabled}
                Component={EditIcon}
                onClick={() => setEditingName(folder.name)}
              />
              <SmallIcon
                disabled={disabled}
                Component={DeleteIcon}
                onClick={onDelete}
              />
              <SmallIcon
                disabled={disabled}
                Component={LinkIcon}
                onClick={() => window.open(folder.webUrl, '_blank')}
              />
            </>
          )}
        </div>
      </div>
      <Button
        onClick={() => onEnter(folder)}
        fullWidth
        disabled={disabled}
        variant="contained"
        size="small"
      >
        Open
      </Button>
      {onSelect && (
        <Button
          style={{ marginTop: 10 }}
          onClick={() => onSelect(folder)}
          fullWidth
          disabled={disabled}
          variant="contained"
          size="small"
        >
          Select This Folder
        </Button>
      )}
    </div>
  );
};

export default OneDriveFolderCard;
