import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  DashboardWrapper,
  Toast,
  SelectionSortingTable,
  PhotoModal,
  PermissionWrapper
} from 'components';
import {
  Typography,
  useTheme,
  TableCell,
  Paper
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import TrainingModuleDropdown from '../components/TrainingModuleDropdown.component';
import http from 'helpers/http.helper';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import TrainingRecentActionCell from '../components/TrainingRecentActionCell.component';
import TrainingCommunicationsModal from '../modals/TrainingCommunications.modal';
import Provider, { UsersContext } from '../context/Users.context';
import TrainingUserFilter from '../components/TrainingUserFilter.component';
import TrainingSendCommunicationModal from '../modals/TrainingSendCommunication.modal';
import { useSelector } from 'react-redux';
import TrainingCertificateDownload from '../components/TrainingCertificateDownload.component';
import TrainingTableCell from '../components/TrainingTableCell.component';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10
  }
};

const Trainings = () => {
  const theme = useTheme();
  const history = useHistory();
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [trainingRecordMap, setTrainingRecordMap] = useState({});
  const [trainingQuizMap, setTrainingQuizMap] = useState({});

  const currentUser = useSelector(state => state.user);
  const { users } = useContext(UsersContext);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [viewingUser, setViewingUser] = useState(null);
  const [userMap, setUserMap] = useState({});

  const [allCommunications, setAllCommunications] = useState([]);
  const [allNotes, setAllNotes] = useState([]);

  const [modalImage, setModalImage] = useState(null);

  const [
    selectedTrainingModules,
    setSelectedTrainingModules
  ] = useLocalStorage('selected_training_modules', [], joi =>
    joi.array().items(joi.object())
  );

  const hasCommunicationsPermission = currentUser.hasPermission(
    'training_module_admin'
  );

  const showAllOnLoad = useCallback(
    trainingGroups => {
      setSelectedTrainingModules(current => {
        if (!current.length) {
          return trainingGroups.reduce(
            (modules, group) => modules.concat(group.trainingModules),
            []
          );
        } else {
          return current;
        }
      });
    },
    [setSelectedTrainingModules]
  );

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const fetchCommunications = useCallback(() => {
    if (!hasCommunicationsPermission) return;
    http()
      .get('/users/all/training-notes')
      .then(res => {
        setAllCommunications(res);
      })
      .catch(err => Toast.show(err.message));

    http()
      .get('/training-communications')
      .then(res => {
        setAllNotes(res);
      })
      .catch(err => Toast.show(err.message));
  }, [hasCommunicationsPermission]);

  useEffect(() => {
    fetchCommunications();

    http()
      .get('/training-groups')
      .then(trainingGroups =>
        Promise.all(
          trainingGroups.map(group =>
            http()
              .get(`/training-groups/${group.id}/training-modules`)
              .then(trainingModules => ({ ...group, trainingModules }))
          )
        )
      )
      .then(trainingGroups => {
        setTrainingGroups(trainingGroups);
        showAllOnLoad(trainingGroups);
      })
      .catch(err => Toast.show(err.message));

    http()
      .get('/users/trainings')
      .then(res => {
        setTrainingRecordMap(
          res.reduce(
            (map, record) =>
              Object.assign(map, {
                [`${record.trainingModuleId},${record.userId}`]: record
              }),
            {}
          )
        );
      })
      .catch(err => Toast.show(err.message));

    if (hasCommunicationsPermission)
      http()
        .get('/training-quizzes/attempts')
        .then(res => {
          setTrainingQuizMap(
            res.reduce(
              (map, quiz) =>
                Object.assign(map, {
                  [`${quiz.trainingModuleId},${quiz.userId}`]: quiz
                }),
              {}
            )
          );
        })
        .catch(err => Toast.show(err.message));
  }, [fetchCommunications, showAllOnLoad, hasCommunicationsPermission]);

  useEffect(() => {
    function mapReduce(acc, item) {
      if (acc[item.userId]) {
        acc[item.userId].push(item);
      } else {
        acc[item.userId] = [item];
      }
      return acc;
    }
    const map = allCommunications.reduce(mapReduce, {});
    allNotes.reduce(mapReduce, map);
    Object.values(map).forEach(comNotes => {
      comNotes.forEach(comNote => {
        comNote.unix = moment(comNote.created).unix();
      });
      comNotes.sort((a, b) => {
        return b.unix - a.unix;
      });
    });
    setUserMap(map);
  }, [allCommunications, allNotes]);

  const communicationsColumn = !hasCommunicationsPermission
    ? []
    : [
        {
          key: 'communications-notes',
          label: 'Communications / Notes',
          extractor: user => user,
          customSort: (userA, userB) => {
            const m = Number.MAX_SAFE_INTEGER;
            const mapA = userMap[userA.id] || [{ unix: m }];
            const mapB = userMap[userB.id] || [{ unix: m }];
            const sign = Math.sign(mapA[0].unix - mapB[0].unix);
            return sign;
          },
          descSort: (userA, userB) => {
            const mapA = userMap[userA.id] || [{ unix: 0 }];
            const mapB = userMap[userB.id] || [{ unix: 0 }];
            const sign = Math.sign(mapB[0].unix - mapA[0].unix);
            return sign;
          },
          renderer: (user, info, rootStyles) => {
            return (
              <TableCell
                padding="none"
                style={{ position: 'relative', ...rootStyles }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                  }}
                >
                  <PermissionWrapper permission="training_module_admin">
                    <TrainingRecentActionCell
                      user={user}
                      items={userMap[user.id] || []}
                      setViewingUser={setViewingUser}
                    />
                  </PermissionWrapper>
                </div>
              </TableCell>
            );
          }
        }
      ];

  const groups = [
    {
      label: '',
      columns: [
        {
          key: 'name',
          label: 'Name',
          extractor: user => `${user.name}-${user.jobTitle}`,
          renderer: (user, info, rootStyles) => (
            <TableCell padding="none" style={rootStyles}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={e => e.stopPropagation()}
                  style={{
                    cursor: 'default',
                    width: 50,
                    height: 50,
                    marginRight: 16,
                    padding: 5
                  }}
                >
                  {user.image && (
                    <img
                      onClick={e => setModalImage(user.image)}
                      style={{
                        objectFit: 'cover',
                        cursor: 'pointer',
                        width: 40,
                        height: 40
                      }}
                      src={user.image}
                      alt="Profile"
                    />
                  )}
                </div>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {user.name}
                  <br />
                  <Typography
                    style={{ whiteSpace: 'nowrap' }}
                    variant="caption"
                  >
                    {user.jobTitle}
                  </Typography>
                </div>
              </div>
            </TableCell>
          )
        },
        ...communicationsColumn
      ]
    },
    ...trainingGroups
      .filter(group =>
        group.trainingModules.some(trainingModule =>
          selectedTrainingModules.some(el => el.id === trainingModule.id)
        )
      )
      .map(group => ({
        label: group.name,
        columns: group.trainingModules
          .filter(trainingModule =>
            selectedTrainingModules.some(el => el.id === trainingModule.id)
          )
          .map(trainingModule => ({
            modifier: val => {
              if (val && val.content) {
                return val.content;
              } else if (val) {
                return val.toString();
              }
              return null;
            },
            key: trainingModule.id,
            label: trainingModule.name,
            customSort: (userA, userB) => {
              const m = Number.MAX_SAFE_INTEGER;
              let recordA =
                trainingRecordMap[`${trainingModule.id},${userA.id}`];
              let recordB =
                trainingRecordMap[`${trainingModule.id},${userB.id}`];
              if (recordA && recordA.optional) recordA = null;
              if (recordB && recordB.optional) recordB = null;
              const unixA = recordA ? moment(recordA.dateCompleted).unix() : m;
              const unixB = recordB ? moment(recordB.dateCompleted).unix() : m;
              const sign = Math.sign(unixA - unixB);
              return sign;
            },
            descSort: (userA, userB) => {
              let recordA =
                trainingRecordMap[`${trainingModule.id},${userA.id}`];
              let recordB =
                trainingRecordMap[`${trainingModule.id},${userB.id}`];
              if (recordA && recordA.optional) recordA = null;
              if (recordB && recordB.optional) recordB = null;
              const unixA = recordA ? moment(recordA.dateCompleted).unix() : 0;
              const unixB = recordB ? moment(recordB.dateCompleted).unix() : 0;
              const sign = Math.sign(unixB - unixA);
              return sign;
            },
            renderer: (user, info, rootStyles) => {
              const quiz = trainingQuizMap[`${trainingModule.id},${user.id}`];
              const record =
                trainingRecordMap[`${trainingModule.id},${user.id}`];
              return (
                <TableCell style={rootStyles}>
                  <TrainingTableCell quiz={quiz} record={record} />
                </TableCell>
              );
            }
          }))
      }))
  ];

  const userCountText = () => {
    const num = selectedUsers.length;
    const users = 'user' + (num !== 1 ? 's' : '');
    return `${num} ${users} selected`;
  };

  return (
    <DashboardWrapper>
      <TrainingCommunicationsModal
        open={!!viewingUser}
        onClose={() => setViewingUser(null)}
        user={viewingUser}
        items={viewingUser && userMap[viewingUser.id]}
      />

      <PhotoModal
        onClose={() => setModalImage(null)}
        open={!!modalImage}
        url={modalImage}
      />
      <div style={styles.header}>
        <Typography component="h1" variant="h4">
          Employee Training Master List
        </Typography>
        <TrainingModuleDropdown
          selectedTrainingModules={selectedTrainingModules}
          trainingGroups={trainingGroups}
          onChange={setSelectedTrainingModules}
        />
      </div>
      <div style={{ ...styles.header, justifyContent: 'flex-start' }}>
        <TrainingUserFilter
          users={users}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          localStoragePrefix="employee-training."
        />
        <div style={{ padding: 5 }} />
        <TrainingSendCommunicationModal
          onSubmit={fetchCommunications}
          selectedUsers={selectedUsers}
        />
        <div style={{ padding: 5 }} />
        <TrainingCertificateDownload
          selectedUsers={selectedUsers}
          modules={trainingGroups.map(group => group.trainingModules).flat()}
        />
        <div style={{ padding: 5 }} />
        <Paper
          style={{
            height: 36,
            padding: '6px 16px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <div>
            <Typography variant="overline">{userCountText()}</Typography>
          </div>
        </Paper>
      </div>

      <SelectionSortingTable
        groups={groups}
        onSelectionChange={setSelectedUsers}
        alternateColors
        stickyHeader
        stickyColumns={2}
        rootStyles={{
          height: `calc(100vh - ${theme.typography.pxToRem(210)})`
        }}
        rows={filteredUsers}
        sticky
        rowAction={row => history.push(`/users/${row.id}/trainings`)}
        localStorageKey="users.master_training_table.pagination"
      />
    </DashboardWrapper>
  );
};

const ConnectedTrainingsRoute = props => (
  <Provider>
    <Trainings {...props} />
  </Provider>
);

export default ConnectedTrainingsRoute;
