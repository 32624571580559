import { useCallback, useRef } from 'react';

const useFreshFn = <T extends Function>(fn: T) => {
  const ref = useRef(fn);
  ref.current = fn;

  const callback = useCallback((...args) => {
    return ref.current(...args);
  }, []);

  return (callback as unknown) as T;
};

export default useFreshFn;
