import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Dialog,
  ListItem,
  DialogTitle,
  ListItemText,
  TextField,
  DialogActions,
  Button,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import { Toast, CustomInput, UserSearch } from 'components';
import moment from 'moment';
import { ProjectsContext } from '../context/projects.context';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';

const filterOptions = createFilterOptions({
  limit: 50,
  stringify: project => `${project.projectNumber} - ${project.name}`
});

const emptyPerDiem = {
  projectId: null,
  startDate: moment()
    .startOf('day')
    .toDate(),
  endDate: moment()
    .startOf('day')
    .toDate(),
  dailyAmount: 0,
  totalAmount: 0,
  notes: '',
  status: null,
  locked: null,
  paidOn: null
};

const EditPerDiemModal = ({
  open,
  onClose,
  title,
  onSubmit,
  hiddenFields = [],
  perDiem,
  userSelect = false
}) => {
  if (!perDiem) perDiem = emptyPerDiem;
  const user = useSelector(state => state.user);
  const [projectId, setProjectId] = useState(perDiem.projectId);
  const [userId, setUserId] = useState(null);
  const [startDate, setStartDate] = useState(perDiem.startDate);
  const [endDate, setEndDate] = useState(perDiem.endDate);
  const [dailyAmount, setDailyAmount] = useState(perDiem.dailyAmount);
  const [totalAmount, setTotalAmount] = useState(perDiem.totalAmount);
  const [notes, setNotes] = useState(perDiem.notes);
  const [status, setStatus] = useState(perDiem.status);
  const [locked, setLocked] = useState(perDiem.locked);
  const [paidOn, setPaidOn] = useState(perDiem.paidOn);
  const { projectsMap, openProjects } = useContext(ProjectsContext);

  const project = projectsMap[projectId] || null;
  const projectSelected = projectId !== null;

  const isTier2User = user.hasPermission('per_diem_tier_2');
  const hide = (field, element) =>
    !projectSelected || hiddenFields.includes(field) ? null : element;

  useEffect(() => {
    if (project) {
      if (!perDiem.startDate) setStartDate(project.start || null);
      if (!perDiem.endDate) setEndDate(project.end || null);
      if (!perDiem.dailyAmount) setDailyAmount(project.perDiem || 0);
    }
  }, [project, perDiem]);

  useEffect(() => {
    // update total amount
    if (project) {
      let nights = Math.ceil(
        moment(endDate).diff(moment(startDate), 'days', true)
      );
      if (moment(startDate).isSame(moment(endDate), 'day')) nights = 1;
      if (isNaN(nights) || nights < 0 || !parseFloat(dailyAmount)) {
        setTotalAmount('0.00');
      } else {
        setTotalAmount((parseFloat(dailyAmount) * nights).toFixed(2));
      }
    }
  }, [project, startDate, endDate, dailyAmount]);

  useEffect(() => {
    if (status === 'Paid' && !paidOn) {
      setPaidOn(
        moment()
          .startOf('day')
          .toDate()
      );
    } else if (status !== 'Paid' && paidOn) {
      setPaidOn(null);
    }
  }, [status, paidOn]);

  const reset = useCallback(() => {
    setProjectId(perDiem.projectId);
    setStartDate(perDiem.startDate);
    setEndDate(perDiem.endDate);
    setDailyAmount(perDiem.dailyAmount);
    setTotalAmount(perDiem.totalAmount);
    setNotes(perDiem.notes);
    setStatus(perDiem.status);
    setLocked(perDiem.locked);
    setPaidOn(perDiem.paidOn);
  }, [perDiem]);

  useEffect(() => reset(), [reset]);

  const closeAndClear = () => {
    reset();
    onClose();
  };

  const onFormSubmit = async e => {
    try {
      e.preventDefault();
      await onSubmit({
        ...(userSelect ? { userId } : {}),
        projectId,
        startDate,
        endDate,
        dailyAmount,
        totalAmount,
        notes,
        status,
        locked,
        paidOn
      });
      closeAndClear();
    } catch (err) {
      Toast.show(err.message);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeAndClear}>
      <form onSubmit={onFormSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {!hiddenFields.includes('projectId') && (
            <Autocomplete
              options={openProjects}
              onChange={(e, project) =>
                setProjectId(project ? project.id : null)
              }
              getOptionLabel={project =>
                `${project.projectNumber} - ${project.name}`
              }
              getOptionSelected={(a, b) => a && b && a.id === b.id}
              value={project}
              filterOptions={filterOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <div style={{ display: 'flex' }}>
                      Project{' '}
                      <SearchIcon
                        style={{
                          marginLeft: 5,
                          transform: 'translateY(-5px)'
                        }}
                      />
                    </div>
                  }
                  fullWidth
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          )}
          {userSelect && (
            <UserSearch
              onChange={user => setUserId(user.id)}
              disabled={!isTier2User}
            />
          )}
          {hide(
            'startDate',
            <CustomInput
              type="date"
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
              disabled={!isTier2User && locked}
            />
          )}
          {hide(
            'endDate',
            <CustomInput
              type="date"
              label="End Date"
              value={endDate}
              onChange={setEndDate}
              disabled={!isTier2User && locked}
            />
          )}
          {hide(
            'dailyAmount',
            <CustomInput
              type="currency"
              label="Daily Amount"
              value={dailyAmount}
              onChange={setDailyAmount}
              disabled={!isTier2User}
            />
          )}
          {hide(
            'locked',
            <CustomInput
              type="boolean"
              label="Locked"
              value={locked}
              onChange={setLocked}
            />
          )}
          {hide(
            'notes',
            <CustomInput
              label="Notes"
              multiline
              value={notes}
              onChange={setNotes}
            />
          )}
          {hide(
            'status',
            <CustomInput
              type="dropdown"
              label="status"
              value={status}
              onChange={setStatus}
              options={[
                { value: 'Pending', label: 'Pending' },
                { value: 'Approved', label: 'Approved' },
                { value: 'Rejected', label: 'Rejected' },
                { value: 'Paid', label: 'Paid' }
              ]}
            />
          )}
          {status === 'Paid' &&
            hide(
              'paidOn',
              <CustomInput
                type="date"
                label="Paid On"
                value={paidOn}
                onChange={setPaidOn}
                disabled={!isTier2User}
              />
            )}
          {hide(
            'totalAmount',
            <ListItem>
              <ListItemText
                primary="$ Total:"
                secondary={totalAmount ? '$' + totalAmount : 'N/A'}
              />
            </ListItem>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={userSelect ? !userId : false}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
          <Button variant="contained" color="default" onClick={closeAndClear}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPerDiemModal;
