import React from 'react';
// import { DashboardWrapper } from 'components';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import { CardWrapper } from 'components';
import { Link } from 'react-router-dom';

export default class CatchAll extends React.Component {
  render() {
    return (
      <CardWrapper>
        <Typography
          component="h2"
          variant="h2"
          gutterBottom
          style={{ textAlign: 'center', color: '#0276C2' }}
        >
          404
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ textAlign: 'center', color: '#777777' }}
        >
          OOPS! Sorry, the page you are looking for can't be found or no longer
          exists.
        </Typography>

        <Button
          component={Link}
          fullWidth
          to="/"
          size="small"
          color="primary"
          style={{ textAlign: 'center', margin: '8px 0 15px' }}
        >
          Take Me Home
        </Button>
      </CardWrapper>
    );
  }
}
