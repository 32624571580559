import React, { useContext } from 'react';
import { makeStyles, TableCell } from 'helpers/themeSafeMui.helper';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const SetProjectsModalMaterialContext = React.createContext(() => {
  console.error('outside of context');
});
const useStyles = makeStyles(theme => ({
  cell: {
    '&:hover': { color: theme.palette.primary.main },
    color: theme.palette.action.active,
    cursor: 'pointer'
  },
  cellDisabled: {
    color: theme.palette.action.disabled
  }
}));

export default function ProjectCountCell({ material, cellStyle }) {
  const setProjectsModalMaterial = useContext(SetProjectsModalMaterialContext);
  const { cell, cellDisabled } = useStyles();

  const count = material
    ? material.projectCount
      ? material.projectCount
      : material.projectCount === null
      ? 0
      : null
    : null;

  return (
    <TableCell
      padding="checkbox"
      className={count ? cell : cellDisabled}
      style={cellStyle}
      onClick={e => {
        if (!count) return;
        setProjectsModalMaterial(material);
        e.stopPropagation();
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ReceiptIcon />
      </div>
    </TableCell>
  );
}
