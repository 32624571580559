import React, { useContext, useState } from 'react';
import { DndTable, NoItemsResult, Toast } from 'components';
import {
  TableCell,
  Popover,
  IconButton,
  TextField,
  TableRow
} from 'helpers/themeSafeMui.helper';
import { v4 as uuid } from 'uuid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { SketchPicker } from 'react-color';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectScheduleTasksContext
} from 'routes/Projects/context/Project.context';

const Labels = ({ labels, setLabels }) => {
  const [editingId, setEditingId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [editingColor, setEditingColor] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const { project } = useContext(ProjectContext);
  const { fetchTasks } = useContext(ProjectScheduleTasksContext);

  const addLabel = async () => {
    try {
      const newLabel = await http().post(
        `/projects/${project.id}/schedule-labels`,
        {
          id: uuid(),
          name: 'New Label',
          color: 'ffffff',
          order: 0
        }
      );
      await Promise.all(
        labels.map(label =>
          http().put(`/projects/${project.id}/schedule-labels/${label.id}`, {
            order: label.order + 1
          })
        )
      );
      setLabels(labels => [newLabel, ...labels]);
      setEditingId(newLabel.id);
      setEditingName(newLabel.name);
      setEditingColor(newLabel.color);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const onDrop = async droppedLabels => {
    setLabels(droppedLabels.map((label, idx) => ({ ...label, order: idx })));

    try {
      const afterUpdate = await Promise.all(
        droppedLabels.map((label, idx) =>
          http().put(`/projects/${project.id}/schedule-labels/${label.id}`, {
            order: idx
          })
        )
      );
      setLabels(afterUpdate);
    } catch (err) {
      Toast.show(err.message);
      setLabels(labels);
    }
  };

  const setEditing = id => () => {
    const label = labels.find(label => label.id === id);
    setEditingId(label.id);
    setEditingName(label.name);
    setEditingColor(label.color);
  };

  const reset = () => {
    setEditingId(null);
    setEditingName('');
    setEditingColor('');
  };

  const remove = () => {
    setLabels(labels => labels.filter(label => label.id !== editingId));
    http()
      .delete(`/projects/${project.id}/schedule-labels/${editingId}`)
      .then(fetchTasks)
      .catch(Toast.showErr);
  };

  const save = () => {
    setLabels(labels =>
      labels.map(label =>
        label.id === editingId
          ? {
              id: editingId,
              name: editingName,
              color: editingColor
            }
          : label
      )
    );
    reset();

    http()
      .put(`/projects/${project.id}/schedule-labels/${editingId}`, {
        name: editingName,
        color: editingColor
      })
      .catch(Toast.showErr);
  };

  const openColorPicker = e => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div>
      <DndTable.Table onDrop={onDrop}>
        <DndTable.TableHead
          dragIndicatorCell={
            <TableCell padding="checkbox">
              <IconButton onClick={addLabel} size="small">
                <AddCircleOutlineIcon />
              </IconButton>
            </TableCell>
          }
        >
          <TableCell>Label Name</TableCell>
          <TableCell padding="checkbox">Update</TableCell>
        </DndTable.TableHead>
        {labels.map(label => {
          const editing = editingId === label.id;
          return (
            <DndTable.TableRow
              style={{ background: `#${editing ? editingColor : label.color}` }}
              value={label}
              key={label.id}
              id={label.id}
              dragCellProps={{ padding: 'checkbox' }}
            >
              <TableCell>
                {editing ? (
                  <TextField
                    value={editingName}
                    onChange={e => setEditingName(e.target.value)}
                  />
                ) : (
                  label.name
                )}
              </TableCell>
              <TableCell align="right" padding="checkbox">
                {editing ? (
                  <div style={{ display: 'flex' }}>
                    <IconButton onClick={openColorPicker} size="small">
                      <ColorLensIcon />
                    </IconButton>
                    <div style={{ paddingRight: 5 }} />
                    <IconButton onClick={remove} size="small">
                      <DeleteIcon />
                    </IconButton>
                    <div style={{ paddingRight: 5 }} />
                    <IconButton onClick={reset} size="small">
                      <ClearIcon />
                    </IconButton>
                    <div style={{ paddingRight: 5 }} />
                    <IconButton onClick={save} size="small">
                      <SaveIcon />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton size="small" onClick={setEditing(label.id)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </DndTable.TableRow>
          );
        })}
        {!labels.length && (
          <TableRow>
            <TableCell colSpan={3}>
              <NoItemsResult type="Labels" />
            </TableCell>
          </TableRow>
        )}
      </DndTable.Table>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {editingId && (
          <SketchPicker
            color={'#' + editingColor}
            onChange={changeColor => {
              setEditingColor(changeColor.hex.slice(1));
            }}
            onChangeComplete={changeColor => {
              setEditingColor(changeColor.hex.slice(1));
            }}
          />
        )}
      </Popover>
    </div>
  );
};

export default Labels;
