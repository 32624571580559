import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from 'helpers/themeSafeMui.helper';
import { CustomInput } from 'components';

export default function UpdateEstimateNameModal({
  open,
  onClose,
  onSave,
  name,
  number
}) {
  const [stateName, setStateName] = useState(name);
  const [stateNumber, setStateNumber] = useState(number);

  const updateState = useCallback(() => {
    setStateName(stateName => (stateName !== name ? name : stateName));
    setStateNumber(stateNumber =>
      stateNumber !== number ? number : stateNumber
    );
  }, [name, number]);

  useEffect(updateState, [updateState, name, number]);

  const save = e => {
    e.preventDefault();
    onSave({
      name: stateName,
      estimateNumber: stateNumber
    });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form onSubmit={save}>
        <DialogTitle>Update Estimate Name</DialogTitle>
        <DialogContent>
          <CustomInput
            required
            type="text"
            label="Name"
            value={stateName}
            onChange={val => setStateName(val)}
          />
          <CustomInput
            required
            type="number"
            label="Number"
            value={stateNumber}
            onChange={val => setStateNumber(val)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
