import React from 'react';
import {
  Paper,
  Typography,
  useTheme,
  IconButton
} from 'helpers/themeSafeMui.helper';
import CheckList from './NotificationGroupCheckList.component';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import TitleList from './NotificationGroupTitleList.component';
import UserList from './NotificationGroupUserList.component';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomInput } from 'components';

const NotificationGroup = ({
  group,
  jobTitles,
  saveGroup,
  removeGroup,
  permissions,
  allUsers,
  number
}) => {
  const theme = useTheme();

  const getCurrent = (getId, key, all) =>
    all.length > 0 && group
      ? all.filter(p => group[key].includes(getId(p))).map(p => p.name)
      : [];

  const currentUsers = stateKey =>
    allUsers.length
      ? group[stateKey].map(id => allUsers.find(u => u.id === id))
      : [];

  const currentAddedPermission = getCurrent(
    i => i.value,
    'addedPermissions',
    permissions
  );
  const currentRemovedPermission = getCurrent(
    i => i.value,
    'removedPermissions',
    permissions
  );

  const currentAddedTitles = getCurrent(
    i => i.id,
    'addedPermissionLevelIds',
    jobTitles
  );
  const currentRemovedTitles = getCurrent(
    i => i.id,
    'removedPermissionLevelIds',
    jobTitles
  );

  const pad = () => <div style={{ marginTop: 10 }} />;

  return (
    <Paper style={{ padding: 10 }}>
      <div style={{ display: 'flex' }}>
        <Typography style={{ flexGrow: 1 }} align="center" variant="subtitle1">
          Group #{number}
        </Typography>
        <IconButton onClick={removeGroup}>
          <DeleteIcon />
        </IconButton>
      </div>
      <TitleList
        title={
          group.asSingleActionItem ? 'Single Action Item' : 'Many Action Items'
        }
        list={
          group.asSingleActionItem
            ? ['All users on 1 action item']
            : ['Different action item for each user']
        }
        icon={<CheckCircleOutlinedIcon />}
      >
        {blur => (
          <div style={{ padding: 10 }}>
            <CustomInput
              type={'boolean'}
              label="Single action item"
              value={group.asSingleActionItem}
              onChange={val => {
                if (val !== group.asSingleActionItem)
                  saveGroup({ asSingleActionItem: val }).then(blur);
              }}
            />
          </div>
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Added Permissions'}
        list={currentAddedPermission}
        icon={<CheckCircleOutlinedIcon />}
      >
        {blur => (
          <CheckList
            allItems={permissions}
            currentIds={group.addedPermissions}
            saveGroup={saveGroup}
            stateKey={'addedPermissions'}
            getId={item => item.value}
            getName={item => item.name}
            blur={blur}
          />
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Removed Permissions'}
        list={currentRemovedPermission}
        icon={<CancelOutlinedIcon />}
        color={theme.palette.error.main}
      >
        {blur => (
          <CheckList
            allItems={permissions}
            currentIds={group.removedPermissions}
            saveGroup={saveGroup}
            stateKey={'removedPermissions'}
            getId={item => item.value}
            getName={item => item.name}
            blur={blur}
          />
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Added Job Titles'}
        list={currentAddedTitles}
        icon={<CheckCircleOutlinedIcon />}
      >
        {blur => (
          <CheckList
            allItems={jobTitles}
            currentIds={group.addedPermissionLevelIds}
            saveGroup={saveGroup}
            stateKey={'addedPermissionLevelIds'}
            getId={item => item.id}
            getName={item => item.name}
            blur={blur}
          />
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Removed Job Titles'}
        list={currentRemovedTitles}
        icon={<CancelOutlinedIcon />}
        color={theme.palette.error.main}
      >
        {blur => (
          <CheckList
            allItems={jobTitles}
            currentIds={group.removedPermissionLevelIds}
            saveGroup={saveGroup}
            stateKey={'removedPermissionLevelIds'}
            getId={item => item.id}
            getName={item => item.name}
            blur={blur}
          />
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Added Users'}
        list={currentUsers('addedUserIds').map(u => u.name)}
        icon={<CheckCircleOutlinedIcon />}
      >
        {blur => (
          <UserList
            group={group}
            blur={blur}
            saveGroup={saveGroup}
            allUsers={allUsers}
            stateKey={'addedUserIds'}
          />
        )}
      </TitleList>
      {pad()}
      <TitleList
        title={'Removed Users'}
        list={currentUsers('removedUserIds').map(u => u.name)}
        icon={<CancelOutlinedIcon />}
        color={theme.palette.error.main}
      >
        {blur => (
          <UserList
            group={group}
            blur={blur}
            saveGroup={saveGroup}
            allUsers={allUsers}
            stateKey={'removedUserIds'}
          />
        )}
      </TitleList>
    </Paper>
  );
};

export default NotificationGroup;
