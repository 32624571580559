import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import { ConfirmDelete, CustomInput, Toast } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import {
  RefreshProjectContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import moment from 'moment';

const defaultPhoto = { title: null, notes: null };

export default function EditPhotoForm({ photo, setDisableKeyDown }) {
  const refreshProject = useContext(RefreshProjectContext);
  const { project } = useContext(ProjectContext);
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [statePhoto, setStatePhoto] = useState(photo || { ...defaultPhoto });

  useEffect(() => {
    setStatePhoto(photo ? { ...photo } : { ...defaultPhoto });
    setEditing(false);
    setChanged(false);
  }, [photo]);

  function save() {
    http()
      .put(`/projects/${project.id}/photos/${photo.photoId}`, {
        title: statePhoto.title,
        notes: statePhoto.notes
      })
      .then(() => {
        setEditing(false);
        setChanged(false);
      })
      .then(refreshProject)
      .catch(Toast.showErr);
  }

  function destroy() {
    http()
      .delete(`/projects/${project.id}/photos/${photo.id}`)
      .then(() => {
        setEditing(false);
        setChanged(false);
      })
      .then(refreshProject)
      .catch(Toast.showErr);
  }

  const focusProps = () => ({
    onFocus: () => setDisableKeyDown(true),
    onBlur: () => setDisableKeyDown(false)
  });

  return (
    <Paper style={{ padding: 10, margin: 10, minHeight: 'calc(100% - 20px)' }}>
      <div style={{ position: 'relative' }}>
        {editing ? (
          <>
            <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
              <ConfirmDelete onConfirm={destroy} />
            </div>
            <CustomInput
              value={statePhoto.title || ''}
              onChange={title => {
                setChanged(true);
                setStatePhoto(old => ({ ...old, title }));
              }}
              autoFocus
              label="Title"
              {...focusProps()}
            />
            <CustomInput
              value={statePhoto.notes || ''}
              onChange={notes => {
                setChanged(true);
                setStatePhoto(old => ({ ...old, notes }));
              }}
              label="Notes"
              {...focusProps()}
              multiline
            />
            <div style={{ paddingTop: 10 }} />
            <Button
              onClick={save}
              variant="contained"
              color="primary"
              disabled={!changed}
            >
              Save
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              onClick={() => {
                setStatePhoto(photo ? { ...photo } : { ...defaultPhoto });
                setEditing(false);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <IconButton
              style={{ position: 'absolute', top: -9, right: -9 }}
              onClick={() => setEditing(true)}
            >
              <EditIcon />
            </IconButton>
            <Typography variant="overline">Title</Typography>
            <Typography variant="h6" style={{ padding: '0 10px' }}>
              {statePhoto.title || 'No Title'}
            </Typography>
            <Typography variant="overline">Notes</Typography>
            <Typography
              variant="caption"
              style={{ padding: '0 10px', whiteSpace: 'pre-wrap' }}
              component="div"
            >
              {statePhoto.notes || 'No Notes'}
            </Typography>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ paddingTop: 16 }} />
          <Typography
            style={{ lineHeight: 1.5, marginTop: 5 }}
            variant="overline"
          >
            Date Added
          </Typography>
          <Typography variant="caption" style={{ padding: '0 10px' }}>
            {moment(photo.photoCreated).format('LLL')}
          </Typography>
          <Typography
            style={{ lineHeight: 1.5, marginTop: 5 }}
            variant="overline"
          >
            Added From
          </Typography>
          <Typography variant="caption" style={{ padding: '0 10px' }}>
            {!!photo.plangridPhotoUid ? 'PlanGrid' : 'STEPS'}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}
