import React from 'react';
import {
  TextField,
  MenuItem,
  Paper,
  Link,
  Tooltip
} from 'helpers/themeSafeMui.helper';
import debounce from 'lodash.debounce';
import Downshift from 'downshift';
import { Toast } from 'components';
import AddAddressModal from './modal/AddAddress.modal';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';
import googleIcon from './powered_by_google.png';

function getNearLocation() {
  const TOLALocation = new window.google.maps.LatLng(
    32.76895881687962,
    -96.80587840647726
  );
  const BoiseLocation = new window.google.maps.LatLng(
    43.57727829639,
    -116.287611001
  );

  return isUsingOneDrive() ? TOLALocation : BoiseLocation;
}

export default class AddressPredict extends React.Component {
  state = {
    addressPredictions: [],
    showAddressModal: false,
    loading: false
  };

  autocompleteService = new window.google.maps.places.AutocompleteService();
  geocoder = new window.google.maps.Geocoder();

  debounceId = 0;

  getZipCode = placeId =>
    new Promise((resolve, reject) => {
      this.geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status === 'OVER_QUERY_LIMIT') {
          return resolve('OVER_QUERY_LIMIT');
        }
        if (status !== 'OK') {
          reject({ message: 'Error finding addresses.' });
        }
        if (results && results[0]) {
          resolve(results[0]);
        }
      });
    });

  getPlacePredictions = debounce(val => {
    if (!val) return;
    this.debounceId++;
    const thisDebounce = this.debounceId;
    this.autocompleteService
      .getPlacePredictions(
        {
          input: val,
          types: ['address'],

          location: getNearLocation(),
          radius: 1
        },
        async places => {
          if (!places)
            return this.setState({ loading: false, addressPredictions: [] });
          const placesWithZip = [];
          let geocodeAttempts = 0;

          for (let i = 0; i < places.length; i++) {
            const place = places[i];

            // if user has requested places for a new val
            if (this.debounceId !== thisDebounce) return;

            let placeWithZip;
            try {
              placeWithZip = await this.getZipCode(place.place_id);
            } catch (err) {
              Toast.show(err.message);
              this.setState({
                addressPredictions: [],
                loading: false
              });
              return;
            }

            if (placeWithZip === 'OVER_QUERY_LIMIT') {
              geocodeAttempts++;
              i--;
              if (geocodeAttempts >= 50) break;
              this.setState({ addressPredictions: placesWithZip });
              await new Promise(res => setTimeout(res, 500));
            } else if (placeWithZip !== null) {
              geocodeAttempts = 0;
              placesWithZip.push(placeWithZip);
            } else {
            }
          }

          this.setState({ addressPredictions: placesWithZip, loading: false });
        }
      )
      .catch(err => {
        if (this.debounceId !== thisDebounce) return;
        this.setState({ addressPredictions: [], loading: false });
        Toast.show(
          'Maps encountered an error. Contact support if the problem persists.'
        );
      });
  }, 700);

  render() {
    return (
      <Downshift
        onChange={item => this.props.onChange(item.formatted_address)}
        itemToString={item => (item ? item.formatted_address : '')}
        onInputValueChange={val => {
          this.getPlacePredictions(val);
          this.props.onChange(val);
          this.setState({ loading: true });
        }}
        inputValue={this.props.value}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen
        }) => (
          <div style={{ position: 'relative' }}>
            <AddAddressModal
              open={this.state.showAddressModal}
              onClose={() => this.setState({ showAddressModal: false })}
              onCreate={address => this.props.onChange(address)}
            />

            <TextField
              InputLabelProps={getLabelProps()}
              InputProps={getInputProps({
                endAdornment: (
                  <Tooltip title="Enter address manually.">
                    <div>
                      <Link
                        style={{ cursor: 'pointer', fontSize: 10 }}
                        onClick={() =>
                          this.setState({ showAddressModal: true })
                        }
                      >
                        <AddIcon color="action" />
                      </Link>
                    </div>
                  </Tooltip>
                )
              })}
              fullWidth
              {...this.props}
            />

            {isOpen && (
              <Paper
                {...getMenuProps()}
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: 0,
                  right: 0
                }}
              >
                {this.state.addressPredictions.map(place => (
                  <MenuItem
                    {...getItemProps({ item: place })}
                    key={place.place_id}
                  >
                    {place.formatted_address}
                  </MenuItem>
                ))}
                <div style={{ height: 20 }} />
                <img
                  alt="Powered by Google"
                  style={{
                    height: 15,
                    position: 'absolute',
                    bottom: 5,
                    right: 5
                  }}
                  src={googleIcon}
                />
                {this.state.loading && <LinearProgress />}
              </Paper>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}
