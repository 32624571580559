import React, { useCallback, useContext, useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import mapReducer from 'helpers/mapReducer.helper';
import { ShiftScheduleContext } from './ShiftSchedule.context';

export const ProjectsContext = React.createContext();

export default function ProjectsProvider({ children }) {
  const { projectIds } = useContext(ShiftScheduleContext);
  const [projects, setProjects] = useState([]);
  const [projectMap, setProjectMap] = useState({});

  const [shiftProjectsWithAreas, setShiftProjectsWithAreas] = useState([]);
  const [shiftProjectsWithAreasMap, setShiftProjectsWithAreasMap] = useState(
    {}
  );

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredProjectMap, setFilteredProjectMap] = useState({});

  const fetchProjects = useCallback(() => {
    http()
      .get('/projects')
      .then(projects => {
        setProjects(projects);
        setProjectMap(projects.reduce(mapReducer('id'), {}));
      })
      .catch(Toast.showErr);
  }, []);

  useEffect(() => {
    let stale = false;
    http()
      .put('/projects/query?includeAreas=true', { ids: projectIds })
      .then(projects => {
        if (stale) return;
        const copy = projects.map(project => ({
          ...project,
          areasSet: project.areas.reduce(
            (set, areaId) => ({ ...set, [areaId]: true }),
            {}
          )
        }));
        setShiftProjectsWithAreas(copy);
        setShiftProjectsWithAreasMap(copy.reduce(mapReducer('id'), {}));
      })
      .catch(Toast.showErr);
    return () => {
      stale = true;
    };
  }, [projectIds]);

  useEffect(() => fetchProjects(), [fetchProjects]);

  useEffect(() => {
    setFilteredProjectMap(filteredProjects.reduce(mapReducer('id'), {}));
  }, [filteredProjects]);

  return (
    <ProjectsContext.Provider
      value={{
        projects,
        projectMap,
        fetchProjects,

        shiftProjectsWithAreas,
        shiftProjectsWithAreasMap,

        filteredProjects,
        filteredProjectMap,
        setFilteredProjects
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
}
