import React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  FormControlLabel,
  Radio,
  RadioGroup
} from 'helpers/themeSafeMui.helper';
import { Toast, ChipSelect } from 'components';
import http from 'helpers/http.helper';

export default class CreatePermissionLevel extends React.Component {
  initialState = {
    name: '',
    selectedPermissions: [],
    tier2: false
  };

  state = this.initialState;

  createPermissionLevel = e => {
    e.preventDefault();

    http()
      .post('/permission-levels', {
        name: this.state.name,
        permissions: this.state.selectedPermissions.map(p => p.value),
        tier2: this.state.tier2,
        order: 0
      })
      .then(res => {
        this.setState(this.initialState);
        this.props.onCreate(res);
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: () => this.setState(this.initialState) }}
      >
        <form autoComplete="off" onSubmit={this.createPermissionLevel}>
          <DialogTitle>Add a New Job Title</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the new Job Title, and select the levels of permission that
              you want the job to have.
            </DialogContentText>

            <TextField
              autoFocus
              label="Job Title"
              fullWidth
              required
              value={this.state.name}
              name="name"
              margin="normal"
              onChange={this.handleChange}
            />

            <ChipSelect
              margin="normal"
              label="Permissions"
              selected={this.state.selectedPermissions}
              options={this.props.permissions}
              name="selectedPermissions"
              handleChange={this.handleChange}
            />

            <RadioGroup
              value={this.state.tier2 ? 'tier2' : 'tier1'}
              onChange={e =>
                this.setState({ tier2: e.target.value === 'tier2' })
              }
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="tier1"
                control={<Radio />}
                label="Tier 1"
              />
              <FormControlLabel
                value="tier2"
                control={<Radio />}
                label="Tier 2"
              />
            </RadioGroup>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
