import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// Slice
const actionItemSlice = createSlice({
  name: 'actionItems',
  initialState: {},
  reducers: {
    setActionItem(state, action) {
      const { actionItem } = action.payload;
      state[actionItem.id] = actionItem;
    },
    setActionItems(state, action) {
      const { actionItems } = action.payload;
      actionItems.forEach(actionItem => {
        state[actionItem.id] = actionItem;
      });
    },
    deleteActionItem(state, action) {
      const { id } = action.payload;
      delete state[id];
    }
  }
});

// Selectors
export const selectAll = appState => Object.values(appState.actionItems);

export const selectByIds = ids => appState =>
  ids.map(id => appState.actionItems[id]).filter(item => item);

// Actions
export const { deleteActionItem } = actionItemSlice.actions;

export const setActionItem = ({ actionItem }) => (dispatch, getState) => {
  const { actionItems: allActionItems } = getState();
  dispatch(
    actionItemSlice.actions.setActionItem({ actionItem, allActionItems })
  );
};

export const setActionItems = ({ actionItems }) => (dispatch, getState) => {
  const { actionItems: allActionItems } = getState();
  dispatch(
    actionItemSlice.actions.setActionItems({ actionItems, allActionItems })
  );
};

// Hooks
export const useActionItems = (ids = []) => {
  const dispatch = useDispatch();
  const allActionItems = useSelector(selectAll);
  const selectedActionItems = useSelector(selectByIds(ids));
  return {
    allActionItems,
    selectedActionItems,
    setActionItem: useCallback(
      actionItem => dispatch(setActionItem({ actionItem })),
      [dispatch]
    ),
    setActionItems: useCallback(
      actionItems => dispatch(setActionItems({ actionItems })),
      [dispatch]
    ),
    deleteActionItem: useCallback(id => dispatch(deleteActionItem({ id })), [
      dispatch
    ])
  };
};

export const useActionItem = id => {
  const dispatch = useDispatch();
  const selectedActionItem = useSelector(state => state.actionItems[id]);
  return {
    actionItem: selectedActionItem,
    setActionItem: actionItem => dispatch(setActionItem({ actionItem }))
  };
};

// Reducer
export default actionItemSlice.reducer;
