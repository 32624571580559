import React, { useState } from 'react';
import { OneDriveFolderViewer, GoogleDriveFolderViewer } from 'components';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

const DriveFolderViewer = props => {
  const [usingOneDrive] = useState(() => isUsingOneDrive());
  return usingOneDrive ? (
    <OneDriveFolderViewer {...props} />
  ) : (
    <GoogleDriveFolderViewer {...props} />
  );
};

export default DriveFolderViewer;
