import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';

const Step = props => {
  const {
    children,
    margin,
    height,
    first,
    color,
    textColor = '#ffffff',
    last,
    onClick,
    style,
    TypographyProps
  } = props;

  return (
    <div
      onClick={onClick}
      style={{
        height: height,
        position: 'relative',
        ...style
      }}
    >
      {!last && (
        <div
          style={{
            position: 'absolute',
            right: (height / 2 - margin) * -1,
            top: 0,
            width: 0,
            height: 0,
            borderTop: `${height / 2}px solid transparent`,
            borderBottom: `${height / 2}px solid transparent`,
            borderLeft: `${height / 2 - margin}px solid ${color}`
          }}
        />
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: height,
          background: color,
          color: textColor,
          padding: '8px 16px',
          width: first ? '100%' : `calc(100% - ${height / 2}px)`,
          marginLeft: first ? 0 : height / 2,
          position: 'relative'
        }}
      >
        <Typography {...TypographyProps}>{children}</Typography>
      </div>

      {!first && (
        <div
          style={{
            position: 'absolute',
            left: margin,
            top: 0,
            width: 0,
            height: 0,
            borderTop: `${height / 2}px solid ${color}`,
            borderBottom: `${height / 2}px solid ${color}`,
            borderLeft: `${height / 2 - margin}px solid transparent`
          }}
        />
      )}
    </div>
  );
};

export default Step;
