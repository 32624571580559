import React, { useContext, useState, useCallback, useEffect } from 'react';
import http from 'helpers/http.helper';
import {
  ProjectDetailsContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import {
  Typography,
  Divider,
  ListItemText,
  ListItem,
  ListItemIcon
} from 'helpers/themeSafeMui.helper';
import PlangridRfi from './PlangridRfi.component';
import { Toast, NoItemsResult } from 'components';
import PlangridRfiModal from '../modals/PlangridRfi.modal';
import AddIcon from '@material-ui/icons/Add';

export const PlangridUsersContext = React.createContext([]);
export const PlangridStatusesContext = React.createContext([]);

const PlangridRfis = () => {
  const { projectDetails } = useContext(ProjectDetailsContext);
  const { project } = useContext(ProjectContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [plangridUsers, setPlangridUsers] = useState([]);
  const [plangridStatuses, setPlangridStatuses] = useState([]);
  const [rfis, setRfis] = useState([]);

  const setRfi = id => rfi =>
    setRfis(old => old.map(otherRfi => (otherRfi.uid === id ? rfi : otherRfi)));

  const createRfi = async rfiData => {
    if (!projectDetails.plangridProjectId) return;
    try {
      const data = {
        locked: rfiData.locked,
        title: rfiData.title,
        question: rfiData.question,
        answer: rfiData.answer,
        assignedToUids: rfiData.assignedToUids,
        statusId: rfiData.statusId,
        stepsProjectId: projectDetails.projectId
      };
      if (rfiData.dueAt) data.dueAt = rfiData.dueAt;
      const res = await http().post(
        `/plangrid/rfis/${projectDetails.plangridProjectId}`,
        data
      );
      setModalOpen(false);
      setRfis(rfis => [res, ...rfis]);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const getRfis = useCallback(() => {
    if (projectDetails.plangridProjectId)
      http()
        .get(`/plangrid/rfis/${projectDetails.plangridProjectId}`)
        .then(res => {
          setRfis(res);
        })
        .catch(err => Toast.show(err.message));
  }, [projectDetails.plangridProjectId]);

  const fetchProjectUsers = useCallback(() => {
    if (projectDetails.plangridProjectId) {
      http()
        .get(
          `/plangrid/projects/${projectDetails.projectId}/users?mergeWithStepsUsers=false`
        )
        .then(res => {
          setPlangridUsers(res);
        })
        .catch(err => Toast.show(err.message));
    }
  }, [projectDetails.projectId, projectDetails.plangridProjectId]);

  useEffect(() => {
    fetchProjectUsers();
  }, [fetchProjectUsers]);

  useEffect(() => {
    getRfis();
  }, [getRfis]);

  useEffect(() => {
    http()
      .get(`/plangrid/rfis/${projectDetails.plangridProjectId}/statuses`)
      .then(res => {
        setPlangridStatuses(res);
      })
      .catch(err => Toast.show(err.message));
  }, [projectDetails.plangridProjectId]);

  return (
    <PlangridUsersContext.Provider value={plangridUsers}>
      <PlangridStatusesContext.Provider value={plangridStatuses}>
        <PlangridRfiModal
          formTitle="Create RFI"
          onSubmit={createRfi}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
        <div style={{ padding: 16, paddingTop: 0 }}>
          {projectDetails.plangridProjectId ? (
            <>
              <ListItem button onClick={() => setModalOpen(true)}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'button' }}
                  primary="Create RFI"
                />
              </ListItem>
              {rfis.map(rfi => (
                <PlangridRfi key={rfi.uid} rfi={rfi} setRfi={setRfi(rfi.uid)} />
              ))}
              {!rfis.length && <NoItemsResult type="RFIs" />}
            </>
          ) : (
            <>
              <Typography variant="body1">
                No plangrid project linked to {project.projectNumber} -{' '}
                {project.name}
              </Typography>
              <Divider />
              <Typography style={{ marginTop: 16 }} variant="body1">
                Add a PlanGrid link to the home tab to use PlanGrid RFIs
              </Typography>
            </>
          )}
        </div>
      </PlangridStatusesContext.Provider>
    </PlangridUsersContext.Provider>
  );
};

export default PlangridRfis;
