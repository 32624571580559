import mapReducer from 'helpers/mapReducer.helper';
import { useEffect, useState } from 'react';

export default function useUsersDiff(userIdsPrev, userIdsCurrent) {
  const [removedUserIds, setRemovedUserIds] = useState([]);
  const [addedUserIds, setAddedUserIds] = useState([]);
  const [stayingUserIds, setStayingUserIds] = useState([]);
  const userIdsPrevString = JSON.stringify(userIdsPrev);
  const userIdsCurrentString = JSON.stringify(userIdsCurrent);

  useEffect(() => {
    const userIdsPrev = JSON.parse(userIdsPrevString);
    const userIdsCurrent = JSON.parse(userIdsCurrentString);

    const userIdsPrevSet = userIdsPrev.reduce(mapReducer.set(), {});
    const userIdsCurrentSet = userIdsCurrent.reduce(mapReducer.set(), {});

    const removedUserIds = userIdsPrev.filter(
      prevId => !userIdsCurrentSet[prevId]
    );
    const addedUserIds = userIdsCurrent.filter(
      currentId => !userIdsPrevSet[currentId]
    );
    const stayingUserIds = userIdsPrev.filter(
      prevId => userIdsCurrentSet[prevId]
    );

    setRemovedUserIds(removedUserIds);
    setAddedUserIds(addedUserIds);
    setStayingUserIds(stayingUserIds);
  }, [userIdsPrevString, userIdsCurrentString]);

  return {
    removedUserIds,
    addedUserIds,
    stayingUserIds
  };
}
