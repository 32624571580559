import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import { Rollup } from 'components';
import TeamDisplay from 'routes/Projects/tabs/Team/components/TeamDisplay.component';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import {
  ProjectTeamContext,
  ProjectContext
} from '../../../context/Project.context';
import RequestAccountManagerChange from 'routes/Projects/tabs/Team/modals/RequestAccountManagerChange.modal';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { useSelector } from 'react-redux';
import EmployeeHours from 'routes/Projects/tabs/Team/components/EmployeeHours.component';
import userAssociations, {
  accountManager
} from 'helpers/userAssociations.helper';

export default function ProjectTeamCard() {
  const currentUser = useCurrentUser();
  const adminSettings = useSelector(state => state.adminSettings);
  const projectUserAssociations = userAssociations();
  const { project, setProject } = useContext(ProjectContext);
  const { projectTeam, setProjectTeam } = useContext(ProjectTeamContext);

  const [users, setUsers] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [status, setStatus] = useState(null);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const tier2User = currentUser.hasPermission('projects_tier_2');

  useEffect(() => {
    http()
      .get('/users?includeImage=true')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/account-managers')
      .then(res =>
        setAccountManagers(
          res.map(am => ({
            id: am.userId,
            name: `${am.managerNumber} - ${am.name}`
          }))
        )
      )
      .catch(err => Toast.show(err.message));

    if (project.statusId)
      http()
        .get(`/project-statuses/${project.statusId}`)
        .then(res => setStatus(res))
        .catch(err => Toast.show(err.message));
  }, [adminSettings.sudoCompanyId, project.companyId, project.statusId]);

  const updateProjectTeam = updates => {
    http()
      .put(`/projects/${projectTeam.projectId}/teams`, updates)
      .then(res => setProjectTeam(res))
      .then(() =>
        http()
          .get(`/projects/${project.id}`)
          .then(project => setProject(project))
      )
      .catch(err => Toast.show(err.message));
  };

  function canUpdateAccountManager() {
    if (
      currentUser.hasPermission('super_admin') ||
      currentUser.hasPermission('project_team_tier_2')
    )
      return true;
    if (
      currentUser.hasPermission('projects_tier_2') &&
      !projectTeam.accountManagerId
    )
      return true;
    if (
      status &&
      !status.teamLocked &&
      currentUser.hasPermission('projects_tier_2')
    )
      return true;
    return false;
  }

  const getImage = id => {
    const user = users.find(u => u.id === id);
    return user && user.image;
  };

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Team</Typography>}
      localStorageKey="project_card_team"
    >
      <RequestAccountManagerChange
        open={requestModalOpen}
        onClose={() => setRequestModalOpen(false)}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        {tier2User && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRequestModalOpen(true)}
          >
            Request Change
          </Button>
        )}
      </div>
      {projectUserAssociations.map(({ name, baseKey, editPermission }) => (
        <TeamDisplay
          key={baseKey}
          title={name}
          readOnly={
            baseKey === accountManager.baseKey
              ? !canUpdateAccountManager()
              : !currentUser.hasPermission(editPermission)
          }
          name={projectTeam[baseKey + 'Name']}
          phoneNumber={projectTeam[baseKey + 'PhoneNumber']}
          email={projectTeam[baseKey + 'Email']}
          image={getImage(projectTeam[baseKey + 'Id'])}
          onChange={({ id }) => updateProjectTeam({ [baseKey + 'Id']: id })}
          options={baseKey === accountManager.baseKey ? accountManagers : users}
        />
      ))}
      <div style={{ padding: '20px 0' }}>
        <EmployeeHours noPaper />
      </div>
    </Rollup>
  );
}
