import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export const UsersContext = React.createContext();

const UsersContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [usersMap, setUsersMap] = useState({});

  useEffect(() => {
    http()
      .get(`/users`)
      .then(users => {
        setUsers(users);
        setUsersMap(
          users.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {})
        );
      })
      .catch(err => Toast.show(err.message));
  }, []);
  return (
    <UsersContext.Provider value={{ users, usersMap }}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
