import React, { useState } from 'react';
import Editor from './Editor.component';
import Field from './Field.component';
import { Document, Page } from 'react-pdf';
import EditMenu from './EditMenu.component';
import http from 'helpers/http.helper';
import { Toast } from 'components';

const DocumentComponent = props => {
  const { base64File, signers, fields, document, onUpdateFields } = props;

  const [editMode, setEditMode] = useState(null);
  const [activeSigner, setActiveSigner] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [originalHeight, setOriginalHeight] = useState(1);
  const [originalWidth, setOriginalWidth] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState(1);
  const [fieldType, setFieldType] = useState(null);
  const [activeField, setActiveField] = useState(null);

  const scaleToSave = field => {
    if (!field) return;
    const scale = num =>
      num && wrapperWidth && originalWidth
        ? Math.round((originalWidth * num) / wrapperWidth)
        : num;

    return {
      ...field,
      x: scale(field.x),
      y: scale(field.y),
      width: scale(field.width),
      height: scale(field.height)
    };
  };

  const scaleToDisplay = field => {
    if (!field) return;
    const scale = num =>
      num && wrapperWidth && originalWidth
        ? Math.round((num * wrapperWidth) / originalWidth)
        : num;

    return {
      ...field,
      x: scale(field.x),
      y: scale(field.y),
      width: scale(field.width),
      height: scale(field.height),
      scaledFontSize: scale(field.fontSize)
    };
  };

  const addField = field => {
    setEditMode(null);
    setFieldType(null);

    http()
      .post(`/esign-documents/${document.id}/fields`, scaleToSave(field))
      .then(res => onUpdateFields(res))
      .catch(e => Toast.show(e.message));
  };

  const updateField = field => {
    setEditMode(null);
    setActiveField(null);

    http()
      .put(
        `/esign-documents/${document.id}/fields/${field.id}`,
        scaleToSave({
          signerId: field.signerId,
          x: field.x,
          y: field.y,
          width: field.width,
          height: field.height,
          required: field.required,
          textAlign: field.textAlign,
          fontSize: field.fontSize
        })
      )
      .then(res => onUpdateFields(res))
      .catch(e => Toast.show(e.message));
  };

  const removeField = field => {
    http()
      .delete(`/esign-documents/${document.id}/fields/${field.id}`)
      .then(res => onUpdateFields(res))
      .catch(e => Toast.show(e.message));
  };

  return (
    <div ref={el => el && setWrapperWidth(el.clientWidth)}>
      <EditMenu
        activePage={activePage}
        totalPages={totalPages}
        fieldType={fieldType}
        onFieldTypeChange={fieldType => {
          setFieldType(fieldType);
          setEditMode(fieldType ? 'create' : null);
        }}
        signers={signers}
        activeSigner={activeSigner}
        onPageChange={setActivePage}
        onActiveSignerChange={setActiveSigner}
      />

      <div
        style={{
          width: wrapperWidth,
          minHeight: (wrapperWidth * originalHeight) / originalWidth,
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
          position: 'relative'
        }}
      >
        <Document
          file={'data:application/pdf;base64,' + base64File}
          onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
          loading={null}
        >
          <Editor
            editMode={editMode}
            onCreate={box =>
              addField({
                signerId: activeSigner.id,
                x: box.left,
                y: box.top,
                width: box.width,
                height: box.height,
                pageNumber: activePage,
                type: fieldType,
                required: true,
                textAlign: 'left',
                fontSize: 12
              })
            }
            onUpdate={field => {
              onUpdateFields(
                fields.map(el => (el.id === field.id ? scaleToSave(field) : el))
              );
            }}
            onUpdateEnd={updateField}
            activeField={activeField}
          >
            {fields
              .filter(field => field.pageNumber === activePage)
              .map(field => (
                <Field
                  key={field.id}
                  field={scaleToDisplay(field)}
                  onRemove={removeField}
                  disabled={editMode}
                  signers={signers}
                  onUpdate={updateField}
                  onCopy={field =>
                    addField({
                      signerId: field.signerId,
                      x: field.x + 10,
                      y: field.y + 10,
                      width: field.width,
                      height: field.height,
                      pageNumber: field.pageNumber,
                      type: field.type,
                      required: field.required,
                      textAlign: field.textAlign,
                      fontSize: field.fontSize
                    })
                  }
                  onResizing={field => {
                    setEditMode('resize');
                    setActiveField(field);
                  }}
                  onMoving={field => {
                    setEditMode('move');
                    setActiveField(field);
                  }}
                />
              ))}

            <Page
              renderAnnotationLayer={true}
              pageNumber={activePage}
              loading={null}
              width={wrapperWidth}
              onLoadSuccess={page => {
                if (page.rotate === 90 || page.rotate === 270) {
                  setOriginalHeight(page.originalWidth);
                  setOriginalWidth(page.originalHeight);
                } else {
                  setOriginalHeight(page.originalHeight);
                  setOriginalWidth(page.originalWidth);
                }
              }}
            />
          </Editor>
        </Document>
      </div>
    </div>
  );
};

export default DocumentComponent;
