import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import moment from 'moment';
import { Toast, SearchDropdown } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';

const HelperText = props => {
  const { count } = props;

  return (
    <Typography
      variant="caption"
      style={count < 50 ? { color: 'red' } : { color: 'inherit' }}
    >
      character count: {count}/50
    </Typography>
  );
};

const CreateWeeklyCheckin = props => {
  const { onClose, refresh, userId } = props;
  const [manager, setManager] = useState(null);
  const [ojtHours, setOjtHours] = useState(0);
  const [tasksCompleted, setTasksCompleted] = useState('');
  const [firstDayOfWeek, setFirstDayOfTheWeek] = useState(
    moment()
      .startOf('week')
      .add(1, 'days')
  );
  const [users, setUsers] = useState([]);
  const [tsheetHours, setTsheetHours] = useState(0);

  useEffect(() => {
    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(e => Toast.show(e.message));
  }, []);

  useEffect(() => {
    http()
      .get(`/users/${userId}/weekly-check-ins/tsheet-hours`, {
        params: { date: firstDayOfWeek.format('L') }
      })
      .then(res => setTsheetHours(res.hours))
      .catch(e => Toast.show(e.message));
  }, [firstDayOfWeek, userId]);

  const save = e => {
    e.preventDefault();

    http()
      .post('/users/me/weekly-check-ins', {
        managerId: manager.id,
        ojtHours: ojtHours,
        firstDayOfWeek: firstDayOfWeek,
        tasksCompleted: tasksCompleted
      })
      .then(() => {
        Toast.show('Weekly Check-In was created.');
        onClose();
        refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle>Create New Weekly Check-In</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Lead/Manager"
              items={users}
              onChange={manager => setManager(manager)}
              value={manager}
              itemToString={item => item.name}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Tasks Completed"
              multiline
              fullWidth
              maxRows="4"
              value={tasksCompleted}
              onChange={e => setTasksCompleted(e.target.value)}
              name="tasksCompleted"
              margin="normal"
              required
              inputProps={{ minLength: 50 }}
              helperText={<HelperText count={tasksCompleted.length} />}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="ojtHours"
              label="OJT Hours worked this week"
              required
              fullWidth
              margin="dense"
              value={ojtHours}
              onChange={e => setOjtHours(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker
              format="MM/DD/YYYY"
              autoOk
              name="startDayOfWeek"
              label="First Day of the Week"
              required
              fullWidth
              margin="dense"
              value={firstDayOfWeek}
              onChange={val => setFirstDayOfTheWeek(val)}
              helperText={`${tsheetHours || 'No'} hours logged through TSheets`}
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default CreateWeeklyCheckin;
