import React, { useContext, useEffect, useState } from 'react';
import {
  ColumnsToCSV,
  DashboardWrapper,
  ProjectFilterDropdown,
  Toast
} from 'components';
import ProjectsContextProvider, {
  ProjectsContext
} from './context/projects.context';
import { Paper, Typography } from 'helpers/themeSafeMui.helper';
import StatusFilter from './components/StatusFilter.component';
import SelectedStatuses from './components/SelectedStatuses.component';
import ChartSortingTable from './components/ChartSortingTable.component';
import SelectColumns from './components/SelectColumns.component';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import http from 'helpers/http.helper';
import moment from 'moment';
import projectChartsColumns, {
  CsvOnlyColumns
} from './helpers/projectChartsColumns.helper';
import getFormattedStats from './helpers/getFormattedStats.helper';

function ProjectChartsRouteUnconnected() {
  const { projects, detailsMap } = useContext(ProjectsContext);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [userFilteredProjects, setUserFilteredProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [lastQbUpladDate, setLastQbUpladDate] = useState(null);

  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    'WIP_Report',
    [],
    joi => joi.array().items(joi.string())
  );

  useEffect(() => {
    setFilteredProjects(
      userFilteredProjects.filter(project => {
        if (selectedStatuses.length === 0) return true;
        if (selectedStatuses.includes(project.statusId)) return true;
        return false;
      })
    );
  }, [selectedStatuses, userFilteredProjects]);

  useEffect(() => {
    http()
      .get('/projects/quickbooks-import/newest')
      .then(setLastQbUpladDate)
      .catch(Toast.showErr);
  }, []);

  const csvColumns = projectChartsColumns.filter(col =>
    selectedColumns.includes(col.key)
  );
  csvColumns.splice(1, 0, ...CsvOnlyColumns);

  return (
    <DashboardWrapper>
      <div
        style={{
          height: 'calc(100vh - 105px)',
          maxHeight: 'calc(100vh - 105px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8
          }}
        >
          <div>
            <Typography component="h1" variant="h4">
              WIP Report
            </Typography>

            <Typography variant="caption" color="textSecondary">
              imported from QB on {moment(lastQbUpladDate).format('l')}
            </Typography>
          </div>
          <SelectColumns
            allColumns={allColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
          <StatusFilter
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
          />
          <ColumnsToCSV
            columns={csvColumns}
            rows={filteredProjects.map(project => ({
              ...project,
              tableData: getFormattedStats(detailsMap[project.id])
            }))}
            title="STEPS-WIP-REPORT"
          />
          <ProjectFilterDropdown
            projects={projects}
            onUpdate={setUserFilteredProjects}
            localStorageRootKey="project_charts"
          />
        </div>
        <SelectedStatuses selectedStatuses={selectedStatuses} />
        <Paper
          style={{ flex: '0 1 100%', overflowY: 'auto', position: 'relative' }}
          elevation={1}
        >
          <ChartSortingTable
            projects={filteredProjects}
            allColumns={allColumns}
            setAllColumns={setAllColumns}
            selectedColumns={selectedColumns}
          />
        </Paper>
      </div>
    </DashboardWrapper>
  );
}

const ProjectChartsRoute = () => (
  <ProjectsContextProvider>
    <ProjectChartsRouteUnconnected />
  </ProjectsContextProvider>
);

export default ProjectChartsRoute;
