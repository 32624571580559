import React, { useContext, useEffect, useState } from 'react';
import { SelectionSortingTable } from 'components';
import { ProjectsContext } from '../context/projects.context';
import { useHistory } from 'react-router-dom';
import columns from '../helpers/projectChartsColumns.helper';
import getFormattedStats from '../helpers/getFormattedStats.helper';

export default function ChartSortingTable({
  projects,
  setAllColumns,
  selectedColumns
}) {
  const [projectsWithDetails, setProjectsWithDetails] = useState([]);
  const { detailsMap } = useContext(ProjectsContext);
  const history = useHistory();

  useEffect(() => {
    setProjectsWithDetails(
      projects.map(project => ({
        ...project,
        tableData: getFormattedStats(detailsMap[project.id])
      }))
    );
  }, [projects, detailsMap]);

  useEffect(() => {
    setAllColumns(columns.map(({ key, label }) => ({ key, label })));
  }, [setAllColumns]);

  return (
    <SelectionSortingTable
      columns={columns.filter(
        column =>
          !selectedColumns.length || selectedColumns.includes(column.key)
      )}
      rows={projectsWithDetails}
      isSelecting={false}
      stickyHeader
      TableProps={{ size: 'small' }}
      rootStyles={{
        height: `100%`
      }}
      rowAction={project => history.push(`/projects/${project.id}`)}
      localStorageKey="users.table"
      checkboxCellProps={{ padding: 'none' }}
      iconProps={{ fontSize: 'inherit' }}
      initialRowsPerPage={50}
      addEmptyRows={false}
    />
  );
}
