import React, { useState } from 'react';
import { Toast, EmailSearch } from 'components';
import http from 'helpers/http.helper';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent
} from 'helpers/themeSafeMui.helper';

export default function SendEmailModal(props) {
  const { onClose, workOrderId, projectId } = props;
  const [contacts, setContacts] = useState([]);

  const emailCopyToCustomer = () => {
    http()
      .post(`/projects/${projectId}/work-orders/${workOrderId}/send`, {
        emails: contacts.map(c => c.email)
      })
      .then(res => {
        Toast.show(res.message);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form onSubmit={emailCopyToCustomer}>
      <DialogTitle id="simple-dialog-title">
        Send Copy of Work Order
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <EmailSearch
          TextFieldProps={{
            fullWidth: true,
            margin: 'normal',
            label: 'Recipients'
          }}
          required
          onChange={contacts => setContacts(contacts)}
          selected={contacts}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={emailCopyToCustomer}
        >
          Send
        </Button>
      </DialogActions>
    </form>
  );
}
