import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from 'helpers/themeSafeMui.helper';
import ShiftCard from '../components/ShiftCard.component';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import useModified from 'hooks/useModified.hook';
import mapReducer from 'helpers/mapReducer.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export default function ApproveByArea({ open, onClose, shiftIds }) {
  const { shiftMap, fetchSchdeules } = useContext(ShiftScheduleContext);

  const groupedByProject = useModified(
    shiftIds => {
      if (!shiftIds || !shiftMap) return [];
      const shiftDays = shiftIds.map(id => shiftMap[id]).filter(day => day);
      const mappedByProject = shiftDays.reduce(
        mapReducer.array('projectId'),
        []
      );
      return Object.keys(mappedByProject).map(
        projectId => mappedByProject[projectId]
      );
    },
    shiftIds,
    [shiftMap]
  );

  const approveShifts = () => {
    http()
      .put('/shift-days/bulk-approve', { shiftIds })
      .then(fetchSchdeules)
      .then(() => {
        Toast.show('Shifts Approved');
        onClose();
      })
      .catch(Toast.showErr);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Approve Shifts:</DialogTitle>
      <Divider />
      <DialogContent>
        {groupedByProject.map(
          (shiftGroup, idx) =>
            shiftGroup.length && (
              <React.Fragment key={shiftGroup[0].id}>
                {idx !== 0 && (
                  <div style={{ padding: '16px 0' }}>
                    <Divider />
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  {shiftGroup &&
                    shiftGroup.map(shiftDay => (
                      <div key={shiftDay.id} style={{ width: 400, padding: 5 }}>
                        <ShiftCard withDate noEdit shiftDay={shiftDay} />
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={approveShifts}>
          Approve
        </Button>
        <Button variant="contained" onClick={onClose}>
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
