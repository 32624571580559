import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, SearchDropdown } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import theme, { useColors } from 'helpers/theme.helper';

const Reviewers = props => {
  const { document } = props;
  const [reviewers, setReviewers] = useState([]);
  const [users, setUsers] = useState([]);
  const [people, setPeople] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const colors = useColors();

  useEffect(() => {
    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(e => Toast.show(e.message));

    http()
      .get('/people')
      .then(res => setPeople(res))
      .catch(e => Toast.show(e.message));
  }, []);

  useEffect(() => {
    http()
      .get(`/esign-documents/${document.id}/reviewers`)
      .then(res => setReviewers(res))
      .catch(e => Toast.show(e.message));
  }, [document.id]);

  const handleSubmit = () => {
    http()
      .post(`/esign-documents/${document.id}/reviewers`, {
        name,
        email
      })
      .then(res => setReviewers(res))
      .catch(e => Toast.show(e.message));
  };

  const handleDelete = id => {
    http()
      .delete(`/esign-documents/${document.id}/reviewers/${id}`)
      .then(res => setReviewers(res))
      .catch(e => Toast.show(e.message));
  };

  return (
    <div style={{ width: '100%', marginTop: 32 }}>
      <Typography variant="h5" gutterBottom>
        Reviewers
      </Typography>

      {!reviewers.length && (
        <Typography align="center" variant="body2" style={{ margin: '16px 0' }}>
          <strong>No document reviewers found...</strong>
        </Typography>
      )}

      {reviewers.map((reviewer, idx) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: colors.paper,
            padding: 10,
            marginBottom: 8,
            boxShadow: theme.shadows[1]
          }}
          key={reviewer.id}
        >
          <div>
            <Typography>{reviewer.name}</Typography>
            <Typography variant="caption">{reviewer.email}</Typography>
          </div>

          <DeleteIcon
            style={{ marginLeft: 10, cursor: 'pointer' }}
            color="action"
            fontSize="small"
            onClick={() => handleDelete(reviewer.id)}
          />
        </div>
      ))}

      <Button
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
        onClick={() => setModalOpen(true)}
        style={{ margin: '8px 0' }}
      >
        + Add Reviewer
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
            setName('');
            setEmail('');
            setModalOpen(false);
          }}
          onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
        >
          <DialogTitle>Add Document Reviewer</DialogTitle>
          <DialogContent>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Name"
              autoFocus
              items={[...users, ...people]}
              itemToString={item => item.name}
              onChange={person => {
                const emails = person.email
                  ? [person.email]
                  : person.emails.map(email => email.email);
                setName(person.name);
                setEmails(emails);
                setEmail(emails[0]);
              }}
            />

            <FormControl fullWidth margin="normal" disabled={!name}>
              <InputLabel>Email</InputLabel>
              <Select value={email} onChange={e => setEmail(e.target.value)}>
                {emails.map(email => (
                  <MenuItem value={email} key={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Reviewers;
