import React, { useState } from 'react';
import EditEmployeeSkill from '../modals/EditEmployeeSkill.modal';
import CreateEmployeeSkill from '../modals/CreateEmployeeSkill.modal';
import ChildSkill from './ChildSkill.component';
import EditIcon from '@material-ui/icons/Edit';
import SortUpIcon from '@material-ui/icons/ArrowUpwardRounded';
import SortDownIcon from '@material-ui/icons/ArrowDownwardRounded';
import { Rollup } from 'components';
import {
  Typography,
  Grid,
  IconButton,
  Button
} from 'helpers/themeSafeMui.helper';

const ParentSkill = ({
  skill,
  onCreate,
  childSkills,
  onReorder,
  updateSkill,
  deleteSkill,
  orderSkills
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  return (
    <Grid container style={{ margin: '10px 0' }}>
      <EditEmployeeSkill
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        skill={skill}
        updateSkill={updateSkill}
        deleteSkill={deleteSkill}
      />

      <CreateEmployeeSkill
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        parentId={skill.id}
        onCreate={onCreate}
        nextOrder={childSkills[0]?.order - 1}
      />

      <IconButton size="small" onClick={() => onReorder(-1)}>
        <SortUpIcon />
      </IconButton>
      <IconButton size="small" onClick={() => onReorder(1)}>
        <SortDownIcon />
      </IconButton>

      <Rollup
        dense={false}
        style={{ flex: 1, margin: '0 10px' }}
        title={
          <Typography style={{ marginLeft: 10 }} variant="h6">
            {skill.name}
          </Typography>
        }
      >
        {childSkills.map((skill, idx) => (
          <ChildSkill
            key={skill.id}
            skill={skill}
            onReorder={direction => orderSkills(childSkills, idx, direction)}
            updateSkill={updateSkill}
            deleteSkill={deleteSkill}
          />
        ))}

        {!childSkills.length && <Typography>No child skills found.</Typography>}

        <Button
          fullWidth
          color="primary"
          onClick={() => setCreateModalOpen(true)}
        >
          Create Child Skill
        </Button>
      </Rollup>

      <IconButton size="small" onClick={() => setEditModalOpen(true)}>
        <EditIcon />
      </IconButton>
    </Grid>
  );
};

export default ParentSkill;
