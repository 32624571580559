import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TimePickerView } from '@material-ui/pickers';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import usePopper from 'hooks/usePopper.hook';
import moment from 'moment';

const dTime = moment()
  .set({ hour: 7 })
  .startOf('hour');

const allTypes = ['hours', 'minutes', 'seconds'];

export default function TimePickerButton({
  onTimeSelected,
  children,
  title,
  startType = 'hours',
  ignoreTypes = [],
  defaultTime = null,
  ...ButtonProps
}) {
  const types = allTypes.filter(type => !ignoreTypes.includes(type));
  const { Popper, handleClick, close, open } = usePopper();
  const [time, setTime] = useState(() => moment(dTime));
  const [currentType, setCurrentType] = useState(types[0]);
  const defaultTimeRef = useRef();
  defaultTimeRef.current = defaultTime || dTime;

  const reset = useCallback(() => {
    close();
    setTime(moment(defaultTimeRef.current));
    setCurrentType('hours');
  }, [close]);

  useEffect(() => {
    if (open) reset();
  }, [open, reset]);

  const select = m => {
    m = moment(m || time);
    onTimeSelected(moment(m));
    reset();
  };

  const updateTime = type => m => {
    const nextTypeIdx = types.indexOf(type) + 1;
    const nextType = types[nextTypeIdx];
    setTime(moment(m));
    if (!nextType) {
      setCurrentType(types[0]);
    } else {
      setCurrentType(nextType);
    }
  };

  function toggleAmPm() {
    setTime(old => {
      const hour = old.hour();
      if (hour >= 12) return moment(old).set({ hour: hour - 12 });
      return moment(old).set({ hour: hour + 12 });
    });
  }

  return (
    <>
      <Button {...ButtonProps} onClick={handleClick}>
        {children}
      </Button>
      <Popper
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: 10 }}>
          {!!title && (
            <Typography style={{ padding: '0 16px' }} variant="h5">
              {title}
            </Typography>
          )}
          <div onClick={e => e.stopPropagation()}>
            <TimePickerView
              minutesStep={5}
              date={time}
              type={currentType}
              onHourChange={updateTime('hours')}
              onMinutesChange={updateTime('minutes')}
              onSecondsChange={updateTime('seconds')}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              color="textSecondary"
              style={{ textAlign: 'center' }}
              variant="h6"
            >
              {moment(time).format('hh:mm A')}
            </Typography>
            <Button style={{ marginLeft: 16 }} onClick={toggleAmPm}>
              AM / PM
            </Button>
          </div>

          <div
            style={{
              paddingTop: 5,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button onClick={reset} variant="contained">
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={() => select()}
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </div>
        </div>
      </Popper>
    </>
  );
}
