import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Grid
} from 'helpers/themeSafeMui.helper';
import { DateTimePicker } from '@material-ui/pickers';
import http from 'helpers/http.helper';
import {
  Toast,
  SearchDropdown,
  CustomInput,
  AddressPredict,
  ConfirmDelete
} from 'components';

export default function EditWorkOrderModal(props) {
  const { onClose, workOrder, setWorkOrder, users, getWorkOrders } = props;
  const [scheduledDateTime, setScheduledDateTime] = useState(
    workOrder.scheduledDateTime
  );
  const [assignedUser, setAssignedUser] = useState(
    users.find(item => item.id === workOrder.assignedUserId)
  );
  const [projectScope, setProjectScope] = useState(workOrder.projectScope);
  const [name, setName] = useState(workOrder.name);
  const [jobsiteAddress, setJobsiteAddress] = useState(
    workOrder.jobsiteAddress || ''
  );
  const [onSiteContact, setOnSiteContact] = useState({
    name: workOrder.onSiteContactName || '',
    phone: workOrder.onSiteContactPhone || '',
    email: workOrder.onSiteContactEmail || ''
  });

  const deleteWorkOrder = () => {
    return http()
      .delete(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}`)
      .then(getWorkOrders)
      .then(onClose)
      .catch(err => Toast.show(err.message));
  };

  const save = e => {
    e.preventDefault();

    http()
      .put(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}`, {
        name,
        scheduledDateTime: scheduledDateTime,
        assignedUserId: assignedUser.id,
        projectScope: projectScope,
        jobsiteAddress,
        onSiteContactName: onSiteContact.name,
        onSiteContactPhone: onSiteContact.phone,
        onSiteContactEmail: onSiteContact.email
      })
      .then(res => {
        Toast.show('Work order updated.');
        setWorkOrder(res);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>Edit Work Order</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Work Order Name"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 16 }}>
              <AddressPredict
                label="Job Site Address"
                onChange={jobsiteAddress => setJobsiteAddress(jobsiteAddress)}
                value={jobsiteAddress}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Assigned Technician"
              itemToString={item => item.name}
              items={users}
              value={assignedUser}
              onChange={user => setAssignedUser(user)}
              required
            />
          </Grid>

          <Grid item xs={9}>
            <CustomInput
              label="Job Site Contact Name"
              value={onSiteContact.name}
              onChange={name => setOnSiteContact(old => ({ ...old, name }))}
            />
            <CustomInput
              label="Job Site Contact Phone Number"
              value={onSiteContact.phone}
              onChange={phone => setOnSiteContact(old => ({ ...old, phone }))}
            />
            <CustomInput
              label="Job Site Contact Email"
              value={onSiteContact.email}
              onChange={email => setOnSiteContact(old => ({ ...old, email }))}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Project Scope"
              variant="outlined"
              multiline
              fullWidth
              rows="4"
              value={projectScope}
              onChange={e => setProjectScope(e.target.value)}
              margin="normal"
              required
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePicker
              format="MM/DD/YYYY hh:mm a"
              autoOk
              label="Scheduled Date and Time"
              required
              fullWidth
              margin="dense"
              value={scheduledDateTime}
              onChange={scheduledDateTime =>
                setScheduledDateTime(scheduledDateTime)
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <ConfirmDelete onConfirm={deleteWorkOrder} />
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit">
          save
        </Button>
      </DialogActions>
    </form>
  );
}
