import React, { useRef } from 'react';
import { matchSorter, MatchSorterOptions } from 'match-sorter';
import { useEffect, useState } from 'react';
import VirtualizedList, {
  VirtualizedListChildFunction,
  VirtualizedListProps
} from '../VirtualizedList/VirtualizedList.component';
import SearchIcon from '@material-ui/icons/Search';
import {
  ClickAwayListener,
  Grow,
  InputAdornment,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
  Typography
} from 'helpers/themeSafeMui.helper';
import NoItemsResult from 'components/NoItemsResult/NoItemsResult.component';
import useFreshFn from 'hooks/useFreshFn.hook';
import { FixedSizeList } from 'react-window';

interface VirtualizedSearchProps {
  allItems: any[];
  getItemText?: (item: any) => any;
  label?: string;
  matchSorterOptions?: MatchSorterOptions<any>;
  onChange?: (item: any) => any;
  VirtualizedListProps?: VirtualizedListProps;
  TextFieldProps?: TextFieldProps;
  MenuProps?: MenuProps;
  MenuItemProps?: MenuItemProps;
  children?: VirtualizedListChildFunction;
  inputValue?: string;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  showInputAsOption?: boolean;
  makeItemFromInput?: (input: string) => any;
  autoselectTop?: boolean;
  boundariesElement?: HTMLElement;
  onSelect?: (item: any) => any;
}

const VirtualizedSearch: React.FC<VirtualizedSearchProps> = ({
  allItems,
  getItemText = (item: any) => item,
  label = '',
  matchSorterOptions,
  onChange: onChangeProp = () => {},
  VirtualizedListProps = {},
  TextFieldProps = {},
  MenuItemProps = {},
  children = null,
  inputValue: inputValueProp = null,
  setInputValue: setInputValueProp = null,
  showInputAsOption = false,
  makeItemFromInput = (item: any) => item,
  autoselectTop = false,
  boundariesElement = null,
  onSelect = () => {}
}) => {
  const [matchedOptions, setMatchedOptions] = useState([] as any[]);
  const [matchedOptionsWithInput, setMatchedOptionsWithInput] = useState(
    [] as any[]
  );
  let [inputValue, setInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null as Element | null);
  const [selectedItem, setSelectedItem] = useState(null as null | any);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<FixedSizeList<any> | null>(null);

  if (inputValueProp !== null && setInputValueProp !== null) {
    inputValue = inputValueProp;
    setInputValue = setInputValueProp;
  }

  const freshGetItemText = useFreshFn(getItemText);
  const freshMakeItemFromInput = useFreshFn(makeItemFromInput);

  useEffect(() => {
    if (!inputValue || !inputValue.length) {
      setMatchedOptions([]);
      return;
    }

    setMatchedOptions(
      matchSorter(
        allItems,
        inputValue,
        matchSorterOptions || { keys: [freshGetItemText] }
      )
    );
  }, [allItems, matchSorterOptions, inputValue, freshGetItemText]);

  useEffect(() => {
    if (showInputAsOption) {
      // add input as top list option
      const topOption = matchedOptions[0] || null;
      const topOptionText = topOption ? freshGetItemText(topOption) : null;
      if (topOptionText === inputValue || inputValue === '') {
        setMatchedOptionsWithInput([...matchedOptions]);
      } else {
        setMatchedOptionsWithInput([
          freshMakeItemFromInput(inputValue),
          ...matchedOptions
        ]);
      }
    }
  }, [
    showInputAsOption,
    freshMakeItemFromInput,
    inputValue,
    matchedOptions,
    freshGetItemText
  ]);

  const options = showInputAsOption ? matchedOptionsWithInput : matchedOptions;

  useEffect(() => {
    setSelectedIndex(old => {
      if (old === null) {
        if (autoselectTop && options.length > 0) {
          return 0;
        } else {
          return null;
        }
      }
      if (old < 0 || options.length === 0) return null;
      if (old >= options.length) return options.length - 1;
      return old;
    });
  }, [autoselectTop, options.length]);

  const handleUserInput = (inputValue: string) => {
    if (showInputAsOption) onChangeProp(makeItemFromInput(inputValue));
    if (selectedItem) {
      setSelectedItem(null);
      if (!showInputAsOption) onChangeProp(null);
    }
    if (inputValue.length) {
      setAnchorEl(textFieldRef.current);
    } else {
      setAnchorEl(null);
    }
    setInputValue(inputValue);
  };

  const onChange = (item: any) => () => {
    setInputValue(getItemText(item));
    handleClose();
    onChangeProp(item);
    setSelectedItem(item);
    onSelect(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemHeight = VirtualizedListProps.itemHeight || 30;
  const resultWindowHeight =
    options.length === 0
      ? 80
      : Math.min(itemHeight * options.length, itemHeight * 10);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedIndex !== null && options[selectedIndex]) {
      onChange(options[selectedIndex])();
    } else {
      setInputValue('');
      handleClose();
      onChangeProp(null);
      setSelectedItem(null);
    }
  };

  const handleArrow = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const moveCursor = (amount: number) => {
      e.preventDefault();
      let nextIndex = selectedIndex;
      if (options.length === 0) return setSelectedIndex(null);
      if (nextIndex === null) nextIndex = amount === 1 ? -1 : 0;
      nextIndex += amount;
      nextIndex += options.length;
      nextIndex %= options.length;
      if (listRef.current) listRef.current.scrollToItem(nextIndex, 'smart');
      return setSelectedIndex(nextIndex);
    };
    if (e.key === 'ArrowUp') moveCursor(-1);
    if (e.key === 'ArrowDown') moveCursor(1);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          label={label}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
          {...TextFieldProps}
          value={inputValue}
          onChange={e => handleUserInput(e.target.value)}
          onKeyDown={handleArrow}
          inputRef={textFieldRef}
        />
      </form>

      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        role={undefined}
        modifiers={{
          flip: {
            enabled: true,
            options: {
              altBoundary: false,
              rootBoundary: 'viewport',
              padding: 8
            }
          },
          preventOverflow: {
            enabled: !!boundariesElement,
            boundariesElement
          },
          hide: {
            enabled: false
          }
        }}
        transition
        disablePortal
        style={{ zIndex: 10 }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{
                    zIndex: 1,
                    minWidth: textFieldRef.current
                      ? textFieldRef.current.clientWidth
                      : 0,
                    height: resultWindowHeight
                  }}
                >
                  {options.length === 0 ? (
                    <NoItemsResult
                      style={{ marginTop: 0, padding: '10px 0' }}
                      type="results"
                    />
                  ) : (
                    <VirtualizedList
                      {...VirtualizedListProps}
                      itemHeight={itemHeight}
                      items={options}
                      ref={listRef}
                    >
                      {({ item, style, index }) => (
                        <MenuItem
                          {...MenuItemProps}
                          style={{ ...MenuItemProps.style, ...style }}
                          onClick={onChange(item)}
                          selected={selectedIndex === index}
                          button
                        >
                          {children ? (
                            children({ item, style: {}, index })
                          ) : (
                            <Typography noWrap>{getItemText(item)}</Typography>
                          )}
                        </MenuItem>
                      )}
                    </VirtualizedList>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default VirtualizedSearch;
