import {
  Button,
  LinearProgress,
  Typography
} from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import PropTypes from 'prop-types';
import ImageCard from './components/ImageCard.component';

function ImageUploader({
  url,
  onSave = () => {},
  handleSubmit = null,
  previewType
}) {
  const [files, setFiles] = useState(null);
  const [dataUrls, setDataUrls] = useState([]);
  const [over, setOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const colors = useColors();

  const upload = () => {
    if (!files) return;
    if (handleSubmit) {
      handleSubmit(files);
      setFiles(null);
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('images', file);
    }
    setLoading(true);
    http({ withLoader: false })
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(onSave)
      .then(() => {
        setLoading(false);
        setFiles(null);
      });
  };

  const onDrop = files => {
    setOver(false);
    setFiles(old => (old ? [...old, ...files] : files));
  };

  useEffect(() => {
    let stale = false;
    if (!files) {
      setDataUrls([]);
      return;
    }
    filesToDataUrls(files)
      .then(urls => (stale ? null : setDataUrls(urls)))
      .catch(() => Toast.show('Unable to preview image.'));
    return () => {
      stale = true;
    };
  }, [files]);

  function removeAt(idx) {
    setFiles(files => {
      const copy = [...files];
      copy.splice(idx, 1);
      return copy;
    });
  }

  return (
    <div>
      <Dropzone
        multiple
        accept="image/*"
        disabled={loading}
        onDrop={onDrop}
        noKeyboard
        onDragEnter={() => setOver(true)}
        onDragLeave={() => setOver(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              style={{
                width: '100%',
                minHeight: 300,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: over ? colors.grey : colors.lightGrey
              }}
            >
              {loading && (
                <div style={{ width: '100%' }}>
                  <LinearProgress />
                </div>
              )}
              <div>
                <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
                  Select Photos
                </Typography>
                <Typography>Choose a photo or drag it here.</Typography>
              </div>
              {!!files && !!files.length && !!dataUrls.length && (
                <div style={{ overflowX: 'auto', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      ...(previewType === 'wrap' && {
                        flexWrap: 'wrap',
                        justifyContent: 'space-around'
                      })
                    }}
                  >
                    {dataUrls.map((src, idx) => (
                      <ImageCard
                        disabled={loading}
                        dataUrl={src}
                        file={files[idx]}
                        onRemoveClick={e => {
                          e.stopPropagation();
                          removeAt(idx);
                        }}
                        key={src + idx}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={upload}
        disabled={loading || !files || !files.length}
        style={{ marginTop: 10 }}
      >
        Upload
      </Button>
    </div>
  );
}

async function filesToDataUrls(files) {
  return Promise.all(
    files.map(
      file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
    )
  );
}

ImageUploader.propTypes = {
  url: PropTypes.string,
  onSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  previewType: PropTypes.oneOf(['scroll', 'wrap'])
};

export default ImageUploader;
