import React, { useCallback, useRef } from 'react';
import { PdfLibComponent } from 'components';
import PickListPdfHelper from '../helpers/PickListPdf.helper';

export default function PickListPdf({
  po,
  items,
  debounceDelay = 0,
  onDocUpdate = null,
  altHeader = '',
  hideColumnIdxs = null,
  materialStatuses
}) {
  const staleRef = useRef(0);
  const createPdf = useCallback(
    async (
      /** @type {import('pdf-lib').PDFDocument} */
      doc
    ) => {
      const staleId = ++staleRef.current;
      await new Promise(res => setTimeout(res, debounceDelay));
      if (staleRef.current !== staleId) return doc;
      if (!po || !items) return doc;
      const helper = new PickListPdfHelper({
        doc,
        po,
        items,
        altHeader,
        hideColumnIdxs: hideColumnIdxs ? hideColumnIdxs : [],
        materialStatuses
      });
      await helper.build();
      if (onDocUpdate) onDocUpdate(doc);
      return doc;
    },
    [
      po,
      items,
      debounceDelay,
      altHeader,
      onDocUpdate,
      hideColumnIdxs,
      materialStatuses
    ]
  );

  return <PdfLibComponent createPdf={createPdf} />;
}
