import React from 'react';
import { useColors } from 'helpers/theme.helper';

const ScheduleTableCell = React.memo(
  ({
    children,
    firstRow = false,
    firstCol = false,
    width = 50,
    height = 50,
    style = {},
    boldBorderBottom = false
  }) => {
    const colors = useColors();
    const borderWidth =
      (firstRow ? '1px' : '0') + ' 1px 1px ' + (firstCol ? '1px' : '0');

    const borderColor = colors.grey;
    const borderBottomColor = boldBorderBottom ? colors.paperText : borderColor;

    return (
      <div
        style={{
          height,
          flex: `0 0 ${width}px`,
          borderWidth,
          borderStyle: 'solid',
          borderColor,
          background: colors.paper,
          color: colors.paperText,
          borderBottomColor,
          ...style
        }}
      >
        {children}
      </div>
    );
  }
);

export default ScheduleTableCell;
