import axios from 'axios';
import pubsub from 'helpers/pubsub.helper';
import { Toast } from 'components';
import { env } from 'env';

const http = ({ withLoader = true } = {}) => {
  if (withLoader) {
    pubsub.publish('LOADING_START');
  }

  const instance = axios.create({
    baseURL: env.apiUrl,
    headers: {
      Authorization: window.localStorage.getItem('token')
    }
  });

  instance.interceptors.response.use(
    response => {
      if (withLoader) {
        pubsub.publish('LOADING_END');
      }
      if (versionMismatch(response)) {
        Toast.show(
          'A new version of STEPS is available. Please reload the page.'
        );
      }
      return response.data;
    },
    error => {
      if (withLoader) {
        pubsub.publish('LOADING_END');
      }

      if (versionMismatch(error.response)) {
        return Promise.reject({
          message:
            'Oops! Something went wrong. Please reload the page and try again'
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return Promise.reject(error.response.data);
      } else if (!navigator.onLine) {
        return Promise.reject({
          message:
            'STEPS was unable to connect to the internet. Please check your connection.'
        });
      } else {
        return Promise.reject({
          message:
            'Oops! Looks like a server error. Contact support if the problem persists.'
        });
      }
    }
  );

  return instance;
};

function versionMismatch(response) {
  const currentVersion = window.sessionStorage.getItem('stepsVersion');
  return (
    response &&
    response.headers &&
    currentVersion &&
    currentVersion !== response.headers['steps-version']
  );
}

export default http;
