import React from 'react';
import {
  Dialog,
  DialogActions,
  styled,
  Button
} from 'helpers/themeSafeMui.helper';
import PoPdf from '../components/PoPdf.component';

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function PdfModal({ open, onClose, po, items }) {
  return (
    <FullHeightDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      {!!po && <PoPdf po={po} items={items} />}
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Back
        </Button>
      </DialogActions>
    </FullHeightDialog>
  );
}
