import React, { useEffect, useState } from 'react';
import { MaterialsTable, NoStyleLink, SearchBar, Switch } from 'components';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import ReplaceMaterialModal from '../modals/ReplaceMaterial.modal';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

const hiddenColumns = MaterialsTable.presetHiddenColumns.ConflictList;
const TOP_CONTENT_HEIGHT = 60;

export default function MaterialsConflictsRoute({
  conflicts: allConflicts,
  setConflictHidden,
  setReplaceWith
}) {
  const [targetMaterial, setTargetMaterial] = useState(null);
  const [replacementOptions, setReplacementOptions] = useState([]);

  const lsState = useLocalStorage([
    'showing-hidden-material-conflicts',
    false,
    joi => joi.boolean()
  ]);
  const [showHiddenConflicts, setShowHiddenConflicts] = lsState;
  const [unhiddenConflicts, setUnhiddenConflicts] = useState([]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    const unhiddenConflicts = allConflicts.filter(
      material => !material.conflictHidden
    );
    setUnhiddenConflicts(unhiddenConflicts);
  }, [allConflicts]);

  useEffect(() => {
    if (targetMaterial) {
      setReplacementOptions(
        allConflicts.filter(
          otherMaterial =>
            otherMaterial.id !== targetMaterial.id &&
            otherMaterial.partNumber === targetMaterial.partNumber &&
            !otherMaterial.conflictReplaceWith
        )
      );
    } else {
      setReplacementOptions(old => (old.length ? [] : old));
    }
  }, [allConflicts, targetMaterial]);

  const closeModal = () => {
    setTargetMaterial(null);
    setReplacementOptions([]);
  };

  const onReplace = (targetId, replacementId) => {
    return setReplaceWith(targetId, replacementId).then(closeModal);
  };

  const onSetHidden = (materialId, hidden) => {
    return setConflictHidden(materialId, hidden).then(closeModal);
  };

  return (
    <>
      <ReplaceMaterialModal
        open={!!targetMaterial}
        onClose={closeModal}
        onReplace={onReplace}
        onSetHidden={onSetHidden}
        targetMaterial={targetMaterial}
        replacementOptions={replacementOptions}
      />
      <div
        style={{
          height: TOP_CONTENT_HEIGHT,
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start'
        }}
      >
        <One3rd content="left">
          <Typography variant="h4">Part Number Conflicts</Typography>
        </One3rd>
        <One3rd content="center">
          <Switch
            label="Hidden Conflicts"
            left="Show"
            right="Hide"
            isLeft={showHiddenConflicts}
            onChange={setShowHiddenConflicts}
          />
        </One3rd>
        <One3rd content="right" style={{ display: 'flex' }}>
          <SearchBar onSubmit={setSearch} onInput={setSearch} />

          <NoStyleLink to="/materials">
            <Button style={{ marginLeft: 16 }} variant="contained">
              <Typography variant="button" noWrap>
                All Materials
              </Typography>
            </Button>
          </NoStyleLink>
        </One3rd>
      </div>
      <div
        style={{ flexGrow: 1, height: `calc(100% - ${TOP_CONTENT_HEIGHT}px)` }}
      >
        <MaterialsTable
          materials={showHiddenConflicts ? allConflicts : unhiddenConflicts}
          onSelect={setTargetMaterial}
          localStorageKey="GlobalMaterials"
          removeColumns={hiddenColumns}
          searchText={search}
          getRowStyle={material => ({
            opacity: material.conflictHidden ? 0.5 : 1
          })}
        />
      </div>
    </>
  );
}

function One3rd({ children, content, style }) {
  const one3rd = '1 1 33.3333%';
  let justifyContent;
  if (content === 'left') {
    justifyContent = 'flex-start';
  } else if (content === 'center') {
    justifyContent = 'center';
  } else if (content === 'right') {
    justifyContent = 'flex-end';
  } else {
    console.error('invalid content prop: ', content);
  }

  return (
    <div
      style={{
        flex: one3rd,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent
      }}
    >
      <div style={style}>{children}</div>
    </div>
  );
}
