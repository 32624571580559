import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import http from 'helpers/http.helper';
import { Toast, ChipSelect } from 'components';

const styles = {
  deleteIcon: { position: 'absolute', top: 10, right: 10 }
};

export default class EditPermissionLevel extends React.Component {
  state = {
    name: '',
    permissions: [],
    showDeleteConfirm: false,
    tier2: false
  };

  setPermissionLevel = () => {
    this.setState({
      name: this.props.permissionLevel.name,
      permissions: this.props.permissions.filter(permission =>
        this.props.permissionLevel.permissions.includes(permission.value)
      ),
      showDeleteConfirm: false,
      tier2: this.props.permissionLevel.tier2
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = () => {
    http()
      .put('/permission-levels/' + this.props.permissionLevel.id, {
        name: this.state.name,
        permissions: this.state.permissions.map(p => p.value),
        tier2: this.state.tier2
      })
      .then(res => {
        this.props.onUpdate(res);
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  archive = () => {
    http()
      .delete('/permission-levels/' + this.props.permissionLevel.id)
      .then(() => {
        Toast.show('Permission Level deleted.');
        this.props.onDelete(this.props.permissionLevel.id);
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: this.setPermissionLevel }}
      >
        <div style={styles.deleteIcon}>
          {this.state.showDeleteConfirm ? (
            <Typography>
              Are you sure?{' '}
              <Button color="primary" size="small" onClick={this.archive}>
                Delete
              </Button>
            </Typography>
          ) : (
            <IconButton
              onClick={() => this.setState({ showDeleteConfirm: true })}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        <DialogTitle>Change Job Title</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Job Titles and their permissions can not be deleted if there are
            users assigned to them. Modifying a job title will affect all users
            assigned to it.
          </DialogContentText>

          <TextField
            autoFocus
            label="Name"
            fullWidth
            value={this.state.name}
            name="name"
            margin="normal"
            onChange={this.handleChange}
          />

          <ChipSelect
            label="Permissions"
            margin="normal"
            selected={this.state.permissions}
            options={this.props.permissions}
            name="permissions"
            handleChange={this.handleChange}
          />

          <RadioGroup
            value={this.state.tier2 ? 'tier2' : 'tier1'}
            onChange={e => this.setState({ tier2: e.target.value === 'tier2' })}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value="tier1"
              control={<Radio />}
              label="Tier 1"
            />
            <FormControlLabel
              value="tier2"
              control={<Radio />}
              label="Tier 2"
            />
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color="default">
            Cancel
          </Button>
          <Button onClick={this.save} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
