import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled
} from 'helpers/themeSafeMui.helper';
import PoPdf from '../components/PoPdf.component';
import http from 'helpers/http.helper';
import { CustomInput, Toast, EmailSearch } from 'components';

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function EmailPdfModal({
  open,
  onClose,
  po,
  items,
  onSend,
  id,
  projectId
}) {
  const [_doc, setDoc] = useState(null);
  const [creatingEmail, setCreatingEmail] = useState(true);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailTo, setEmailTo] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadAndSend = async () => {
    /** @type {import('pdf-lib').PDFDocument} */
    const doc = _doc;
    const ui8 = await doc.save();
    const formData = new FormData();

    const blob = new Blob([ui8], { type: 'application/pdf' });
    formData.append('pdf', blob, { filename: 'PO.pdf' });

    for (let i = 0; i < emailTo.length; i++)
      formData.append('emails[]', emailTo[i].email);

    formData.append('subject', emailSubject ? emailSubject : ' ');
    formData.append('body', emailBody ? emailBody : ' ');

    if (!id || id === undefined) {
      setLoading(true);
      await http()
        .post(`/projects/${projectId}/pos`, {
          projectMaterialIds: items.map(pm => pm.id),
          quantities: items.map(pm => pm.quantity),
          to: po.to ? po.to : ' ',
          attn: po.attn ? po.attn : ' ',
          poNumber: po.poNumber ? po.poNumber : ' ',
          quoteNumber: po.quoteNumber ? po.quoteNumber : ' ',
          from: po.from,
          shipping: po.shipping,
          billTo: po.billTo,
          billToContactEmail: po.billToContactEmail,
          billToContactPhone: po.billToContactPhone,
          shipTo: po.shipTo,
          shipToAddress: po.shipToAddress,
          shipToContactPhone: po.shipToContactPhone
            ? po.shipToContactPhone
            : ' ',
          notes: po.notes,
          supplierId: po.supplierId,
          emailTo: emailTo ? formData.get('emails[]') : po.emailTo
        })
        .then(res => {
          id = res.po.id;
          Toast.show('PO Created');
        })
        .catch(err => {
          Toast.show(err.message);
        });
    }

    if (id && id !== undefined) {
      http({ withLoader: false })
        .put(`/projects/${projectId}/pos/save-and-send/${id}`, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(() => {
          Toast.show('PO Email Sent');
          onSend();
        })
        .catch(err => {
          Toast.show(err.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const poNumber = po ? po.poNumber || '' : '';
  const defaultSubject = poNumber ? `Purchase Order ${poNumber}` : '';

  useEffect(() => {
    if (open) {
      setCreatingEmail(true);
      setEmailSubject(defaultSubject);
      setEmailBody('');
    }
  }, [open, defaultSubject]);

  const reviewDisabled = !emailSubject || !emailTo.length;

  useEffect(() => {
    const originalEmailTo = !!po && po.emailTo;
    if (open)
      setEmailTo([
        { email: originalEmailTo ? originalEmailTo : '', id: originalEmailTo }
      ]);
  }, [open, po]);

  return (
    <FullHeightDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      {creatingEmail ? (
        <>
          <DialogTitle>Send Purchase Order</DialogTitle>
          <DialogContent>
            <EmailSearch
              TextFieldProps={{
                fullWidth: true,
                margin: 'normal',
                label: 'Recipients'
              }}
              required
              onChange={emailTo => setEmailTo(emailTo)}
              selected={emailTo}
            />

            <CustomInput
              label="Subject"
              value={emailSubject}
              onChange={setEmailSubject}
            />

            <CustomInput
              multiline
              minRows={5}
              label="Email Body"
              variant="outlined"
              value={emailBody}
              onChange={setEmailBody}
            />
          </DialogContent>
        </>
      ) : (
        !!po && <PoPdf po={po} items={items} onDocUpdate={setDoc} />
      )}

      <DialogActions>
        {creatingEmail ? (
          <>
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
            <Button
              color="primary"
              disabled={reviewDisabled}
              variant="contained"
              onClick={() => setCreatingEmail(false)}
            >
              Review
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" onClick={() => setCreatingEmail(true)}>
              Back
            </Button>
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={() => uploadAndSend()}
            >
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </FullHeightDialog>
  );
}
