import React, { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import http from 'helpers/http.helper';
import { useColors } from 'helpers/theme.helper';
import { Toast, LoadingOverlay } from 'components';
import {
  Paper,
  Typography,
  ListItem,
  Divider,
  ListItemText,
  Grid,
  List,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import toCurrency from 'helpers/currency.helper';
import jwt from 'jwt-decode';

function QuoteItems(props) {
  const { items, label } = props;
  const colors = useColors();

  return (
    <Grid item xs={12}>
      <div style={{ background: colors.background, paddingLeft: 16 }}>
        <Typography variant="h6" component="h6">
          {label}
        </Typography>
      </div>
      <List>
        {items.map(item => (
          <div key={item}>
            <ListItem>
              <ListItemText>{item}</ListItemText>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Grid>
  );
}

function QuotePricing(props) {
  const {
    item,
    checkable = false,
    disabled = false,
    checked = false,
    onUpdate = () => {}
  } = props;

  return (
    <React.Fragment>
      <div style={{ display: 'flex', padding: 12 }}>
        <div style={{ flexGrow: 1, margin: 'auto', paddingLeft: 4 }}>
          <Typography>{item.description}</Typography>
        </div>
        <div
          style={{
            width: '30%',
            margin: 'auto',
            textAlign: 'right',
            paddingRight: 18
          }}
        >
          <Typography>{toCurrency(item.cost)}</Typography>
        </div>
        {checkable && (
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onUpdate}
                disabled={disabled}
                value="Approved"
                color="primary"
              />
            }
            label={
              disabled ? (checked ? 'Approved' : 'Not Approved') : 'Approved'
            }
          />
        )}
      </div>
      <Divider />
    </React.Fragment>
  );
}

function QuoteApproval(props) {
  const { location } = props;
  const colors = useColors();
  const token = queryString.parse(location.search)['quote-token'];
  const { projectId, quoteId } = jwt(token);
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState(null);
  const [approvedBy, setApprovedBy] = useState('');
  const [approvedScopeIndices, setApprovedScopeIndices] = useState([]);

  const toggleApproveScopeIdx = idx => () => {
    const copy = [...approvedScopeIndices];
    copy[idx] = !approvedScopeIndices[idx];
    setApprovedScopeIndices(copy);
  };

  let canApprove =
    (quote && quote.pricing.length === 1) ||
    approvedScopeIndices.reduce(
      (acc, approved) => (approved ? acc + 1 : acc),
      0
    ) > 0;

  if (quote && !quote.isMultiScope) canApprove = true;

  if (quote && quote.voided) canApprove = false;

  const setAllApproved = useCallback(
    val => () => {
      setApprovedScopeIndices(Array(quote.pricing.length).fill(val));
    },
    [setApprovedScopeIndices, quote]
  );

  useEffect(() => {
    if (!quote) return;
    if (!quote.isMultiScope) return;
    if (!quote.approvedBy && quote.pricing.length > 1) {
      setAllApproved(false)();
    } else if (!quote.approvedBy && quote.pricing.length === 1) {
      setAllApproved(true)();
    } else {
      setApprovedScopeIndices(quote.approvedScopeIndices);
    }
  }, [quote, setAllApproved]);

  useEffect(() => {
    setLoading(true);
    http()
      .get(`/projects/${projectId}/quotes/${quoteId}/open`, {
        params: { token: token }
      })
      .then(res => setQuote(res))
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  }, [projectId, quoteId, token]);

  const approveQuote = () => {
    http()
      .post(`/projects/${projectId}/quotes/${quoteId}/approve`, {
        token: token,
        approvedBy: approvedBy,
        ...(quote.isMultiScope ? { approvedScopeIndices } : {})
      })
      .then(res => {
        setQuote(res);
        Toast.show('Quote was successfully approved, thank you');
      })
      .catch(err => Toast.show(err.message));
  };

  if (!quote) return null;
  return (
    <LoadingOverlay loading={loading}>
      <div style={{ padding: 16 }}>
        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 16
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 15
              }}
            >
              <div>
                <img
                  alt="system-tech-logo"
                  style={{ maxWidth: 350 }}
                  src="https://storage.googleapis.com/steps-files/assets/system-tech-logo.png"
                />
              </div>
              <div style={{ marginLeft: 16 }}>
                <Typography variant="h5">
                  Integrated Technology Solutions
                </Typography>
                <Typography variant="body1">
                  Cameras | Access Control | AV | Fiber Optics | Network Cabling
                  | Wireless | Networking | Fire Alarm
                </Typography>
              </div>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: 16, paddingLeft: 32 }}>
              <Typography variant="h6" component="h6">
                Quote for: {quote.projectName}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              {quote.voided && (
                <>
                  <Divider />
                  <Typography
                    variant="h5"
                    component="h5"
                    color="error"
                    style={{ textAlign: 'center' }}
                  >
                    QUOTE VOID
                  </Typography>
                </>
              )}
            </Grid>
            {quote.isMultiScope ? (
              quote.multiScopeOfWork.map(scope => (
                <QuoteItems
                  key={scope.scopeTitle}
                  items={scope.scopeItems}
                  label={scope.scopeTitle}
                />
              ))
            ) : (
              <QuoteItems
                items={quote.scopeOfWork}
                label="System Tech will complete the following scope of work for this project: "
              />
            )}

            <QuoteItems
              items={quote.exclusions}
              label="The following items are excluded from this scope of work: "
            />

            <QuoteItems
              items={quote.assumptions}
              label="The following assumptions were made with regards to this scope of work: "
            />

            <QuoteItems
              items={quote.lineItems}
              label="Upon completion of this project, the following items will be provided to the customer: "
            />
            <Grid item xs={12}>
              <div style={{ background: colors.background, paddingLeft: 16 }}>
                <Typography variant="h6" component="h6">
                  Prices:
                </Typography>
              </div>
              <List>
                {quote.pricing.map((item, index) => (
                  <QuotePricing
                    key={index}
                    item={item}
                    checkable={quote.isMultiScope && quote.pricing.length > 1}
                    checked={!!approvedScopeIndices[index]}
                    onUpdate={toggleApproveScopeIdx(index)}
                    disabled={quote.dateOfApproval || quote.voided}
                  />
                ))}
              </List>
            </Grid>
            <Grid item={12} style={{ margin: 'auto', padding: 16 }}>
              {quote.dateOfApproval ? (
                <Typography variant="h6">
                  <strong>
                    Quote was approved by <strong>{quote.approvedBy}</strong> on{' '}
                    <strong>
                      {moment(quote.dateOfApproval).format('LLL')}
                    </strong>
                  </strong>
                </Typography>
              ) : (
                <React.Fragment>
                  <TextField
                    placeholder="Your Name"
                    style={{ width: 250, marginRight: 16 }}
                    value={approvedBy}
                    onChange={e => setApprovedBy(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={approveQuote}
                    disabled={!canApprove}
                  >
                    Approve Quote
                  </Button>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          {!quote.dateOfApproval &&
            quote.isMultiScope &&
            quote.pricing &&
            quote.pricing.length > 1 &&
            !quote.voided && (
              <div style={{ position: 'relative' }}>
                <Button
                  style={{ position: 'absolute', right: 25, bottom: 16 }}
                  variant="outlined"
                  color="secondary"
                  onClick={setAllApproved(true)}
                >
                  Approve All
                </Button>
              </div>
            )}
        </Paper>
      </div>
    </LoadingOverlay>
  );
}

export default QuoteApproval;
