import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from 'helpers/themeSafeMui.helper';
import { ProjectsContext } from '../context/Projects.context';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import {
  fromDateString,
  fromTimeString,
  getDefaultLunch,
  getShiftLengthHours
} from '../helpers/DateTime.helper';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import CrewSelector from '../components/CrewSelector.component';
import { UsersContext } from '../context/Users.context';
import useDayUpdater from '../hooks/useDayUpdater.hook';
import useUserIsScheduled from '../hooks/useUserIsScheduled.hook';
import CrewItem from '../components/CrewItem.component';
import { useColors } from 'helpers/theme.helper';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';
import useWorkOrders from '../hooks/useWorkOrders.hook';
import WorkOrderListItem from '../components/WorkOrderListItem.component';
import { useDispatch } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import { CustomInput, Toast } from 'components';
import AddIcon from '@material-ui/icons/Add';
import TaskSelectorModal from '../modals/TaskSelector.modal';
import useProjectShiftsGroups from '../hooks/useProjectShiftsGroups';
import { ShiftModalContext } from '../context/ShiftModal.context';
import UpdateApprovedShift from './UpdateApprovedShifts.modal';
import useUsersDiff from '../hooks/useUsersDiff.hook';

export default function ShiftDayModal({ open, onClose, shiftId }) {
  const colors = useColors();
  const { shiftMap, shiftUserMap, userIdHoursMap } = useContext(
    ShiftScheduleContext
  );
  const { projectMap } = useContext(ProjectsContext);
  const { userMap } = useContext(UsersContext);
  const { openWithProps } = useContext(ShiftModalContext);
  const shift = shiftMap[shiftId] || null;
  const groups = useProjectShiftsGroups(shift ? shift.projectId : null);
  let currentShiftGroupIdx = !groups
    ? undefined
    : groups.findIndex(group =>
        group.shifts.find(other => other.id === shiftId)
      );
  currentShiftGroupIdx =
    currentShiftGroupIdx === -1 ? undefined : currentShiftGroupIdx;
  const projectId = shift ? shift.projectId : null;
  const project = projectMap[projectId] || null;
  const dispatch = useDispatch();

  const userIsScheduled = useUserIsScheduled();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [userIds, setUserIds] = useState([]);
  const [notes, setNotes] = useState('');
  const [approved, setApproved] = useState(false);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [lunchStart, setLunchStart] = useState(null);
  const [lunchDurationMinutes, setLunchDurationMinutes] = useState(null);

  const [taskSelectorOpen, setTaskSelectorOpen] = useState(false);
  const [updateApprovedOpen, setUpdateApprovedOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasLunch = !!lunchStart || !!lunchDurationMinutes;
  const removeLunch = () => {
    setLunchStart(null);
    setLunchDurationMinutes(null);
  };

  const save = useDayUpdater({
    shiftId,
    start,
    end,
    userIds,
    notes,
    approved,
    workOrderId,
    taskId,
    lunchStart,
    lunchDurationMinutes
  });
  const { workOrders, refresh: getWorkOrders } = useWorkOrders(projectId);

  const shiftUserIds = (shiftUserMap[shiftId] || []).map(
    shiftUser => shiftUser.userId
  );
  const { removedUserIds, addedUserIds, stayingUserIds } = useUsersDiff(
    shiftUserIds,
    userIds
  );

  const openWeekModal = () => {
    if (shift)
      openWithProps(
        { projectId: shift.projectId, selectedGroupIdx: currentShiftGroupIdx },
        'week'
      );
  };

  const trySave = () => {
    if (shift && shift.approved) {
      if (!updateApprovedOpen) {
        setUpdateApprovedOpen(true);
      }
    } else {
      save();
    }
  };

  const saveAndSendTexts = sendTexts => {
    save()
      .then(sendTexts)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const shift = shiftMap[shiftId] || null;
    const shiftUsers = shiftUserMap[shiftId] || [];
    if (shift) {
      setStart(fromTimeString(shift.start));
      setEnd(fromTimeString(shift.end));
      setUserIds(shiftUsers.map(shiftUser => shiftUser.userId));
      setNotes(shift.notes || '');
      setApproved(shift.approved);
      setWorkOrderId(shift.workOrderId);
      setTaskId(shift.taskId);
      setTaskName(shift.taskName);
      setLunchStart(shift.lunchStart ? fromTimeString(shift.lunchStart) : null);
      setLunchDurationMinutes(shift.lunchDurationMinutes);
    } else {
      setStart(null);
      setEnd(null);
      setUserIds([]);
      setNotes('');
      setApproved(false);
      setWorkOrderId(null);
      setTaskId(null);
      setTaskName('No Task Selected');
      setLunchStart(null);
      setLunchDurationMinutes(null);
    }
  }, [shiftId, shiftMap, shiftUserMap]);

  const openCreateWoModal = () => {
    if (!projectId) return Toast.show('No project selected.');
    dispatch(
      showModal({
        props: {
          projectId,
          refresh: getWorkOrders
        },
        size: 'md',
        type: 'CREATE_WORK_ORDER'
      })
    );
  };

  if (!project) return null;
  const paddingLeft = 10;

  function addLunch() {
    setLunchStart(
      getDefaultLunch(
        fromTimeString(start),
        getShiftLengthHours({ start, end })
      )
    );
    setLunchDurationMinutes(30);
  }

  const getUsersFromIds = ids =>
    ids.map(id => userMap[id]).filter(user => user);

  const removeUser = user => () =>
    setUserIds(old => {
      const idx = old.indexOf(user.id);
      if (idx === -1) return old;
      const copy = [...old];
      copy.splice(idx, 1);
      return copy;
    });

  const getDaysText = user => {
    const userDays = userIsScheduled(user);
    const workingDays = userDays
      .map((active, idx) => (active ? idx : null))
      .filter(day => day !== null)
      .map(day => DOWs[day]);
    if (!workingDays.length) return null;
    const hours = userIdHoursMap[user.id] || 0;
    const roundedHours = Math.floor(hours * 100) / 100;
    const hoursText = roundedHours === 1 ? '1 hr' : `${roundedHours} hrs`;
    return `${hoursText} - ${workingDays.join(', ')}`;
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <UpdateApprovedShift
        open={updateApprovedOpen}
        onClose={() => setUpdateApprovedOpen(false)}
        dateString={shift.date}
        removedUserIds={removedUserIds}
        addedUserIds={addedUserIds}
        stayingUserIds={stayingUserIds}
        setOnScheduleChange={sendTexts => {
          setUpdateApprovedOpen(false);
          setLoading(true);
          saveAndSendTexts(sendTexts);
        }}
      />
      <DialogTitle disableTypography>
        <div style={{ position: 'relative' }}>
          <Typography variant="h6" gutterBottom>
            Update Day:
          </Typography>
          <Button
            style={{ position: 'absolute', top: 0, right: 0 }}
            variant="contained"
            onClick={openWeekModal}
            disabled={loading}
          >
            Open Week
          </Button>
          <div style={{ paddingLeft }}>
            <Typography variant="h5">
              {project.projectNumber} {project.name}
            </Typography>
            {project && (
              <Typography>
                {fromDateString(shift.date).format('dddd, MMM Do')}
              </Typography>
            )}
          </div>
          {loading && <LinearProgress style={{ marginTop: 10 }} />}
        </div>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Typography variant="h6">Select Time:</Typography>
        <div style={{ display: 'flex', alignItems: 'flex-end', paddingLeft }}>
          <KeyboardTimePicker
            disabled={loading}
            margin="normal"
            label="Start"
            value={start && start.format()}
            onChange={date => setStart(moment(date))}
            KeyboardButtonProps={{ 'aria-label': 'change time' }}
          />
          <div style={{ fontSize: 40, padding: '0 8px 4px' }}>
            <ArrowRightIcon style={{ display: 'block' }} fontSize="inherit" />
          </div>
          <KeyboardTimePicker
            disabled={loading}
            margin="normal"
            label="End"
            value={end && end.format()}
            onChange={date => setEnd(moment(date))}
            KeyboardButtonProps={{ 'aria-label': 'change time' }}
          />
        </div>

        <Spacer />

        <Typography variant="h6">Lunch:</Typography>

        <div style={{ display: 'flex', alignItems: 'flex-end', paddingLeft }}>
          {hasLunch ? (
            <>
              <KeyboardTimePicker
                disabled={loading}
                margin="normal"
                label="Start"
                value={lunchStart ? lunchStart.format() : ''}
                onChange={date => setLunchStart(moment(date))}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
              <div style={{ fontSize: 40, padding: '0 8px 4px' }}>
                <ArrowRightIcon
                  style={{ display: 'block' }}
                  fontSize="inherit"
                />
              </div>
              <CustomInput
                disabled={loading}
                style={{ width: 257 }}
                type="dropdown"
                label="Duration"
                onChange={setLunchDurationMinutes}
                value={lunchDurationMinutes}
                options={[
                  { value: 30, label: '30 min' },
                  { value: 45, label: '45 min' },
                  { value: 60, label: '1 hr' }
                ]}
              />
              <div>
                <Button
                  disabled={!hasLunch || loading}
                  variant="contained"
                  onClick={removeLunch}
                >
                  <Typography variant="button" noWrap>
                    Remove Lunch
                  </Typography>
                </Button>
              </div>
            </>
          ) : (
            <Button disabled={loading} onClick={addLunch}>
              Add Lunch
            </Button>
          )}
        </div>

        <Spacer />
        <Typography variant="h6" gutterBottom>
          Select Crew:
        </Typography>
        <div style={{ paddingLeft }}>
          <div style={{ paddingLeft: 20, paddingBottom: 16 }}>
            {!getUsersFromIds(userIds).length && (
              <Typography component="div">None</Typography>
            )}
            {getUsersFromIds(userIds).map(user => (
              <CrewItem
                disabled={loading}
                key={user.id}
                onRemove={removeUser(user)}
                user={user}
              />
            ))}
          </div>
          <CrewSelector
            disabled={loading}
            onSelect={user => {
              setUserIds(old => {
                const idx = old.indexOf(user.id);
                if (idx === -1) return [...old, user.id];
                const copy = [...old];
                copy.splice(idx, 1);
                return copy;
              });
            }}
            getSecondaryText={user => getDaysText(user)}
          />
        </div>
        <Spacer />

        <Typography variant="h6" gutterBottom>
          Approval:
        </Typography>
        <div style={{ paddingLeft }}>
          <ListItem
            button
            disabled={loading}
            onClick={() => setApproved(old => !old)}
          >
            <ListItemIcon>
              {approved ? (
                <BsLockFill size={24} color={colors.success} />
              ) : (
                <BsUnlockFill size={24} color={colors.error} />
              )}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'button' }}>
              {approved ? 'Approved' : 'Unapproved'}
            </ListItemText>
          </ListItem>
        </div>
        <Spacer />

        <Typography variant="h6" gutterBottom>
          Notes:
        </Typography>
        <div style={{ paddingLeft }}>
          <TextField
            disabled={loading}
            fullWidth
            placeholder="Notes"
            multiline
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>

        <Spacer />
        <Typography variant="h6" gutterBottom>
          Work Orders:
        </Typography>
        <div style={{ paddingLeft }}>
          <ListItem disabled={loading} button onClick={openCreateWoModal}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText>Add Work Order</ListItemText>
          </ListItem>
          {workOrders.map(workOrder => (
            <WorkOrderListItem
              disabled={loading}
              key={workOrder.id}
              getWorkOrders={getWorkOrders}
              onSelect={checked =>
                setWorkOrderId(() => (checked ? workOrder.id : null))
              }
              selected={workOrderId === workOrder.id}
              workOrder={workOrder}
            />
          ))}
        </div>

        <Spacer />
        <Typography variant="h6" gutterBottom>
          Task:
        </Typography>
        <TaskSelectorModal
          open={taskSelectorOpen}
          onClose={() => setTaskSelectorOpen(false)}
          projectId={projectId}
          onSelect={task => {
            setTaskId(task.id);
            setTaskName(task.taskName);
            setTaskSelectorOpen(false);
          }}
        />

        <ListItem
          disabled={loading}
          button
          onClick={() => setTaskSelectorOpen(true)}
        >
          <ListItemText primaryTypographyProps={{ variant: 'button' }}>
            {taskName || 'None'}
          </ListItemText>
        </ListItem>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="contained" onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={trySave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Spacer = () => <div style={{ marginTop: 21 }} />;
const DOWs = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
};
