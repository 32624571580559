import React, { useState, useContext } from 'react';
import {
  ListItemText,
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  Tooltip
} from 'helpers/themeSafeMui.helper';
import PlangridFile from './PlangridFile.component';
import AddFile from '../modals/AddFile.modal';
import http from 'helpers/http.helper';
import {
  ProjectDetailsContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import { Toast, CollapseListItem } from 'components';
import { useColors } from 'helpers/theme.helper';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';
import moment from 'moment';
import PlangridRfiModal from '../modals/PlangridRfi.modal';
import PlangridRfiEmailModal from '../modals/PlangridRfiEmail.modal';

const PlangridRfi = ({ rfi, setRfi }) => {
  const { projectDetails } = useContext(ProjectDetailsContext);
  const { project } = useContext(ProjectContext);
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const colors = useColors();

  const updateRfi = async rfiData => {
    if (!projectDetails.plangridProjectId) return;
    try {
      const data = {
        locked: rfiData.locked,
        title: rfiData.title,
        question: rfiData.question,
        answer: rfiData.answer,
        assignedToUids: rfiData.assignedToUids,
        statusId: rfiData.statusId,
        stepsProjectId: projectDetails.projectId
      };
      if (rfiData.dueAt) data.dueAt = rfiData.dueAt;
      const res = await http().put(
        `/plangrid/rfis/${projectDetails.plangridProjectId}/${rfi.uid}`,
        data
      );
      setUpdateModalOpen(false);
      setRfi(res);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const refreshRfi = async () => {
    try {
      const res = await http().get(
        `/plangrid/rfis/${projectDetails.plangridProjectId}/${rfi.uid}`
      );
      setRfi(res);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const onUpload = async newFile => {
    try {
      await http().post(`/plangrid/rfis/${projectDetails.projectId}/file`, {
        plangridProjectId: projectDetails.plangridProjectId,
        rfiId: rfi.uid,
        fileId: newFile.uid,
        uploadType: newFile.uploadType
      });
      await refreshRfi();
      setAddFileOpen(false);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const setLocalStorageRfi = () => {
    localStorage.setItem('_plangrid_rfi', JSON.stringify({ rfi, project }));
  };

  return (
    <>
      <AddFile
        open={addFileOpen}
        onClose={() => setAddFileOpen(false)}
        onUpload={onUpload}
      />
      <PlangridRfiModal
        formTitle="Update RFI"
        rfi={rfi}
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onSubmit={updateRfi}
      />
      <PlangridRfiEmailModal
        rfi={rfi}
        open={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
      />
      <CollapseListItem
        headerItemContent={
          <ListItemText
            primary={`#${rfi.number} - ${rfi.title}`}
            primaryTypographyProps={{ component: 'h1', variant: 'h5' }}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item container spacing={2} sm={12} md={7}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Question
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10 }} />
              <Typography variant="body1" style={{ paddingLeft: 16 }}>
                {rfi.question}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Answer
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10 }} />
              <Typography variant="body1" style={{ paddingLeft: 16 }}>
                {rfi.answer ? rfi.answer : 'No answer yet'}
              </Typography>
            </Grid>

            {!!rfi.attachments && !!rfi.attachments.length && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Documents
                </Typography>
                <Divider />
                {rfi.attachments.map((attachment, idx, { length }) => (
                  <React.Fragment key={attachment.uid}>
                    <PlangridFile
                      key={attachment.uid}
                      title={attachment.name}
                      url={attachment.url}
                      type="attachment"
                    />
                    {idx !== length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Grid>
            )}

            {!!rfi.photos && !!rfi.photos.length && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Images
                </Typography>
                <Divider />
                {rfi.photos.map((photo, idx, { length }) => (
                  <React.Fragment key={photo.uid}>
                    <PlangridFile
                      title={photo.title}
                      url={photo.url}
                      type="image"
                    />
                    {idx !== length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={2} sm={12} md={5}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Actions
              </Typography>
              <Divider />
              <div
                style={{
                  paddingTop: 10,
                  display: 'flex'
                }}
              >
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => setUpdateModalOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Update Rfi
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => setEmailModalOpen(true)}
                  variant="contained"
                  color="primary"
                >
                  Create Email
                </Button>
                <Tooltip
                  title={rfi.locked ? 'RFI must be unlocked to add files' : ''}
                >
                  <div>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => setAddFileOpen(true)}
                      variant="contained"
                      color="primary"
                      disabled={rfi.locked}
                    >
                      Add File
                    </Button>
                  </div>
                </Tooltip>
                <Button
                  href={'/rfi?id=plangrid'}
                  target="_blank"
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={setLocalStorageRfi}
                >
                  Download
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                info
              </Typography>
              <Divider />
              <div style={{ paddingTop: 10 }} />

              <Typography variant="body1" style={{ paddingLeft: 16 }}>
                {rfi.locked ? (
                  <span style={{ color: colors.error }}>
                    <BsLockFill style={{ marginRight: 5 }} />
                    locked
                  </span>
                ) : (
                  <span style={{ color: colors.success }}>
                    <BsUnlockFill style={{ marginRight: 5 }} />
                    unlocked
                  </span>
                )}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                style={{ display: 'flex', paddingLeft: 16 }}
              >
                Status:
                <Paper
                  style={{
                    color: '#fff',
                    backgroundColor: rfi.status.color,
                    marginLeft: 5,
                    paddingLeft: 5,
                    paddingRight: 5
                  }}
                >
                  {rfi.status.label}
                </Paper>
              </Typography>
              {rfi.due_at && (
                <Typography variant="body1" style={{ paddingLeft: 16 }}>
                  Due:{' '}
                  <span style={{ fontFamily: 'monospace' }}>
                    {moment(rfi.due_at).format('LL')}
                  </span>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CollapseListItem>
    </>
  );
};

export default PlangridRfi;
