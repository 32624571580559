import React, { useEffect, useCallback, useState } from 'react';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  Table,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton
} from 'helpers/themeSafeMui.helper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { showModal } from 'ducks/modal.duck';
import { NoItemsResult, TableHeader, Toast } from 'components';

function WorkOrderMaterials(props) {
  const { workOrder, canEdit } = props;
  const [workOrderMaterials, setWorkOrderMaterials] = useState([]);
  const [workOrderMaterialState, setWorkOrderMaterialState] = useState([]);
  const [editing, setEditing] = useState(false);

  const getMaterials = useCallback(() => {
    http()
      .get(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/materials`
      )
      .then(res => {
        setWorkOrderMaterials(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  }, [workOrder.id, workOrder.projectId]);

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  useEffect(() => {
    setWorkOrderMaterialState(workOrderMaterials);
  }, [workOrderMaterials, setWorkOrderMaterialState]);

  const save = () => {
    Promise.all(
      workOrderMaterialState.map(item => {
        return http().put(
          `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/materials/${item.id}`,
          {
            itemDescription: item.itemDescription,
            quantity: item.quantity
          }
        );
      })
    ).then(getMaterials);
    setEditing(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <div style={{ display: 'flex' }}>
          <Typography gutterBottom variant="h5">
            Materials
            {canEdit && !workOrder.techSignOff && (
              <IconButton
                color="primary"
                onClick={() =>
                  props.showModal({
                    type: 'ADD_WORK_ORDER_MATERIAL',
                    props: {
                      workOrderId: workOrder.id,
                      projectId: workOrder.projectId,
                      refresh: getMaterials
                    }
                  })
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </Typography>
        </div>
        {editing && (
          <div style={{ position: 'absolute', right: 24, top: 18 }}>
            <Button variant="contained" color="primary" onClick={getMaterials}>
              cancel
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              variant="contained"
              color="secondary"
              onClick={save}
            >
              save
            </Button>
          </div>
        )}

        {canEdit && !workOrder.techSignOff && !editing && (
          <Button
            style={{ position: 'absolute', right: 24, top: 18 }}
            variant="contained"
            color="secondary"
            onClick={() => setEditing(true)}
          >
            edit materials
          </Button>
        )}
      </Grid>

      <Grid item xs={12}>
        <MaterialsWoTable
          workOrderMaterials={workOrderMaterials}
          workOrderMaterialState={workOrderMaterialState}
          setWorkOrderMaterialState={setWorkOrderMaterialState}
          editing={editing}
        />
      </Grid>
    </Grid>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(
  WorkOrderMaterials
);

function MaterialsWoTable(props) {
  const {
    workOrderMaterials,
    workOrderMaterialState,
    setWorkOrderMaterialState,
    editing
  } = props;
  const titles = ['Quantity Used', 'Item Description'];
  const [materialDescriptionIndex, setMaterialDescriptionIndex] = useState([]);

  const changeTableRow = (index, object) => {
    const tempArray = workOrderMaterials.slice();
    tempArray[index] = object;

    setWorkOrderMaterialState(tempArray);
  };

  useEffect(() => {
    // GET ALL MATERIALS
    http()
      .get('/materials')
      .then(res => {
        const descriptions = res.map(material => material.description);
        setMaterialDescriptionIndex(descriptions);
      })
      .catch(err => Toast.show(err.message));
  }, []);

  return (
    <Paper>
      <Table>
        <TableHeader titles={titles} />
        <TableBody>
          {workOrderMaterials.length === 0 && (
            <TableRow>
              <TableCell colSpan={2} scope="row">
                <NoItemsResult type="Materials" />
              </TableCell>
            </TableRow>
          )}

          {workOrderMaterialState.map((row, index) => (
            <EditableRow
              key={row.id}
              row={row}
              editing={editing}
              changeTableRow={object => changeTableRow(index, object)}
              materialDescriptionIndex={materialDescriptionIndex}
              initalValue={workOrderMaterials[index].itemDescription}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

function EditableRow(props) {
  const {
    row,
    editing,
    changeTableRow,
    materialDescriptionIndex,
    initalValue
  } = props;

  if (editing) {
    return (
      <TableRow>
        <TableCell>
          <TextField
            fullWidth
            label="Quantity"
            value={row.quantity}
            required
            onChange={e => changeTableRow({ ...row, quantity: e.target.value })}
            type="number"
            margin="normal"
            inputProps={{ step: '0.01' }}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            freeSolo
            options={materialDescriptionIndex}
            autoHighlight
            selectOnFocus
            value={initalValue}
            onChange={(e, value) => {
              changeTableRow({ ...row, itemDescription: value });
            }}
            onInput={e => {
              changeTableRow({ ...row, itemDescription: e.target.value });
            }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  label="Description of Item"
                  required
                  margin="normal"
                />
              );
            }}
          />
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell>{row.quantity}</TableCell>
        <TableCell>{row.itemDescription}</TableCell>
      </TableRow>
    );
  }
}
