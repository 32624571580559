import React from 'react';
import { connect } from 'react-redux';
import { AddressPredict, Toast } from 'components';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

class EmergencyContact extends React.Component {
  state = {
    married: false,
    spouseName: '',
    spousePhone: '',
    name: '',
    phone: '',
    address: '',
    address2: '',
    relationship: '',
    existingContacts: [],
    employeeDetails: {}
  };

  componentDidMount() {
    http()
      .get('/users/me')
      .then(employeeDetails => {
        this.setState({
          married: employeeDetails.married || false,
          spouseName: employeeDetails.spouseName || '',
          spousePhone: employeeDetails.spousePhone || '',
          employeeDetails
        });
      })
      .catch(err => Toast.show(err.message));

    http()
      .get('/users/me/emergency-contacts')
      .then(emergencyContacts => {
        if (emergencyContacts[0]) {
          this.setState({
            name: emergencyContacts[0].name,
            phone: emergencyContacts[0].phone,
            address: emergencyContacts[0].address,
            address2: emergencyContacts[0].address2,
            relationship: emergencyContacts[0].relationship,
            existingContacts: emergencyContacts
          });
        }
      })
      .catch(err => Toast.show(err.message));
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  useSpouseAsEmergencyContact = () => {
    this.setState({
      name: this.state.spouseName,
      phone: this.state.spousePhone,
      address: this.state.employeeDetails.address,
      address2: this.state.employeeDetails.address2 || '',
      relationship: 'Spouse'
    });
  };

  submit = e => {
    e.preventDefault();

    this.state.existingContacts.forEach(contact =>
      http().delete('/users/me/emergency-contacts/' + contact.id)
    );

    http()
      .put('/users/me', {
        married: this.state.married,
        spouseName: this.state.spouseName,
        spousePhone: this.state.spousePhone
      })
      .then(user =>
        http().post('/users/me/emergency-contacts', {
          name: this.state.name,
          phone: this.state.phone,
          address: this.state.address,
          address2: this.state.address2 || undefined,
          relationship: this.state.relationship
        })
      )
      .then(() => this.props.history.push('/onboarding/direct-deposit'))
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <form autoComplete="off" onSubmit={this.submit}>
        <Grid container spacing={3}>
          <Grid item xs={this.state.married ? 4 : 12}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Marital Status</InputLabel>
              <Select
                value={this.state.married}
                onChange={this.handleChange}
                name="married"
                required
              >
                <MenuItem value={true}>Married</MenuItem>
                <MenuItem value={false}>Single</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {this.state.married && (
            <Grid item xs={4}>
              <TextField
                name="spouseName"
                label="Spouse Name"
                required
                fullWidth
                margin="dense"
                value={this.state.spouseName}
                onChange={this.handleChange}
              />
            </Grid>
          )}
          {this.state.married && (
            <Grid item xs={4}>
              <TextField
                name="spousePhone"
                label="Spouse Phone"
                required
                type="tel"
                fullWidth
                margin="dense"
                value={this.state.spousePhone}
                onChange={this.handleChange}
              />
            </Grid>
          )}

          {this.state.married && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.useSpouseAsEmergencyContact}
                style={{ margin: 'auto', display: 'block' }}
              >
                Use Spouse as Emergency Contact
              </Button>
            </Grid>
          )}

          <Grid item xs={4}>
            <TextField
              name="name"
              label="Emergency Contact Name"
              required
              fullWidth
              margin="dense"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="phone"
              label="Emergency Contact Mobile"
              type="tel"
              required
              fullWidth
              margin="dense"
              value={this.state.phone}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="relationship"
              label="Relationship"
              required
              fullWidth
              margin="dense"
              value={this.state.relationship}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={8}>
            <AddressPredict
              name="address"
              label="Emergency Contact Address"
              required
              fullWidth
              margin="dense"
              value={this.state.address}
              onChange={address => this.setState({ address })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="address2"
              label="Apt/Suite"
              fullWidth
              margin="dense"
              value={this.state.address2}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ float: 'right', marginTop: 30 }}
        >
          Next
        </Button>
        <div style={{ clear: 'both' }} />
      </form>
    );
  }
}

export default connect(state => ({ user: state.user }))(EmergencyContact);
