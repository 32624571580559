import React, { useRef, useState } from 'react';
import {
  TextField,
  MenuItem,
  ListItemText,
  Paper,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import Downshift from 'downshift';
import theme from 'helpers/theme.helper';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';

export default function SearchDropdown({
  onChange,
  value,
  InputProps,
  items,
  itemToString,
  openOnClick,
  itemRender,
  getSecondaryText,
  ...rest
}) {
  const [val, setVal] = useState('');
  const anchorEl = useRef(null);

  const modifiedItemToString = item =>
    item && itemToString(item) ? itemToString(item) : '';

  const filteredItems =
    Array.isArray(items) &&
    items.filter(item => {
      const words = val.toLowerCase().split(' ');
      const searchString = modifiedItemToString(item).toLowerCase();
      return words.every(word => searchString.indexOf(word) !== -1);
    });

  return (
    <Downshift
      onChange={onChange}
      itemToString={modifiedItemToString}
      onInputValueChange={setVal}
      selectedItem={value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        openMenu
      }) => (
        <div>
          <TextField
            InputLabelProps={getLabelProps()}
            InputProps={{
              endAdornment: openOnClick ? (
                <InputAdornment position="end">
                  <DropdownIcon />
                </InputAdornment>
              ) : null,
              ...getInputProps(),
              ...InputProps
            }}
            ref={anchorEl}
            onClick={() => (openOnClick ? openMenu() : null)}
            {...rest}
          />

          <div
            {...getMenuProps(
              {
                style: {
                  display: !isOpen && 'none',
                  position: 'absolute',
                  zIndex: theme.zIndex.modal
                }
              },
              { suppressRefError: true }
            )}
          >
            <Paper
              square
              style={{
                marginTop: 8,
                width: anchorEl.current
                  ? anchorEl.current.clientWidth
                  : undefined
              }}
            >
              {filteredItems &&
                filteredItems.slice(0, 5).map(item => (
                  <MenuItem
                    {...getItemProps({ item })}
                    key={item.id || modifiedItemToString(item)}
                  >
                    <ListItemText
                      secondary={
                        getSecondaryText && getSecondaryText(item)
                          ? getSecondaryText(item)
                          : null
                      }
                    >
                      {itemRender
                        ? itemRender(item)
                        : modifiedItemToString(item)}
                    </ListItemText>
                  </MenuItem>
                ))}
            </Paper>
          </div>
        </div>
      )}
    </Downshift>
  );
}
