import React, { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Link
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import {
  Toast,
  CustomInput,
  Rollup,
  CopyText,
  FilePickerButton,
  IframeModal
} from 'components';
import EditableRow from '../components/EditableRow.component';
import { useSelector } from 'react-redux';
import formatValue from 'helpers/formatValue.helper';
import phoneHelper from 'helpers/phone.helper';
import ServiceItemsModal from '../modals/ServiceItems.modal';
import {
  ProjectContext,
  ProjectDetailsContext,
  ServiceItemsContext
} from 'routes/Projects/context/Project.context';
import invoiceTypeOptions from '../helpers/invoiceTypeOptions.helper';

export default function CustomerBillingCard() {
  const user = useSelector(state => state.user);
  const tier2 = user.hasPermission('projects_tier_2');
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const { project } = useContext(ProjectContext);
  const { serviceItems } = useContext(ServiceItemsContext);
  const [fields, setFields] = useState({ ...projectDetails });
  const [editing, setEditing] = useState(false);
  const [sowOpen, setSowOpen] = useState(false);
  const [serviceItemsOpen, setServiceItemsOpen] = useState(false);
  const [poFileOpen, setPoFileOpen] = useState(false);
  const [poFileWebViewLink, setPoFileWebViewLink] = useState(null);

  useEffect(() => {
    let stale = false;
    if (fields.customerPODriveId)
      http()
        .get(`/google/drive/web-view-link/${fields.customerPODriveId}`)
        .then(url => {
          if (!stale) setPoFileWebViewLink(url.replace('view', 'preview'));
        })
        .catch(Toast.showErr);
    return () => (stale = true);
  }, [fields.customerPODriveId]);

  useEffect(() => {
    setFields({ ...projectDetails });
  }, [projectDetails, editing]);

  const savePoFile = fileId => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        customerPODriveId: fileId
      })
      .then(res => {
        setProjectDetails(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  };

  const save = () => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        bidDueDate: fields.bidDueDate,
        planRoomLink: fields.planRoomLink || '',
        plangridLink: fields.plangridLink || '',
        customerPO: fields.customerPO || '',
        invoiceType: fields.invoiceType || '',
        retainagePercentage: fields.retainagePercentage || 0,
        start: fields.start,
        end: fields.end,
        estimatedCrew: fields.estimatedCrew || null,
        perDiem: fields.perDiem || '',
        scopeOfWork: fields.scopeOfWork || ''
      })
      .then(res => {
        setProjectDetails(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Billing Info</Typography>}
      localStorageKey="project_card_customer_billing"
    >
      <ServiceItemsModal
        open={serviceItemsOpen}
        onClose={() => setServiceItemsOpen(false)}
        editing={editing}
        setEditing={setEditing}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={sowOpen}
        onClose={() => setSowOpen(false)}
      >
        <DialogTitle>Scope Of Work</DialogTitle>
        {editing ? (
          <DialogContent>
            <CustomInput
              label="Scope Of Work"
              value={fields.scopeOfWork}
              multiline
              onChange={scopeOfWork => setFields({ ...fields, scopeOfWork })}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {formatValue({ value: fields.scopeOfWork })}
            </Typography>
          </DialogContent>
        )}
        <div style={{ padding: 5 }} />
        {editing ? (
          <DialogActions>
            <Button variant="contained" color="primary" onClick={save}>
              Save
            </Button>
            <Button variant="contained" onClick={() => setEditing(false)}>
              Cancel
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            {tier2 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
            <Button variant="contained" onClick={() => setSowOpen(false)}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        {tier2 && (
          <div>
            {editing ? (
              <Button variant="contained" color="secondary" onClick={save}>
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>

      <EditableRow
        type="link"
        internal
        editing={false}
        label="Company"
        value={`/companies/${project.companyId}`}
        linkText={project.companyName}
      />
      <EditableRow
        type="link"
        editing={false}
        label="Phone"
        value={`tel:${project.companyPhoneNumber}`}
        linkText={phoneHelper(project.companyPhoneNumber)}
      />
      <CopyText text={project.companyBillingEmail} type="Email">
        <EditableRow
          type="link"
          editing={false}
          label="Email"
          value={`tel:${project.companyBillingEmail}`}
          linkText={project.companyBillingEmail}
        />
      </CopyText>
      <CopyText text={project.companyAddress} type="Company Address">
        <EditableRow
          type="link"
          editing={false}
          label="Address"
          value={`https://www.google.com/maps/search/?api=1&query=${project.companyAddress}`}
          linkText={project.companyAddress}
        />
      </CopyText>

      {tier2 && (
        <>
          <EditableRow
            editing={editing}
            label="Customer PO"
            value={fields.customerPO}
            onChange={customerPO => setFields({ ...fields, customerPO })}
          />
          <div>
            <Typography>Customer PO File:</Typography>

            <IframeModal
              open={poFileOpen}
              onClose={() => setPoFileOpen(false)}
              url={poFileWebViewLink}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: 24,
                marginBottom: 10
              }}
            >
              {!!fields.customerPODriveId && (
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => setPoFileOpen(true)}
                >
                  Open file
                </Link>
              )}
              <div style={{ display: 'flex' }}>
                <FilePickerButton
                  onSelect={file =>
                    file ? savePoFile(file.id) : savePoFile(null)
                  }
                  apiPath={`/projects/${project.id}/docs`}
                >
                  <Link style={{ cursor: 'pointer' }}>
                    {!!fields.customerPODriveId ? 'Change file' : 'Add File'}
                  </Link>
                </FilePickerButton>

                {!!fields.customerPODriveId && (
                  <Link
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                    onClick={() => savePoFile(null)}
                  >
                    Remove file
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {tier2 && (
        <EditableRow
          editing={editing}
          label="Invoice Type"
          value={fields.invoiceType}
          onChange={invoiceType => setFields({ ...fields, invoiceType })}
          type="dropdown"
          options={invoiceTypeOptions}
        />
      )}
      {fields.invoiceType === 'Time & Materials' && tier2 && (
        <>
          <EditableRow
            editing={editing}
            label="T&M Hourly Rate"
            value={fields.tamBillableRate}
            onChange={tamBillableRate =>
              setFields({ ...fields, tamBillableRate })
            }
            type="currency"
          />
          <EditableRow
            editing={editing}
            label="T&M Drive Time Rate"
            value={fields.tamDriveRate}
            onChange={tamDriveRate => setFields({ ...fields, tamDriveRate })}
            type="currency"
          />
        </>
      )}
      {tier2 && (
        <EditableRow
          editing={false}
          label="Service Items"
          value={serviceItems.length}
          type="link"
          multiline
          onChange={() => {}}
          onLinkClick={() => setServiceItemsOpen(true)}
        />
      )}
      <EditableRow
        editing={editing}
        label="Per Diem"
        value={fields.perDiem}
        onChange={perDiem => setFields({ ...fields, perDiem })}
        type="currency"
      />
    </Rollup>
  );
}
