import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export const ProjectsContext = React.createContext();

const ProjectsContextProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [detailsMap, setDetailsMap] = useState({});

  useEffect(() => {
    Promise.all([
      http().get(`/projects?closed=false&orderBy=projectNumber`),
      http().get(`/projects/all-details`)
    ])
      .then(([projects, details]) => {
        const detailsMap = details.reduce(
          (map, detail) => Object.assign(map, { [detail.projectId]: detail }),
          {}
        );
        setDetailsMap(detailsMap);
        setProjects(
          projects.map(project => ({
            ...project,
            details: detailsMap[project.id]
          }))
        );
      })
      .catch(Toast.showErr);
  }, []);

  return (
    <ProjectsContext.Provider
      value={{ projects, filteredProjects, setFilteredProjects, detailsMap }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
