import React, { useContext } from 'react';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import { TrainingQuizzesContext } from 'routes/Users/context/TrainingQuizzes.context';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'routes/Users/context/User.context';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export default function TrainingQuizButton({ trainingModule }) {
  const { user } = useContext(UserContext);
  const currentUser = useSelector(state => state.user);
  const { moduleIdMap, refresh } = useContext(TrainingQuizzesContext);
  const history = useHistory();
  const goToQuiz = () =>
    history.push(`/training-quizzes/${trainingModule.quizId}/attempt`);

  const reviewQuiz = () =>
    history.push(
      `/training-quizzes/${trainingModule.quizId}/review-attempt?userId=${user.id}`
    );

  if (!trainingModule || !trainingModule.quizId) return null;

  const completedQuiz = moduleIdMap[trainingModule.id];

  const removeAttempt = () => {
    http()
      .delete(`/training-quizzes/attempts/${completedQuiz.id}`)
      .then(refresh)
      .catch(Toast.showErr);
  };

  if (!completedQuiz && currentUser.id === user.id)
    return (
      <div style={{ paddingBottom: 5 }}>
        <Button onClick={goToQuiz} variant="contained" size="small">
          Attempt Quiz
        </Button>
      </div>
    );

  if (!completedQuiz) return null;

  return (
    <div>
      <Typography>
        Quiz Score: {(completedQuiz.score * 100).toFixed(1)}%
      </Typography>
      <div style={{ paddingBottom: 5 }}>
        {currentUser.hasPermission('training_module_admin') && (
          <Button
            style={{ marginRight: 10 }}
            onClick={removeAttempt}
            variant="contained"
            size="small"
          >
            Delete Quiz Attempt
          </Button>
        )}
        <Button onClick={reviewQuiz} variant="contained" size="small">
          Review Quiz
        </Button>
      </div>
    </div>
  );
}
