import React from 'react';
import trunc from 'helpers/trunc.helper';
import useBreakpoint from 'hooks/useBreakpoint.hook';

export default function ResponsiveColumns({
  breakpoints,
  style = {},
  colStyle = {},
  children
}) {
  const aboveXs = useBreakpoint('xs', false);
  const aboveSm = useBreakpoint('sm', false);
  const aboveMd = useBreakpoint('md', false);
  const aboveLg = useBreakpoint('lg', false);
  const aboveXl = useBreakpoint('xl', false);

  const min = {
    xs: aboveXs,
    sm: aboveSm,
    md: aboveMd,
    lg: aboveLg,
    xl: aboveXl
  };

  const childrenArray = React.Children.toArray(children);
  const colCount =
    sizes.reduce((matched, size) => {
      if (!breakpoints[size]) return matched;
      if (min[size]) return breakpoints[size];
      return matched;
    }, null) || 1;

  const itemsPerCol = getItemsPerCol(childrenArray.length, colCount);
  const colWidth = `${trunc(1 / colCount, 6) * 100}%`;

  const columns = Array(colCount)
    .fill()
    .map(() => []);

  for (let i = 0; i < childrenArray.length; i++) {
    columns[i % colCount].push(childrenArray[i]);
  }

  return (
    <div style={{ ...style, display: 'flex' }}>
      {columns.map((column, idx) => (
        <div
          key={`${idx},${colCount},${itemsPerCol}`}
          style={{ ...colStyle, width: colWidth }}
        >
          {column}
        </div>
      ))}
    </div>
  );
}

function getItemsPerCol(itemCount, cols) {
  const result = [];
  let remaining = itemCount;
  for (let i = 0; i < cols; i++) {
    result.push(Math.ceil(remaining / (cols - i)));
    remaining -= result[result.length - 1];
  }
  return result;
}

const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
