import React from 'react';
import { IconButton, Typography } from 'helpers/themeSafeMui.helper';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import RightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment';

export default function WeekPicker({
  dateWindow,
  setDateWindow,
  disableWeekendButton = false,
  weekendHidden,
  setWeekendHidden,
  style
}) {
  const toggleWeekend = () => {
    setWeekendHidden(!weekendHidden);
  };

  const update = factor => () => {
    setDateWindow(dateWindow.map(m => moment(m).add(7 * factor, 'days')));
  };

  const format = m => {
    return m.format('MMM D');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style
      }}
    >
      <IconButton onClick={update(-1)}>
        <LeftIcon />
      </IconButton>

      <div style={{ textAlign: 'center' }}>
        <Typography component="div" style={{ fontSize: 20 }}>
          {format(dateWindow[0])} - {format(dateWindow[1])}
        </Typography>

        {!disableWeekendButton && (
          <Typography
            variant="overline"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={toggleWeekend}
          >
            {weekendHidden ? 'Show' : 'Hide'} weekend
          </Typography>
        )}
      </div>

      <IconButton onClick={update(1)}>
        <RightIcon />
      </IconButton>
    </div>
  );
}
