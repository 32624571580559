import React from 'react';
import { DashboardWrapper } from 'components';
import ShiftScheduleProvider from '../context/ShiftSchedule.context';
import ProjectsProvider from '../context/Projects.context';
import ShiftModalProvider from '../context/ShiftModal.context';
import UsersProvider from '../context/Users.context';
import ShiftScheduleRoute from './ShiftSchedule/ShiftSchedule.route';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import MyShifts from './MyShifts.route';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { Route, Switch, Redirect } from 'react-router-dom';
import ShiftsByUser from './ShiftsByUser.route';

export default function ShiftSchedule() {
  const currentUser = useCurrentUser();
  const isSmallScreen = useBreakpoint('xs');
  const isShiftAuthor =
    currentUser.hasPermission('shift_author') ||
    currentUser.hasPermission('shift_admin');
  return (
    <DashboardWrapper>
      <ShiftScheduleProvider>
        <ProjectsProvider>
          <UsersProvider>
            <ShiftModalProvider>
              <Switch>
                <Route path="/shift-schedule" exact>
                  {isSmallScreen || !isShiftAuthor ? (
                    <MyShifts />
                  ) : (
                    <ShiftScheduleRoute />
                  )}
                </Route>
                <Route
                  path="/shift-schedule/user-table"
                  component={ShiftsByUser}
                />
                <Redirect to="/404" />
              </Switch>
            </ShiftModalProvider>
          </UsersProvider>
        </ProjectsProvider>
      </ShiftScheduleProvider>
    </DashboardWrapper>
  );
}
