import React, { useState } from 'react';
import http from 'helpers/http.helper';
import promiseAllProgress from 'helpers/promise-all-progress.helper';
import { Toast } from 'components';
import { Button } from 'helpers/themeSafeMui.helper';

export default function SyncUserTsheets({ users = [], onSync }) {
  const [updatingUsers, setUpdatingUsers] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(null);

  const syncUsers = async () => {
    if (!users.length) return;
    setUpdatingUsers(users);
    setUpdatedCount(0);
    try {
      await promiseAllProgress(
        users.map(user =>
          http({ withLoader: false })
            .put(`/users/${user.id}/tsheets`)
            .catch(Toast.showErr)
        ),
        (completedItems, completeCount) => {
          setUpdatedCount(completeCount);
        }
      );
      Toast.show('Hours updated');
    } catch (err) {
      Toast.showErr(err);
    }

    setUpdatingUsers([]);
    setUpdatedCount(null);
    if (onSync) onSync();
  };

  const userCountText = users =>
    users ? `${users.length} user${users.length !== 1 ? 's' : ''}` : null;

  const commonProps = { variant: 'contained' };

  return updatedCount === null ? (
    <Button {...commonProps} onClick={syncUsers}>
      Sync {userCountText(users)} with TSheets
    </Button>
  ) : (
    <Button {...commonProps} disabled>
      Syncing ({updatedCount} out of {userCountText(updatingUsers)} completed)
    </Button>
  );
}
