import React, { useState, useEffect } from 'react';
import { Paper } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, NoItemsResult } from 'components';
import MultiScopeQuote from '../../components/MultiScopeQuote.component';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

const ProjectQuotesTab = props => {
  const { projectId } = props;
  const location = useLocation();
  const query = qs.parse(location.search);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    http()
      .get(`/projects/${projectId}/quotes`, {
        params: { type: 'quote' }
      })
      .then(quotes => {
        if (quotes.length) {
          quotes[0] = { ...quotes[0], isOpen: true };
        }
        setQuotes(quotes);
      })
      .catch(err => Toast.show(err.message));
  }, [projectId, query.r]);

  const duplicateQuote = quote => {
    http()
      .post(`/projects/${projectId}/quotes`, {
        description: `${quote.description} - Duplicate`,
        type: quote.type,
        scopeOfWork: quote.scopeOfWork,
        exclusions: quote.exclusions,
        assumptions: quote.assumptions,
        lineItems: quote.lineItems,
        pricing: quote.pricing
      })
      .then(res => setQuotes([{ ...res, isOpen: true }].concat(quotes)))
      .catch(err => Toast.show(err.message));
  };

  const updateQuote = updates => {
    setQuotes(
      quotes.map(quote =>
        quote.id === updates.id ? { ...quote, ...updates } : quote
      )
    );
  };

  return (
    <React.Fragment>
      {!quotes.length && (
        <Paper style={{ padding: 16, margin: '16px 0' }}>
          <NoItemsResult type="Quotes" style={{ padding: 50 }} />
        </Paper>
      )}

      {quotes.map(quote => (
        <MultiScopeQuote
          key={quote.id}
          quote={quote}
          onDuplicate={duplicateQuote}
          onUpdate={updateQuote}
        />
      ))}
    </React.Fragment>
  );
};

export default ProjectQuotesTab;
