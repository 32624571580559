import React, { useState, useContext } from 'react';
import { Typography, Divider, Button } from 'helpers/themeSafeMui.helper';
import { useSelector, useDispatch } from 'react-redux';
import http from 'helpers/http.helper';
import { UserContext } from '../context/User.context';
import { setUser as setUserDuck } from 'ducks/user.duck';
import { Toast, ImageCropperModal } from 'components';

const AccountPhoto = () => {
  const currentUser = useSelector(state => state.user);
  const { user, setUser } = useContext(UserContext);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const dispatch = useDispatch();
  const setCurrentUser = user => dispatch(setUserDuck(user));

  const save = dataUrl => {
    return http()
      .put('/users/' + user.id, {
        image: dataUrl
      })
      .then(user => {
        setUser(user);
        Toast.show('Updated.');
        if (user.id === currentUser.id) {
          setCurrentUser(user);
        }
      })
      .catch(e => Toast.show(e.message));
  };

  return (
    <>
      <ImageCropperModal
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        onCrop={dataUrl => {
          save(dataUrl).then(() => {
            setImageModalOpen(false);
          });
        }}
      />
      <Typography variant="h6">Account Photo</Typography>
      <Divider />
      <div style={{ padding: 16 }}>
        {user.image ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={user.image} alt="Account" />
          </div>
        ) : (
          <Typography variant="h6">
            This user does not have an Account Photo
          </Typography>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setImageModalOpen(true)}
        >
          Select new photo
        </Button>
      </div>
    </>
  );
};

export default AccountPhoto;
