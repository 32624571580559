import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Step, HorizontalScroll } from 'components';
import { Typography } from 'helpers/themeSafeMui.helper';
import { connect } from 'react-redux';
import { useColors } from 'helpers/theme.helper';
import toCurrency from 'helpers/currency.helper';
import ProjectBoardColumn from './ProjectBoardColumn.component';
import BreakoutPercentSteps from './BreakoutPercentSteps.component';
import BreakoutPercentColumns from './BreakoutPercentColumns.component';
import useScrollMemory from 'hooks/useScrollMemory.hook';
import LeftToBill, { useIsLeftToBill } from './LeftToBill.component';
import mapReducer from 'helpers/mapReducer.helper';

const ProjectBoard = props => {
  const { projectStatuses, filteredProjects, loading } = props;
  const isLeftToBill = useIsLeftToBill();
  const colors = useColors();
  const [columnWindowRef, { prevScrollLeft }] = useScrollMemory(
    'project_board.horizontal_scroll'
  );

  const [offsetLeft, setOffsetLeft] = useState(prevScrollLeft);
  const [breakout, setBreakout] = useState(false);
  const [statusProjectsMap, setStatusProjectsMap] = useState({});
  const ignoreEventRef = useRef(false);

  const handleScrollLeft = useCallback(
    (newLeft, ignoreEvent = false) => {
      ignoreEventRef.current = ignoreEvent;
      const columnWindowElement = columnWindowRef.current;
      if (columnWindowElement) columnWindowElement.scrollLeft = newLeft;
    },
    [columnWindowRef]
  );

  useEffect(() => {
    const statusMap = projectStatuses.reduce(
      (map, status) => ({ ...map, [status.id]: [] }),
      {}
    );

    setStatusProjectsMap(
      filteredProjects.reduce(mapReducer.array('statusId'), statusMap)
    );
  }, [filteredProjects, projectStatuses]);

  const _renderSum = statusId => {
    const columnSum = filteredProjects
      .filter(project => project.statusId === statusId)
      .reduce((sum, project) => sum + Number(project.dollarValue), 0);

    return `Contract Total: ${toCurrency(columnSum)}`;
  };

  const canBreakout = status => status.status === 'Potential POs';

  useEffect(() => {
    let frame;
    const handler = e => {
      cancelAnimationFrame(frame);
      frame = requestAnimationFrame(() => {
        if (!ignoreEventRef.current) setOffsetLeft(e.target.scrollLeft);
        ignoreEventRef.current = false;
      });
    };
    const columnWindowElement = columnWindowRef.current;
    columnWindowElement.addEventListener('scroll', handler);
    return () => {
      cancelAnimationFrame(frame);
      columnWindowElement.removeEventListener('scroll', handler);
    };
  }, [columnWindowRef]);

  const scrollWidth =
    columnWindowRef.current && columnWindowRef.current.scrollWidth;

  const handleHorizontalScroll = useCallback(
    newX => {
      if (scrollWidth) handleScrollLeft(newX, true);
    },
    [handleScrollLeft, scrollWidth]
  );

  useEffect(() => {
    handleHorizontalScroll(prevScrollLeft);
    setOffsetLeft(prevScrollLeft);
  }, [prevScrollLeft, handleHorizontalScroll]);

  const getStepColor = status =>
    status.status.match(/\bssa\b/i) ? colors.success : colors.secondary;

  return (
    <>
      <div
        style={{
          position: 'relative',
          height: 'calc(100% - 48px)',
          width: '100%'
        }}
      >
        <HorizontalScroll
          wrapperStyle={{ display: 'flex', overflowY: 'hidden' }}
          onScroll={handleHorizontalScroll}
          scrollPos={offsetLeft}
        >
          {projectStatuses.map((status, idx) =>
            canBreakout(status) ? (
              <BreakoutPercentSteps
                filteredProjects={filteredProjects}
                breakout={breakout}
                setBreakout={setBreakout}
                statusId={status.id}
                statusName={status.status}
                key={status.id}
              />
            ) : (
              <Step
                height={70}
                margin={4}
                first={idx === 0}
                last={idx === projectStatuses.length - 1}
                color={getStepColor(status)}
                textColor={colors.secondaryContrastText}
                key={status.id}
                style={{
                  width: 300,
                  flexShrink: 0,
                  cursor: 'pointer'
                }}
                TypographyProps={
                  isLeftToBill(status.id) ? { style: { lineHeight: 1.25 } } : {}
                }
              >
                {status.status}
                <br />
                <Typography
                  style={{ lineHeight: 0, fontWeight: 500 }}
                  variant="overline"
                >
                  {_renderSum(status.id)}
                  <LeftToBill
                    projects={statusProjectsMap[status.id] || []}
                    statusId={status.id}
                  />
                </Typography>
              </Step>
            )
          )}
        </HorizontalScroll>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            top: '5em',
            right: 24,
            left: 24,
            overflowX: 'auto'
          }}
          ref={columnWindowRef}
        >
          <div
            style={{
              height: '100%',
              display: 'flex'
            }}
          >
            {projectStatuses.map(status =>
              canBreakout(status) ? (
                <BreakoutPercentColumns
                  key={status.id}
                  breakout={breakout}
                  loading={loading}
                  projects={statusProjectsMap[status.id] || []}
                  statusId={status.id}
                />
              ) : (
                <div
                  key={status.id}
                  style={{
                    width: 300,
                    flexShrink: 0,
                    overflowY: 'auto',
                    height: '100%'
                  }}
                >
                  <ProjectBoardColumn
                    loading={loading}
                    projects={statusProjectsMap[status.id] || []}
                    statusId={status.id}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(state => ({
  user: state.user,
  projectStatuses: state.projectStatuses
}))(ProjectBoard);
