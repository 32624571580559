import React, { useState } from 'react';
import {
  createTheme,
  useTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import isUsingOneDrive from './isUsingOneDrive.helper';
import Color from 'color';

export const SetThemeContext = React.createContext();

const defaultColors = {
  type: 'light',
  primary: '#0F75BD',
  primaryContrastText: '#ffffff',
  secondary: '#21558C',
  secondaryContrastText: '#ffffff',
  background: '#eef5f9',
  action: 'rgba(0, 0, 0, 0.54)',
  lightGrey: '#F6F6F6',
  grey: '#e0e0e0',
  darkGrey: '#6E6E6F',
  red: '#ff0000',
  success: '#28a745',
  error: '#d32f2f',
  yellow: '#e6b800',
  lightYellow: '#ffd600',
  paperText: 'rgba(0, 0, 0, 0.87)',
  backgroundText: 'rgba(0, 0, 0, 0.87)',
  paper: '#ffffff'
};

export const useColors = () => {
  const theme = useTheme();
  return pullColorsFromTheme(theme);
};

export const withColors = Component => props => {
  const colors = useColors();
  return <Component colors={colors} {...props} />;
};

function pullColorsFromTheme(theme) {
  const { palette } = theme;
  try {
    const paperYellow = new Color(defaultColors.yellow)
      .mix(new Color(theme.palette.background.paper), 0.8)
      .hsl()
      .toString();
    const divider = new Color(palette.text.primary)
      .alpha(0.12)
      .hsl()
      .toString();
    return {
      type: palette.type,
      primary: palette.primary.main,
      primaryContrastText: palette.primary.contrastText,
      secondary: palette.secondary.main,
      secondaryContrastText: palette.secondary.contrastText,
      background: palette.background.default,
      action: palette.action.active,
      lightGrey: palette.grey[100],
      grey: palette.grey[300],
      darkGrey: palette.grey[600],
      darkerGrey: palette.grey[700],
      success: palette.success.main,
      error: palette.error.main,
      red: '#d32f2f',
      yellow: '#e6b800',
      lightYellow: '#ffd600',
      paperYellow,
      divider,
      paperText: palette.text.primary,
      secondaryText: palette.text.secondary,
      backgroundText: theme.palette.getContrastText(
        theme.palette.background.default
      ),
      paper: theme.palette.background.paper
    };
  } catch (err) {
    return defaultColors;
  }
}

export const makeDefaultConfig = () =>
  JSON.parse(
    JSON.stringify({
      palette: {
        primary: { main: defaultColors.primary },
        secondary: { main: defaultColors.secondary },
        background: { default: defaultColors.background },
        action: { main: defaultColors.action },
        type: 'light'
      },
      typography: {
        useNextVariants: true
      },
      overrides: {
        MuiSelect: {
          select: {
            '&:focus': {
              background: 'none'
            }
          }
        }
      }
    })
  );

const initialTheme = isUsingOneDrive()
  ? makeMuiThemeFromColors({ primary: '#489789', secondary: '#397a6b' })
  : createTheme(makeDefaultConfig());

export function makeMuiThemeFromColors({
  primary,
  secondary,
  background,
  paper,
  darkMode
}) {
  const config = makeDefaultConfig();
  if (primary) config.palette.primary.main = primary;
  if (secondary) config.palette.secondary.main = secondary;
  if (background) config.palette.background.default = background;
  if (paper) config.palette.background.paper = paper;
  if (darkMode === true) config.palette.type = 'dark';
  if (darkMode === false) config.palette.type = 'light';
  return createTheme(config);
}

export const ThemeProvider = props => {
  const isTola = isUsingOneDrive();
  const [memoryConfig, setMemoryConfig] = useLocalStorage(
    isTola ? 'theme.tola.current' : 'theme.current',
    isTola ? { primary: '#489789', secondary: '#397a6b' } : {},
    joi => joi.object()
  );

  // load page directly into correct theme without fetching
  const [theme, _setTheme] = useState(makeMuiThemeFromColors(memoryConfig));

  function setTheme({ primary, secondary, background, paper, darkMode }) {
    const newTheme = makeMuiThemeFromColors({
      primary,
      secondary,
      background,
      paper,
      darkMode
    });
    setMemoryConfig(arguments[0]);
    _setTheme(newTheme);
  }

  return (
    <SetThemeContext.Provider value={setTheme}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </SetThemeContext.Provider>
  );
};

export default initialTheme;
