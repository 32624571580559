import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from 'helpers/themeSafeMui.helper';
import { SketchPicker, CirclePicker, SwatchesPicker } from 'react-color';

function ColorEdit({ color: initialColor, onUpdate, title = '' }) {
  const [color, setColor] = useState(initialColor);
  const [pickerType, setPickerType] = useState('circle');

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const renderPicker = () => {
    switch (pickerType) {
      case 'circle':
        return (
          <div style={{}}>
            <CirclePicker
              color={color}
              onChange={changeColor => {
                setColor(changeColor.hex);
              }}
              onChangeComplete={changeColor => {
                setColor(changeColor.hex);
                onUpdate(changeColor.hex);
              }}
            />
          </div>
        );
      case 'detail':
        return (
          <div style={{ height: 0, overflow: 'visible' }}>
            <SketchPicker
              color={color}
              onChange={changeColor => {
                setColor(changeColor.hex);
              }}
              onChangeComplete={changeColor => {
                setColor(changeColor.hex);
                onUpdate(changeColor.hex);
              }}
            />
          </div>
        );
      case 'boxes':
        return (
          <div style={{ height: 0, overflow: 'visible' }}>
            <SwatchesPicker
              color={color}
              onChange={changeColor => {
                setColor(changeColor.hex);
              }}
              onChangeComplete={changeColor => {
                setColor(changeColor.hex);
                onUpdate(changeColor.hex);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Paper style={{ marginTop: 10 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 10
        }}
      >
        <Typography variant="h4" style={{ marginBottom: 10 }}>
          Editing{title ? ` ${title}` : ''}
        </Typography>
        <div style={{ maxWidth: 1100, position: 'relative', display: 'flex' }}>
          <FormControl style={{ margin: 10 }}>
            <InputLabel>Color Picker Type</InputLabel>
            <Select
              value={pickerType}
              onChange={e => setPickerType(e.target.value)}
            >
              <MenuItem value={'circle'}>Circle Picker</MenuItem>
              <MenuItem value={'detail'}>Detailed Picker</MenuItem>
              <MenuItem value={'boxes'}>Box Picker</MenuItem>
            </Select>
            <FormHelperText>Select the color picker to use</FormHelperText>
          </FormControl>
          {renderPicker()}
        </div>
      </div>
    </Paper>
  );
}

export default ColorEdit;
