import React from 'react';
import { useColors } from 'helpers/theme.helper';
import { Step } from 'components';
import toCurrency from 'helpers/currency.helper';
import { IconButton, Typography } from 'helpers/themeSafeMui.helper';
import ContractIcon from '@material-ui/icons/FirstPage';
import ExpandIcon from '@material-ui/icons/LastPage';
import {
  percents,
  percentColors,
  percentLabels
} from 'helpers/projectPercents.helper';

const BreakoutPercentSteps = ({
  breakout,
  setBreakout,
  filteredProjects,
  statusName,
  statusId
}) => {
  const colors = useColors();

  const totalSum = () => {
    const columnSum = filteredProjects
      .filter(project => project.statusId === statusId)
      .reduce((sum, project) => sum + Number(project.dollarValue), 0);

    return `Contract Total: ${toCurrency(columnSum)}`;
  };

  const percentSum = (percent, includeNull) => {
    const columnSum = filteredProjects
      .filter(
        project =>
          project.statusId === statusId &&
          (includeNull
            ? project.probability === percent || project.probability === null
            : project.probability === percent)
      )
      .reduce((sum, project) => sum + Number(project.dollarValue), 0);

    return `Contract Total: ${toCurrency(columnSum)}`;
  };

  if (!breakout)
    return (
      <Step
        height={70}
        margin={4}
        first={false}
        last={false}
        color={colors.secondary}
        textColor={colors.secondaryContrastText}
        style={{
          width: 300,
          flexShrink: 0,
          cursor: 'pointer'
        }}
      >
        {statusName}

        <br />
        <Typography
          style={{ lineHeight: 0, fontWeight: 500 }}
          variant="overline"
        >
          {totalSum()}
          <IconButton
            onClick={() => setBreakout(true)}
            style={{
              position: 'absolute',
              right: -10,
              top: '50%',
              transform: 'translateY(-50%)',
              color: colors.secondaryContrastText
            }}
          >
            <ExpandIcon />
          </IconButton>
        </Typography>
      </Step>
    );

  return percents
    .slice(0, 1)
    .map(firstPercent => (
      <Step
        height={70}
        margin={4}
        first={false}
        last={false}
        color={percentColors[firstPercent]}
        textColor={colors.secondaryContrastText}
        style={{
          width: 300,
          flexShrink: 0,
          cursor: 'pointer'
        }}
        key={firstPercent}
      >
        {`${statusName} - ${firstPercent}%`}

        <br />
        <Typography
          style={{ lineHeight: 0, fontWeight: 500 }}
          variant="overline"
        >
          {percentSum(firstPercent, true)}
          <IconButton
            onClick={() => setBreakout(false)}
            style={{
              position: 'absolute',
              right: -10,
              top: '50%',
              transform: 'translateY(-50%)',
              color: colors.secondaryContrastText
            }}
          >
            <ContractIcon />
          </IconButton>
        </Typography>
      </Step>
    ))
    .concat(
      percents.slice(1).map(percent => (
        <Step
          height={70}
          margin={4}
          first={false}
          last={false}
          color={percentColors[percent]}
          textColor={colors.secondaryContrastText}
          style={{
            width: 300,
            flexShrink: 0,
            cursor: 'pointer'
          }}
          key={percent}
        >
          {`${percentLabels[percent]} - ${percent}%`}

          <br />
          <Typography
            style={{ lineHeight: 0, fontWeight: 500 }}
            variant="overline"
          >
            {percentSum(percent)}
          </Typography>
        </Step>
      ))
    );
};

export default BreakoutPercentSteps;
