export default function overlap(start1, end1, start2, end2) {
  const retVal =
    between(start1, start2, end2) ||
    between(end1, start2, end2) ||
    between(start2, start1, end1) ||
    between(end2, start1, end1);
  return retVal;
}

function between(num, a, b) {
  return num >= a && num <= b;
}
