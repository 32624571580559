import React, { useContext, useState, useLayoutEffect, useRef } from 'react';
import { UsersContext } from 'routes/Users/context/Users.context';
import { Paper, Typography, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ConfirmModal from '../modals/Confirm.modal';
import { useSelector } from 'react-redux';

const PreviousMessage = ({
  message: massMessage,
  setFormContent,
  deleteMessage,
  recursive = false
}) => {
  const {
    created,
    createdBy,
    title,
    userIds,
    recipients,
    message,
    type,
    areaIds
  } = massMessage;

  const allAreas = useSelector(state => state.areas);
  const areas = areaIds
    .map(id => allAreas.find(a => a.id === id))
    .filter(a => a)
    .map(({ name }) => name);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { users } = useContext(UsersContext);
  const loadingUser = { name: 'loading' };
  const recipientUsers = recipients.map(
    id => users.find(user => user.id === id) || { ...loadingUser, id }
  );
  const addedUsers = userIds.map(
    id => users.find(user => user.id === id) || { ...loadingUser, id }
  );
  const createdByUser =
    users.find(user => user.id === createdBy) || loadingUser;

  const messageRef = useRef(null);
  const [height, setHeight] = useState(null);

  useLayoutEffect(() => {
    if (messageRef.current) {
      if (messageRef.current.clientHeight + 20 < 128) {
        setHeight(128);
      } else {
        setHeight(messageRef.current.clientHeight + 20);
      }
    }
    return () => {
      setHeight(null);
    };
  }, [open]);

  return (
    <Paper style={{ padding: 16, marginBottom: 20 }}>
      {!recursive && (
        <ConfirmModal
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={deleteMessage}
          open={deleteModalOpen}
          title="Delete Mass Message Record"
          description="Are you sure you want to delete this:"
          confirmButtonText="Delete"
        >
          <PreviousMessage message={massMessage} recursive={true} />
        </ConfirmModal>
      )}
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 1 100%' }}>
          <Typography variant="h6">{title}</Typography>
          <div style={{ opacity: 0.75 }}>
            <Typography variant="caption">{createdByUser.name} - </Typography>
            <Typography variant="caption">{type} - </Typography>
            <Typography variant="caption">
              {moment(created).format('MMM Do, YYYY h:mma')}
            </Typography>
          </div>
        </div>
        {open && setFormContent && deleteMessage && (
          <>
            <Tooltip title="Delete">
              <IconButton onClick={() => setDeleteModalOpen(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy">
              <IconButton onClick={() => setFormContent({ ...massMessage })}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      {open && (
        <div
          style={{
            height: height ? height : null,
            display: 'flex',
            paddingTop: 7
          }}
        >
          <div
            style={{
              flex: '0 1 100%',
              background: 'rgba(128,128,128,0.1)'
            }}
          >
            <Typography
              style={{ margin: 10, whiteSpace: 'pre-wrap' }}
              variant="body1"
              innerRef={messageRef}
            >
              {message}
            </Typography>
          </div>
          <div
            style={{
              padding: 10,
              paddingRight: 20,
              height: '100%',
              overflowY: 'auto',
              flex: '1 0 auto'
            }}
          >
            {!!areas.length && (
              <>
                <Typography variant="body1">Areas:</Typography>
                {areas.map(area => (
                  <Typography
                    key={area}
                    variant="caption"
                    component="p"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {area}
                  </Typography>
                ))}
                <div style={{ paddingTop: 10 }} />
              </>
            )}
            {!!addedUsers.length && (
              <>
                <Typography variant="body1">Added Users:</Typography>
                {addedUsers.map(user => (
                  <Typography
                    key={user.id}
                    variant="caption"
                    component="p"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {user.name}
                  </Typography>
                ))}
                <div style={{ paddingTop: 10 }} />
              </>
            )}
            {!!recipientUsers.length && (
              <>
                <Typography variant="body1">Recipients:</Typography>
                {recipientUsers.map(user => (
                  <Typography
                    key={user.id}
                    variant="caption"
                    component="p"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {user.name}
                  </Typography>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </Paper>
  );
};

export default PreviousMessage;
