import React from 'react';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import ReactPlayer from 'react-player';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

const Welcome = ({ history }) => {
  const [
    videoWatched,
    setVideoWatched
  ] = useLocalStorage('onboarding.welcome.video_watched', false, joi =>
    joi.boolean()
  );

  return (
    <React.Fragment>
      <Typography variant="body1" style={{ maxWidth: 640, margin: 'auto' }}>
        Welcome to the System Tech Employee and Project System. This system will
        walk you through each step you will need to take before starting work at
        System Tech. Watch the 5 minute video below to get started. Once the
        video is finished you will be able to move to the next step.
      </Typography>
      <ReactPlayer
        url="https://youtu.be/vADjUcCl3qk?si=usxVroh_athyizOu"
        controls={false}
        onEnded={() => setVideoWatched(true)}
        style={{ margin: '15px auto 30px auto' }}
      />

      <Button
        color="primary"
        variant="contained"
        disabled={!videoWatched}
        onClick={() => history.push('/onboarding/personal-info')}
        style={{ float: 'right' }}
      >
        Next
      </Button>
      <div style={{ clear: 'both' }} />
    </React.Fragment>
  );
};

export default Welcome;
