import userAssociations from 'helpers/userAssociations.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { useEffect } from 'react';

export default function useProjectFilter({
  projects,
  selectedFilterUsers,
  noneFlags,
  onUpdate,
  isShowingClosedProjects,
  isShowingMyProjects,
  setFilteredProjectsLength,
  setIsShowingMyProjects
}) {
  const currentUser = useCurrentUser();

  useEffect(() => {
    const projectUserAssociations = userAssociations();
    const countType = (array, noneFlag) => (noneFlag ? 0 : array.length);

    const filteredProjects = projects
      .filter(project => project.closed === isShowingClosedProjects)
      .filter(project => {
        if (isShowingMyProjects) {
          return !!projectUserAssociations.find(
            ({ baseKey }) => project[baseKey + 'Id'] === currentUser.id
          );
        } else {
          const noneFlagCount = projectUserAssociations.reduce(
            (sum, { baseKey }) => sum + !!noneFlags[baseKey],
            0
          );

          const filterCount = projectUserAssociations.reduce(
            (sum, { baseKey }) =>
              sum +
              countType(selectedFilterUsers[baseKey], !!noneFlags[baseKey]),
            0
          );

          if (filterCount + noneFlagCount === 0) return true;

          const userMatches = projectUserAssociations.map(({ baseKey }) => {
            const selectedUsers = selectedFilterUsers[baseKey];
            const idKey = baseKey + 'Id';
            const projectUserId = project[idKey];
            const projectHasUserInAssociation = selectedUsers.includes(
              projectUserId
            );

            const match =
              !noneFlags[baseKey] &&
              (projectHasUserInAssociation || !filterCount);

            return match;
          });

          const hasUserMatch = userMatches.some(foundUser => foundUser);
          if (!noneFlagCount) return hasUserMatch;

          const noneMatches = projectUserAssociations.map(({ baseKey }) => {
            const idKey = baseKey + 'Id';
            const match = !noneFlags[baseKey] || project[idKey] === null;
            return match;
          });
          const passesAllNoneFilters = noneMatches.every(match => match);
          return hasUserMatch && passesAllNoneFilters;
        }
      });

    onUpdate(filteredProjects);
    setFilteredProjectsLength(filteredProjects.length);

    const filterUsersCount = projectUserAssociations
      .map(({ baseKey }) => selectedFilterUsers[baseKey])
      .reduce((sum, { length }) => sum + length, 0);

    if (isShowingMyProjects && filterUsersCount > 0)
      setIsShowingMyProjects(false);
  }, [
    projects,
    selectedFilterUsers,
    noneFlags,
    onUpdate,
    isShowingClosedProjects,
    isShowingMyProjects,
    setFilteredProjectsLength,
    setIsShowingMyProjects,
    currentUser.id
  ]);
}
