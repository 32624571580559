import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useScrollMemory = (localStoragePath: string) => {
  const elementRef = useRef<null | HTMLElement>(null);
  const frameRef = useRef(-1);
  const [[prevScrollTop, prevScrollLeft]] = useState(() => {
    const [prevLeft, prevTop] = JSON.parse(
      localStorage.getItem(localStoragePath) || '[]'
    ) as number[];
    return [prevLeft || 0, prevTop || 0];
  });

  const observerRef = useRef(new MutationObserver(onMutate));

  function onMutate() {
    if (!localStoragePath) {
      observerRef.current.disconnect();
      return;
    }
    const element = elementRef.current;
    if (!element) return;
    const [prevLeft, prevTop] = JSON.parse(
      localStorage.getItem(localStoragePath) || '[]'
    ) as number[];
    if (prevLeft === undefined || prevTop === undefined) return;
    if (element.scrollHeight > prevTop || element.scrollWidth > prevLeft) {
      element.scrollTo(prevTop, prevLeft);
      observerRef.current.disconnect();
    }
  }

  const onScroll = (e: Event) => {
    cancelAnimationFrame(frameRef.current);
    const element = e.currentTarget as HTMLDivElement;
    frameRef.current = requestAnimationFrame(() => {
      const lastPos = [element.scrollTop, element.scrollLeft];
      localStorage.setItem(localStoragePath, JSON.stringify(lastPos));
    });
  };

  useEffect(() => {
    const element = elementRef.current;
    const observer = observerRef.current;
    if (!localStoragePath) {
      observer.disconnect();
      return;
    }
    if (element) {
      element.addEventListener('scroll', onScroll);
      observer.observe(element, {
        attributes: true,
        attributeFilter: ['scrollHeight', 'scrollWidth'],
        childList: true,
        subtree: true
      });
      onMutate();
    }
    return () => {
      if (element) element.removeEventListener('scroll', onScroll);
      observer.disconnect();
    };
  });

  function setElementRef(ref: HTMLElement | null) {
    if (ref === undefined) return elementRef.current;
    elementRef.current = ref;
    onMutate();
  }

  return [elementRef, { prevScrollTop, prevScrollLeft, setElementRef }] as [
    MutableRefObject<HTMLElement | null>,
    {
      prevScrollTop: number;
      prevScrollLeft: number;
      setElementRef: typeof setElementRef;
    }
  ];
};

export default useScrollMemory;
