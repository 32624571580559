import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  withTheme
} from 'helpers/themeSafeMui.helper';
import MoreIcon from '@material-ui/icons/MoreVert';
import stable from 'stable';

const color = 'rgba(0, 0, 0, 0.03)';

class SortingTable extends React.Component {
  state = {
    order: this.props.initialOrder || 'desc',
    orderBy: this.props.initialOrderBy || this.props.groups[0].columns[0].key,
    rowsPerPage: 25,
    page: 0
  };

  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  makeRowStyles(idx) {
    return idx === 0 && this.props.sticky
      ? {
          position: 'sticky',
          left: 0,
          background: this.props.theme.palette.background.paper
        }
      : {};
  }

  makeHeaderCellStyles(colIdx, row2 = false) {
    const background =
      colIdx % 2 === 1
        ? this.props.theme.palette.grey[100]
        : this.props.theme.palette.background.paper;
    const color = this.props.theme.palette.getContrastText(background);

    return this.props.sticky
      ? {
          position: 'sticky',
          background,
          color,
          top: row2 ? this.props.theme.typography.pxToRem(57) : 0
        }
      : {};
  }

  render() {
    const { rowsPerPage, page, order, orderBy } = this.state;
    const { groups, rowAction } = this.props;

    const columns = groups.reduce(
      (columns, group) => columns.concat(group.columns),
      []
    );

    const rows = stable(this.props.rows, (a, b) => {
      const column = columns.find(column => column.key === orderBy);
      a = column.extractor ? column.extractor(a) : a[orderBy];
      b = column.extractor ? column.extractor(b) : b[orderBy];
      if (order === 'asc') {
        if (!a) return false;
        if (!b) return true;
        return a.toLowerCase() < b.toLowerCase();
      } else {
        if (!a) return true;
        if (!b) return false;
        return a.toLowerCase() > b.toLowerCase();
      }
    });

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const height = this.props.height || null;

    return (
      <Paper>
        <div style={{ overflowX: 'auto', position: 'relative', height }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={this.makeHeaderCellStyles(0)}
                  padding="checkbox"
                />
                {groups.map((group, idx) => (
                  <TableCell
                    key={group.label}
                    style={{
                      whiteSpace: 'nowrap',
                      background: idx % 2 === 1 ? color : null,
                      ...this.makeHeaderCellStyles(idx)
                    }}
                    colSpan={group.columns.length}
                    align="left"
                  >
                    {group.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell
                  style={this.makeHeaderCellStyles(0, true)}
                  padding="checkbox"
                />
                {groups.map((group, idx) =>
                  group.columns.map(column => (
                    <TableCell
                      key={column.key}
                      sortDirection={orderBy === column.key ? order : false}
                      style={{
                        whiteSpace: 'nowrap',
                        background: idx % 2 === 1 ? color : null,
                        ...this.makeHeaderCellStyles(idx, true)
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={order}
                        onClick={() => this.handleRequestSort(column.key)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow
                    style={{ cursor: rowAction && 'pointer' }}
                    hover={Boolean(rowAction)}
                    key={row.id}
                    onClick={() => rowAction && rowAction(row)}
                  >
                    <TableCell padding="checkbox" className="cell-shrink">
                      <MoreIcon color="action" fontSize="small" />
                    </TableCell>
                    {groups.map((group, idx) =>
                      group.columns.map(column => {
                        const val = column.extractor
                          ? column.extractor(row)
                          : row[column.key];
                        return (
                          <TableCell
                            key={column.key + row.id}
                            style={{
                              whiteSpace: 'nowrap',
                              background: idx % 2 === 1 ? color : null,
                              color:
                                val && val.textColor ? val.textColor : null,
                              ...this.makeRowStyles(idx)
                            }}
                          >
                            {column.modifier ? column.modifier(val) : val}
                          </TableCell>
                        );
                      })
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columns.length + (rowAction ? 1 : 0)} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default withTheme(SortingTable);
