import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  CircularProgress,
  Typography
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, CustomInput } from 'components';
import promiseAllProgress from 'helpers/promise-all-progress.helper';
import moment from 'moment';

const TrainingSendCommunicationModal = ({
  ButtonProps,
  onSubmit,
  selectedUsers
}) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('note');
  const [content, setContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [actionItemNotes, setActionItemNotes] = useState('');
  const [actionItemDueDate, setActionItemDueDate] = useState(() =>
    moment()
      .add(2, 'days')
      .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
  );

  const [loading, setLoading] = useState(false);
  const [numCompleted, setNumCompleted] = useState([0, 0]);

  const send = async () => {
    const promiseArray = selectedUsers.map(postSwitch);
    let frame;
    setLoading(true);
    setNumCompleted([0, promiseArray.length]);
    try {
      const newItems = await promiseAllProgress(
        promiseArray,
        (statuses, completed) => {
          cancelAnimationFrame(frame);
          frame = requestAnimationFrame(() => {
            setNumCompleted([completed, promiseArray.length]);
          });
        }
      );
      setOpen(false);
      onSubmit(newItems);
    } catch (err) {
      Toast.show(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setType('note');
    setContent('');
    setEmailSubject('');
    setActionItemNotes('');
    setActionItemDueDate(
      moment()
        .add(2, 'days')
        .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
    );
  }, [open]);

  const attemptClose = () => {
    if (!loading) setOpen(false);
  };

  const postSwitch = user => {
    switch (type) {
      case 'note':
        return http().post(`/users/${user.id}/training-notes`, {
          content
        });
      case 'sms':
        return http().post('/training-communications/sms', {
          userId: user.id,
          message: content
        });
      case 'email':
        return http().post('/training-communications/email', {
          userId: user.id,
          subject: emailSubject,
          body: content
        });
      case 'action-item':
        return http().post('/training-communications/action-item', {
          userId: user.id,
          content,
          notes: actionItemNotes,
          dueDate: actionItemDueDate
        });
      default:
        return Promise.reject(new Error({ message: 'Bad type' }));
    }
  };

  const formSwitch = () => {
    switch (type) {
      case 'note':
        return (
          <CustomInput
            disabled={loading}
            multiline
            label="Note"
            value={content}
            onChange={setContent}
          />
        );
      case 'sms':
        return (
          <CustomInput
            disabled={loading}
            multiline
            label="Message"
            value={content}
            onChange={setContent}
          />
        );
      case 'email':
        return (
          <>
            <CustomInput
              disabled={loading}
              label="Subject"
              value={emailSubject}
              onChange={setEmailSubject}
            />
            <CustomInput
              disabled={loading}
              multiline
              label="Body"
              value={content}
              onChange={setContent}
            />
          </>
        );
      case 'action-item':
        return (
          <>
            <CustomInput
              disabled={loading}
              multiline
              label="Title"
              value={content}
              onChange={setContent}
            />
            <CustomInput
              disabled={loading}
              multiline
              label="Notes"
              value={actionItemNotes}
              onChange={setActionItemNotes}
            />
            <CustomInput
              disabled={loading}
              multiline
              type="date"
              label="Due Date"
              value={actionItemDueDate}
              onChange={setActionItemDueDate}
            />
          </>
        );
      default:
        return 'bad type';
    }
  };

  const userCountText = () => {
    const num = selectedUsers.length;
    if (num === 1) return selectedUsers[0].name;
    return `${num} users`;
  };

  return (
    <>
      <Button
        variant="contained"
        {...ButtonProps}
        disabled={!selectedUsers.length}
        onClick={() => setOpen(true)}
      >
        New Message / Note
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={attemptClose}>
        <DialogTitle>
          Creating {type} for {userCountText()}
        </DialogTitle>

        <DialogContent>
          <CustomInput
            disabled={loading}
            type="dropdown"
            label="Type"
            value={type}
            onChange={setType}
            options={['note', 'sms', 'email', 'action-item'].map(label => ({
              value: label,
              label
            }))}
          />
        </DialogContent>

        <DialogContent>{formSwitch()}</DialogContent>

        {loading && (
          <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress />
            <div style={{ paddingLeft: 18 }}>
              <Typography variant="overline" color="primary">
                Sent {numCompleted[0]} of {numCompleted[1]}
              </Typography>
            </div>
          </DialogContent>
        )}

        <DialogActions>
          <Button
            variant="contained"
            disabled={loading}
            onClick={attemptClose}
            autoFocus
          >
            cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={send}
            color="primary"
          >
            send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrainingSendCommunicationModal;
