import React, { useContext, useState } from 'react';
import {
  percents,
  percentColors,
  percentLabels
} from 'helpers/projectPercents.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import { CustomInput, Toast } from 'components';
import {
  Paper,
  Popover,
  Tooltip,
  Typography
} from 'helpers/themeSafeMui.helper';

const ProbabilitySlider = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );

  const currentPercent =
    percents.find(percent => percent === projectDetails.probability) ||
    percents[0];
  const currentIndex = percents.findIndex(
    percent => percent === currentPercent
  );
  const barColor = percentColors[currentPercent];

  const update = newValue => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        probability: newValue
      })
      .then(setProjectDetails)
      .catch(Toast.showErr);
  };
  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const cellWidth = ((1 / percents.length) * 100).toFixed(3) + '%';
  return (
    <>
      <Tooltip title={percentLabels[currentPercent]}>
        <Paper
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            display: 'flex',
            margin: '5px 0',
            borderRadius: 50,
            overflow: 'hidden'
          }}
        >
          {percents.map((percent, idx) => (
            <div
              key={percent}
              style={{
                width: cellWidth,
                paddingRight: 5,
                backgroundColor: idx <= currentIndex ? barColor : null
              }}
            >
              {idx === currentIndex && (
                <Typography align="right" style={{ color: '#ffffff' }}>
                  {percent}%
                </Typography>
              )}
            </div>
          ))}
        </Paper>
      </Tooltip>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: 10, width: 275 }}>
          <CustomInput
            type="dropdown"
            label="probability"
            value={currentPercent}
            options={percents.map(p => ({
              value: p,
              label: `${percentLabels[p]} ${p}%`
            }))}
            onChange={update}
          />
        </div>
      </Popover>
    </>
  );
};

export default ProbabilitySlider;
