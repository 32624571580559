import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CopyText, Rollup, Toast } from 'components';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import TeamDisplay from 'routes/Projects/tabs/Team/components/TeamDisplay.component';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import {
  ProjectContext,
  ProjectTeamContext
} from 'routes/Projects/context/Project.context';
import EditableRow from '../components/EditableRow.component';

export default function CustomerContactsCard() {
  const currentUser = useCurrentUser();
  const adminSettings = useSelector(state => state.adminSettings);

  const { project, setProject } = useContext(ProjectContext);
  const { projectTeam, setProjectTeam } = useContext(ProjectTeamContext);

  const [editingAddress, setEditingAddress] = useState(false);
  const [address, setAddress] = useState(project.address || '');
  const [people, setPeople] = useState([]);

  useEffect(() => {
    setAddress(project.address);
  }, [project.address]);

  useEffect(() => {
    http()
      .get('/people')
      .then(people => {
        if (project.companyId !== adminSettings.sudoCompanyId) {
          const filtered = people.filter(
            person => person.companyId === project.companyId
          );
          const active = filtered.filter(person => !person.inactive);
          setPeople(active);
        } else {
          setPeople(people);
        }
      })
      .catch(err => Toast.show(err.message));
  }, [adminSettings.sudoCompanyId, project.companyId, project.statusId]);

  const updateProjectTeam = updates => {
    http()
      .put(`/projects/${projectTeam.projectId}/teams`, updates)
      .then(res => setProjectTeam(res))
      .then(() =>
        http()
          .get(`/projects/${project.id}`)
          .then(project => setProject(project))
      )
      .catch(err => Toast.show(err.message));
  };

  const getPersonPhoneNumber = key => {
    const base =
      projectTeam[key] && projectTeam[key][0]
        ? projectTeam[key][0].phoneNumber
        : null;
    if (!base) return null;
    const ext = projectTeam[key][0].ext || null;
    return `${base}, ${ext}`;
  };

  function saveAddress() {
    http()
      .put(`/projects/${project.id}`, { address })
      .then(project => {
        setProject(project);
        setEditingAddress(false);
      })
      .catch(Toast.showErr);
  }

  function withCopyText(el) {
    return editingAddress ? (
      el
    ) : (
      <CopyText text={project.address} type="Jobsite Address">
        {el}
      </CopyText>
    );
  }

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Customer Contacts</Typography>}
      localStorageKey="project_card_customer_contacts"
    >
      {currentUser.hasPermission('projects_tier_2') && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {editingAddress ? (
            <>
              <Button onClick={saveAddress} variant="contained" color="primary">
                save
              </Button>
              <Button
                onClick={() => {
                  setEditingAddress(false);
                  setAddress(project.address);
                }}
                variant="contained"
                style={{ marginLeft: 10 }}
              >
                cancel
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setEditingAddress(true)}
              variant="contained"
              color="primary"
            >
              edit address
            </Button>
          )}
        </div>
      )}
      {withCopyText(
        <EditableRow
          type={editingAddress ? 'address' : 'link'}
          onChange={setAddress}
          editing={editingAddress}
          label="Jobsite Address"
          forceTwoLine
          value={
            editingAddress
              ? address
              : `https://www.google.com/maps/search/?api=1&query=${project.address}`
          }
          linkText={project.address}
        />
      )}
      <TeamDisplay
        title="Customer Project Manager"
        readOnly={!currentUser.hasPermission('projects_tier_2')}
        name={projectTeam.customerProjectManagerName}
        phoneNumber={getPersonPhoneNumber('customerProjectManagerPhoneNumbers')}
        email={
          projectTeam.customerProjectManagerEmails &&
          projectTeam.customerProjectManagerEmails.length
            ? projectTeam.customerProjectManagerEmails[0].email
            : null
        }
        onChange={({ id }) =>
          updateProjectTeam({ customerProjectManagerId: id })
        }
        options={people}
      />
      <TeamDisplay
        title="Customer Initial Contact"
        readOnly={!currentUser.hasPermission('projects_tier_2')}
        name={projectTeam.customerInitialContactName}
        phoneNumber={getPersonPhoneNumber('customerInitialContactPhoneNumbers')}
        email={
          projectTeam.customerInitialContactEmails &&
          projectTeam.customerInitialContactEmails[0]
            ? projectTeam.customerInitialContactEmails[0].email
            : null
        }
        onChange={({ id }) =>
          updateProjectTeam({ customerInitialContactId: id })
        }
        options={people}
      />
      <TeamDisplay
        title="Customer Job Site Contact"
        readOnly={!currentUser.hasPermission('projects_tier_2')}
        name={projectTeam.customerOnSiteContactName}
        phoneNumber={getPersonPhoneNumber('customerOnSiteContactPhoneNumbers')}
        email={
          projectTeam.customerOnSiteContactEmails &&
          projectTeam.customerOnSiteContactEmails[0]
            ? projectTeam.customerOnSiteContactEmails[0].email
            : null
        }
        onChange={({ id }) =>
          updateProjectTeam({ customerOnSiteContactId: id })
        }
        options={people}
      />
    </Rollup>
  );
}
