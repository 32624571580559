import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'components';
import { Paper, Typography, Grid, Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import TeamDisplay from './components/TeamDisplay.component';
import {
  ProjectTeamContext,
  ProjectContext
} from '../../context/Project.context';
import EmployeeHours from './components/EmployeeHours.component';
import PlangridUsers from './components/PlangridUsers.component';
import RequestAccountManagerChange from './modals/RequestAccountManagerChange.modal';
import ChildTeams from './components/ChildTeams.component';
import userAssociations, {
  accountManager
} from 'helpers/userAssociations.helper';

function TeamTab(props) {
  const { adminSettings, currentUser } = props;
  const projectUserAssociations = userAssociations();

  const { project, setProject } = useContext(ProjectContext);
  const { projectTeam, setProjectTeam } = useContext(ProjectTeamContext);

  const [users, setUsers] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [people, setPeople] = useState([]);
  const [status, setStatus] = useState(null);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const tier2User = currentUser.hasPermission('projects_tier_2');
  const tier1_5User = currentUser.hasPermission('projects_tier_1.5');

  useEffect(() => {
    http()
      .get('/users?includeImage=true')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/account-managers')
      .then(res =>
        setAccountManagers(
          res.map(am => ({
            id: am.userId,
            name: `${am.managerNumber} - ${am.name}`
          }))
        )
      )
      .catch(err => Toast.show(err.message));

    http()
      .get('/people')
      .then(people => {
        if (project.companyId !== adminSettings.sudoCompanyId) {
          const filtered = people.filter(
            person => person.companyId === project.companyId
          );
          const active = filtered.filter(person => !person.inactive);
          setPeople(active);
        } else {
          setPeople(people);
        }
      })
      .catch(err => Toast.show(err.message));

    if (project.statusId)
      http()
        .get(`/project-statuses/${project.statusId}`)
        .then(res => setStatus(res))
        .catch(err => Toast.show(err.message));
  }, [adminSettings.sudoCompanyId, project.companyId, project.statusId]);

  const updateProjectTeam = updates => {
    http()
      .put(`/projects/${projectTeam.projectId}/teams`, updates)
      .then(res => setProjectTeam(res))
      .then(() =>
        http()
          .get(`/projects/${project.id}`)
          .then(project => setProject(project))
      )
      .catch(err => Toast.show(err.message));
  };

  function canUpdateAccountManager() {
    if (
      currentUser.hasPermission('super_admin') ||
      currentUser.hasPermission('project_team_tier_2')
    )
      return true;
    if (tier2User && !projectTeam.accountManagerId) return true;
    if (status && !status.teamLocked && tier2User) return true;
    return false;
  }

  const getImage = id => {
    const user = users.find(u => u.id === id);
    return user && user.image;
  };

  const getPersonPhoneNumber = key => {
    const base =
      projectTeam[key] && projectTeam[key][0]
        ? projectTeam[key][0].phoneNumber
        : null;
    if (!base) return null;
    const ext = projectTeam[key][0].ext || null;
    return `${base}, ${ext}`;
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <RequestAccountManagerChange
          open={requestModalOpen}
          onClose={() => setRequestModalOpen(false)}
        />
        <Paper style={{ padding: 16 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component="h1" variant="h5" gutterBottom>
              Project Team
            </Typography>
            {tier2User && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setRequestModalOpen(true)}
              >
                Request Change
              </Button>
            )}
          </div>

          {projectUserAssociations.map(({ name, baseKey, editPermission }) => (
            <TeamDisplay
              key={baseKey}
              title={name}
              readOnly={
                baseKey === accountManager.baseKey
                  ? !canUpdateAccountManager()
                  : !currentUser.hasPermission(editPermission)
              }
              name={projectTeam[baseKey + 'Name']}
              phoneNumber={projectTeam[baseKey + 'PhoneNumber']}
              email={projectTeam[baseKey + 'Email']}
              image={getImage(projectTeam[baseKey + 'Id'])}
              onChange={({ id }) => updateProjectTeam({ [baseKey + 'Id']: id })}
              options={
                baseKey === accountManager.baseKey ? accountManagers : users
              }
            />
          ))}
        </Paper>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Paper style={{ padding: 16 }}>
          <Typography component="h1" variant="h5" gutterBottom>
            Customer Contact
          </Typography>
          <TeamDisplay
            title="Customer Project Manager"
            readOnly={!tier1_5User}
            name={projectTeam.customerProjectManagerName}
            phoneNumber={getPersonPhoneNumber(
              'customerProjectManagerPhoneNumbers'
            )}
            email={
              projectTeam.customerProjectManagerEmails &&
              projectTeam.customerProjectManagerEmails[0]
                ? projectTeam.customerProjectManagerEmails[0].email
                : null
            }
            onChange={({ id }) =>
              updateProjectTeam({ customerProjectManagerId: id })
            }
            options={people}
          />
          <TeamDisplay
            title="Customer Initial Contact"
            readOnly={!tier1_5User}
            name={projectTeam.customerInitialContactName}
            phoneNumber={getPersonPhoneNumber(
              'customerInitialContactPhoneNumbers'
            )}
            email={
              projectTeam.customerInitialContactEmails &&
              projectTeam.customerInitialContactEmails[0]
                ? projectTeam.customerInitialContactEmails[0].email
                : null
            }
            onChange={({ id }) =>
              updateProjectTeam({ customerInitialContactId: id })
            }
            options={people}
          />
          <TeamDisplay
            title="Customer Job Site Contact"
            readOnly={!tier1_5User}
            name={projectTeam.customerOnSiteContactName}
            phoneNumber={getPersonPhoneNumber(
              'customerOnSiteContactPhoneNumbers'
            )}
            email={
              projectTeam.customerOnSiteContactEmails &&
              projectTeam.customerOnSiteContactEmails[0]
                ? projectTeam.customerOnSiteContactEmails[0].email
                : null
            }
            onChange={({ id }) =>
              updateProjectTeam({ customerOnSiteContactId: id })
            }
            options={people}
          />
        </Paper>
      </Grid>

      <Grid item sm={6} xs={12}>
        <PlangridUsers />
      </Grid>

      <Grid item sm={6} xs={12}>
        <EmployeeHours />
      </Grid>

      <ChildTeams getImage={getImage} />
    </Grid>
  );
}

export default connect(state => ({
  adminSettings: state.adminSettings,
  currentUser: state.user
}))(TeamTab);
