export default function getFormattedStats(details) {
  const bcd = numOr0(details.currentlyBilled) - numOr0(details.currentCost);
  const leftToBill =
    numOr0(details.dollarValue) - numOr0(details.currentlyBilled);
  const complete = numOr0(details.currentCost) / numOr0(details.estimatedCost);

  return {
    contractAmount: dollar(details.dollarValue),
    previouslyBilled: dollar(details.currentlyBilled),
    leftToBill: dollar(leftToBill),
    cost: dollar(details.currentCost),
    billedCostDifference: dollar(bcd),
    bcdOverBilled: percent(bcd / numOr0(details.dollarValue)),
    estimatedCost: dollar(details.estimatedCost),
    complete: percent(complete)
  };
}

function dollar(number) {
  number = numOrNull(number);
  const text =
    number === null || number === 0
      ? '---'
      : `$ ${addCommas(number.toFixed(2))}`;
  const value = number === null || number === 0 ? null : number;
  return { text, value };
}

function percent(number) {
  number = numOrNull(number);
  number = number === 0 ? null : number;
  number = number === Infinity ? null : number;
  number = number === -Infinity ? null : number;
  const text = number === null ? '---' : `${(number * 100).toFixed(2)} %`;
  return { text, value: number };
}

function numOrNull(number) {
  number = Number(number);
  return isNaN(number) ? null : number;
}

function numOr0(number) {
  number = Number(number);
  return isNaN(number) ? 0 : number;
}

function addCommas(string) {
  const [numSide, decSide] = string.split('.');
  const nums = numSide.match(/\d+/)[0] || '';
  const arr = nums.split('');
  let adj = 0;
  for (let i = 3; i < nums.length; i += 3) {
    arr.splice(-(i + adj), 0, ',');
    adj++;
  }
  return `${numSide.replace(nums, arr.join(''))}.${decSide}`;
}
