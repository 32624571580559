import React, { useContext, useState } from 'react';
import { NewhireChecklistContext } from 'routes/Users/context/User.context';
import ChecklistStep from './ChecklistStep.component';
import { IconButton } from 'helpers/themeSafeMui.helper';
import More from '@material-ui/icons/MoreVert';
import ChecklistModal from './ChecklistModal.component';
import { useSelector } from 'react-redux';
import useAutomatedChecklistItems from './useAutomatedChecklistItems.hook';

export default function NewhireChecklist() {
  const [modalOpen, setModalOpen] = useState(false);
  const { newhireChecklist } = useContext(NewhireChecklistContext);
  const currentUser = useSelector(state => state.user);
  const automatedItems = useAutomatedChecklistItems();

  const canView = currentUser.hasPermission('checklist_tier_1');
  if (!canView) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ChecklistModal open={modalOpen} onClose={() => setModalOpen(false)} />
      <div style={{ display: 'flex', flex: '0 1 100%', height: 24 }}>
        {automatedItems.map(item => (
          <ChecklistStep key={item.title} item={item} locked={true} />
        ))}

        {newhireChecklist.map(item => (
          <ChecklistStep key={item.id} item={item} />
        ))}
      </div>
      <IconButton size="small" onClick={() => setModalOpen(true)}>
        <More />
      </IconButton>
    </div>
  );
}
