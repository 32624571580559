import React from 'react';
import { Toast } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

class CreateTrainingModule extends React.Component {
  state = {
    name: '',
    testLink: '',
    courseLink: '',
    handoutLink: '',
    daysToExpire: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();

    http()
      .post(`/training-groups/${this.props.groupId}/training-modules`, {
        name: this.state.name,
        order: this.props.nextSpot,
        testLink: this.state.testLink,
        courseLink: this.state.courseLink,
        handoutLink: this.state.handoutLink,
        daysToExpire: this.state.daysToExpire || 0
      })
      .then(res => {
        Toast.show('Training Module created.');
        this.props.onClose();
        this.props.refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { onClose } = this.props;

    return (
      <form autoComplete="off" onSubmit={this.save}>
        <DialogTitle>New Training Module</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Name"
            fullWidth
            value={this.state.name}
            name="name"
            margin="normal"
            onChange={this.handleChange}
            required
          />
          <TextField
            label="Days to Expire"
            type="number"
            fullWidth
            value={this.state.daysToExpire}
            name="daysToExpire"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Course Link"
            fullWidth
            required
            value={this.state.courseLink}
            name="courseLink"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Quiz Link"
            fullWidth
            value={this.state.testLink}
            name="testLink"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Handout Link"
            fullWidth
            value={this.state.handoutLink}
            name="handoutLink"
            margin="normal"
            onChange={this.handleChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default CreateTrainingModule;
