import React from 'react';
import { Button, TextField } from 'helpers/themeSafeMui.helper';
import { Link } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast, CardWrapper } from 'components';
import { setUser } from 'ducks/user.duck';
import { connect } from 'react-redux';

class SignIn extends React.Component {
  state = {
    email: '',
    password: ''
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.message) {
      Toast.show(this.props.location.state.message);
      this.props.history.replace({ pathname: '/', state: {} });
    }
  }

  signIn = e => {
    e.preventDefault();
    http()
      .post('/users/auth/sign-in', {
        email: this.state.email,
        password: this.state.password
      })
      .then(user => this.props.setUser(user))
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <CardWrapper>
        <form autoComplete="off" onSubmit={this.signIn}>
          <TextField
            name="email"
            label="Email Address"
            autoFocus
            required
            fullWidth
            margin="normal"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextField
            name="password"
            label="Password"
            autoComplete="current-password"
            type="password"
            required
            fullWidth
            margin="normal"
            value={this.state.password}
            onChange={this.handleChange}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign in
          </Button>

          <Button
            component={Link}
            fullWidth
            to="/forgot-password"
            size="small"
            color="primary"
            style={{ margin: '8px 0 15px' }}
          >
            Forgot password?
          </Button>
        </form>
      </CardWrapper>
    );
  }
}

export default connect(null, { setUser })(SignIn);
