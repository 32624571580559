import React from 'react';
import { TableHead, TableCell, TableRow } from 'helpers/themeSafeMui.helper';

function DndTableHead({ children, dragIndicatorCell, rowProps, ...rest }) {
  return (
    <TableHead {...rest}>
      <TableRow {...rowProps}>
        {dragIndicatorCell ? dragIndicatorCell : <TableCell></TableCell>}
        {children}
      </TableRow>
    </TableHead>
  );
}

export default DndTableHead;
