import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Divider,
  Typography
} from 'helpers/themeSafeMui.helper';
import SignaturePad from 'signature_pad';
import { useColors } from 'helpers/theme.helper';
import WebFont from 'webfontloader';
import trim from 'trim-canvas';

const fonts = [
  'Caveat',
  'Dawning of a New Day',
  'Nothing You Could Do',
  'Shadows Into Light',
  'Cedarville Cursive'
];

const SignatureSelect = props => {
  const { open, onClose, signer, onSave } = props;
  const colors = useColors();

  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [width, setWidth] = useState(0);
  const [name, setName] = useState(signer.name);
  const [selectedFont, setSelectedFont] = useState(null);
  const [selectedCanvas, setSelectedCanvas] = useState(null);
  const [signaturePad, setSignaturePad] = useState(null);

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts
      },
      active: () => setFontsLoaded(true)
    });
  }, []);

  const draw = (canvas, font, text, { height = 50, width = 100 } = {}) => {
    if (canvas) {
      const fontSize = Math.round(height * 0.6);
      const ctx = canvas.getContext('2d');

      ctx.canvas.width = width;
      ctx.canvas.height = height;

      ctx.font = `${fontSize}px ${font}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, width / 2, height / 2);
    }
  };

  const save = () => {
    if (!selectedCanvas || !name) {
      return alert('Please select a signature to continue.');
    }

    const initials = name
      .split(' ')
      .map(w => w[0].toUpperCase())
      .join('');

    if (selectedFont === 'draw') {
      const signatureCanvas = document.createElement('canvas');
      const ctx = signatureCanvas.getContext('2d');
      ctx.canvas.width = selectedCanvas.width * 3;
      ctx.canvas.height = selectedCanvas.height * 3;
      ctx.drawImage(
        selectedCanvas,
        0,
        0,
        selectedCanvas.width * 3,
        selectedCanvas.height * 3
      );

      const initialsCanvas = document.createElement('canvas');
      draw(initialsCanvas, fonts[0], initials, {
        height: 500,
        width: 1000
      });

      onSave({
        signatureImage: trim(signatureCanvas).toDataURL(),
        initialsImage: trim(initialsCanvas).toDataURL(),
        name: name
      });
    } else {
      const initialsCanvas = document.createElement('canvas');
      draw(initialsCanvas, selectedFont, initials, {
        height: 500,
        width: 1000
      });
      const signatureCanvas = document.createElement('canvas');
      draw(signatureCanvas, selectedFont, name, {
        height: 500,
        width: 5000
      });

      onSave({
        signatureImage: trim(signatureCanvas).toDataURL(),
        initialsImage: trim(initialsCanvas).toDataURL(),
        name: name
      });
    }
  };

  const signaturePadRef = useCallback(el => {
    if (el) {
      setSignaturePad(new SignaturePad(el));
      const ctx = el.getContext('2d');
      ctx.scale(5, 5);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>Select a Signature</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          required
          label="Full Name"
          fullWidth
          value={name}
          margin="dense"
          onChange={e => setName(e.target.value)}
        />

        <div ref={el => el && setWidth(el.offsetWidth)}>
          {fontsLoaded &&
            fonts.map(font => (
              <canvas
                key={font}
                style={{
                  margin: '8px 0',
                  display: 'block',
                  cursor: 'pointer',
                  border: `${selectedFont === font ? '1.5px' : '1px'} solid ${
                    selectedFont === font ? colors.primary : colors.grey
                  }`
                }}
                ref={el => draw(el, font, name, { width: width })}
                onClick={e => {
                  setSelectedCanvas(e.target);
                  setSelectedFont(font);
                }}
              />
            ))}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            }}
          >
            <Typography>Draw your signature...</Typography>
            <Button
              size="small"
              onClick={() => signaturePad.clear()}
              color="primary"
            >
              Reset
            </Button>
          </div>
          <canvas
            height={100}
            width={width}
            style={{
              margin: '8px 0',
              display: 'block',
              border: `${selectedFont === 'draw' ? '1.5px' : '1px'} solid ${
                selectedFont === 'draw' ? colors.primary : colors.grey
              }`
            }}
            onMouseDown={e => {
              setSelectedCanvas(e.target);
              setSelectedFont('draw');
            }}
            ref={signaturePadRef}
          />
        </div>

        <DialogContentText variant="caption">
          The parties agree that this agreement may be electronically signed.
          The parties agree that the electronic signatures appearing on this
          agreement are the same as handwritten signatures for the purposes of
          validity, enforceability and admissibility.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button onClick={save} color="primary" variant="contained">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureSelect;
