import React, { useState, useEffect } from 'react';
import { Button, Typography, Switch, Link } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { DragAndDrop, Toast } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectSigner from './SelectSigner.component';
import signerColors from '../helpers/signerColors.helper';

const SortableSigners = props => {
  const { signers, document, onUpdate, isTemplate } = props;
  const [selectedSigner, setSelectedSigner] = useState(null);
  const [orderedSigning, setOrderedSigning] = useState(false);

  useEffect(() => {
    setOrderedSigning(!signers.every(signer => signer.signingOrder === 0));
  }, [signers]);

  const handleSubmit = ({ name, email, id }) => {
    if (id !== 'NEW') {
      http()
        .put(`/esign-documents/${document.id}/signers/${id}`, {
          name,
          email
        })
        .then(res => onUpdate(res))
        .catch(e => Toast.show(e.message));
    } else {
      http()
        .post(`/esign-documents/${document.id}/signers`, {
          name,
          email,
          signingOrder: orderedSigning ? signers.length : 0
        })
        .then(res => onUpdate(res))
        .catch(e => Toast.show(e.message));
    }
  };

  const handleDelete = id => {
    http()
      .delete(`/esign-documents/${document.id}/signers/${id}`)
      .then(res => onUpdate(res))
      .catch(e => Toast.show(e.message));
  };

  const syncSigningOrder = (signers, orderedSigning) => {
    const updatedSigners = signers.map((signer, idx) => ({
      ...signer,
      signingOrder: orderedSigning ? idx : 0
    }));

    onUpdate(updatedSigners);

    Promise.all(
      updatedSigners.map((signer, idx) =>
        http().put(`/esign-documents/${document.id}/signers/${signer.id}`, {
          signingOrder: signer.signingOrder
        })
      )
    ).catch(e => Toast.show(e.message));
  };

  return (
    <div style={{ width: '100%' }}>
      <SelectSigner
        selectedSigner={selectedSigner}
        onClose={() => setSelectedSigner(null)}
        isTemplate={isTemplate}
        onSubmit={handleSubmit}
      />

      {!signers.length && (
        <Typography align="center" variant="body2" style={{ margin: '16px 0' }}>
          <strong>Add a signer to get started...</strong>
        </Typography>
      )}

      <DragAndDrop
        onDrop={signers => syncSigningOrder(signers, orderedSigning)}
      >
        {signers.map((signer, idx) => (
          <div
            id={signer.id}
            wrapperStyle={{
              borderLeft: `3px solid ${signerColors(idx)}`
            }}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            key={signer.id}
            value={signer}
          >
            <div>
              <Typography>{signer.name}</Typography>
              {signer.email ? (
                <Typography variant="caption">{signer.email}</Typography>
              ) : (
                <Typography
                  variant="caption"
                  onClick={() => setSelectedSigner(signer.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <Link>Set Signer Role</Link>
                </Typography>
              )}
            </div>

            <DeleteIcon
              style={{ marginLeft: 10, cursor: 'pointer' }}
              color="action"
              fontSize="small"
              onClick={() => handleDelete(signer.id)}
            />
          </div>
        ))}
      </DragAndDrop>

      <Button
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
        onClick={() => setSelectedSigner('NEW')}
        style={{ margin: '8px 0' }}
      >
        + Add Signer
      </Button>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2">
          <strong>Signing Order</strong>
        </Typography>
        <Switch
          checked={orderedSigning}
          onChange={e => syncSigningOrder(signers, e.target.checked)}
        />
      </div>
      <Typography variant="body2">
        Having signing order turned on will send the document to each signer
        only once the previous party has signed. Having it off will send the
        document to all parties at once.
      </Typography>
    </div>
  );
};

export default SortableSigners;
