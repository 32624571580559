import React, { useState, useEffect, useCallback } from 'react';
import { DragAndDrop, Toast, ExternalLink } from 'components';
import { Typography, Button, IconButton } from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import http from 'helpers/http.helper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { useColors } from 'helpers/theme.helper';
import { IoMdCloseCircle, IoMdCheckmarkCircle } from 'react-icons/io';

export default function TrainingModules(props) {
  const { trainingGroup, showModal, trainingGroups, getTrainingGroups } = props;
  const [trainingModules, setTrainingModules] = useState([]);
  const colors = useColors();

  const [
    open,
    setOpen
  ] = useLocalStorage(
    `admin_settings.training_modules.${trainingGroup.id}.open`,
    false,
    joi => joi.boolean()
  );

  const getModules = useCallback(() => {
    if (trainingGroups.length >= 0)
      http()
        .get(`/training-groups/${trainingGroup.id}/training-modules`)
        .then(res => {
          setTrainingModules(res);
        })
        .catch(err => Toast.show(err.message));
  }, [trainingGroup.id, trainingGroups]);

  useEffect(() => {
    getModules();
  }, [getModules]);

  const handleModuleDrop = trainingModules => {
    setTrainingModules(trainingModules);

    Promise.all(
      trainingModules.map((trainingModule, index) => {
        return http().put(
          `/training-groups/${trainingGroup.id}/training-modules/${trainingModule.id}`,
          { order: index + 1 }
        );
      })
    )
      .then(() => {
        Toast.show('Updated');
      })
      .catch(err => Toast.show(err.message));
  };
  const iconStyle = {
    position: 'absolute',
    top: 0,
    right: 0
  };

  if (!open) {
    return (
      <div>
        <IconButton style={iconStyle} onClick={() => setOpen(true)}>
          <ExpandMoreIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: 16
      }}
    >
      <IconButton style={iconStyle} onClick={() => setOpen(false)}>
        <ExpandLessIcon />
      </IconButton>
      <DragAndDrop onDrop={handleModuleDrop}>
        {trainingModules.map(trainingModule => (
          <div
            key={trainingModule.id}
            id={trainingModule.id}
            value={trainingModule}
            style={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h6">{trainingModule.name}</Typography>

              <EditIcon
                style={{ marginLeft: 10, cursor: 'pointer' }}
                color="action"
                fontSize="small"
                onClick={() =>
                  showModal({
                    type: 'EDIT_TRAINING_MODULE',
                    props: {
                      trainingGroup: trainingGroup,
                      refresh: getTrainingGroups,
                      trainingModule: trainingModule,
                      trainingModules: trainingModules,
                      trainingGroups
                    }
                  })
                }
              />
            </div>

            <div style={{ padding: 8 }}>
              {Boolean(trainingModule.daysToExpire) && (
                <Typography style={{ wordBreak: 'break-all' }}>
                  Days to Expire: {trainingModule.daysToExpire}
                </Typography>
              )}

              <Typography style={{ wordBreak: 'break-all' }}>
                Course Link:{' '}
                {trainingModule.courseLink && (
                  <ExternalLink href={trainingModule.courseLink}>
                    {trainingModule.courseLink}
                  </ExternalLink>
                )}
              </Typography>

              <Typography style={{ wordBreak: 'break-all' }}>
                Quiz Link:{' '}
                {trainingModule.testLink && (
                  <ExternalLink href={trainingModule.testLink}>
                    {trainingModule.testLink}
                  </ExternalLink>
                )}
              </Typography>

              <Typography style={{ wordBreak: 'break-all' }}>
                STEPS Quiz:{' '}
                {trainingModule.quizId ? (
                  <IoMdCheckmarkCircle
                    style={{ verticalAlign: 'text-bottom' }}
                    size={19}
                    color={colors.success}
                  />
                ) : (
                  <IoMdCloseCircle
                    style={{ verticalAlign: 'text-bottom' }}
                    size={19}
                    color={colors.error}
                  />
                )}
              </Typography>

              <Typography style={{ wordBreak: 'break-all' }}>
                Handout Link:{' '}
                {trainingModule.handoutLink && (
                  <ExternalLink href={trainingModule.handoutLink}>
                    {trainingModule.handoutLink}
                  </ExternalLink>
                )}
              </Typography>
            </div>
          </div>
        ))}
      </DragAndDrop>

      <Button
        color="primary"
        variant="outlined"
        fullWidth
        onClick={() =>
          showModal({
            type: 'CREATE_TRAINING_MODULE',
            props: {
              refresh: getModules,
              nextSpot: trainingModules.length,
              groupId: trainingGroup.id
            }
          })
        }
      >
        New Training Module
      </Button>
    </div>
  );
}
