import React, { useState, useEffect, Fragment } from 'react';
import http from 'helpers/http.helper';
import {
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from 'helpers/themeSafeMui.helper';
import { Toast, UserSearch, NoItemsResult } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';

function PerDiemApprovers() {
  const [approvers, setApprovers] = useState([]);
  useEffect(() => {
    http()
      .get('/per-diems/approvers')
      .then(res => setApprovers(res))
      .catch(err => Toast.show(err.message));
  }, []);

  const addApprover = user => {
    if (user) {
      http()
        .post('/per-diems/approvers', { userId: user.id })
        .then(res => setApprovers(old => [res, ...old]))
        .catch(err => Toast.show(err.message));
    }
  };

  const removeApprover = approver => () => {
    http()
      .delete(`/per-diems/approvers/${approver.id}`)
      .then(() =>
        setApprovers(old => old.filter(other => other.id !== approver.id))
      )
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      <Typography component="h1" variant="h5" gutterBottom>
        Per Diem Approvers
      </Typography>
      <div style={{ padding: '5px 0' }}>
        <UserSearch title="Add Approver" onChange={addApprover} />
      </div>
      <List>
        {approvers.map(approver => (
          <Fragment key={approver.id}>
            <Divider />
            <ListItem>
              <ListItemText primary={approver.name} />
              <ListItemIcon>
                <IconButton onClick={removeApprover(approver)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          </Fragment>
        ))}
        {!approvers.length && <NoItemsResult type="approvers" />}
      </List>
    </div>
  );
}

export default PerDiemApprovers;
