import React from 'react';
import {
  DialogActions,
  Button,
  DialogTitle
} from 'helpers/themeSafeMui.helper';

class ErrorModal extends React.Component {
  render() {
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <DialogTitle>Modal not found.</DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default ErrorModal;
