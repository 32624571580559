const SHOW_MODAL = 'modals/SET_MODAL';
const CLOSE_MODAL = 'modals/CLOSE_MODAL';

const initialState = {
  type: null,
  size: 'sm',
  props: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MODAL:
      return action.payload;
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}

export const showModal = ({ type, props, size, fullScreen }) => {
  return {
    type: SHOW_MODAL,
    payload: { type, props, size, fullScreen }
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL
  };
};
