import React from 'react';
import { Grid } from 'helpers/themeSafeMui.helper';
import CompanyProjects from '../components/CompanyProjects.component';
import CompanyPeople from '../components/CompanyPeople.component';

function Home(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CompanyPeople />
      </Grid>
      <Grid item xs={6}>
        <CompanyProjects />
      </Grid>
    </Grid>
  );
}

export default Home;
