const f = Object.freeze;

export const MATERIAL_FORM_FIELDS = f([
  // f({
  //   label: 'Manufacturer',
  //   key: 'manufacturer',
  //   type: 'text'
  // }),
  f({
    label: 'Model',
    key: 'model',
    type: 'text'
  }),
  f({
    label: 'Part Number',
    key: 'partNumber',
    type: 'text'
  }),
  f({
    label: 'Description',
    key: 'description',
    type: 'text'
  }),
  f({
    label: 'Unit Cost',
    key: 'unitCost',
    type: 'currency'
  }),
  f({
    label: 'Unit Labor',
    key: 'unitLabor',
    type: 'number'
  }),
  f({
    label: 'Spec Sheet',
    key: 'specSheet',
    type: 'text'
  }),
  f({
    label: 'OM',
    key: 'OM',
    type: 'text'
  })
]);
