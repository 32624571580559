import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSetActionItems } from 'state/actionItems.state';

export const ProjectContext = React.createContext();
export const ProjectDetailsContext = React.createContext();
export const ProjectNotesContext = React.createContext();
export const ProjectActionItemsContext = React.createContext();
export const ProjectTeamContext = React.createContext();
export const ProjectChecklistContext = React.createContext();
export const ProjectChangeOrdersContext = React.createContext();
export const ProjectScheduleTasksContext = React.createContext();
export const ProjectRfisContext = React.createContext();
export const ProjectPhotosContext = React.createContext();
export const ServiceItemsContext = React.createContext();
export const RefreshProjectContext = React.createContext();

const ProjectContextProvider = props => {
  const { user } = props;
  const projectId = props.match.params.id;
  const [project, setProject] = useState({});
  const [projectDetails, setProjectDetails] = useState({});
  const [projectNotes, setProjectNotes] = useState([]);
  const [projectActionItems, setProjectActionItems] = useState([]);
  const [projectTeam, setProjectTeam] = useState({});
  const [projectChecklist, setProjectChecklist] = useState([]);
  const [projectChangeOrders, setProjectChangeOrders] = useState([]);
  const [projectScheduleTasks, setProjectScheduleTasks] = useState([]);
  const allActionItems = useSelector(state => state.actionItems);
  const [projectRfis, setProjectRfis] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [serviceItemTemplates, setServiceItemTemplates] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const setActionItems = useSetActionItems();

  const fetchTasks = useCallback(() => {
    http()
      .get(`/projects/${projectId}/schedule-tasks`)
      .then(cos => {
        setProjectScheduleTasks(cos);
      })
      .catch(err => Toast.show(err.message));
  }, [projectId]);

  const refreshProject = useCallback(() => {
    let stale = false;
    const setVal = setter => res => {
      if (!stale) setter(res);
      return res;
    };
    http()
      .get(`/projects/${projectId}`)
      .then(setVal(setProject))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/details`)
      .then(setVal(setProjectDetails))
      .catch(err => Toast.show(err.message));

    http()
      .get('/action-items/query?projectId=' + projectId)
      .then(
        setVal(({ actionItems }) => {
          setProjectActionItems(actionItems.map(item => item.id));
          setActionItems(actionItems);
        })
      )
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/change-orders`)
      .then(setVal(setProjectChangeOrders))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/teams`)
      .then(setVal(setProjectTeam))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/checklist-items`)
      .then(setVal(setProjectChecklist))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/rfis`)
      .then(setVal(setProjectRfis))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/photos`)
      .then(setVal(setPhotos))
      .catch(err => Toast.show(err.message));

    http()
      .get(`/projects/${projectId}/notes`)
      .then(setVal(setProjectNotes))
      .catch(err => Toast.show(err.message));

    if (user.hasPermission('projects_tier_2')) {
      http()
        .get(`/projects/${projectId}/service-items`)
        .then(setVal(setServiceItems))
        .catch(err => Toast.show(err.message));

      http()
        .get(`/projects/${projectId}/service-items/templates`)
        .then(setVal(setServiceItemTemplates))
        .catch(err => Toast.show(err.message));
    }
    return () => {
      stale = true;
    };
  }, [projectId, user, setActionItems]);

  const setServiceItem = useCallback(item => {
    setServiceItems(old => {
      const idx = old.findIndex(other => other.number === item.number);
      if (idx === -1) {
        return [...old, item];
      } else {
        const copy = [...old];
        copy[idx] = item;
        return copy;
      }
    });
  }, []);

  useEffect(() => {
    setProjectActionItems(old => {
      const itemsToAdd = Object.values(allActionItems)
        .filter(
          item =>
            item.projectId &&
            item.projectId === projectId &&
            !old.includes(item.id)
        )
        .map(item => item.id);
      return itemsToAdd.length ? [...itemsToAdd, ...old] : old;
    });
  }, [allActionItems, projectId]);

  useEffect(() => refreshProject(), [refreshProject, projectId, user]);
  useEffect(() => fetchTasks(), [fetchTasks]);

  useEffect(() => {
    setServiceItems(old => {
      const outOfOrder = old.some((item, idx) => {
        if (idx === 0) return false;
        if (old[idx - 1].number > item.number) return true;
        return false;
      });
      if (!outOfOrder) return old;
      const copy = [...old];
      return copy.sort((a, b) => a.number - b.number);
    });
  }, [serviceItems]);

  return (
    <ProjectContext.Provider value={{ project, setProject }}>
      <ProjectDetailsContext.Provider
        value={{ projectDetails, setProjectDetails }}
      >
        <ProjectNotesContext.Provider value={{ projectNotes, setProjectNotes }}>
          <ProjectActionItemsContext.Provider
            value={{ projectActionItems, setProjectActionItems }}
          >
            <ProjectTeamContext.Provider
              value={{ projectTeam, setProjectTeam }}
            >
              <ProjectChecklistContext.Provider
                value={{ projectChecklist, setProjectChecklist }}
              >
                <ProjectChangeOrdersContext.Provider
                  value={{ projectChangeOrders, setProjectChangeOrders }}
                >
                  <ProjectScheduleTasksContext.Provider
                    value={{
                      projectScheduleTasks,
                      setProjectScheduleTasks,
                      fetchTasks
                    }}
                  >
                    <ProjectPhotosContext.Provider
                      value={{ photos, setPhotos }}
                    >
                      <ProjectRfisContext.Provider
                        value={{ projectRfis, setProjectRfis }}
                      >
                        <ServiceItemsContext.Provider
                          value={{
                            serviceItemTemplates,
                            serviceItems,
                            setServiceItems,
                            setServiceItem
                          }}
                        >
                          <RefreshProjectContext.Provider
                            value={refreshProject}
                          >
                            {props.children}
                          </RefreshProjectContext.Provider>
                        </ServiceItemsContext.Provider>
                      </ProjectRfisContext.Provider>
                    </ProjectPhotosContext.Provider>
                  </ProjectScheduleTasksContext.Provider>
                </ProjectChangeOrdersContext.Provider>
              </ProjectChecklistContext.Provider>
            </ProjectTeamContext.Provider>
          </ProjectActionItemsContext.Provider>
        </ProjectNotesContext.Provider>
      </ProjectDetailsContext.Provider>
    </ProjectContext.Provider>
  );
};

export default withRouter(
  connect(state => ({ user: state.user }))(ProjectContextProvider)
);
