import React, { useState, useEffect, useRef } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';

const LoadingOverlay = props => {
  const wrapperRef = useRef();

  const [dots, setDots] = useState([' ', ' ', ' ']);
  const [yPos, setYPos] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(d => {
        const idx = d.indexOf(' ');
        if (idx === -1) {
          return [' ', ' ', ' '];
        } else {
          return d.map((dot, i) => (i === idx ? '.' : dot));
        }
      });
    }, 400);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (!wrapperRef.current) return;
      setYPos(wrapperRef.current.getBoundingClientRect().y);
    };

    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'relative',
        pointerEvents: props.loading && 'none',
        minHeight: props.loading && 400,
        ...props.style
      }}
    >
      {props.loading && (
        <div
          style={{
            background: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 100
          }}
        >
          <div
            style={{
              color: '#FFFFFF',
              top: 200,
              position: yPos > 0 ? 'absolute' : 'fixed'
            }}
          >
            <Typography variant="h5">
              LOADING<pre style={{ display: 'inline' }}>{dots.join('')}</pre>
            </Typography>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
};

export default LoadingOverlay;
