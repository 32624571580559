import React from 'react';
import {
  Divider,
  IconButton,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import CancelIcon from '@material-ui/icons/Cancel';
import { useColors } from 'helpers/theme.helper';

export default function ImageCard({ disabled, dataUrl, file, onRemoveClick }) {
  const colors = useColors();

  return (
    <Paper style={{ margin: 10, opacity: disabled ? 0.7 : null }}>
      {!!file && (
        <div
          style={{
            width: 260,
            padding: '0 10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="caption" noWrap>
            {file.name}
          </Typography>
          <IconButton disabled={disabled} onClick={onRemoveClick}>
            <CancelIcon />
          </IconButton>
        </div>
      )}
      <Divider />
      <div
        style={{
          backgroundColor: colors.lightGrey,
          width: 260,
          height: 260,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          src={dataUrl}
          alt="project"
          style={{
            maxHeight: 250,
            maxWidth: 250,
            objectFit: 'contain'
          }}
        />
      </div>
    </Paper>
  );
}
