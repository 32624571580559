import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import ViewWorkOrderModal from '../modals/ViewWorkOrder.modal';

export default function WorkOrderListItem({
  workOrder,
  selected,
  onSelect,
  getWorkOrders,
  disabled = false
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Checkbox
            disabled={disabled}
            checked={selected}
            onChange={e => onSelect(e.target.checked)}
          />
        </ListItemIcon>
        <ListItemIcon>
          <Button
            disabled={disabled}
            onClick={() => setModalOpen(true)}
            variant="contained"
            color="primary"
            style={{ marginRight: 15 }}
          >
            View
          </Button>
        </ListItemIcon>
        <ListItemText>{workOrder.name}</ListItemText>
      </ListItem>
      <ViewWorkOrderModal
        getWorkOrders={getWorkOrders}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        workOrder={workOrder}
      />
    </>
  );
}
