import React from 'react';
import { Button, Grid, Typography } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';

function WorkOrderSignOff(props) {
  const { item, canEdit, refresh, showModal } = props;

  const openSignatureModal = () => {
    showModal({
      type: 'CAPTURE_SIGNATURE',
      props: {
        projectId: item.projectId,
        workOrderId: item.id,
        refresh: refresh
      },
      size: 'lg'
    });
  };

  const openSignOffModal = () => {
    showModal({
      type: 'WORK_ORDER_SIGN_OFF',
      props: {
        projectId: item.projectId,
        workOrderId: item.id,
        refresh: refresh
      }
    });
  };

  return (
    <React.Fragment>
      {item.techSignOff && (
        <Grid item xs={12}>
          <Typography variant="h5">Customer Acceptance</Typography>

          {item.dateOfCustomerAcceptance ? (
            <div>
              <img
                src={item.signatureBase64}
                style={{ marginTop: 16, marginBottom: 16, maxHeight: 100 }}
                alt="customer-sig"
              />

              <Typography>
                <strong>
                  Customer {item.customerName} signed off on:{' '}
                  {moment(item.dateOfCustomerAcceptance).format('LLL')}
                </strong>
              </Typography>
            </div>
          ) : canEdit ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={openSignatureModal}
            >
              Customer Sign Off
            </Button>
          ) : (
            'Not Completed.'
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        {item.techSignOff ? (
          <Typography>
            <strong>
              Lead Technician {item.leadTechnicianName} signed off on:{' '}
              {moment(item.techSignOff).format('LLL')}
            </strong>
          </Typography>
        ) : canEdit ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={openSignOffModal}
          >
            Complete Work Order
          </Button>
        ) : (
          <Typography>Not Completed</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default connect(state => ({ currentUser: state.user }), { showModal })(
  WorkOrderSignOff
);
