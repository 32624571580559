import React, { useEffect, useState } from 'react';
import { DashboardWrapper, Toast } from 'components';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import PerDiemTable from '../components/PerDiemTable.component';
import AddPerDiemModal from '../modals/EditPerDiem.modal';
import { IconButton, Typography } from 'helpers/themeSafeMui.helper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterPopover from '../components/FilterPopoverButton.component';
import PerDiemList from '../components/PerDiemList.component';
import useBreakpoint from 'hooks/useBreakpoint.hook';

const MyPerDiem = () => {
  const user = useSelector(state => state.user);
  const [perDiems, setPerDiems] = useState([]);
  const [filteredPerDiems, setFilteredPerDiems] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const isSmallScreen = useBreakpoint('xs');

  const createPerDiem = async data => {
    const copy = { ...data };
    delete copy.locked;
    delete copy.status;
    delete copy.paidOn;
    const newPerdiem = await http().post('/per-diems/', copy);
    setPerDiems(old => [...old, newPerdiem]);
  };

  useEffect(() => {
    if (user.id) {
      http()
        .get(`/per-diems?userId=${user.id}`)
        .then(perDiems => setPerDiems(perDiems))
        .catch(err => Toast.show(err.message));
    }
  }, [user.id]);

  return (
    <DashboardWrapper>
      <AddPerDiemModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create Per-Diem"
        hiddenFields={['status', 'locked', 'paidOn']}
        onSubmit={createPerDiem}
      />
      <div
        style={
          isSmallScreen
            ? {}
            : { display: 'flex', justifyContent: 'space-between' }
        }
      >
        <div>
          <Typography variant="h5" component="h1">
            My Per Diem
            <IconButton onClick={() => setCreateModalOpen(true)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Typography>
        </div>
        <FilterPopover
          allPerDiems={perDiems}
          setFiltered={setFilteredPerDiems}
          defaultVisibleStatuses={['Pending', 'Approved', 'Rejected', 'Paid']}
          style={{ marginBottom: isSmallScreen ? 10 : 0 }}
        />
      </div>
      {isSmallScreen ? (
        <PerDiemList setPerDiems={setPerDiems} perDiems={filteredPerDiems} />
      ) : (
        <PerDiemTable
          columns={[
            'project',
            'created',
            'startDate',
            'endDate',
            'dailyAmount',
            'totalAmount',
            'locked',
            'status',
            'paidOn'
          ]}
          setPerDiems={setPerDiems}
          perDiems={filteredPerDiems}
          height="calc(100vh - 160px)"
          initialOrderBy="created"
          initialOrder="asc"
        />
      )}
    </DashboardWrapper>
  );
};

export default MyPerDiem;
