import React, { useState, useEffect, useCallback } from 'react';
import http from 'helpers/http.helper';
import {
  Typography,
  Divider,
  IconButton,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Button,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';

function FleetioGroupsTab() {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [syncingUser, setSyncingUser] = useState(null);

  const getGroups = useCallback(() => {
    http()
      .get(`/fleetio/groups`)
      .then(groups => setGroups(groups))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => getGroups(), [getGroups]);

  useEffect(() => {
    http()
      .get(`/users?includeInactive=true`)
      .then(users => setUsers(users))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    let timeout;
    if (secondsRemaining > 0)
      timeout = setTimeout(() => {
        setSecondsRemaining(old => old - 1);
      }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsRemaining]);

  const syncLoop = async remainingUsers => {
    try {
      if (remainingUsers.length === 0) {
        setSyncingUser(null);
        setSecondsRemaining(0);
        return Toast.show('Sync finished.');
      }
      const [user, ...rest] = remainingUsers;
      setSyncingUser(user.name);
      await http().post('/fleetio/sync-user', {
        userId: user.id
      });
      syncLoop(rest);
    } catch (err) {
      if (err.wait && parseInt(err.wait)) {
        setSecondsRemaining(parseInt(err.wait) + 1);
        setTimeout(
          () => syncLoop(remainingUsers),
          (parseInt(err.wait) + 1) * 1000
        );
      } else {
        setSyncingUser(null);
        setSecondsRemaining(0);
        Toast.show(err.message);
      }
    }
  };

  const syncLoopRemoveDuplicates = async remainingUsers => {
    try {
      if (remainingUsers.length === 0) {
        setSyncingUser(null);
        setSecondsRemaining(0);
        return Toast.show('Sync finished.');
      }
      const [user, ...rest] = remainingUsers;
      setSyncingUser(user.name);
      await http().delete(`/fleetio/sync-user/${user.id}`);
      syncLoopRemoveDuplicates(rest);
    } catch (err) {
      if (err.wait && parseInt(err.wait)) {
        setSecondsRemaining(parseInt(err.wait) + 1);
        setTimeout(
          () => syncLoopRemoveDuplicates(remainingUsers),
          (parseInt(err.wait) + 1) * 1000
        );
      } else {
        setSyncingUser(null);
        setSecondsRemaining(0);
        Toast.show(err.message);
      }
    }
  };

  const removeGroup = groupId => async () => {
    try {
      await http().delete(`/fleetio/groups/${groupId}`);
      getGroups();
      Toast.show('Removed');
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const isSyncing = secondsRemaining > 0 || !!syncingUser;

  return (
    <>
      <Typography component="h1" variant="h5" gutterBottom>
        Fleetio Groups
      </Typography>
      <div>
        <Button
          disabled={isSyncing}
          onClick={() =>
            syncLoop(users.filter(u => u.employmentStatus !== 'Inactive'))
          }
          variant="contained"
          color="primary"
        >
          Sync All Users
          {isSyncing && (
            <span style={{ paddingLeft: 5 }}>
              <CircularProgress size={20} />
            </span>
          )}
        </Button>
        <Button
          disabled={isSyncing}
          style={{ marginLeft: 10 }}
          onClick={() => syncLoopRemoveDuplicates(users)}
          variant="contained"
          color="primary"
        >
          Remove duplicates
          {isSyncing && (
            <span style={{ paddingLeft: 5 }}>
              <CircularProgress size={20} />
            </span>
          )}
        </Button>

        {isSyncing && (
          <>
            <Typography
              style={{ paddingLeft: 10 }}
              component="div"
              variant="caption"
            >
              {secondsRemaining > 0
                ? `Fleetio requests limited. Waiting ${secondsRemaining}.`
                : !!syncingUser
                ? `Syncing ${syncingUser}.`
                : null}
            </Typography>
            <Typography
              style={{ paddingLeft: 10 }}
              component="div"
              variant="caption"
              gutterBottom
            >
              Stay on this page until syncing finishes.
            </Typography>
          </>
        )}
      </div>
      <List>
        <Divider />
        {groups.map(group => (
          <React.Fragment key={group.id}>
            <ListItem>
              <ListItemText
                primary={group.name}
                secondary={`User Count: ${group.userCount}`}
              />
              <ListItemIcon>
                <IconButton onClick={removeGroup(group.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </>
  );
}

export default FleetioGroupsTab;
