import React, { useCallback, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  Typography
} from 'helpers/themeSafeMui.helper';
import {
  ProjectContext,
  ProjectDetailsContext
} from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import PotentialPosWorkflow from './PotentialPos';
import { useQueryParams } from 'hooks/useQueryParams.hook';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';
import PendingPopover from './components/PendingPopover.component';
import ProjectWon from './ProjectWon';

const workflowComponents = {
  // [workflowId]: WorkflowComponent
  potential_pos: PotentialPosWorkflow,
  project_won: ProjectWon
};

const ProjectWorkflows = () => {
  const [queryParams, updateQueryParams] = useQueryParams();
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const { project } = useContext(ProjectContext);
  const currentUser = useSelector(state => state.user);
  const colors = useColors();

  const unhiddenWorkflows = projectDetails.workflows
    ? projectDetails.workflows.filter(wf => !wf.hidden)
    : [];

  const queryWorkflow = queryParams.workflowName &&
    queryParams.workflowId && {
      name: queryParams.workflowName,
      id: queryParams.workflowId
    };
  const currentWorkflow = queryWorkflow || unhiddenWorkflows[0];
  const currentWorkflowComponent =
    currentWorkflow && workflowComponents[currentWorkflow.id];
  const open = !!currentWorkflowComponent;

  const completeWorkflow = useCallback(async () => {
    updateQueryParams({});
    if (!projectDetails.workflows || !currentWorkflow) return;
    const updated = projectDetails.workflows.filter(
      wf => wf.id !== currentWorkflow.id
    );
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        workflows: updated
      })
      .then(setProjectDetails)
      .catch(Toast.showErr);
  }, [
    updateQueryParams,
    projectDetails.workflows,
    projectDetails.projectId,
    setProjectDetails,
    currentWorkflow
  ]);

  const addReminder = () => {
    return http()
      .post('/action-items', {
        projectId: projectDetails.projectId,
        title: `Complete "${currentWorkflow.name}" workflow for project ${project.projectNumber}`,
        actionItemLink: `/projects/${projectDetails.projectId}?workflowName=${currentWorkflow.name}&workflowId=${currentWorkflow.id}`,
        dueDate: moment().add(1, 'day'),
        assignedUserIds: [currentUser.id]
      })
      .then(() => Toast.show('Reminder added'))
      .catch(Toast.showErr);
  };

  const hideWorkflow = async () => {
    if (!currentWorkflow) return;
    if (!projectDetails.workflows) return;
    const idx = projectDetails.workflows.findIndex(
      wf => wf.id === currentWorkflow.id
    );
    if (idx !== -1) {
      const copy = JSON.parse(JSON.stringify(projectDetails.workflows));
      copy[idx].hidden = true;
      http()
        .put(`/projects/${projectDetails.projectId}/details`, {
          workflows: copy
        })
        .then(setProjectDetails)
        .catch(Toast.showErr);
      if (!queryParams.workflowId) {
        addReminder();
      }
    }
    updateQueryParams({});
  };
  const workflowCount =
    (projectDetails.workflows && projectDetails.workflows.length) || 0;
  const workflowCountText = `${workflowCount} workflow${
    workflowCount > 1 ? 's' : ''
  } pending`;

  return (
    <>
      {!!workflowCount && !!currentUser.hasPermission('projects_tier_2') && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            background: colors.error
          }}
        >
          <div style={{ color: colors.primaryContrastText, paddingRight: 10 }}>
            <Typography variant="overline">{workflowCountText}</Typography>
          </div>
          <div>
            <PendingPopover workflows={projectDetails.workflows} />
          </div>
        </div>
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open && !!currentUser.hasPermission('projects_tier_2')}
        onClose={hideWorkflow}
      >
        <DialogTitle
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <Typography variant="h5">
              {currentWorkflow && currentWorkflow.name}
            </Typography>
          </div>
          <div>
            {queryParams.workflowId && (
              <Button style={{ marginRight: 10 }} onClick={addReminder}>
                Add reminder
              </Button>
            )}
            <Button variant="contained" onClick={hideWorkflow}>
              Finish later
            </Button>
          </div>
        </DialogTitle>
        {!!currentWorkflowComponent &&
          React.createElement(currentWorkflowComponent, { completeWorkflow })}
      </Dialog>
    </>
  );
};

export default ProjectWorkflows;
