import React, { useContext, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import moment from 'moment';

const RfiComments = ({ rfi, setRfi, users }) => {
  const { project } = useContext(ProjectContext);
  const [message, setMessage] = useState('');
  const onSubmit = async e => {
    e.preventDefault();
    const updatedRfi = await http().post(
      `/projects/${project.id}/rfis/${rfi.id}/comments`,
      {
        message
      }
    );
    setRfi(updatedRfi);
    setMessage('');
  };

  const userMap = {};
  users.forEach(user => (userMap[user.id] = user));

  return (
    <form onSubmit={onSubmit}>
      {rfi.comments.map(comment => {
        if (!userMap[comment.userId]) return null;
        const user = userMap[comment.userId];
        return (
          <div key={comment.id} style={{ paddingBottom: 10 }}>
            <Typography variant="caption">
              {user.name} - {moment(comment.created).format('LLL')}
            </Typography>
            <div style={{ paddingLeft: 16 }}>
              <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                {comment.message}
              </Typography>
            </div>
          </div>
        );
      })}
      <Paper
        elevation={3}
        style={{ padding: 10, display: 'flex', alignItems: 'flex-end' }}
      >
        <TextField
          style={{ flex: '0 1 100%', paddingRight: 10 }}
          value={message}
          onChange={e => setMessage(e.target.value)}
          multiline
          label="Add Comment"
        />
        <div>
          <Button color="primary" variant="contained" type="submit">
            Post
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default RfiComments;
