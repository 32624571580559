import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  DialogContentText
} from 'helpers/themeSafeMui.helper';

const VerifySend = props => {
  const { onClose, open, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Once you send an eSign document to it's participants, you will no
          longer be able to make edits. Make sure all the fields and signers
          have been added to the document correctly before continuing.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Send Document
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifySend;
