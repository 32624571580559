import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import { Toast, NoItemsResult } from 'components';
import { alpha } from '@material-ui/core/styles';
import {
  InputBase,
  Paper,
  List,
  AppBar,
  Tabs,
  Tab,
  Tooltip,
  ListItem,
  Divider,
  Typography
} from 'helpers/themeSafeMui.helper';
import SearchIcon from '@material-ui/icons/Search';
import SearchListItem from './SearchListItem.component';
import theme, { useColors } from 'helpers/theme.helper';
import { useLocation } from 'react-router-dom';
import UsersIcon from '@material-ui/icons/People';
import PeopleIcon from '@material-ui/icons/Contacts';
import CompaniesIcon from '@material-ui/icons/Business';
import ProjectsIcon from '@material-ui/icons/Folder';

const styles = {
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginLeft: theme.spacing(3),
    maxWidth: 400,
    width: '60%'
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchInput: {
    color: 'white',
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%'
  }
};

export default function GlobalSearch({
  styles: propStyles = {},
  onLinkClick,
  forceOpen = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [wrapperRef, setWrapperRef] = useState(null);
  const [category, setCategory] = useState('all');
  const [searchingInactive, setSearchingInactive] = useState(false);
  const { pathname } = useLocation();
  const colors = useColors();

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (searchString.length > 1) {
      http()
        .get(
          `/global-search?search=${searchString}&inactive=${!!searchingInactive}`
        )
        .then(res => {
          setSearchResults(res);
        })
        .catch(err => Toast.show(err.message));
    } else {
      setSearchResults(null);
    }
  }, [searchString, searchingInactive]);

  useEffect(() => {
    const handler = e => {
      if (wrapperRef && !wrapperRef.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handler);

    return () => document.removeEventListener('click', handler);
  }, [wrapperRef]);

  const handleCategoryChange = (e, newVal) => setCategory(newVal);
  const handleSearchChange = e => {
    setSearchString(e.target.value);
  };

  const empty = (item, key) => !item || !item[key] || item[key].length === 0;
  const len = key =>
    (searchResults && searchResults[key] && searchResults[key].length) || 0;
  const noItems =
    empty(searchResults, 'users') &&
    empty(searchResults, 'people') &&
    empty(searchResults, 'projects') &&
    empty(searchResults, 'companies');

  const getSlice = key => {
    if (category !== 'all') return key === category ? [0, 12] : [0, 0];
    let emptySpots = 12;
    let keysFinished = [];
    const keys = ['users', 'people', 'projects', 'companies'];
    const slice = {};

    keys.forEach(key => {
      slice[key] = [0, 0];
    });
    for (
      let keyIdx = 0;
      emptySpots > 0 && keysFinished.length < 4;
      emptySpots--, keyIdx++
    ) {
      keyIdx %= keys.length;
      const key = keys[keyIdx];
      if (len(key) > slice[key][1]) {
        slice[key][1]++;
      } else {
        if (!keysFinished.includes(key)) {
          keysFinished.push(key);
        }
        emptySpots++;
      }
    }
    return slice[key];
  };

  return (
    <div
      style={{ ...styles.search, ...propStyles.search }}
      name="globalSearchBar"
      ref={setWrapperRef}
    >
      <div style={{ ...styles.searchIcon, ...propStyles.searchIcon }}>
        <SearchIcon />
      </div>
      <InputBase
        onFocus={() => {
          setIsOpen(true);
        }}
        placeholder="Search…"
        style={{ ...styles.searchInput, ...propStyles.searchInput }}
        inputProps={{ style: { padding: 0 } }}
        name="searchString"
        autoComplete="off"
        value={searchString}
        onChange={handleSearchChange}
      />
      {(isOpen || forceOpen) && (
        <Paper
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            ...propStyles.paper
          }}
        >
          <List style={{ paddingTop: 0 }}>
            <AppBar position="relative" variant="outlined" color="secondary">
              <Tabs
                value={category}
                onChange={handleCategoryChange}
                variant="standard"
                indicatorColor="primary"
                centered
              >
                <Tab label="All" value="all" style={{ minWidth: 30 }} />
                <Tab
                  icon={
                    <Tooltip title="Users" placement="top">
                      <UsersIcon />
                    </Tooltip>
                  }
                  value="users"
                  style={{ minWidth: 30 }}
                />
                <Tab
                  icon={
                    <Tooltip title="People" placement="top">
                      <PeopleIcon />
                    </Tooltip>
                  }
                  value="people"
                  style={{ minWidth: 30 }}
                />
                <Tab
                  icon={
                    <Tooltip title="Companies" placement="top">
                      <CompaniesIcon />
                    </Tooltip>
                  }
                  value="companies"
                  style={{ minWidth: 30 }}
                />
                <Tab
                  icon={
                    <Tooltip title="Projects" placement="top">
                      <ProjectsIcon />
                    </Tooltip>
                  }
                  value="projects"
                  style={{ minWidth: 30 }}
                />
              </Tabs>
            </AppBar>
            <ListItem
              button
              dense
              onClick={() => setSearchingInactive(!searchingInactive)}
              style={{
                backgroundColor: colors.secondary,
                color: colors.secondaryContrastText,
                justifyContent: 'center'
              }}
            >
              <Typography variant="overline">
                Searching
                <span
                  style={{
                    color: searchingInactive ? colors.error : colors.success
                  }}
                >
                  {searchingInactive ? ' inactive ' : ' active '}
                </span>
                projects and users
              </Typography>
            </ListItem>
            <Divider />
            {noItems && <NoItemsResult type="results" />}
            {!empty(searchResults, 'users') &&
              searchResults.users
                .slice(...getSlice('users'))
                .map(user => (
                  <SearchListItem
                    onClick={onLinkClick}
                    key={user.id}
                    type="Users"
                    itemLabel={user.name}
                    link={`/users/${user.id}`}
                  />
                ))}
            {!empty(searchResults, 'people') &&
              searchResults.people
                .slice(...getSlice('people'))
                .map(person => (
                  <SearchListItem
                    onClick={onLinkClick}
                    key={person.id}
                    type="People"
                    itemLabel={person.name}
                    link={`/people/${person.id}`}
                  />
                ))}
            {!empty(searchResults, 'companies') &&
              searchResults.companies
                .slice(...getSlice('companies'))
                .map(company => (
                  <SearchListItem
                    onClick={onLinkClick}
                    key={company.id}
                    type="Companies"
                    itemLabel={company.name}
                    link={`/companies/${company.id}`}
                  />
                ))}
            {!empty(searchResults, 'projects') &&
              searchResults.projects
                .slice(...getSlice('projects'))
                .map(project => (
                  <SearchListItem
                    onClick={onLinkClick}
                    key={project.id}
                    type="Projects"
                    itemLabel={`${project.projectNumber} - ${project.name}`}
                    link={`/projects/${project.id}`}
                  />
                ))}
          </List>
        </Paper>
      )}
    </div>
  );
}
