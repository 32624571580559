import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import { Button } from 'helpers/themeSafeMui.helper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function StatusFilter({
  selectedStatuses,
  setSelectedStatuses
}) {
  const statuses = useSelector(state => state.projectStatuses);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSelectedStatuses(getDefaultStatuses(statuses));
  }, [statuses, setSelectedStatuses]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onCheck = status => () => {
    setSelectedStatuses(old => {
      if (old.includes(status.id)) {
        return old.filter(other => other !== status.id);
      } else {
        return [status.id, ...old];
      }
    });
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Filter Statuses
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Selected Statuses</FormLabel>
            <FormGroup>
              {statuses.map(status => (
                <FormControlLabel
                  key={status.id}
                  control={
                    <Checkbox
                      checked={!!selectedStatuses.includes(status.id)}
                      onChange={onCheck(status)}
                      name={status.status}
                    />
                  }
                  label={status.status}
                />
              ))}
            </FormGroup>
            <div>
              <Button
                variant="contained"
                onClick={() => setSelectedStatuses([])}
                fullWidth
              >
                Clear
              </Button>
            </div>
          </FormControl>
        </div>
      </Popover>
    </div>
  );
}

function getDefaultStatuses(allStatuses) {
  const defaults = [
    'Operations Hand Off',
    'Planning / Staging',
    'Scheduled / In Progress',
    'Closeout'
  ];
  return allStatuses
    .filter(status => defaults.includes(status.status))
    .map(({ id }) => id);
}
