import mapReducer from 'helpers/mapReducer.helper';

export default function filterByAreaName(
  projects,
  users,
  relationKey,
  areaNames
) {
  // console.log(projects, users, relationKey, areaNames);
  const areaSet = areaNames.reduce(mapReducer.set(), {});
  const userSet = users.reduce(mapReducer('id'), {});
  if (!areaNames.length) return [...projects];
  return projects.filter(project => {
    const userId = project[relationKey];
    if (!userId) return true;
    const user = userSet[userId];
    if (!user) return true;
    const userAreaName = user.area;
    return !!areaSet[userAreaName];
  });
}
