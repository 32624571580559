import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  ListItem,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import { UserSearch, Toast } from 'components';
import { ProjectTeamContext } from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import mapReducer from 'helpers/mapReducer.helper';

const RequestAccountManagerChange = ({ open, onClose }) => {
  const [user, setUser] = useState(null);
  const [accountManagerIdSet, setAccountManagerIdSet] = useState({});
  const { projectTeam } = useContext(ProjectTeamContext);
  const clearAndClose = () => {
    setUser(null);
    onClose();
  };
  const requestChange = () => {
    http()
      .put(`/projects/${projectTeam.projectId}/teams/request-change`, {
        newAccountManagerId: user.id
      })
      .then(() => {
        clearAndClose();
        Toast.show('Request sent.');
      })
      .catch(err => Toast.show(err.message));
  };
  const itemTextProps = {
    primaryTypographyProps: {
      variant: 'caption',
      color: 'textSecondary'
    },
    secondaryTypographyProps: {
      variant: 'body1',
      color: 'textPrimary'
    }
  };

  useEffect(() => {
    http()
      .get('/account-managers')
      .then(AMs =>
        setAccountManagerIdSet(
          AMs.reduce(
            mapReducer.set(am => am.userId),
            {}
          )
        )
      )
      .catch(Toast.showErr);
  }, []);

  return (
    <Dialog open={open} onClose={clearAndClose}>
      <DialogTitle>Request Account Manager Change</DialogTitle>
      <DialogContent>
        <UserSearch
          filter={user => !!accountManagerIdSet[user.id]}
          onChange={setUser}
        />
      </DialogContent>
      <ListItem>
        <ListItemText
          {...itemTextProps}
          primary="Current:"
          secondary={projectTeam.accountManagerName}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          {...itemTextProps}
          primary="Requested:"
          secondary={user ? user.name : 'N/A'}
        />
      </ListItem>
      <DialogActions>
        <Button
          disabled={!user}
          onClick={requestChange}
          variant="contained"
          color="primary"
        >
          Request
        </Button>
        <Button onClick={clearAndClose} variant="contained" color="default">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestAccountManagerChange;
