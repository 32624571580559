import React from 'react';
import { connect } from 'react-redux';
import { CardWrapper } from 'components';
import { Stepper, Step, StepButton, Button } from 'helpers/themeSafeMui.helper';
import { Route, Switch, Redirect } from 'react-router-dom';
import { clearUser } from 'ducks/user.duck';

import Welcome from './routes/Welcome.route';
import PersonalInfo from './routes/PersonalInfo.route';
import Identification from './routes/Identification.route';
import EmployeePolicy from './routes/EmployeePolicy.route';
import EmergencyContact from './routes/EmergencyContact.route';
import DirectDeposit from './routes/DirectDeposit.route';
import GovernmentForms from './routes/GovernmentForms.route';

const routes = [
  {
    label: 'Welcome',
    path: '/onboarding',
    component: Welcome
  },
  {
    label: 'Personal Info',
    path: '/onboarding/personal-info',
    component: PersonalInfo
  },
  {
    label: 'Identification',
    path: '/onboarding/identification',
    component: Identification
  },
  {
    label: 'Employee Policy',
    path: '/onboarding/employee-policy',
    component: EmployeePolicy
  },
  {
    label: 'Emergency Contact',
    path: '/onboarding/emergency-contact',
    component: EmergencyContact
  },
  {
    label: 'Direct Deposit',
    path: '/onboarding/direct-deposit',
    component: DirectDeposit
  },
  {
    label: 'Government Forms',
    path: '/onboarding/government-forms',
    component: GovernmentForms
  }
];

class Onboarding extends React.Component {
  componentDidMount() {
    if (this.props.user.onBoardingCompleted) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <CardWrapper onboarding>
        <Button
          style={{ position: 'absolute', top: 10, right: 10 }}
          variant="contained"
          color="primary"
          onClick={this.props.clearUser}
        >
          Sign Out
        </Button>
        <Stepper
          alternativeLabel
          activeStep={routes.findIndex(
            route => route.path === this.props.location.pathname
          )}
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          {routes.map(route => {
            return (
              <Step key={route.path}>
                <StepButton style={{ cursor: 'auto' }} disableRipple>
                  {route.label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              exact
              component={route.component}
            />
          ))}
          <Redirect to="/404" />
        </Switch>
      </CardWrapper>
    );
  }
}

export default connect(state => ({ user: state.user }), { clearUser })(
  Onboarding
);
