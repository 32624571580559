import React from 'react';
import { Button, TextField } from 'helpers/themeSafeMui.helper';
import { Link } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast, CardWrapper } from 'components';
import queryString from 'query-string';

export default class ResetPassword extends React.Component {
  state = {
    password: '',
    confirmPassword: ''
  };

  resetPassword = e => {
    e.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      return Toast.show("Passwords don't match.");
    }

    http()
      .post('/users/auth/reset-password', {
        resetToken: queryString.parse(this.props.location.search).reset_token,
        password: this.state.password
      })
      .then(res => {
        this.props.history.push({
          pathname: '/',
          state: { message: res.message }
        });
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <CardWrapper>
        <form autoComplete="off" onSubmit={this.resetPassword}>
          <TextField
            name="password"
            label="New Password"
            autoComplete="new-password"
            type="password"
            required
            fullWidth
            margin="normal"
            autoFocus
            value={this.state.password}
            onChange={this.handleChange}
          />
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            autoComplete="new-password"
            type="password"
            required
            fullWidth
            margin="normal"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Change password
          </Button>

          <Button
            component={Link}
            fullWidth
            to="/"
            size="small"
            color="primary"
            style={{ marginTop: 8 }}
          >
            I know my password.
          </Button>
        </form>
      </CardWrapper>
    );
  }
}
