import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import TrainingGroup from './TrainingGroup.component';
import TrainingQuizzesContextProvider from 'routes/Users/context/TrainingQuizzes.context';
import { useQueryParams } from 'hooks/useQueryParams.hook';

export default function TrainingTab(props) {
  const { userId } = props;
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [moduleIdToGroupMap, setModuleIdToGroupMap] = useState({});
  const [params] = useQueryParams();

  const getTrainingGroups = useCallback(async () => {
    try {
      const trainingRecords = await http().get(`/users/${userId}/trainings`);

      const trainingGroups = await http()
        .get('/training-groups')
        .then(trainingGroups =>
          Promise.all(
            trainingGroups.map(group =>
              http()
                .get(`/training-groups/${group.id}/training-modules`)
                .then(trainingModules => ({ ...group, trainingModules }))
            )
          )
        );

      const trainingGroupsWithRecords = trainingGroups.map(trainingGroup => ({
        ...trainingGroup,
        trainingModules: trainingGroup.trainingModules.map(trainingModule => ({
          ...trainingModule,
          userTrainingRecord: trainingRecords.find(
            trainingRecord =>
              trainingRecord.trainingModuleId === trainingModule.id
          )
        }))
      }));

      const allModules = trainingGroupsWithRecords
        .map(group => group.trainingModules)
        .flat();
      setModuleIdToGroupMap(
        allModules.reduce(
          (map, module) =>
            Object.assign(map, {
              [module.id]: trainingGroupsWithRecords.find(
                group => group.id === module.trainingGroupId
              )
            }),
          {}
        )
      );

      setTrainingGroups(trainingGroupsWithRecords);
    } catch (err) {
      console.error(err);
      Toast.show(err.message);
    }
  }, [userId]);

  useEffect(() => {
    getTrainingGroups();
  }, [getTrainingGroups]);

  return (
    <Grid container spacing={5}>
      <TrainingQuizzesContextProvider>
        {moduleIdToGroupMap[params.moduleId] ? (
          <TrainingGroup
            getTrainingGroups={getTrainingGroups}
            trainingGroup={moduleIdToGroupMap[params.moduleId]}
            userId={userId}
            moduleFilter={module => module && module.id === params.moduleId}
          />
        ) : (
          trainingGroups.map(trainingGroup => (
            <TrainingGroup
              key={trainingGroup.id}
              getTrainingGroups={getTrainingGroups}
              trainingGroup={trainingGroup}
              userId={userId}
            />
          ))
        )}
      </TrainingQuizzesContextProvider>
    </Grid>
  );
}
