import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TableSortLabel
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import AddAccountManagerModal from 'routes/AdminSettings/modals/CreateAccountManager.modal';
import UpdateAccountManagerModal from 'routes/AdminSettings/modals/UpdateAccountManager.modal';
import { Toast } from 'components';

const AccountManagers = () => {
  const [accountManagers, setAccountManagers] = useState([]);
  const [sortedAccountManagers, setSortedAccountManagers] = useState([]);
  const [editingAccountManager, setEditingAccountManager] = useState(null);
  const [addAccountManagerModal, setAddAccountManagerModal] = useState(false);
  const [order, setOrder] = useState('asc');
  const [sortingBy, setSortingBy] = useState('name');

  const archive = id => {
    http()
      .delete(`/account-managers/${id}`)
      .then(() => {
        Toast.show(`user was removed from the system as an Account Manager.`);
        getAccountManagers();
      });
  };

  const setEditing = manager => () => {
    setEditingAccountManager(manager);
  };

  const getAccountManagers = () => {
    http()
      .get('/account-managers')
      .then(res => {
        setAccountManagers(res);
      })
      .catch(err => Toast.show(err.message));
  };

  const handleSortClick = by => () => {
    if (sortingBy === by) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingBy(by);
      setOrder('asc');
    }
  };

  const sortAccountManagers = () => {
    setSortedAccountManagers(
      [...accountManagers].sort((a, b) => {
        let res = 0;
        if (
          a[sortingBy].toString().toUpperCase() <
          b[sortingBy].toString().toUpperCase()
        )
          res = -1;
        if (
          a[sortingBy].toString().toUpperCase() >
          b[sortingBy].toString().toUpperCase()
        )
          res = 1;
        return order === 'asc' ? res : res * -1;
      })
    );
  };

  useEffect(getAccountManagers, []);

  useEffect(sortAccountManagers, [accountManagers, order, sortingBy]);

  return (
    <React.Fragment>
      <AddAccountManagerModal
        open={addAccountManagerModal}
        onClose={() => setAddAccountManagerModal(false)}
        refresh={getAccountManagers}
      />
      <UpdateAccountManagerModal
        accountManager={editingAccountManager}
        open={!!editingAccountManager}
        onUpdate={getAccountManagers}
        onClose={() => setEditingAccountManager(null)}
      />

      <Typography component="h1" variant="h5" gutterBottom>
        Account Managers
        <IconButton
          color="primary"
          style={{ marginLeft: 10, padding: 0 }}
          onClick={() => setAddAccountManagerModal(true)}
        >
          <AddIcon />
        </IconButton>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortingBy === 'name'}
                direction={order === 'asc' ? 'desc' : 'asc'}
                onClick={handleSortClick('name')}
              >
                Account Manager Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortingBy === 'managerNumber'}
                direction={order === 'asc' ? 'desc' : 'asc'}
                onClick={handleSortClick('managerNumber')}
              >
                Number
              </TableSortLabel>
            </TableCell>
            <TableCell padding="checkbox" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAccountManagers.map(manager => (
            <TableRow key={manager.id}>
              <TableCell>{manager.name}</TableCell>
              <TableCell>{manager.managerNumber}</TableCell>
              <TableCell padding="checkbox" className="cell-shrink">
                <IconButton onClick={setEditing(manager)}>
                  <EditIcon color="action" fontSize="small" />
                </IconButton>
                <IconButton onClick={() => archive(manager.id)}>
                  <DeleteIcon color="action" fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
export default AccountManagers;
