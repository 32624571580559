import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import { Button } from 'helpers/themeSafeMui.helper';
import React, { useEffect, useState } from 'react';

export default function SelectColumns({
  allColumns,
  selectedColumns,
  setSelectedColumns
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onCheck = column => () => {
    setSelectedColumns(old => {
      if (old.includes(column.key)) {
        return old.filter(other => other !== column.key);
      } else {
        return [column.key, ...old];
      }
    });
  };

  useEffect(() => {
    if (selectedColumns.length === 0) {
      setSelectedColumns(allColumns.map(c => c.key));
    }
  }, [allColumns, setSelectedColumns, selectedColumns.length]);

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Select Columns
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Selected Columns</FormLabel>
            <FormGroup>
              {allColumns.map((column, idx) => (
                <FormControlLabel
                  key={column.key}
                  control={
                    <Checkbox
                      disabled={idx === 0}
                      checked={!!selectedColumns.includes(column.key)}
                      onChange={onCheck(column)}
                      name={column.label}
                    />
                  }
                  label={column.label}
                />
              ))}
            </FormGroup>
            <div>
              <Button
                variant="contained"
                onClick={() => setSelectedColumns(allColumns.map(c => c.key))}
                fullWidth
              >
                Reset
              </Button>
            </div>
          </FormControl>
        </div>
      </Popover>
    </div>
  );
}
