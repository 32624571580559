import React from 'react';
import AccountManagers from './AccountManagers.component';
import OperationsManagers from './OperationsManagers.component';
import HumanResourceOfficers from './HumanResourceOfficers.component';

const UserAssignments = props => {
  return (
    <div>
      <AccountManagers />
      <OperationsManagers />
      <HumanResourceOfficers />
    </div>
  );
};

export default UserAssignments;
