export default function getStatusText(item) {
  if (typeof item === 'string') item = { color: item };
  let { completed = false, color = 'red' } = item || {};
  if (completed) return 'Completed';
  if (color === 'red') return 'Not Completed';
  if (color === 'yellow') return 'In Progress';
  if (color === 'gray') return 'N / A';
  if (color === 'green') return 'Completed';
  return 'task status error';
}
