import http from 'helpers/http.helper';
import { Toast } from 'components';

// Actions
const SET_PROJECT_STATUSES = 'project-statuses/SET_PROJECT_STATUSES';

// Reducer
export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SET_PROJECT_STATUSES:
      return action.projectStatuses;
    default:
      return state;
  }
}

// Action Creators
export const getProjectStatuses = () => async dispatch => {
  const projectStatuses = await http()
    .get('/project-statuses')
    .catch(err => Toast.show(err.message));

  dispatch({ type: SET_PROJECT_STATUSES, projectStatuses });
};

export const setProjectStatuses = projectStatuses => {
  return { type: SET_PROJECT_STATUSES, projectStatuses };
};
