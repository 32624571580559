import React, { useCallback, useState } from 'react';
import ShiftWeekModal from '../modals/ShiftWeek.modal';
import ShiftDayModal from '../modals/ShiftDay.modal';
import ProjectPickerModal from '../modals/ProjectPicker.modal';
import ApproveByArea from '../modals/ApproveByArea.modal';

export const ShiftModalContext = React.createContext();

export default function ShiftModalProvider({ children }) {
  const [currentModal, setCurrentModal] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const openWithProps = useCallback((props = {}, modalType = 'week') => {
    if (modalType === 'week') {
      setCurrentModal('week');
    } else if (modalType === 'day') {
      setCurrentModal('day');
    } else if (modalType === 'project_picker') {
      setCurrentModal('project_picker');
    } else if (modalType === 'approve_by_area') {
      setCurrentModal('approve_by_area');
    }
    setModalProps(props);
  }, []);

  const close = useCallback(() => {
    setCurrentModal(null);
  }, []);

  return (
    <ShiftModalContext.Provider value={{ openWithProps, close }}>
      <ShiftWeekModal
        {...modalProps}
        open={currentModal === 'week'}
        onClose={close}
      />
      <ShiftDayModal
        {...modalProps}
        open={currentModal === 'day'}
        onClose={close}
      />
      <ProjectPickerModal
        {...modalProps}
        open={currentModal === 'project_picker'}
        onClose={close}
      />
      <ApproveByArea
        {...modalProps}
        open={currentModal === 'approve_by_area'}
        onClose={close}
      />
      {children}
    </ShiftModalContext.Provider>
  );
}
