import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import http from 'helpers/http.helper';
import { Toast, AddressPredict, CustomInput } from 'components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function EditCompanyModal(props) {
  const { company, open, onCreate, onClose } = props;
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [isSupplier, setIsSupplier] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const user = useSelector(state => state.user);
  const history = useHistory();

  useEffect(() => {
    setName(company.name);
    setAddress(company.address);
    setPhoneNumber(company.phoneNumber);
    setBillingEmail(company.billingEmail);
    setWebsite(company.website);
    setShowDeleteConfirm(false);
    setIsSupplier(company.isSupplier);
  }, [company]);

  const save = e => {
    e.preventDefault();

    http()
      .put(`/companies/${company.id}`, {
        name,
        address,
        phoneNumber,
        billingEmail,
        isSupplier,
        website
      })
      .then(res => {
        onCreate(res);
        Toast.show('Company updated.');
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const archive = () => {
    http()
      .delete(`/companies/${company.id}`)
      .then(() => {
        Toast.show('Company removed.');
        history.push('/companies');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {user.hasPermission('super_admin') && (
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          {showDeleteConfirm ? (
            <Typography>
              Are you sure?{' '}
              <Button color="primary" size="small" onClick={archive}>
                Delete
              </Button>
            </Typography>
          ) : (
            <IconButton onClick={() => setShowDeleteConfirm(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      )}
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>Edit Company</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Name"
            fullWidth
            value={name}
            margin="normal"
            onChange={e => setName(e.target.value)}
            required
          />
          <AddressPredict
            label="Billing Address"
            onChange={address => setAddress(address)}
            value={address}
            required
            margin="normal"
          />
          <TextField
            label="Phone Number"
            fullWidth
            value={phoneNumber}
            name="phoneNumber"
            margin="normal"
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Billing Email"
            fullWidth
            value={billingEmail}
            margin="normal"
            onChange={e => setBillingEmail(e.target.value)}
            required
          />
          <TextField
            label="Website"
            fullWidth
            value={website}
            name="website"
            margin="normal"
            onChange={e => setWebsite(e.target.value)}
          />
          <CustomInput
            type="boolean"
            value={isSupplier}
            label="Is Supplier Company"
            onChange={setIsSupplier}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
