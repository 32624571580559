import React, { useState } from 'react';
import { Typography, Popover } from 'helpers/themeSafeMui.helper';
import ScheduleTableCell from './ScheduleTableCell.component';
import ScheduleTableBar from './ScheduleTableBar.component';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';
import safeParse from 'helpers/safeParse.helper';
import Color from 'color';
import getTaskIndexMap from '../helpers/getTaskIndexMap.helper';
import getTodayIndex from '../helpers/getTodayIdx.helper';
import useTaskTechsOnDays from '../helpers/useTaskTechsOnDays.helper';

const ScheduleTableRow = React.memo(
  ({
    project,
    tasks,
    startDay,
    colCount,
    firstCell = null,
    firstCellWidth = 0,
    cellWidth = 50,
    boldBorderBottom
  }) => {
    const [detailPos, setDetailPos] = useState(null);
    const [detailTask, setDetailTask] = useState({});
    const colors = useColors();
    const taskMap = getTaskIndexMap(startDay, colCount, tasks);
    const techsOnDays = useTaskTechsOnDays({ tasks, startDay, colCount });

    const weekends = [0, 6];
    if (project.scheduleDaysPerWeek === 4) weekends.push(5);
    const modOffset = moment(startDay).day();
    const isWeekend = idx => weekends.includes((idx + modOffset) % 7);

    const onClickBar = task => e => {
      setDetailTask(task);
      setDetailPos({
        top: e.clientY + 10,
        left: e.clientX
      });
    };

    const weekendBg = new Color(colors.background).alpha(0.9).hsl();
    const tech = detailTask.techCount === 1 ? 'tech' : 'techs';
    const keyBase = moment(startDay).format('ll');
    const todayBg = new Color(colors.yellow)
      .mix(new Color(colors.paper), 0.8)
      .hsl();
    const todayIdx = getTodayIndex(startDay);
    let barColor =
      project.scheduleType === 'potential' ? colors.yellow : colors.primary;
    barColor = new Color(barColor).alpha(0.7).hsl();

    const firstCellWrapper = cell => (
      <div
        key="first-cell-wrapper"
        style={{
          position: 'sticky',
          left: 0,
          zIndex: colCount + 2,
          display: 'flex'
        }}
      >
        {cell}
      </div>
    );

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          position: 'relative',
          width: cellWidth * colCount + firstCellWidth
        }}
      >
        {Array(colCount)
          .fill(null)
          .map((day, colIdx) => {
            // row reversed so left cells are drawn on top of right cells
            colIdx = colCount - 1 - colIdx;
            const taskDataAtIdx = taskMap[colIdx];
            return (
              <ScheduleTableCell
                key={keyBase + colIdx}
                firstCol={!firstCell && colIdx === 0}
                boldBorderBottom={boldBorderBottom}
                style={{
                  position: !!taskDataAtIdx ? 'relative' : null,
                  zIndex: isWeekend(colIdx)
                    ? !!taskDataAtIdx
                      ? taskDataAtIdx.taskIdx
                      : tasks.length
                    : null,
                  background:
                    colIdx === todayIdx
                      ? todayBg
                      : isWeekend(colIdx)
                      ? weekendBg
                      : colors.paper
                }}
                width={cellWidth}
              >
                {!!taskDataAtIdx && (
                  <ScheduleTableBar
                    padStart={taskDataAtIdx.padStart}
                    padEnd={taskDataAtIdx.padEnd}
                    padding={12}
                    cellWidth={cellWidth}
                    cellSpan={taskDataAtIdx.span}
                    color={barColor}
                    onClick={onClickBar(taskDataAtIdx.task)}
                    style={{
                      zIndex: taskDataAtIdx.taskIdx,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                )}
              </ScheduleTableCell>
            );
          })}
        <div>
          {techsOnDays.map((count, idx) =>
            count === 0 || isWeekend(idx) ? null : (
              <div
                style={{
                  position: 'absolute',
                  pointerEvents: 'none',
                  left: firstCellWidth + cellWidth * idx,
                  top: 0,
                  bottom: 0,
                  height: '100%',
                  zIndex: colCount + 1,
                  width: cellWidth,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: colors.primaryContrastText
                }}
                key={`${idx}-${count}`}
              >
                <Typography>{count}</Typography>
              </div>
            )
          )}
        </div>

        {Array.isArray(firstCell)
          ? [...firstCell]
              .map((cell, idx) => (idx === 0 ? firstCellWrapper(cell) : cell))
              .reverse()
          : firstCellWrapper(firstCell)}
        <Popover
          open={!!detailPos}
          onClose={() => setDetailPos(null)}
          anchorReference="anchorPosition"
          anchorPosition={detailPos}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div style={{ padding: 10 }}>
            <Typography variant="body1">{detailTask.taskName}</Typography>
            <Typography variant="body1">
              {project.projectNumber} - {project.name}
            </Typography>
            <Typography variant="body1">
              Hours: {safeParse(detailTask.hours)}
            </Typography>
            <Typography variant="caption">
              {moment(detailTask.startDate).format('ll')} -{' '}
              {moment(detailTask.endDate).format('ll')}
            </Typography>
            {!!detailTask.techCount && (
              <>
                <br />
                <Typography variant="caption">
                  {detailTask.techCount} {tech} -{' '}
                  {parseInt(project.scheduleHrsPerDay || 10)}hr days -{' '}
                  {project.scheduleDaysPerWeek || 4} days per week
                </Typography>
              </>
            )}
          </div>
        </Popover>
      </div>
    );
  }
);

export default ScheduleTableRow;
