import React, { useState } from 'react';
import {
  DialogTitle,
  Checkbox,
  Divider,
  Typography,
  DialogContent,
  Button,
  FormControl,
  DialogContentText
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import moment from 'moment';
import { Toast } from 'components';

function WorkOrderSignOff(props) {
  const { onClose, workOrderId, projectId, refresh } = props;

  const [state, setState] = useState({
    checkOffAttitudes: false,
    checkOffSafety: false,
    checkOffCleanArea: false,
    checkOffTidy: false,
    securelyFastenedCheckOff: false,
    securelySupportedCheckOff: false,
    noStressCheckOff: false,
    cablesNeatCheckOff: false,
    cableTerminatedCheckOff: false,
    newWallCheckOff: false,
    existingPenetrationsCheckOff: false,
    firestopStickersCheckOff: false,
    sentRecordsCheckOff: false,
    labelCheckOff: false,
    drawingsCheckOff: false,
    passTestCheckOff: false,
    reportsSentCheckOff: false,
    subparStandardsCheckOff: false,
    stickerCheckOff: false
  });

  const handleChecked = e => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const submit = e => {
    e.preventDefault();

    http()
      .put(`/projects/${projectId}/work-orders/${workOrderId}`, {
        techSignOff: moment()
      })
      .then(() => {
        refresh();
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const DisplayCheckOff = props => {
    const { isChecked, handleChecked, item } = props;

    return (
      <div
        style={{
          display: 'flex'
        }}
      >
        <div>
          <Checkbox
            required
            checked={isChecked}
            onChange={handleChecked}
            name={item.name}
          />
        </div>
        <Typography key={item.name}>{item.label}</Typography>
      </div>
    );
  };

  return (
    <form autoComplete="off" onSubmit={submit}>
      <DialogTitle variant="h5">Work Order Tech Sign off</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>Note: </strong> The project lead is responsible to check off
          all items after careful inspection of the job site. The Project
          manager will perform a walk through and review this checklist with the
          project lead to ensure accuracy of the completed installation. This
          completed checklist constitutes a contract between the project lead,
          project manager and System Tech that the project is ready to be turned
          over to the customer.
        </DialogContentText>
        <Divider />
      </DialogContent>
      <DialogContent>
        <FormControl required>
          <Typography variant="subtitle1">
            <strong>Safety & Professionalism</strong>
          </Typography>
          {safetyCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Pathways</strong>
          </Typography>
          {pathwayCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Cable Installation</strong>
          </Typography>
          {cableCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Firestop</strong>
          </Typography>
          {fireStopCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Labelling</strong>
          </Typography>
          {labellingCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Test Reports</strong>
          </Typography>
          {testReportCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}

          <Typography variant="subtitle1">
            <strong>Other</strong>
          </Typography>
          {otherCheckOff.map((item, index) => (
            <DisplayCheckOff
              key={index}
              item={item}
              isChecked={state[item.name]}
              handleChecked={handleChecked}
            />
          ))}
        </FormControl>
        <Divider />

        <div
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8
          }}
        >
          <Button color="primary" variant="outlined" onClick={onClose}>
            Close
          </Button>
          <div>
            <Button color="secondary" variant="contained" type="submit">
              Sign Off
            </Button>
          </div>
        </div>
      </DialogContent>
    </form>
  );
}

export default WorkOrderSignOff;

const safetyCheckOff = [
  {
    name: 'checkOffAttitudes',
    label: `Employees were kind, curteous, and demonstrated exceptional attitudes and work ethic.`
  },
  {
    name: 'checkOffSafety',
    label: `Employees performed safety briefings to identify and protect against jobsite hazards`
  },
  {
    name: 'checkOffCleanArea',
    label: `Employee's kept the work areas clean and used safety cones or barriers to protect others against injury.`
  },
  {
    name: 'checkOffTidy',
    label: `Cables were neatly placed to avoid foot traffic and were not an obstruction or tripping hazard.`
  }
];

const pathwayCheckOff = [
  {
    name: 'securelyFastenedCheckOff',
    label: `Cable supports are securely fastened to an approved support structure so that cables are supported at
    intervals of approximately four to five feet per TIA standard.`
  },
  {
    name: 'securelySupportedCheckOff',
    label: `Cables are not being supported by ceiling tiles, sprinkler pipes, conduits or other objects that are not approved
    supporting methods.`
  }
];

const cableCheckOff = [
  {
    name: 'noStressCheckOff',
    label: `Cables were not stressed, kinked, twisted, or physically damaged during installation.`
  },
  {
    name: 'cablesNeatCheckOff',
    label: `Cables are neatly dressed and presentable throughout the installation.`
  },
  {
    name: 'cableTerminatedCheckOff',
    label: `Cables have been properly terminated and faceplates are installed level and secure.`
  }
];
const fireStopCheckOff = [
  {
    name: 'newWallCheckOff',
    label: `New wall penetrations have been properly installed and adequate firestop putty has been placed in penetration after cables.`
  },
  {
    name: 'existingPenetrationsCheckOff',
    label: `Existing penetrations, which were installed by System Tech, have the firestop putty replaced or new putty added as needed.`
  },
  {
    name: 'firestopStickersCheckOff',
    label: `Firestop installation stickers have been affixed near each penetration and a picture documenting
    the installation attached to the installation attached to the Firestop Installation Record.`
  },
  {
    name: 'sentRecordsCheckOff',
    label: `Firestop Installation Records have been sent to the project manager and the customer.`
  }
];

const labellingCheckOff = [
  {
    name: 'labelCheckOff',
    label: `100% of the cables have been labeled accurately per customer requirements.`
  },
  {
    name: 'drawingsCheckOff',
    label: `As-built drawings have been updated with all changes.`
  }
];

const testReportCheckOff = [
  {
    name: 'passTestCheckOff',
    label: `100% of cables have a PASS test report and are saved in the tester with the proper cable ID.`
  },
  {
    name: 'reportsSentCheckOff',
    label: `Test reports have been sent to the office for the project manager to review.`
  }
];

const otherCheckOff = [
  {
    name: 'subparStandardsCheckOff',
    label: `Items encountered during the installation of this project which are not up to codes or standards for
    this site are outlined on a Code & Standard Observation and reported to the customer.`
  },
  {
    name: 'stickerCheckOff',
    label: `A System Tech sticker was placed in a visible location in each telecommunications room.`
  }
];
