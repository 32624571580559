import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import { CustomInput, ExternalLink } from 'components';
import formatValue from 'helpers/formatValue.helper';

function EditableItem(props) {
  const { label, editing, hidden, marginTop = 0, width, ...rest } = props;

  if (hidden) return null;

  return (
    <div style={{ width, ...rest.style }}>
      {editing ? (
        <CustomInput margin="none" {...rest} label={label} />
      ) : (
        <>
          <Typography style={{ marginTop }}>{props.label}:</Typography>
          <Typography color="textSecondary" style={{ marginTop }}>
            {props.type === 'link' && props.value ? (
              <ExternalLink href={props.value}>open</ExternalLink>
            ) : (
              formatValue(props)
            )}
          </Typography>
        </>
      )}
    </div>
  );
}

export default EditableItem;
