import React from 'react';
import { Button } from 'helpers/themeSafeMui.helper';
import EditableRow from './EditableRow.component';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function ScheduleForm({
  scheduleFormData,
  setScheduleFormData,
  editing,
  setEditing,
  save,
  reset,
  float
}) {
  const user = useSelector(state => state.user);
  const canEdit = user.hasPermission('projects_tier_1.5');
  return (
    <div>
      <div
        style={{
          marginTop: 16,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <EditableRow
          editing={editing}
          type="boolean"
          value={scheduleFormData.scheduleOnMaster}
          label="Include on Master List"
          onChange={val =>
            setScheduleFormData(old => ({ ...old, scheduleOnMaster: val }))
          }
        />
        <EditableRow
          editing={editing}
          type="date"
          value={scheduleFormData.start}
          label="Start Date"
          onChange={val =>
            setScheduleFormData(old => ({
              ...old,
              start: moment(val).startOf('day')
            }))
          }
        />
        <EditableRow
          editing={editing}
          type="date"
          value={scheduleFormData.end}
          label="Deadline"
          onChange={val => setScheduleFormData(old => ({ ...old, end: val }))}
        />
        <EditableRow
          editing={false}
          type="date"
          value={float}
          label="Projected End Date"
        />
        <EditableRow
          editing={editing}
          type="Number"
          value={scheduleFormData.estimatedCrew}
          label="Tech Count"
          onChange={val =>
            setScheduleFormData(old => ({ ...old, estimatedCrew: val }))
          }
        />
        <EditableRow
          editing={editing}
          label="Work Days"
          type="dropdown"
          value={scheduleFormData.scheduleWorkDays}
          onChange={val => {
            const [scheduleDaysPerWeek, scheduleHrsPerDay] = val
              .split(',')
              .map(num => parseFloat(num));
            setScheduleFormData(old => ({
              ...old,
              scheduleDaysPerWeek,
              scheduleHrsPerDay,
              scheduleWorkDays: val
            }));
          }}
          options={[
            { value: '5,8', label: 'Five 8hr days per week' },
            { value: '4,10', label: 'Four 10hr days per week' },
            { value: '5,10', label: 'Five 10hr days per week' }
          ]}
        />
      </div>
      {canEdit && (
        <div
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {editing ? (
            <>
              <Button variant="contained" onClick={reset}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={save}
              >
                Save
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
