import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core';
import useFreshFn from 'hooks/useFreshFn.hook';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import React, { useEffect } from 'react';

export default function MultiSelectContents({
  allItems,
  selectedItems,
  setSelectedItems,
  name = 'Items',
  localStorageKey = null,
  checkboxPropsMap = {}
}) {
  const [selectedKeys, setSelectedKeys] = useLocalStorage(
    localStorageKey,
    selectedItems.map(item => item.key),
    joi => joi.array().items(joi.string())
  );

  const updateSelectedItems = useFreshFn(() => {
    setSelectedItems(allItems.filter(item => selectedKeys.includes(item.key)));
  });

  const onCheck = item => () => {
    setSelectedKeys(old => {
      if (old.includes(item.key)) {
        return old.filter(other => other !== item.key);
      } else {
        return [item.key, ...old];
      }
    });
  };

  useEffect(() => {
    updateSelectedItems(selectedKeys);
  }, [updateSelectedItems, selectedKeys]);

  return (
    <div style={{ padding: 10 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Selected {name}</FormLabel>
        <FormGroup>
          {allItems.map((item, idx) => (
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  checked={!!selectedKeys.includes(item.key)}
                  onChange={onCheck(item)}
                  name={item.label}
                  {...checkboxPropsMap[item.key]}
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
