import React, { useEffect, useContext, useState } from 'react';
import { TextField } from 'helpers/themeSafeMui.helper';
import { UsersContext } from '../context/users.context';
import { ProjectsContext } from '../context/projects.context';

const SearchFilter = ({ allPerDiems, setFiltered, setIsActive, ...rest }) => {
  const [search, setSearch] = useState('');
  const { usersMap } = useContext(UsersContext);
  const { projectsMap } = useContext(ProjectsContext);

  useEffect(() => {
    if (search) {
      setFiltered(
        allPerDiems.filter(perDiem => {
          const project = projectsMap[perDiem.projectId];
          const user = usersMap[perDiem.userId];
          const hasSearch = string =>
            string.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
          if (user) {
            if (hasSearch(user.name)) return true;
            if (hasSearch(user.legalFirstName)) return true;
          }
          if (project) {
            if (hasSearch(project.name)) return true;
            if (hasSearch(project.projectNumber)) return true;
          }
          return false;
        })
      );
      setIsActive(true);
    } else {
      setFiltered(allPerDiems);
      setIsActive(false);
    }
  }, [allPerDiems, setFiltered, setIsActive, search, usersMap, projectsMap]);

  return (
    <TextField
      value={search}
      onChange={e => setSearch(e.target.value)}
      label="Filter"
      {...rest}
    />
  );
};

export default SearchFilter;
