import React, { useState, useContext, useEffect } from 'react';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { getFavoriteProjects } from 'ducks/favoriteProjects.duck';
import { useColors } from 'helpers/theme.helper';
import toCurrency from 'helpers/currency.helper';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import { CopyText, HeaderItem, Toast } from 'components';
import EditProjectModal from '../modals/EditProject.modal';
import ProjectStatusBar from './ProjectStatusBar.component';
import ProjectWonLostButton from './ProjectWonLostButton.component';
import {
  ProjectContext,
  RefreshProjectContext
} from '../../../context/Project.context';

import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import StarIcon from '@material-ui/icons/Star';
import BusinessIcon from '@material-ui/icons/Business';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import moment from 'moment';
import { ChildProjectsContext } from 'routes/Projects/context/ChildProjectsContext.context';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import { env } from 'env';

const ProjectHeader = props => {
  const { favoriteProjects, getFavoriteProjects, user } = props;
  const isSmallScreen = useBreakpoint('sm');

  const colors = useColors();
  const { project, setProject } = useContext(ProjectContext);
  const { dollarValue } = useContext(ChildProjectsContext);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const refreshProject = useContext(RefreshProjectContext);

  const matchingFavoriteProject = favoriteProjects.find(
    current => current.projectId === project.id
  );

  useEffect(() => {
    getFavoriteProjects();
  }, [project.closed, getFavoriteProjects]);

  const toggleStar = () => {
    if (matchingFavoriteProject) {
      http()
        .delete(`/favorite-user-projects/${matchingFavoriteProject.id}`)
        .then(() => getFavoriteProjects())
        .catch(err => Toast.show(err.message));
    } else {
      http()
        .post('/favorite-user-projects', {
          userId: user.id,
          projectId: project.id
        })
        .then(() => getFavoriteProjects())
        .catch(err => Toast.show(err.message));
    }
  };

  const changeProjectStatus = statusId => {
    if (!user.hasPermission('projects_tier_2')) return;
    http()
      .put(`/projects/${project.id}`, { statusId })
      .then(res => {
        setProject(res);
        refreshProject();
        Toast.show('Project updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      {project.closed === true && (
        <Typography
          style={{
            color: 'white',
            backgroundColor: colors.error,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Closed
        </Typography>
      )}
      <div style={{ padding: 16 }}>
        <EditProjectModal
          open={editProjectModal}
          onClose={() => setEditProjectModal(false)}
          setProject={setProject}
          project={project}
        />

        <div
          style={{
            display: isSmallScreen ? null : 'flex',
            justifyContent: 'space-between',
            marginBottom: 16
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isSmallScreen && (
              <IconButton style={{ color: '#ffd600' }} onClick={toggleStar}>
                {matchingFavoriteProject ? (
                  <StarIcon fontSize="large" />
                ) : (
                  <StarBorderIcon fontSize="large" />
                )}
              </IconButton>
            )}

            <div style={{ margin: isSmallScreen ? 0 : 8 }}>
              <Typography variant="h5">
                <CopyText
                  text={`${project.projectNumber} - ${project.name}`}
                  type="Project Name"
                  iconSpanProps={{ style: { fontSize: '1.1rem' } }}
                  afterIconChildren={
                    user.hasPermission('projects_tier_2') && (
                      <span style={{ marginLeft: 10, lineHeight: 0 }}>
                        <EditIcon
                          size="inherit"
                          color="action"
                          fontSize="inherit"
                          onClick={() => setEditProjectModal(true)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )
                  }
                >
                  {project.projectNumber} - {project.name}
                </CopyText>
              </Typography>
              <CopyText
                text={`${project.projectNumber}@${env.emailDomain}`}
                type="Project Email"
              >
                <HeaderItem
                  Icon={MailIcon}
                  externalUrl={`mailto:${project.projectNumber}@${env.emailDomain}`}
                  label={`${project.projectNumber}@${env.emailDomain}`}
                />
              </CopyText>
              <HeaderItem
                Icon={BusinessIcon}
                internalUrl={`/companies/${project.companyId}`}
                label={project.companyName}
              />
            </div>
          </div>
          {project.created && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: isSmallScreen ? '5px 0' : '0 24px'
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {'Created Date: ' + moment(project.created).format('l')}
              </Typography>
            </div>
          )}
          {user.hasPermission('projects_tier_2') && (
            <div
              style={
                isSmallScreen
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      whiteSpace: 'nowrap',
                      paddingTop: 10
                    }
                  : {
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'nowrap'
                    }
              }
            >
              <Typography
                variant="h5"
                style={{
                  color: project.bidStatus
                    ? project.bidStatus === 'won'
                      ? colors.success
                      : colors.error
                    : colors.darkGrey,
                  marginRight: 16
                }}
              >
                {toCurrency(dollarValue)}
              </Typography>
              <ProjectWonLostButton project={project} setProject={setProject} />
            </div>
          )}
        </div>
        <ProjectStatusBar
          activeIndex={project.statusOrder}
          onClick={changeProjectStatus}
        />
      </div>
    </div>
  );
};

export default connect(
  state => ({
    favoriteProjects: state.favoriteProjects,
    user: state.user
  }),
  { getFavoriteProjects }
)(ProjectHeader);
