import React from 'react';
import { Typography, IconButton, Divider } from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { connect } from 'react-redux';

const AnnouncementDisplay = props => {
  const { announcement, user, refresh } = props;

  const archiveAnnouncement = id => {
    if (!window.confirm('Are you sure you want to delete this announcement?')) {
      return;
    }
    http()
      .delete(`/announcements/${id}`)
      .then(() => {
        Toast.show('Announcement deleted');
        refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 16,
          width: '100%'
        }}
      >
        <div>
          <Typography variant="h6" color="textPrimary">
            {announcement.title}
          </Typography>

          <Typography color="textSecondary" variant="body2" gutterBottom>
            {`${announcement.authorName} - ${moment(
              announcement.created
            ).format('LL')}`}
          </Typography>

          <Typography style={{ whiteSpace: 'pre-line' }}>
            {announcement.announcement}
          </Typography>
        </div>

        {user.hasPermission('announcements') && (
          <div>
            <IconButton onClick={() => archiveAnnouncement(announcement.id)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default connect(state => ({ user: state.user }))(AnnouncementDisplay);
