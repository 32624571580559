export function trim0s(string) {
  const parsed = parseFloat(string);
  if (isNaN(parsed)) return string;
  const smallerDec = parsed.toString();
  return smallerDec;
}

export function toDollar(string) {
  if (typeof string !== 'string') return string;
  const fixed = toMinDecPrecision(string, 2);
  if (fixed === null) return string;
  return `$${fixed}`;
}

export function twoAfterDec(string) {
  if (typeof string !== 'string') return string;
  const fixed = toMinDecPrecision(string, 2);
  if (fixed === null) return string;
  return fixed;
}

export function toMinDecPrecision(string, min) {
  const precision = Math.max(min, getDecPrecision(string));
  const parsed = parseFloat(string);
  if (isNaN(parsed)) return null;
  const fixed = parsed.toFixed(precision);
  return fixed;
}

export function getDecPrecision(string) {
  if (typeof string !== 'string') return string;
  let [, dec = ''] = string.split('.');
  let decPrecision = 0;
  for (let i = dec.length - 1; i >= 0; i--) {
    if (dec[i] !== '0') {
      decPrecision = i + 1;
      break;
    }
  }

  return decPrecision;
}
