import React from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem
} from 'helpers/themeSafeMui.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const UserSkill = ({ userId, skill, userSkill, getUserSkills }) => {
  const currentUser = useCurrentUser();

  const setUserSkill = value => {
    if (userSkill) {
      http()
        .put(`/users/${userId}/skills/${userSkill.id}`, {
          skillLevel: value
        })
        .then(res => {
          getUserSkills();
        })
        .catch(err => Toast.show(err.message));
    } else {
      http()
        .post(`/users/${userId}/skills`, {
          skillId: skill.id,
          skillLevel: value
        })
        .then(res => {
          getUserSkills();
        })
        .catch(err => Toast.show(err.message));
    }
  };

  return (
    <form>
      <Grid container>
        <Grid item xs={6} md={4} lg={2}>
          <Typography variant="subtitle1" gutterBottom>
            {skill.name}
          </Typography>
        </Grid>
        {!!skill.parentId ? (
          <Grid item xs={1}>
            {currentUser.hasPermission('employee_training') ? (
              <FormControl fullWidth>
                <Select
                  value={userSkill?.skillLevel || 0}
                  onChange={e => setUserSkill(e.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <Typography variant="subtitle1" gutterBottom>
                {userSkill?.skillLevel || 0}
              </Typography>
            )}
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};

export default UserSkill;
