import { PDFFont } from 'pdf-lib';

export default class PoPdfNotesHelper {
  constructor(private po: any, private font: PDFFont) {}

  private createNotesRows(options: {
    notesText: string;
    textSize: number;
    maxWidth: number;
    font?: PDFFont;
  }) {
    const { notesText, textSize, maxWidth, font = this.font } = options;

    if (!font || !notesText) return [];

    const lines = notesText
      .split('\n')
      .map(line => this.filterText(line, font))
      .map(line => line.split(' '));

    const getLineSliceWidth = (lineIdx: number, keepCount: number) => {
      const text = lines[lineIdx].slice(0, keepCount).join(' ');
      const width = font.widthOfTextAtSize(text, textSize);
      return width;
    };

    const wrappedRows = [] as string[];

    for (let lineIdx = 0; lineIdx < lines.length; lineIdx++) {
      const line = lines[lineIdx];
      let keepCount = line.length;
      for (; keepCount > 0; keepCount--) {
        const sliceWidth = getLineSliceWidth(lineIdx, keepCount);
        if (sliceWidth < maxWidth || keepCount === 1) {
          const keepWords = line.splice(0, keepCount);
          wrappedRows.push(keepWords.join(' '));
          keepCount = line.length;
        }
      }
    }

    return wrappedRows;
  }

  private filterText(s: string, font: PDFFont) {
    if (!s) return '';
    const cSet = font
      .getCharacterSet()
      .reduce((cSet, num) => ({ ...cSet, [num]: true }), {} as any);
    return s
      .split('')
      .filter(char => {
        const code = char.charCodeAt(0);
        return !!cSet[code];
      })
      .join('');
  }

  buildPages(
    maxWidth: number,
    textSize: number,
    linePad: number,
    sectionMaxHeight: number,
    pageMaxHeight: number
  ) {
    const rows = this.createNotesRows({
      notesText: this.po.notes,
      maxWidth,
      textSize
    });
    const rowHeight =
      this.font.heightAtSize(textSize, { descender: true }) + linePad;
    const pages = [] as string[][];
    const sectionRowCount = Math.floor(sectionMaxHeight / rowHeight);
    if (sectionRowCount > 0) {
      pages.push(rows.splice(0, sectionRowCount));
    }
    const rowsPerPage = Math.floor(pageMaxHeight / rowHeight);

    while (rows.length > 0) {
      pages.push(rows.splice(0, rowsPerPage));
    }

    return pages;
  }
}
