import React, { useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton
} from 'helpers/themeSafeMui.helper';
import { Toast, CustomInput } from 'components';
import http from 'helpers/http.helper';
import DeleteIcon from '@material-ui/icons/Delete';

function EditTaskPreset({
  initName,
  initSkillId,
  presetId,
  skillOptions,
  open,
  onClose,
  setPresets,
  presets
}) {
  const [name, setName] = useState(initName);
  const [skillId, setSkillId] = useState(initSkillId);
  const [loading, setLoading] = useState(false);

  //update preset
  const updatePreset = async e => {
    e.preventDefault();
    setLoading(true);
    http()
      .put(`/task-presets/${presetId}`, {
        name: name,
        skillId: skillId
      })
      .then(res => {
        setPresets(presets =>
          presets.map(preset => (preset.id === presetId ? res : preset))
        );
        onClose();
        setLoading(false);
        Toast.show('Updated.');
      })
      .catch(e => Toast.show(e.message));
  };

  //delete preset
  const deletePreset = async e => {
    e.preventDefault();
    setLoading(true);
    http()
      .delete(`/task-presets/${presetId}`)
      .then(res => {
        setPresets(presets => presets.filter(preset => preset.id !== presetId));
        onClose();
        setLoading(false);
        Toast.show('Deleted.');
      })
      .catch(e => Toast.show(e.message));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={updatePreset}>
        <DialogTitle>Edit Task Preset</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            label="Task Preset Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={e => setName(e.target.value)}
          />
          <CustomInput
            label="employee skill"
            type="dropdown"
            onChange={setSkillId}
            value={skillId}
            options={skillOptions.map(skill => ({
              label: skill.name,
              value: skill.id
            }))}
          />
        </DialogContent>

        <DialogActions>
          <IconButton onClick={deletePreset}>
            <DeleteIcon />
          </IconButton>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditTaskPreset;
