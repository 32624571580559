import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Typography
} from 'helpers/themeSafeMui.helper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';

export default function WeeklyQuizzesModal({ open, onClose }) {
  const [currentWeek, setCurrentWeek] = useState(moment().startOf('week'));
  const [groups, setGroups] = useState([]);
  const [groupMap, setGroupMap] = useState({});
  const [moduleMap, setModuleMap] = useState({});
  const [weekMap, setWeekMap] = useState({});

  const addCurrentWeeks = change =>
    setCurrentWeek(old => moment(old).add(change, 'weeks'));

  useEffect(() => {
    Promise.all([
      http().get('/training-quizzes/weekly-modules'),
      http().get('/training-groups/populated')
    ])
      .then(([weeklies, groups]) => {
        setWeekMap(
          weeklies.reduce(
            (map, weekly) =>
              Object.assign(map, { [getMapKey(weekly.dateStart)]: weekly }),
            {}
          )
        );
        setGroupMap(groups.reduce(pluck('id'), {}));
        const allModules = groups.map(group => group.modules).flat();
        setModuleMap(allModules.reduce(pluck('id'), {}));
        setGroups(groups);
      })
      .catch(Toast.showErr);
  }, []);

  const handleChange = week => trainingModuleId => {
    const current = weekMap[getMapKey(week)];
    let promise;
    if (current) {
      promise = trainingModuleId
        ? http().put(`/training-quizzes/weekly-modules/${current.id}`, {
            trainingModuleId
          })
        : http().delete(`/training-quizzes/weekly-modules/${current.id}`);
    } else {
      promise = http().post(`/training-quizzes/weekly-modules`, {
        trainingModuleId,
        dateStart: moment(week).startOf('week'),
        dateEnd: moment(week).endOf('week')
      });
    }
    promise
      .then(weekly =>
        setWeekMap(old => ({ ...old, [getMapKey(week)]: weekly || null }))
      )
      .catch(Toast.showErr);
  };

  const getActiveNames = week => {
    const current = weekMap[getMapKey(week)];
    const none = {
      activeGroup: 'None',
      activeModule: null,
      activeModuleId: ''
    };
    if (!current) return none;
    const module = moduleMap[current.trainingModuleId];
    if (!module) return none;
    const group = groupMap[module.trainingGroupId];
    if (!group) return none;
    return {
      activeGroup: group.name,
      activeModule: module.name,
      activeModuleId: module.id
    };
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Weekly Quizzes</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <WeeklyQuizTile
            groups={groups}
            startMoment={addWeeks(currentWeek, -1)}
            onChange={handleChange(addWeeks(currentWeek, -1))}
            {...getActiveNames(addWeeks(currentWeek, -1))}
          />
          <WeeklyQuizTile
            groups={groups}
            startMoment={addWeeks(currentWeek, 0)}
            onChange={handleChange(addWeeks(currentWeek, 0))}
            {...getActiveNames(addWeeks(currentWeek, 0))}
          />
          <WeeklyQuizTile
            groups={groups}
            startMoment={addWeeks(currentWeek, 1)}
            onChange={handleChange(addWeeks(currentWeek, 1))}
            {...getActiveNames(addWeeks(currentWeek, 1))}
          />
        </div>
      </DialogContent>
      <div style={{ display: 'flex' }}>
        <Button fullWidth onClick={() => addCurrentWeeks(-1)}>
          <KeyboardArrowLeft />
          Back
        </Button>
        <Button fullWidth onClick={() => addCurrentWeeks(1)}>
          Next
          <KeyboardArrowRight />
        </Button>
      </div>
    </Dialog>
  );
}

function WeeklyQuizTile({
  groups,
  startMoment,
  onChange,
  activeGroup,
  activeModule,
  activeModuleId
}) {
  const colors = useColors();
  const handleChange = e => {
    const val = e.target.value;
    onChange(val);
  };
  return (
    <Paper
      style={{
        flexBasis: 'calc(33.333% - 15px)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="subtitle2" style={{ padding: '10px 10px 3px' }}>
        {dateRange(startMoment)}
      </Typography>
      <Divider />
      <div
        style={{
          padding: 10,
          flex: '0 1 100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Typography variant="subtitle2">Current Module:</Typography>
          <Typography variant="caption" component="div">
            {activeGroup}
          </Typography>
          <Typography variant="caption" component="div">
            {activeModule}
          </Typography>
        </div>
        <FormControl style={{ marginTop: 20 }} fullWidth>
          <InputLabel htmlFor={`grouped-select-${getMapKey(startMoment)}`}>
            Select Module
          </InputLabel>
          <Select
            defaultValue=""
            id={`grouped-select-${getMapKey(startMoment)}`}
            onChange={handleChange}
            value={activeModuleId}
          >
            <MenuItem value={null}>
              <em>None</em>
            </MenuItem>
            {groups
              .map(group => [
                <ListSubheader
                  style={{
                    backgroundColor: colors.paper,
                    borderBottom: `1px solid ${colors.grey}`
                  }}
                  key={group.id}
                >
                  {group.name}
                </ListSubheader>,
                group.modules.map(module => (
                  <MenuItem key={module.id} value={module.id}>
                    {module.name}
                  </MenuItem>
                ))
              ])
              .flat()}
          </Select>
        </FormControl>
      </div>
    </Paper>
  );
}

function addWeeks(m, change) {
  return moment(m).add(change, 'weeks');
}

function getMapKey(m) {
  return moment(m)
    .startOf('week')
    .format('L');
}

function pluck(key) {
  return (acc, item) => Object.assign(acc, { [item[key]]: item });
}

function dateRange(m) {
  m = moment(m).startOf('w');
  return (
    moment(m)
      .add(1, 'd')
      .format('M/D') +
    ' - ' +
    moment(m)
      .add(5, 'd')
      .format('M/D')
  );
}
