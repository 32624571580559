import pngUrl from 'assets/st-logo.png';
import { PdfLibComponent } from 'components';
import {  trim0s } from 'helpers/floatStringFormatter.helper';
import moment from 'moment';
import { grayscale, PDFDocument, PDFFont, PDFImage, PDFPage } from 'pdf-lib';
import PoPdfNotesHelper from './poPdfNotes.helper';
const { PdfLibTable } = PdfLibComponent;

interface Pos {
  x: number;
  y: number;
}
interface Size {
  width: number;
  height: number;
}
interface HeaderImage {
  pngImage: PDFImage;
  pngDims: Size;
}

const borderColor = grayscale(0.7);
const color = grayscale(0.9);
const pagePad = 10;
const notesTextSize = 12;
const notePad = pagePad;
const noteHeaderHeight = 20;
const contactsTableTopY = 165;
const notesLinePad = 3;
const freightWarning =
  'System Tech does not allow freight on orders. Contact the purchaser to discuss options prior to shipment.';

interface ConstructorOptions {
  doc: PDFDocument;
  po: any;
  items: any[];
  altHeader?: string;
  hideColumnIdxs?: number[];
  materialStatuses: {};
}

export default class PoPdfHelper {
  private fontSize = 10;
  private headerImage: HeaderImage | null = null;
  private headerFont: PDFFont | null = null;
  private table = new PdfLibTable();
  private contactsTable = new PdfLibTable();
  private notesPages: string[][] = [];
  private tableTopY = 275;
  private disableFreightWarning = false;
  doc: PDFDocument;
  po: any;
  items: any[];
  altHeader: string;
  hideColumnIdxs: number[];
  materialStatuses: Object;


  constructor({
    doc,
    po,
    items,
    altHeader = '',
    hideColumnIdxs = [],
    materialStatuses = {}
  }: ConstructorOptions) {
    if (altHeader) this.tableTopY = pagePad + 20;
    this.doc = doc;
    this.po = po;
    this.items = items;
    this.altHeader = altHeader;
    this.hideColumnIdxs = hideColumnIdxs;
    this.disableFreightWarning = po.shipping === 'Per Quoted Amount';
    this.materialStatuses = materialStatuses;
  }

  async build() {
    if (!this.altHeader) {
      this.headerImage = await this.buildHeaderImage();
    }
    this.table = new PdfLibTable();
    await this.table.embedFonts(this.doc);
    this.headerFont = this.table.headerFont;

    const firstPage = this.doc.addPage();
    if (!this.altHeader) this.setupContactsTable(firstPage);
    this.setupPoTable(firstPage, this.items);
    const pageCount = this.table.cellDataPages.length;
    for (let pageIdx = 0; pageIdx < pageCount; pageIdx++) {
      let page = firstPage;
      if (pageIdx !== 0) page = this.doc.addPage();
      await this.poHeader(page);
      this.table.draw(page, pageIdx);
      this.drawPageNumber(page, pageIdx + 1, pageCount);
    }
    await this.addNotes();
  }

  private async buildHeaderImage() {
    const { doc } = this;
    const pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer());
    const desiredWidth = 200;
    const pngImage = await doc.embedPng(pngImageBytes);
    const scaleFactor = desiredWidth / pngImage.width;
    const pngDims = pngImage.scale(scaleFactor);
    return { pngImage, pngDims };
  }

  private drawPageNumber(
    page: PDFPage,
    pageNumber: number,
    totalPages: number
  ) {
    const { headerFont } = this;
    if (!headerFont) return;
    const fontSize = 8;
    const pageWidth = page.getWidth();
    const pageHeight = page.getHeight();
    const textHeight = headerFont.heightAtSize(fontSize, { descender: false });
    this.rightText(
      `${pageNumber} / ${totalPages}`,
      page,
      pageWidth - pagePad,
      pageHeight - pagePad - textHeight,
      fontSize
    );
  }

  private poHeader(page: PDFPage) {
    if (this.altHeader) return this.createAltHeader(page);
    if (!this.headerImage) return;
    const { fixY, headerImage } = this;
    const { pngImage, pngDims } = headerImage;
    const pagePad = 10;
    page.drawImage(pngImage, {
      x: pagePad,
      y: fixY(pagePad, page, pngDims.height),
      width: pngDims.width,
      height: pngDims.height
    });
    this.drawPoNumber(page);
    this.drawAttnHeader(page);
    this.drawContacts(page);
  }

  createAltHeader(page: PDFPage) {
    this.text(this.altHeader, page, pagePad, pagePad);
  }

  private drawPoNumber(page: PDFPage) {
    const pageWidth = page.getWidth();
    const pad = 5;
    const poNumSize = 14;
    const poLabelText = 'PO Number';
    const poLabelSize = this.measure(poLabelText);
    const poTextSize = this.measure(this.po.poNumber, poNumSize);
    const maxTextWidth = Math.max(poTextSize.width, poLabelSize.width);
    const rectWidth = maxTextWidth + pad * 2;
    const rectX = pageWidth - pagePad - rectWidth;
    const rectHeight = poLabelSize.height + poTextSize.height + pad * 3;

    page.drawRectangle({
      x: rectX,
      y: this.fixY(pagePad, page, rectHeight),
      width: rectWidth,
      height: rectHeight,
      borderWidth: 1,
      borderColor,
      color
    });
    this.text(
      poLabelText,
      page,
      pageWidth - pagePad - pad - maxTextWidth,
      pagePad + pad
    );
    this.rightText(
      this.po.poNumber,
      page,
      pageWidth - pagePad - pad,
      pagePad + poLabelSize.height + pad * 2,
      14
    );
  }

  private drawAttnHeader(page: PDFPage) {
    const pad = 5;
    const pageWidth = page.getWidth();
    const headerText = 'Purchase Order';
    const headerFontSize = 16;
    const headerSize = this.measure(headerText, headerFontSize);
    const rectHeight = headerSize.height + pad * 2;
    const rectWidth = pageWidth - pagePad * 2;
    const topY = pagePad + 75;

    page.drawRectangle({
      x: pagePad,
      y: this.fixY(topY, page, rectHeight),
      width: rectWidth,
      height: rectHeight,
      borderWidth: 0,
      color
    });

    this.text(headerText, page, pagePad + pad, topY + pad, headerFontSize);

    const attnTableY = topY + rectHeight;
    const attnTableWidth = (pageWidth * 2) / 3;
    const attnTable = this.leftLabelTable(
      [
        ['To:', this.po.to],
        ['Attn:', this.po.attn],
        ['Quote:', this.po.quoteNumber]
      ],
      {
        x: pagePad,
        y: attnTableY,
        width: attnTableWidth,
        height: 0
      }
    );
    const dateTableWidth = pageWidth / 3;
    let date = this.po.date ? moment(this.po.date).format('l') : 'N / A';
    const dateTable = this.leftLabelTable(
      [
        ['Date:', date],
        ['From:', this.po.from],
        ['Shipping:', this.po.shipping]
      ],
      {
        x: pagePad + attnTableWidth,
        y: attnTableY,
        width: dateTableWidth,
        height: 0
      }
    );
    attnTable.draw(page);
    dateTable.draw(page);
  }

  private setupContactsTable(page: PDFPage) {
    const pageWidth = page.getWidth();
    const { po } = this;
    const billToText = [
      ['Bill To:', po.billTo],
      [
        'Billing Contact:',
        this.createContactText(po.billToContactEmail, po.billToContactPhone)
      ]
    ];
    const shipToText = this.setupShipToText();
    const table = this.leftLabelTable(
      [...billToText, ['', ''], ...shipToText],
      {
        x: pagePad,
        y: contactsTableTopY,
        width: pageWidth - pagePad * 2,
        height: 0
      }
    );

    const height = table.getHeightAtPage(0);
    const freightHeight = this.disableFreightWarning ? 0 : 20;
    this.tableTopY = contactsTableTopY + height + freightHeight + 12;
    this.contactsTable = table;
    return table;
  }

  private drawContacts(page: PDFPage) {
    if (!this.disableFreightWarning)
      this.text(freightWarning, page, pagePad, this.tableTopY - 23, 12);
    this.contactsTable.draw(page);
  }

  private setupShipToText() {
    const textRows = [] as string[][];
    const { po } = this;
    const title = (po.shipTo as string) || null;
    const address = (po.shipToAddress as string) || null;
    const lCol: string[] = ['Ship To:'];
    const rCol: string[] = [];
    if (title && address) {
      rCol.push(title, address);
      lCol.push('');
    } else if (address) rCol.push(address);
    else if (title) rCol.push(title);
    lCol.forEach((l, idx) => {
      textRows.push([l, rCol[idx]]);
    });
    textRows.splice(1, 0, ['Attn:', po.poNumber]);
    textRows.push([
      'Shipping Contact:',
      this.createContactText('', po.shipToContactPhone)
    ]);
    return textRows;
  }

  private createContactText(email: string, phone: string) {
    if (!email && !phone) return '';
    if (!phone) return email;
    if (!email) return phone;
    return `${email} ${phone}`;
  }

  private async addNotes() {
    if (!this.headerFont) return;
    const { tableTopY } = this;
    const minSamePageHeight = 110;
    const lastPageIdx = this.table.cellDataPages.length - 1;
    const lastPage = this.doc.getPage(lastPageIdx);
    const tableHeight = this.table.getHeightAtPage(lastPageIdx);
    const pageHeight = lastPage.getHeight();
    const remainingHeight =
      pageHeight -
      (tableTopY + tableHeight + notePad + noteHeaderHeight + pagePad);

    const pageWidth = this.doc.getPage(lastPageIdx).getWidth();
    const notesWidth = pageWidth - pagePad * 2 - notePad * 2;
    const notesHelper = new PoPdfNotesHelper(this.po, this.headerFont);

    const onTablePage = remainingHeight >= minSamePageHeight;

    this.notesPages = notesHelper.buildPages(
      notesWidth,
      notesTextSize,
      notesLinePad,
      onTablePage ? remainingHeight : 0,
      pageHeight - tableTopY - noteHeaderHeight - notePad
    );

    if (!this.notesPages.length || !this.notesPages[0].length) return;
    const notesCopy = [...this.notesPages];

    if (onTablePage) {
      const tableBottomY = tableTopY + tableHeight;
      const noteTopY = tableBottomY + notePad;
      const [rows] = notesCopy.splice(0, 1);
      this.drawNotes(lastPage, noteTopY, rows);
    }

    while (notesCopy.length > 0) {
      let page = this.doc.addPage();
      await this.poHeader(page);
      const [rows] = notesCopy.splice(0, 1);
      this.drawNotes(page, tableTopY, rows);
    }
  }

  private drawNotes(page: PDFPage, topY: number, rows: string[]) {
    if (!this.headerFont) return;

    const headerTextSize = notesTextSize + 4;
    const textHeight = this.headerFont.heightAtSize(notesTextSize, {
      descender: true
    });

    let currentY = topY;
    this.text('Notes:', page, notePad, currentY, headerTextSize);

    currentY += noteHeaderHeight + notesLinePad;
    for (let row of rows) {
      this.text(row, page, notePad * 2, currentY, notesTextSize);
      currentY += textHeight + notesLinePad;
    }
  }

  private setupPoTable(page: PDFPage, items: any[]) {
    const { tableTopY } = this;

    this.table.getAlign = (pageIdx, row, col) => {
      if (col === 0 || col === 3 || col === 4) return 'r';
      return 'l';
    };
    this.table.padX = 3;
    this.table.setText(this.getPoTableText(items));
    const maxHeight = page.getHeight() - (tableTopY + pagePad * 2);
    this.table.setup(
      10,
      tableTopY,
      page.getWidth() - 20,
      [0, 1, 3, 4, 5],
      maxHeight
    );
  }

  private getPoTableText(items: any[]) {
    const s = (val: any) => (val ? `${val}` : '');

    const header = [
      'QTY',
      'Manufacturer',
      'Model',
      'Description',
      'Received',
      'Deployed',
      'Returned',
    ];
    items.forEach( mat => {
      const entry = Object.entries(this.materialStatuses).find(i => i[0] === mat.materialId);
      mat.received = entry && entry[1].received;
      mat.deployed = entry && entry[1].deployed;
      mat.returned = entry && entry[1].returned;
      } 
    );
   
      function trimString(string: string){
        if(!string) return null;
        return string?.length > 40 ? string.substring(0, 37).concat('...') : string;
      }

    const rows = items.map(mat => {
      return [
        trim0s(s(mat.quantity)),
        s(mat.manufacturer),
        s(mat.model),
        s(trimString(mat.description)),
        s(mat.received),
        s(mat.deployed),
        s(mat.returned)
      ];
    });
    const removeSet = this.hideColumnIdxs.reduce(
      (map, idx) => Object.assign(map, { [idx]: true }),
      {} as { [key: number]: boolean }
    );

    let results = [header]
      .concat(rows)
      .map(row => row.filter((cell, idx) => (removeSet[idx] ? false : true)));
    return results;
  }

  private leftLabelTable(text: string[][], area: Pos & Size) {
    const emptyRowIdxs = text.reduce(
      (empty, row, idx) => (row.every(text => !text) ? [...empty, idx] : empty),
      [] as number[]
    );
    const table = new PdfLibTable();
    table.headerFont = this.headerFont;
    table.padX = 5;
    table.padY = 4;
    table.cellFont = this.headerFont;
    table.headerFontSize = 10;
    table.rowsFontSize = 10;
    table.getAlign = (p, r, c) => (c === 0 ? 'r' : 'l');
    table.drawCellBackground = (cell, page, pageIdx, rowIdx, colIdx) => {
      if (emptyRowIdxs.includes(rowIdx)) return;
      if (colIdx === 0)
        page.drawRectangle({
          x: cell.x,
          y: this.fixY(cell.y, page, cell.height),
          width: cell.width,
          height: cell.height,
          borderWidth: 0,
          color
        });
    };
    table.setText(text);
    table.setup(area.x, area.y, area.width, [0]);
    return table;
  }

  private rightText(
    text: string,
    page: PDFPage,
    x: number,
    y: number,
    fontSize = this.fontSize
  ) {
    const { width } = this.measure(text, fontSize);
    return this.text(text, page, x - width, y, fontSize);
  }

  private text(
    text: string,
    page: PDFPage,
    x: number,
    y: number,
    fontSize = this.fontSize
  ) {
    const { headerFont } = this;
    if (!headerFont) return 0;
    const filtered = this.filterText(text, headerFont);
    const m = this.measure(text, fontSize);
    const data = {
      x,
      y: this.fixY(y, page, m.height),
      font: headerFont,
      size: fontSize
    };
    page.drawText(filtered, data);
    return m;
  }

  private fixY(y: number, page: PDFPage, itemHeight = 0) {
    return page.getHeight() - y - itemHeight;
  }

  private filterText = (s: string, font: PDFFont) => {
    if (!s) return '';
    const cSet = font
      .getCharacterSet()
      .reduce((cSet, num) => ({ ...cSet, [num]: true }), {} as any);
    return s
      .split('')
      .filter(char => {
        const code = char.charCodeAt(0);
        return !!cSet[code];
      })
      .join('');
  };

  private measure(text: string, size: number = this.fontSize) {
    const { headerFont } = this;
    if (!headerFont) return { width: 0, height: 0 };
    const width = headerFont.widthOfTextAtSize(text, size);
    const height = headerFont.heightAtSize(size, { descender: true });
    return { width, height };
  }

}
