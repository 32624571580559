import React from 'react';
import { Toast } from 'components';
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import http from 'helpers/http.helper';

const styles = {
  deleteIcon: { position: 'absolute', top: 10, right: 10 }
};

class EditProjectStatus extends React.Component {
  state = {
    status: this.props.status.status,
    teamLocked: this.props.status.teamLocked
  };

  archive = () => {
    const item = this.props.status;
    const tempList = this.props.projectStatuses.filter(r => item.id !== r.id);

    http()
      // First we are deleting the module from the list
      .delete(`/project-statuses/${item.id}`)
      .then(() => {
        Toast.show('Status sucessfully deleted');
        // Next, we are updating the order of the items in the list
        tempList.forEach((item, index) => {
          this.updateListItem(item, index);
        });
      })
      .catch(err => Toast.show(err.message));

    this.props.onClose();
  };

  updateListItem = (item, index) => {
    http()
      .put(`/project-statuses/${item.id}`, {
        order: index + 1
      })
      .then(() => this.props.refresh())
      .catch(err => Toast.show(err.message));
  };

  save = e => {
    e.preventDefault();
    http()
      .put(`/project-statuses/${this.props.status.id}`, {
        name: this.state.status,
        teamLocked: this.state.teamLocked
      })
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBooleanChange = key => () => {
    this.setState({ [key]: !this.state[key] });
  };

  render() {
    const { status } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.save} style={{ padding: '16px' }}>
        <div style={styles.deleteIcon}>
          {this.state.showDeleteConfirm ? (
            <Typography>
              Are you sure?{' '}
              <Button color="primary" size="small" onClick={this.archive}>
                Delete
              </Button>
            </Typography>
          ) : (
            <IconButton
              onClick={() => this.setState({ showDeleteConfirm: true })}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        <DialogContent style={{ padding: 10 }}>
          <TextField
            autoFocus
            label="Project Status"
            fullWidth
            required
            value={status}
            name="status"
            margin="normal"
            onChange={this.handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.teamLocked}
                value={this.state.teamLocked}
                onChange={this.handleBooleanChange('teamLocked')}
                color="primary"
              />
            }
            label="Team Locked"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default EditProjectStatus;
