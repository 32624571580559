import React from 'react';
import { IconButton } from 'helpers/themeSafeMui.helper';

const SmallIcon = ({ Component, onClick, disabled, ...rest }) => {
  return (
    <IconButton disabled={disabled} size="small" onClick={onClick} {...rest}>
      <Component fontSize="small" />
    </IconButton>
  );
};

export default SmallIcon;
