import React from 'react';
import { connect } from 'react-redux';
import { AddressPredict, Toast } from 'components';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import http from 'helpers/http.helper';
import { setUser } from 'ducks/user.duck';
import moment from 'moment';

class PersonalInfo extends React.Component {
  state = {
    firstName: '',
    legalFirstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    address: '',
    address2: '',
    shirtSize: '',
    birthDate: null,
    confirmPassword: '',
    password: ''
  };

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      firstName: user.firstName || '',
      legalFirstName: user.legalFirstName || '',
      lastName: user.lastName || '',
      middleName: user.middleName || '',
      email: user.email || '',
      phoneNumber: user.phoneNumber || '',
      address: user.address || '',
      address2: user.address2 || '',
      shirtSize: user.shirtSize || '',
      birthDate: user.birthDate || null
    });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = e => {
    e.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      return Toast.show("Passwords don't match.");
    }

    http()
      .put('/users/me', {
        firstName: this.state.firstName,
        legalFirstName: this.state.legalFirstName,
        lastName: this.state.lastName,
        middleName: this.state.middleName,
        personalEmail: this.state.email,
        password: this.state.password || undefined,
        phoneNumber: this.state.phoneNumber,
        address: this.state.address,
        address2: this.state.address2 || undefined,
        shirtSize: this.state.shirtSize,
        birthDate: moment(this.state.birthDate).format('L')
      })
      .then(user => this.props.setUser(user))
      .then(() => this.props.history.push('/onboarding/identification'))
      .catch(err => Toast.show(err.message));
  };

  render() {
    const shirtSizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];

    return (
      <form autoComplete="off" onSubmit={this.submit}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              name="firstName"
              label="Preferred First Name"
              autoFocus
              required
              fullWidth
              margin="dense"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="legalFirstName"
              label="Legal First Name"
              autoFocus
              required
              fullWidth
              margin="dense"
              value={this.state.legalFirstName}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="lastName"
              label="Last Name"
              required
              fullWidth
              margin="dense"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="middleName"
              label="Middle Name"
              fullWidth
              margin="dense"
              value={this.state.middleName}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="email"
              label="Email"
              type="email"
              required
              fullWidth
              margin="dense"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="phoneNumber"
              label="Mobile"
              type="tel"
              required
              fullWidth
              margin="dense"
              value={this.state.phoneNumber}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              format="MM/DD/YYYY"
              autoOk
              name="birthDate"
              label="Birth Date"
              disableFuture
              required
              fullWidth
              margin="dense"
              value={this.state.birthDate}
              onChange={birthDate => this.setState({ birthDate })}
            />
          </Grid>

          <Grid item xs={5}>
            <AddressPredict
              name="address"
              label="Address"
              required
              fullWidth
              margin="dense"
              value={this.state.address}
              onChange={address => this.setState({ address })}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="address2"
              label="Apt/Suite"
              fullWidth
              margin="dense"
              value={this.state.address2}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Shirt Size</InputLabel>
              <Select
                value={this.state.shirtSize}
                onChange={this.handleChange}
                name="shirtSize"
                required
              >
                {shirtSizes.map(size => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {!this.props.user.passwordSet && (
            <React.Fragment>
              <Grid item xs={6}>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  pattern=".{8}"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  pattern=".{8}"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ float: 'right', marginTop: 30 }}
        >
          Next
        </Button>
        <div style={{ clear: 'both' }} />
      </form>
    );
  }
}

export default connect(state => ({ user: state.user }), { setUser })(
  PersonalInfo
);
