import React from 'react';
import { Toast } from 'components';
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import http from 'helpers/http.helper';

const styles = {
  deleteIcon: { position: 'absolute', top: 10, right: 10 }
};

class EditTrainingGroup extends React.Component {
  state = {
    name: this.props.trainingGroup.name
  };

  archive = () => {
    const groupToDelete = this.props.trainingGroup;
    const tempList = this.props.trainingGroups.filter(
      group => groupToDelete.id !== group.id
    );

    http()
      // First we are deleting the module from the list
      .delete(`/training-groups/${groupToDelete.id}`)
      .then(() => {
        // Next, we are updating the order of the items in the list
        tempList.forEach((group, index) => {
          this.updateListItem(group, index);
        });
      });

    this.props.onClose();
  };

  updateListItem = (group, index) => {
    http()
      .put(`/training-groups/${group.id}`, {
        order: index + 1
      })
      .then(() => this.props.refresh())
      .catch(err => Toast.show(err.message));
  };

  save = e => {
    e.preventDefault();
    http()
      .put(`/training-groups/${this.props.trainingGroup.id}`, {
        name: this.state.name
      })
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { name } = this.state;
    return (
      <form autoComplete="off" onSubmit={this.save} style={{ padding: '16px' }}>
        <div style={styles.deleteIcon}>
          {this.state.showDeleteConfirm ? (
            <Typography>
              Are you sure?{' '}
              <Button color="primary" size="small" onClick={this.archive}>
                Delete
              </Button>
            </Typography>
          ) : (
            <IconButton
              onClick={() => this.setState({ showDeleteConfirm: true })}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        <DialogContent style={{ padding: 10 }}>
          <TextField
            autoFocus
            label="Training Group Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={this.handleChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default EditTrainingGroup;
