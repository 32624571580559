import React from 'react';
import {
  Typography,
  List,
  ListItem,
  Paper,
  Divider
} from 'helpers/themeSafeMui.helper';
import { ItemDisplay, NoItemsResult, ExternalLink, CopyText } from 'components';

class PersonEmails extends React.Component {
  render() {
    const { person, openModal } = this.props;

    return (
      <Paper style={{ padding: 16 }}>
        <Typography variant="h6">Emails</Typography>
        <Divider />

        <List>
          {person.emails.map(item => {
            return (
              <React.Fragment key={item.id + item}>
                <ListItem style={{ cursor: 'pointer' }} onClick={openModal}>
                  <ItemDisplay
                    itemTitle={
                      <CopyText text={item.email} type="Email">
                        <ExternalLink href={`mailto:${item.email}`}>
                          {item.email}
                        </ExternalLink>
                      </CopyText>
                    }
                    itemSubTitle={item.type}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
        {!person.emails.length && <NoItemsResult type="emails" />}
      </Paper>
    );
  }
}

export default PersonEmails;
