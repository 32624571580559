import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from 'helpers/themeSafeMui.helper';
import { NoItemsResult, Toast } from 'components';
import http from 'helpers/http.helper';
import { showModal } from 'ducks/modal.duck';
import EstimateContainer from './components/EstimateContainer.component';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import EstimateContextProvider from '../../context/Estimate.context';

function EstimatesTab(props) {
  const { projectId } = props;
  const query = qs.parse(props.location.search);
  const [estimates, setEstimates] = useState([]);

  const getEstimates = useCallback(() => {
    http()
      .get(`/projects/${projectId}/estimates`)
      .then(estimates => {
        if (estimates[0]) {
          estimates[0] = { ...estimates[0], isOpen: true };
        }
        setEstimates(estimates);
      })
      .catch(err => Toast.show(err.message));
  }, [projectId]);

  useEffect(() => {
    getEstimates();
  }, [getEstimates, query.r]);

  return (
    <Grid container spacing={3}>
      {estimates.length === 0 && (
        <Grid item xs={12}>
          <Paper style={{ padding: 16 }}>
            <NoItemsResult type="Estimates" style={{ padding: 50 }} />
          </Paper>
        </Grid>
      )}

      {estimates.map(estimate => (
        <EstimateContextProvider key={estimate.id} estimate={estimate}>
          <EstimateContainer estimate={estimate} onUpdate={getEstimates} />
        </EstimateContextProvider>
      ))}
    </Grid>
  );
}

export default withRouter(
  connect(state => ({ currentUser: state.user }), { showModal })(EstimatesTab)
);
