import React, { useState, useEffect } from 'react';
import {
  Input,
  InputAdornment,
  Paper,
  Button,
  Link,
  FormControl,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography
} from 'helpers/themeSafeMui.helper';
import ProjectFilterColumn from './components/ProjectFilterColumn.component';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useColors } from 'helpers/theme.helper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import useFreshFn from 'hooks/useFreshFn.hook';
import useProjectFilter from './hooks/useProjectFilter.hook';
import userAssociations from 'helpers/userAssociations.helper';
import mapReducer from 'helpers/mapReducer.helper';

const ONE_COL_WIDTH = 950 / 4;

function ProjectFilterDropdown({
  projects,
  onUpdate,
  localStorageRootKey,
  onChangeFilterKeys = () => {}
}) {
  const colors = useColors();
  const [wrapperRef, setWrapperRef] = useState(null);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredProjectsLength, setFilteredProjectsLength] = useState(null);
  const freshOnChangeFilterKeys = useFreshFn(onChangeFilterKeys);
  const projectUserAssociations = userAssociations();

  const rootKey = `project_filter_dropdown.${localStorageRootKey}`;
  const [
    isShowingClosedProjects,
    setIsShowingClosedProjects
  ] = useLocalStorage(`${rootKey}.is_showing_closed_projects`, false, joi =>
    joi.boolean()
  );
  const [
    isShowingMyProjects,
    setIsShowingMyProjects
  ] = useLocalStorage(`${rootKey}.is_showing_my_projects`, true, joi =>
    joi.boolean()
  );

  const defaultSelectedFilterUsers = projectUserAssociations.reduce(
    mapReducer.value('baseKey', () => []),
    {}
  );
  const validateSelectedFilterUsers = joi =>
    joi
      .object(
        projectUserAssociations.reduce(
          mapReducer.value('baseKey', () =>
            joi
              .array()
              .items(joi.string())
              .required()
          ),
          {}
        )
      )
      .unknown(false);
  const [selectedFilterUsers, setSelectedFilterUsers] = useLocalStorage(
    `${rootKey}.selected_filter_users`,
    defaultSelectedFilterUsers,
    validateSelectedFilterUsers
  );
  const setSelectedFilterUsersAt = key => value =>
    setSelectedFilterUsers(old => {
      const oldValue = old[key];
      const newValue = typeof value === 'function' ? value(oldValue) : value;
      if (oldValue === newValue) return old;
      return { ...old, [key]: newValue };
    });

  const defaultNoneFlags = projectUserAssociations.reduce(
    mapReducer.value('baseKey', false),
    {}
  );
  const validateNoneFlags = joi =>
    joi
      .object(
        projectUserAssociations.reduce(
          mapReducer.value('baseKey', () => joi.boolean()),
          {}
        )
      )
      .unknown(false);
  const [noneFlags, setNoneFlags] = useLocalStorage(
    `${rootKey}.none_flags`,
    defaultNoneFlags,
    validateNoneFlags
  );
  const setNoneFlag = key => value =>
    setNoneFlags(old => {
      const oldValue = old[key];
      const newValue = typeof value === 'function' ? value(oldValue) : value;
      if (oldValue === newValue) return old;
      return { ...old, [key]: newValue };
    });

  useEffect(() => {
    freshOnChangeFilterKeys({
      ...selectedFilterUsers
    });
  }, [freshOnChangeFilterKeys, selectedFilterUsers]);

  const filterColumns = projectUserAssociations.map(
    ({ name, baseKey, pluralName }) => ({
      title: name,
      pluralTitle: pluralName,
      userIdKey: baseKey + 'Id',
      columnValues: selectedFilterUsers[baseKey],
      setColumnValues: setSelectedFilterUsersAt(baseKey),
      noneFlag: noneFlags[baseKey],
      setNoneFlag: setNoneFlag(baseKey)
    })
  );

  useEffect(() => {
    http()
      .get('/users?includeInactive=true')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    const handler = e => {
      if (wrapperRef && !wrapperRef.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [wrapperRef]);

  useProjectFilter({
    projects,
    selectedFilterUsers,
    noneFlags,
    onUpdate,
    isShowingClosedProjects,
    isShowingMyProjects,
    setFilteredProjectsLength,
    setIsShowingMyProjects
  });

  const clearFilters = () => {
    setSelectedFilterUsers({ ...defaultSelectedFilterUsers });
    setNoneFlags({ ...defaultNoneFlags });
  };

  const _renderFilterDisplay = () => {
    const countType = key =>
      noneFlags[key] ? 1 : (selectedFilterUsers[key] || []).length;
    const filterTotal = projectUserAssociations.reduce(
      (sum, { baseKey }) => sum + countType(baseKey),
      0
    );
    if (isShowingMyProjects) {
      return 'Showing "My Projects"';
    } else {
      return `${filterTotal} filter${filterTotal === 1 ? '' : 's'} applied`;
    }
  };

  const handleSwitch = e => {
    setIsShowingMyProjects(e.target.checked);
    clearFilters();
  };

  const filterUsersByProjectRelation = (currentUsers, userIdKey) => {
    const includeSet = {};
    if (!Array.isArray(userIdKey)) userIdKey = [userIdKey];
    projects.forEach(project => {
      userIdKey.forEach(userIdKey => {
        if (project[userIdKey] && isShowingClosedProjects === project.closed)
          includeSet[project[userIdKey]] = true;
      });
    });
    currentUsers.forEach(id => (includeSet[id] = true));
    return users.filter(user => includeSet[user.id]);
  };

  return (
    <div
      style={{
        position: 'relative',
        marginRight: 24,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
      ref={setWrapperRef}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={() => setIsShowingClosedProjects(!isShowingClosedProjects)}
          variant="outlined"
          size="small"
          color="inherit"
          style={{
            marginRight: 16,
            color: isShowingClosedProjects ? colors.error : colors.secondary
          }}
        >
          Viewing {isShowingClosedProjects ? 'Closed' : 'Open'} Projects
        </Button>
      </div>

      <Input
        inputProps={{ style: { cursor: 'pointer' } }}
        fullWidth
        readOnly
        style={{ cursor: 'pointer', width: 300, color: colors.backgroundText }}
        value={_renderFilterDisplay()}
        onClick={() => setIsOpen(!isOpen)}
        endAdornment={
          <InputAdornment position="end">
            <DropDownIcon />
          </InputAdornment>
        }
      />

      <Paper
        style={{
          padding: 8,
          position: 'absolute',
          top: 40,
          right: 0,
          zIndex: 100,
          justifyContent: 'center',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
          width: ONE_COL_WIDTH * filterColumns.length
        }}
      >
        <div
          style={{
            display: isOpen ? 'flex' : 'none'
          }}
        >
          {filterColumns.map(column => (
            <ProjectFilterColumn
              key={column.title}
              title={column.title}
              pluralTitle={column.pluralTitle}
              users={filterUsersByProjectRelation(
                column.columnValues,
                column.userIdKey
              )}
              selectedItems={column.columnValues}
              setSelectedItems={column.setColumnValues}
              noneFlag={column.noneFlag}
              setNoneFlag={column.setNoneFlag}
            />
          ))}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            paddingBottom: 0
          }}
        >
          <div>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  label="Show My Projects"
                  labelPlacement="end"
                  control={
                    <Switch
                      color="primary"
                      checked={isShowingMyProjects}
                      onChange={handleSwitch}
                      value={isShowingMyProjects}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </div>

          <div>
            <Typography variant="caption">
              ({filteredProjectsLength} projects)
            </Typography>
          </div>

          <Link
            color="secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              clearFilters();
              setIsShowingMyProjects(false);
            }}
          >
            Remove all filters
          </Link>
        </div>
      </Paper>
    </div>
  );
}

export default ProjectFilterDropdown;
