import http from 'helpers/http.helper';
import { Toast } from 'components';

// Actions
const SET_AREAS = 'areas/SET_AREAS';

// Reducer
export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SET_AREAS:
      return action.areas;
    default:
      return state;
  }
}

export const setAreas = areas => ({ type: SET_AREAS, areas });

// Action Creators
export const getAreas = () => async dispatch => {
  const areas = await http()
    .get('/areas')
    .catch(err => Toast.show(err.message));

  dispatch({ type: SET_AREAS, areas });
};
