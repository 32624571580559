import React, { useState, useContext } from 'react';
import { Toast, CustomInput } from 'components';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';

const ContractValues = ({ prev, next }) => {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [contractValue, setContractValue] = useState(
    projectDetails.contractValue || ''
  );
  const [estimatedLaborHours, setEstimatedLaborHours] = useState(
    projectDetails.estimatedLaborHours || ''
  );
  const [estimatedCost, setEstimatedCost] = useState(
    projectDetails.estimatedCost || ''
  );
  const [estimatedLaborCost, setEstimatedLaborCost] = useState(
    projectDetails.estimatedLaborCost || ''
  );
  const [estimatedMaterialCost, setEstimatedMaterialCost] = useState(
    projectDetails.estimatedMaterialCost || ''
  );
  const [start, setStart] = useState(projectDetails.start || null);
  const [end, setEnd] = useState(projectDetails.end || null);
  const [retainagePercentage, setRetainagePercentage] = useState(
    projectDetails.retainagePercentage || 0
  );
  const [scheduleOnMaster, setScheduleOnMaster] = useState(
    !!projectDetails.scheduleOnMaster
  );

  const save = async e => {
    e.preventDefault();

    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        contractValue,
        estimatedCost,
        estimatedLaborCost,
        estimatedMaterialCost,
        estimatedLaborHours,
        start,
        end,
        retainagePercentage,
        scheduleOnMaster
      })
      .then(res => {
        setProjectDetails(res);
        next();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>Contract Values</DialogTitle>
      <DialogContent>
        <CustomInput
          required
          label="Contract Value"
          value={contractValue}
          onChange={setContractValue}
          type="currency"
        />
        <CustomInput
          required
          label="Estimated Cost"
          value={estimatedCost}
          onChange={setEstimatedCost}
          type="currency"
        />
        <CustomInput
          required
          label="Estimated Labor Cost"
          value={estimatedLaborCost}
          onChange={setEstimatedLaborCost}
          type="currency"
        />
        <CustomInput
          required
          label="Estimated Material Cost"
          value={estimatedMaterialCost}
          onChange={setEstimatedMaterialCost}
          type="currency"
        />
        <CustomInput
          required
          label="Estimated Labor Hours"
          value={estimatedLaborHours}
          onChange={setEstimatedLaborHours}
          type="number"
        />
        <CustomInput
          required
          label="Estimated Start Date"
          value={start}
          onChange={setStart}
          type="date"
        />
        <CustomInput
          required
          label="Estimated End Date"
          value={end}
          onChange={setEnd}
          type="date"
        />
        <CustomInput
          required
          label="Include on Master Schedule"
          value={scheduleOnMaster}
          onChange={setScheduleOnMaster}
          type="boolean"
        />
        <CustomInput
          required
          label="Retainage Percentage"
          value={retainagePercentage}
          onChange={setRetainagePercentage}
          type="percent"
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={prev}>
          Back
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Next
        </Button>
      </DialogActions>
    </form>
  );
};

export default ContractValues;
