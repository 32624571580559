import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  Typography,
  Button,
  Divider,
  IconButton
} from 'helpers/themeSafeMui.helper';
import CreatePlangridUser from '../modals/CreatePlangridUser.modal';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UpdatePlangridUser from '../modals/UpdatePlangridUser.modal';

const sortKey = 'name';

const PlangridUsers = () => {
  const [users, setUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    http()
      .get('/plangrid/users')
      .then(users => setUsers(users))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    setSortedUsers(
      [...users].sort((a, b) => {
        if (a[sortKey] < b[sortKey]) return -1;
        if (a[sortKey] > b[sortKey]) return 1;
        return 0;
      })
    );
  }, [users]);

  const createPlangridUser = ({ email, name, roleName }) => {
    return http()
      .post('/plangrid/users', { email, name, roleName })
      .then(res => setUsers(users => [res, ...users]))
      .catch(err => Toast.show(err.message));
  };

  const updatePlangridUser = ({ user, email, name, roleName }) => {
    return http()
      .put(`/plangrid/users/${user.id}`, { email, name, roleName })
      .then(res =>
        setUsers(users =>
          users.map(other => (other.id === res.id ? res : other))
        )
      )
      .catch(err => Toast.show(err.message));
  };

  const removePlangridUser = id => () => {
    return http()
      .delete(`/plangrid/users/${id}`)
      .then(res =>
        setUsers(users => users.filter(({ id: otherId }) => otherId !== id))
      )
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      <CreatePlangridUser
        open={open}
        onClose={() => setOpen(false)}
        onSave={createPlangridUser}
      />
      <UpdatePlangridUser
        open={!!editingUser}
        user={editingUser}
        onClose={() => setEditingUser(null)}
        onSave={updatePlangridUser}
      />
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Typography style={{ lineHeight: 1.6 }} component="h1" variant="h5">
          PlanGrid Users
        </Typography>
        <Button
          style={{ marginLeft: 16 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Add
        </Button>
      </div>
      <div>
        {sortedUsers.map(user => (
          <React.Fragment key={user.id}>
            <Divider />
            <div
              style={{
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                {user.name}
                <br />
                {user.email}
                <Typography
                  style={{ lineHeight: 1.9 }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {user.roleName}
                </Typography>
              </div>
              <div>
                <IconButton size="small" onClick={() => setEditingUser(user)}>
                  <EditIcon />
                </IconButton>

                <IconButton
                  style={{ marginLeft: 10 }}
                  size="small"
                  onClick={removePlangridUser(user.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </React.Fragment>
        ))}
        <Divider />
      </div>
    </div>
  );
};

export default PlangridUsers;
