import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export default connect(state => ({
  user: state.user
}))(props => {
  window.scrollTo(0, 0);

  // Onboarding redirect.
  if (props.user) {
    if (
      !props.user.onBoardingCompleted &&
      !props.location.pathname.includes('/onboarding')
    ) {
      return <Redirect to="/onboarding" />;
    } else if (
      props.user.onBoardingCompleted &&
      props.location.pathname.includes('/onboarding')
    ) {
      return <Redirect to="/home" />;
    }
  }

  // No redirect needed.
  if ((props.secure && props.user) || (!props.secure && !props.user)) {
    return <Route {...props} />;
  }

  // Not signed in. Redirect to login.
  if (!props.user) {
    // Store original path.
    window.sessionStorage.setItem('original_path', window.location.pathname);
    return <Redirect to="/" />;
  }

  // Signed in. Redirect to home or originally requested route.
  const path = window.sessionStorage.getItem('original_path') || '/home';
  window.sessionStorage.removeItem('original_path');
  return <Redirect to={path} />;
});
