import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'helpers/themeSafeMui.helper';
import SearchIcon from '@material-ui/icons/Search';

const UserSearch = ({
  onChange,
  title = 'User',
  initialUser = null,
  filter = null,
  path = '/users',
  nameKey = 'name',
  ...rest
}) => {
  const [users, setUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(initialUser);
  if (!filter) filter = u => u.employmentStatus !== 'Inactive';

  useEffect(() => {
    let unmounted = false;
    http()
      .get(path)
      .then(res => {
        if (unmounted) return;
        let copy = [...res];
        copy.sort((a, b) => (a[nameKey] < b[nameKey] ? -1 : 1));
        setUsers(copy);
      })
      .catch(err => Toast.show(err.message));
    return () => (unmounted = true);
  }, [path, nameKey]);

  return (
    <Autocomplete
      options={users.filter(filter)}
      value={activeUser}
      onChange={(e, user) => {
        setActiveUser(user);
        onChange(user);
      }}
      getOptionLabel={user => user[nameKey]}
      renderInput={params => (
        <TextField
          {...params}
          label={
            <div style={{ display: 'flex' }}>
              {title}
              <SearchIcon
                style={{
                  marginLeft: 5,
                  transform: 'translateY(-5px)'
                }}
              />
            </div>
          }
          fullWidth
          variant="outlined"
          margin="dense"
        />
      )}
      {...rest}
    />
  );
};

export default UserSearch;
