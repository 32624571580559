import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { setAdminSettings } from 'ducks/adminSettings.duck';
import http from 'helpers/http.helper';
import {
  TextField,
  Typography,
  Grid,
  Button,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import { Toast, AuthPopup } from 'components';
import Connected from '@material-ui/icons/CloudDone';
import Disconnected from '@material-ui/icons/CloudOff';

function GoogleDriveTab(props) {
  const { adminSettings } = props;
  const [settings, setSettings] = useState({
    googleDriveProjectsFolderId: '',
    googleDriveCompaniesFolderId: '',
    googleDriveProjectTemplateFolderId: '',
    googleDriveUsersFolderId: '',
    googleDriveUserFolderTemplateId: '',
    googleDriveEsignFolderId: '',
    googleDriveTemplateFolderId: ''
  });

  const [connectionStatuses, setConnectionStatuses] = useState({
    googleDriveProjectsFolderId: false,
    googleDriveUserFolderTemplateId: false,
    googleDriveProjectTemplateFolderId: false,
    googleDriveCompaniesFolderId: false,
    googleDriveUsersFolderId: false,
    googleDriveEsignFolderId: false,
    googleDriveTemplatesFolderId: false
  });

  const getConnectionStatus = useCallback(
    setting => {
      if (!adminSettings[setting]) return;
      return http()
        .get('/google/drive/drive-connection-status', {
          params: {
            fileId: adminSettings[setting]
          }
        })
        .then(res => {
          setConnectionStatuses(statuses => ({
            ...statuses,
            [setting]: res
          }));
        });
    },
    [adminSettings]
  );

  // SETS ALL OF THE CONNECTION STATUSES FOR THE DRIVE
  useEffect(() => {
    getConnectionStatus('googleDriveProjectsFolderId');
    getConnectionStatus('googleDriveProjectTemplateFolderId');
    getConnectionStatus('googleDriveUsersFolderId');
    getConnectionStatus('googleDriveUserFolderTemplateId');
    getConnectionStatus('googleDriveEsignFolderId');
    getConnectionStatus('googleDriveTemplateFolderId');
    getConnectionStatus('googleDriveCompaniesFolderId');
  }, [getConnectionStatus]);

  // SETS THE SETTINGS DATA FIELDS WHEN SETTING OBJECT CHANGES
  useEffect(() => {
    setSettings(adminSettings);
  }, [adminSettings]);

  const setRefreshToken = code => {
    http()
      .post('/google/oauth/access-token', { code: code })
      .then(({ refresh_token }) =>
        http().put('/settings', {
          googleDriveRefreshToken: refresh_token
        })
      )
      .then(res => {
        setAdminSettings(res);
        Toast.show('Updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  const save = () => {
    if (!window.confirm('Are you sure?')) return;
    http()
      .put('/settings', {
        googleDriveProjectsFolderId: settings.googleDriveProjectsFolderId,
        googleDriveProjectTemplateFolderId:
          settings.googleDriveProjectTemplateFolderId,
        googleDriveUsersFolderId: settings.googleDriveUsersFolderId,
        googleDriveUserFolderTemplateId:
          settings.googleDriveUserFolderTemplateId,
        googleDriveEsignFolderId: settings.googleDriveEsignFolderId,
        googleDriveTemplateFolderId: settings.googleDriveTemplateFolderId,
        googleDriveCompaniesFolderId: settings.googleDriveCompaniesFolderId
      })
      .then(res => {
        setAdminSettings(res);
        Toast.show('Google Drive Settings were updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  const handleChange = e => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const _renderCustomField = ({ name, label }) => (
    <TextField
      key={name}
      margin="normal"
      label={label}
      fullWidth
      name={name}
      value={settings[name]}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {connectionStatuses[name] ? <Connected /> : <Disconnected />}
          </InputAdornment>
        )
      }}
    />
  );

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography component="h1" variant="h5" gutterBottom>
            Google Drive
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            color="primary"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: 8 }}
            href="https://drive.google.com/drive/my-drive"
          >
            Open Drive
          </Button>
          <AuthPopup
            onCode={setRefreshToken}
            forceConsent
            scopes={[
              'https://www.googleapis.com/auth/drive',
              'https://www.googleapis.com/auth/userinfo.profile',
              'https://www.googleapis.com/auth/gmail.send'
            ]}
          >
            <Button variant="outlined" color="primary">
              Connect Google Drive
            </Button>
          </AuthPopup>
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'Users Folder ID',
            name: 'googleDriveUsersFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'eSign Folder ID',
            name: 'googleDriveEsignFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'Projects Folder ID',
            name: 'googleDriveProjectsFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'Companies Folder ID',
            name: 'googleDriveCompaniesFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'Projects Template Folder ID',
            name: 'googleDriveProjectTemplateFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'Templates Folder ID',
            name: 'googleDriveTemplateFolderId'
          })}
        </Grid>
        <Grid item xs={6}>
          {_renderCustomField({
            label: 'User Templates Folder ID',
            name: 'googleDriveUserFolderTemplateId'
          })}
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button onClick={save} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default connect(state => ({ adminSettings: state.adminSettings }), {
  setAdminSettings
})(GoogleDriveTab);
