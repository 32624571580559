import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ConfirmDelete, NewTabLink, NoItemsResult, Toast } from 'components';
import ld from 'fast-levenshtein';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function MergePeopleModal({ open, onClose, onMerge, personId }) {
  const [allPeople, setAllPeople] = useState([]);
  const [similarPeople, setSimilarPeople] = useState([]);
  const [selectedForMerge, setSelectedForMerge] = useState([]);

  useEffect(() => {
    let stale = false;
    if (open) {
      http()
        .get('/people')
        .then(people => {
          if (stale) return;
          setAllPeople(people);
        })
        .catch(Toast.showErr);
    }
    setAllPeople([]);
    setSimilarPeople([]);
    setSelectedForMerge([]);

    return () => {
      stale = true;
    };
  }, [open, personId]);

  useEffect(() => {
    if (!personId) return;
    let viewingPerson = allPeople.find(p => p.id === personId);

    if (!viewingPerson) {
      setSimilarPeople([]);
    }
    const matchAdded = allPeople.map(person => {
      const lcNames = [
        viewingPerson.name.toLowerCase(),
        person.name.toLowerCase()
      ];
      return {
        nameMatch: ld.get(...lcNames) < 3,
        companyMatch: person.companyId === viewingPerson.companyId,
        isViewingPerson: person.id === personId,
        person
      };
    });

    const filtered = matchAdded.filter(m => m.nameMatch && !m.isViewingPerson);

    setSimilarPeople(filtered);
    setSelectedForMerge(filtered.map(() => false));
  }, [allPeople, personId]);

  const setSelectedForMergeAtIdx = idx => val =>
    setSelectedForMerge(old => {
      if (idx >= old.length) return old;
      const copy = [...old];
      copy[idx] = val;
      return copy;
    });

  const merge = () => {
    const mergePeopleIds = similarPeople
      .filter((p, idx) => selectedForMerge[idx])
      .map(spData => spData.person.id);
    http()
      .put('/people/merge', {
        mergePeopleIds,
        ontoPersonId: personId
      })
      .then(onClose)
      .then(onMerge)
      .catch(Toast.showErr);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Merge Contacts</DialogTitle>
      <Divider />
      <DialogContent>
        {!similarPeople.length && (
          <NoItemsResult message="No similar names found." />
        )}
        {similarPeople.map((spData, idx) => (
          <SimilarPersonChecklistItem
            key={spData.person.id}
            spData={spData}
            setChecked={setSelectedForMergeAtIdx(idx)}
            checked={!!selectedForMerge[idx]}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <ConfirmDelete onConfirm={merge}>
          <Button
            disabled={!selectedForMerge.length}
            variant="contained"
            color="primary"
          >
            Merge
          </Button>
        </ConfirmDelete>
      </DialogActions>
    </Dialog>
  );
}

function SimilarPersonChecklistItem({ spData, checked, setChecked }) {
  return (
    <ListItem button onClick={() => setChecked(!checked)}>
      <ListItemIcon>
        {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </ListItemIcon>
      <ListItemText
        primary={
          <NewTabLink to={`/people/${spData.person.id}`}>
            {spData.person.name}
          </NewTabLink>
        }
        secondary={spData.person.companyName}
      />
    </ListItem>
  );
}
