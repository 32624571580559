import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from 'helpers/themeSafeMui.helper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Rollup({
  title,
  children,
  localStorageKey = '',
  innerStyles = {},
  dense = true,
  ...rest
}) {
  const [open, setOpen] = useStateOrStorage(localStorageKey, false, joi =>
    joi.boolean()
  );

  return (
    <Accordion {...rest} expanded={open} onChange={() => setOpen(old => !old)}>
      <AccordionSummary
        style={dense ? { height: 40, maxHeight: 40 } : {}}
        expandIcon={<ExpandMoreIcon />}
      >
        {title}
      </AccordionSummary>
      <Divider />
      <AccordionDetails style={{ display: 'block', ...innerStyles }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

function useStateOrStorage(key, value, schema) {
  key = key ? key : '';
  const lsResult = useLocalStorage(key, value, schema);
  const stResult = useState(value);
  return key === '' ? stResult : lsResult;
}
