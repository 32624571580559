import React, { useEffect, useState } from 'react';
import { DashboardWrapper, NoItemsResult, Toast } from 'components';
import { Button, Paper, Typography } from 'helpers/themeSafeMui.helper';
import { Link, useRouteMatch } from 'react-router-dom';
import http from 'helpers/http.helper';
import QuizQuestion from '../components/QuizQuestion.component';
import { useSelector } from 'react-redux';
import { useQueryParams } from 'hooks/useQueryParams.hook';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';

export default function ReviewQuiz() {
  const [attempt, setAttempt] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const user = useSelector(state => state.user);
  const [queryParams] = useQueryParams();
  const match = useRouteMatch();

  useEffect(() => {
    const userId = queryParams.userId || user.id;
    if (match.params.quizId && userId) {
      Promise.all([
        http().get(
          `/training-quizzes/${match.params.quizId}/review-attempt?userId=${userId}`
        ),
        http().get(`/training-quizzes/${match.params.quizId}/attempt`)
      ])
        .then(([att, q]) => {
          setAttempt(att);
          setQuiz(q);
        })
        .catch(Toast.showErr);
    }
  }, [match.params.quizId, queryParams.userId, user]);

  if (!quiz || !attempt) return <DashboardWrapper />;

  const returnUrl =
    !!attempt &&
    !!quiz &&
    !!attempt.userId &&
    !!quiz.trainingModuleId &&
    `/users/${attempt.userId}/trainings?moduleId=${quiz.trainingModuleId}`;

  return (
    <DashboardWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Typography variant="h4" gutterBottom>
          {quiz.moduleName} - Quiz
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <Typography variant="h6">
            Score: {(attempt.score * 100).toFixed(1)}%
          </Typography>
          {returnUrl && (
            <Link to={returnUrl}>
              <Button style={{ marginLeft: 16 }} variant="contained">
                Back to trainings <ArrowIcon fontSize="inherit" />
              </Button>
            </Link>
          )}
        </div>
      </div>

      {!quiz.questions.length ? (
        <Paper style={{ padding: 15 }}>
          <NoItemsResult type="questions" />
        </Paper>
      ) : (
        quiz.questions.map((question, idx) => (
          <React.Fragment key={question.id}>
            {idx !== 0 && <div style={{ paddingTop: 15 }} />}
            <QuizQuestion
              correct={tryValue(() => attempt.answers[idx].correct)}
              question={question}
              lockedOption={tryValue(() => attempt.answers[idx].optionId) || -1}
            />
          </React.Fragment>
        ))
      )}
    </DashboardWrapper>
  );
}

function tryValue(fn) {
  try {
    return fn();
  } catch (err) {
    return null;
  }
}
