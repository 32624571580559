import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAdminSettings } from 'ducks/adminSettings.duck';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import { OneDriveFolderViewer, Toast } from 'components';
import http from 'helpers/http.helper';

const folders = Object.freeze([
  ['googleDriveProjectsFolderId', 'Projects Folder Id'],
  ['googleDriveCompaniesFolderId', 'Companies Folder Id'],
  ['googleDriveProjectTemplateFolderId', 'Project Template Folder Id'],
  ['googleDriveUsersFolderId', 'Users Folder Id'],
  ['googleDriveEsignFolderId', 'Esign Folder Id'],
  ['googleDriveTemplateFolderId', 'Template Folder Id']
]);

function OneDriveTab(props) {
  const adminSettings = useSelector(state => state.adminSettings);
  const dispatch = useDispatch();
  const [modalKey, setModalKey] = useState(null);
  const [settings, setSettings] = useState(() =>
    folders.reduce((obj, [key]) => (obj[key] = '') || obj, {})
  );

  useEffect(() => {
    setSettings(adminSettings);
  }, [adminSettings]);

  const save = () => {
    if (!window.confirm('Are you sure?')) return;
    http()
      .put('/settings', {
        googleDriveProjectsFolderId: settings.googleDriveProjectsFolderId,
        googleDriveProjectTemplateFolderId:
          settings.googleDriveProjectTemplateFolderId,
        googleDriveUsersFolderId: settings.googleDriveUsersFolderId,
        googleDriveEsignFolderId: settings.googleDriveEsignFolderId,
        googleDriveTemplateFolderId: settings.googleDriveTemplateFolderId,
        googleDriveCompaniesFolderId: settings.googleDriveCompaniesFolderId
      })
      .then(res => {
        dispatch(setAdminSettings(res));
        Toast.show('One Drive Settings were updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={!!modalKey}
        onClose={() => setModalKey(null)}
      >
        <DialogContent>
          <OneDriveFolderViewer
            onSelect={item => {
              if (!item.folder) return Toast.show('Item must be a folder');
              setSettings(old => ({ ...old, [modalKey]: item.id }));
              setModalKey(null);
            }}
          />
        </DialogContent>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" gutterBottom>
            One Drive
          </Typography>
        </Grid>
        {folders.map(folder => (
          <Grid item xs={6} key={folder[0]}>
            <Typography>{folder[1]}</Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ fontFamily: 'monospace' }}
            >
              {settings[folder[0]] ? settings[folder[0]] : 'Not set'}
            </Typography>
            <Button
              style={{ display: 'block', marginTop: 5 }}
              variant="contained"
              color="default"
              size="small"
              onClick={() => setModalKey(folder[0])}
            >
              Pick Folder
            </Button>
          </Grid>
        ))}

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button onClick={save} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default OneDriveTab;
