import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Companies from './routes/Companies.route';
import Company from './routes/Company.route';

export default class UsersRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/companies" exact component={Companies} />
        <Route path="/companies/:id" component={Company} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
