import React, { useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';

function EditEmployeeSkill({ skill, onClose, updateSkill, deleteSkill, open }) {
  const [name, setName] = useState(skill.name);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const editEmployeeSkill = e => {
    e.preventDefault();
    updateSkill(skill.id, { name });
    onClose();
  };

  const onDelete = () => {
    deleteSkill(skill.id);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={editEmployeeSkill}>
        <DialogTitle>Edit Employee Skill</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Skill Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {showDeleteConfirm ? (
            <Typography variant="caption">
              <Button color="primary" size="small" onClick={onDelete}>
                Delete
              </Button>
              Are you sure?
            </Typography>
          ) : (
            <IconButton
              style={{ marginRight: 30 }}
              onClick={() => setShowDeleteConfirm(true)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditEmployeeSkill;
