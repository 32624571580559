import { useCallback, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

export const useQueryParams = (initialParams: {}) => {
  const { pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const [initialParamsState] = useState(initialParams);
  const [queryParams, setQueryParams] = useState(() => {
    const updatedQueryParams = {
      ...initialParamsState,
      ...qs.parse(search)
    };
    return updatedQueryParams;
  });

  useEffect(() => {
    const updatedQueryParams = {
      ...initialParamsState,
      ...qs.parse(search)
    };
    replace(qs.stringifyUrl({ url: pathname, query: updatedQueryParams }));
  }, [replace, pathname, search, initialParamsState]);

  const updateQueryParams = useCallback(
    updatedQueryParams => {
      setQueryParams(updatedQueryParams);
      push(qs.stringifyUrl({ url: pathname, query: updatedQueryParams }));
    },
    [pathname, push]
  );

  useEffect(() => {
    setQueryParams(qs.parse(search));
  }, [search]);

  return [queryParams, updateQueryParams];
};
