import React, { useContext } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from 'helpers/themeSafeMui.helper';
import LockIcon from '@material-ui/icons/Lock';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { CheckBox } from '@material-ui/icons';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import { Button } from '@material-ui/core';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import {
  ProjectContext,
  ProjectChecklistContext
} from 'routes/Projects/context/Project.context';
import moment from 'moment';
import useItemData, { markLoop } from './useItemData.hook';

export default function ChecklistModalItem({ item, required, locked = false }) {
  const currentUser = useCurrentUser();
  const { project } = useContext(ProjectContext);
  const { setProjectChecklist } = useContext(ProjectChecklistContext);
  const getItemData = useItemData(item);

  const canMark = currentUser.hasPermission('checklist_tier_2');

  locked = locked || !canMark;

  const mark = data => async e => {
    if (locked) return;
    try {
      e.stopPropagation();
      if (locked) return;
      const updated = await http().put(
        `/projects/${project.id}/checklist-items/${item.id}`,
        data
      );

      setProjectChecklist(old => {
        const foundIdx = old.findIndex(other => other.id === updated.id);
        if (foundIdx === -1) return old;
        const copy = [...old];
        copy[foundIdx] = updated;
        return copy;
      });
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const toggleOptionalMark = () => {
    if (!item.optional)
      return mark({ completed: false, optional: true, color: 'gray' });
    return mark(markLoop[0]);
  };

  return (
    <ListItem
      key={item.id}
      button={!locked}
      onClick={mark(getItemData('nextMarkData'))}
      style={{ cursor: locked ? 'not-allowed' : undefined }}
    >
      <ListItemIcon style={{ color: getItemData('color') }}>
        {locked ? <LockIcon /> : getItemData('icon')}
      </ListItemIcon>
      <ListItemText
        primary={item.title}
        secondaryTypographyProps={{ component: 'div' }}
        secondary={
          <>
            <Typography
              color="textSecondary"
              style={{ color: getItemData('color') }}
              variant="caption"
              component="p"
            >
              {getItemData('statusName')}
              {required ? ' (required)' : ''}
            </Typography>
            {!!item.updatedByName && (
              <>
                <Typography
                  component="p"
                  color="textSecondary"
                  variant="caption"
                >
                  Updated: {bold(item.updatedByName)}
                </Typography>
                <Typography
                  component="p"
                  color="textSecondary"
                  variant="caption"
                >
                  {bold(moment(item.updatedOn).format('LLL'))}
                </Typography>
              </>
            )}
            <Typography color="textSecondary" variant="body2">
              {item.notes}
            </Typography>
          </>
        }
      />
      {item.allowOptional && !locked && (
        <Button
          onClick={toggleOptionalMark()}
          variant="contained"
          size="small"
          style={{ flexShrink: 0 }}
        >
          <Typography variant="caption" color="textSecondary">
            optional
          </Typography>
          {item.optional ? (
            <CheckBox
              style={{ marginLeft: 10 }}
              fontSize="inherit"
              color="action"
            />
          ) : (
            <CheckBoxOutline
              style={{ marginLeft: 10 }}
              fontSize="inherit"
              color="action"
            />
          )}
        </Button>
      )}
    </ListItem>
  );
}

function bold(string) {
  return (
    <Typography component="span" variant="inherit" style={{ fontWeight: 500 }}>
      {string}
    </Typography>
  );
}
