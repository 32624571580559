import diffDays from './diffDays.helper';
// eslint-disable-next-line
import { ScheduleData } from './getScheduleData.helper';
import moment from 'moment';
import safeParse from 'helpers/safeParse.helper';

/**
 * @param { Object } data
 * @param { ScheduleData[] } data.scheduleData
 * @param { Date } data.startDay
 * @param { number } data.days
 */

const calculateTechDays = ({ scheduleData, startDay, days }) => {
  const diffChart = Array(days).fill(null);

  scheduleData.forEach(({ type, project, tasks }) => {
    if (type === 'project') {
      addProjectToDiffChart(diffChart, project, startDay, days);
    }
    if (type === 'tasks') {
      addTasksToDiffChart(diffChart, tasks, project, startDay, days);
    }
  });

  let runningTotal = 0;
  return diffChart.map(change => {
    if (change === null) return runningTotal;
    runningTotal += change;
    return runningTotal;
  });
};

const addProjectToDiffChart = (chart, project, startDay, days) => {
  if (!project.start) return;
  if (!project.scheduleFloat && !project.end) return;
  const techCount = project.estimatedCrew || 0;
  const daysPerWeek = safeParse(project.scheduleDaysPerWeek) || 5;

  let startIdx = diffDays(startDay, project.start);
  let span = diffDays(project.start, project.scheduleFloat) + 1;
  if (isNaN(span)) {
    span = diffDays(project.start, project.end) + 1;
    if (isNaN(span)) span = 1;
  }
  if (startIdx < 0) {
    span += startIdx;
    startIdx = 0;
  }
  if (startIdx + span >= days) {
    span = days - startIdx;
  }
  if (span < 0) return;
  if (project.closed) return;

  addItemToChart({
    chart,
    startDay,
    startIdx,
    dayCount: span,
    techCount,
    weekendStart: daysPerWeek === 5 ? 6 : 5,
    weekStart: 1
  });
};

const addTasksToDiffChart = (chart, tasks, project, startDay, days) => {
  tasks.forEach(task => {
    if (!task.startDate || !task.days) return;
    const techCount = task.techCount || 0;
    const daysPerWeek = safeParse(project.scheduleDaysPerWeek) || 5;

    let startIdx = diffDays(startDay, task.startDate);
    let span = diffDays(task.startDate, task.endDate) + 1;
    if (startIdx < 0) {
      span += startIdx;
      startIdx = 0;
    }
    if (startIdx + span >= days) {
      span = days - startIdx;
    }
    if (span < 0) return;

    addItemToChart({
      chart,
      startDay,
      startIdx,
      dayCount: span,
      techCount,
      weekendStart: daysPerWeek === 5 ? 6 : 5,
      weekStart: 1
    });
  });
};

const addItemToChart = ({
  chart,
  startDay,
  startIdx,
  dayCount,
  techCount,
  weekendStart,
  weekStart
}) => {
  let day = moment(startDay)
    .add(startIdx, 'days')
    .day();
  let isWeekend;
  if (day >= weekStart && day < weekendStart) {
    if (typeof chart[startIdx] === 'number') {
      chart[startIdx] += techCount;
    } else {
      chart[startIdx] = techCount;
    }
    isWeekend = false;
  } else {
    isWeekend = true;
  }

  let i = startIdx;
  for (; i < dayCount + startIdx; i++) {
    if (day === weekStart && i !== startIdx) {
      isWeekend = false;
      if (typeof chart[i] === 'number') {
        chart[i] += techCount;
      } else {
        chart[i] = techCount;
      }
    }

    if (day === weekendStart && isWeekend !== true) {
      isWeekend = true;
      if (typeof chart[i] === 'number') {
        chart[i] += -techCount;
      } else {
        chart[i] = -techCount;
      }
    }

    day++;
    day %= 7;
  }

  if (!isWeekend)
    if (typeof chart[i] === 'number') {
      chart[i] += -techCount;
    } else {
      chart[i] = -techCount;
    }
};

export default calculateTechDays;
