import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  DialogTitle,
  TextField,
  FormControl
} from 'helpers/themeSafeMui.helper';
import states from 'helpers/states.helper';
import { Autocomplete } from '@material-ui/lab';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

export default function AddressModal(props) {
  const { open, onClose, onCreate } = props;

  const [streetLine1, setStreetLine1] = useState('');
  const [streetLine2, setStreetLine2] = useState('');
  const [addressState, setAddressState] = useState(() =>
    isUsingOneDrive() ? 'TX' : 'ID'
  );
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');

  const createAddress = () => {
    var addressString = `${streetLine1}, `;
    if (streetLine2) {
      addressString += `${streetLine2}, `;
    }
    addressString += `${city}, ${addressState} ${zipCode}`;

    onCreate(addressString);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Add Address</DialogTitle>
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Street Line 1"
            value={streetLine1}
            onChange={e => setStreetLine1(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street Line 2"
            value={streetLine2}
            onChange={e => setStreetLine2(e.target.value)}
          />
        </Grid>

        <Grid item xs={8}>
          <TextField
            fullWidth
            label="City"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl required fullWidth style={{ marginTop: 1 }}>
            <Autocomplete
              options={states}
              autoHighlight
              autoSelect
              selectOnFocus
              value={addressState}
              onChange={(e, value) => {
                setAddressState(value);
              }}
              renderInput={params => (
                <TextField {...params} fullWidth label="State" required />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={5}>
          <TextField
            fullWidth
            required
            label="Zip Code"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={onClose} size="small">
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={createAddress}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
