import React, { useState, useContext, useEffect } from 'react';
import {
  Paper,
  Typography,
  MuiThemeProvider,
  Button
} from 'helpers/themeSafeMui.helper';
import {
  makeMuiThemeFromColors,
  useColors,
  SetThemeContext
} from 'helpers/theme.helper';
import ColorEdit from './ColorEdit.component';

const ThemeItemWrapper = ({
  theme: {
    colors: { primary, secondary, background, paper, darkMode },
    themeTitle
  } = { colors: {} },
  editable,
  isCurrentTheme = false,
  editTheme = () => {}
}) => {
  const siteThemeColors = useColors();
  const defaultState = isCurrentTheme
    ? {
        themeTitle: 'Current Theme',
        colors: {
          primary: siteThemeColors.primary,
          secondary: siteThemeColors.secondary,
          background: siteThemeColors.background,
          paper: siteThemeColors.paper,
          darkMode: siteThemeColors.type === 'dark'
        }
      }
    : {
        colors: { primary, secondary, background, paper, darkMode },
        themeTitle
      };
  const [stateTheme, _setStateTheme] = useState(defaultState);
  const setSiteTheme = useContext(SetThemeContext);

  useEffect(() => {
    if (isCurrentTheme) {
      _setStateTheme({
        themeTitle: 'Current Theme',
        colors: {
          primary: siteThemeColors.primary,
          secondary: siteThemeColors.secondary,
          background: siteThemeColors.background,
          paper: siteThemeColors.paper,
          darkMode: siteThemeColors.type === 'dark'
        }
      });
    }
  }, [
    isCurrentTheme,
    siteThemeColors.primary,
    siteThemeColors.secondary,
    siteThemeColors.background,
    siteThemeColors.paper,
    siteThemeColors.type
  ]);

  const setStateTheme = (key, value) => {
    _setStateTheme(currentStateTheme => {
      const copy = JSON.parse(JSON.stringify(currentStateTheme));
      if (key === 'themeTitle') {
        copy[key] = value;
      } else {
        copy.colors[key] = value;
      }
      return copy;
    });
  };

  const toggleDarkMode = () => {
    setStateTheme('darkMode', !stateTheme.colors.darkMode);
    if (stateTheme.colors.paper) setStateTheme('paper', null);
    if (isCurrentTheme)
      setSiteTheme({
        ...stateTheme.colors,
        paper: null,
        darkMode: !stateTheme.colors.darkMode
      });
  };

  const generatedTheme = makeMuiThemeFromColors(stateTheme.colors);

  return (
    <MuiThemeProvider theme={generatedTheme}>
      <ThemeItem
        providedThemeColors={stateTheme.colors}
        editable={editable}
        themeTitle={stateTheme.themeTitle}
        setItemTheme={setStateTheme}
        save={() => {
          if (isCurrentTheme) {
            setSiteTheme(stateTheme.colors);
          } else {
            editTheme(stateTheme);
          }
        }}
        cancel={() => _setStateTheme(defaultState)}
        isCurrentTheme={isCurrentTheme}
        toggleDarkMode={toggleDarkMode}
      />
    </MuiThemeProvider>
  );
};

const ThemeItem = ({
  editable,
  themeTitle,
  providedThemeColors,
  setItemTheme,
  save,
  cancel,
  isCurrentTheme,
  toggleDarkMode
}) => {
  const generatedThemeColors = useColors();
  const setSiteTheme = useContext(SetThemeContext);
  const [editingKey, setEditingKey] = useState(null);

  const setEditing = key => () => editable && setEditingKey(key);
  const onUpdate = value => {
    if (editingKey) setItemTheme(editingKey, value);
  };

  return (
    <Paper
      style={{
        backgroundColor: generatedThemeColors.background,
        padding: 10,
        marginBottom: 20
      }}
    >
      <Paper
        style={{
          padding: 10,
          marginBottom: 10,
          background: generatedThemeColors.primary,
          color: generatedThemeColors.primaryContrastText
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant={'h4'}>{themeTitle}</Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {editingKey ? (
              <>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    save();
                    setEditingKey(null);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    cancel();
                    setEditingKey(null);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={toggleDarkMode}
                  style={{ marginRight: 10 }}
                >
                  Set {providedThemeColors.darkMode ? 'Light' : 'Dark'} Mode
                </Button>
                {editable && (
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={setEditing('primary')}
                    style={{ marginRight: 10 }}
                  >
                    Edit
                  </Button>
                )}
                {!isCurrentTheme && (
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => setSiteTheme(providedThemeColors)}
                  >
                    Use Theme
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Paper>

      <Paper style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <ColorBlock
            title="background"
            backgroundColor={generatedThemeColors.background}
            textColor={generatedThemeColors.backgroundText}
            onClick={setEditing('background')}
          />
          <ColorBlock
            title="primary"
            backgroundColor={generatedThemeColors.primary}
            textColor={generatedThemeColors.primaryContrastText}
            onClick={setEditing('primary')}
          />
          <ColorBlock
            title="secondary"
            backgroundColor={generatedThemeColors.secondary}
            textColor={generatedThemeColors.secondaryContrastText}
            onClick={setEditing('secondary')}
          />
          <ColorBlock
            title="paper"
            backgroundColor={generatedThemeColors.paper}
            textColor={generatedThemeColors.paperText}
            onClick={setEditing('paper')}
            outlined={true}
          />
          <ColorBlock
            title={generatedThemeColors.type}
            backgroundColor={generatedThemeColors.background}
            textColor={generatedThemeColors.backgroundText}
            onClick={() => {
              toggleDarkMode();
              setEditing('paper')();
            }}
            subtitleText="mode"
          />
        </div>
      </Paper>

      {editingKey && (
        <ColorEdit
          color={generatedThemeColors[editingKey]}
          onUpdate={onUpdate}
          title={editingKey}
        />
      )}
    </Paper>
  );
};

const ColorBlock = ({
  title,
  backgroundColor,
  textColor,
  subtitleText = 'color',
  outlined = false,
  onClick = () => {}
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <Paper
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      style={{
        backgroundColor,
        minWidth: 200,
        minHeight: 200,
        margin: 10,
        padding: 10,
        cursor: 'pointer'
      }}
      elevation={mouseOver ? 5 : 0}
      variant={outlined && !mouseOver ? 'outlined' : 'elevation'}
      onClick={onClick}
    >
      <Typography variant="h6" style={{ color: textColor }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" style={{ color: textColor }}>
        {subtitleText}
      </Typography>
    </Paper>
  );
};

export default ThemeItemWrapper;
