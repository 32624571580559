import React, { useState, useCallback, useEffect } from 'react';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField
} from 'helpers/themeSafeMui.helper';
import { Toast, SearchDropdown } from 'components';
import http from 'helpers/http.helper';
import { DatePicker } from '@material-ui/pickers';

function AddWorkOrderLabors(props) {
  const { onClose, workOrderId, projectId, refresh } = props;
  const [users, setUsers] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [hoursWorked, setHoursWorked] = useState('');
  const [dateWorked, setDateWorked] = useState(null);

  const getUsers = useCallback(() => {
    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const save = e => {
    e.preventDefault();

    http()
      .post(`/projects/${projectId}/work-orders/${workOrderId}/labors`, {
        hoursWorked: hoursWorked,
        dateWorked: dateWorked,
        userId: assignedUser.id
      })
      .then(() => {
        refresh();
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle>Add Labor to Work Order</DialogTitle>
      <DialogContent>
        <SearchDropdown
          label="Employee"
          itemToString={item => item.name}
          items={users}
          onChange={user => setAssignedUser(user)}
          value={assignedUser}
          required
          fullWidth
          margin="normal"
        />

        <TextField
          fullWidth
          label="Hours Worked"
          value={hoursWorked}
          required
          onChange={e => setHoursWorked(e.target.value)}
          inputProps={{ step: '0.01' }}
          type="number"
          margin="normal"
        />

        <DatePicker
          format="MM/DD/YYYY"
          autoOk
          name="dateWorked"
          label="Date Worked"
          required
          fullWidth
          margin="dense"
          value={dateWorked}
          onChange={dateWorked => setDateWorked(dateWorked)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          close
        </Button>
        <Button variant="contained" color="secondary" type="submit">
          save
        </Button>
      </DialogActions>
    </form>
  );
}

export default AddWorkOrderLabors;
