import React from 'react';
import { Toast } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';

class InviteUser extends React.Component {
  initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    areaId: '',
    compensationType: 'wage',
    wage: '',
    salary: '',
    startDate: null,
    employmentStatus: '',
    permissionLevel: '',
    loading: false
  };

  state = this.initialState;

  handleChange = e => {
    if (e.target.name === 'compensationType') {
      this.setState({ wage: '', salary: '' });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  invite = e => {
    e.preventDefault();
    if (this.state.loading) return;

    if (!this.state.permissionLevel) {
      return Toast.show('Permission Level is required.');
    }

    this.setState({ loading: true });

    http()
      .post('/users', {
        firstName: this.state.firstName || undefined,
        lastName: this.state.lastName,
        legalFirstName: this.state.legalFirstName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        areaId: this.state.areaId,
        employmentStatus: this.state.employmentStatus,
        permissionLevelId: this.state.permissionLevel.id,
        wage: this.state.wage || undefined,
        salary: this.state.salary || undefined,
        startDate: this.state.startDate
      })
      .then(() => {
        this.setState(this.initialState);
        this.props.onClose();
        this.props.refresh();
        Toast.show('User created and awaiting approval.');
      })
      .catch(err => {
        Toast.show(err.message);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <form autoComplete="off" onSubmit={this.invite}>
          <DialogTitle>New Hire</DialogTitle>
          <DialogContent style={{ overflow: 'visible' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  name="firstName"
                  label="Preferred First Name"
                  autoFocus
                  fullWidth
                  margin="dense"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="legalFirstName"
                  label="Legal First Name"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.legalFirstName}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="phoneNumber"
                  label="Mobile"
                  type="tel"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <InputLabel>Job Title</InputLabel>
                  <Select
                    value={this.state.permissionLevel}
                    onChange={this.handleChange}
                    name="permissionLevel"
                  >
                    {this.props.permissionLevels.map(permissionLevel => (
                      <MenuItem
                        key={permissionLevel.id}
                        value={permissionLevel}
                        disabled={permissionLevel.superior}
                      >
                        {permissionLevel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <InputLabel>Employment Status</InputLabel>
                  <Select
                    value={this.state.employmentStatus}
                    onChange={this.handleChange}
                    name="employmentStatus"
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Variable">Variable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <InputLabel>Area</InputLabel>
                  <Select
                    value={this.state.areaId}
                    onChange={this.handleChange}
                    name="areaId"
                  >
                    {this.props.areas.map(area => (
                      <MenuItem value={area.id} key={area.id}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  format="MM/DD/YYYY"
                  autoOk
                  name="startDate"
                  label="Start Date"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.startDate}
                  onChange={startDate => this.setState({ startDate })}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <InputLabel>Salary/Hourly</InputLabel>
                  <Select
                    value={this.state.compensationType}
                    onChange={this.handleChange}
                    name="compensationType"
                  >
                    <MenuItem value="wage">Hourly</MenuItem>
                    <MenuItem value="salary">Salary</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {this.state.compensationType === 'wage' && (
                  <TextField
                    label="Wage"
                    fullWidth
                    value={this.state.wage}
                    name="wage"
                    margin="dense"
                    type="number"
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    onChange={this.handleChange}
                    required
                  />
                )}
                {this.state.compensationType === 'salary' && (
                  <TextField
                    label="Salary"
                    fullWidth
                    value={this.state.salary}
                    name="salary"
                    margin="dense"
                    type="number"
                    inputProps={{ step: '0.01' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    onChange={this.handleChange}
                    required
                  />
                )}
              </Grid>

              <Grid item xs={12} />
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={this.state.loading}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connect(state => ({
  areas: state.areas,
  permissionLevels: state.permissionLevels
}))(InviteUser);
