import React, { useContext, useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { ProjectPhotosContext } from 'routes/Projects/context/Project.context';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Button, Grid } from 'helpers/themeSafeMui.helper';
import EditPhotoForm from './EditPhotoForm.component';
import './Gallery.css';
import { PhotoModal } from 'components';
import cloudinaryImageTransform from 'helpers/cloudinaryImageTransform.helper';
import GridPickerModal from '../modals/GridPicker.modal';

export default function ProjectGallery() {
  const { photos } = useContext(ProjectPhotosContext);
  const [galleryIdx, setGalleryIdx] = useState(0);
  const [disableKeyDown, setDisableKeyDown] = useState(false);
  const [modalPhoto, setModalPhoto] = useState(null);
  const [gridPickerOpen, setGridPickerOpen] = useState(false);
  const galleryRef = useRef(null);

  useEffect(() => {
    setModalPhoto(old => {
      if (!old) return old;
      return photos[galleryIdx];
    });
  }, [photos, galleryIdx]);

  if (!photos || !photos.length) return null;

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}
      >
        <Button variant="contained" onClick={() => setGridPickerOpen(true)}>
          Open Photo Grid
        </Button>
      </div>
      <Grid container style={{ justifyContent: 'center' }}>
        <PhotoModal
          url={modalPhoto && modalPhoto.url}
          open={!!modalPhoto}
          onClose={() => setModalPhoto(null)}
        />
        <GridPickerModal
          open={gridPickerOpen}
          onClose={() => setGridPickerOpen(false)}
          photos={photos}
          onSelect={(photo, idx) => {
            setGalleryIdx(idx);
            galleryRef.current.slideToIndex(idx);
            setGridPickerOpen(false);
          }}
        />
        <Grid item md={8} sm={12}>
          <div className="image-height-limit">
            <ImageGallery
              ref={galleryRef}
              items={photos.map(photo => ({
                original: photo.url,
                thumbnail: photo.isCloudinaryPhoto
                  ? cloudinaryImageTransform(photo.url)
                  : photo.url
              }))}
              thumbnailPosition="left"
              showPlayButton={false}
              onBeforeSlide={setGalleryIdx}
              disableKeyDown={disableKeyDown}
              onClick={() => setModalPhoto(photos[galleryIdx])}
            />
          </div>
        </Grid>
        <Grid item md={4} sm={12}>
          <EditPhotoForm
            photo={photos[galleryIdx]}
            setDisableKeyDown={setDisableKeyDown}
          />
        </Grid>
      </Grid>
    </>
  );
}
