import React from 'react';
import Checked from '@material-ui/icons/CheckBox';
import MuiCheckBox from '@material-ui/icons/CheckBoxOutlineBlank';

const CheckBox = props => {
  if (props.checked) {
    return (
      <Checked
        onClick={props.onClick}
        color="primary"
        style={{ verticalAlign: 'middle', cursor: 'pointer', ...props.style }}
      />
    );
  } else {
    return (
      <MuiCheckBox
        onClick={props.onClick}
        color="primary"
        style={{ verticalAlign: 'middle', cursor: 'pointer', ...props.style }}
      />
    );
  }
};

export default CheckBox;
