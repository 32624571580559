import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import MoreIcon from '@material-ui/icons/MoreVert';

const ItemDisplay = props => {
  const { itemTitle, itemSubTitle } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        width: '100%'
      }}
    >
      <div>
        <Typography>{itemTitle}</Typography>
        <Typography color="textSecondary" variant="body2">
          {itemSubTitle}
        </Typography>
      </div>

      <MoreIcon color="action" />
    </div>
  );
};

export default ItemDisplay;
