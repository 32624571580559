import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, NoItemsResult } from 'components';
import { useColors } from 'helpers/theme.helper';
import EditableValue from '../components/EditableValue.component';

const EmergencyContactTab = props => {
  const { userId } = props;
  const [emergencyContacts, setEmergencyContacts] = useState([]);

  const getEmergencyContacts = useCallback(() => {
    http()
      .get('/users/' + userId + '/emergency-contacts')
      .then(res => setEmergencyContacts(res))
      .catch(e => Toast.show(e.message));
  }, [userId]);

  useEffect(() => {
    getEmergencyContacts();
  }, [getEmergencyContacts]);

  const removeEmergencyContact = id => {
    if (id.includes('new_')) {
      setEmergencyContacts(emergencyContacts.filter(el => el.id !== id));
    } else {
      if (!window.confirm('Are you sure?')) return;
      http()
        .delete(`/users/${userId}/emergency-contacts/${id}`)
        .then(() => getEmergencyContacts())
        .catch(err => Toast.show(err.message));
    }
  };

  const addEmergencyContact = () => {
    setEmergencyContacts(
      [
        {
          id: 'new_' + new Date().getTime(),
          name: '',
          address: '',
          address2: '',
          phone: '',
          relationship: ''
        }
      ].concat(emergencyContacts)
    );
  };

  const save = emergencyContact => {
    http()
      .post('/users/' + userId + '/emergency-contacts', {
        name: emergencyContact.name,
        address: emergencyContact.address,
        address2: emergencyContact.address2 || undefined,
        phone: emergencyContact.phone,
        relationship: emergencyContact.relationship
      })
      .then(() => getEmergencyContacts())
      .catch(e => Toast.show(e.message));
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="secondary"
        onClick={addEmergencyContact}
      >
        + Add Emergency Contact
      </Button>

      {!emergencyContacts.length && <NoItemsResult type="emergency contacts" />}

      {emergencyContacts.map(emergencyContact => {
        const handleChange = field => value => {
          setEmergencyContacts(
            emergencyContacts.map(el => {
              if (el.id === emergencyContact.id) {
                return { ...el, [field]: value };
              } else {
                return el;
              }
            })
          );
        };

        return (
          <EmergencyContact
            key={emergencyContact.id}
            handleChange={handleChange}
            save={save}
            emergencyContact={emergencyContact}
            removeEmergencyContact={removeEmergencyContact}
          />
        );
      })}
    </React.Fragment>
  );
};

const EmergencyContact = props => {
  const {
    emergencyContact,
    handleChange,
    save,
    removeEmergencyContact
  } = props;
  const colors = useColors();
  const editing = emergencyContact.id.includes('new_');

  return (
    <form
      style={{
        border: `1px solid ${colors.grey}`,
        borderRadius: 8,
        padding: 15,
        marginTop: 15
      }}
      onSubmit={e => {
        e.preventDefault();
        save(emergencyContact);
      }}
    >
      <EditableValue
        label="Name"
        onChange={handleChange('name')}
        value={emergencyContact.name}
        required={true}
        editing={editing}
      />
      <EditableValue
        label="Address"
        onChange={handleChange('address')}
        value={emergencyContact.address}
        required={true}
        type="address"
        editing={editing}
      />
      <EditableValue
        label="Apt/Suite"
        onChange={handleChange('address2')}
        value={emergencyContact.address2}
        editing={editing}
      />
      <EditableValue
        label="Phone"
        onChange={handleChange('phone')}
        value={emergencyContact.phone}
        type="tel"
        required={true}
        editing={editing}
      />
      <EditableValue
        label="Relationship"
        onChange={handleChange('relationship')}
        value={emergencyContact.relationship}
        required={true}
        editing={editing}
      />

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
      >
        <Button
          onClick={() => removeEmergencyContact(emergencyContact.id)}
          variant="outlined"
          style={{ color: colors.error }}
          color="inherit"
        >
          Delete
        </Button>
        {emergencyContact.id.includes('new_') && (
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            style={{ marginLeft: 8 }}
          >
            Save
          </Button>
        )}
      </div>
    </form>
  );
};

export default EmergencyContactTab;
