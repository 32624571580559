import React, { useContext } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from 'helpers/themeSafeMui.helper';
import {
  NewhireChecklistContext,
  UserContext
} from 'routes/Users/context/User.context';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { useColors } from 'helpers/theme.helper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CheckBox } from '@material-ui/icons';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import { Button } from '@material-ui/core';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export default function ChecklistModalItem({ item, locked = false }) {
  const colors = useColors();
  const currentUser = useCurrentUser();
  const { user } = useContext(UserContext);
  const { setNewhireChecklist } = useContext(NewhireChecklistContext);

  const canMark = currentUser.hasPermission('checklist_tier_2');

  locked = locked || !canMark;

  const mark = data => async e => {
    if (locked) return;
    try {
      e.stopPropagation();
      if (locked) return;
      const updated = await http().put(
        `/users/${user.id}/newhire-checklist-items/${item.id}`,
        data
      );

      setNewhireChecklist(old => {
        const foundIdx = old.findIndex(other => other.id === updated.id);
        if (foundIdx === -1) return old;
        const copy = [...old];
        copy[foundIdx] = updated;
        return copy;
      });
    } catch (err) {
      Toast.showErr(err);
    }
  };

  return (
    <ListItem
      key={item.id}
      button={!locked}
      onClick={mark({ completed: !item.completed })}
    >
      <ListItemIcon>
        {item.completed ? (
          <CheckCircleOutlineIcon
            style={{ color: item.optional ? null : colors.success }}
          />
        ) : (
          <HighlightOffIcon
            style={{ color: item.optional ? null : colors.error }}
          />
        )}
      </ListItemIcon>
      <ListItemText primary={item.title} secondary={item.notes} />
      {item.allowOptional && !locked && (
        <Button
          onClick={mark({ optional: !item.optional })}
          variant="contained"
          size="small"
          style={{ flexShrink: 0 }}
        >
          <Typography variant="caption" color="textSecondary">
            optional
          </Typography>
          {item.optional ? (
            <CheckBox
              style={{ marginLeft: 10 }}
              fontSize="inherit"
              color="action"
            />
          ) : (
            <CheckBoxOutline
              style={{ marginLeft: 10 }}
              fontSize="inherit"
              color="action"
            />
          )}
        </Button>
      )}
    </ListItem>
  );
}
