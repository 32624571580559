import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AreaFilterButton, ProjectFilterDropdown } from 'components';
import { ShiftScheduleContext } from '../../context/ShiftSchedule.context';
import WeekPicker from '../../components/WeekPicker.component';
import { ProjectsContext } from '../../context/Projects.context';
import ProjectShiftSearch from '../../components/ProjectShiftSearch.component';
import useCreateShiftsFromTask from '../../hooks/useCreateShiftsFromTask.hook';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Button } from 'helpers/themeSafeMui.helper';
import ShiftScheduleColumns from './components/ShiftScheduleColumns.route';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { ShiftModalContext } from 'routes/ShiftSchedule/context/ShiftModal.context';

export default function ShiftScheduleRoute() {
  const [teamFilteredProjects, setTeamFilteredProjects] = useState([]);
  const [
    selectedAreas,
    setSelectedAreas
  ] = useLocalStorage('shift_schedule_area_filter', [], joi =>
    joi.array().items(joi.object({ id: joi.string().uuid() }).unknown())
  );
  const [projectFilterKeys, setProjectFilterKeys] = useState({});

  const {
    dateWindow,
    setDateWindow,
    hideWeekend,
    setHideWeekend,
    shiftDays
  } = useContext(ShiftScheduleContext);
  const {
    shiftProjectsWithAreasMap,
    projects,
    setFilteredProjects,
    filteredProjectMap,
    projectMap
  } = useContext(ProjectsContext);

  const { openWithProps } = useContext(ShiftModalContext);

  const createShifsFromTask = useCreateShiftsFromTask();

  const openShiftApproval = () => {
    const shiftIds = shiftDays
      .filter(shiftDay => filteredProjectMap[shiftDay.projectId])
      .map(shift => shift.id);
    openWithProps({ shiftIds }, 'approve_by_area');
  };

  useEffect(() => {
    setFilteredProjects(
      teamFilteredProjects.filter(project => {
        if (selectedAreas.length === 0) return true;
        const shiftProject = shiftProjectsWithAreasMap[project.id];
        if (!shiftProject) return false;
        return selectedAreas.some(area => shiftProject.areasSet[area.id]);
      })
    );
  }, [
    shiftProjectsWithAreasMap,
    selectedAreas,
    teamFilteredProjects,
    setFilteredProjects
  ]);

  const sortProjectIds = useCallback(
    projectIds => {
      const copy = [...projectIds];
      if (
        !projectFilterKeys ||
        !projectFilterKeys.leadTechnician ||
        !projectFilterKeys.leadTechnician.length
      ) {
        copy.sort((a, b) => {
          a = projectMap[a];
          b = projectMap[b];
          if (!a && !b) return 0;
          if (!a) return 1;
          if (!b) return -1;
          const aPN = a.projectNumber;
          const bPN = b.projectNumber;
          if (aPN === bPN) return 0;
          if (aPN < bPN) return -1;
          if (aPN > bPN) return 1;
          return 0;
        });
        return copy;
      }
      copy.sort((a, b) => {
        a = projectMap[a];
        b = projectMap[b];
        if (!a && !b) return 0;
        if (!a) return 1;
        if (!b) return -1;
        const aLT = a.leadTechnicianName;
        const bLT = b.leadTechnicianName;
        if (!aLT && !bLT) return 0;
        if (!aLT) return 1;
        if (!bLT) return -1;
        if (aLT === bLT) return 0;
        if (aLT < bLT) return -1;
        if (aLT > bLT) return 1;
        return 0;
      });
      return copy;
    },
    [projectFilterKeys, projectMap]
  );

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 10,
          zIndex: 2
        }}
      >
        <ProjectShiftSearch onSelect={createShifsFromTask} />
        <Link component={RouterLink} to="/shift-schedule/user-table">
          <Button variant="contained">User Table</Button>
        </Link>
        <ProjectFilterDropdown
          projects={projects}
          onUpdate={setTeamFilteredProjects}
          localStorageRootKey="shift_schedule"
          onChangeFilterKeys={setProjectFilterKeys}
        />
      </div>
      <div style={{ zIndex: 1, display: 'flex', alignItems: 'center' }}>
        <WeekPicker
          weekendHidden={hideWeekend}
          setWeekendHidden={setHideWeekend}
          dateWindow={dateWindow}
          setDateWindow={setDateWindow}
          style={{ flexGrow: 1 }}
        />
        <div style={{ paddingLeft: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={openShiftApproval}
          >
            Approve visible shifts
          </Button>
        </div>
        <div style={{ paddingLeft: 10 }}>
          <AreaFilterButton
            selectedAreas={selectedAreas}
            setSelectedAreas={setSelectedAreas}
            buttonText="Filter Projects By Area"
          />
        </div>
      </div>
      <div
        style={{
          height: 'calc(100vh -  215px)',
          display: 'flex',
          overflowX: 'auto',
          zIndex: 1
        }}
      >
        <ShiftScheduleColumns
          showLeadTechColumn={
            !!(
              projectFilterKeys &&
              projectFilterKeys.leadTechnician &&
              projectFilterKeys.leadTechnician.length
            )
          }
          sortProjectIds={sortProjectIds}
        />
      </div>
    </div>
  );
}
