import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export const ProjectsContext = React.createContext();

const ProjectsContextProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [detailsMap, setDetailsMap] = useState({});

  useEffect(() => {
    Promise.all([
      http().get(`/projects?closed=false&orderBy=projectNumber`),
      http().get(`/projects/all-details`)
    ])
      .then(([projects, details]) => {
        setProjects(projects);
        setDetailsMap(
          details.reduce(
            (map, detail) => Object.assign(map, { [detail.projectId]: detail }),
            {}
          )
        );
      })
      .catch(Toast.showErr);
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects, detailsMap }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
