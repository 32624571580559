import React, { useState, useEffect } from 'react';
import { Toast } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { useSelector, useDispatch } from 'react-redux';
import { setAreas } from 'ducks/areas.duck';

const defaultArea = {
  name: '',
  description: ''
};

function AreaModal({ editing, id = null, open, onClose }) {
  const [stateArea, setStateArea] = useState({});
  const dispatch = useDispatch();
  const area = useSelector(state => state.areas.find(area => area.id === id));

  useEffect(() => {
    setStateArea({ ...defaultArea, ...area });
  }, [area]);

  const save = e => {
    e.preventDefault();
    const method = editing ? 'put' : 'post';
    const path = editing ? `/areas/${id}` : '/areas';

    http()
      [method](path, {
        name: stateArea.name,
        description: stateArea.description
      })
      .then(res => {
        dispatch(setAreas(res));
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const onChange = field => e => {
    e.preventDefault();
    const { value } = e.target;
    setStateArea(s => ({ ...s, [field]: value }));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>{editing ? 'Update' : 'Create'} Area</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              type="text"
              label="Name"
              value={stateArea.name}
              onChange={onChange('name')}
              required
            />
          </div>
          <div>
            <TextField
              type="text"
              label="Description"
              value={stateArea.description}
              onChange={onChange('description')}
              required
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AreaModal;
