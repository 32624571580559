import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from 'helpers/themeSafeMui.helper';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ProjectsIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/Contacts';
import CompaniesIcon from '@material-ui/icons/Business';

export default function SearchListItem(props) {
  const { itemLabel, type, link, ...rest } = props;

  function _renderListIcon() {
    switch (type) {
      case 'Users':
        return <AccountCircle />;
      case 'People':
        return <PeopleIcon />;
      case 'Projects':
        return <ProjectsIcon />;
      case 'Companies':
        return <CompaniesIcon />;
      default:
        break;
    }
  }

  return (
    <div>
      <ListItem {...rest} button component={Link} to={link}>
        <ListItemIcon>{_renderListIcon()}</ListItemIcon>
        <ListItemText>{itemLabel}</ListItemText>
      </ListItem>
      <Divider />
    </div>
  );
}
