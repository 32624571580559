import React, { useContext } from 'react';
import { Paper, Typography, Grid, Divider } from 'helpers/themeSafeMui.helper';
import TeamDisplay from './TeamDisplay.component';
import { ChildProjectsContext } from 'routes/Projects/context/ChildProjectsContext.context';

export default function ChildTeams({ getImage }) {
  const { childProjects } = useContext(ChildProjectsContext);
  if (!childProjects.length) return null;

  return (
    <Grid item sm={6} xs={12}>
      <Paper style={{ marginTop: 16 }}>
        <Typography
          style={{ padding: '16px 16px 0' }}
          component="h1"
          variant="h5"
          gutterBottom
        >
          Child Project Team
        </Typography>
        {childProjects.map(project => {
          if (!project.team) return null;
          return (
            <React.Fragment key={project.id}>
              <Divider />
              <div style={{ padding: 16 }}>
                <Typography component="h2" variant="h6" noWrap>
                  {project.projectNumber} {project.name}
                </Typography>
                <div style={{ paddingLeft: 16 }}>
                  <TeamDisplay
                    title="Account Manager"
                    readOnly
                    name={project.team.accountManagerName}
                    phoneNumber={project.team.accountManagerPhoneNumber}
                    email={project.team.accountManagerEmail}
                    image={getImage(project.team.accountManagerId)}
                  />
                  <TeamDisplay
                    title="Senior Project Manager"
                    readOnly
                    name={project.team.seniorProjectManagerName}
                    phoneNumber={project.team.seniorProjectManagerPhoneNumber}
                    email={project.team.seniorProjectManagerEmail}
                    image={getImage(project.team.seniorProjectManagerId)}
                  />
                  <TeamDisplay
                    title="Project Manager"
                    readOnly
                    name={project.team.projectManagerName}
                    phoneNumber={project.team.projectManagerPhoneNumber}
                    email={project.team.projectManagerEmail}
                    image={getImage(project.team.projectManagerId)}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </Paper>
    </Grid>
  );
}
