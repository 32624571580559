import { Toast } from 'components';
import http from 'helpers/http.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/User.context';

export default function useAutomatedChecklistItems() {
  const { user } = useContext(UserContext);
  const [drugTests, setDrugTests] = useState(null);
  const currentUser = useCurrentUser();
  const canView = currentUser.hasPermission('checklist_tier_1');

  useEffect(() => {
    if (user.id && canView)
      http()
        .get(`/users/${user.id}/drug-tests`)
        .then(setDrugTests)
        .catch(Toast.showErr);
  }, [user.id, canView]);

  return drugTests === null
    ? []
    : [
        {
          title: 'Drug Test Sent',
          notes: null,
          completed: drugTests.length > 0,
          userId: user.id,
          optional: false,
          allowOptional: false,
          order: 0
        },
        {
          title: 'Drug Test Completed',
          notes: null,
          completed: user.drugTestCompleted,
          userId: user.id,
          optional: false,
          allowOptional: false,
          order: 1
        },
        {
          title: 'Profile Started',
          notes: 'User has reset password and started the onboarding process',
          completed: user.created !== user.passwordChangedAt,
          userId: user.id,
          optional: false,
          allowOptional: false,
          order: 2
        },
        {
          title: 'Employee Agreement Completed',
          notes: 'User has acknowledged the employee policy',
          completed: user.employeePolicyAcknowledged,
          userId: user.id,
          optional: false,
          allowOptional: false,
          order: 3
        },
        {
          title: 'Profile Completed',
          notes: 'User has completed STEPS onboarding',
          completed: user.onBoardingCompleted,
          userId: user.id,
          optional: false,
          allowOptional: false,
          order: 4
        }
      ];
}
