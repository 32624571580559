import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { CompanyProjectsContext } from '../context/Company.context';
import CompanyProjectsTable from '../components/CompanyProjectTable.component';

function ProjectsTab() {
  const { companyProjects } = useContext(CompanyProjectsContext);

  return (
    <div>
      <CompanyProjectsTable
        projects={companyProjects.filter(project => project.closed)}
      />
    </div>
  );
}

export default withRouter(ProjectsTab);
