import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

GroupedColumns.Column = Column;
GroupedColumns.Group = Group;

function GroupedColumns({ children, groupIds, style = {}, ...rest }) {
  // children should be columns
  const [_groupHeightMatrix, setGroupHeightMatrix] = useState({});

  const _setGroupRef = useCallback(
    (columnIdx, groupId, ref, remove = false) => {
      setGroupHeightMatrix(old => {
        let change = false;
        let group = old[groupId];
        if (!group) {
          change = true;
          group = Array(columnIdx + 1).fill(null);
        }
        if (group.length <= columnIdx) {
          change = true;
          group = group.concat(null);
        }

        const oldHeight = group[columnIdx];
        if (ref && ref.scrollHeight && ref.scrollHeight !== oldHeight) {
          change = true;
          group = [...group];
          group[columnIdx] = ref.scrollHeight;
        }

        if (remove) {
          change = true;
          group = [...group];
          group[columnIdx] = null;
        }

        if (change) {
          return {
            ...old,
            [groupId]: group
          };
        } else {
          return old;
        }
      });
    },
    []
  );

  const _getHeight = useCallback(
    groupId => {
      const group = _groupHeightMatrix[groupId];
      if (!group) return undefined;
      const max = group.reduce((max, curr) =>
        max === null ? curr : curr === null ? max : curr > max ? curr : max
      );
      return max === null ? undefined : max;
    },
    [_groupHeightMatrix]
  );

  const _columnHeight = groupIds
    .map(_getHeight)
    .filter(h => h)
    .reduce((sum, val) => sum + val, 0);

  const columns = Children.toArray(children);
  return (
    <div {...rest} style={{ display: 'flex', ...style }}>
      {columns.map((column, _columnIdx) =>
        React.cloneElement(
          column,
          {
            ...column.props,
            _groupIds: groupIds,
            _columnHeight,
            _getHeight,
            _setGroupRef,
            _columnIdx
          },
          column.props.children
        )
      )}
    </div>
  );
}

function Column({
  children,
  _groupIds,
  _columnHeight,
  _getHeight,
  _setGroupRef,
  _columnIdx,
  style = {},
  ...rest
}) {
  // children should be groups
  const groups = Children.toArray(children);
  const groupMap = groups.reduce(
    (acc, group) => Object.assign(acc, { [group.props.groupId]: group }),
    {}
  );

  return (
    <div
      {...rest}
      style={{
        ...style,
        height: style.height ? style.height(_columnHeight) : _columnHeight
      }}
    >
      {_groupIds.map(groupId => {
        const group = groupMap[groupId];
        return group ? (
          React.cloneElement(
            group,
            { ...group.props, _getHeight, _setGroupRef, _columnIdx },
            group.props.children
          )
        ) : (
          // none of group-type in this column
          // show empty spot
          <GroupedColumns.Group
            key={groupId}
            groupId={groupId}
            _getHeight={_getHeight}
            _setGroupRef={_setGroupRef}
            _columnIdx={_columnIdx}
          />
        );
      })}
    </div>
  );
}

function Group({
  children,
  groupId,
  _columnIdx,
  _getHeight,
  _setGroupRef,
  style = {},
  innerProps = {},
  ...rest
}) {
  const height = _getHeight(groupId);
  const divRef = useRef(null);

  useEffect(() => {
    return () => {
      _setGroupRef(_columnIdx, groupId, divRef.current, true);
    };
  }, [_setGroupRef, _columnIdx, groupId]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      _setGroupRef(_columnIdx, groupId, divRef.current);
    });
    const config = { attributes: true, childList: false, subtree: true };
    observer.observe(divRef.current, config);

    return () => {
      observer.disconnect();
    };
  }, [_setGroupRef, _columnIdx, groupId]);

  return (
    <div
      style={{ ...style, height: style.height ? style.height(height) : height }}
      {...rest}
    >
      <div
        {...innerProps}
        ref={ref => {
          divRef.current = ref;
          _setGroupRef(_columnIdx, groupId, ref);
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default GroupedColumns;
