import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import {
  NewhireChecklistContext,
  UserContext
} from 'routes/Users/context/User.context';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Button, DialogActions, Divider } from '@material-ui/core';
import ChecklistModalItem from './ChecklistModalItem.component';
import useAutomatedChecklistItems from './useAutomatedChecklistItems.hook';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export default function ChecklistModal({ open, onClose }) {
  const currentUser = useCurrentUser();
  const { user } = useContext(UserContext);
  const { newhireChecklist: items, setNewhireChecklist: setItems } = useContext(
    NewhireChecklistContext
  );

  const canSync = currentUser.hasPermission('checklist_tier_2');
  const automatedItems = useAutomatedChecklistItems();

  const resetUserItems = async () => {
    try {
      const updated = await http().put(
        `/users/${user.id}/newhire-checklist-items/sync`
      );

      setItems(updated);
      Toast.show('User Checklist Synced.');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Employee Checklist</DialogTitle>
      <Divider />
      <DialogContent>
        {automatedItems.map(item => (
          <ChecklistModalItem key={item.title} item={item} locked />
        ))}
        {items.map(item => (
          <ChecklistModalItem key={item.id} item={item} />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        {canSync && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={resetUserItems}
            >
              Reset (sync) Checklist
            </Button>
          </>
        )}
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
