import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useImperativeHandle
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Divider
} from 'helpers/themeSafeMui.helper';
import stable from 'stable';
import { useColors } from 'helpers/theme.helper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

const UfSelectionSortingTable = (
  {
    initialOrder = 'desc',
    initialOrderBy,
    columns,
    rows,
    rowAction,
    stickyHeader = false,
    TableProps = {},
    rootStyles = {},
    tableContainerStyles = {},
    paginationContainerStyles = {},
    initialRowsPerPage = 25,
    onSelectionChange = () => {},
    isSelecting = true,
    groups = null,
    alternateColors = false,
    stickyColumns = 0,
    localStorageKey = null,
    checkboxCellProps = {},
    iconProps = {},
    addEmptyRows = true,
    getRowStyle = () => ({}),
    elevation = 1,
    selectedRows: selectedRowsProp = null,
    setSelectedRows: setSelectedRowsProp = null
  },
  selectionSortingTableRef
) => {
  columns = groups
    ? groups.reduce((acc, g, idx) => acc.concat(addGroupIdx(g, idx)), [])
    : columns;

  const checkboxColumn = columns.find(col => col.key === '_checkbox') || null;
  columns = checkboxColumn
    ? columns.filter(col => col.key !== '_checkbox')
    : columns;

  const [order, setOrder] = useStateOrStorage(
    localStorageKey,
    'order',
    initialOrder,
    joi => joi.string()
  );
  const [orderBy, setOrderBy] = useStateOrStorage(
    localStorageKey,
    'orderBy',
    initialOrderBy || (columns[0] && columns[0].key),
    joi => joi.string()
  );
  const [rowsPerPage, setRowsPerPage] = useStateOrStorage(
    localStorageKey,
    'rowsPerPage',
    initialRowsPerPage,
    joi => joi.number()
  );
  const [page, setPage] = useStateOrStorage(localStorageKey, 'page', 0, joi =>
    joi.number()
  );

  let [selectedRows, setSelectedRows] = useState([]);
  if (selectedRowsProp && setSelectedRowsProp) {
    selectedRows = selectedRowsProp;
    setSelectedRows = setSelectedRowsProp;
  }
  const colors = useColors();
  const scrollerRef = useRef(null);
  const columnRefs = useRef(Array(stickyColumns).fill(null));
  const checkboxRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState(
    Array(stickyColumns + 1).fill(0)
  );
  const [unsortRows, setUnsortRows] = useState(false);
  useImperativeHandle(selectionSortingTableRef, () => ({ setUnsortRows }));

  const handleRequestSort = property => {
    if (orderBy === property && order === 'desc') {
      setOrder('asc');
    } else {
      setOrder('desc');
    }
    setUnsortRows(false);
    scrollToTop();
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, page) => {
    scrollToTop();
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const headerCellStyle = ({ columnIdx, groupIdx = null, row2 = false }) => {
    const idx = groupIdx === null ? columnIdx : groupIdx;
    const background =
      alternateColors && idx % 2 === 1 ? colors.lightGrey : colors.paper;
    const color =
      alternateColors && idx % 2 === 1 ? '#000000' : colors.paperText;

    const colorStyles = {
      background,
      color
    };

    if (stickyHeader) {
      const left = offsetLeft(columnIdx);
      return {
        position: 'sticky',
        top: groups && row2 ? 37 : 0,
        left: columnIdx === -1 ? 0 : left,
        zIndex: !row2 ? 4 : left === null ? 4 : stickyColumns + 4,
        ...colorStyles
      };
    }

    return colorStyles;
  };

  const cellStyle = ({ columnIdx, groupIdx: idx }) => {
    const background =
      alternateColors && idx % 2 === 1 ? colors.lightGrey : null;
    const color = alternateColors && idx % 2 === 1 ? '#000000' : null;

    const left = offsetLeft(columnIdx);

    if (left !== null) {
      return {
        background: background === null ? colors.paper : background,
        color,
        position: 'sticky',
        left: columnIdx === -1 ? 0 : left,
        zIndex: 3
      };
    }
    return {
      background,
      color
    };
  };

  const offsetLeft = idx => {
    if (idx >= stickyColumns) return null;
    return columnWidths
      .slice(0, idx + 1)
      .reduce((acc, width) => acc + width, 0);
  };

  const sortedRows = stable(rows, (a, b) => {
    const column = columns.find(column => column.key === orderBy);

    if (!column) {
      return 0;
    }

    if (column.customSort) {
      return order === 'asc'
        ? column.customSort(a, b)
        : column.descSort
        ? column.descSort(a, b)
        : column.customSort(a, b) * -1;
    }

    const orderByA = column.extractor ? column.extractor(a) : a[orderBy];
    const orderByB = column.extractor ? column.extractor(b) : b[orderBy];

    if (order === 'asc') {
      if (!orderByA) return false;
      if (!orderByB) return true;
      return orderByA.toLowerCase() < orderByB.toLowerCase();
    } else {
      if (!orderByA) return true;
      if (!orderByB) return false;
      return orderByA.toLowerCase() > orderByB.toLowerCase();
    }
  });

  const onSelect = row => e => {
    e.stopPropagation();
    setSelectedRows(oldRows => {
      if (oldRows.find(other => other.id === row.id)) {
        return oldRows.filter(other => other.id !== row.id);
      } else {
        return [row, ...oldRows];
      }
    });
  };

  const selectAll = () => setSelectedRows(old => (old.length ? [] : [...rows]));

  const scrollToTop = useCallback(() => {
    if (scrollerRef.current) scrollerRef.current.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSelectedRows(oldRows => {
      const filtered = oldRows.filter(oldRow =>
        rows.find(newRow => newRow.id === oldRow.id)
      );
      if (filtered.length !== oldRows.length) return filtered;
      return oldRows;
    });
  }, [rows, setSelectedRows]);

  useEffect(() => {
    if (onSelectionChange) onSelectionChange(selectedRows);
  }, [selectedRows, onSelectionChange]);

  // eslint-disable-next-line
  useLayoutEffect(() => {
    if (columnRefs.current.length) {
      const currentWidths = columnRefs.current.map(col =>
        col ? col.clientWidth : 0
      );
      currentWidths.unshift(
        checkboxRef.current ? checkboxRef.current.clientWidth : 0
      );
      setColumnWidths(old => {
        return old.some((val, idx) => val !== currentWidths[idx])
          ? currentWidths
          : old;
      });
    }
  });

  let maxPage = Math.ceil(rows.length / rowsPerPage) - 1;
  maxPage = Math.max(maxPage, 0);
  useEffect(() => {
    if (page > maxPage && rows.length > 0) {
      setPage(maxPage);
    }
  }, [rows, maxPage, page, setPage]);

  const displayedRows = unsortRows ? rows : sortedRows;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, displayedRows.length - page * rowsPerPage);

  const { paperText: color, paper: backgroundColor } = colors;

  return (
    <Paper
      elevation={elevation}
      style={{ display: 'flex', flexDirection: 'column', ...rootStyles }}
    >
      <div
        ref={scrollerRef}
        style={{
          overflowX: 'auto',
          flex: '0 1 100%',
          ...tableContainerStyles
        }}
      >
        <Table stickyHeader={stickyHeader} size="small" {...TableProps}>
          <TableHead>
            {groups && (
              <TableRow>
                {/* GROUP HEADER ROW */}
                <TableCell
                  padding={isSelecting ? 'checkbox' : 'none'}
                  style={{
                    backgroundColor,
                    ...headerCellStyle({
                      columnIdx: -1,
                      groupIdx: 0
                    })
                  }}
                />
                {groups.map((group, idx) => {
                  const columnIdx = groups
                    .slice(0, idx)
                    .reduce((acc, group) => acc + group.columns.length, 0);
                  return (
                    <TableCell
                      key={group.label}
                      style={{
                        whiteSpace: 'nowrap',
                        ...headerCellStyle({ columnIdx, groupIdx: idx })
                      }}
                      colSpan={group.columns.length}
                      align="left"
                    >
                      {group.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            <TableRow>
              {/* MODULE HEADER ROW */}
              <TableCell
                padding={isSelecting ? 'checkbox' : 'none'}
                style={{
                  backgroundColor,
                  ...headerCellStyle({
                    columnIdx: -1,
                    row2: true
                  })
                }}
                ref={checkboxRef}
              >
                {isSelecting ? (
                  <IconButton
                    {...(checkboxColumn ? checkboxColumn.IconButtonProps : {})}
                    onClick={selectAll}
                  >
                    {selectedRows.length ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </IconButton>
                ) : null}
              </TableCell>

              {columns.map((column, idx) => (
                <TableCell
                  key={column.key}
                  ref={ref => {
                    if (idx < stickyColumns) columnRefs.current[idx] = ref;
                  }}
                  sortDirection={orderBy === column.key ? order : false}
                  {...(column.headerCellProps || {})}
                  style={{
                    whiteSpace: 'nowrap',
                    color,
                    backgroundColor,
                    ...headerCellStyle({
                      columnIdx: idx,
                      groupIdx: column.groupIdx,
                      row2: true
                    }),
                    ...(column.headerCellProps && column.headerCellProps.style
                      ? column.headerCellProps.style
                      : {})
                  }}
                >
                  <TableSortLabel
                    active={!unsortRows && orderBy === column.key}
                    direction={order}
                    onClick={() => handleRequestSort(column.key)}
                  >
                    {column.getHeaderCellContent
                      ? column.getHeaderCellContent(displayedRows)
                      : column.headerCellContent
                      ? column.headerCellContent
                      : column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                const selected = !!selectedRows.find(
                  other => other.id === row.id
                );
                return (
                  <TableRow
                    style={{
                      cursor: rowAction && 'pointer',
                      ...getRowStyle(row, idx)
                    }}
                    hover={Boolean(rowAction)}
                    key={row.id}
                    onClick={() => rowAction && rowAction(row)}
                  >
                    <TableCell
                      padding={isSelecting ? 'checkbox' : 'none'}
                      className="cell-shrink"
                      style={{
                        cursor: isSelecting ? 'default' : null,
                        ...cellStyle({
                          columnIdx: -1,
                          groupIdx: 0
                        })
                      }}
                      onClick={e => isSelecting && e.stopPropagation()}
                      {...checkboxCellProps}
                    >
                      {isSelecting ? (
                        <IconButton
                          {...(checkboxColumn
                            ? checkboxColumn.IconButtonProps
                            : {})}
                          onClick={onSelect(row)}
                        >
                          {selected ? (
                            <CheckBoxIcon {...iconProps} />
                          ) : (
                            <CheckBoxOutlineBlankIcon {...iconProps} />
                          )}
                        </IconButton>
                      ) : null}
                    </TableCell>
                    {columns.map((column, idx) => {
                      const cellInfo = {
                        columnIdx: idx,
                        groupIdx: column.groupIdx,
                        offsetLeft: offsetLeft(idx),
                        column
                      };
                      if (column.renderer)
                        return (
                          <React.Fragment key={column.key + row.id}>
                            {column.renderer(
                              row,
                              cellInfo,
                              cellStyle(cellInfo)
                            )}
                          </React.Fragment>
                        );
                      const val = column.extractor
                        ? column.extractor(row, cellInfo)
                        : row[column.key];
                      return (
                        <TableCell
                          key={column.key + row.id}
                          style={{
                            whiteSpace: 'nowrap',
                            ...cellStyle(cellInfo)
                          }}
                        >
                          {column.modifier ? column.modifier(val) : val}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && addEmptyRows && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={columns.length + (rowAction ? 1 : 0)} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Divider />
      <div style={paginationContainerStyles}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={displayedRows.length}
          rowsPerPage={rowsPerPage}
          page={page > maxPage ? maxPage : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Paper>
  );
};
function addGroupIdx(group, idx) {
  return group.columns.map(col => ({ ...col, groupIdx: idx }));
}

function useStateOrStorage(localStorageKey, type, initialState, validator) {
  const [stateVal, setStateVal] = useState(initialState);
  const [storageVal, setStorageVal] = useLocalStorage(
    (localStorageKey || '__default_table') + `.${type}`,
    initialState,
    validator
  );

  const val = localStorageKey ? storageVal : stateVal;
  const setVal = localStorageKey ? setStorageVal : setStateVal;

  return [val, setVal];
}

const SelectionSortingTable = React.forwardRef(UfSelectionSortingTable);

export default SelectionSortingTable;
