import React from 'react';
import { useColors } from 'helpers/theme.helper';
import moment from 'moment';

export default function TrainingTableCell({ quiz, record }) {
  const colors = useColors();
  if (record && record.optional) return null;
  const score = quiz ? ((quiz.score || 0) * 100).toFixed(1) + '%' : '';
  const badScore = quiz && quiz.score < 0.7;
  const completed = record ? moment(record.dateCompleted) : '';
  const expireDate =
    record && record.daysToExpire
      ? moment(completed).add(record.daysToExpire, 'days')
      : '';
  const expiresSoon = expireDate
    ? moment().isAfter(moment(expireDate).subtract(1, 'month'))
    : false;
  return (
    <>
      {completed && completed.format('MM/DD/YYYY')}
      {completed && score && ' - '}
      <span style={{ color: badScore ? colors.error : colors.success }}>
        {score}
      </span>
      {!!expireDate && (
        <div
          style={{
            color: expiresSoon ? colors.error : null
          }}
        >
          exp: {expireDate.format('MM/DD/YYYY')}
        </div>
      )}
    </>
  );
}
