import React from 'react';
import { DashboardWrapper, AppBarRouter } from 'components';
import { Paper, Typography } from 'helpers/themeSafeMui.helper';
import { Redirect } from 'react-router-dom';

import JobTitle from './tabs/JobTitle.tab';
import EmployeeSkillsTab from './tabs/EmployeeSkills.tab';
import TaskPresets from './tabs/TaskPresets.tab';
import UserAssignments from './tabs/UserAssignments.tab';
import ProjectStatuses from './tabs/ProjectStatuses';
import DriveTab from './tabs/Drive';
import TemplateDocs from './tabs/TemplateDocs.tab';
import QuickBooksImport from './tabs/QuickBooksImport.tab';
import PlangridUsers from './tabs/PlangridUsers.tab';
import Misc from './tabs/Misc';
import ServiceItems from './tabs/ServiceItems.tab';
import NotificationGroups from './tabs/NotificationGroups.tab';
import PerDiemApprovers from './tabs/PerDiemApprovers.tab';
import FleetioGroupsTab from './tabs/FleetioGroups.tab';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';
import NewhireChecklistItems from './tabs/NewhireChecklistItems.tab';
import ProjectChecklistItems from './tabs/ProjectChecklistItems.tab';

export default function AdminSettings(props) {
  const routes = [
    {
      name: 'Job Titles',
      path: `/admin-settings/job-titles`,
      Component: JobTitle
    },
    {
      name: 'User Assignments',
      path: `/admin-settings/user-assignments`,
      Component: UserAssignments
    },
    {
      name: 'Employee Checklist',
      path: `/admin-settings/newhire-checklist`,
      Component: NewhireChecklistItems
    },
    {
      name: 'Project Checklist',
      path: `/admin-settings/project-checklist`,
      Component: ProjectChecklistItems
    },
    {
      name: 'Notification Groups',
      path: `/admin-settings/notification-groups`,
      Component: NotificationGroups
    },
    {
      name: 'Project Statuses',
      path: `/admin-settings/project-statuses`,
      Component: ProjectStatuses
    },
    {
      name: 'Service Items',
      path: `/admin-settings/service-items`,
      Component: ServiceItems
    },
    {
      name: isUsingOneDrive() ? 'One Drive' : 'Google Drive',
      path: isUsingOneDrive()
        ? `/admin-settings/one-drive`
        : `/admin-settings/google-drive`,
      Component: DriveTab
    },
    {
      name: 'Template Docs',
      path: `/admin-settings/template-docs`,
      Component: TemplateDocs
    },
    {
      name: 'QuickBooks Import',
      path: `/admin-settings/quickbooks-import`,
      Component: QuickBooksImport
    },
    {
      name: 'Per Diem Approvers',
      path: `/admin-settings/per-diem-approvers`,
      Component: PerDiemApprovers
    },
    {
      name: 'Plangrid Users',
      path: `/admin-settings/plangrid-users`,
      Component: PlangridUsers
    },
    {
      name: 'Fleetio Groups',
      path: '/admin-settings/fleetio-groups',
      Component: FleetioGroupsTab
    },
    {
      name: 'Misc',
      path: `/admin-settings/misc`,
      Component: Misc
    },
    {
      name: 'Employee Skills',
      path: `/admin-settings/employee-skills`,
      Component: EmployeeSkillsTab
    },
    {
      name: 'Task Presets',
      path: `/admin-settings/task-presets`,
      Component: TaskPresets
    }
  ];

  if (props.location.pathname === '/admin-settings') {
    return <Redirect to="/admin-settings/job-titles" />;
  }

  return (
    <DashboardWrapper>
      <Paper>
        <Typography variant="h4" style={{ padding: 24 }}>
          Admin Settings
        </Typography>
        <AppBarRouter routes={routes} />
      </Paper>
    </DashboardWrapper>
  );
}
