import safeParse from 'helpers/safeParse.helper';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import StatusesContext from '../context/Statuses.context';

function MaterialCsvGeneratorUF({ materials }, ref) {
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const rowsRef = useRef([]);
  const statuses = useContext(StatusesContext);

  useEffect(() => {
    let rows = [
      [...csvKeys, ...csvStatusKeys].map(key => csvLabels[key])
    ].concat(
      filteredMaterials.map(mat => {
        const row = [];
        const matId = mat.materialId || mat.id;
        for (let key of csvKeys) {
          const val = mat[key] || '';
          const numVal = safeParse(val, '');
          row.push(numKeys[key] ? numVal : val);
        }
        for (let key of csvStatusKeys) {
          const status = statuses[matId] || {};
          const val = status[key] || '';
          row.push(val);
        }
        return row;
      })
    );

    rows = rows.map(row => row.map(escape));
    rowsRef.current = rows;
  }, [statuses, filteredMaterials]);

  let matsString = '';
  try {
    matsString = JSON.stringify(materials);
  } catch (e) {
    matsString = '[]';
  }

  useEffect(() => {
    try {
      const materials = JSON.parse(matsString);
      setFilteredMaterials(
        materials.filter(mat => safeParse(mat.quantity, 0) !== 0)
      );
    } catch (e) {
      setFilteredMaterials(old => (old.length ? [] : old));
    }
  }, [matsString]);

  const downloadCsv = (csvTitle = 'MATERIALS') => {
    const string = rowsRef.current.map(row => row.join(',')).join('\n');

    let prefix = 'data:text/csv;charset=utf-8,';
    const encodedUri = prefix + encodeURIComponent(string);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${csvTitle}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`
    );
    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  useImperativeHandle(ref, () => ({
    downloadCsv
  }));

  return null;
}

const MaterialCsvGenerator = React.forwardRef(MaterialCsvGeneratorUF);
export default MaterialCsvGenerator;

const escape = text => {
  if (typeof text === 'string') {
    if (!text) return text;
    const escapedQuotes = text.replace(/"/g, '""');
    return `"${escapedQuotes}"`;
  }
  if (typeof text === 'number' && !isNaN(text)) {
    return text;
  }
  return '';
};

const csvKeys = [
  'partNumber',
  'model',
  'manufacturer',
  'description',
  'unitCost',
  'unitLabor',
  'specSheet',
  'OM',
  'quantity'
];

const csvStatusKeys = [
  // ADDED WITH {StatusesContext}
  'ordered',
  'received',
  'deployed',
  'returned'
];

const numKeys = {
  unitCost: true,
  unitLabor: true,
  quantity: true,
  ordered: true,
  received: true,
  deployed: true,
  returned: true
};

const csvLabels = {
  partNumber: 'Part Number',
  model: 'Model',
  manufacturer: 'Manufacturer',
  description: 'Description',
  unitCost: 'Unit Cost',
  unitLabor: 'Unit Labor',
  specSheet: 'Spec Sheet',
  OM: 'OM',
  quantity: 'Quantity',
  ordered: 'Ordered',
  received: 'Received',
  deployed: 'Deployed',
  returned: 'Returned'
};
