import React, { useCallback, useMemo } from 'react';
import safeParse from 'helpers/safeParse.helper';
import currency from 'helpers/currency.helper';
import { useSelector } from 'react-redux';

export default function LeftToBill({ projects, statusId }) {
  const leftToBillTotal = useMemo(() => {
    const leftToBillTotal = projects.reduce((total, p) => {
      const leftToBill =
        safeParse(p.dollarValue) - safeParse(p.currentlyBilled);
      return leftToBill <= 0 ? total : leftToBill + total;
    }, 0);
    return leftToBillTotal;
  }, [projects]);

  const isLeftToBill = useIsLeftToBill()(statusId);

  if (!isLeftToBill) return null;
  return (
    <>
      <br />
      Left To Bill: {currency(leftToBillTotal)}
    </>
  );
}

export function useIsLeftToBill() {
  const statuses = useSelector(state => state.projectStatuses);
  const fn = useCallback(
    statusId => {
      const status = statuses.find(s => s.id === statusId);
      return statusMatchers.some(m => status && status.status.match(m));
    },
    [statuses]
  );
  return fn;
}

const statusMatchers = [
  // /Lead \/ Pre-Bid/i,
  // /Currently Bidding/i,
  // /Sent to Customer/i,
  // /Potential POs/i,
  /Operations Hand Off/i,
  /Planning \/ Staging/i,
  /Scheduled \/ In Progress/i,
  /Closeout/i,
  /SSA/i
];
