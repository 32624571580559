import React, { useState } from 'react';
import {
  Popover,
  Button,
  ListItem,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import { useQueryParams } from 'hooks/useQueryParams.hook';

const PendingPopover = ({ workflows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const qp = useQueryParams();
  const updateQueryParams = qp[1];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button onClick={handleClick} variant="contained" size="small">
        Complete
      </Button>
      <Popover
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {workflows.map(wf => (
          <ListItem
            key={wf.id}
            button
            onClick={() => {
              updateQueryParams({
                workflowName: wf.name,
                workflowId: wf.id
              });
              setAnchorEl(null);
            }}
          >
            <ListItemText primary={wf.name} />
          </ListItem>
        ))}
      </Popover>
    </div>
  );
};
export default PendingPopover;
