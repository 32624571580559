import React, { useState, useEffect } from 'react';
import {
  Popover,
  Button,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  Typography
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';

const UserSkillsFilter = ({ ButtonProps, fetchUsers }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedParent, setSelectedParent] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [skills, setSkills] = useState([]);

  const childSkills = selectedParent
    ? skills.filter(skill => selectedParent.id === skill.parentId)
    : [];

  const parentSkills = skills.filter(s => !s.parentId);

  useEffect(() => {
    http()
      .get('/skills')
      .then(res => setSkills(res))
      .catch(Toast.showErr);
  }, []);

  const handleClick = e => setAnchorEl(e.currentTarget);

  useEffect(() => {
    fetchUsers(selectedSkill?.id, skillLevel);
  }, [selectedSkill, skillLevel, fetchUsers]);

  return (
    <>
      <Button variant="contained" {...ButtonProps} onClick={handleClick}>
        Filter by Skill
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ width: 300, padding: 10 }}>
          <Typography variant="h6">Filter by Skill</Typography>
          <div style={{ padding: 5 }} />
          <FormControl fullWidth>
            <InputLabel>Parent Skills</InputLabel>
            <Select
              value={selectedParent}
              onChange={e => setSelectedParent(e.target.value)}
            >
              {parentSkills.map(skill => (
                <MenuItem key={skill.id} value={skill}>
                  <ListItemText primary={skill.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedParent && !childSkills.length && (
            <Typography color="error">No Child Skills Found.</Typography>
          )}

          {childSkills.length > 0 && (
            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel>Skill</InputLabel>
              <Select
                value={selectedSkill}
                onChange={e => setSelectedSkill(e.target.value)}
              >
                {childSkills.map(skill => (
                  <MenuItem key={skill.id} value={skill}>
                    <ListItemText primary={skill.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedSkill && (
            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel>Skill Level</InputLabel>
              <Select
                value={skillLevel}
                onChange={e => setSkillLevel(e.target.value)}
              >
                <MenuItem value={'1'}>Level 1</MenuItem>
                <MenuItem value={'2'}>Level 2</MenuItem>
                <MenuItem value={'1&2'}>Levels 1 & 2</MenuItem>
              </Select>
            </FormControl>
          )}

          <div style={{ paddingTop: 10 }}>
            <Button
              onClick={() => {
                setSelectedParent('');
                setSelectedSkill('');
                setSkillLevel('');
                fetchUsers();
                setAnchorEl(null);
              }}
              fullWidth
              variant="contained"
              size="small"
            >
              Clear Filters
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default UserSkillsFilter;
