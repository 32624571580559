import React from 'react';
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton
} from 'helpers/themeSafeMui.helper';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

export default function PhoneEmailField(props) {
  return (
    <>
      {props.value.map((val, idx) => {
        const handleChange = e => {
          const dup = JSON.parse(JSON.stringify(props.value));
          dup[idx][e.target.name] = e.target.value;
          props.onChange(dup);
        };
        const removeKey = key => {
          const dup = JSON.parse(JSON.stringify(props.value));
          delete dup[idx][key];
          props.onChange(dup);
        };
        const remove = () => {
          const dup = JSON.parse(JSON.stringify(props.value));
          dup.splice(idx, 1);
          props.onChange(dup);
        };

        const gridSizes = {
          primary: props.ext ? 6 : 8,
          type: props.value.length > 1 ? 3 : 4,
          trash: 1,
          ext: 2
        };

        return (
          <Grid container key={idx} spacing={2}>
            <Grid item xs={gridSizes.primary}>
              <TextField
                label={props.phone ? 'Phone Number' : 'Email'}
                fullWidth
                value={val[props.phone ? 'phoneNumber' : 'email']}
                margin="dense"
                name={props.phone ? 'phoneNumber' : 'email'}
                onChange={handleChange}
                required
              />
            </Grid>
            {props.ext && (
              <Grid item xs={gridSizes.ext}>
                <TextField
                  label="Ext."
                  fullWidth
                  value={val.ext || ''}
                  margin="dense"
                  name="ext"
                  onChange={e => {
                    if (e.target.value) {
                      handleChange(e);
                    } else {
                      removeKey('ext');
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={gridSizes.type}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Type</InputLabel>
                <Select value={val.type} onChange={handleChange} name="type">
                  <MenuItem value="Work">Work</MenuItem>
                  <MenuItem value="Home">Home</MenuItem>
                  {props.phone && <MenuItem value="Mobile">Mobile</MenuItem>}
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {props.value.length > 1 && (
              <Grid
                item
                xs={gridSizes.trash}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center'
                }}
              >
                <IconButton onClick={remove}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
      <Button
        size="small"
        color="primary"
        onClick={() =>
          props.onChange([
            ...props.value,
            { type: '', [props.phone ? 'phoneNumber' : 'email']: '' }
          ])
        }
      >
        <AddIcon fontSize="small" />
        {props.phone ? 'Add a phone' : 'Add an email'}
      </Button>
    </>
  );
}
