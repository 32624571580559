import React from 'react';
import ScheduleTableCell from './ScheduleTableCell.component';
import moment from 'moment';
import { Typography } from 'helpers/themeSafeMui.helper';
import Color from 'color';
import getTodayIndex from '../helpers/getTodayIdx.helper';

const ScheduleTableHeader = ({
  startDay,
  firstColumnWidth,
  colors,
  days,
  children,
  height = 50,
  cellWidth = 50,
  firstRow = true,
  firstCell = null,
  style = {}
}) => {
  const todayBg = new Color(colors.yellow)
    .mix(new Color(colors.paper), 0.8)
    .hsl();
  const todayIdx = getTodayIndex(startDay);

  return (
    <>
      <ScheduleTableCell
        width={firstColumnWidth}
        height={height}
        firstRow={firstRow}
        firstCol
        style={{
          backgroundColor: colors.paper,
          ...style,
          zIndex: 3,
          position: 'sticky',
          left: 0
        }}
      >
        {firstCell}
      </ScheduleTableCell>
      {Array(days)
        .fill(null)
        .map((n, idx) => {
          const m = moment(startDay).add(idx, 'days');
          return (
            <ScheduleTableCell
              key={m.format('MMM Do')}
              height={null}
              width={cellWidth}
              style={{
                zIndex: 2,
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                left: firstColumnWidth,
                backgroundColor: idx === todayIdx ? todayBg : colors.paper,
                overflow: 'hidden',
                ...style
              }}
              firstRow={firstRow}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="caption"
                  style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                >
                  {typeof children === 'function' ? children(idx, m) : children}
                </Typography>
              </div>
            </ScheduleTableCell>
          );
        })}
    </>
  );
};

export default ScheduleTableHeader;
