import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  Button,
  List,
  Divider,
  DialogTitle,
  TextField
} from 'helpers/themeSafeMui.helper';
import ProjectNote from '../components/ProjectNote.component';

class ViewAllNotesModal extends React.Component {
  state = {
    note: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { note } = this.state;
    const { notes, open, onClose, addNote } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll="body"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>All Notes</DialogTitle>
        <Divider />

        {notes && (
          <List>
            {notes.map(note => (
              <ProjectNote key={note.id} note={note} />
            ))}
          </List>
        )}

        <div style={{ margin: 20, marginBottom: 10 }}>
          <TextField
            name="note"
            label="Project Note"
            multiline
            rows="4"
            value={note}
            onChange={this.handleChange}
            fullWidth
            variant="outlined"
          />
        </div>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Close
          </Button>
          <Button
            onClick={() => {
              this.setState({ note: '' });
              addNote(note);
            }}
            color="primary"
            variant="outlined"
          >
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(state => ({ user: state.user }))(ViewAllNotesModal);
