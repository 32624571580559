import Color from 'color';

const yellow = new Color('#d1ca00');
const green = new Color('green');

export const percents = [50, 60, 70, 80, 90];

export const percentColors = {
  50: yellow.hex(),
  60: yellow.mix(green, 0.25).hex(),
  70: yellow.mix(green, 0.5).hex(),
  80: yellow.mix(green, 0.75).hex(),
  90: green.hex()
};

export const percentLabels = {
  50: '',
  60: 'Pre-award Designing',
  70: 'Value Engineering',
  80: 'Developed Lead/Preferred Customer',
  90: 'Contract Negotiation'
};
