import React, { useState, useEffect } from 'react';
import { DashboardWrapper, PermissionWrapper, Toast } from 'components';
import { useTheme } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import MessageInput from './components/MessageInput.component';
import PreviousMessage from './components/PreviousMessage.component';
import UsersProvider from 'routes/Users/context/Users.context';
import SmsQueueModal from './modals/SmsQueue.modal';
import { Button } from '@material-ui/core';

const Messaging = () => {
  const theme = useTheme();
  const { pxToRem } = theme.typography;
  const [smsQueueOpen, setSmsQueueOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formContent, setFormContent] = useState({
    type: 'sms',
    areaIds: [],
    title: '',
    message: '',
    userIds: []
  });

  const deleteMessage = id => {
    http()
      .delete(`/mass-messages/${id}`)
      .then(() => {
        setMessages(messages.filter(m => m.id !== id));
      })
      .catch(e => Toast.show(e.message));
  };

  useEffect(() => {
    http()
      .get('/mass-messages')
      .then(message => {
        setMessages(message);
      })
      .catch(e => Toast.show(e.message));
  }, []);

  return (
    <DashboardWrapper>
      <SmsQueueModal
        open={smsQueueOpen}
        onClose={() => setSmsQueueOpen(false)}
      />
      <div
        style={{
          height: '100%',
          display: 'flex'
        }}
      >
        <div
          style={{
            height: `calc(100vh - ${pxToRem(110)})`,
            flex: `0 1 ${pxToRem(600)}`,
            overflow: 'auto'
          }}
        >
          <PermissionWrapper permission="super_admin">
            <Button
              style={{ marginBottom: 5 }}
              onClick={() => setSmsQueueOpen(true)}
            >
              View Sms Queue
            </Button>
          </PermissionWrapper>
          <MessageInput
            setMessages={setMessages}
            formState={[formContent, setFormContent]}
          />
        </div>
        <div style={{ width: 20 }} />
        <div
          style={{
            height: `calc(100vh - ${pxToRem(110)})`,
            flex: `1 1 ${pxToRem(600)}`,
            overflow: 'auto'
          }}
        >
          {messages.map(message => (
            <PreviousMessage
              key={message.id}
              message={message}
              setFormContent={setFormContent}
              deleteMessage={() => deleteMessage(message.id)}
            />
          ))}
        </div>
      </div>
    </DashboardWrapper>
  );
};

const MessagingConnected = () => (
  <UsersProvider>
    <Messaging />
  </UsersProvider>
);

export default MessagingConnected;
