import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';

function CreateEmployeeSkill({ parentId, open, onClose, onCreate, nextOrder }) {
  const [name, setName] = useState('');

  useEffect(() => setName(''), [open]);

  const createEmployeeSkill = async e => {
    e.preventDefault();

    http()
      .post('/skills', {
        name: name,
        parentId: parentId,
        order: nextOrder || 0
      })
      .then(res => {
        onCreate(res);
        onClose();
        Toast.show('Skill created.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={createEmployeeSkill}>
        <DialogTitle>New Skill</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            label="Skill Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateEmployeeSkill;
