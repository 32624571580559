import React, { useContext } from 'react';
import { makeStyles, TableCell, Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import safeParse from 'helpers/safeParse.helper';

export const SetMaterialWithReplacementsContext = React.createContext(() => {
  console.error('outside of context');
});
const useStyles = makeStyles({
  cell: { '&:hover': { textDecoration: 'underline' } }
});

export function getConflictSortingNumber(material) {
  if (!material) return -3;
  const replacingCount =
    material.replacingCount && safeParse(material.replacingCount);
  if (replacingCount > 0) return replacingCount;
  if (material.conflictReplaceWith) return 0;
  return material.conflictHidden ? -1 : -2;
}

export default function ConflictReplacementCell({ material, cellStyle }) {
  const setMaterialWithReplacements = useContext(
    SetMaterialWithReplacementsContext
  );
  const colors = useColors();
  const { cell } = useStyles();

  const replacingCount =
    material.replacingCount && safeParse(material.replacingCount);

  function cellText() {
    if (replacingCount > 0) {
      const s = replacingCount === 1 ? '' : 's';
      return {
        text: `Replaces ${replacingCount} material${s}`,
        color: colors.success
      };
    }
    if (material.conflictReplaceWith)
      return {
        text: 'Has replacement',
        color: colors.success
      };
    return {
      text: 'No replacement assigned',
      color: material.conflictHidden ? colors.paperText : colors.error
    };
  }

  const { color, text } = cellText();

  return (
    <TableCell
      className={replacingCount ? cell : undefined}
      style={cellStyle}
      onClick={e => {
        if (!replacingCount) return;
        setMaterialWithReplacements(material);
        e.stopPropagation();
      }}
    >
      <Typography style={{ color, fontSize: 14 }}>{text}</Typography>
    </TableCell>
  );
}
