const sidePad = 6;

export const makeArrowCoordinates = ({
  rowHeight,
  rowMargin,
  rowCount,
  canvasWidth
}) => {
  const left = [];
  const right = [];
  const quarterHeight = rowHeight / 4;
  for (let i = 0; i < rowCount; i++) {
    const rowTop = rowHeight * i + rowMargin * i;
    left.push({
      out: { x: 0, y: rowTop + quarterHeight },
      in: { x: sidePad, y: rowTop + quarterHeight * 3 }
    });
    right.push({
      in: { x: canvasWidth - sidePad, y: rowTop + quarterHeight },
      out: { x: canvasWidth, y: rowTop + quarterHeight * 3 }
    });
  }
  return { left, right };
};
