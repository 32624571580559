import React, { useContext, useState } from 'react';
import ChecklistStep from './ChecklistStep.component';
import { IconButton } from 'helpers/themeSafeMui.helper';
import More from '@material-ui/icons/MoreVert';
import ChecklistModal from './ChecklistModal.component';
import { useSelector } from 'react-redux';
import {
  ProjectChecklistContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import useAutomatedChecklist from './useAutomatedChecklist.hook';

export default function ProjectChecklist() {
  const [modalOpen, setModalOpen] = useState(false);
  const { project } = useContext(ProjectContext);
  const { projectChecklist } = useContext(ProjectChecklistContext);
  const currentUser = useSelector(state => state.user);
  const automatedItems = useAutomatedChecklist();

  const canView = currentUser.hasPermission('projects_tier_2');
  if (!canView) return null;

  let stepWidthPercent = 1 / (automatedItems.length + projectChecklist.length);
  stepWidthPercent *= 100;
  stepWidthPercent = Math.floor(stepWidthPercent * 1000) / 1000;
  stepWidthPercent = `${stepWidthPercent}%`;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ChecklistModal open={modalOpen} onClose={() => setModalOpen(false)} />

      <div style={{ width: 'calc(100% - 30px)' }}>
        <div style={{ display: 'flex', height: 24 }}>
          {automatedItems.map(item => (
            <ChecklistStep
              width={stepWidthPercent}
              required
              locked
              key={item.id}
              item={item}
            />
          ))}
          {projectChecklist.map(item => (
            <ChecklistStep
              width={stepWidthPercent}
              key={item.id}
              item={item}
              required={
                !item.optional &&
                !!(item.statusOrder || item.statusOrder === 0) &&
                item.statusOrder <= project.statusOrder
              }
            />
          ))}
        </div>
      </div>
      <IconButton size="small" onClick={() => setModalOpen(true)}>
        <More />
      </IconButton>
    </div>
  );
}
