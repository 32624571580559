import moment from 'moment';
import safeParse from 'helpers/safeParse.helper';

const calculateFloat = projectDetails => {
  const {
    estimatedCrew,
    scheduleHrsPerDay,
    scheduleDaysPerWeek,
    start
  } = projectDetails;
  const totalHours =
    safeParse(projectDetails.estimatedCoLaborHours) +
    safeParse(projectDetails.estimatedLaborHours);
  if (!estimatedCrew || !totalHours) return null;
  const dailyManHours = scheduleHrsPerDay * estimatedCrew;
  if (dailyManHours <= 0) return null;

  let remainingHours = totalHours;
  const floatDay = moment(start).startOf('day');
  const skipDays = [0, 6];
  if (scheduleDaysPerWeek === 4) skipDays.push(5);
  while (remainingHours > 0) {
    if (!skipDays.includes(floatDay.day())) {
      remainingHours -= dailyManHours;
      if (remainingHours <= 0) break;
    }
    floatDay.add(1, 'day');
  }

  return floatDay;
};

export default calculateFloat;
