import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  TableCell,
  IconButton
} from 'helpers/themeSafeMui.helper';
import {
  EstimateContext,
  PhaseContext
} from 'routes/Projects/context/Estimate.context';
import {
  getEstimateTotalPropertyOrder,
  calculateEstimateTotals,
  estimateTotalsToString
} from '../../helpers/phaseMaterial.helper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { DndTable } from 'components';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useColors } from 'helpers/theme.helper';

function CalculatedDetails() {
  const { phases } = useContext(PhaseContext);
  const { estimate } = useContext(EstimateContext);
  const detailsViewProperties = getEstimateTotalPropertyOrder();
  const [detailsView, setDetailsView] = useLocalStorage(
    'estimate.details_view',
    detailsViewProperties
  );
  const [open, setOpen] = useState(false);
  const estimateTotals = calculateEstimateTotals(phases, estimate);
  const estimateTotalsString = estimateTotalsToString(estimateTotals);
  const colors = useColors();

  const handleChange = viewProp => () => {
    const copy = [...detailsView];
    const foundIdx = copy.indexOf(viewProp);
    if (foundIdx >= 0) {
      // remove it
      copy.splice(foundIdx, 1);
      setDetailsView(copy);
    } else {
      // add it
      copy.push(viewProp);
      setDetailsView(copy);
    }
  };

  return (
    <>
      <SetCalculatedViewModal
        {...{ open, setOpen, detailsView, handleChange }}
      />
      <DndTable.Table onDrop={setDetailsView}>
        <DndTable.TableHead
          style={{ background: colors.background }}
          dragIndicatorCell={
            <TableCell>
              <IconButton
                style={{ color: colors.backgroundText }}
                onClick={() => setOpen(true)}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </TableCell>
          }
        >
          <TableCell
            style={{ color: colors.backgroundText, textAlign: 'right' }}
          >
            Calculated Information
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </DndTable.TableHead>

        {detailsView.map(prop => (
          <DndTable.TableRow key={prop} id={prop} value={prop}>
            <TableCell>
              <Typography style={{ textAlign: 'right' }}>
                {unCamelCase(prop)}:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{estimateTotalsString[prop]}</Typography>
            </TableCell>
            <TableCell>
              <IconButton onClick={handleChange(prop)} style={{ padding: 0 }}>
                <VisibilityOffIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </DndTable.TableRow>
        ))}
      </DndTable.Table>
    </>
  );
}

function SetCalculatedViewModal({ detailsView, handleChange, open, setOpen }) {
  const viewProperties = getEstimateTotalPropertyOrder();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Display Calculated Details</DialogTitle>
      <DialogContent>
        <FormGroup>
          {viewProperties.map(viewProp => (
            <FormControlLabel
              key={viewProp}
              control={
                <Checkbox
                  checked={detailsView.indexOf(viewProp) >= 0}
                  onChange={handleChange(viewProp)}
                  color="primary"
                />
              }
              label={unCamelCase(viewProp)}
            />
          ))}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}

function unCamelCase(string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
}

export default CalculatedDetails;
