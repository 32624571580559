import React, { useContext, useState } from 'react';
import { ListItemText, TextField } from 'helpers/themeSafeMui.helper';
import { Autocomplete } from '@material-ui/lab';
import { UsersContext } from '../context/Users.context';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { AreaFilterButton } from 'components';

export default function CrewSelector({
  onSelect,
  disabled = false,
  filter = () => true,
  getSecondaryText = () => null
}) {
  const { users } = useContext(UsersContext);
  const [inputValue, setInputValue] = useState('');
  const [
    selectedAreas,
    setSelectedAreas
  ] = useLocalStorage('crew_selector_area_filter', [], joi =>
    joi.array().items(joi.object({ id: joi.string().uuid() }).unknown())
  );

  const filtersActive = selectedAreas.length;
  const filteredUsers = users.filter(
    user =>
      !filtersActive || !!selectedAreas.find(({ id }) => id === user.areaId)
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Autocomplete
        disabled={disabled}
        value={null}
        onChange={(event, user) => {
          onSelect(user);
          setInputValue('');
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={filteredUsers.filter(filter)}
        getOptionLabel={user => user.name}
        style={{ width: 600 }}
        renderOption={option => (
          <ListItemText
            style={{ margin: 0 }}
            primaryTypographyProps={{ noWrap: true }}
            primary={option.name}
            secondary={getSecondaryText(option)}
          />
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Add Crew"
            variant="outlined"
          />
        )}
      />
      <div style={{ marginLeft: 10 }} />
      <AreaFilterButton
        disabled={disabled}
        selectedAreas={selectedAreas}
        setSelectedAreas={setSelectedAreas}
        buttonText="Filter Crew By Area"
      />
    </div>
  );
}
