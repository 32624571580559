import React, { useState } from 'react';
import { Dialog, IconButton } from 'helpers/themeSafeMui.helper';
import { Document, Page } from 'react-pdf';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { useColors } from 'helpers/theme.helper';

const ZoomablePdf = ({ url, open, onClose }) => {
  const [totalPages, setTotalPages] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [heights, setHeights] = useState({});
  const colors = useColors();
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
      style={{ position: 'relative' }}
    >
      <div
        style={{
          width: '100%',
          height: '90vh',
          overflowY: 'auto'
        }}
      >
        <div style={{ width: 500 * zoom, margin: 'auto' }}>
          <Document
            onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
            file={url}
            renderMode="svg"
          >
            {Array.from({ length: totalPages }).map((x, idx) => (
              <div
                key={idx}
                style={{
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
                  position: 'relative',
                  marginTop: 25,
                  height: (heights[idx + 1] || 0) * zoom
                }}
              >
                <Page
                  renderAnnotationLayer={true}
                  pageNumber={idx + 1}
                  loading={null}
                  width={500 * zoom}
                  onLoadSuccess={page => {
                    setHeights(old => ({ ...old, [idx + 1]: page.height }));
                  }}
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
      <div style={{ position: 'absolute', height: 0, top: 0, left: 0 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            style={{
              backgroundColor: colors.primary,
              color: colors.primaryContrastText,
              margin: 10
            }}
            onClick={() => setZoom(zoom => zoom + 0.2)}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            style={{
              backgroundColor: colors.primary,
              color: colors.primaryContrastText,
              margin: 10
            }}
            onClick={() => setZoom(zoom => zoom - 0.2)}
          >
            <ZoomOutIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ZoomablePdf;
