import { useColors } from 'helpers/theme.helper';
import React, { useCallback } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const markLoop = [
  {
    completed: false,
    optional: false,
    color: 'red'
  },
  {
    completed: false,
    optional: false,
    color: 'yellow'
  },
  {
    completed: true,
    optional: false,
    color: 'green'
  }
];

export default function useItemData(item) {
  const colors = useColors();
  return useCallback(
    key => {
      const dataAtColor = {
        red: {
          statusName: 'Incomplete',
          color: colors.error,
          nextMarkData: markLoop[1],
          icon: <HighlightOffIcon style={{ color: colors.error }} />
        },
        yellow: {
          statusName: 'In Progress',
          color: colors.yellow,
          nextMarkData: markLoop[2],
          icon: <AutorenewIcon style={{ color: colors.yellow }} />
        },
        green: {
          statusName: 'Complete',
          color: colors.success,
          nextMarkData: markLoop[0],
          icon: <CheckCircleOutlineIcon style={{ color: colors.success }} />
        },
        gray: {
          statusName: 'Optional',
          color: colors.secondaryText,
          nextMarkData: markLoop[0],
          icon: (
            <RemoveCircleOutlineIcon style={{ color: colors.secondaryText }} />
          )
        },
        default: {
          statusName: 'N / A',
          color: colors.secondaryText,
          nextMarkData: markLoop[0],
          icon: (
            <RemoveCircleOutlineIcon style={{ color: colors.secondaryText }} />
          )
        }
      };
      const itemData = dataAtColor[item.color] || dataAtColor.default;
      return itemData[key];
    },
    [colors, item]
  );
}
