import React, { useEffect, useState } from 'react';
import { Dialog } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import TaskTableSelector from 'routes/Projects/tabs/Schedule/components/TaskTableSelector.component';

export default function TaskSelectorModal({
  projectId,
  open,
  onClose,
  onSelect
}) {
  const [project, setProject] = useState(null);
  const [projectScheduleTasks, setProjectScheduleTasks] = useState([]);

  useEffect(() => {
    if (projectId) {
      http()
        .get(`/projects/${projectId}/schedule-tasks`)
        .then(setProjectScheduleTasks)
        .catch(Toast.showErr);
      http()
        .get(`/projects/${projectId}`)
        .then(setProject)
        .catch(Toast.showErr);
    }
  }, [projectId]);

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={onClose}>
      {!!projectId && !!project ? (
        <TaskTableSelector
          project={project}
          projectScheduleTasks={projectScheduleTasks}
          onSelect={onSelect}
        />
      ) : (
        'Loading'
      )}
    </Dialog>
  );
}
