import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export const ProjectsContext = React.createContext();

const ProjectsContextProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [openProjects, setOpenProjects] = useState([]);
  const [projectsMap, setProjectsMap] = useState({});

  useEffect(() => {
    http()
      .get(`/per-diems/projects`)
      .then(projects => {
        setProjects(projects);
        setProjectsMap(
          projects.reduce((acc, project) => {
            acc[project.id] = project;
            return acc;
          }, {})
        );
        setOpenProjects(projects.filter(project => !project.closed));
      })
      .catch(err => Toast.show(err.message));
  }, []);
  return (
    <ProjectsContext.Provider value={{ projects, projectsMap, openProjects }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
