import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ConfirmDelete, CustomInput, Toast } from 'components';
import { ProjectContext } from 'routes/Projects/context/Project.context';

const EditTaskModal = ({ open, onClose, task, tasks, labels, onUpdate }) => {
  const { project } = useContext(ProjectContext);
  const [taskName, setTaskName] = useState((task && task.taskName) || '');
  const [skill, setSkill] = useState((task && task.skill) || '');
  const [hours, setHours] = useState((task && task.hours) || 0);
  const [skillOptions, setSkillOptions] = useState([]);
  const [techCount, setTechCount] = useState((task && task.techCount) || 0);
  const [startDate, setStartDate] = useState((task && task.startDate) || null);
  const [labelId, setLabelId] = useState((task && task.labelId) || null);
  const [linkedTo, setLinkedTo] = useState((task && task.linkedTo) || null);

  const update = e => {
    e.preventDefault();
    http()
      .put(`/projects/${project.id}/schedule-tasks/${task.id}`, {
        taskName,
        skill,
        hours,
        techCount,
        startDate,
        labelId,
        linkedTo
      })
      .then(onClose)
      .then(() => onUpdate())
      .catch(Toast.showErr);
  };

  const remove = () => {
    http()
      .delete(`/projects/${project.id}/schedule-tasks/${task.id}`)
      .then(onClose)
      .then(() => onUpdate())
      .catch(Toast.showErr);
  };

  useEffect(() => {
    if (!task) return;
    setTaskName(task.taskName);
    setSkill(task.skill);
    setHours(task.hours);
    setTechCount(task.techCount);
    setStartDate(task.startDate);
    setLabelId(task.labelId);
    setLinkedTo(task.linkedTo);

    //get skills
    http()
      .get('/skills')
      .then(res => setSkillOptions(res))
      .catch(Toast.showErr);
  }, [task]);

  const currentLabel = labels.find(label => label.id === labelId);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={update}>
        <DialogTitle>Update Task</DialogTitle>
        <DialogContent>
          <CustomInput
            required
            label="Task Name"
            value={taskName}
            onChange={setTaskName}
          />
          <CustomInput
            type="dropdown"
            label="Task Skill"
            value={skill}
            onChange={setSkill}
            options={[
              { value: null, label: 'No Label' },
              ...skillOptions.map(skill => ({
                value: skill.id,
                label: skill.name
              }))
            ]}
          />
          <CustomInput
            required
            type="number"
            label="Hours"
            value={hours}
            onChange={setHours}
          />
          <CustomInput
            required
            type="number"
            label="Tech Count"
            value={techCount}
            onChange={setTechCount}
          />
          <CustomInput
            required
            type="date"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <div
            style={{
              borderBottom: currentLabel
                ? `5px solid #${currentLabel.color}`
                : null
            }}
          >
            <CustomInput
              type="dropdown"
              label="Label"
              value={labelId}
              onChange={setLabelId}
              options={[
                { value: null, label: 'No Label' },
                ...labels.map(label => ({
                  value: label.id,
                  label: label.name
                }))
              ]}
            />
          </div>

          <CustomInput
            type="dropdown"
            label="LinkedTo"
            value={linkedTo}
            onChange={setLinkedTo}
            options={[
              { value: null, label: '-' },
              ...tasks
                .filter(t => task?.id !== t.id && task?.id !== t.linkedTo)
                .map(t => ({
                  value: t.id,
                  label: t.taskName
                }))
            ]}
          />
          <InputLabel>
            When linked start time is changed, it will change this task's start
            time
          </InputLabel>
        </DialogContent>
        <DialogActions>
          <ConfirmDelete onConfirm={remove} />
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditTaskModal;
