import React, { useContext, useState } from 'react';
import { DndTable, Toast } from 'components';
import {
  PhaseContext,
  EstimateContext
} from '../../../../context/Estimate.context';
import {
  calculateMaterialCosts,
  getMaterialCostKeys,
  formatMaterialCostKey
} from '../../helpers/phaseMaterial.helper';
import { TableCell, IconButton } from 'helpers/themeSafeMui.helper';
import SetVisibleColumnsModal from '../../modals/SetVisibleColumns.modal';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import VisibilityIcon from '@material-ui/icons/Visibility';
import http from 'helpers/http.helper';
import { useColors } from 'helpers/theme.helper';

function PhasesTable() {
  const { phases, setPhases } = useContext(PhaseContext);
  const { estimate } = useContext(EstimateContext);
  const [open, setOpen] = useState(false);
  const allViewProperties = ['name', ...getMaterialCostKeys()];
  const defaultVisibleColumns = allViewProperties.map(prop => [prop, true]);
  const [visibleColumns, setVisibleColumns] = useLocalStorage(
    'estimate.phase_table.visible_columns',
    defaultVisibleColumns
  );
  const colors = useColors();

  const phaseTotals = getPhasesTotals(phases, estimate);

  const onDrop = async newPhases => {
    const phaseUrl = `/projects/${estimate.projectId}/estimates/${estimate.id}/phases/`;
    try {
      setPhases(
        newPhases.map((phase, idx) => {
          phase.order = idx;
          return phase;
        })
      );
      const resPhases = await Promise.all(
        newPhases.map((phase, idx) =>
          http().put(phaseUrl + phase.id, {
            name: phase.name,
            materials: phase.materials,
            order: idx
          })
        )
      );
      setPhases(resPhases);
    } catch (err) {
      setPhases(phases);
      Toast.show(err.message);
    }
  };

  return (
    <>
      <SetVisibleColumnsModal
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        alwaysOn={['name']}
        open={open}
        setOpen={setOpen}
      />
      <DndTable.Table onDrop={onDrop}>
        <DndTable.TableHead
          style={{
            background: colors.background
          }}
          dragIndicatorCell={
            <TableCell>
              <IconButton size="small" onClick={() => setOpen(true)}>
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </TableCell>
          }
        >
          {visibleColumns
            .filter(([, visible]) => visible)
            .map(([columnName]) => (
              <TableCell
                key={columnName}
                style={{ color: colors.backgroundText, whiteSpace: 'nowrap' }}
              >
                {unCamelCase(columnName)}
              </TableCell>
            ))}
        </DndTable.TableHead>
        {phases.map((phase, idx) => (
          <DndTable.TableRow key={phase.id} id={phase.id} value={phase}>
            {visibleColumns
              .filter(([, visible]) => visible)
              .map(([columnName]) => (
                <TableCell key={columnName} style={{ whiteSpace: 'nowrap' }}>
                  {formatMaterialCostKey(
                    columnName,
                    phaseTotals[idx][columnName]
                  )}
                </TableCell>
              ))}
          </DndTable.TableRow>
        ))}
      </DndTable.Table>
    </>
  );
}

export default PhasesTable;

function unCamelCase(string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
}

function getPhasesTotals(phases, estimate) {
  return phases
    .map(phase =>
      phase.materials.map(material =>
        calculateMaterialCosts({ material, estimateDetails: estimate })
      )
    )
    .map((materialsForSinglePhaseCost, idx) => {
      const baseObject = {
        ...phases[idx],
        ...Object.fromEntries(getMaterialCostKeys().map(key => [key, 0]))
      };
      return materialsForSinglePhaseCost.reduce((acc, materialCost) => {
        getMaterialCostKeys().forEach(key => (acc[key] += materialCost[key]));
        return acc;
      }, baseObject);
    });
}
