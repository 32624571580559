import React, { useState, useRef } from 'react';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useColors } from 'helpers/theme.helper';
import 'draft-js/dist/Draft.css';

const RichTextEditor = React.forwardRef(
  ({ placeholder, onChange, initialHtml, initialText }, forwardedRef) => {
    const colors = useColors();
    const [editorState, setEditorState] = useState(() => {
      if (initialHtml)
        return EditorState.createWithContent(stateFromHTML(initialHtml));
      if (initialText)
        return EditorState.createWithContent(
          ContentState.createFromText(initialText)
        );
      return EditorState.createEmpty();
    });
    const ref = useRef(null);

    const focus = () => ref.current && ref.current.editor.focus();

    const handleKeyCommand = command => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return true;
      }
      return false;
    };

    const onTab = e => {
      const maxDepth = 4;
      setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    };

    const toggleBlockType = blockType => {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = inlineStyle => {
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const getHtml = editorState => stateToHTML(editorState.getCurrentContent());

    const getText = editorState =>
      editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .map(block => block.getText())
        .join('\n');

    if (forwardedRef) {
      if (!forwardedRef.current) {
        forwardedRef.current = {};
      }
      forwardedRef.current.getHtml = () => getHtml(editorState);
      forwardedRef.current.getText = () => getText(editorState);
      forwardedRef.current.loadHtml = html => {
        setEditorState(EditorState.createWithContent(stateFromHTML(html)));
      };
      forwardedRef.current.reset = () =>
        setEditorState(() => {
          if (initialHtml)
            return EditorState.createWithContent(stateFromHTML(initialHtml));
          if (initialText)
            return EditorState.createWithContent(
              ContentState.createFromText(initialText)
            );
          return EditorState.createEmpty();
        });
    }

    const handleChange = editorState => {
      setEditorState(editorState);
      onChange({
        html: getHtml(editorState),
        text: getText(editorState)
      });
    };

    return (
      <div style={{ border: `1px solid ${colors.grey}`, borderRadius: 4 }}>
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <div style={{ padding: 10, minHeight: 80 }} onClick={focus}>
          <Editor
            ref={editorRef => (ref.current = editorRef)}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={handleChange}
            onTab={onTab}
            placeholder={placeholder}
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
);

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const StyleButton = ({ onToggle, style, label, active }) => {
  const colors = useColors();
  const onMouseDown = e => {
    e.preventDefault();
    onToggle(style);
  };
  return (
    <span
      onMouseDown={onMouseDown}
      style={{
        display: 'inline-block',
        whiteSpace: 'nowrap',
        color: active ? colors.primaryContrastText : null,
        background: active ? colors.primary : null,
        padding: '5px 10px',
        cursor: 'pointer'
      }}
    >
      {label}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' }
];

const BlockStyleControls = props => {
  const colors = useColors();
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div
      style={{
        borderBottom: `1px solid ${colors.grey}`,
        display: 'flex'
      }}
    >
      {BLOCK_TYPES.map((type, idx, { length }) => (
        <React.Fragment key={type.label}>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
          {idx !== length - 1 && (
            <div
              style={{
                display: 'inline-block',
                borderWidth: 0,
                borderLeft: 1,
                borderStyle: 'solid',
                borderColor: colors.grey
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' }
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  const colors = useColors();
  return (
    <div
      style={{
        borderBottom: `1px solid ${colors.grey}`,
        display: 'flex'
      }}
    >
      {INLINE_STYLES.map((type, idx, { length }) => (
        <React.Fragment key={type.label}>
          <StyleButton
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
          {idx !== length - 1 && (
            <div
              style={{
                display: 'inline-block',
                borderWidth: 0,
                borderLeft: 1,
                borderStyle: 'solid',
                borderColor: colors.grey
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default RichTextEditor;
