import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import AddSkillIcon from '@material-ui/icons/AddCircleOutline';
import http from 'helpers/http.helper';
import CreateTaskPreset from '../modals/CreateTaskPreset.modal';
import TaskPreset from '../components/TaskPreset.component';

import { Toast } from 'components';

const TaskPresets = () => {
  const [presets, setPresets] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);

  //get skill options for preset skills
  useEffect(() => {
    http()
      .get('/skills')
      .then(res => setSkillOptions(res))
      .catch(e => Toast.show(e.message));
  }, [setSkillOptions]);

  //get all presets
  useEffect(() => {
    http()
      .get('/task-presets')
      .then(res => setPresets(res))
      .catch(e => Toast.show(e.message));
  }, []);

  return (
    <div>
      <CreateTaskPreset
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={preset => setPresets(presets.concat(preset))}
        skillOptions={skillOptions}
      />

      <Typography component="h1" variant="h5" gutterBottom>
        Task Presets
        <IconButton
          color="primary"
          style={{ marginLeft: 10, padding: 0 }}
          onClick={() => setCreateModalOpen(true)}
        >
          <AddSkillIcon />
        </IconButton>
      </Typography>

      {presets.map((preset, idx) => (
        <TaskPreset
          preset={preset}
          setPresets={setPresets}
          presets={presets}
          skillOptions={skillOptions}
        />
      ))}
    </div>
  );
};

export default TaskPresets;
