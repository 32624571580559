import React from 'react';
import { TableCell, Tooltip } from 'helpers/themeSafeMui.helper';
import currency from 'helpers/currency.helper';

const commonStyles = {
  fontFamily: 'monospace',
  padding: '0 16px',
  whiteSpace: 'nowrap'
};

const headerCellProps = { align: 'right', style: { whiteSpace: 'pre' } };

const projectChartsColumns = [
  {
    key: 'projectNumber',
    label: 'Project Number',
    renderer: (project, info, cellStyle) => (
      <Tooltip title={project.name}>
        <TableCell padding="none" style={{ ...cellStyle, ...commonStyles }}>
          {project.projectNumber}
        </TableCell>
      </Tooltip>
    )
  },
  {
    key: 'contractAmount',
    label: 'Contract Amount\n(Est. Revenue)',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.contractAmount.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.contractAmount.value,
    ...sorts('contractAmount')
  },
  {
    key: 'previouslyBilled',
    label: 'Previously Billed\n(Act. Revenue)',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.previouslyBilled.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.previouslyBilled.value,
    ...sorts('previouslyBilled')
  },
  {
    key: 'leftToBill',
    label: 'Left to Bill\n(Est. Rev - Act. Rev)',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.leftToBill.text}
      </TableCell>
    ),
    getHeaderCellContent: rows => {
      const sum = rows
        .map(project => {
          try {
            return project.tableData.leftToBill.value;
          } catch (err) {
            return null;
          }
        })
        .reduce((sum, val) => sum + (val || 0), 0);
      return 'Left to Bill' + (sum > 0 ? `\n${currency(sum)}` : '');
    },
    headerCellProps,
    csvExtractor: project => project.tableData.leftToBill.value,
    ...sorts('leftToBill')
  },
  {
    key: 'cost',
    label: 'Act. Cost',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.cost.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.cost.value,
    ...sorts('cost')
  },
  {
    key: 'billedCostDifference',
    label: 'Difference\n(Previously billed -Act Cost)',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.billedCostDifference.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.billedCostDifference.value,
    ...sorts('billedCostDifference')
  },
  {
    key: 'bcdOverBilled',
    label: '%',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.bcdOverBilled.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.bcdOverBilled.value,
    ...sorts('bcdOverBilled')
  },
  {
    key: 'estimatedCost',
    label: 'Est. Cost',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.estimatedCost.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.estimatedCost.value,
    ...sorts('estimatedCost')
  },
  {
    key: 'complete',
    label: '(%) Complete',
    renderer: (project, info, cellStyle) => (
      <TableCell
        padding="none"
        align="right"
        style={{ ...cellStyle, ...commonStyles }}
      >
        {project.tableData.complete.text}
      </TableCell>
    ),
    headerCellProps,
    csvExtractor: project => project.tableData.complete.value,
    ...sorts('complete')
  }
];

export const CsvOnlyColumns = [
  {
    key: 'name',
    label: 'Job Name'
  },
  {
    key: 'companyName',
    label: 'Company'
  }
];

function sorts(tableDataKey) {
  return {
    customSort: (projectA, projectB) => {
      let [a, b] = [
        projectA.tableData[tableDataKey].value,
        projectB.tableData[tableDataKey].value
      ];
      const m = Infinity;
      a = a === null ? m : a;
      b = b === null ? m : b;
      return a - b;
    },
    descSort: (projectA, projectB) => {
      let [a, b] = [
        projectA.tableData[tableDataKey].value,
        projectB.tableData[tableDataKey].value
      ];
      const m = -Infinity;
      a = a === null ? m : a;
      b = b === null ? m : b;
      return b - a;
    }
  };
}

export default projectChartsColumns;
