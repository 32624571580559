import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { showModal } from 'ducks/modal.duck';
import http from 'helpers/http.helper';
import { Toast, ActionItem, Switch } from 'components';
import { useDispatch } from 'react-redux';
import {
  Menu,
  MenuItem,
  Divider,
  Badge,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
  Tooltip
} from 'helpers/themeSafeMui.helper';
import ActionItemsIcon from '@material-ui/icons/AssignmentTurnedIn';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import useScrollMemory from 'hooks/useScrollMemory.hook';
import {
  useSetActionItems,
  useSortedUserActionItems
} from 'state/actionItems.state';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes blinker': {
      '0%': { opacity: 1 },
      '12.5%': { opacity: 0 },
      '25%': { opacity: 1 }
    },
    badge: {
      '& .MuiBadge-badge': {
        animationName: '$blinker',
        animationDuration: '2s',
        animationTimingFunction: 'linear',
        animationIterationCount: props => (props.blink ? 'infinite' : '0')
      }
    }
  })
);

const ActionItems = React.forwardRef(({ color = null }, ref) => {
  const isSmallScreen = useBreakpoint('xs');
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [anchor, setAnchor] = useState(null);
  const [, { setElementRef }] = useScrollMemory('action-items-scroll');
  const [orderBy, setOrderBy] = useLocalStorage(
    'order-action-items-by',
    'created',
    joi => joi.allow('created', 'dueDate')
  );

  const userActionItems = useSortedUserActionItems(
    user.id,
    orderBy,
    orderBy === 'created' ? 'desc' : 'asc'
  );
  const setActionItems = useSetActionItems();

  const incompleteActionItems = userActionItems.filter(
    item => !item.dateCompleted
  );

  const unseenCount = incompleteActionItems.filter(
    item => !item.seenByUser && !item.dateCompleted
  ).length;

  const blinkOn = unseenCount > 0;
  const classes = useStyles({ blink: blinkOn });

  const getActionItems = useCallback(() => {
    let path = '/action-items/query?';
    path += `userId=${user.id}`;
    path += '&completed=false';
    path += `&orderBy=${orderBy}`;
    path += orderBy === 'created' ? '&sortOrder=desc' : '';

    if (user.id)
      http({ withLoader: false })
        .get(path)
        .then(({ actionItems }) => setActionItems(actionItems))
        .catch(err => Toast.show(err.message));
  }, [user.id, setActionItems, orderBy]);

  const updateSeen = seen => {
    const ids = incompleteActionItems
      .filter(item => (seen ? !item.seenByUser : item.seenByUser))
      .map(item => item.id);

    if (ids.length) {
      http()
        .put(`/action-items/view`, { ids, seen: seen })
        .then(updated => setActionItems(updated))
        .catch(err => Toast.show(err.message));
    }
  };

  const close = () => setAnchor(null);

  const open = anchor => {
    setAnchor(anchor);
    getActionItems();
    updateSeen(true);
  };

  const openCreateModal = async () => {
    dispatch(showModal({ type: 'CREATE_ACTION_ITEM' }));
    close();
  };

  useEffect(() => {
    getActionItems();
    const updateActions = setInterval(getActionItems, 1000 * 60);

    return () => {
      clearInterval(updateActions);
    };
  }, [getActionItems]);

  return (
    <React.Fragment>
      <Tooltip title="Action Items">
        <IconButton
          ref={ref}
          color="inherit"
          onClick={e => open(e.currentTarget)}
          style={{ marginRight: 8, padding: 16, color }}
        >
          <Badge
            overlap="rectangular"
            className={classes.badge}
            color="error"
            max={50}
            badgeContent={incompleteActionItems.length}
          >
            <ActionItemsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchor)}
        onClose={close}
        MenuListProps={{
          style: { padding: 0 }
        }}
        keepMounted
      >
        {!!incompleteActionItems.length && [
          <MenuItem
            key={1}
            style={{ textAlign: 'center' }}
            onClick={() => updateSeen(!!unseenCount)}
          >
            <Typography
              variant="button"
              style={{ width: '100%' }}
              color="primary"
            >
              Mark all as {unseenCount ? 'read' : 'unread'}
            </Typography>
          </MenuItem>,
          <Divider key={2} />,
          <div
            key={3}
            style={{
              paddingTop: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Switch
              key={3}
              label="Sort by"
              isLeft={orderBy === 'created'}
              left={{ value: 'created', label: 'Created' }}
              right={{ value: 'dueDate', label: 'Due Date' }}
              onChange={setOrderBy}
              TypographyProps={{ variant: 'button' }}
              id="action-items-sort-by-switch"
            />
          </div>,
          <Divider key={4} />
        ]}

        <div
          style={{
            maxHeight: '40vh',
            maxWidth: isSmallScreen ? null : '40vw',
            overflowY: 'auto'
          }}
          ref={ref => {
            setElementRef(ref);
          }}
        >
          {incompleteActionItems.slice(0, 50).map((item, idx, { length }) => {
            return (
              <div key={item.id}>
                <ActionItem id={item.id} />
                {idx !== length - 1 && <Divider />}
              </div>
            );
          })}
          {incompleteActionItems.length > 50 && (
            <>
              <Divider />
              <MenuItem
                style={{ textAlign: 'center' }}
                component={Link}
                to={`/action-items`}
                onClick={close}
              >
                <Typography
                  variant="button"
                  style={{ width: '100%' }}
                  color="primary"
                >
                  More action Items
                </Typography>
              </MenuItem>
            </>
          )}
        </div>

        <Divider />
        <MenuItem style={{ textAlign: 'center' }} onClick={openCreateModal}>
          <Typography
            variant="button"
            style={{ width: '100%' }}
            color="primary"
          >
            + Add Action Item
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ textAlign: 'center' }}
          component={Link}
          to={`/action-items`}
          onClick={close}
        >
          <Typography variant="button" style={{ width: '100%' }}>
            View All Action Items
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
});

export default ActionItems;
