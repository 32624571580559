import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import { useSelector } from 'react-redux';
import { CustomInput } from 'components';

const StatusMapItemModal = ({ open, onClose, status, onSubmit }) => {
  const [statusId, setStatusId] = useState(status ? status.statusId : '');
  const [dToolsName, setDToolsName] = useState(status ? status.dToolsName : '');
  const stepsStatuses = useSelector(state => state.projectStatuses);

  useEffect(() => {
    setStatusId(status ? status.statusId : '');
    setDToolsName(status ? status.dToolsName : '');
  }, [status]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Status</DialogTitle>
      <DialogContent>
        {status &&
          (status.fromDtools ? (
            <CustomInput
              label="SI Status"
              onChange={setDToolsName}
              value={dToolsName}
            />
          ) : (
            <CustomInput
              type="dropdown"
              label="STEPS Status"
              options={stepsStatuses.map(status => ({
                value: status.id,
                label: status.status
              }))}
              onChange={setStatusId}
              value={statusId}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit({ ...status, statusId, dToolsName });
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusMapItemModal;
