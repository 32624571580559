import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  Paper
} from 'helpers/themeSafeMui.helper';
import { MaterialCard, NoItemsResult, Toast } from 'components';
import { Button } from '@material-ui/core';
import http from 'helpers/http.helper';
import CompactProjectDisplayTile from 'components/ProjectDisplayTile/CompactProjectDisplayTile.component';

export default function ProjectListModal({
  open,
  onClose,
  onProjectSelect,
  material
}) {
  const [projects, setProjects] = useState([]);

  const materialId = material ? material.materialId || material.id : null;

  useEffect(() => {
    if (materialId) {
      setProjects([]);
      http()
        .get(`/materials/${materialId}/projects`)
        .then(setProjects)
        .catch(Toast.showErr);
    }
  }, [materialId]);

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Projects With Material</DialogTitle>
      <Divider />

      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 8,
                flex: '0 0 auto'
              }}
            >
              <Paper elevation={2}>
                <MaterialCard
                  style={{ width: 300 }}
                  material={material}
                  hiddienFields={['quantity']}
                />
              </Paper>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            style={{ overflowY: 'auto', maxHeight: '80vh' }}
          >
            {projects.length ? (
              <List>
                {projects.map(project => (
                  <ListItem button key={project.id} onClick={onProjectSelect}>
                    <CompactProjectDisplayTile
                      link
                      extendPath="materials"
                      project={project}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <NoItemsResult type="Projects" />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
