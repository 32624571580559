import React, { useState, useContext } from 'react';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { Typography } from 'helpers/themeSafeMui.helper';
import {
  ProjectContext,
  ProjectNotesContext,
  RefreshProjectContext
} from '../../../context/Project.context';
import ViewAllNotesModal from '../../../tabs/Home/modals/ViewAllNotes.modal';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const ProjectAddMenu = props => {
  const { projectId, history, showModal } = props;
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);
  const { project } = useContext(ProjectContext);
  const refreshProject = useContext(RefreshProjectContext);
  const [notesOpen, setNotesOpen] = useState(false);
  const { projectNotes: notes, setProjectNotes: setNotes } = useContext(
    ProjectNotesContext
  );

  const addNote = note => {
    http()
      .post(`/projects/${project.id}/notes`, {
        note: note
      })
      .then(res => {
        Toast.show('The note was added.');
        setNotes([res].concat(notes));
      })
      .catch(err => Toast.show(err.message));
  };

  const openCreateActionItemModal = () => {
    showModal({
      type: 'CREATE_ACTION_ITEM',
      props: {
        actionItemLink: `/projects/${projectId}`,
        projectId,
        refresh: () => {
          history.push(`/projects/${projectId}`);
        }
      }
    });
    setOpen(false);
  };

  const createQuote = type => e => {
    setOpen(false);

    http()
      .post(`/projects/${projectId}/quotes`, {
        type: type,
        description: `New ${type} - ${moment().format('L')}`,
        exclusions: [
          'Conduits, boxes, sleeves, bushings, and related hardware or installation.',
          'Configuration or installation of customer provided network equipment.'
        ],
        assumptions: [
          'All work can be completed during normal business hours of 7 am and 6 pm.',
          'All conduits installed for our use will be installed so that cable lengths will not exceed 295 feet.',
          'All cable will be installed after painting is completed. The manufacturer warranty will not cover cables which have been painted.'
        ],
        lineItems: [
          'A computer generated as-built drawings in PDF format. AutoCAD is available upon request.',
          'A PDF document containing the test results for the cable analyzer tests performed for this project.'
        ]
      })
      .then(res =>
        history.push({
          pathname: `/projects/${projectId}/${
            type === 'Quote' ? 'quotes' : 'change-orders'
          }`,
          search: `?r=${Date.now()}`
        })
      )
      .catch(err => Toast.show(err.message));
  };

  const openWorkOrderModal = () => {
    setOpen(false);

    showModal({
      type: 'CREATE_WORK_ORDER',
      props: {
        projectId: projectId,
        refresh: () => {
          refreshProject();
          history.push({
            pathname: `/projects/${projectId}/work-orders`,
            search: `?r=${Date.now()}`
          });
        }
      }
    });
  };

  const openEmailModal = () => {
    setOpen(false);

    showModal({
      type: 'SEND_EMAIL',
      size: 'md',
      props: {
        project: project
      }
    });
  };

  const createEstimate = () => {
    setOpen(false);

    http()
      .post(`/projects/${projectId}/estimates`, {
        name: 'New Estimate'
      })
      .then(res =>
        history.push({
          pathname: `/projects/${projectId}/estimates`,
          search: `?r=${Date.now()}`
        })
      )
      .catch(err => Toast.show(err.message));
  };

  return (
    <>
      <ViewAllNotesModal
        open={notesOpen}
        onClose={() => setNotesOpen(false)}
        addNote={addNote}
        notes={notes}
      />
      <SpeedDial
        style={{ position: 'fixed', right: 24, bottom: 24 }}
        ariaLabel="Project Add Menu"
        icon={<SpeedDialIcon />}
        onClick={() => setOpen(!open)}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          icon={<Typography>A</Typography>}
          tooltipTitle="Action Item"
          onClick={openCreateActionItemModal}
        />
        <SpeedDialAction
          icon={<Typography>N</Typography>}
          tooltipTitle="Notes"
          onClick={() => setNotesOpen(true)}
        />
        <SpeedDialAction
          icon={<Typography>WO</Typography>}
          tooltipTitle="Work Order"
          onClick={openWorkOrderModal}
        />
        {user.hasPermission('testing') && (
          <SpeedDialAction
            icon={<Typography>E</Typography>}
            tooltipTitle="Estimate"
            onClick={createEstimate}
          />
        )}
        {user.hasPermission('projects_tier_2') && (
          <SpeedDialAction
            icon={<Typography>Q</Typography>}
            tooltipTitle="Quote"
            onClick={createQuote('Quote')}
          />
        )}
        {user.hasPermission('projects_tier_2') && (
          <SpeedDialAction
            icon={<Typography>CO</Typography>}
            tooltipTitle="Change Order"
            onClick={createQuote('Change Order')}
          />
        )}
        {user.hasPermission('projects_tier_2') && (
          <SpeedDialAction
            icon={<Typography>EM</Typography>}
            tooltipTitle="Send Email"
            onClick={openEmailModal}
          />
        )}
      </SpeedDial>
    </>
  );
};

export default withRouter(
  connect(state => ({ user: state.user }), { showModal })(ProjectAddMenu)
);
