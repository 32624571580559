import React, { useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { ListItem, Collapse, Paper } from 'helpers/themeSafeMui.helper';

const CollapseListItem = ({
  headerItemContent = null,
  headerItemProps = {},
  getOpen = () => {},
  childrenInListItem = true,
  includeExpandIcon = true,
  dense = false,
  children
}) => {
  const [open, setOpen] = useState(false);
  getOpen(open);

  const margin = open ? 10 : 0;

  return (
    <Paper
      elevation={open ? 2 : 0}
      style={{
        transition: 'all 200ms linear',
        marginBottom: margin,
        marginTop: margin
      }}
    >
      <ListItem
        button
        dense={dense}
        {...headerItemProps}
        onClick={() => setOpen(!open)}
      >
        {headerItemContent}
        {includeExpandIcon && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {childrenInListItem ? <ListItem>{children}</ListItem> : children}
      </Collapse>
    </Paper>
  );
};

export default CollapseListItem;
