import React, { useCallback, useContext, useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectDetailsContext
} from 'routes/Projects/context/Project.context';
import mapReducer from 'helpers/mapReducer.helper';
import { Toast } from 'components';
import safeParse from 'helpers/safeParse.helper';

export const ChildProjectsContext = React.createContext();

export default function ChildProjectsProvider({ children }) {
  const [childProjectIds, setChildProjectIds] = useState([]);
  const [childProjects, setChildProjects] = useState([]);
  const [dollarValue, setDollarValue] = useState(0);

  const [childRelationships, setChildRelationships] = useState([]);
  const [parentProjectIds, setParentProjectIds] = useState([]);
  const [siblingIds, setSiblingIds] = useState([]);
  const [summaryDetails, setSummaryDetails] = useState(null);
  const { project } = useContext(ProjectContext);
  const { projectDetails } = useContext(ProjectDetailsContext);

  const [projectMap, setProjectMap] = useState({});
  const [childDetailsMap, setChildDetailsMap] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchRelatedProjects = useCallback(() => {
    let stale = false;

    if (project.id && projectDetails.projectId) {
      setLoading(true);
      Promise.all([
        http().get(
          `/projects/${project.id}/children?parentProjectId=${project.id}`
        ),
        http().get(
          `/projects/${project.id}/children?childProjectId=${project.id}`
        ),

        // if the details change, summary needs to update
        http().get(`/projects/${projectDetails.projectId}/children/summary`),
        http().get(`/projects/${project.id}/children/details`),
        http().get(`/projects/${project.id}/children/siblings`)
      ])
        .then(
          ([
            childRelationships,
            parentRelationships,
            summaryDetails,
            childDetails,
            siblingIds
          ]) => {
            if (stale) return;
            setChildRelationships(childRelationships);
            setChildProjectIds(childRelationships.map(cr => cr.childProjectId));
            setParentProjectIds(
              parentRelationships.map(pr => pr.parentProjectId)
            );
            setSummaryDetails(summaryDetails);
            setChildDetailsMap(childDetails);
            setSiblingIds(siblingIds);
          }
        )
        .catch(Toast.showErr)
        .finally(() => setLoading(false));
    } else {
      setChildProjectIds([]);
      setParentProjectIds([]);
    }

    return () => {
      stale = true;
    };
  }, [project, projectDetails]);

  const syncWithTsheets = useCallback(
    (setLoading = () => {}) => {
      setLoading(true);
      return Promise.all(
        [project.id, ...childProjectIds].map(projectId =>
          http({ withLoader: false }).put(
            `/projects/${projectId}/details/tsheets`
          )
        )
      )
        .then(fetchRelatedProjects)
        .then(() => Toast.show('Project hours synced with TSheets.'))
        .catch(Toast.showErr)
        .finally(() => setLoading(false));
    },
    [project.id, childProjectIds, fetchRelatedProjects]
  );

  const removeChild = useCallback(
    projectId => {
      const relationships = childRelationships.filter(
        cr => cr.childProjectId === projectId
      );
      Promise.all(
        relationships.map(cr =>
          http().delete(`/projects/${project.id}/children/${cr.id}`)
        )
      )
        .then(fetchRelatedProjects)
        .catch(Toast.showErr);
    },
    [childRelationships, project.id, fetchRelatedProjects]
  );

  useEffect(() => {
    const cleanup = fetchRelatedProjects();
    return cleanup;
  }, [fetchRelatedProjects]);

  useEffect(() => {
    http()
      .get('/projects')
      .then(projects => setProjectMap(projects.reduce(mapReducer('id'), {})))
      .catch(Toast.showErr);
  }, []);

  function addToProjectMap(newProject) {
    setProjectMap(old => ({ ...old, [newProject.id]: newProject }));
  }

  useEffect(() => {
    setChildProjects(
      childProjectIds
        .map(id => projectMap[id] || null)
        .filter(p => p)
        .map(project => ({ ...project, ...childDetailsMap[project.id] }))
    );
  }, [childProjectIds, projectMap, childDetailsMap]);

  useEffect(() => {
    setDollarValue(
      childProjectIds
        .map(id => projectMap[id] || null)
        .filter(p => p)
        .reduce(
          (total, project) => total + safeParse(project.dollarValue),
          safeParse(projectDetails.dollarValue)
        )
    );
  }, [childProjectIds, projectDetails.dollarValue, projectMap]);

  return (
    <ChildProjectsContext.Provider
      value={{
        syncWithTsheets,
        fetchRelatedProjects,
        removeChild,
        childProjects,
        childProjectIds,
        parentProjectIds,
        dollarValue,
        summaryDetails,
        projectMap,
        addToProjectMap,
        siblingIds,
        loading,
        relatedProjectCount:
          childProjectIds.length + parentProjectIds.length + siblingIds.length
      }}
    >
      {children}
    </ChildProjectsContext.Provider>
  );
}
