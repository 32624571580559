import { useState, useEffect } from 'react';

export default function useBetterBlur({
  innerRef,
  onFocus = () => {},
  onBlur = () => {},
  ignoreSelectors = null
}) {
  const [focused, setFocused] = useState(false);

  const handleClick = e => {
    const { current: rootDomNode } = innerRef;
    if (!rootDomNode) return;
    if (ignoreSelectors && e.target.matches(ignoreSelectors)) {
      return;
    }

    let target = e.target;
    while (target !== document.body) {
      if (target === null) {
        // if onClick events remove the target from the dom tree
        // before this listener is reached
        onBlur();
        setFocused(false);
        return;
      }
      target = target.parentNode;
    }

    let willBeFocused;
    if (rootDomNode === e.target || rootDomNode.contains(e.target)) {
      willBeFocused = true;
    } else {
      willBeFocused = false;
    }
    if (focused !== willBeFocused) {
      if (willBeFocused) onFocus();
      if (!willBeFocused) onBlur();
    }

    setFocused(willBeFocused);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return {
    blur: () => {
      if (!focused) return;
      onBlur();
      setFocused(false);
    },
    focus: () => {
      if (focused) return;
      onFocus();
      setFocused(true);
    }
  };
}
