import React, { useState, useEffect } from 'react';
import { Toast, MultiSearchDropdown } from 'components';
import http from 'helpers/http.helper';

const EmailSearch = props => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const getContacts = async () => {
      try {
        const people = await http().get('/people');
        const users = await http().get('/users');

        const contacts = [];

        people.forEach(person =>
          person.emails.forEach(email =>
            contacts.push({
              email: email.email,
              name: person.name,
              id: `${email.email} (${person.name})`
            })
          )
        );

        users.forEach(user =>
          contacts.push({
            email: user.email,
            name: user.name,
            id: `${user.email} (${user.name})`
          })
        );

        setContacts(
          contacts.filter(
            // Filter Duplicates
            (c1, idx) => contacts.findIndex(c2 => c2.id === c1.id) === idx
          )
        );
      } catch (err) {
        Toast.show(err.message);
      }
    };

    getContacts();
  }, []);

  return (
    <MultiSearchDropdown
      stringToItem={email => ({ email: email, id: email })}
      itemToString={contact =>
        contact.email + (contact.name ? ` (${contact.name})` : '')
      }
      items={contacts}
      {...props}
    />
  );
};

export default EmailSearch;
