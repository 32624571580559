import React from 'react';
import {
  Typography,
  Paper,
  Divider,
  List,
  ListItem
} from 'helpers/themeSafeMui.helper';
import { NoItemsResult, ProjectDisplayTile } from 'components';
import { withRouter } from 'react-router-dom';

class PersonProjects extends React.Component {
  render() {
    const { projects } = this.props;

    return (
      <Paper style={{ padding: 16 }}>
        <Typography variant="h6">Projects</Typography>
        <Divider />

        {projects.length > 0 ? (
          <List>
            {projects.map(item => {
              return (
                <React.Fragment key={item.id + item}>
                  <ListItem
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.history.push(`/projects/${item.id}`)
                    }
                  >
                    <ProjectDisplayTile
                      projectTitle={`${item.projectNumber} - ${item.name}`}
                      projectCompany={item.companyName}
                      projectValue={item.dollarValue}
                      project={item}
                      showTooltip={true}
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        ) : (
          <NoItemsResult type="projects" />
        )}
      </Paper>
    );
  }
}

export default withRouter(PersonProjects);
