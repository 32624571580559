import React from 'react';

export default class BoxMove extends React.Component {
  state = {
    pivot: { x: 0, y: 0 },
    wrapperHeight: 0,
    wrapperWidth: 0
  };

  componentDidMount() {
    document.addEventListener('mouseup', this.onDone);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onDone);
  }

  onDone = e => {
    const { onDone, active } = this.props;
    if (!active) return;

    onDone(this.updateBox(e));
  };

  setPivot = e => {
    this.setState({
      pivot: { x: e.clientX, y: e.clientY },
      wrapperWidth: e.currentTarget.offsetWidth,
      wrapperHeight: e.currentTarget.offsetHeight
    });
  };

  updateBox = e => {
    const { box, onUpdate, active } = this.props;
    if (!active) return;

    let x = box.x + (e.clientX - this.state.pivot.x);
    let y = box.y + (e.clientY - this.state.pivot.y);

    if (x < 0) x = 0;
    if (y < 0) y = 0;

    const maxX = this.state.wrapperWidth - box.width;
    const maxY = this.state.wrapperHeight - box.height;

    if (x > maxX) x = maxX;
    if (y > maxY) y = maxY;

    const updatedBox = {
      ...box,
      x: x,
      y: y
    };

    onUpdate(updatedBox);
    return updatedBox;
  };

  render() {
    return (
      <div onMouseMove={this.updateBox} onMouseDown={this.setPivot}>
        {this.props.children}
      </div>
    );
  }
}
