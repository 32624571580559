import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  IconButton,
  Divider
} from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { CustomInput } from 'components';

const FileModal = ({ open, onClose, updateName, url, file, disabled }) => {
  const [editedName, setEditedName] = useState(null);
  const shrink = { flex: '0 1 100%' };
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <div>
        {file && (
          <div
            style={{ display: 'flex', paddingLeft: 16, alignItems: 'center' }}
          >
            {editedName ? (
              <>
                <div style={shrink}>
                  <CustomInput
                    value={editedName}
                    onChange={setEditedName}
                    label="File Name"
                    disabled={disabled}
                  />
                </div>
                <IconButton
                  disabled={disabled}
                  onClick={() =>
                    updateName(editedName).then(() => setEditedName(null))
                  }
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  disabled={disabled}
                  onClick={() => setEditedName(null)}
                >
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <>
                <div style={shrink}>
                  <Typography variant="h5">{file.name}</Typography>
                </div>
                <IconButton
                  disabled={disabled}
                  onClick={() => setEditedName(file.name)}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
          </div>
        )}
      </div>
      <Divider />
      <div style={{ height: '60vh' }}>
        <iframe
          style={{ border: '0px' }}
          width="100%"
          height="100%"
          src={url}
          title="file preview"
        ></iframe>
      </div>
    </Dialog>
  );
};

export default FileModal;
