import React from 'react';

export default class BoxDraw extends React.Component {
  state = {
    pivot: {
      x: 0,
      y: 0
    },
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    drawing: false
  };

  onMouseDown = e => {
    if (!this.props.active) return;

    const { x, y } = e.currentTarget.getBoundingClientRect();
    const downPosition = {
      x: e.clientX - x,
      y: e.clientY - y
    };

    this.setState({
      pivot: downPosition,
      top: downPosition.y,
      left: downPosition.x,
      width: 0,
      height: 0,
      drawing: true
    });
  };

  onMouseMove = e => {
    if (!this.state.drawing) return;

    const { pivot } = this.state;
    const { x, y } = e.currentTarget.getBoundingClientRect();
    const currentPosition = {
      x: e.clientX - x,
      y: e.clientY - y
    };

    this.setState({
      top: currentPosition.y < pivot.y ? currentPosition.y : pivot.y,
      left: currentPosition.x < pivot.x ? currentPosition.x : pivot.x,
      width: Math.abs(currentPosition.x - pivot.x),
      height: Math.abs(currentPosition.y - pivot.y)
    });
  };

  onMouseUp = () => {
    if (!this.state.drawing) return;

    this.setState({ drawing: false });
    this.props.onDrawn({
      top: this.state.top,
      left: this.state.left,
      width: this.state.width < 15 ? 15 : this.state.width,
      height: this.state.height < 15 ? 15 : this.state.height
    });
  };

  onMouseLeave = () => {
    if (!this.state.drawing) return;

    this.setState({ drawing: false });
  };

  render() {
    const { top, left, width, height } = this.state;

    return (
      <div
        onMouseMove={this.onMouseMove}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
        style={{
          position: 'relative',
          userSelect: 'none',
          cursor: this.props.active ? 'crosshair' : 'auto'
        }}
      >
        {this.state.drawing && (
          <div
            style={{
              background: 'rgba(0, 0, 0, 0.2)',
              position: 'absolute',
              zIndex: 1,
              top: top,
              left: left,
              height: height,
              width: width
            }}
          />
        )}
        {this.props.children}
      </div>
    );
  }
}
