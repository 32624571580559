import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import MoreIcon from '@material-ui/icons/MoreVert';
import toCurrency from 'helpers/currency.helper';
import { connect } from 'react-redux';
import { TooltipTable } from '../';
import moment from 'moment';

function ProjectDisplayTile(props) {
  const {
    projectTitle,
    projectCompany,
    projectValue,
    user,
    project = null,
    showTooltip = false,
    tooltipProps = {}
  } = props;

  // must be getting project prop to show tooltip
  const _showTooltip = showTooltip && project;

  const content = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        width: '100%'
      }}
    >
      <div>
        <Typography>{projectTitle}</Typography>
        <Typography color="textSecondary" variant="body2">
          {projectCompany}
        </Typography>
        {user.hasPermission('projects_tier_2') && (
          <Typography color="textSecondary" variant="body2">
            {toCurrency(projectValue)}
          </Typography>
        )}
      </div>

      <MoreIcon color="action" />

      {project && project.created && (
        <div style={{ position: 'absolute', right: 15, bottom: 5 }}>
          <Typography variant="caption" color="textSecondary">
            Created: {moment(project.created).format('l')}
          </Typography>
        </div>
      )}
    </div>
  );

  let projectManagerName;
  let leadTechnicianName;
  if (_showTooltip) {
    projectManagerName = (project.projectManagerName || '').trim() || 'none';
    leadTechnicianName = (project.leadTechnicianName || '').trim() || 'none';
  }

  return _showTooltip ? (
    <TooltipTable
      grid={[
        ['PM:', projectManagerName],
        ['LT:', leadTechnicianName]
      ]}
      placement={'left'}
      {...tooltipProps}
    >
      {content}
    </TooltipTable>
  ) : (
    content
  );
}

export default connect(state => ({ user: state.user }))(ProjectDisplayTile);
