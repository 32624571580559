import http from 'helpers/http.helper';
import { useCallback, useContext } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import { Toast } from 'components';

export default function useCheckIfShouldRefetch() {
  const { project } = useContext(ProjectContext);

  const checkIfShouldRefetch = useCallback(() => {
    if (!project.id) return Promise.resolve(false);
    return http()
      .put(`/plangrid/projects/${project.id}/photos`)
      .then(res => !!res.isPhotosAdded)
      .catch(err => {
        Toast.showErr(err);
        return false;
      });
  }, [project.id]);

  return checkIfShouldRefetch;
}
