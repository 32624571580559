import { NoItemsResult, Toast } from 'components';
import {
  Button,
  CircularProgress,
  Typography
} from 'helpers/themeSafeMui.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ShiftCard from '../components/ShiftCard.component';
import WeekPicker from '../components/WeekPicker.component';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { fromDateString } from '../helpers/DateTime.helper';

export default function MyShifts() {
  const currentUser = useCurrentUser();
  const {
    dateWindow,
    setDateWindow,
    userIdShiftMap,
    shiftMap,
    fetchSchdeules
  } = useContext(ShiftScheduleContext);
  const [loading, setLoading] = useState(false);

  const allShifts = getUsersShifts(currentUser.id, userIdShiftMap, shiftMap);
  const dayShifts = getShiftDowDayArray(allShifts, dateWindow);

  const reloadSchedule = async () => {
    setLoading(true);
    await fetchSchdeules();
    setLoading(false);
    Toast.show('Schedule loaded.');
  };

  return (
    <div style={{ position: 'relative' }}>
      <WeekPicker
        dateWindow={dateWindow}
        setDateWindow={setDateWindow}
        disableWeekendButton
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          top: 56,
          pointerEvents: 'none'
        }}
      >
        <Button
          style={{ pointerEvents: 'auto', margin: '5px 0' }}
          variant="contained"
          onClick={reloadSchedule}
          disabled={loading}
        >
          {loading && (
            <CircularProgress size="1rem" style={{ marginRight: 10 }} />
          )}{' '}
          Refresh
        </Button>
      </div>
      {dayShifts.map(({ date, shifts }) => (
        <div key={date.format()} style={{ marginBottom: 35 }}>
          <Typography variant="h5" gutterBottom>
            {moment(date).format('ddd MMM D')}
          </Typography>

          {!shifts.length && <NoItemsResult type="shifts" />}

          {shifts.map((shift, idx) => (
            <React.Fragment key={shift.id}>
              {idx !== 0 && <div style={{ paddingBottom: 10 }} />}
              <ShiftCard
                shiftDay={shift}
                lockedOpen
                noEdit
                showCrewPhoneNumbers
              />
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
}

function getUsersShifts(userId, userIdShiftMap, shiftMap) {
  const userShifts = userIdShiftMap[userId] || [];
  return userShifts
    .map(({ shiftDayId }) => shiftMap[shiftDayId])
    .filter(shift => shift)
    .filter(shift => shift.approved);
}

function getShiftDowDayArray(shifts, dateWindow) {
  const DowArray = Array(7)
    .fill(null)
    .map((n, idx) => ({
      date: moment(dateWindow[0]).day(idx),
      shifts: []
    }));

  shifts.forEach(shift => {
    const day = fromDateString(shift.date).day();
    if (DowArray[day]) DowArray[day].shifts.push(shift);
  });

  return DowArray;
}
