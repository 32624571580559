import React, { useContext, useState } from 'react';
import moment from 'moment';
import {
  Paper,
  Typography,
  Divider,
  Button
} from 'helpers/themeSafeMui.helper';
import { ActionItem } from 'components';
import { UsersContext } from '../context/Users.context';

const TrainingRecentActionCard = ({
  item,
  type,
  compact = false,
  noPaper = false,
  width = undefined,
  style = {}
}) => {
  const { users } = useContext(UsersContext);
  const createdByUser =
    !compact && users.find(({ id }) => item.createdBy === id);

  const [showEmailPrevMessages, setShowEmailPrevMessages] = useState(false);

  function contents() {
    switch (type) {
      case 'sms':
        return <div style={compact ? ellipsis : multiLine}>{item.content}</div>;
      case 'email':
        const [message, prevMessage] = splitEmailMessage(item.content);
        return compact ? (
          <div style={ellipsis}>{item.emailSubject}</div>
        ) : (
          <div style={multiLine}>
            <Typography>{item.emailSubject}</Typography>
            <Divider style={{ marginBottom: 10 }} />
            {prevMessage ? (
              <>
                <div>
                  {message}
                  {showEmailPrevMessages && prevMessage}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setShowEmailPrevMessages(old => !old)}
                  >
                    {showEmailPrevMessages ? 'Hide' : 'Show'} Previous Message
                  </Button>
                </div>
              </>
            ) : (
              item.content
            )}
          </div>
        );
      case 'action-item':
        return compact ? (
          <div style={ellipsis}>{item.content}</div>
        ) : (
          <ActionItem id={item.actionItemId} />
        );
      case 'note':
        return <div style={compact ? ellipsis : multiLine}>{item.content}</div>;
      default:
        return null;
    }
  }

  return React.createElement(
    noPaper ? 'div' : Paper,
    {
      style: {
        position: 'relative',
        width:
          width === undefined ? (compact ? 'calc(100% - 10px)' : 300) : width,
        height: 'calc(100% - 10px)',
        margin: '5px 5px',
        padding: '18px 5px 2px',
        ...style
      }
    },
    <Typography
      style={{ position: 'absolute', top: -3, left: 5 }}
      variant="caption"
      color="textSecondary"
    >
      {type}
    </Typography>,
    <Typography
      style={{ position: 'absolute', top: -3, right: 5 }}
      variant="caption"
      color="textSecondary"
    >
      {createdByUser && `${createdByUser.name} - `}
      {moment(item.created).format('M/D LT')}
    </Typography>,
    contents()
  );
};

const ellipsis = {
  height: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

const multiLine = {
  whiteSpace: 'pre-wrap'
};

function splitEmailMessage(content) {
  const [emailCurrentMessage, emailPrevMessage] = content.split('\n').reduce(
    (acc, line) => {
      if (acc[2]) {
        acc[1] += line + '\n';
        return acc;
      }
      if (line.match(/On.+wrote:/)) {
        acc[1] = line + '\n';
        acc[2] = true;
        return acc;
      }
      acc[0] += line + '\n';
      return acc;
    },
    ['', null, false]
  );

  return [emailCurrentMessage, emailPrevMessage];
}

export default TrainingRecentActionCard;
