import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import AddSkillIcon from '@material-ui/icons/AddCircleOutline';
import http from 'helpers/http.helper';
import CreateEmployeeSkill from '../modals/CreateEmployeeSkill.modal';
import ParentSkill from '../components/ParentSkill.component';
import { Toast } from 'components';

const EmployeeSkills = () => {
  const [skills, setSkills] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const parentSkills = skills
    .filter(skill => !skill.parentId)
    .sort((a, b) => a.order - b.order);

  //get all skills
  useEffect(() => {
    http()
      .get('/skills')
      .then(res => setSkills(res))
      .catch(e => Toast.show(e.message));
  }, []);

  const updateSkill = (skillId, payload) => {
    http()
      .put(`/skills/${skillId}`, payload)
      .then(res =>
        setSkills(skills =>
          skills.map(skill => (skill.id === skillId ? res : skill))
        )
      )
      .catch(e => Toast.show(e.message));
  };

  const deleteSkill = skillId => {
    http()
      .delete(`/skills/${skillId}`)
      .then(res =>
        setSkills(skills => skills.filter(skill => skill.id !== skillId))
      )
      .catch(e => Toast.show(e.message));
  };

  const orderSkills = async (allSkills, indexToMove, direction) => {
    const orderedSkills = [...allSkills];
    // Swap array items
    const item1 = orderedSkills[indexToMove];
    const item2 = orderedSkills[indexToMove + direction];
    if (!item1 || !item2) return;
    orderedSkills[indexToMove] = item2;
    orderedSkills[indexToMove + direction] = item1;
    // Update
    orderedSkills.map((skill, idx) => updateSkill(skill.id, { order: idx }));
  };

  return (
    <div>
      <CreateEmployeeSkill
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={skill => setSkills(skills.concat(skill))}
        nextOrder={parentSkills[0]?.order - 1}
      />

      <Typography component="h1" variant="h5" gutterBottom>
        Employee Skills
        <IconButton
          color="primary"
          style={{ marginLeft: 10, padding: 0 }}
          onClick={() => setCreateModalOpen(true)}
        >
          <AddSkillIcon />
        </IconButton>
      </Typography>

      {parentSkills.map((parentSkill, idx) => (
        <ParentSkill
          key={parentSkill.id}
          skill={parentSkill}
          onCreate={skill => setSkills(skills.concat(skill))}
          onReorder={direction => orderSkills(parentSkills, idx, direction)}
          updateSkill={updateSkill}
          deleteSkill={deleteSkill}
          orderSkills={orderSkills}
          childSkills={skills
            .filter(skill => skill.parentId === parentSkill.id)
            .sort((a, b) => a.order - b.order)}
        />
      ))}
    </div>
  );
};

export default EmployeeSkills;
