import React, { useState } from 'react';
import { Button, Typography, TextField } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { DragAndDrop } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const LineItem = props => {
  const { value, onDelete, onUpdate, readOnly } = props;
  const [editing, setEditing] = useState(false);
  const [itemText, setItemText] = useState(value);

  const saveItem = () => {
    onUpdate(itemText);
    setEditing(false);
  };

  return (
    <div style={{ width: '100%' }}>
      {editing ? (
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            value={itemText}
            onChange={e => setItemText(e.target.value)}
            style={{ marginRight: 8 }}
          />

          <Button variant="contained" color="secondary" onClick={saveItem}>
            Save
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          {!readOnly && (
            <EditIcon
              fontSize="small"
              color="action"
              onClick={() => setEditing(true)}
              style={{ cursor: 'pointer', marginRight: 8 }}
            />
          )}

          <Typography style={{ flex: 1 }}>{value}</Typography>

          {!readOnly && (
            <DeleteIcon
              fontSize="small"
              color="action"
              style={{ cursor: 'pointer' }}
              onClick={onDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};

const QuoteLineItems = props => {
  const {
    readOnly,
    values,
    onChange,
    title,
    placeholder,
    customTitle = false
  } = props;
  const colors = useColors();
  const [description, setDescription] = useState('');

  const addItem = () => {
    onChange(values.concat(description));
    setDescription('');
  };

  const removeItem = deleteIndex => () => {
    onChange(values.filter((item, index) => deleteIndex !== index));
  };

  const updateItem = updateIndex => val => {
    onChange(values.map((item, index) => (updateIndex === index ? val : item)));
  };

  return (
    <div style={{ marginTop: 8, marginBottom: 24 }}>
      {customTitle ? (
        props.children
      ) : (
        <Typography
          variant="h6"
          style={{
            padding: '0 8px',
            background: colors.secondary,
            color: colors.secondaryContrastText,
            marginBottom: 16
          }}
        >
          {title}
        </Typography>
      )}

      {readOnly ? (
        values.map((item, index) => (
          <Typography key={item} style={{ paddingLeft: 16 }}>
            {index + 1}. {item}
          </Typography>
        ))
      ) : (
        <DragAndDrop onDrop={onChange}>
          {values.map((item, index) => (
            <LineItem
              id={item}
              key={item}
              index={index}
              value={item}
              readOnly={readOnly}
              onDelete={removeItem(index)}
              onUpdate={updateItem(index)}
            />
          ))}
        </DragAndDrop>
      )}

      {!readOnly && (
        <div style={{ display: 'flex', marginTop: 16 }}>
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            label={placeholder}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          <Button
            disabled={!description.length}
            variant="contained"
            color="secondary"
            onClick={addItem}
            style={{ marginLeft: 24 }}
          >
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuoteLineItems;
