import React, { useState, useContext, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { CustomInput, Toast } from 'components';
import {
  PlangridUsersContext,
  PlangridStatusesContext
} from '../components/PlangridRfis.component';

const emptyRfi = {
  locked: false,
  title: '',
  question: '',
  answer: '',
  assigned_to: [],
  due_at: null,
  status: null
};

const PlangridRfiModal = ({
  formTitle,
  rfi = emptyRfi,
  open,
  onSubmit,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(rfi.locked);
  const [title, setTitle] = useState(rfi.title);
  const [question, setQuestion] = useState(rfi.question);
  const [answer, setAnswer] = useState(rfi.answer);
  const [statusId, setStatusId] = useState(rfi.status && rfi.status.uid);
  const [assignedToUids, setAssignedToUids] = useState(
    rfi.assigned_to.map(user => user.uid)
  );
  const [dueAt, setDueAt] = useState(() =>
    rfi.due_at ? moment(rfi.due_at).toDate() : null
  );
  const plangridUsers = useContext(PlangridUsersContext);
  const plangridStatuses = useContext(PlangridStatusesContext);

  const onFormSubmit = async e => {
    e.preventDefault();
    if (rfi.locked && locked) {
      return Toast.show("Can't update locked RFI");
    }
    setLoading(true);
    await onSubmit({
      locked,
      title,
      question,
      answer,
      assignedToUids,
      statusId,
      dueAt
    });
    setLoading(false);
  };

  const toggleUser = user => () => {
    setAssignedToUids(ids =>
      ids.includes(user.uid)
        ? ids.filter(id => user.uid !== id)
        : [user.uid, ...ids]
    );
  };

  const reset = useCallback(() => {
    setLocked(rfi.locked);
    setTitle(rfi.title);
    setQuestion(rfi.question);
    setAnswer(rfi.answer || '');
    setAssignedToUids(rfi.assigned_to.map(user => user.uid));
    setDueAt(rfi.due_at ? moment(rfi.due_at).toDate() : null);
    setLoading(false);
  }, [rfi]);

  const closeAndClear = () => {
    reset();
    onClose();
  };

  useEffect(() => reset(), [reset]);

  return (
    <Dialog onClose={closeAndClear} open={open}>
      <DialogTitle>{formTitle}</DialogTitle>
      <form onSubmit={onFormSubmit}>
        <div style={{ padding: 16 }}>
          <TextField
            disabled={loading}
            fullWidth
            style={{ marginBottom: 10 }}
            variant="outlined"
            label="Title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            inputProps={{ style: { fontSize: '1.5em' } }}
          />
          <TextField
            disabled={loading}
            fullWidth
            style={{ marginBottom: 10 }}
            label="Question"
            multiline
            value={question}
            onChange={e => setQuestion(e.target.value)}
          />
          <TextField
            disabled={loading}
            fullWidth
            style={{ marginBottom: 10 }}
            label="Answer"
            value={answer}
            onChange={e => setAnswer(e.target.value)}
          />
          <CustomInput
            disabled={loading}
            type="dropdown"
            label="Status"
            onChange={setStatusId}
            value={statusId}
            options={plangridStatuses.map(status => ({
              label: status.label || 'unnamed',
              value: status.uid
            }))}
          />
          <CustomInput
            disabled={loading}
            label="Due On"
            type="date"
            value={dueAt}
            onChange={val => setDueAt(val)}
          />
          <CustomInput
            disabled={loading}
            label="Locked"
            type="boolean"
            value={locked}
            onChange={val => setLocked(val)}
          />
          {!!plangridUsers.length && (
            <List>
              <ListItem>
                <ListItemText primary="Assigned Users" />
              </ListItem>
              {plangridUsers.map(user => {
                const labelId = `checkbox-list-label-${user.uid}`;
                return (
                  <ListItem
                    key={user.uid}
                    dense
                    button
                    onClick={toggleUser(user)}
                    disabled={loading}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={assignedToUids.includes(user.uid)}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${user.first_name} ${user.last_name}`}
                      secondary={user.email}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
        <DialogActions>
          <Button
            disabled={loading}
            color="primary"
            type="submit"
            variant="contained"
          >
            {loading ? (
              <>
                <CircularProgress size={20} style={{ marginRight: 10 }} />{' '}
                Loading...
              </>
            ) : (
              'Save'
            )}
          </Button>
          <Button
            disabled={loading}
            onClick={closeAndClear}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PlangridRfiModal;
