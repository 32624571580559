import { TextOverflowPopover } from 'components';
import {
  toDollar,
  trim0s,
  twoAfterDec
} from 'helpers/floatStringFormatter.helper';
import { Typography } from 'helpers/themeSafeMui.helper';

const fields = [
  'manufacturer',
  'model',
  'partNumber',
  'description',
  'quantity',
  'unitCost',
  'unitLabor',
  'specSheet',
  'OM'
];

export default function MaterialCard({
  material,
  hiddienFields = [],
  style = {}
}) {
  if (!material) return null;

  const isHidden = key => hiddienFields.includes(key);

  return (
    <div style={{ padding: '4px 8px', minWidth: 300, ...style }}>
      {fields.map(fieldName => (
        <DisplayMaterialField
          key={fieldName}
          material={material}
          fieldName={fieldName}
          hidden={isHidden(fieldName)}
        />
      ))}
    </div>
  );
}

const format = {
  quantity: trim0s,
  unitCost: toDollar,
  unitLabor: twoAfterDec
};

const labels = {
  manufacturer: 'Manufacturer',
  model: 'Model',
  partNumber: 'Part Number',
  description: 'Description',
  quantity: 'Quantity',
  unitCost: 'Unit Cost',
  unitLabor: 'Unit Labor',
  specSheet: 'Spec Sheet',
  OM: 'OM'
};

const altDisplays = {
  description: val => (
    <TextOverflowPopover
      style={{ paddingLeft: 6 }}
      text={val}
      popoverTitle="Description"
    />
  )
};

function DisplayMaterialField({ material, fieldName, hidden }) {
  let noValue = false;
  if (hidden) return null;
  let value = material[fieldName];
  if (format[fieldName]) value = format[fieldName](value);
  if (!value) noValue = true;
  const label = labels[fieldName];
  const altDisplay = altDisplays[fieldName];

  return (
    <div>
      <Typography
        style={{ lineHeight: 1, fontSize: 9 }}
        color="textSecondary"
        variant="overline"
      >
        {label}:
      </Typography>
      {!noValue && altDisplay ? (
        altDisplay(value)
      ) : (
        <Typography
          component="p"
          variant={noValue ? 'body2' : 'body1'}
          color={noValue ? 'textSecondary' : 'textPrimary'}
          style={{ paddingLeft: 6 }}
        >
          {noValue ? 'N / A' : value}
        </Typography>
      )}
    </div>
  );
}
