import { useEffect } from 'react';
import isUsingOneDrive from './isUsingOneDrive.helper';

export default function TolaIcon() {
  useEffect(() => {
    const isUsingOnedrive = isUsingOneDrive();
    if (!isUsingOnedrive) return;
    const iconEl = document.querySelector('link[rel="shortcut icon"]');
    iconEl.href = iconEl.href.replace('favicon', 'favicon_tola');

    document.title = 'STEPS TOLA - System Tech Employee & Project System';
  }, []);

  return null;
}
