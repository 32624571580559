import React from 'react';
import { DropdownButton } from 'components';
import mapReducer from 'helpers/mapReducer.helper';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useColors } from 'helpers/theme.helper';

export default function AreaSelector({ selectedNames, setSelectedNames }) {
  const colors = useColors();
  const allAreas = useSelector(state => state.areas);
  const selectedMap = selectedNames.reduce(mapReducer.set(), {});

  const onCheck = area => () => {
    setSelectedNames(old => {
      const copy = [...old];
      const idx = copy.indexOf(area.name);
      if (idx === -1) copy.push(area.name);
      if (idx !== -1) copy.splice(idx, 1);
      return copy;
    });
  };

  const onBackgroundStyle = {
    borderColor: colors.backgroundText,
    color: colors.backgroundText
  };

  const noSelection = selectedNames.length === 0;

  return (
    <DropdownButton
      ButtonProps={{
        style: onBackgroundStyle,
        variant: 'outlined'
      }}
      PopoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      buttonText={
        'Filter Areas...' + (noSelection ? '' : ` (${selectedNames.length})`)
      }
    >
      <div style={{ padding: '10px 16px' }}>
        <FormControl
          component="fieldset"
          style={{ display: 'block', marginBottom: 6 }}
        >
          <FormLabel component="legend">Selected areas</FormLabel>
          <FormGroup>
            {allAreas.map((area, idx) => (
              <FormControlLabel
                key={area.id}
                control={
                  <Checkbox
                    checked={noSelection || !!selectedMap[area.name]}
                    onChange={onCheck(area)}
                    name={area.name}
                  />
                }
                label={area.name}
              />
            ))}
          </FormGroup>
        </FormControl>
        {!noSelection && (
          <Button
            component="div"
            variant="contained"
            onClick={() => setSelectedNames([])}
            fullWidth
          >
            Clear
          </Button>
        )}
      </div>
    </DropdownButton>
  );
}
