import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { ConfirmDelete, Toast } from 'components';
import { Grid, Button } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { connect } from 'react-redux';
import EditableValue from './EditableValue.component';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const JobLog = props => {
  const {
    employmentLog,
    userId,
    color,
    areas,
    setEmploymentLogs,
    permissionLevels,
    onCancel
  } = props;
  const colors = useColors();
  const currentUser = useCurrentUser();
  const [editing, setEditing] = useState(employmentLog.id === 'NEW');
  const [editedJobLog, setEditedJobLog] = useState({});
  const isTerminationLog = employmentLog.employmentStatus === 'Inactive';

  useEffect(() => {
    setEditedJobLog({
      employmentStatus: employmentLog.employmentStatus || '',
      areaId: employmentLog.areaId || '',
      startDate: employmentLog.startDate || null,
      permissionLevelId: employmentLog.permissionLevelId || '',
      salary: employmentLog.salary ? Number(employmentLog.salary) : 0,
      wage: employmentLog.wage ? Number(employmentLog.wage) : 0,
      compensationType: Number(employmentLog.salary) !== 0 ? 'salary' : 'wage',
      isApproved: employmentLog.isApproved,
      notes: employmentLog.notes || '',
      createdByName: employmentLog.createdByName || '',
      isEligibleToRehire: employmentLog.isEligibleToRehire,
      terminationReason: employmentLog.terminationReason || ''
    });
  }, [employmentLog, editing]);

  const save = e => {
    e.preventDefault();

    const body = {
      employmentStatus: editedJobLog.employmentStatus,
      areaId: editedJobLog.areaId,
      startDate: editedJobLog.startDate,
      wage: editedJobLog.wage,
      salary: editedJobLog.salary,
      permissionLevelId: editedJobLog.permissionLevelId,
      isEligibleToRehire: booleanOrDefault(
        editedJobLog.isEligibleToRehire,
        null
      ),
      terminationReason: editedJobLog.terminationReason || '',
      notes: editedJobLog.notes || undefined
    };

    if (isTerminationLog) {
      delete body.permissionLevelId;
      delete body.area;
    } else {
      delete body.terminationReason;
      delete body.isEligibleToRehire;
    }

    if (employmentLog.id === 'NEW') {
      http()
        .post(`/users/${userId}/employment-logs/`, body)
        .then(res => {
          Toast.show('Created.');
          setEmploymentLogs(res);
          setEditing(false);
        })
        .catch(e => Toast.show(e.message));
    } else {
      http()
        .put(`/users/${userId}/employment-logs/${employmentLog.id}`, body)
        .then(res => {
          Toast.show('Updated.');
          setEmploymentLogs(res);
          setEditing(false);
        })
        .catch(e => Toast.show(e.message));
    }
  };

  const changeApproval = isApproved => {
    http()
      .put(`/users/${userId}/employment-logs/${employmentLog.id}`, {
        isApproved: isApproved
      })
      .then(res => setEmploymentLogs(res))
      .catch(e => Toast.show(e.message));
  };

  const removeLog = () => {
    http()
      .delete(`/users/${userId}/employment-logs/${employmentLog.id}`)
      .then(res => setEmploymentLogs(res))
      .catch(e => Toast.show(e.message));
  };

  return (
    <form
      onSubmit={save}
      style={{
        width: '100%',
        border: `1px solid ${colors.grey}`,
        borderRadius: 8,
        padding: 15,
        marginBottom: 15,
        borderLeft: `5px solid ${color}`,
        position: 'relative'
      }}
    >
      {currentUser.hasPermission('super_admin') && employmentLog.id !== 'NEW' && (
        <div
          style={{
            position: 'absolute',
            right: '6px',
            top: '5px',
            zIndex: '2'
          }}
        >
          <ConfirmDelete onConfirm={removeLog} />
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EditableValue
            onChange={employmentStatus =>
              setEditedJobLog({ ...editedJobLog, employmentStatus })
            }
            label="Employment Status"
            value={editedJobLog.employmentStatus}
            type="dropdown"
            options={[
              { label: 'Full Time', value: 'Full Time' },
              { label: 'Part Time', value: 'Part Time' },
              { label: 'Variable', value: 'Variable' }
            ]}
            required={true}
            editing={!isTerminationLog && editing}
          />
          <EditableValue
            onChange={areaId => setEditedJobLog({ ...editedJobLog, areaId })}
            label="Area"
            value={editedJobLog.areaId}
            type="dropdown"
            options={areas.map(area => ({
              label: area.name,
              value: area.id
            }))}
            required={true}
            editing={editing}
            hidden={isTerminationLog}
          />
          <EditableValue
            onChange={compensationType =>
              setEditedJobLog({
                ...editedJobLog,
                compensationType: compensationType,
                wage: 0,
                salary: 0
              })
            }
            label="Hourly/Salary"
            value={editedJobLog.compensationType}
            type="dropdown"
            options={[
              { label: 'Salary', value: 'salary' },
              { label: 'Hourly', value: 'wage' }
            ]}
            required={true}
            editing={editing}
            hidden={!editing || isTerminationLog}
          />
          <EditableValue
            onChange={wage => setEditedJobLog({ ...editedJobLog, wage })}
            label="Hourly Wage"
            value={editedJobLog.wage}
            type="currency"
            required={true}
            editing={editing}
            hidden={
              editedJobLog.compensationType !== 'wage' || isTerminationLog
            }
          />
          <EditableValue
            onChange={salary => setEditedJobLog({ ...editedJobLog, salary })}
            label="Salary"
            value={editedJobLog.salary}
            type="currency"
            required={true}
            editing={editing}
            hidden={
              editedJobLog.compensationType !== 'salary' || isTerminationLog
            }
          />
          <EditableValue
            onChange={isApproved =>
              setEditedJobLog({ ...editedJobLog, isApproved })
            }
            label="Approved"
            value={editedJobLog.isApproved}
            type="boolean"
            editing={false}
            hidden={!isTerminationLog && editing}
          />
          <EditableValue
            label="Is Eligible To Rehire"
            onChange={isEligibleToRehire =>
              setEditedJobLog({ ...editedJobLog, isEligibleToRehire })
            }
            value={editedJobLog.isEligibleToRehire}
            type="boolean"
            editing={editing}
            hidden={!isTerminationLog}
          />
        </Grid>
        <Grid item xs={6}>
          <EditableValue
            onChange={startDate =>
              setEditedJobLog({ ...editedJobLog, startDate })
            }
            label="Start Date"
            value={editedJobLog.startDate}
            type="date"
            editing={editing}
          />
          <EditableValue
            onChange={permissionLevelId =>
              setEditedJobLog({ ...editedJobLog, permissionLevelId })
            }
            label="Job Title"
            value={editedJobLog.permissionLevelId}
            type="dropdown"
            options={permissionLevels.map(item => ({
              label: item.name,
              value: item.id
            }))}
            required={true}
            hidden={isTerminationLog}
            editing={editing}
          />
          <EditableValue
            onChange={notes => setEditedJobLog({ ...editedJobLog, notes })}
            label="Notes"
            value={editedJobLog.notes}
            editing={editing}
          />
          <EditableValue
            label="Created By"
            value={editedJobLog.createdByName}
            editing={false}
          />
          <EditableValue
            onChange={terminationReason =>
              setEditedJobLog({ ...editedJobLog, terminationReason })
            }
            label="Reason For Termination"
            value={editedJobLog.terminationReason}
            editing={editing}
            hidden={!isTerminationLog}
          />
        </Grid>

        {!editing && employmentLog.isApproved === null && (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditing(true)}
            >
              Revise Details
            </Button>
            {currentUser.hasPermission('super_admin') && (
              <React.Fragment>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 16,
                    backgroundColor: colors.error,
                    color: 'white'
                  }}
                  onClick={() => changeApproval(false)}
                >
                  Decline
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 16,
                    backgroundColor: colors.success,
                    color: 'white'
                  }}
                  onClick={() => changeApproval(true)}
                >
                  Approve
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {editing && (
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setEditing(false);
                if (onCancel) onCancel();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        )}
      </Grid>
    </form>
  );
};

function booleanOrDefault(val, def) {
  if (Boolean(val) === val) return val;
  return def;
}

export default connect(state => ({
  areas: state.areas,
  permissionLevels: state.permissionLevels
}))(JobLog);
