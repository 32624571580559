import React, { useState, useEffect } from 'react';
import {
  SearchBar,
  FormModal,
  VirtualizedSearch,
  MaterialsTable,
  NoStyleLink,
  Toast,
  ConfirmDelete,
  PermissionWrapper
} from 'components';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import { MATERIAL_FORM_FIELDS } from '../helpers/materialFormFields.helper';
import { toMinDecPrecision } from 'helpers/floatStringFormatter.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const hiddenColumns = MaterialsTable.presetHiddenColumns.Master;
const TOP_CONTENT_HEIGHT = 60;

function formatForEditing(material) {
  const copy = { ...material };
  copy.unitCost = toMinDecPrecision(copy.unitCost, 2);
  copy.unitLabor = toMinDecPrecision(copy.unitLabor, 2);
  return copy;
}

function MaterialsNonConflictRoute({
  materials,
  submitMaterial,
  removeMaterial
}) {
  const [loading, setLoading] = useState(false);
  const [editingMaterial, setEditingMaterial] = useState(null);
  const [addMaterialOpen, setAddMaterialOpen] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [search, setSearch] = useState('');
  const user = useCurrentUser();

  useEffect(() => {
    const manufacturers = [];
    const manufacturersSet = {};
    materials.forEach(material => {
      if (manufacturersSet[material.manufacturer]) return;
      manufacturersSet[material.manufacturer] = true;
      manufacturers.push(material.manufacturer);
    });
    setManufacturers(manufacturers);
  }, [materials]);

  const formFields = [
    {
      makeField: ({ stateData, setStateData, loading }) => {
        const setVal = newVal =>
          setStateData(old => ({ ...old, manufacturer: newVal }));
        return (
          <div key="manufacturer">
            <VirtualizedSearch
              label="Manufacturer"
              allItems={manufacturers}
              showInputAsOption
              inputValue={stateData.manufacturer}
              setInputValue={setVal}
              onChange={setVal}
              TextFieldProps={{ variant: 'standard', disabled: loading }}
              autoselectTop
            />
          </div>
        );
      }
    },
    ...MATERIAL_FORM_FIELDS
  ];

  const handleSubmitMatertial = method => updates => {
    setLoading(true);
    const copy = { ...updates };
    if (!copy.unitCost) copy.unitCost = null;
    if (!copy.unitLabor) copy.unitLabor = null;

    const closeModal = () =>
      method === 'put' ? setEditingMaterial(null) : setAddMaterialOpen(false);
    submitMaterial(method, copy)
      .then(closeModal)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const handleRemoveMaterial = () => {
    if (!editingMaterial) return;
    setLoading(true);
    removeMaterial(editingMaterial)
      .then(() => setEditingMaterial(null))
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const canEdit = user.hasPermission('materials_tier_2');
  const onMaterialSelect = canEdit
    ? material => setEditingMaterial(formatForEditing(material))
    : null;

  const removeMaterialButton = (
    <ConfirmDelete onConfirm={handleRemoveMaterial} disabled={loading}>
      <Button variant="contained" disabled={loading}>
        Delete Material
      </Button>
    </ConfirmDelete>
  );

  return (
    <>
      <FormModal
        title="Edit Material"
        onClose={() => setEditingMaterial(null)}
        open={!!editingMaterial}
        onSubmit={handleSubmitMatertial('put')}
        fields={formFields}
        loading={loading}
        data={editingMaterial}
        buttons={removeMaterialButton}
      />
      <FormModal
        title="Add Material"
        onClose={() => setAddMaterialOpen(false)}
        open={!!addMaterialOpen}
        onSubmit={handleSubmitMatertial('post')}
        fields={formFields}
        loading={loading}
      />
      <div
        style={{
          height: TOP_CONTENT_HEIGHT,
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start'
        }}
      >
        <div>
          <Typography variant="h4">Materials</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <SearchBar onSubmit={setSearch} onInput={setSearch} />
          <PermissionWrapper permission="materials_tier_2">
            <div>
              <Button
                style={{ marginLeft: 16 }}
                variant="contained"
                color="secondary"
                onClick={() => setAddMaterialOpen(true)}
              >
                Add Material item
              </Button>
            </div>

            <NoStyleLink to="/materials/conflicts">
              <Button style={{ marginLeft: 16 }} variant="contained">
                Part Number Conflicts
              </Button>
            </NoStyleLink>
          </PermissionWrapper>
        </div>
      </div>
      <div
        style={{ flexGrow: 1, height: `calc(100% - ${TOP_CONTENT_HEIGHT}px)` }}
      >
        <MaterialsTable
          materials={materials}
          onSelect={onMaterialSelect}
          localStorageKey="GlobalMaterials"
          removeColumns={hiddenColumns}
          initialRowsPerPage={100}
          searchText={search}
        />
      </div>
    </>
  );
}

export default MaterialsNonConflictRoute;
