import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import { Toast, CustomInput } from 'components';
import http from 'helpers/http.helper';

function CreateTaskPreset({ skillOptions, open, onClose, onCreate }) {
  const [name, setName] = useState('');
  const [skillId, setSkillId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => setName(''), [open]);

  const createPreset = async e => {
    e.preventDefault();
    setLoading(true);
    http()
      .post('/task-presets', {
        name: name,
        skillId: skillId
      })
      .then(res => {
        onCreate(res);
        onClose();
        setLoading(false);
        Toast.show('Task Preset Created.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={createPreset}>
        <DialogTitle>New Task Preset</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            label="Task Preset Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={e => setName(e.target.value)}
          />
          <CustomInput
            label="employee skill"
            type="dropdown"
            onChange={setSkillId}
            value={skillId}
            options={skillOptions.map(skill => ({
              label: skill.name,
              value: skill.id
            }))}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateTaskPreset;
