import React from 'react';
import { Typography, Link } from 'helpers/themeSafeMui.helper';
import { CustomInput, ExternalLink } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import formatValue from 'helpers/formatValue.helper';
import useBreakpoint from 'hooks/useBreakpoint.hook';

function EditableRow(props) {
  const {
    label,
    editing,
    hidden,
    onLinkClick,
    linkText,
    error = false,
    forceTwoLine = false,
    ContentTypographyProps,
    ...rest
  } = props;
  const isSmallScreen = useBreakpoint('sm');

  if (hidden) return null;

  return (
    <div
      style={{
        display: isSmallScreen || forceTwoLine ? null : 'flex',
        alignItems: 'center',
        marginBottom: 16
      }}
    >
      <div>
        <Typography noWrap color={error && editing ? 'error' : 'inherit'}>
          {props.label}:
        </Typography>
      </div>
      <div style={{ flex: '0 1 100%', paddingLeft: 10 }}>
        {editing ? (
          <CustomInput margin="none" error={error} {...rest} />
        ) : (
          <Typography color="textSecondary" {...ContentTypographyProps}>
            {(() => {
              if (onLinkClick)
                return (
                  <Link style={{ cursor: 'pointer' }} onClick={onLinkClick}>
                    {linkText || 'open'}
                  </Link>
                );
              if (props.type === 'link' && props.value) {
                if (props.internal) {
                  return (
                    <RouterLink to={props.value}>
                      {linkText || 'open'}
                    </RouterLink>
                  );
                }

                return (
                  <ExternalLink href={props.value}>
                    {linkText || 'open'}
                  </ExternalLink>
                );
              }
              return formatValue(props);
            })()}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default EditableRow;
