import React, { useContext } from 'react';
import stable from 'stable';
import { Toast, ExternalLink, NoItemsResult, LastUpdated } from 'components';
import { Paper, Typography, Grid, Divider } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';

const EmployeeHours = ({ noPaper = false }) => {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );

  const syncWithTsheets = setLoading => {
    setLoading(true);
    http({ withLoader: false })
      .put(`/projects/${projectDetails.projectId}/details/tsheets`)
      .then(res => setProjectDetails(res))
      .then(() => Toast.show('Project hours synced with TSheets.'))
      .catch(e => Toast.show(e.message))
      .finally(() => setLoading(false));
  };

  if (!projectDetails.currentLaborHoursByUser) return null;

  return React.createElement(
    noPaper ? React.Fragment : Paper,
    noPaper ? {} : { style: { padding: 16 } },
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Employee Hours
        </Typography>
        <LastUpdated
          lastUpdated={projectDetails.currentLaborHoursLastUpdate}
          onRefresh={syncWithTsheets}
          toolTipValue="Imported from TSheets"
        />
      </div>

      <Divider style={{ marginBottom: 16 }} />

      {!projectDetails.currentLaborHoursByUser.length && (
        <NoItemsResult type="project hours" />
      )}

      <Grid container spacing={2}>
        {stable(
          projectDetails.currentLaborHoursByUser,
          (a, b) => a.name > b.name
        ).map(user => (
          <Grid item xs={6} key={user.email}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 8px'
              }}
            >
              <Typography>
                <ExternalLink title={user.email} href={`mailto:${user.email}`}>
                  {user.name}
                </ExternalLink>
              </Typography>
              <Typography>{user.hours} hrs</Typography>
            </div>

            <Divider style={{ marginTop: 16 }} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default EmployeeHours;
