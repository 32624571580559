import React, { useContext } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import ScheduleTableRowProject from './ScheduleTableRowProject.component';
import ScheduleTableRowTasks from './ScheduleTableRowTasks.component';
import ScheduleTableCell from './ScheduleTableCell.component';
import { useColors } from 'helpers/theme.helper';
import calculateTechDays from '../helpers/calculateTechDays.helper';
import { Link } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ScheduleTableHeader from './ScheduleTableHeader.component';
import ScheduleTableLoadingCanvas from './ScheduleTableLoadingCanvas.component';
import getScheduleData from '../helpers/getScheduleData.helper';
import overlap from '../helpers/overlap.helper';

const StickyContext = React.createContext();

const cellWidth = 50;
const cellHeight = 50;
const headerHeight = 40;
const perDayRowHeight = 30;
const projectColumnWidth = 120;
const teamColumnWidth = 140;
const firstColumnWidth = projectColumnWidth + teamColumnWidth;

const sortByKeys = {
  spm: 'seniorProjectManagerName',
  pm: 'projectManagerName',
  lt: 'leadTechnicianName'
};

const ScheduleTable = ({
  height,
  startDay,
  days,
  allProjects,
  allTasks,
  filteredProjects = allProjects,
  filteredTasks = allTasks,
  confirmedOnly,
  sortBy = 'spm'
}) => {
  const colors = useColors();

  const scheduleDataFiltered = getScheduleData({
    tasks: filteredTasks,
    projects: filteredProjects,
    startDay
  }).filter(filterOffSchedule(days));

  const numConfirmedProjects = scheduleDataFiltered.reduce(
    (count, dataItem) =>
      dataItem.project.scheduleType !== 'potential' ? count + 1 : count,
    0
  );
  const numPotentialProjects =
    scheduleDataFiltered.length - numConfirmedProjects;

  const confirmedDayTotals = calculateTechDays({
    scheduleData: scheduleDataFiltered.filter(
      dataItem => dataItem.project.scheduleType !== 'potential'
    ),
    startDay,
    days
  });

  const potentialDayTotals = calculateTechDays({
    scheduleData: scheduleDataFiltered.filter(
      dataItem => dataItem.project.scheduleType === 'potential'
    ),
    startDay,
    days
  });

  const header = (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        top: 0,
        height: headerHeight,
        width: firstColumnWidth + days * cellWidth,
        zIndex: 1000
      }}
    >
      <ScheduleTableHeader
        startDay={startDay}
        firstColumnWidth={firstColumnWidth}
        colors={colors}
        days={days}
        height={headerHeight}
        cellWidth={cellWidth}
        firstCell={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography component="div" variant="caption">
              Project Count:
            </Typography>
            <Typography component="div" variant="caption">
              {confirmedOnly
                ? numConfirmedProjects
                : numConfirmedProjects + numPotentialProjects}
            </Typography>
          </div>
        }
      >
        {(idx, m) => m.format('ddd') + '\n' + m.format('M/D')}
      </ScheduleTableHeader>
    </div>
  );

  const dailyTotalRow = (totals, title, style, top = headerHeight) => (
    <div
      style={{
        display: 'flex',
        position: 'sticky',
        top,
        height: perDayRowHeight,
        width: firstColumnWidth + days * cellWidth,
        zIndex: 1000
      }}
    >
      <ScheduleTableHeader
        startDay={startDay}
        firstColumnWidth={firstColumnWidth}
        colors={colors}
        days={days}
        cellWidth={cellWidth}
        height={perDayRowHeight}
        firstRow={false}
        style={style}
        firstCell={
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              padding: 3
            }}
          >
            {title}
          </div>
        }
      >
        {idx => totals[idx]}
      </ScheduleTableHeader>
    </div>
  );

  const potentialDayTotalsRow = dailyTotalRow(
    potentialDayTotals,
    'Potential / Day',
    { backgroundColor: colors.paperYellow }
  );
  const confirmedDayTotalsRow = dailyTotalRow(
    confirmedDayTotals,
    'Total / Day',
    { backgroundColor: colors.background },
    headerHeight + (confirmedOnly ? 0 : perDayRowHeight)
  );

  const topOffset = headerHeight + perDayRowHeight * (confirmedOnly ? 1 : 2);
  const boldBottomIdxs = findBoldBottomIdxs(
    scheduleDataFiltered.map(data => data.project),
    sortBy
  );

  return (
    <div
      style={{
        position: 'relative',
        height,
        overflow: 'auto'
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <StickyContext.Provider
            value={{
              contentHeight: scheduleDataFiltered.length * cellHeight,
              topOffset,
              header: (
                <>
                  {header}
                  {!confirmedOnly && potentialDayTotalsRow}
                  {confirmedDayTotalsRow}
                </>
              ),
              rowDimensions: {
                rowWidth: firstColumnWidth + cellWidth * days,
                firstWidth: firstColumnWidth,
                cellWidth,
                cellHeight
              }
            }}
          >
            <List
              itemCount={scheduleDataFiltered.length}
              itemSize={50}
              height={height}
              width={width}
              innerElementType={stickyRenderer}
            >
              {({ index: idx, style }) => {
                const { project, tasks, type } = scheduleDataFiltered[idx];
                const boldBorderBottom = !!boldBottomIdxs[idx];
                const firstCell = [
                  <div
                    key="project-cell"
                    style={{
                      backgroundColor: colors.paper,
                      position: 'sticky',
                      display: 'flex',
                      zIndex: 1,
                      left: 0,
                      flexGrow: 0,
                      flexShrink: 0,
                      flexBasis: firstColumnWidth,
                      width: firstColumnWidth
                    }}
                  >
                    <ProjectLinkCell
                      boldBorderBottom={boldBorderBottom}
                      project={project}
                    />
                    <TeamCell
                      key="team-cell"
                      boldBorderBottom={boldBorderBottom}
                      project={project}
                      sortBy={sortBy}
                    />
                  </div>
                ];
                return (
                  <div
                    key={project.id}
                    style={{
                      ...style,
                      top: `${parseFloat(style.top) + topOffset}px`
                    }}
                  >
                    {type === 'project' && (
                      <ScheduleTableRowProject
                        rowIdx={idx}
                        project={project}
                        startDay={startDay}
                        colCount={days}
                        cellWidth={cellWidth}
                        firstCell={firstCell}
                        firstCellWidth={firstColumnWidth}
                        boldBorderBottom={boldBorderBottom}
                      />
                    )}
                    {type === 'tasks' && (
                      <ScheduleTableRowTasks
                        tasks={tasks}
                        rowIdx={idx}
                        project={project}
                        startDay={startDay}
                        colCount={days}
                        cellWidth={cellWidth}
                        firstCell={firstCell}
                        firstCellWidth={firstColumnWidth}
                        boldBorderBottom={boldBorderBottom}
                      />
                    )}
                  </div>
                );
              }}
            </List>
          </StickyContext.Provider>
        )}
      </AutoSizer>
    </div>
  );
};

const stickyRenderer = React.forwardRef(({ children, style, ...rest }, ref) => {
  const { contentHeight, rowDimensions, topOffset, header } = useContext(
    StickyContext
  );
  const { paper, grey } = useColors();
  return (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height) + topOffset}px`
      }}
      {...rest}
    >
      {children}
      {header}
      <ScheduleTableLoadingCanvas
        backgroundColor={paper}
        color={grey}
        height={contentHeight}
        rowDimensions={rowDimensions}
      />
    </div>
  );
});

function ProjectLinkCell({ project, boldBorderBottom }) {
  const colors = useColors();
  return (
    <Link
      style={{
        display: 'block',
        backgroundColor: colors.paper,
        zIndex: 1,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: projectColumnWidth,
        width: projectColumnWidth,
        overflow: 'hidden'
      }}
      to={`/projects/${project.id}/schedule`}
    >
      <ScheduleTableCell
        boldBorderBottom={boldBorderBottom}
        firstCol
        style={{
          padding: 3
        }}
      >
        <Typography variant="body1">{project.projectNumber}</Typography>
        <Typography
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: projectColumnWidth - 6
          }}
          variant="caption"
        >
          {project.name}
        </Typography>
      </ScheduleTableCell>
    </Link>
  );
}

function TeamCell({ project, boldBorderBottom, sortBy = '' }) {
  const colors = useColors();
  const labelWidth = 31;
  const cellPad = 2;
  const sortByTitle = sortBy.toUpperCase() + ':';
  const userDisplay = (title, name) =>
    !!name && (
      <div
        style={{
          lineHeight: 1,
          fontWeight: title === sortByTitle ? 600 : null
        }}
      >
        <Typography
          style={{
            display: 'inline-block',
            fontWeight: 'inherit',
            width: labelWidth,
            textAlign: 'right',
            verticalAlign: 'bottom',
            paddingLeft: 3,
            lineHeight: 1
          }}
          variant="caption"
        >
          {title}
        </Typography>

        <Typography
          style={{
            display: 'inline-block',
            fontWeight: 'inherit',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: teamColumnWidth - (labelWidth + cellPad * 2 + 3),
            paddingLeft: 3,
            verticalAlign: 'bottom',
            lineHeight: 1
          }}
          variant="caption"
        >
          {name}
        </Typography>
      </div>
    );
  return (
    <div
      style={{
        display: 'block',
        color: 'unset',
        backgroundColor: colors.paper,
        // position: 'sticky',
        zIndex: 1,
        left: 0,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: teamColumnWidth,
        width: teamColumnWidth,
        overflow: 'hidden'
      }}
    >
      <ScheduleTableCell
        boldBorderBottom={boldBorderBottom}
        style={{
          padding: cellPad,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingBottom: 1
        }}
      >
        {userDisplay('SPM:', project.seniorProjectManagerName)}
        {userDisplay('PM:', project.projectManagerName)}
        {userDisplay('LT:', project.leadTechnicianName)}
      </ScheduleTableCell>
    </div>
  );
}

function findBoldBottomIdxs(projects, sortBy) {
  const nameKey = sortByKeys[sortBy];
  const boldBottomIdxs = {};
  for (let i = 0; i < projects.length - 1; i++) {
    const thisProject = projects[i];
    const thisVal = thisProject[nameKey];
    const nextProject = projects[i + 1];
    const nextVal = nextProject[nameKey];
    if (nextVal !== thisVal) boldBottomIdxs[i] = true;
  }
  return boldBottomIdxs;
}

function filterOffSchedule(columns) {
  return scheduleDataItem => {
    if (scheduleDataItem.type === 'tasks') {
      const anyOnSchedule = scheduleDataItem.indexes.some(([start, end]) =>
        overlap(start, end, 0, columns)
      );
      if (!anyOnSchedule) return false;

      const keepAtIdx = scheduleDataItem.indexes.map(([start, end]) =>
        overlap(start, end, 0, columns)
      );
      // remove off-schedule tasks
      scheduleDataItem.tasks = scheduleDataItem.tasks.filter(
        (i, idx) => keepAtIdx[idx]
      );
      scheduleDataItem.indexes = scheduleDataItem.indexes.filter(
        (i, idx) => keepAtIdx[idx]
      );

      return true;
    } else if (overlap(...scheduleDataItem.indexes, 0, columns)) {
      return true;
    } else {
      return false;
    }
  };
}

export default ScheduleTable;
