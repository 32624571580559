import React from 'react';
import { TextField, InputAdornment } from 'helpers/themeSafeMui.helper';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
  search: {
    margin: 0,
    width: 300,
    background: 'white'
  }
};

class SearchBar extends React.Component {
  state = {
    search: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.onInput) this.props.onInput(e.target.value);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.search);
  };

  render() {
    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <TextField
          variant="outlined"
          placeholder="Search"
          disabled={!!this.props.disabled}
          value={this.state.search}
          onChange={this.handleChange}
          name="search"
          margin="dense"
          style={styles.search}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={this.handleSubmit}
                style={{ cursor: 'pointer' }}
              >
                <SearchIcon color="action" />
              </InputAdornment>
            )
          }}
        />
      </form>
    );
  }
}

export default SearchBar;
