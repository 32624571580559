import {
  makeStyles,
  Switch as MuiSwitch,
  Typography,
  TypographyProps
} from 'helpers/themeSafeMui.helper';
import React from 'react';

type Info = { label: string; value: any };
type LabelOrInfo = string | Info;

interface SwitchProps {
  left: LabelOrInfo;
  right: LabelOrInfo;
  isLeft: boolean;
  id: string;
  onChange?: (isLeftOrValue: any) => void;
  TypographyProps?: TypographyProps<React.ElementType<any>>;
  label?: string;
  LabelTypographyProps?: TypographyProps<React.ElementType<any>>;
}

const useStyles = makeStyles(theme => ({
  track: { backgroundColor: theme.palette.secondary.main },
  switchBase: { color: theme.palette.secondary.main }
}));

const Switch: React.FC<SwitchProps> = ({
  left,
  right,
  isLeft,
  id,
  label,
  onChange = () => {},
  TypographyProps = {},
  LabelTypographyProps = {}
}) => {
  const classes = useStyles();

  const handleChange = () => {
    const getValue = (side: LabelOrInfo) =>
      typeof side === 'string' ? !isLeft : side.value;
    if (isLeft) {
      onChange(getValue(right));
    } else {
      onChange(getValue(left));
    }
  };

  const getSideLabel = (side: LabelOrInfo) =>
    typeof side === 'string' ? side : side.value;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingTop: !!label ? 10 : 0
      }}
    >
      {label && (
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            ...LabelTypographyProps.style,
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            left: 0
          }}
          {...LabelTypographyProps}
          htmlFor={id}
          component="label"
        >
          {label}
        </Typography>
      )}
      <div style={{ flexGrow: 1, textAlign: 'right' }}>
        <Typography
          {...TypographyProps}
          style={{ ...TypographyProps.style, cursor: 'pointer' }}
          htmlFor={id}
          component="label"
          color={isLeft ? 'primary' : undefined}
        >
          {getSideLabel(left)}
        </Typography>
      </div>
      <div style={{ padding: '0 5px' }}>
        <MuiSwitch
          id={id}
          checked={!isLeft}
          onChange={handleChange}
          classes={{
            track: classes.track,
            switchBase: classes.switchBase,
            checked: classes.switchBase
          }}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <Typography
          {...TypographyProps}
          style={{ ...TypographyProps.style, cursor: 'pointer' }}
          htmlFor={id}
          component="label"
          color={!isLeft ? 'primary' : undefined}
        >
          {getSideLabel(right)}
        </Typography>
      </div>
    </div>
  );
};

export default Switch;
