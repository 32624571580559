import React from 'react';
import { CustomInput } from 'components';
import { ListItem, ListItemText, Divider } from 'helpers/themeSafeMui.helper';
import formatValue from 'helpers/formatValue.helper';

const EditableValue = ({ editing, ...rest }) => {
  if (rest.hidden) return null;

  return editing ? (
    <CustomInput {...rest} />
  ) : (
    <React.Fragment>
      <ListItem>
        <ListItemText primary={rest.label} secondary={formatValue(rest)} />
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

export default EditableValue;
