import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Paper,
  TableCell,
  TableRow,
  IconButton,
  TextField
} from 'helpers/themeSafeMui.helper';
import { NoItemsResult, DndTable, BetterBlur } from 'components';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { EstimateContext } from 'routes/Projects/context/Estimate.context';
import {
  calculateMaterialCosts,
  getDefaultMaterialView,
  phaseMaterialPropertiesToString
} from '../../helpers/phaseMaterial.helper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SetVisibleColumnsModal from '../../modals/SetVisibleColumns.modal';
import { useColors } from 'helpers/theme.helper';

export default function MaterialsEstimateTable(props) {
  const { phase, onSave, onDelete } = props;
  const phaseMaterials = phase.materials;
  const defaultMaterialView = getDefaultMaterialView();

  const [materialView, setMaterialView] = useLocalStorage(
    'phase_material_view',
    defaultMaterialView
  );
  const [modalOpen, setModalOpen] = useState(false);

  const viewProperties = materialView
    .filter(([, open]) => open)
    .map(([name]) => name);

  const { estimate } = useContext(EstimateContext);
  const phaseMaterialDetails = phaseMaterials.map(mat => ({
    quantity: mat.quantity,
    description: mat.description,
    partNumber: mat.partNumber,
    unitCost: mat.unitCost,
    unitLabor: mat.unitLabor,
    ...calculateMaterialCosts({ material: mat, estimateDetails: estimate })
  }));

  const colors = useColors();

  const materialsWithFormattedDetails = phaseMaterialDetails.map(
    phaseMaterialPropertiesToString
  );

  const updateProp = (idx, prop) => newValue => {
    const copy = JSON.parse(JSON.stringify(phase));
    copy.materials[idx][prop] = newValue;
    onSave(copy);
  };

  const onDrop = newMaterials => {
    onSave({ ...phase, materials: newMaterials });
  };

  return (
    <Paper style={{ overflowY: 'auto' }}>
      <SetVisibleColumnsModal
        visibleColumns={materialView}
        setVisibleColumns={setMaterialView}
        alwaysOn={['description']}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      <DndTable.Table onDrop={onDrop}>
        <DndTable.TableHead
          style={{
            background: colors.background
          }}
          dragIndicatorCell={
            <TableCell>
              <IconButton
                style={{ color: colors.backgroundText }}
                size="small"
                onClick={() => setModalOpen(true)}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </TableCell>
          }
        >
          {viewProperties.map(viewProperty => (
            <TableCell
              key={viewProperty}
              style={{ color: colors.backgroundText, whiteSpace: 'nowrap' }}
            >
              {unCamelCase(viewProperty)}
            </TableCell>
          ))}
          <TableCell>{/* trash icon placeholder */}</TableCell>
        </DndTable.TableHead>

        {!phaseMaterials.length ? (
          <TableRow>
            <TableCell
              colSpan={viewProperties.length > 6 ? 6 : viewProperties.length}
            >
              <NoItemsResult type="Materials" style={{ padding: 50 }} />
            </TableCell>
            {viewProperties.length > 6 && (
              // If header column count > 6 user has to scroll to see no items result
              // add empty cells to move it over
              <TableCell colSpan={viewProperties.length - 4}></TableCell>
            )}
          </TableRow>
        ) : (
          materialsWithFormattedDetails.map((material, idx) => (
            <DndTable.TableRow
              key={phaseMaterials[idx].id}
              id={phaseMaterials[idx].id}
              value={phaseMaterials[idx]}
            >
              {viewProperties.map(materialName =>
                ['quantity', 'unitCost', 'unitLabor'].includes(materialName) ? (
                  <EditableCell
                    key={materialName}
                    value={phaseMaterials[idx][materialName]}
                    displayValue={material[materialName]}
                    onUpdate={updateProp(idx, materialName)}
                  />
                ) : (
                  <TableCell
                    key={materialName}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {material[materialName]}
                  </TableCell>
                )
              )}
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() => onDelete(phaseMaterials[idx].id)}
                >
                  <DeleteIcon color="action" fontSize="small" />
                </IconButton>
              </TableCell>
            </DndTable.TableRow>
          ))
        )}
      </DndTable.Table>
    </Paper>
  );
}

function EditableCell({ value: givenValue, displayValue, onUpdate }) {
  const [value, setValue] = useState(givenValue);
  const handleInput = e => setValue(e.target.value);
  const user = useSelector(state => state.user);
  const canEdit = user.hasPermission('testing');

  const cellRef = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(givenValue);
  }, [givenValue]);

  const onCancel = () => {
    setValue(givenValue);
    setOpen(false);
  };

  const onSave = () => {
    onUpdate(value);
    setOpen(false);
  };

  const selectAll = e => {
    e.target.setSelectionRange(0, -1);
  };

  return (
    <BetterBlur
      rootRef={cellRef}
      onFocus={() => setOpen(true)}
      onBlur={onCancel}
      focused={open}
    >
      <TableCell
        innerRef={cellRef}
        style={{
          cursor: canEdit ? (open ? null : 'pointer') : null
        }}
      >
        <div style={{ display: 'flex' }}>
          {open && canEdit ? (
            <form onSubmit={onSave} style={{ display: 'flex' }}>
              <TextField
                style={{ minWidth: 150 }}
                autoFocus
                value={value}
                onInput={handleInput}
                onFocus={selectAll}
              />
              <IconButton size="small" onClick={onSave}>
                <SaveIcon />
              </IconButton>
              <IconButton size="small" onClick={onCancel}>
                <CancelIcon />
              </IconButton>
            </form>
          ) : (
            <>
              {displayValue}
              {canEdit && (
                <EditIcon
                  fontSize="small"
                  color="action"
                  style={{ marginLeft: 25 }}
                />
              )}
            </>
          )}
        </div>
      </TableCell>
    </BetterBlur>
  );
}

function unCamelCase(string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
}
