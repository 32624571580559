import React from 'react';
import { Dialog, Typography, Link } from 'helpers/themeSafeMui.helper';
import { ExternalLink } from 'components';

const IFrameModal = ({ open, onClose, url }) => {
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <div
        style={{
          height: 'calc(100vh - 96px)',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <div>
          <Typography
            variant="body2"
            style={{
              padding: 8,
              width: '100%',
              textAlign: 'center'
            }}
          >
            <ExternalLink href={url}>Open in a new tab</ExternalLink> |{' '}
            <Link style={{ cursor: 'pointer' }} onClick={onClose}>
              Close
            </Link>
          </Typography>
        </div>
        <iframe
          src={url}
          title={url}
          style={{
            width: '100%',
            border: 'none',
            height: '100%'
          }}
        />
      </div>
    </Dialog>
  );
};

export default IFrameModal;
