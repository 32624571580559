import React, { useContext, useEffect, useState } from 'react';
import { CustomInput, Toast, VirtualizedSearch } from 'components';
import { poKeyLabelMap } from '../helpers/poFields.helper';
import { Typography } from '@material-ui/core';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import useFreshFn from 'hooks/useFreshFn.hook';
import { SuppliersContext } from '../PurchaseOrders.tab';
import http from 'helpers/http.helper';
import mapReducer from 'helpers/mapReducer.helper';

export default function PoFields({ poData, setPoData, shipToOptions }) {
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [attnOptions, setAttnOptions] = useState([]);
  const [toSearchInputValue, setToSearchInputValue] = useState('');
  const [attnSearchInputValue, setAttnSearchInputValue] = useState('');
  const { project } = useContext(ProjectContext);
  const suppliers = useContext(SuppliersContext);
  const [shipToOther, setShipToOther] = useState(false);
  const projectAddress = (project && project.address) || '';
  const isWillCall = poData.shipToAddress === 'Hold for Will Call';
  const isShipToJob = poData.shipToAddress === projectAddress;
  const canEditPhone = shipToOther || isWillCall || isShipToJob;

  const setTo = useFreshFn(supplier => {
    if (!supplier) supplier = { id: null, name: '' };
    setPoData(old => ({
      ...old,
      to: supplier.name,
      supplierId: supplier.id,
      attn: ''
    }));
    setSelectedSupplierId(supplier.id);
  });

  const shipToByAddress = (shipToOptions || []).reduce(mapReducer('address'), {
    [projectAddress]: { address: projectAddress, phoneNumber: '', title: '' }
  });

  const shipToDropdownOptions = (shipToOptions || []).map(option => ({
    value: option.address,
    label: option.title
  }));

  const setShipToAddress = shipTo => {
    setShipToOther(shipTo === 'Other');
    setPoData(old => {
      const shipToOption =
        shipToByAddress[shipTo] || defaultOptions[shipTo] || {};
      const shipToTitle = shipToOption.title || '';
      const shipToAddress = shipToOption.address || '';
      const shipToContactPhone = shipToOption.phoneNumber || '';
      return { ...old, shipTo: shipToTitle, shipToAddress, shipToContactPhone };
    });
  };

  const getAttn = useFreshFn(() => poData.attn);
  const setAttn = useFreshFn(person => {
    const personEmail = person
      ? person.emails && person.emails[0] && person.emails[0].email
      : '';
    const emailTo = personEmail || '';
    const attn = person ? person.name : '';
    setPoData(old => {
      return { ...old, attn, emailTo };
    });
  });

  useEffect(() => {
    if (!selectedSupplierId) return;
    let stale = false;
    http()
      .get('/people', { params: { companyId: selectedSupplierId } })
      .then(res => {
        if (stale) return;
        const active = res.filter(person => !person.inactive);
        const firstOption = active[0] || null;
        const attn = getAttn();
        if (!attn) {
          setAttn(firstOption);
          setAttnSearchInputValue(firstOption ? firstOption.name : '');
        }
        setAttnOptions(active);
      })
      .catch(err => Toast.show(err.message));
    return () => (stale = true);
  }, [selectedSupplierId, setPoData, setAttn, getAttn]);

  useEffect(() => {
    if (poData.to) setToSearchInputValue(poData.to);
    if (poData.attn) setAttnSearchInputValue(poData.attn);
    if (poData.supplierId) setSelectedSupplierId(poData.supplierId);
  }, [poData]);

  return (
    <>
      <Typography
        style={{ lineHeight: 1, marginBottom: 8 }}
        color="textSecondary"
        component="div"
        variant="overline"
      >
        PO Number:
      </Typography>
      <Typography style={{ marginLeft: 21 }} variant="h5">
        {poData.poNumber}
      </Typography>
      <VirtualizedSearch
        label="To *"
        allItems={suppliers}
        getItemText={item => item.name}
        inputValue={toSearchInputValue}
        setInputValue={setToSearchInputValue}
        onChange={setTo}
        TextFieldProps={{ variant: 'standard', margin: 'normal' }}
        autoselectTop
      />
      <VirtualizedSearch
        label="Attn"
        allItems={attnOptions}
        getItemText={item => item.name}
        inputValue={attnSearchInputValue}
        setInputValue={setAttnSearchInputValue}
        onChange={setAttn}
        TextFieldProps={{
          variant: 'standard',
          disabled: !selectedSupplierId,
          margin: 'normal',
          style: { marginBottom: 2 }
        }}
        autoselectTop
      />
      {!!poData.emailTo && (
        <Typography
          style={{ marginLeft: 5 }}
          variant="caption"
          color="textSecondary"
        >
          - {poData.emailTo}
        </Typography>
      )}
      {['quoteNumber', 'from'].map(dataKey => (
        <PoField
          key={dataKey}
          label={poKeyLabelMap[dataKey]}
          dataKey={dataKey}
          data={poData}
          setData={setPoData}
        />
      ))}
      <CustomInput
        required
        label="Shipping"
        type="dropdown"
        value={poData.shipping}
        options={shippingOptions}
        onChange={shipping => setPoData(old => ({ ...old, shipping }))}
      />
      <CustomInput
        required
        label="Ship To"
        type="dropdown"
        value={shipToOther ? 'Other' : poData.shipToAddress}
        options={[
          { value: projectAddress, label: 'Project Site Address' },
          ...shipToDropdownOptions,
          { value: willCall, label: 'Will Call' },
          { value: 'Other', label: 'Other' }
        ]}
        onChange={setShipToAddress}
      />
      {!!shipToOther && (
        <CustomInput
          required
          label="Ship To Address"
          type="address"
          value={poData.shipToAddress}
          onChange={shipToAddress =>
            setPoData(old => ({ ...old, shipToAddress }))
          }
        />
      )}
      <PoField
        label={poKeyLabelMap.shipToContactPhone}
        dataKey="shipToContactPhone"
        data={poData}
        setData={setPoData}
        disabled={!canEditPhone}
      />
      <PoField
        label={poKeyLabelMap.notes}
        dataKey="notes"
        data={poData}
        setData={setPoData}
        multiline
      />
    </>
  );
}

function PoField({ label, dataKey, data, setData, ...rest }) {
  const handleChange = val => setData(old => ({ ...old, [dataKey]: val }));

  return (
    <CustomInput
      label={label}
      value={data[dataKey]}
      onChange={handleChange}
      {...rest}
    />
  );
}

const willCall = 'Hold for Will Call';

const defaultOptions = {
  [willCall]: {
    address: willCall,
    phoneNumber: '',
    title: ''
  },
  Other: {
    address: '',
    phoneNumber: '',
    title: ''
  }
};

const shippingOptions = [
  { label: 'Best Way', value: 'Best Way' },
  { label: 'Deliver - Jobsite', value: 'Deliver - Jobsite' },
  { label: 'Deliver - Shop', value: 'Deliver - Shop' },
  { label: 'Ground', value: 'Ground' },
  { label: 'Next Day', value: 'Next Day' },
  { label: '2 Day', value: '2 Day' },
  { label: '3 Day', value: '3 Day' },
  { label: 'Freight', value: 'Freight' },
  { label: 'Per Quoted Amount', value: 'Per Quoted Amount' }
];
