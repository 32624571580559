export default function getStatusColor(status) {
  switch (status) {
    case 'Pending':
      return 'yellow';
    case 'Approved':
      return 'success';
    case 'Rejected':
      return 'error';
    case 'Paid':
      return 'paperText';
    default:
      return null;
  }
}
