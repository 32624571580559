import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Grid,
  styled,
  Paper
} from 'helpers/themeSafeMui.helper';
import { MaterialCard, NoItemsResult, Toast } from 'components';
import { Button } from '@material-ui/core';
import http from 'helpers/http.helper';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import { useColors } from 'helpers/theme.helper';

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function MaterialsReplacedByModal({ open, onClose, material }) {
  const [materials, setMaterials] = useState([]);
  const isSmallScreen = useBreakpoint('md');
  const colors = useColors();

  const materialId = material ? material.id : null;

  useEffect(() => {
    if (materialId) {
      setMaterials([]);
      http()
        .get(`/materials/${materialId}/replaced-by`)
        .then(setMaterials)
        .catch(Toast.showErr);
    }
  }, [materialId]);

  const height = isSmallScreen ? undefined : '100%';

  return (
    <FullHeightDialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Projects With Material</DialogTitle>
      <Divider />

      <DialogContent style={{ padding: 0 }}>
        <Grid container spacing={0} style={{ height }}>
          <Grid item md={12} lg={4}>
            <MaterialCardWrapper
              material={material}
              header={
                <>
                  <Typography
                    variant="h5"
                    style={{ textAlign: 'center', paddingTop: 5 }}
                  >
                    MATERIAL-A
                  </Typography>
                  <Divider />
                </>
              }
            />
          </Grid>

          <Grid
            item
            md={12}
            lg={8}
            container
            spacing={0}
            style={{
              height,
              overflowY: 'auto',
              borderLeft: isSmallScreen
                ? undefined
                : `1px solid ${colors.divider}`
            }}
          >
            <div style={{ width: '100%' }}>
              {materials.length ? (
                <Grid container spacing={0}>
                  <Grid
                    item
                    md={12}
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: colors.paper
                    }}
                  >
                    {isSmallScreen && <Divider />}
                    <Typography
                      variant="h6"
                      style={{ textAlign: 'center', padding: 5 }}
                    >
                      Materials replaced by MATERIAL-A
                    </Typography>
                    <Divider />
                  </Grid>
                  {materials.map(material => (
                    <Grid key={material.id} item md={12} lg={6}>
                      <MaterialCardWrapper material={material} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <NoItemsResult type="Materials replaced by MATERIAL-A" />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </FullHeightDialog>
  );
}

function MaterialCardWrapper({ material, header }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        flex: '0 0 auto'
      }}
    >
      <Paper elevation={2}>
        {header}
        <MaterialCard
          style={{ width: 300 }}
          material={material}
          hiddienFields={['quantity']}
        />
      </Paper>
    </div>
  );
}
