import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText
} from 'helpers/themeSafeMui.helper';

const allStatuses = ['Pending', 'Approved', 'Rejected', 'Paid'];

const StatusFilter = ({
  allPerDiems,
  setFiltered,
  setActiveCount,
  defaultStatuses = ['Pending']
}) => {
  const [statuses, setStatuses] = useState(defaultStatuses);

  useEffect(() => {
    setFiltered(
      allPerDiems.filter(perDiem => statuses.includes(perDiem.status))
    );
    setActiveCount(statuses.length);
  }, [allPerDiems, setFiltered, setActiveCount, statuses]);

  const handleClickStatus = status => () =>
    setStatuses(old =>
      old.includes(status)
        ? old.filter(other => other !== status)
        : [status, ...old]
    );

  return (
    <List>
      {allStatuses.map(status => (
        <ListItem key={status} onClick={handleClickStatus(status)} button dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={statuses.includes(status)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={status} />
        </ListItem>
      ))}
    </List>
  );
};

export default StatusFilter;
