import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { showModal } from 'ducks/modal.duck';
import { connect } from 'react-redux';
import moment from 'moment';
import { DashboardWrapper, Toast, NoItemsResult, ActionItem } from 'components';
import {
  Typography,
  Paper,
  IconButton,
  Divider,
  TextField
} from 'helpers/themeSafeMui.helper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import NewIcon from '@material-ui/icons/AddCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { useQueryParams } from 'hooks/useQueryParams.hook';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import qs from 'query-string';
import safeParse from 'helpers/safeParse.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';
import UseMarkAllComplete from './components/MarkAllComplete.component';
import { useSetActionItems } from 'state/actionItems.state';

const styles = {
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 10
  }
};

const ActionItems = props => {
  const [visibleActionItems, setVisibleActionItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(true);
  const [count, setCount] = useState(null);
  const [isTola] = useState(isUsingOneDrive);
  const currentUser = useCurrentUser();
  const [state, _setState] = useState({
    indexEnd: 10,
    users: [],
    sortBy: 'VIEW INCOMPLETE'
  });

  const pageSize = 25;

  const setState = updates =>
    _setState(current => ({ ...current, ...updates }));

  const setActionItems = useSetActionItems();

  const [params, setParams] = useQueryParams({
    completed: false,
    userId: props.user.id,
    sortBy: 'VIEW INCOMPLETE',
    limit: pageSize,
    offset: 0
  });

  useEffect(() => {
    http()
      .get('/users')
      .then(res => {
        setState({ users: res });
      })
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    const {
      limit = null,
      offset = null,
      userId = null,
      sortBy = null
    } = params;
    if ([limit, offset, userId, sortBy].some(p => p === null)) {
      setParams(
        {
          sortBy: 'VIEW INCOMPLETE',
          userId: props.user.id,
          limit: pageSize,
          offset: 0,
          completed: false
        },
        true
      );
    } else if ([limit, offset, userId, sortBy].every(p => p !== null)) {
      setVisibleActionItems([]);
      setLoading(true);
      setCount(null);
      http()
        .get(
          qs.stringifyUrl({
            url: '/action-items/query',
            query: {
              ...params,
              sortBy: undefined
            }
          })
        )
        .then(({ actionItems, pagination }) => {
          setLastPage(pagination.lastPage);
          setCount(pagination.count);
          setVisibleActionItems(actionItems.map(item => item.id));
          setLoading(false);
          setActionItems(actionItems);
        })
        .catch(err => Toast.show(err.message));
    }
  }, [params, props.user.id, setActionItems, setParams]);

  const onUserChange = user => {
    setState({ sortBy: 'VIEW INCOMPLETE' });
    setLastPage(true);
    setParams({
      userId: user.id,
      limit: pageSize,
      offset: 0,
      completed: false,
      sortBy: 'VIEW INCOMPLETE'
    });
  };

  const onSortByChange = newSortBy => {
    if (!newSortBy || newSortBy === params.sortBy) {
      return;
    }
    if (!toggleOptions.includes(newSortBy)) newSortBy = 'VIEW INCOMPLETE';
    setState({ sortBy: newSortBy });
    const defaultSettings = {
      sortBy: newSortBy,
      userId: params.userId ? params.userId : props.user.id,
      limit: pageSize,
      offset: 0,
      completed: false
    };
    switch (newSortBy) {
      case 'VIEW INCOMPLETE':
        return setParams({
          ...defaultSettings
        });
      case 'VIEW COMPLETE':
        return setParams({
          ...defaultSettings,
          completed: true
        });
      case 'VIEW OVERDUE':
        return setParams({
          ...defaultSettings,
          before: moment()
            .utc()
            .format()
        });
      case 'VIEW DUE TODAY':
        return setParams({
          ...defaultSettings,
          after: moment()
            .startOf('day')
            .utc()
            .format(),
          before: moment()
            .endOf('day')
            .utc()
            .format(),
          completed: undefined
        });
      case 'VIEW ALL':
        return setParams({
          ...defaultSettings,
          completed: undefined
        });
      default:
    }
  };

  const { users } = state;

  const toggleOptions = [
    'VIEW INCOMPLETE',
    'VIEW COMPLETE',
    'VIEW OVERDUE',
    'VIEW DUE TODAY',
    'VIEW ALL'
  ];

  const firstPage = params.offset === '0';

  const movePage = amount => () => {
    let nextOffset = safeParse(params.offset) + amount;
    if (nextOffset < 0) nextOffset = 0;
    setParams({ ...params, offset: nextOffset });
  };

  return (
    <DashboardWrapper>
      <div style={styles.header}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <Typography component="h3" variant="h4" style={{ marginRight: 8 }}>
            Action Items
          </Typography>

          <div>
            <IconButton
              color="primary"
              onClick={() =>
                props.showModal({
                  type: 'CREATE_ACTION_ITEM'
                })
              }
            >
              <NewIcon />
            </IconButton>
          </div>
        </div>

        {!!users.length && (
          <Paper
            style={{
              padding: '0.25rem 0.5rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography style={{ marginRight: 8 }}>
              Viewing action items for:{' '}
            </Typography>
            <div style={{ width: 300 }}>
              <Autocomplete
                options={users}
                value={users.find(user => user.id === params.userId)}
                onChange={(e, user) => {
                  if (user) onUserChange(user);
                }}
                getOptionLabel={user => user.name}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label={
                        <div style={{ display: 'flex' }}>
                          User{' '}
                          <SearchIcon
                            style={{
                              marginLeft: 5,
                              transform: 'translateY(-5px)'
                            }}
                          />
                        </div>
                      }
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  );
                }}
              />
            </div>
          </Paper>
        )}
      </div>
      <ToggleButtonGroup
        exclusive
        size="small"
        style={{ marginBottom: 8 }}
        value={params.sortBy}
        onChange={(e, sortBy) => onSortByChange(sortBy)}
      >
        {isTola && currentUser.hasPermission('super_admin') && (
          <MarkCompleteButton userId={params.userId} />
        )}
        {toggleOptions.map(option => (
          <ToggleButton key={option} value={option}>
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Paper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <IconButton
            onClick={movePage(-pageSize)}
            style={{ visibility: firstPage ? 'hidden' : null }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant="caption" style={{ display: 'block' }}>
            {loading
              ? 'loading...'
              : visibleActionItems.length === 0
              ? 'no items'
              : 'Showing ' +
                (safeParse(params.offset) + 1) +
                ' - ' +
                (safeParse(params.offset) + visibleActionItems.length) +
                ' of ' +
                safeParse(count)}
          </Typography>
          <IconButton
            onClick={movePage(pageSize)}
            style={{ visibility: lastPage ? 'hidden' : null }}
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
        <Divider />
        <div
          style={{
            padding: 16,
            maxHeight: `calc(100vh - 275px)`,
            overflowY: 'auto'
          }}
        >
          {!visibleActionItems.length && <NoItemsResult type="items" />}
          {visibleActionItems.map(id => (
            <div key={id}>
              <div style={{ padding: 8 }}>
                <ActionItem id={id} />
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </Paper>
    </DashboardWrapper>
  );
};

const MarkCompleteButton = React.forwardRef(({ userId }, ref) => {
  const markAllComplete = UseMarkAllComplete(userId);
  return (
    <ToggleButton ref={ref} onClick={markAllComplete} value="">
      Mark All Complete
    </ToggleButton>
  );
});

export default connect(state => ({ user: state.user }), { showModal })(
  ActionItems
);
