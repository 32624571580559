import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Paper,
  Button,
  Typography,
  InputBase
} from 'helpers/themeSafeMui.helper';
import { showModal } from 'ducks/modal.duck';
import { useDispatch } from 'react-redux';
import QuoteLineItems from './QuoteLineItems.component';
import http from 'helpers/http.helper';
import { Toast, EditableTitle } from 'components';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  ProjectContext,
  RefreshProjectContext
} from '../context/Project.context';
import debounce from 'lodash.debounce';
import QuotePrices from './QuotePrices.component';

const _showModal = showModal;

function MultiScopeQuote(props) {
  const colors = useColors();
  const { quote, onDuplicate, onUpdate } = props;
  const dispatch = useDispatch();
  const showModal = arg => dispatch(_showModal(arg));
  const { project } = useContext(ProjectContext);
  const refreshProject = useContext(RefreshProjectContext);
  const [description, setDescription] = useState(quote.description);
  const [focused, setFocused] = useState(false);
  const [previewLink, setPreviewLink] = useState(null);

  const updateQuote = updates => {
    onUpdate(updates);
    http()
      .put(`/projects/${project.id}/quotes/${quote.id}`, {
        multiScopeOfWork: updates.multiScopeOfWork,
        scopeOfWork: updates.scopeOfWork,
        exclusions: updates.exclusions,
        assumptions: updates.assumptions,
        lineItems: updates.lineItems,
        pricing: updates.pricing,
        isMultiScope: updates.isMultiScope
      })
      .then(res => {
        onUpdate(res);
      })
      .catch(err => {
        Toast.show(err.message);
      });
  };

  const voidQuote = () => {
    if (quote.voided) return;
    http()
      .post(`/projects/${project.id}/quotes/${quote.id}/void`)
      .then(res => {
        onUpdate(res);
        refreshProject();
      })
      .catch(err => {
        Toast.show(err.message);
      });
  };

  const addScope = () => {
    const copy = JSON.parse(JSON.stringify(quote.multiScopeOfWork));
    copy.unshift({
      scopeTitle: 'Default Scope Title',
      scopeItems: ['Default scope description 1', 'Default scope description 2']
    });
    updateQuote({ multiScopeOfWork: copy });
  };

  const removeScope = idx => () => {
    const multiCopy = JSON.parse(JSON.stringify(quote.multiScopeOfWork));
    multiCopy.splice(idx, 1);
    updateQuote({ multiScopeOfWork: multiCopy });
  };

  const updateDescription = useCallback(() => {
    debounce(description => {
      http()
        .put(`/projects/${project.id}/quotes/${quote.id}`, {
          description: description
        })
        .then(res => onUpdate(res))
        .catch(err => Toast.show(err.message));
    }, 1000)();
  }, [project, quote, onUpdate]);

  useEffect(() => {
    if (quote.description !== description) {
      updateDescription(description);
    }
  }, [description, updateDescription, quote.description]);

  const openEmailModal = () => {
    if (
      quote.isMultiScope &&
      quote.pricing.length !== 1 &&
      quote.pricing.length !== quote.multiScopeOfWork.length
    ) {
      return Toast.show(
        'Pricing must be lump sum or equal to number of scopes'
      );
    }
    showModal({
      type: 'CREATE_AND_SEND_PDF',
      props: { project, quote, onUpdate }
    });
  };

  useEffect(() => {
    if (project.id && quote.id)
      http()
        .post(`/projects/${project.id}/quotes/${quote.id}/preview`)
        .then(({ token }) => {
          setPreviewLink(
            `${window.location.origin}/multi-quote-approval?quote-token=${token}`
          );
        });
  }, [project.id, quote]);

  const updateMultiScopeProperty = (updateIdx, property) => updatedValue => {
    updateQuote({
      multiScopeOfWork: quote.multiScopeOfWork.map((oldScope, idx) =>
        idx === updateIdx ? { ...oldScope, [property]: updatedValue } : oldScope
      )
    });
  };

  const toggleMultiScopeOfWork = () => {
    const firstTimeMulti =
      !quote.isMultiScope && quote.multiScopeOfWork.length === 0;
    const defaultMulti = {
      multiScopeOfWork: [
        {
          scopeTitle: 'Default scope 1 title',
          scopeItems: [
            'Default scope 1 description 1',
            'Default scope 1 description 2'
          ]
        }
      ]
    };
    updateQuote({
      isMultiScope: !quote.isMultiScope,
      ...(firstTimeMulti ? defaultMulti : {})
    });
  };

  const lumpSum = quote.pricing && quote.pricing.length === 1;

  return (
    <Paper style={{ margin: '16px 0', padding: 16 }}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ flex: 1, marginRight: 16 }}>
          <InputBase
            readOnly={Boolean(quote.emailSentDate)}
            value={description}
            onChange={e => setDescription(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{
              boxShadow: focused && `0px 0px 2px 0.5px ${colors.primary}`,
              width: '100%'
            }}
            inputProps={{
              style: { padding: 4, fontSize: '1.25rem', fontWeight: 500 }
            }}
          />
          <Typography color="textSecondary">
            Created: {moment(quote.created).format('LL')}
          </Typography>
          {quote.emailSentTo && (
            <React.Fragment>
              <Typography color="textSecondary">
                Sent: {moment(quote.emailSentDate).format('LLL')}
              </Typography>
              <Typography color="textSecondary">
                Recipient(s): {quote.emailSentTo}
              </Typography>
              <Typography color="textSecondary">
                Approved:{' '}
                {quote.dateOfApproval
                  ? moment(quote.dateOfApproval).format('LLL')
                  : 'Pending Approval'}
              </Typography>
              <Typography color="textSecondary">
                Approved By: {quote.approvedBy ? quote.approvedBy : 'N/A'}
              </Typography>
            </React.Fragment>
          )}

          {quote.voided && (
            <Typography variant="h6" component="h6" color="error">
              QUOTE VOID
            </Typography>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{ marginRight: 8 }}
            variant="outlined"
            color="primary"
            href={previewLink}
            target="_blank"
          >
            Preview
          </Button>
          {!quote.emailSentDate && (
            <>
              {quote.isMultiScope && (
                <Button
                  style={{ marginRight: 8 }}
                  variant="outlined"
                  color="primary"
                  onClick={addScope}
                >
                  Add Scope
                </Button>
              )}

              <Button
                style={{ marginRight: 8 }}
                variant="outlined"
                color="primary"
                onClick={toggleMultiScopeOfWork}
              >
                {quote.isMultiScope ? 'Make Single Scope' : 'Make Multi-Scope'}
              </Button>
            </>
          )}

          {quote.emailSentDate && !quote.voided && (
            <Button
              style={{ marginRight: 8 }}
              variant="outlined"
              color="primary"
              onClick={voidQuote}
            >
              Void Quote
            </Button>
          )}

          <Button
            style={{ marginRight: 8 }}
            variant="outlined"
            color="primary"
            onClick={() => onDuplicate(quote)}
          >
            Duplicate
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 16 }}
            onClick={openEmailModal}
          >
            Save &amp; Send PDF
          </Button>

          {quote.isOpen ? (
            <UpArrowIcon
              style={{ cursor: 'pointer' }}
              onClick={() => onUpdate({ ...quote, isOpen: false })}
            />
          ) : (
            <DownArrowIcon
              style={{ cursor: 'pointer' }}
              onClick={() => onUpdate({ ...quote, isOpen: true })}
            />
          )}
        </div>
      </div>

      {quote.isOpen && (
        <div>
          {quote.isMultiScope ? (
            quote.multiScopeOfWork.map((scope, idx) => (
              <QuoteLineItems
                key={
                  scope.scopeTitle +
                  scope.scopeItems.reduce((acc, val) => acc + val, '') +
                  `${idx}`
                }
                readOnly={Boolean(quote.emailSentDate)}
                values={scope.scopeItems}
                onChange={updateMultiScopeProperty(idx, 'scopeItems')}
                placeholder="Add scope of work"
                title={scope.scopeTitle}
                customTitle={true}
              >
                <div style={{ display: 'flex' }}>
                  <EditableTitle
                    title={scope.scopeTitle}
                    canEdit={!Boolean(quote.emailSentDate)}
                    onUpdate={updateMultiScopeProperty(idx, 'scopeTitle')}
                    buttonProps={{
                      variant: 'contained'
                    }}
                    typographyProps={{
                      variant: 'h6',
                      style: {
                        padding: '3px 8px',
                        background: colors.secondary,
                        color: colors.secondaryContrastText,
                        marginBottom: 16,
                        flexGrow: 1
                      }
                    }}
                    inputProps={{
                      style: {
                        color: colors.secondaryContrastText
                      }
                    }}
                  />
                  {!quote.emailSentDate && (
                    <Button
                      style={{ marginRight: 8, marginBottom: 16 }}
                      variant="outlined"
                      color="primary"
                      onClick={removeScope(idx)}
                    >
                      Remove Scope
                    </Button>
                  )}
                </div>
              </QuoteLineItems>
            ))
          ) : (
            <QuoteLineItems
              readOnly={Boolean(quote.emailSentDate)}
              values={quote.scopeOfWork}
              onChange={scopeOfWork => updateQuote({ scopeOfWork })}
              placeholder="Add scope of work"
              title="System Tech will complete the following scope of work for this project:"
            />
          )}
          <QuoteLineItems
            readOnly={Boolean(quote.emailSentDate)}
            values={quote.exclusions}
            onChange={exclusions => updateQuote({ exclusions })}
            placeholder="Add exclusion"
            title="The following items are excluded from this scope of work:"
          />
          <QuoteLineItems
            readOnly={Boolean(quote.emailSentDate)}
            values={quote.assumptions}
            onChange={assumptions => updateQuote({ assumptions })}
            placeholder="Add assumption"
            title="The following assumptions were made with regards to this scope of work:"
          />
          <QuoteLineItems
            readOnly={Boolean(quote.emailSentDate)}
            values={quote.lineItems}
            onChange={lineItems => updateQuote({ lineItems })}
            placeholder="Add line item"
            title="Upon completion of this project, the following items will be provided
            to the customer:"
          />
          <QuotePrices
            readOnly={Boolean(quote.emailSentDate)}
            values={quote.pricing}
            onChange={pricing => updateQuote({ pricing })}
            isMultiScope={quote.isMultiScope && !lumpSum}
            isApproved={!!quote.dateOfApproval}
            approvedScopeIndices={quote.approvedScopeIndices}
          />
        </div>
      )}
    </Paper>
  );
}
export default MultiScopeQuote;
