import React, { useContext, useState } from 'react';
import { useColors } from 'helpers/theme.helper';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  ProjectTeamContext,
  RefreshProjectContext
} from 'routes/Projects/context/Project.context';

const ProjectWonLostButton = props => {
  const { project, setProject } = props;
  const colors = useColors();

  const refreshProject = useContext(RefreshProjectContext);
  const { projectTeam } = useContext(ProjectTeamContext);
  const [openLostModal, setOpenLostModal] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);

  const [lostReason, setLostReason] = useState('');

  const updateProjectBidStatus = bidStatus => {
    return http()
      .put(`/projects/${project.id}`, {
        bidStatus: bidStatus
      })
      .then(res => setProject(res))
      .catch(err => Toast.show(err.message));
  };

  const updateClose = status => {
    http()
      .put(`/projects/${project.id}`, {
        closed: status
      })
      .then(res => setProject(res))
      .catch(err => Toast.show(err.message));
  };

  const markLost = e => {
    e.preventDefault();

    Promise.all([
      http().post(`/projects/${project.id}/notes`, {
        note: 'PROJECT LOST: ' + lostReason
      }),
      http().put(`/projects/${project.id}/details`, { lostReason }),
      http()
        .put(`/projects/${project.id}`, {
          closed: true,
          bidStatus: 'lost'
        })
        .then(res => setProject(res))
    ])
      .then(res => {
        setOpenLostModal(false);
      })
      .catch(err => Toast.show(err.message));
  };

  const markWon = () => {
    if (!projectTeam.accountManagerId) {
      return alert(
        'Account manager must be selected before moving a project to "won".'
      );
    }
    updateProjectBidStatus('won').then(refreshProject);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openLostModal}
        onClose={() => setOpenLostModal(false)}
      >
        <form autoComplete="off" onSubmit={markLost}>
          <DialogTitle>Mark Lost</DialogTitle>
          <DialogContent>
            <TextField
              label="Lost Reason"
              multiline
              rows={4}
              value={lostReason}
              onChange={e => setLostReason(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            ></TextField>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpenLostModal(false)}>Cancel</Button>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {project.bidStatus === null && (
        <div>
          <Button
            variant="contained"
            style={{
              marginRight: 8,
              backgroundColor: colors.success,
              color: 'white'
            }}
            onClick={markWon}
          >
            Won
          </Button>
          <Button
            variant="contained"
            style={{
              marginRight: 8,
              backgroundColor: colors.error,
              color: 'white'
            }}
            onClick={() => setOpenLostModal(true)}
          >
            Lost
          </Button>
        </div>
      )}

      {project.bidStatus === 'won' && (
        <div>
          <Button
            variant="contained"
            style={{
              marginRight: 8,
              backgroundColor: colors.success,
              color: 'white',
              borderRadius: 50
            }}
            onClick={() => updateProjectBidStatus(null)}
            disabled={props.project.closed}
          >
            Won
          </Button>

          {project.closed === false && (
            <>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: 8 }}
                onClick={() => {
                  setOpenCloseModal(true);
                }}
              >
                Close
              </Button>

              <Dialog
                open={openCloseModal}
                onClose={() => {
                  setOpenCloseModal(false);
                }}
              >
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to close project?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenCloseModal(false);
                    }}
                    color="default"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => updateClose(true)}
                    color="primary"
                    autoFocus
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {project.closed === true && (
            <Button
              variant="contained"
              color="default"
              style={{ marginRight: 8 }}
              onClick={() => {
                updateProjectBidStatus(null);
                updateClose(false);
              }}
            >
              Reopen
            </Button>
          )}
        </div>
      )}

      {project.bidStatus === 'lost' && (
        <div>
          <Button
            variant="contained"
            style={{
              marginRight: 8,
              backgroundColor: colors.error,
              color: 'white',
              borderRadius: 50
            }}
            onClick={() => updateProjectBidStatus(null)}
            disabled={props.project.closed}
          >
            Lost
          </Button>
          <Button
            variant="contained"
            color="default"
            style={{
              marginRight: 8
            }}
            onClick={() => {
              updateProjectBidStatus(null);
              updateClose(false);
            }}
          >
            Reopen
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProjectWonLostButton;
