import React, { useEffect, useState } from 'react';
import { ListItemText, TextField } from 'helpers/themeSafeMui.helper';
import { Autocomplete } from '@material-ui/lab';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export default function ProjectAutocomplete({ onSelect }) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let stale = false;
    if (inputValue.length < 2) {
      setOptions([]);
    } else {
      http()
        .get(`/global-search?search=${inputValue}`)
        .then(({ projects }) => (stale ? null : setOptions(projects)))
        .catch(Toast.showErr);
    }
    return () => {
      stale = true;
    };
  }, [inputValue]);

  return (
    <Autocomplete
      value={null}
      onChange={(event, project) => {
        if (project) onSelect(project);
        setInputValue('');
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={option => `${option.projectNumber} ${option.name}`}
      style={{ width: 600 }}
      renderOption={option => (
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{ noWrap: true }}
          primary={`${option.projectNumber} ${option.name}`}
        />
      )}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="Search Projects"
          variant="outlined"
        />
      )}
    />
  );
}
