import React, { useEffect, useState } from 'react';
import { CustomInput, NoItemsResult } from 'components';
import {
  Button,
  Checkbox,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';
import CancelIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { useColors } from 'helpers/theme.helper';

export default function QuizQuestion({
  correct = null,
  question,
  canEdit = false,
  onEdit,
  onDelete,
  onSelect = () => {},
  lockedOption = null
}) {
  const [stateQuestion, setStateQuestion] = useState(question);
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState(null);
  const colors = useColors();

  const handleChange = event => {
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  useEffect(() => {
    setStateQuestion(question);
    setEditing(false);
  }, [question]);

  const updateOption = (optionIdx, replacer) => newValue => {
    setStateQuestion(old => {
      const copy = JSON.parse(JSON.stringify(old));
      copy.options[optionIdx] = replacer(newValue, copy.options[optionIdx]);
      return copy;
    });
  };

  const addOption = () => {
    setStateQuestion(old => {
      const copy = JSON.parse(JSON.stringify(old));
      copy.options.push({ text: 'New Option', correct: false, id: uuid() });
      return copy;
    });
  };

  const removeOption = idx => () => {
    setStateQuestion(old => {
      const copy = JSON.parse(JSON.stringify(old));
      copy.options.splice(idx, 1);
      return copy;
    });
  };

  return !editing ? (
    // NOT EDITING
    <Paper>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <Typography style={{ whiteSpace: 'pre-wrap' }} variant="h5">
            {question.prompt}
          </Typography>
        </div>
        {correct !== null &&
          (correct ? (
            <CheckIcon style={{ color: colors.success }} />
          ) : (
            <CancelIcon style={{ color: colors.error }} />
          ))}
        {canEdit && (
          <IconButton size="small" onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <div style={{ padding: 10, paddingLeft: 30 }}>
        {question.options.length ? (
          <FormControl component="fieldset">
            <RadioGroup
              value={lockedOption || selected}
              onChange={handleChange}
            >
              {question.options.map(option => (
                <FormControlLabel
                  style={{ whiteSpace: 'pre-wrap', lineHeight: '1.1' }}
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={option.text}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <div style={{ paddingBottom: 15 }}>
            <NoItemsResult type="Options" />
          </div>
        )}
      </div>
    </Paper>
  ) : (
    // EDITING
    <Paper>
      <div style={{ padding: 10 }}>
        <CustomInput
          value={stateQuestion.prompt}
          label="prompt"
          multiline
          onChange={prompt => setStateQuestion(old => ({ ...old, prompt }))}
          variant="outlined"
        />
        <div
          style={{
            paddingTop: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <FormControlLabel
            style={{ whiteSpace: 'nowrap', paddingRight: 16 }}
            control={
              <Checkbox
                checked={stateQuestion.timeLimitSeconds !== null}
                onChange={e =>
                  setStateQuestion(old => ({
                    ...old,
                    timeLimitSeconds: old.timeLimitSeconds === null ? 30 : null
                  }))
                }
                color="primary"
              />
            }
            labelPlacement="start"
            label="Question Time Limit"
          />
          <CustomInput
            label="Time Limit (seconds)"
            type="number"
            style={{ margin: 0 }}
            fullWidth={false}
            value={stateQuestion.timeLimitSeconds}
            disabled={stateQuestion.timeLimitSeconds === null}
            onChange={timeLimitSeconds =>
              setStateQuestion(old => ({ ...old, timeLimitSeconds }))
            }
          />
        </div>
      </div>
      <Divider />
      {stateQuestion.options.length ? (
        stateQuestion.options.map((option, idx) => (
          <div
            key={option.id}
            style={{
              padding: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            }}
          >
            <CustomInput
              value={option.text}
              label={`Option ${idx + 1}`}
              onChange={updateOption(idx, (text, old) => ({ ...old, text }))}
              multiline
            />
            <FormControlLabel
              style={{ whiteSpace: 'nowrap', paddingLeft: 16 }}
              control={
                <Checkbox
                  checked={option.correct}
                  onChange={e =>
                    updateOption(idx, (correct, old) => ({
                      ...old,
                      correct
                    }))(e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Correct Answer"
            />
            <IconButton onClick={removeOption(idx)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))
      ) : (
        <NoItemsResult type="Options" />
      )}
      <DialogActions>
        <Button variant="contained" onClick={addOption}>
          Add option
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Remove Question
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setStateQuestion(question);
            setEditing(false);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onEdit(stateQuestion);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Paper>
  );
}
