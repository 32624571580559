import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography
} from 'helpers/themeSafeMui.helper';
import { DropZone, CustomInput } from 'components';

const NewFileModal = ({ open, onClose, createFiles, templates, disabled }) => {
  const [templateId, setTemplateId] = useState(null);
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>New File</DialogTitle>
      <DialogContent>
        <CustomInput
          type="dropdown"
          label="Template"
          onChange={setTemplateId}
          value={templateId}
          options={templates.map(template => ({
            label: template.name,
            value: template.id
          }))}
          disabled={disabled}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            createFiles([templates.find(t => t.id === templateId)]).then(() =>
              onClose()
            );
          }}
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Create From Template
        </Button>
      </DialogActions>
      <DialogContent>
        {disabled ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography variant="caption" gutterBottom>
              Loading
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <DropZone
            onRead={files =>
              createFiles(files.map(item => item.file)).then(() => onClose())
            }
            title="User Document"
            disabled={disabled}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            createFiles([{ name: 'New Folder', isFolder: true }]).then(() =>
              onClose()
            )
          }
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Create Folder
        </Button>
        <Button disabled={disabled} variant="contained" color="default">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewFileModal;
