const env = {
  apiUrl: '',
  emailDomain: window.location.host,
  usingOneDrive: false
};

if (window.location.host === 'localhost:3000') {
  // DEV
  env.apiUrl = 'http://localhost:3001';
  env.emailDomain = 'dev.steps.systemtech.app';
} else if (window.location.host === 'staging.steps.systemtech.app') {
  // STAGING
  env.apiUrl = 'https://steps-back-end-staging.herokuapp.com/';
} else if (window.location.host === 'steps.systemtech.app') {
  // PROD
  env.apiUrl = 'https://steps-back-end.herokuapp.com';
} else if (window.location.host === 'steps.systemtechtola.com') {
  // TOLA PROD
  env.apiUrl = 'https://steps-back-end-tola.herokuapp.com/';
  env.emailDomain = 'steps.email.systemtechtola.com';
  env.usingOneDrive = true;
}

export { env };
