import React from 'react';
import Color from 'color';
import {
  ButtonProps,
  Button as MuiButton,
  LinkProps,
  Link as MuiLink
} from '@material-ui/core';
import { useColors } from './theme.helper';

export * from '@material-ui/core';

interface ThemeColors {
  type: string;
  primary: string;
  primaryContrastText: string;
  secondary: string;
  secondaryContrastText: string;
  background: string;
  action: string;
  grey: string;
  darkGrey: string;
  success: string;
  error: string;
  red: string;
  yellow: string;
  paperText: string;
  backgroundText: string;
  paper: string;
}

interface ThemeSafeButtonProps extends ButtonProps {
  isOnPaper?: boolean;
}

export const Button = (props: ThemeSafeButtonProps) => {
  const colors: ThemeColors = useColors();

  const buttonColor =
    props.color === 'primary'
      ? colors.primary
      : props.color === 'secondary'
      ? colors.secondary
      : null;

  const buttonProps: ButtonProps = {};

  const applyBackground = props.variant !== 'contained' && buttonColor !== null;

  if (buttonColor !== null && applyBackground && props.isOnPaper !== false) {
    const paperContrast = Color(buttonColor).contrast(Color(colors.paper));
    const bgContrast = Color(buttonColor).contrast(Color(colors.background));
    if (paperContrast < 5 && paperContrast < bgContrast) {
      console.warn('overwriting some button props to improve contrast');
      buttonProps.variant = 'contained';
    }
  }

  return (
    <MuiButton {...props} {...buttonProps}>
      {props.children}
    </MuiButton>
  );
};

interface ThemeSafeLinkProps extends LinkProps {
  isOnPaper?: boolean;
}

export const Link = (props: ThemeSafeLinkProps) => {
  const colors: ThemeColors = useColors();

  const linkColor =
    (props.style && props.style.color) ||
    (props.color === 'primary' || props.color === undefined
      ? colors.primary
      : props.color === 'secondary'
      ? colors.secondary
      : null);

  const linkProps: LinkProps = {};

  if (linkColor !== null && props.isOnPaper !== false) {
    const paperContrast = Color(linkColor).contrast(Color(colors.paper));
    const bgOnPaper = Color(colors.background).contrast(Color(colors.paper));
    if (paperContrast < 5 && paperContrast < bgOnPaper) {
      console.warn('overwriting some link props to improve contrast');
      linkProps.variant = 'subtitle2';
      linkProps.style = {
        ...props.style,
        color: colors.paperText
      };
    }
  }

  return (
    <MuiLink {...props} {...linkProps}>
      {props.children}
    </MuiLink>
  );
};
