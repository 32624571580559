import React, { useContext, useRef, useState } from 'react';
import {
  Divider,
  Paper,
  Popper,
  TableCell,
  Typography
} from 'helpers/themeSafeMui.helper';
import StatusesContext from '../context/Statuses.context';
import { trim0s } from 'helpers/floatStringFormatter.helper';
import moment from 'moment';

export const HighlightUnorderedContext = React.createContext(false);

export default function QuantityCell({ material, cellStyle }) {
  const highlightUnordered = useContext(HighlightUnorderedContext);
  const statuses = useContext(StatusesContext);
  const typographyRef = useRef(null);
  const [open, setOpen] = useState(false);

  const materialId = material.materialId || material.id;
  let ordered = null;
  let pos = null;
  let showPopper = false;
  const status = statuses[materialId];
  if (status) {
    ordered = status.ordered || null;
    pos = status.pos || null;
    if (pos !== null) showPopper = true;
  }

  const quantity = trim0s(material.quantity || '');
  const orderedText = ordered === null ? '' : `(${ordered})`;

  const quantityColor =
    highlightUnordered && !ordered ? 'error' : 'textPrimary';

  return (
    <TableCell
      style={{ ...cellStyle, position: 'relative' }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          color={quantityColor}
          component="div"
          innerRef={typographyRef}
          style={{ fontSize: 14 }}
        >
          {quantity}
        </Typography>
        {!!orderedText && (
          <Typography
            component="div"
            style={{ fontSize: 14, marginRight: 16, marginLeft: 10 }}
          >
            {orderedText}
          </Typography>
        )}
        {showPopper && (
          <Popper
            placement="bottom-start"
            open={open}
            anchorEl={typographyRef.current}
            disablePortal
            onClick={e => e.stopPropagation()}
            style={{ zIndex: 1 }}
          >
            <Paper style={{ marginTop: 6, minWidth: 225 }}>
              {pos.map((poData, idx) => (
                <React.Fragment key={poData.poId}>
                  {idx !== 0 && <Divider />}
                  <PoSummary data={poData} />
                </React.Fragment>
              ))}
            </Paper>
          </Popper>
        )}
      </div>
    </TableCell>
  );
}

function PoSummary({ data }) {
  const quantity = trim0s(data.quantity);
  const date = data.created ? moment(data.created).format('L') : '';
  const time = data.created ? moment(data.created).format('LT') : '';
  const dateTime = date && time ? date + '\n' + time : date;
  return (
    <div style={{ padding: 8 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <Typography
          style={{ lineHeight: 1, fontSize: 16, fontWeight: 500 }}
          component="div"
          variant="overline"
        >
          {data.poNumber}
        </Typography>
        <Typography
          style={{
            textAlign: 'right',
            lineHeight: 1.1,
            marginLeft: 5,
            whiteSpace: 'pre-wrap'
          }}
          component="div"
          color="textSecondary"
          variant="caption"
        >
          {dateTime}
        </Typography>
      </div>
      <div style={{ marginLeft: 5 }}>
        <Typography
          style={{ lineHeight: 1 }}
          component="div"
          variant="subtitle2"
        >
          Ordered: {quantity}
        </Typography>
        <div style={{ paddingTop: 10 }} />
        <Typography
          style={{ lineHeight: 1, fontWeight: 400 }}
          component="div"
          variant="subtitle2"
        >
          - {data.userName}
        </Typography>
      </div>
    </div>
  );
}
