import React, { useContext, useRef, useState } from 'react';
import {
  Divider,
  Paper,
  Popper,
  TableCell,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import StatusesContext from '../context/Statuses.context';
import { trim0s } from 'helpers/floatStringFormatter.helper';
import moment from 'moment';
import { ConfirmDelete, Toast } from 'components';
import http from 'helpers/http.helper';
import { useRouteMatch } from 'react-router-dom';
import OnStatusRemovedContext from '../context/OnStatusRemoved.context';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

export default function StatusReceivedCell({ material, cellStyle }) {
  const statuses = useContext(StatusesContext);
  const typographyRef = useRef(null);
  const [open, setOpen] = useState(false);

  const materialId = material.materialId || material.id;
  let received = null;
  let receivedItems = null;
  let showPopper = false;
  const status = statuses[materialId];
  if (status) {
    received = status.received || null;
    receivedItems = status.receivedItems || null;
    if (receivedItems !== null) showPopper = true;
  }

  if (!received) return <TableCell style={{ ...cellStyle }} />;

  const receivedText = trim0s(received);

  return (
    <TableCell
      style={{ ...cellStyle, position: 'relative' }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div>
        <Typography innerRef={typographyRef} style={{ fontSize: 14 }}>
          {receivedText}
        </Typography>
        {showPopper && (
          <Popper
            placement="bottom-start"
            open={open}
            anchorEl={typographyRef.current}
            disablePortal
            onClick={e => e.stopPropagation()}
            style={{ zIndex: 1 }}
          >
            <Paper style={{ marginTop: 6, minWidth: 225 }}>
              {receivedItems.map((item, idx) => (
                <React.Fragment key={item.id}>
                  {idx !== 0 && <Divider />}
                  <ReceivedMaterial data={item} />
                </React.Fragment>
              ))}
            </Paper>
          </Popper>
        )}
      </div>
    </TableCell>
  );
}

function ReceivedMaterial({ data }) {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const onStatusRemoved = useContext(OnStatusRemovedContext);
  const canDelete = onStatusRemoved !== null && !!projectId;

  const removeReceived = () => {
    if (!canDelete) return Promise.resolve();
    return http()
      .delete(`/projects/${projectId}/materials/received/${data.id}`)
      .then(onStatusRemoved)
      .catch(Toast.showErr);
  };

  const quantity = trim0s(data.quantity);
  const date = data.created ? moment(data.created).format('L') : '';
  const time = data.created ? moment(data.created).format('LT') : '';
  const dateTime = date && time ? date + '\n' + time : date;
  return (
    <div style={{ padding: 8, position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <Typography
          noWrap
          style={{ lineHeight: 1 }}
          component="div"
          variant="subtitle2"
        >
          Received: {quantity}
        </Typography>

        <Typography
          style={{
            textAlign: 'right',
            lineHeight: 1.1,
            marginLeft: 16,
            whiteSpace: 'pre-wrap'
          }}
          component="div"
          color="textSecondary"
          variant="caption"
        >
          {dateTime}
        </Typography>
      </div>
      <div
        style={{
          marginLeft: 5,
          paddingBottom: 5
        }}
      >
        {!!data.location && (
          <Typography
            style={{ lineHeight: 1, fontWeight: 400 }}
            component="div"
            variant="subtitle2"
          >
            Location: {data.location}
          </Typography>
        )}
        <Typography
          style={{ lineHeight: 1, fontWeight: 400 }}
          component="div"
          variant="subtitle2"
        >
          Created By: {data.userName}
        </Typography>
      </div>
      {!!canDelete && (
        <div style={{ position: 'absolute', bottom: 5, right: 5 }}>
          <ConfirmDelete
            iconButtonProps={{ size: 'small' }}
            onConfirm={removeReceived}
            message=""
            confirmButton={
              <IconButton size="small">
                <DeleteIcon color="error" />
              </IconButton>
            }
            cancelConfirmButton={
              <IconButton size="small">
                <CloseIcon />
              </IconButton>
            }
          />
        </div>
      )}
    </div>
  );
}
