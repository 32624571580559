import React, { useState, useContext, useEffect, useCallback } from 'react';
import { SelectionSortingTable } from 'components';
import moment from 'moment';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import { TableCell } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import ViewPerDiemModal from '../modals/ViewPerDiem.modal';
import EditPerDiemModal from '../modals/EditPerDiem.modal';
import { UsersContext } from '../context/users.context';
import { ProjectsContext } from '../context/projects.context';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import { useQueryParams } from 'hooks/useQueryParams.hook';
import getStatusColor from '../helpers/getStatusColor.helper';

const dateColumn = key => ({
  extractor: item => (item[key] ? moment(item[key]).format('ddd, LL') : '---'),
  customSort: (a, b) => moment(a[key]).unix() < moment(b[key]).unix()
});

const dollarColumn = key => ({
  extractor: item => `$${parseFloat(item[key]).toFixed(2)}`,
  customSort: (a, b) => parseFloat(a[key]) < parseFloat(b[key])
});

const allColumns = colors => [
  {
    key: 'created',
    label: 'Created Date',
    ...dateColumn('created')
  },
  {
    key: 'user',
    label: 'Created By',
    extractor: item => (item.user ? item.user.name : 'Loading...')
  },
  {
    key: 'lastName',
    label: 'Last Name',
    extractor: item => (item.user ? item.user.lastName : 'Loading...')
  },
  {
    key: 'legalFirstName',
    label: 'Legal First',
    extractor: item => (item.user ? item.user.legalFirstName : 'Loading...')
  },
  {
    key: 'project',
    label: 'Project',
    extractor: item =>
      item.project
        ? `#${item.project.projectNumber} - ${item.project.name}`
        : 'Loading...'
  },
  {
    key: 'projectNumber',
    label: 'Project Number',
    extractor: item =>
      item.project ? item.project.projectNumber : 'Loading...'
  },
  { key: 'startDate', label: 'Start', ...dateColumn('startDate') },
  { key: 'endDate', label: 'End', ...dateColumn('endDate') },
  { key: 'dailyAmount', label: 'Daily Amount', ...dollarColumn('dailyAmount') },
  { key: 'totalAmount', label: 'Total Amount', ...dollarColumn('totalAmount') },
  {
    key: 'locked',
    label: 'Locked',
    customSort: (a, b) => a.locked < b.locked,
    renderer: row => (
      <TableCell key="locked">
        {row.locked ? (
          <BsLockFill color={colors.error} size={20} />
        ) : (
          <BsUnlockFill color={colors.success} size={20} />
        )}
      </TableCell>
    )
  },
  {
    key: 'status',
    label: 'Status',
    renderer: row => (
      <TableCell
        key="status"
        style={{ color: colors[getStatusColor(row.status)] }}
      >
        {row.status}
      </TableCell>
    )
  },
  {
    key: 'paidOn',
    label: 'Paid On',
    ...dateColumn('paidOn')
  }
];

const PerDiemTable = ({
  perDiems,
  setPerDiems,
  setSelected,
  columns = [],
  initialOrderBy,
  initialOrder,
  height
}) => {
  const [viewModalItem, setViewModalItem] = useState(null);
  const [editModalItem, setEditModalItem] = useState(null);
  const user = useSelector(state => state.user);
  const { usersMap } = useContext(UsersContext);
  const { projectsMap } = useContext(ProjectsContext);
  const colors = useColors();
  const [params, setParams] = useQueryParams();

  const hiddenEditFields = user.hasPermission('per_diem_tier_2')
    ? []
    : ['status', 'locked', 'paidOn'];

  const setPerdiem = useCallback(
    updatedPerDiem => {
      if (updatedPerDiem) {
        setPerDiems(old =>
          old.map(oldPerDiem =>
            oldPerDiem.id === updatedPerDiem.id ? updatedPerDiem : oldPerDiem
          )
        );
      }
    },
    [setPerDiems]
  );

  const removePerdiem = perDiemId =>
    setPerDiems(old => old.filter(other => other.id !== perDiemId));

  const edit = async data => {
    const copy = { ...data };
    hiddenEditFields.forEach(field => delete copy[field]);
    const edited = await http().put(
      `/per-diems/${editModalItem.id}`,
      user.hasPermission('per_diem_tier_2')
        ? copy
        : {
            startDate: copy.startDate,
            endDate: copy.endDate,
            dailyAmount: copy.dailyAmount,
            totalAmount: copy.totalAmount,
            notes: copy.notes
          }
    );
    setViewModalItem(edited);
    setPerdiem(edited);
  };

  const rows = perDiems.map(perDiem => ({
    ...perDiem,
    user: usersMap[perDiem.userId],
    project: projectsMap[perDiem.projectId]
  }));

  const withColors = allColumns(colors);
  const visibleColumns = columns.map(key =>
    withColors.find(other => other.key === key)
  );

  useEffect(() => {
    if (params.viewId) {
      setViewModalItem(
        perDiems.find(perDiem => perDiem.id === params.viewId) || null
      );
    } else {
      setViewModalItem(null);
    }
  }, [params, perDiems]);

  return (
    <>
      <ViewPerDiemModal
        open={!!viewModalItem}
        onClose={() => {
          const copy = { ...params };
          delete copy.viewId;
          setViewModalItem(null);
          setParams(copy);
        }}
        perDiem={viewModalItem}
        setPerdiem={setPerdiem}
        removePerdiem={removePerdiem}
        onEditClick={() => setEditModalItem(viewModalItem)}
      />
      <EditPerDiemModal
        open={!!editModalItem}
        onClose={() => setEditModalItem(null)}
        title="Edit Per-Diem"
        hiddenFields={hiddenEditFields}
        onSubmit={edit}
        perDiem={editModalItem}
      />
      <SelectionSortingTable
        rows={rows}
        columns={visibleColumns}
        stickyColumns={1}
        initialOrderBy={initialOrderBy}
        initialOrder={initialOrder}
        rowAction={row => setParams({ ...params, viewId: row.id })}
        rootStyles={{ height }}
        isSelecting={!!setSelected}
        onSelectionChange={setSelected}
        stickyHeader
      />
    </>
  );
};

export default PerDiemTable;
