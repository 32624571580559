import React, { useState, useEffect, useCallback, useRef } from 'react';
import RightArrow from '@material-ui/icons/ChevronRight';
import LeftArrow from '@material-ui/icons/ChevronLeft';
import ease from 'helpers/easing.helper';

const HorizontalScroll = ({
  children,
  initialPosition,
  style,
  wrapperStyle,
  onScroll,
  scrollPos: scrollPosProp = null
}) => {
  const scrollableRef = useRef(null);
  const [mouseDownPos, setMouseDownPos] = useState(null);
  const [innerScrollPos, setInnerScrollPos] = useState(scrollPosProp || 0);
  const [scrollPos, setScrollPos] = useState(0);
  const [originalScrollPos, setOriginalScrollPos] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  const maxScrollPos = scrollableRef.current
    ? scrollableRef.current.scrollWidth - scrollableRef.current.offsetWidth
    : 0;

  useEffect(() => {
    if (!scrollableRef.current) return;
    if (maxScrollPos === 0) return;

    if (scrollPos < 0) {
      setScrollPos(0);
    } else if (scrollPos > maxScrollPos) {
      setScrollPos(maxScrollPos);
    } else {
      if (onScroll) onScroll(scrollPos);
      scrollableRef.current.scrollTo(scrollPos, 0);
    }
  }, [scrollPos, onScroll, maxScrollPos]);

  const smoothScroll = useCallback(
    end => {
      setScrollCount(scrollCount + 1);
      ease(innerScrollPos, end, 750, setScrollPos).then(endPos => {
        if (!scrollableRef.current) return;
        const maxScrollPos =
          scrollableRef.current.scrollWidth - scrollableRef.current.offsetWidth;
        setOriginalScrollPos(clamp(endPos, 0, maxScrollPos));
      });
    },
    [scrollCount, innerScrollPos]
  );

  useEffect(() => {
    if (scrollableRef.current && initialPosition && scrollCount < 1) {
      smoothScroll(initialPosition);
    }
  }, [initialPosition, smoothScroll, scrollCount]);

  useEffect(() => {
    setInnerScrollPos(scrollPos);
  }, [scrollPos]);

  useEffect(() => {
    if (!scrollableRef.current) return;
    if (maxScrollPos === 0) return;
    let nextScrollValue;
    if (scrollPosProp < 0) {
      nextScrollValue = 0;
    } else if (scrollPosProp > maxScrollPos) {
      nextScrollValue = maxScrollPos;
    } else {
      nextScrollValue = scrollPosProp;
    }

    scrollableRef.current.scrollTo(nextScrollValue, 0);
    setInnerScrollPos(nextScrollValue);
    setOriginalScrollPos(nextScrollValue);
  }, [scrollPosProp, maxScrollPos]);

  useEffect(() => {
    const handleMouseUp = e => {
      setMouseDownPos(null);
      setOriginalScrollPos(innerScrollPos);
    };

    let frame;

    const handleMouseMove = e => {
      if (mouseDownPos === null) return;
      e.preventDefault();

      const clientX =
        e.clientX === 0 || e.clientX ? e.clientX : e.touches[0].clientX;

      cancelAnimationFrame(frame);
      frame = requestAnimationFrame(() => {
        setScrollPos((clientX - mouseDownPos - originalScrollPos) * -1);
      });
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove, { passive: false });

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
    };
  }, [mouseDownPos, originalScrollPos, innerScrollPos]);

  const handleMouseDown = e => {
    setMouseDownPos(e.clientX ? e.clientX : e.touches[0].clientX);
    setOriginalScrollPos(scrollableRef.current.scrollLeft);
  };

  const moving = Math.abs(originalScrollPos - innerScrollPos) > 10;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        ...style
      }}
    >
      <LeftArrow
        style={{ cursor: 'pointer', alignSelf: 'center' }}
        onClick={() =>
          smoothScroll(innerScrollPos - scrollableRef.current.clientWidth / 2)
        }
      />
      <div
        style={{
          width: 'calc(100% - 30px)',
          overflowX: 'hidden',
          pointerEvents: moving && 'none',
          userSelect: moving && 'none',
          ...wrapperStyle
        }}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        ref={scrollableRef}
      >
        {children}
      </div>
      <RightArrow
        style={{ cursor: 'pointer', alignSelf: 'center' }}
        onClick={() =>
          smoothScroll(innerScrollPos + scrollableRef.current.clientWidth / 2)
        }
      />
    </div>
  );
};

export default HorizontalScroll;

function clamp(val, min, max) {
  return val < min ? min : val > max ? max : val;
}
