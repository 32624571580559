import React, { useState } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import moment from 'moment';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import WorkOrderMaterials from './WorkOrderMaterials.component';
import WorkOrderLabors from './WorkOrderLabor.component';
import WorkOrderNotes from './WorkOrderNotes.component';
import WorkOrderSignOff from './WorkOrderSignOff.component';
import WorkOrderFiles from './WorkOrderFiles.component';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import http from 'helpers/http.helper';
import { Toast, EditableTitle, ConfirmDelete } from 'components';
import useBreakpoint from 'hooks/useBreakpoint.hook';

function WorkOrderContainer(props) {
  const {
    item,
    users,
    showModal,
    user,
    getWorkOrders,
    defaultOpen = false
  } = props;
  const [workOrder, setWorkOrder] = useState(item);
  const [isCollapsed, setIsCollapsed] = useState(!defaultOpen);
  const isSmallScreen = useBreakpoint('xs');

  const openEmailModal = () => {
    showModal({
      type: 'EMAIL_WORK_ORDER',
      props: {
        projectId: workOrder.projectId,
        workOrderId: workOrder.id
      }
    });
  };

  const openEditModal = () => {
    showModal({
      type: 'EDIT_WORK_ORDER',
      props: {
        users: users,
        workOrder: workOrder,
        setWorkOrder: setWorkOrder,
        getWorkOrders
      }
    });
  };

  const sendReminder = () => {
    http()
      .post(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/reminder`
      )
      .then(res => Toast.show('Reminder sent'))
      .catch(err => Toast.show(err.message));
  };

  const updateWorkOrder = () => {
    http()
      .get(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}`)
      .then(res => setWorkOrder(res))
      .catch(err => Toast.show(err.message));
  };

  const removeWorkOrder = () => {
    http()
      .delete(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}`)
      .then(getWorkOrders);
  };

  const canEdit = !!(
    !workOrder.techSignOff &&
    !workOrder.dateOfCustomerAcceptance &&
    user.hasPermission('projects_tier_1.5')
  );

  const updateName = newName => {
    if (newName.length === 0) return Toast.show('Name can not be empty');
    if (newName === item.name) return Toast.show('Name not changed');

    http()
      .put(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}`, {
        name: newName
      })
      .then(res => {
        Toast.show('Name updated.');
        setWorkOrder(res);
      })
      .catch(err => Toast.show(err.message));
  };

  let onsiteContact = null;
  if (workOrder.onSiteContactName && workOrder.onSiteContactPhone) {
    onsiteContact =
      workOrder.onSiteContactName + ' - ' + workOrder.onSiteContactPhone;
    if (workOrder.onSiteContactEmail)
      onsiteContact += ' - ' + workOrder.onSiteContactEmail;
  }

  const label = text => (
    <Typography component="span" variant="subtitle1" color="textPrimary">
      {text}
    </Typography>
  );

  return (
    <Grid item xs={12}>
      <Paper style={{ padding: 16 }}>
        <div
          style={{
            // display: isSmallScreen ? null : 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <EditableTitle
              canEdit={canEdit}
              title={workOrder.name}
              onUpdate={updateName}
              multiLine={isSmallScreen}
            />
            <Typography variant="subtitle1" color="textSecondary">
              {label('Order:')} {workOrder.projectNumber} WO
              {workOrder.workOrderNumber} - {workOrder.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {label('Created on:')} {moment(workOrder.created).format('LL')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {label('Scheduled Date:')}{' '}
              {moment(workOrder.scheduledDateTime).format('LLL')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {label('Lead Tech:')} {workOrder.leadTechnicianName}
            </Typography>
            {workOrder.jobsiteAddress && (
              <Typography variant="subtitle1" color="textSecondary">
                {label('Address:')} {workOrder.jobsiteAddress}
              </Typography>
            )}
            {onsiteContact && (
              <Typography variant="subtitle1" color="textSecondary">
                {label('Job Site Contact:')} {onsiteContact}
              </Typography>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: 10,
              justifyContent: 'flex-end'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexWrap: 'wrap'
              }}
            >
              {canEdit && (
                <div style={{ paddingRight: 10 }}>
                  <ConfirmDelete onConfirm={removeWorkOrder} />
                </div>
              )}
              {workOrder.techSignOff && workOrder.dateOfCustomerAcceptance && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 32 }}
                  onClick={openEmailModal}
                >
                  email copy
                </Button>
              )}
              {workOrder.techSignOff && workOrder.dateOfCustomerAcceptance && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 5 }}
                  onClick={openEmailModal}
                >
                  email copy
                </Button>
              )}
              {!workOrder.dateOfCustomerAcceptance &&
                user.hasPermission('projects_tier_1.5') && (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: 5 }}
                    onClick={sendReminder}
                  >
                    Send Reminder
                  </Button>
                )}
              {canEdit && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: 5 }}
                  onClick={openEditModal}
                >
                  Edit Info
                </Button>
              )}
            </div>

            <div style={{ marginLeft: 10 }}>
              {isCollapsed ? (
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <DownArrowIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <UpArrowIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>

        {!isCollapsed && (
          <div style={{ marginTop: 16 }}>
            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ display: 'flex', marginTop: 16 }}>
                  <Typography variant="h5" style={{ marginBottom: 16 }}>
                    Project Scope
                  </Typography>
                </div>

                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {workOrder.projectScope}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <WorkOrderNotes canEdit={true} item={workOrder} />
              </Grid>

              <Grid item xs={12}>
                <WorkOrderMaterials workOrder={workOrder} canEdit={true} />
              </Grid>

              <Grid item xs={12}>
                <WorkOrderLabors workOrder={workOrder} canEdit={true} />
              </Grid>

              <Grid item xs={12}>
                <WorkOrderFiles canEdit={true} workOrder={workOrder} />
              </Grid>

              <WorkOrderSignOff
                item={workOrder}
                refresh={updateWorkOrder}
                canEdit={true}
              />
            </Grid>
          </div>
        )}
      </Paper>
    </Grid>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(
  WorkOrderContainer
);
