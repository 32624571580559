const easing = (start, end, milliseconds, onUpdate) => {
  // t: current time, b: beginning value, c: change in value, d: duration
  return new Promise(resolve => {
    const easeInOutCubic = (t, b, c, d) => {
      if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
      return (c / 2) * ((t -= 2) * t * t + 2) + b;
    };

    const updateScrollPosition = currentTime => {
      const request = requestAnimationFrame(timestamp => {
        if (!startTime) startTime = timestamp;
        currentTime = timestamp - startTime;
        onUpdate(easeInOutCubic(currentTime, start, end - start, milliseconds));
        updateScrollPosition(currentTime);
      });
      if (currentTime > milliseconds) {
        cancelAnimationFrame(request);
        resolve(easeInOutCubic(currentTime, start, end - start, milliseconds));
      }
    };

    let startTime = null;

    updateScrollPosition(0);
  });
};

export default easing;
