import React, { useContext } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
  TableRow,
  TableCell,
  IconButton,
  Checkbox
} from 'helpers/themeSafeMui.helper';
import {
  ProjectChangeOrdersContext,
  ProjectContext,
  ProjectDetailsContext
} from 'routes/Projects/context/Project.context';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import formatValue from 'helpers/formatValue.helper';
import { useColors } from 'helpers/theme.helper';

const ChangeOrderRow = ({ id, openEditModal, isHidden }) => {
  const colors = useColors();
  const { projectChangeOrders, setProjectChangeOrders } = useContext(
    ProjectChangeOrdersContext
  );
  const {
    project: { id: projectId }
  } = useContext(ProjectContext);

  const { setProjectDetails } = useContext(ProjectDetailsContext);
  const CO = projectChangeOrders.find(otherCO => otherCO.id === id);

  const setApproved = val => () => {
    http()
      .put(`/projects/${projectId}/change-orders/${id}`, {
        approved: val
      })
      .then(({ changeOrder: updated, projectDetails }) => {
        setProjectChangeOrders(oldCOs => {
          const copy = [...oldCOs];
          const idx = oldCOs.findIndex(oldCO => oldCO.id === updated.id);
          copy[idx] = updated;
          return copy;
        });
        setProjectDetails(projectDetails);
      })
      .catch(err => Toast.show(err.message));
  };

  const unsetApproved = setApproved(null);

  return (
    <TableRow>
      {!isHidden('CO#') && (
        <TableCell>
          {CO.coNumber === -1 ? 'Pending' : `CO#${CO.coNumber}`}
        </TableCell>
      )}
      {!isHidden('Ref#') && <TableCell>{CO.refNumber || '---'}</TableCell>}
      {!isHidden('Name') && <TableCell>{CO.name}</TableCell>}
      {!isHidden('Notes') && (
        <TableCell style={{ whiteSpace: 'pre-wrap' }}>
          {CO.notes || '---'}
        </TableCell>
      )}
      {!isHidden('$ Amount') && (
        <TableCell>
          {formatValue({
            type: 'currency',
            value: CO.price
          })}
        </TableCell>
      )}
      {!isHidden('$ Amount') && (
        <TableCell>
          {formatValue({
            type: 'currency',
            value: CO.cost
          })}
        </TableCell>
      )}
      {!isHidden('Hrs.') && <TableCell>{CO.hours}</TableCell>}
      {!isHidden('Approved') && (
        <TableCell>
          {/* Approved column */}
          <Checkbox
            disabled={isHidden('checkable')}
            checkedIcon={<CheckBoxIcon style={{ color: colors.success }} />}
            checked={CO.approved === true}
            onClick={CO.approved === true ? unsetApproved : setApproved(true)}
          />
        </TableCell>
      )}
      {!isHidden('Denied') && (
        <TableCell>
          {/* Denied column */}
          <Checkbox
            disabled={isHidden('checkable')}
            checkedIcon={<CheckBoxIcon color="error" />}
            checked={CO.approved === false}
            onClick={CO.approved === false ? unsetApproved : setApproved(false)}
          />
        </TableCell>
      )}
      {!isHidden('add/edit') && (
        <TableCell padding="checkbox">
          <IconButton onClick={openEditModal(CO.id)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ChangeOrderRow;
