import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import People from './routes/People.route';
import Person from './routes/Person.route';

export default class PeopleRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/people" exact component={People} />
        <Route path="/people/:id" exact component={Person} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
