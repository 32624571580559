import React, { useState, useEffect } from 'react';
import { useQueryParams } from 'hooks/useQueryParams.hook';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  Typography,
  IconButton,
  makeStyles
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useColors } from 'helpers/theme.helper';

const useStyles = makeStyles({
  '@media print': {
    hideOnPrint: {
      display: 'none !important'
    }
  }
});

const PrintRfi = () => {
  const [rfi, setRfi] = useState(null);
  const [project, setProject] = useState(null);
  const [params] = useQueryParams();
  const { id } = params;
  const colors = useColors();
  const classes = useStyles();

  useEffect(() => {
    if (id && id !== 'plangrid')
      http()
        .get(`/projects/na/rfis/${id}`)
        .then(res => setRfi(res))
        .catch(err => Toast.show(err.message));
  }, [id]);

  useEffect(() => {
    if (rfi && rfi.projectId && id !== 'plangrid')
      http()
        .get(`/projects/${rfi.projectId}`)
        .then(res => setProject(res))
        .catch(err => Toast.show(err.message));
  }, [rfi, id]);

  useEffect(() => {
    if (id === 'plangrid') {
      const { rfi, project } = JSON.parse(
        localStorage.getItem('_plangrid_rfi')
      );
      setProject(project);
      setRfi({
        number: rfi.number,
        title: rfi.title,
        dueDate: rfi.due_at,
        questionText: rfi.question,
        questionHtml: null,
        answer: rfi.answer,
        users: rfi.assigned_to.map(user => ({
          id: user.uid,
          email: user.email
        }))
      });
    }
  }, [id]);

  const bold = { fontWeight: 600, marginBottom: 10 };
  const border = (style = 'dashed') => (
    <div style={{ borderBottom: `1px ${style} #000`, marginBottom: 10 }} />
  );
  if (!project || !rfi) return null;
  return (
    <div>
      <div
        className={classes.hideOnPrint}
        style={{ position: 'fixed', top: 10, right: 10 }}
      >
        <IconButton
          style={{ background: colors.paper }}
          onClick={() => window.print()}
        >
          <GetAppIcon />
        </IconButton>
      </div>
      <div
        style={{
          background: '#fff',
          color: '#000',
          maxWidth: 800,
          margin: 'auto',
          padding: 20
        }}
      >
        <Typography style={{ ...bold, marginBottom: null }}>
          {project.projectNumber} - {project.name}
        </Typography>
        <Typography>{project.address}</Typography>
        {border('solid')}
        <Typography variant="h3" style={{ margin: '10px 0' }}>
          RFI #{rfi.number} - {rfi.title}
        </Typography>
        {!!rfi.dueDate && (
          <Typography style={{ marginBottom: 10 }}>
            Due
            <br />
            {moment(rfi.dueDate).format('LL')}
          </Typography>
        )}
        {!!rfi.users.length && (
          <>
            <Typography style={{ ...bold, marginBottom: null }}>
              Assigned to
            </Typography>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}
            >
              {rfi.users.map(user => (
                <div key={user.id}>
                  {!!user.name && <Typography>{user.name}</Typography>}
                  <Typography>{user.email}</Typography>
                </div>
              ))}
            </div>
          </>
        )}
        {border()}
        <Typography style={bold}>Question</Typography>
        {rfi.questionHtml ? (
          <div dangerouslySetInnerHTML={{ __html: rfi.questionHtml }} />
        ) : (
          <Typography>{rfi.questionText}</Typography>
        )}
        {!!rfi.answer && (
          <>
            {border()}
            <Typography style={bold}>Answer</Typography>
            <Typography>{rfi.answer}</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default PrintRfi;
