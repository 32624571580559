import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import { CustomInput, Toast } from 'components';
import PublishIcon from '@material-ui/icons/Publish';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import Axios from 'axios';

const AddFile = ({ open, onClose, onUpload }) => {
  const { projectDetails } = useContext(ProjectDetailsContext);
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [loadingText, setLoadingText] = useState(null);

  const onFormSubmit = e => {
    e.preventDefault();
    getAwsUploadData();
  };

  const clear = () => {
    setFile(null);
    setName(null);
    setType(null);
    setLoadingText(null);
  };

  const closeAndClear = () => {
    clear();
    onClose();
  };

  const selectFile = e => {
    if (e.target && e.target.files) {
      if (e.target.files.length > 1)
        return Toast.show("Can't select more than one file");
      setFile(e.target.files[0]);
    }
  };

  const getAwsUploadData = async () => {
    if (disabled) {
      return;
    }
    setLoadingText('Uploading...');
    const data = await http().post(
      `/plangrid/projects/upload/${projectDetails.projectId}`,
      {
        name,
        fileType: file.type,
        plangridProjectId: projectDetails.plangridProjectId,
        uploadPath: type
      }
    );
    const aws = data.aws_post_form_arguments;
    const formData = new FormData();
    aws.fields.forEach(({ name, value }) => {
      formData.set(name, value);
    });
    formData.set('file', file);

    let uploadRes;
    try {
      uploadRes = await Axios.post(aws.action, formData);
    } catch (err) {
      try {
        uploadRes = await Axios.get(data.webhook_url);
      } catch (err) {}
    }
    if (uploadRes) onUpload({ ...uploadRes.data, uploadType: type });
  };

  useEffect(() => {
    if (!open) clear();
  }, [open]);

  const disabled =
    !name ||
    !file ||
    !projectDetails.projectId ||
    !projectDetails.plangridProjectId ||
    !!loadingText;

  return (
    <Dialog maxWidth="sm" fullWidth onClose={closeAndClear} open={open}>
      <DialogTitle>Upload File</DialogTitle>
      <div style={{ padding: 16 }}>
        <CustomInput label="Name" value={name} onChange={val => setName(val)} />
        <CustomInput
          label="Type"
          type="dropdown"
          options={[
            { value: 'attachments', label: 'Document' },
            { value: 'photos', label: 'Photo' }
          ]}
          value={type}
          onChange={val => setType(val)}
        />
      </div>
      <form onSubmit={onFormSubmit}>
        <label htmlFor="upload-photo">
          <input
            onChange={selectFile}
            style={{ display: 'none' }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            disabled={!type || !name}
          />

          <ListItem button disabled={!type || !name}>
            <ListItemIcon>
              <PublishIcon />
            </ListItemIcon>
            <ListItemText primary={'Select File'} />
          </ListItem>
        </label>
        <DialogActions>
          <Button
            disabled={disabled}
            color="primary"
            type="submit"
            variant="contained"
          >
            {loadingText ? (
              <>
                <CircularProgress style={{ marginRight: 10 }} size={20} />
                {loadingText}
              </>
            ) : (
              'Save'
            )}
          </Button>
          <Button onClick={closeAndClear} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddFile;
