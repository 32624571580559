import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, CustomInput } from 'components';

const UpdateNumbers = props => {
  const { projectDetails, setProjectDetails, open, onClose } = props;

  const [contractValue, setContractValue] = useState(0);
  const [estimatedLaborHours, setEstimatedLaborHours] = useState(0);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [estimatedLaborCost, setEstimatedLaborCost] = useState(0);
  const [estimatedMaterialCost, setEstimatedMaterialCost] = useState(0);

  const handleOpen = () => {
    setContractValue(projectDetails.contractValue || 0);
    setEstimatedLaborHours(projectDetails.estimatedLaborHours || 0);
    setEstimatedCost(projectDetails.estimatedCost || 0);
    setEstimatedLaborCost(projectDetails.estimatedLaborCost || 0);
    setEstimatedMaterialCost(projectDetails.estimatedMaterialCost || 0);
  };

  const save = () => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        contractValue,
        estimatedLaborHours,
        estimatedCost,
        estimatedLaborCost,
        estimatedMaterialCost
      })
      .then(res => {
        setProjectDetails(res);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="body"
      TransitionProps={{ onEnter: handleOpen }}
    >
      <DialogTitle>Project Numbers</DialogTitle>
      <DialogContent>
        <CustomInput
          label="Contract Value"
          value={contractValue}
          onChange={setContractValue}
          type="currency"
        />
        <CustomInput
          label="Estimated Cost"
          value={estimatedCost}
          onChange={setEstimatedCost}
          type="currency"
        />
        <CustomInput
          label="Estimated Labor Cost"
          value={estimatedLaborCost}
          onChange={setEstimatedLaborCost}
          type="currency"
        />
        <CustomInput
          label="Estimated Material Cost"
          value={estimatedMaterialCost}
          onChange={setEstimatedMaterialCost}
          type="currency"
        />
        <CustomInput
          label="Estimated Labor Hours"
          value={estimatedLaborHours}
          onChange={setEstimatedLaborHours}
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateNumbers;
