import React, { useState, useEffect } from 'react';
import {
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import SortableSigners from './components/SortableSigners.component';
import Document from './components/Document.component';
import VerifySend from './components/VerifySend.component';
import Preview from './components/Preview.component';
import SendIcon from '@material-ui/icons/Send';
import Signer from './components/Signer.component';
import Reviewers from './components/Reviewers.component';

const ViewEditEsign = props => {
  const { onClose, fileId, isTemplate } = props;

  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const [fullDocument, setFullDocument] = useState(null);
  const [signers, setSigners] = useState([]);
  const [fields, setFields] = useState([]);
  const [verifySendModalOpen, setVerifySendModalOpen] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    http()
      .get('/esign-documents', {
        params: {
          fileId
        }
      })
      .then(res => setDocument(res[0]))
      .catch(e => Toast.show(e.message));
  }, [fileId]);

  useEffect(() => {
    if (!document) return;

    http()
      .get('/esign-documents/' + document.id)
      .then(res => setFullDocument(res))
      .catch(e => Toast.show(e.message));

    http()
      .get('/esign-documents/' + document.id + '/signers')
      .then(res => {
        setSent(res.some(s => s.sentAt));
        setSigners(res);
      })
      .catch(e => Toast.show(e.message));

    http()
      .get('/esign-documents/' + document.id + '/fields')
      .then(res => setFields(res))
      .catch(e => Toast.show(e.message));
  }, [document]);

  const createEsignDoc = () => {
    if (loading) return;
    setLoading(true);

    http()
      .post('/esign-documents', { fileId, isTemplate })
      .then(res => setDocument(res))
      .catch(e => Toast.show(e.message))
      .finally(() => setLoading(false));
  };

  const sendDocument = ({ resend }) => {
    if (loading) return;
    setLoading(true);

    http()
      .put('/esign-documents/' + document.id + '/send', null, {
        params: {
          resend: resend
        }
      })
      .then(() => {
        Toast.show('Emails sent.');
        setVerifySendModalOpen(false);
        setSent(true);
      })
      .catch(e => Toast.show(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <VerifySend
        open={verifySendModalOpen}
        onClose={() => setVerifySendModalOpen(false)}
        onConfirm={sendDocument}
      />
      <DialogTitle variant="h5">
        <Grid container justifyContent="space-between">
          <div>eSign PDF{isTemplate && document && ` - ${document.id}`}</div>
          <div>
            <Button variant="outlined" onClick={onClose}>
              Done
            </Button>
            {document &&
              !document.isTemplate &&
              !signers.every(s => s.completedAt) && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 8 }}
                  onClick={() => {
                    if (signers.some(s => !s.email)) {
                      alert(
                        'All signers must be assigned to a user before sending.'
                      );
                    } else if (sent) {
                      sendDocument({ resend: true });
                    } else {
                      setVerifySendModalOpen(true);
                    }
                  }}
                >
                  <SendIcon style={{ marginRight: 8 }} />
                  {sent ? 'Resend Emails' : 'Send'}
                </Button>
              )}
          </div>
        </Grid>
      </DialogTitle>

      <Divider />
      {!document && (
        <DialogContent>
          <div style={{ maxWidth: 500, margin: 'auto' }}>
            <Typography align="center" variant="h5" gutterBottom>
              Would you like to electronically sign this document?
            </Typography>
            <Typography align="center" variant="body2" gutterBottom>
              This will modify the original document and is irreversible. Make a
              copy of the document before signing if you would like to keep the
              original document intact.
            </Typography>

            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              size="small"
              fullWidth
              onClick={createEsignDoc}
            >
              {loading ? 'Please Wait...' : 'eSign Document'}
            </Button>
          </div>
        </DialogContent>
      )}

      {document && sent && (
        <DialogContent style={{ overflowY: 'initial' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                Signers
              </Typography>
              {signers.map(signer => (
                <Signer signer={signer} key={signer.id} />
              ))}
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                Document
              </Typography>
              {fullDocument ? (
                <Preview document={fullDocument} />
              ) : (
                <Typography color="textSecondary">
                  Loading document...
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}

      {document && !sent && (
        <DialogContent style={{ overflowY: 'initial' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" gutterBottom>
                Signers
              </Typography>
              <SortableSigners
                isTemplate={document.isTemplate}
                signers={signers}
                document={document}
                onUpdate={setSigners}
              />
              {!document.isTemplate && <Reviewers document={document} />}
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                Document Fields
              </Typography>
              {fullDocument ? (
                <Document
                  base64File={fullDocument.base64File}
                  signers={signers}
                  fields={fields}
                  document={document}
                  onUpdateFields={setFields}
                />
              ) : (
                <Typography color="textSecondary">
                  Loading document...
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions />
    </React.Fragment>
  );
};

export default ViewEditEsign;
