import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  DashboardWrapper,
  DropdownButton,
  ProjectFilterDropdown,
  Toast
} from 'components';
import {
  Typography,
  Button,
  Grid,
  Switch,
  List,
  ListItem,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import ScheduleTable from './components/ScheduleTableVirtualized.component';
import moment from 'moment';
import http from 'helpers/http.helper';
import { useColors } from 'helpers/theme.helper';
import { useUsers } from 'hooks/useUsers.hook';
import AreaSelector from './components/AreaSelector.component';
import filterByAreaName from './helpers/filterByAreaName.helper';

const sortByIdKeys = {
  spm: 'seniorProjectManagerId',
  pm: 'projectManagerId',
  lt: 'leadTechnicianId'
};

const MasterSchedule = () => {
  const [projects, setProjects] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [confirmedOnly, setConfirmedOnly] = useState(true);
  const [months, setMonths] = useState(3);

  const [sortBy, setSortBy] = useState('spm');
  const [selectedAreaNames, setSelectedAreaNames] = useState([]);
  const { users } = useUsers();

  const dropdownRef = useRef(null);
  const colors = useColors();
  const [startDay, setStartDay] = useState(
    moment()
      .startOf('week')
      .toDate()
  );

  const monthsToDays = useCallback(() => {
    const date = moment(startDay);
    let days =
      moment(date)
        .endOf('month')
        .diff(date, 'days') + 1;
    date.add(1, 'month').startOf('month');
    for (let i = 0; i < months; i++) {
      days += date.daysInMonth();
      date.add(1, 'month');
    }
    return days;
  }, [months, startDay]);

  const addMonths = change =>
    setStartDay(date =>
      moment(date)
        .add(change, 'month')
        .toDate()
    );

  useEffect(() => {
    let updated = false;
    const days = monthsToDays();
    const start = moment(startDay).format();
    const end = moment(start)
      .add(days, 'days')
      .format();

    http()
      .get(`/projects/schedule/query?start=${start}&end=${end}`)
      .then(({ projects, tasks, allTasks }) => {
        if (updated) return;
        setAllTasks(allTasks);
        setTasks(tasks);
        setProjects(projects.sort(sortProjects(sortOrders.spm)));
      })
      .catch(err => Toast.show(err.message));

    return () => {
      updated = true;
    };
  }, [startDay, monthsToDays]);

  const onFilterProjects = useCallback(
    filteredProjects => {
      const idMap = {};
      filteredProjects.forEach(({ id }) => (idMap[id] = true));
      setFilteredTasks(allTasks.filter(task => idMap[task.projectId]));
      const sortFn = sortProjects(sortOrders[sortBy]);
      const sorted = [...filteredProjects].sort(sortFn);
      const areaFiltered = filterByAreaName(
        sorted,
        users,
        sortByIdKeys[sortBy],
        selectedAreaNames
      );
      setFilteredProjects(areaFiltered);
    },
    [allTasks, sortBy, users, selectedAreaNames]
  );

  const clickSortBy = sortBy => () => {
    setSortBy(sortBy);
    if (dropdownRef.current) dropdownRef.current.close();
  };

  const onBackgroundStyle = {
    borderColor: colors.backgroundText,
    color: colors.backgroundText
  };

  const confirmedFilteredProjects = filteredProjects.filter(
    project => project.scheduleType !== 'potential'
  );

  return (
    <DashboardWrapper style={{ height: 'calc(100vh - 70px)' }}>
      <div style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8
          }}
        >
          <Typography component="h1" variant="h4">
            Master Schedule
          </Typography>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Contract Work</Grid>
              <Grid item>
                <Switch
                  checked={!confirmedOnly}
                  onChange={() => setConfirmedOnly(old => !old)}
                  name="checkedC"
                />
              </Grid>
              <Grid item>+ Potential POs</Grid>
            </Grid>
          </Typography>
          <ProjectFilterDropdown
            projects={projects}
            onUpdate={onFilterProjects}
            localStorageRootKey={'MasterSchedule'}
          />
        </div>
        <div
          style={{
            height: 50,
            padding: 5,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <Button
              style={{ ...onBackgroundStyle, marginLeft: 5 }}
              onClick={() => addMonths(-1)}
              variant="outlined"
            >
              Previous Month
            </Button>
            <Button
              onClick={() =>
                setStartDay(
                  moment()
                    .startOf('week')
                    .toDate()
                )
              }
              style={{ ...onBackgroundStyle, marginLeft: 5 }}
              variant="outlined"
            >
              Today
            </Button>
            <Button
              onClick={() => addMonths(1)}
              style={{ ...onBackgroundStyle, marginLeft: 5 }}
              variant="outlined"
            >
              Next Month
            </Button>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <DropdownButton
                ref={dropdownRef}
                ButtonProps={{
                  style: onBackgroundStyle,
                  variant: 'outlined'
                }}
                PopoverProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                buttonText={`Sort By... (${sortByLabel[sortBy]})`}
              >
                <List>
                  <ListItem button onClick={clickSortBy('spm')}>
                    <ListItemText primary="Senior Project Manager" />
                  </ListItem>
                  <ListItem button onClick={clickSortBy('pm')}>
                    <ListItemText primary="Project Manager" />
                  </ListItem>
                  <ListItem button onClick={clickSortBy('lt')}>
                    <ListItemText primary="Lead Technician" />
                  </ListItem>
                </List>
              </DropdownButton>
            </div>
            <div style={{ paddingRight: 5 }} />
            <div>
              <AreaSelector
                selectedNames={selectedAreaNames}
                setSelectedNames={setSelectedAreaNames}
              />
            </div>
          </div>
          <div>
            <Button
              style={onBackgroundStyle}
              onClick={() => setMonths(1)}
              variant="outlined"
            >
              1 month
            </Button>
            <Button
              onClick={() => setMonths(2)}
              style={{
                ...onBackgroundStyle,
                marginLeft: 5
              }}
              variant="outlined"
            >
              2 months
            </Button>
            <Button
              onClick={() => setMonths(3)}
              style={{
                ...onBackgroundStyle,
                marginLeft: 5
              }}
              variant="outlined"
            >
              3 months
            </Button>
          </div>
        </div>
        <ScheduleTable
          allProjects={projects}
          confirmedOnly={confirmedOnly}
          allTasks={tasks}
          filteredProjects={
            confirmedOnly ? confirmedFilteredProjects : filteredProjects
          }
          filteredTasks={filteredTasks}
          startDay={startDay}
          days={monthsToDays()}
          height="calc(100% - 99px)"
          sortBy={sortBy}
        />
      </div>
    </DashboardWrapper>
  );
};

const sortByLabel = {
  spm: 'SPM',
  pm: 'PM',
  lt: 'LT'
};

const sortOrders = {
  spm: ['seniorProjectManagerName', 'projectManagerName', 'leadTechnicianName'],
  pm: ['projectManagerName', 'seniorProjectManagerName', 'leadTechnicianName'],
  lt: ['leadTechnicianName', 'seniorProjectManagerName', 'projectManagerName']
};

function sortProjects(sortOrder = sortOrders.spm) {
  return (a, b) => {
    const [key1, key2, key3] = sortOrder;

    const res1 = sortKey(key1, a, b);
    if (!!res1) return res1;

    const res2 = sortKey(key2, a, b);
    if (!!res2) return res2;

    const res3 = sortKey(key3, a, b);
    if (!!res3) return res3;

    return sortKey('projectNumber', a, b) || 0;
  };
}

function sortKey(key, a, b) {
  a = `${a[key] || ''}`.toLowerCase().trim();
  b = `${b[key] || ''}`.toLowerCase().trim();
  if (a && b) {
    if (a < b) return -1;
    if (a > b) return 1;
    if (a === b) return 0;
  }
  if (!a && !b) return 0;
  if (a) return -1;
  if (b) return 1;
  return 0;
}

export default MasterSchedule;
