import React, { useCallback, useEffect, useState } from 'react';
import {
  CustomInput,
  DashboardWrapper,
  NoItemsResult,
  Toast
} from 'components';
import {
  Button,
  DialogActions,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import { Link, useRouteMatch } from 'react-router-dom';
import http from 'helpers/http.helper';
import QuizQuestion from '../components/QuizQuestion.component';
import { v4 as uuid } from 'uuid';

export default function EditQuiz() {
  const [quiz, setQuiz] = useState(null);
  const [passingScore, setPassingScore] = useState(-1);
  let match = useRouteMatch();

  useEffect(() => {
    if (match.params.quizId) {
      http()
        .get(`/training-quizzes/${match.params.quizId}`)
        .then(quiz => {
          setPassingScore(
            quiz.passingQuestionCount === null ? -1 : quiz.passingQuestionCount
          );
          setQuiz(quiz);
        })
        .catch(Toast.showErr);
    }
  }, [match.params.quizId]);

  const updatePassingScore = useCallback(
    updatedScore => {
      setPassingScore(updatedScore);
      http()
        .put(`/training-quizzes/${match.params.quizId}`, {
          passingQuestionCount: updatedScore === -1 ? null : updatedScore
        })
        .catch(Toast.showErr);
    },
    [match.params.quizId]
  );

  useEffect(() => {
    if (quiz && passingScore > quiz.questions.length) {
      updatePassingScore(quiz.questions.length);
    }
  }, [passingScore, quiz, updatePassingScore]);

  if (!quiz) return <DashboardWrapper />;

  const addQuestion = () => {
    const newQuestion = {
      prompt: 'New Question...',
      id: uuid(),
      options: [{ id: uuid(), text: 'Default Option', correct: false }],
      timeLimitSeconds: null
    };
    setQuiz(oldQuiz => ({
      ...oldQuiz,
      questions: [...oldQuiz.questions, newQuestion]
    }));
  };

  const onEditQuestion = questionIdx => updatedQuestion => {
    const copy = { ...quiz };
    copy.questions[questionIdx] = updatedQuestion;
    setQuiz(copy);

    http()
      .put(`/training-quizzes/${match.params.quizId}`, {
        questions: copy.questions
      })
      .catch(Toast.showErr);
  };

  const onRemoveQuestion = questionIdx => () => {
    const copy = { ...quiz };
    copy.questions.splice(questionIdx, 1);
    setQuiz(copy);

    http()
      .put(`/training-quizzes/${match.params.quizId}`, {
        questions: copy.questions
      })
      .catch(Toast.showErr);
  };

  const createPassingScoreOptions = () => {
    const options = [
      {
        value: -1,
        label:
          'No passing score (does not trigger module completion when submitted)'
      },
      { value: 0, label: '0.0%' }
    ];
    const questionCount = quiz.questions.length;
    for (let correctCount = 1; correctCount <= questionCount; correctCount++) {
      options.push({
        value: correctCount,
        label: `${((correctCount / questionCount) * 100).toFixed(1)}%`
      });
    }
    return options;
  };

  return (
    <DashboardWrapper>
      <div
        style={{
          paddingBottom: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h4">{quiz.moduleName} - Quiz</Typography>
        <Link to="/training-groups">
          <Button variant="contained">Back to trainings</Button>
        </Link>
      </div>
      <div>
        <CustomInput
          label="Passing Score"
          type="dropdown"
          value={passingScore}
          getBooleanValue={val => val}
          onChange={updatePassingScore}
          options={createPassingScoreOptions()}
        />
      </div>
      {!quiz.questions.length ? (
        <Paper style={{ padding: 15 }}>
          <NoItemsResult type="questions" />
        </Paper>
      ) : (
        quiz.questions.map((question, idx) => (
          <React.Fragment key={question.id}>
            {idx !== 0 && <div style={{ paddingTop: 15 }} />}
            <QuizQuestion
              question={question}
              canEdit={true}
              onDelete={onRemoveQuestion(idx)}
              onEdit={onEditQuestion(idx)}
            />
          </React.Fragment>
        ))
      )}
      <DialogActions>
        <Button variant="contained" onClick={addQuestion}>
          Add Question
        </Button>
      </DialogActions>
    </DashboardWrapper>
  );
}
