import React, { useState, useEffect, useRef, useContext } from 'react';
import { CompactProjectDisplayTile } from 'components';
import { Paper, CircularProgress } from 'helpers/themeSafeMui.helper';
import { areEqual, VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useScrollMemory from 'hooks/useScrollMemory.hook';
import moment from 'moment';
import useFreshFn from 'hooks/useFreshFn.hook';
import { ChecklistStatusMapContext } from '../ProjectBoard.route';
import { useColors } from 'helpers/theme.helper';

const paddingHeight = 8;
const cardHeight = 80;
const openCardHeight = 150;
const itemHeight = cardHeight + paddingHeight * 2;
const openItemHeight = openCardHeight + paddingHeight * 2;

const toolTipProps = {
  enterDelay: 300,
  enterNextDelay: 300
};

function sortProject(a, b) {
  function getProjectDate(project) {
    if (project.bidDueDate) return moment(project.bidDueDate);
    return moment(project.created);
  }
  const aM = getProjectDate(a);
  const bM = getProjectDate(b);
  return aM.unix() - bM.unix();
}

const ProjectBoardColumn = React.memo(({ projects, loading, statusId }) => {
  const [openIdx, setOpenIdx] = useState(null);
  const [sortedProjects, setSortedProjects] = useState([]);

  const listRef = useRef(null);
  const [scrollRef] = useScrollMemory(`project-pipeline.${statusId}`);
  scrollRef.current = listRef.current && listRef.current._outerRef;

  const toggleExpand = useFreshFn(idx => {
    setOpenIdx(oldIdx => (oldIdx === idx ? null : idx));
  });

  const getHeight = useFreshFn(idx =>
    idx === openIdx
      ? { itemHeight: openItemHeight, cardHeight: openCardHeight }
      : { itemHeight, cardHeight }
  );

  const [listItemData, setListItemData] = useState({
    sortedProjects,
    toggleExpand,
    getHeight,
    openIdx
  });

  useEffect(() => {
    setSortedProjects([...projects].sort(sortProject));
  }, [projects]);

  useEffect(() => {
    if (listRef.current && listRef.current.resetAfterIndex)
      if (openIdx) {
        listRef.current.resetAfterIndex(openIdx);
      } else {
        listRef.current.resetAfterIndex(0);
      }
    return () => {
      if (listRef.current && listRef.current.resetAfterIndex)
        if (openIdx) {
          listRef.current.resetAfterIndex(openIdx);
        } else {
          listRef.current.resetAfterIndex(0);
        }
    };
  }, [openIdx]);

  useEffect(() => {
    setListItemData({ sortedProjects, toggleExpand, getHeight, openIdx });
  }, [sortedProjects, toggleExpand, getHeight, openIdx]);

  if (loading)
    return (
      <AutoSizer>
        {({ height, width }) => (
          <div
            style={{
              height,
              width,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
      </AutoSizer>
    );

  return (
    <ListItemContext.Provider value={listItemData}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={ref => (listRef.current = ref)}
            itemCount={sortedProjects.length}
            itemSize={idx => getHeight(idx).itemHeight}
            estimatedItemSize={itemHeight}
            height={height}
            width={width}
          >
            {ListItem}
          </List>
        )}
      </AutoSizer>
    </ListItemContext.Provider>
  );
});

const ListItemContext = React.createContext();

const ListItem = React.memo(({ index: idx, style }) => {
  const { sortedProjects, getHeight, toggleExpand, openIdx } = useContext(
    ListItemContext
  );
  const { checklistStatusMap } = useContext(ChecklistStatusMapContext);
  const project = sortedProjects[idx];
  const checklistColor = project ? checklistStatusMap[project.id] : null;
  const colors = useColors();
  const statusColor =
    checklistColor === 'red'
      ? colors.error
      : checklistColor === 'yellow'
      ? colors.yellow
      : checklistColor === 'green'
      ? colors.success
      : undefined;

  return (
    <div
      style={{
        ...style,
        height: getHeight(idx).itemHeight
      }}
    >
      <Paper
        style={{
          height: getHeight(idx).cardHeight,
          margin: `${paddingHeight}px 4px`,
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {!!project && (
          <CompactProjectDisplayTile
            link
            project={project}
            showTooltip={true}
            statusColor={statusColor}
            tooltipProps={toolTipProps}
            compact={idx !== openIdx}
            handleExpand={toggleExpand}
            handleExpandArg={idx}
          />
        )}
      </Paper>
    </div>
  );
}, areEqual);

export default ProjectBoardColumn;
