import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Button, DialogActions, Divider } from '@material-ui/core';
import ChecklistModalItem from './ChecklistModalItem.component';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import {
  ProjectContext,
  ProjectChecklistContext
} from 'routes/Projects/context/Project.context';
import useAutomatedChecklist from './useAutomatedChecklist.hook';

export default function ChecklistModal({ open, onClose }) {
  const currentUser = useCurrentUser();
  const { project } = useContext(ProjectContext);
  const automatedItems = useAutomatedChecklist();
  const { projectChecklist: items, setProjectChecklist: setItems } = useContext(
    ProjectChecklistContext
  );

  const canSync = currentUser.hasPermission('checklist_tier_2');

  const resetProjectItems = async () => {
    try {
      const updated = await http().put(
        `/projects/${project.id}/checklist-items/sync`
      );

      setItems(updated);
      Toast.show('Project Checklist Synced.');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Project Checklist</DialogTitle>
      <Divider />
      <DialogContent>
        {automatedItems.map(item => (
          <ChecklistModalItem required locked key={item.id} item={item} />
        ))}
        {items.map(item => (
          <ChecklistModalItem
            required={
              !item.optional &&
              !!(item.statusOrder || item.statusOrder === 0) &&
              item.statusOrder <= project.statusOrder
            }
            key={item.id}
            item={item}
          />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        {canSync && (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={resetProjectItems}
            >
              Reset (sync) Checklist
            </Button>
          </>
        )}
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
