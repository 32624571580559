import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { IconButton, Typography, Button } from 'helpers/themeSafeMui.helper';
import { saveAs } from 'file-saver';

import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';

const Preview = props => {
  const { document } = props;

  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [originalHeight, setOriginalHeight] = useState(1);
  const [originalWidth, setOriginalWidth] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState(1);

  return (
    <div ref={el => el && setWrapperWidth(el.clientWidth)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: 8,
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginRight: 8 }}
          >
            Page {activePage} of {totalPages}
          </Typography>
          <IconButton
            size="small"
            disabled={activePage === 1}
            onClick={() => setActivePage(activePage - 1)}
          >
            <LeftIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={activePage === totalPages}
            onClick={() => setActivePage(activePage + 1)}
          >
            <RightIcon />
          </IconButton>
        </div>
        <div>
          <Button
            onClick={() =>
              saveAs(
                `data:application/pdf;base64,${document.base64File}`,
                `${document.name}_signed.pdf`
              )
            }
            color="secondary"
            variant="outlined"
          >
            Download File
          </Button>
          {document.base64CertificateFile && (
            <Button
              style={{ marginLeft: 8 }}
              color="secondary"
              variant="outlined"
              onClick={() =>
                saveAs(
                  `data:application/pdf;base64,${document.base64CertificateFile}`,
                  `${document.name}_certificate.pdf`
                )
              }
            >
              Download Certificate
            </Button>
          )}
        </div>
      </div>

      <div
        style={{
          width: wrapperWidth,
          minHeight: (wrapperWidth * originalHeight) / originalWidth,
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
          position: 'relative'
        }}
      >
        <Document
          file={`data:application/pdf;base64,${document.base64File}`}
          onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
          loading={null}
        >
          <Page
            renderAnnotationLayer={true}
            pageNumber={activePage}
            loading={null}
            width={wrapperWidth}
            onLoadSuccess={page => {
              if (page.rotate === 90 || page.rotate === 270) {
                setOriginalHeight(page.originalWidth);
                setOriginalWidth(page.originalHeight);
              } else {
                setOriginalHeight(page.originalHeight);
                setOriginalWidth(page.originalWidth);
              }
            }}
          />
        </Document>
      </div>
    </div>
  );
};

export default Preview;
