import React from 'react';
import moment from 'moment';
import toCurrency from 'helpers/currency.helper';
import phoneHelper from 'helpers/phone.helper';
import { EncryptedValue, EncryptedFile } from 'components';

const FormatValue = ({ value, type, options, encryptedValue }) => {
  if (type === 'encrypted' || type === 'encryptedFile') {
    if (!encryptedValue) {
      return '---';
    }
  } else {
    if (!value && type !== 'boolean') {
      return '---';
    }
  }

  if (type === 'dropdown') {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : value;
  } else if (type === 'boolean') {
    return value ? 'Yes' : 'No';
  } else if (type === 'date') {
    return moment(value).format('LL');
  } else if (type === 'dateTime') {
    return moment(value).format('LLL');
  } else if (type === 'currency') {
    return toCurrency(value);
  } else if (type === 'tel') {
    return phoneHelper(value);
  } else if (type === 'percent') {
    return `${value} %`;
  } else if (type === 'encrypted') {
    return <EncryptedValue value={encryptedValue} />;
  } else if (type === 'encryptedFile') {
    return <EncryptedFile file={encryptedValue} />;
  } else {
    return value;
  }
};

export default FormatValue;
