import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Paper,
  Button,
  ButtonGroup,
  TextField,
  InputAdornment,
  Input,
  Typography
} from 'helpers/themeSafeMui.helper';
import SearchIcon from '@material-ui/icons/Search';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { useColors } from 'helpers/theme.helper';

const TrainingModuleDropdown = props => {
  const { selectedTrainingModules, trainingGroups, onChange } = props;
  const colors = useColors();
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [wrapperRef, setWrapperRef] = useState(null);

  const trainingModules = trainingGroups.reduce(
    (modules, group) => modules.concat(group.trainingModules),
    []
  );

  useEffect(() => {
    const handler = e => {
      if (wrapperRef && !wrapperRef.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [wrapperRef]);

  const toggleModule = (id, checked) => {
    if (checked) {
      const tempModules = selectedTrainingModules.filter(
        trainingModule => trainingModule.id !== id
      );
      onChange(tempModules);
    } else {
      const trainingModule = trainingModules.find(current => current.id === id);
      onChange(selectedTrainingModules.concat(trainingModule));
    }
  };

  const toggleGroup = (id, checked) => {
    const trainingGroup = trainingGroups.find(current => current.id === id);
    if (checked) {
      const tempModules = selectedTrainingModules.filter(
        trainingModule =>
          !trainingGroup.trainingModules.some(el => el.id === trainingModule.id)
      );
      onChange(tempModules);
    } else {
      onChange(selectedTrainingModules.concat(trainingGroup.trainingModules));
    }
  };

  return (
    <div style={{ position: 'relative', width: 350 }} ref={setWrapperRef}>
      <Input
        inputProps={{ style: { cursor: 'pointer' } }}
        fullWidth
        readOnly
        style={{ cursor: 'pointer' }}
        value={`Displaying ${selectedTrainingModules.length} training modules`}
        onClick={() => setIsOpen(!isOpen)}
        endAdornment={
          <InputAdornment position="end">
            <ArrowIcon />
          </InputAdornment>
        }
      />
      {isOpen && (
        <Paper
          style={{
            padding: 15,
            position: 'absolute',
            top: 40,
            right: 0,
            zIndex: 100
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Filter"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            name="search"
            margin="dense"
            style={{ margin: 0, marginBottom: 10 }}
            fullWidth
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
          />
          <ButtonGroup color="primary" fullWidth style={{ paddingBottom: 10 }}>
            <Button onClick={() => onChange(trainingModules)}>All</Button>
            <Button onClick={() => onChange([])}>None</Button>
          </ButtonGroup>
          <div style={{ maxHeight: 350, overflowY: 'auto' }}>
            {trainingGroups
              .filter(
                trainingGroup =>
                  trainingGroup.name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  trainingGroup.trainingModules.some(trainingModule =>
                    trainingModule.name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  )
              )
              .map(trainingGroup => {
                const checked = trainingGroup.trainingModules.every(
                  trainingModule =>
                    selectedTrainingModules.some(
                      selected => selected.id === trainingModule.id
                    )
                );
                return (
                  <div style={{ marginBottom: 16 }} key={trainingGroup.id}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: `1px solid ${colors.grey}`
                      }}
                    >
                      <Checkbox
                        checked={checked}
                        onChange={() => toggleGroup(trainingGroup.id, checked)}
                      />
                      <Typography style={{ fontWeight: 'bold' }}>
                        {trainingGroup.name}
                      </Typography>
                    </div>
                    {trainingGroup.trainingModules
                      .filter(trainingModule =>
                        trainingModule.name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                      .map(trainingModule => {
                        const checked = selectedTrainingModules.some(
                          selected => selected.id === trainingModule.id
                        );
                        return (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            key={trainingModule.id}
                          >
                            <Checkbox
                              checked={checked}
                              onChange={() =>
                                toggleModule(trainingModule.id, checked)
                              }
                            />
                            <Typography>{trainingModule.name}</Typography>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </Paper>
      )}
    </div>
  );
};

export default TrainingModuleDropdown;
