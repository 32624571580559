import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, Rollup } from 'components';
import EditableRow from '../components/EditableRow.component';
import { useSelector } from 'react-redux';
import {
  ProjectDetailsContext,
  ProjectScheduleTasksContext
} from 'routes/Projects/context/Project.context';
import safeParse from 'helpers/safeParse.helper';
import trunc from 'helpers/trunc.helper';

export default function ProjectScheduleCard() {
  const user = useSelector(state => state.user);
  const tier2 = user.hasPermission('projects_tier_2');
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [fields, setFields] = useState({ ...projectDetails });
  const [editing, setEditing] = useState(false);
  const { projectScheduleTasks } = useContext(ProjectScheduleTasksContext);

  const totalTaskHours = projectScheduleTasks.reduce(
    (total, task) => total + safeParse(task.hours),
    0
  );

  const totalHours =
    safeParse(projectDetails.estimatedCoLaborHours) +
    safeParse(projectDetails.estimatedLaborHours);

  useEffect(() => {
    setFields({ ...projectDetails });
  }, [projectDetails, editing]);

  const getUUID = string => {
    if (!string) return null;
    const match = string.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    if (!match) return null;
    if (!match[0]) return null;
    return match[0];
  };

  const validPlangridLink = Boolean(getUUID(fields.plangridLink));

  const save = () => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        bidDueDate: fields.bidDueDate,
        planRoomLink: fields.planRoomLink || '',
        plangridLink: fields.plangridLink || '',
        customerPO: fields.customerPO || '',
        invoiceType: fields.invoiceType || '',
        retainagePercentage: fields.retainagePercentage || 0,
        start: fields.start,
        end: fields.end,
        estimatedCrew: fields.estimatedCrew || null,
        estimatedLaborHours: fields.estimatedLaborHours || null,
        perDiem: fields.perDiem || '',
        scopeOfWork: fields.scopeOfWork || ''
      })
      .then(res => {
        if (validPlangridLink && !projectDetails.plangridProjectId) {
          return http().post(
            `/plangrid/projects/${projectDetails.projectId}/link/`,
            { plangridProjectId: getUUID(fields.plangridLink) }
          );
        } else {
          return res;
        }
      })
      .then(res => {
        setProjectDetails(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  };

  const workDaysCalculation =
    (safeParse(projectDetails.estimatedLaborHours) +
      safeParse(projectDetails.estimatedCoLaborHours)) /
    safeParse(projectDetails.estimatedCrew) /
    safeParse(projectDetails.scheduleDaysPerWeek, 10);

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Project Schedule</Typography>}
      localStorageKey="project_card_project_schedule"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        {tier2 && (
          <div>
            {editing ? (
              <Button variant="contained" color="secondary" onClick={save}>
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>

      <EditableRow
        editing={editing}
        label="Start Date"
        value={fields.start}
        onChange={start => setFields({ ...fields, start })}
        type="date"
      />
      <EditableRow
        editing={editing}
        label="Deadline"
        value={fields.end}
        onChange={end => setFields({ ...fields, end })}
        type="date"
      />
      <EditableRow
        editing={false}
        label="Projected End"
        value={fields.end}
        onChange={() => {}}
        type="date"
      />
      <EditableRow
        editing={editing}
        label="Crew"
        value={fields.estimatedCrew}
        onChange={estimatedCrew => setFields({ ...fields, estimatedCrew })}
        type="number"
      />
      <EditableRow
        editing={false}
        label="Work Days"
        value={trunc(workDaysCalculation, 3)}
        onChange={() => {}}
        type="number"
      />
      <EditableRow
        editing={editing}
        label={editing ? 'Project Hours (Excluding CO Hours)' : 'Project Hours'}
        value={editing ? fields.estimatedLaborHours : totalHours}
        onChange={estimatedLaborHours =>
          setFields({ ...fields, estimatedLaborHours })
        }
        type="number"
      />
      <EditableRow
        editing={false}
        label="Task Hours"
        value={totalTaskHours}
        onChange={() => {}}
        type="number"
      />
    </Rollup>
  );
}
