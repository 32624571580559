import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import { Toast, RichTextEditor } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { ProjectTeamContext } from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';

const PlangridRfiEmailModal = ({ rfi, open, onClose }) => {
  const { projectTeam } = useContext(ProjectTeamContext);
  const [recipients, setRecipients] = useState([]);
  const [subject, setSubject] = useState([]);
  const [options, setOptions] = useState([]);
  const [questionHtml, setQuestionHtml] = useState(rfi.question);
  const [loading, setLoading] = useState(false);
  const [inputOption, setInputOption] = useState('');
  const editorRef = useRef(null);
  const closeAndClear = () => {
    setRecipients([]);
    editorRef.current.reset();
    setSubject('');
    setQuestionHtml(rfi.question);
    onClose();
  };

  useEffect(() => {
    const getEmail = ({ email }) => email;
    const customerInitialContactEmails =
      projectTeam.customerInitialContactEmails || [];
    const customerOnSiteContactEmails =
      projectTeam.customerOnSiteContactEmails || [];
    const customerProjectManagerEmails =
      projectTeam.customerProjectManagerEmails || [];

    setOptions(
      [
        inputOption,
        ...customerInitialContactEmails.map(getEmail),
        ...customerOnSiteContactEmails.map(getEmail),
        ...customerProjectManagerEmails.map(getEmail),
        projectTeam.accountManagerEmail,
        projectTeam.leadTechnicianEmail,
        projectTeam.projectEstimatorEmail,
        projectTeam.projectManagerEmail
      ].filter(email => email)
    );
  }, [projectTeam, inputOption]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await http().post(`/plangrid/rfis/emails`, {
        subject,
        recipients,
        number: rfi.number,
        title: rfi.title,
        dueDate: rfi.due_at,
        questionHtml,
        assignedTo: rfi.assigned_to.map(user => user.email)
      });
      setLoading(false);
      Toast.show('Email Sent');
      closeAndClear();
    } catch (err) {
      setLoading(false);
      Toast.show(err.message);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth onClose={closeAndClear} open={open}>
      <DialogTitle>Email Rfi</DialogTitle>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: 16 }}>
          <TextField
            value={subject}
            onChange={e => setSubject(e.target.value)}
            fullWidth
            label="Subject"
            margin="normal"
          />
          <p style={{ paddingTop: 16, margin: 0, fontWeight: 500 }}>
            Format Email:
          </p>
          <RichTextEditor
            initialText={rfi.question}
            placeholder="Format Question"
            onChange={({ html }) => setQuestionHtml(html)}
            ref={editorRef}
          />
          <Autocomplete
            freeSolo
            options={options}
            selectOnFocus
            multiple
            value={recipients}
            onClose={e => {
              if (e.type === 'blur' && inputOption)
                setRecipients(old => [...old, inputOption]);
            }}
            onChange={(e, value) => {
              setRecipients(value ? value : []);
            }}
            onInputChange={(e, value) => {
              setInputOption(value);
            }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  label="Recipients"
                  margin="normal"
                />
              );
            }}
          />
        </div>
        <DialogActions>
          <Button
            disabled={loading}
            color="primary"
            type="submit"
            variant="contained"
          >
            {loading ? (
              <>
                <CircularProgress size={20} style={{ marginRight: 10 }} />{' '}
                Loading...
              </>
            ) : (
              'Send'
            )}
          </Button>
          <Button
            disabled={loading}
            onClick={closeAndClear}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PlangridRfiEmailModal;
