import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import RSA from 'node-rsa';
import LockIcon from '@material-ui/icons/LockOutlined';

const EncryptedFile = props => {
  const { value } = props;
  const [privateKey, setPrivateKey] = useState('');
  const [decrypted, setDecrypted] = useState(null);
  const [open, setOpen] = useState(false);

  const decrypt = () => {
    if (!window.sessionStorage.getItem('private_rsa_key') && !privateKey) {
      return setOpen(true);
    }
    if (privateKey) {
      window.sessionStorage.setItem('private_rsa_key', privateKey);
    }

    try {
      const key = new RSA(window.sessionStorage.getItem('private_rsa_key'));
      setDecrypted(key.decrypt(value, 'utf8'));
      setOpen(false);
    } catch (e) {
      Toast.show(e.message);
      window.sessionStorage.removeItem('private_rsa_key');
    }
  };

  useEffect(() => {
    setDecrypted(null);
    setOpen(false);
  }, [value]);

  if (!value) return null;
  if (decrypted) return decrypted;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enter Private Key</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            rows={10}
            multiline
            fullWidth
            value={privateKey}
            onChange={e => setPrivateKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={decrypt}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Link style={{ cursor: 'pointer' }} onClick={decrypt}>
        <LockIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} />
        View
      </Link>
    </React.Fragment>
  );
};

export default EncryptedFile;
