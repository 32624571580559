import React, { useCallback, useState, useEffect } from 'react';
import { DashboardWrapper, Toast } from 'components';
import http from 'helpers/http.helper';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import DrugTestLocation from './components/DrugTestLocation.component';
import LocationModal from './modals/DrugTestLocation.modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';

const EditDrugTestLocations = () => {
  const [locations, setLocations] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const getLocations = useCallback(() => {
    http()
      .get('/drug-test-locations')
      .then(locations => setLocations(locations))
      .catch(err => Toast.show(err.message));
  }, []);

  const updateLocation = location => updated =>
    setLocations(locations =>
      locations.map(mapLocation =>
        mapLocation.id === location.id ? updated : mapLocation
      )
    );
  const removeLocation = location => () =>
    setLocations(locations =>
      locations.filter(filterLocation => filterLocation.id !== location.id)
    );

  const createLocation = async location => {
    const newLocation = await http().post('/drug-test-locations', {
      ...location
    });
    setLocations(old => [newLocation, ...old]);
  };

  useEffect(() => getLocations(), [getLocations]);

  return (
    <DashboardWrapper>
      <LocationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={createLocation}
        title="Add Location"
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Typography component="h1" variant="h4">
          Drug Test Locations
        </Typography>
        <IconButton
          style={{ marginLeft: 5, marginRight: 5 }}
          onClick={() => setModalOpen(true)}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </IconButton>
        <Link to="/onboarding/drug-test-location" target="_blank">
          <Typography component="h1" variant="h5">
            Preview
          </Typography>
        </Link>
      </div>
      {locations.map((location, idx, { length }) => (
        <React.Fragment key={location.id}>
          <DrugTestLocation
            location={location}
            update={updateLocation(location)}
            onDelete={removeLocation(location)}
          />
          {idx !== length - 1 && <div style={{ paddingTop: 16 }} />}
        </React.Fragment>
      ))}
    </DashboardWrapper>
  );
};

export default EditDrugTestLocations;
