import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { useColors } from 'helpers/theme.helper';
import { useTheme } from 'helpers/themeSafeMui.helper';

const DragAndDrop = props => {
  const { children, onDrop, wrapperStyles, cardStyles, handleStyles } = props;
  const theme = useTheme();
  const colors = useColors();

  const styles = {
    wrapper: styles => ({
      ...wrapperStyles,
      ...styles
    }),
    card: styles => ({
      background: colors.paper,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      boxShadow: theme.shadows[1],
      ...cardStyles,
      ...styles
    }),
    handle: styles => ({
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      ...handleStyles,
      ...styles
    })
  };

  const onDragEnd = event => {
    const { destination, source } = event;

    // dropped outside the list or dropped in the same spot
    if (!destination || destination.index === source.index) return;

    const values = children.map(child => child.props.value);
    const removed = values.splice(source.index, 1)[0];
    values.splice(destination.index, 0, removed);

    onDrop(values);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable_id">
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={styles.wrapper(provided.droppableProps.style)}
          >
            {children.map((child, idx) => {
              const { id, wrapperStyle, ...rest } = child.props;
              return (
                <Draggable key={id} draggableId={id} index={idx}>
                  {provided => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={styles.card({
                        ...provided.draggableProps.style,
                        ...wrapperStyle
                      })}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={styles.handle(provided.dragHandleProps.style)}
                      >
                        <DragIndicator color="action" />
                      </div>
                      {React.createElement(child.type, rest)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDrop;
