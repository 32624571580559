import React, { useCallback, useEffect, useState } from 'react';
import { DashboardWrapper, Toast } from 'components';
import {
  Button,
  DialogActions,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import { useRouteMatch, useHistory } from 'react-router-dom';
import http from 'helpers/http.helper';
import QuizQuestion from '../components/QuizQuestion.component';
import Timer from '../components/Timer.component';
import { useSelector } from 'react-redux';

export default function AttemptQuiz() {
  const [quiz, setQuiz] = useState(null);
  const [lockedAnswers, setLockedAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(null);
  const user = useSelector(state => state.user);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (match.params.quizId) {
      http()
        .get(
          `/training-quizzes/${match.params.quizId}/review-attempt?userId=${user.id}`
        )
        .then(attempt => {
          if (attempt)
            history.replace(
              `/training-quizzes/${match.params.quizId}/review-attempt`
            );
          return http().get(`/training-quizzes/${match.params.quizId}/attempt`);
        })
        .then(setQuiz)
        .catch(Toast.showErr);
    }
  }, [match.params.quizId, user, history]);

  const submitAnswer = useCallback(() => {
    const question = quiz.questions[currentQuestionIdx];
    if (!question) return;
    setLockedAnswers(old => [
      ...old,
      currentAnswer
        ? currentAnswer
        : { optionId: null, questionId: question.id }
    ]);
    setCurrentAnswer(null);
    setCurrentQuestionIdx(old => old + 1);
  }, [currentAnswer, currentQuestionIdx, quiz]);

  useEffect(() => {
    if (!quiz || !quiz.questions || !quiz.questions.length) return;
    if (currentQuestionIdx === quiz.questions.length) {
      http()
        .post(`/training-quizzes/${match.params.quizId}/attempt`, {
          answers: lockedAnswers
        })
        .then(attempt => {
          history.push(
            `/training-quizzes/${match.params.quizId}/review-attempt`
          );
        })
        .catch(Toast.showErr);
    }
  }, [
    currentQuestionIdx,
    lockedAnswers,
    quiz,
    user,
    history,
    match.params.quizId
  ]);

  if (!quiz) return <DashboardWrapper />;

  if (currentQuestionIdx === null)
    return (
      <DashboardWrapper>
        <Paper>
          <Typography style={{ padding: 8 }} variant="h4">
            {quiz.moduleName} - Quiz
          </Typography>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentQuestionIdx(0)}
            >
              Start Quiz
            </Button>
          </DialogActions>
        </Paper>
      </DashboardWrapper>
    );

  if (!quiz.questions[currentQuestionIdx]) return <DashboardWrapper />;

  const timeLimit = quiz.questions[currentQuestionIdx].timeLimitSeconds;

  return (
    <DashboardWrapper>
      <Timer
        resetId={quiz.questions[currentQuestionIdx].id}
        limit={timeLimit}
        onTimeExceeded={submitAnswer}
      />
      <QuizQuestion
        canEdit={false}
        question={quiz.questions[currentQuestionIdx]}
        onSelect={optionId =>
          setCurrentAnswer({
            optionId,
            questionId: quiz.questions[currentQuestionIdx].id
          })
        }
      />
      <Button
        style={{ marginTop: 20 }}
        variant="contained"
        color="primary"
        onClick={submitAnswer}
      >
        Submit Answer
      </Button>
    </DashboardWrapper>
  );
}
