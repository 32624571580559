import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DndTableRow from './DndTableRow.component';
import DndTableHead from './DndTableHead.component';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export const DraggableContext = React.createContext();

export default function DndTable({ children, bodyProps, onDrop, ...rest }) {
  const classes = useStyles();

  const rows = React.Children.toArray(children).filter(
    child => child.type === DndTableRow
  );

  const onDragEnd = event => {
    const { destination, source } = event;

    // dropped outside the list or dropped in the same spot
    if (!destination || destination.index === source.index) return;

    const values = rows.map(child => child.props.value);
    const removed = values.splice(source.index, 1)[0];
    values.splice(destination.index, 0, removed);

    onDrop(values);
  };

  const [tableHead] = React.Children.toArray(children).filter(
    child => child.type === DndTableHead
  );

  const otherChildren = React.Children.toArray(children).filter(
    child => child.type !== DndTableHead && child.type !== DndTableRow
  );

  if (otherChildren.length > 0 && rows.length > 0) {
    throw new Error(
      "Can't have mix of draggable and non draggable children in DndTable"
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" {...rest}>
        {tableHead ? tableHead : null}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="table_droppable_id">
            {provided => (
              <TableBody
                {...bodyProps}
                {...provided.droppableProps}
                innerRef={provided.innerRef}
              >
                {rows.map((row, idx) => (
                  <Draggable
                    draggableId={row.props.id}
                    index={idx}
                    key={row.props.id}
                  >
                    {provided => (
                      <DraggableContext.Provider value={provided}>
                        {row}
                      </DraggableContext.Provider>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {otherChildren}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}
