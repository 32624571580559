import React, { useState, useEffect } from 'react';
import { Toast, CustomInput } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { useSelector } from 'react-redux';

function AreaMergeModal({ open, onClose, areaLogMap, onMerge }) {
  const areas = useSelector(state => state.areas);
  const [sourceAreaId, setSourceAreaId] = useState(null);
  const [destinationAreaId, setDestinationAreaId] = useState(null);
  const [preConfirm, setPreConfirm] = useState(true);

  const getArea = id => areas.find(area => area.id === id);

  const disabled =
    !sourceAreaId || !destinationAreaId || sourceAreaId === destinationAreaId;

  const recordCount = areaLogMap[sourceAreaId]
    ? areaLogMap[sourceAreaId].length
    : '?';

  useEffect(() => {
    setSourceAreaId(null);
    setDestinationAreaId(null);
    setPreConfirm(true);
  }, [open]);

  const merge = () => {
    if (preConfirm) return setPreConfirm(false);
    http()
      .put(`areas/${sourceAreaId}/merge`, {
        destinationId: destinationAreaId
      })
      .then(() => {
        onMerge();
        onClose();
        Toast.show('Job records updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Merge Areas</DialogTitle>
      <DialogContent>
        <CustomInput
          type="dropdown"
          label="Source"
          value={sourceAreaId}
          onChange={setSourceAreaId}
          options={areas.map(area => ({ value: area.id, label: area.name }))}
        />
        <CustomInput
          type="dropdown"
          label="Destination"
          value={destinationAreaId}
          onChange={setDestinationAreaId}
          options={areas.map(area => ({ value: area.id, label: area.name }))}
        />
        {!disabled && (
          <Typography style={{ marginTop: 10 }}>
            Change {recordCount} "{getArea(sourceAreaId).name}" job records to "
            {getArea(destinationAreaId).name}" jobs
          </Typography>
        )}
        {!preConfirm && (
          <Typography color="error" style={{ marginTop: 10 }}>
            Please confirm. There is no way to undo this.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          onClick={merge}
          disabled={disabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          {preConfirm ? 'Merge' : 'Confirm Merge'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AreaMergeModal;
