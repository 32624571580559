import React, { useEffect, useState, useCallback, useContext } from 'react';
import http from 'helpers/http.helper';
import { connect, useDispatch } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import moment from 'moment';
import {
  Avatar,
  Grid,
  IconButton,
  Paper,
  Divider,
  Typography
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Mail';
import ReplyIcon from '@material-ui/icons/Reply';
import { Toast, NoItemsResult, ExternalLink } from 'components';
import { ProjectContext } from '../../context/Project.context';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function EmailListItem(props) {
  const { email, projectId, refresh } = props;
  const [open, setOpen] = useState(false);
  const { project } = useContext(ProjectContext);
  const dispatch = useDispatch();

  const archiveEmail = () => {
    if (!window.confirm('Are you sure you want to delete this email?')) return;
    http()
      .delete(`projects/${projectId}/emails/${email.id}`)
      .then(() => refresh())
      .catch(err => Toast.show(err.message));
  };

  const openEmailModal = () =>
    dispatch(
      showModal({
        type: 'SEND_EMAIL',
        size: 'xl',
        props: {
          project: project,
          replyingTo: email
        }
      })
    );

  return (
    <Paper style={{ padding: 16 }}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar style={{ margin: 16 }}>
          <EmailIcon />
        </Avatar>

        <div style={{ flex: 1 }}>
          <Typography variant="h5">{email.subject}</Typography>
          <Typography variant="subtitle1">To: {email.receiverEmail}</Typography>
          <Typography variant="subtitle1">From: {email.senderEmail}</Typography>
          <Typography variant="subtitle1">
            Sent on: {moment(email.created).format('LL')}
          </Typography>
        </div>

        <IconButton
          onClick={() => {
            openEmailModal();
          }}
          style={{ margin: 16 }}
        >
          <ReplyIcon />
        </IconButton>

        <IconButton onClick={archiveEmail} style={{ margin: 16 }}>
          <DeleteIcon />
        </IconButton>

        <IconButton style={{ margin: 16 }} onClick={() => setOpen(!open)}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>

      {open && (
        <div>
          <Divider style={{ margin: '8px 0' }} />
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{ __html: email.emailContent }}
          />
          {email.attachments.map(attachment => (
            <Typography key={attachment.id}>
              <ExternalLink href={attachment.webViewLink}>
                {attachment.fileName}
              </ExternalLink>
            </Typography>
          ))}
        </div>
      )}
    </Paper>
  );
}

function ProjectEmailsTab(props) {
  const { showModal, projectId } = props;
  const [emails, setEmails] = useState([]);

  const getEmails = useCallback(() => {
    if (!projectId) return;
    http()
      .get(`/projects/${projectId}/emails`)
      .then(res => setEmails(res))
      .catch(err => Toast.show(err.message));
  }, [projectId]);

  useEffect(() => {
    getEmails();
  }, [getEmails]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ margin: 16 }}>
            {!emails.length && <NoItemsResult type="project emails" />}
          </Paper>
          {emails.map(email => (
            <EmailListItem
              key={email.id}
              email={email}
              projectId={projectId}
              refresh={getEmails}
              openModal={() =>
                showModal({
                  type: 'VIEW_PROJECT_EMAIL',
                  props: {
                    email: email
                  }
                })
              }
            />
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(
  ProjectEmailsTab
);
