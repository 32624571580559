import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from 'helpers/themeSafeMui.helper';
import { ProjectsContext } from '../context/Projects.context';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import useProjectShiftsGroups from '../hooks/useProjectShiftsGroups';
import {
  fromDateString,
  fromTimeString,
  getDefaultLunch,
  getShiftLengthHours
} from '../helpers/DateTime.helper';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import CrewSelector from '../components/CrewSelector.component';
import DowButtons from '../components/DowButtons.component';
import { UsersContext } from '../context/Users.context';
import useWeekUpdater from '../hooks/useWeekUpdater.hook';
import useUserIsScheduled from '../hooks/useUserIsScheduled.hook';
import CrewItem from '../components/CrewItem.component';
import { useColors } from 'helpers/theme.helper';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';
import AddIcon from '@material-ui/icons/Add';
import useShiftApi from '../hooks/useShiftApi.hook';
import { CustomInput, Toast } from 'components';
import TaskSelectorModal from './TaskSelector.modal';
import useWeekLunchTimes from '../hooks/useWeekLunchTimes.hook';
import CommonShiftsButton from '../components/CommonShiftsButtons.component';
import DeleteIcon from '@material-ui/icons/Delete';
import useDefaultGroup from '../hooks/useDefaultGroup.helper';
import WorkOrderListItem from '../components/WorkOrderListItem.component';
import useWorkOrders from '../hooks/useWorkOrders.hook';
import { useDispatch } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import useUsersDiff from '../hooks/useUsersDiff.hook';
import UpdateApprovedShift from './UpdateApprovedShifts.modal';

export default function ShiftWeekModal({
  open,
  onClose,
  projectId,
  selectedGroupIdx: groupIdxProp
}) {
  const colors = useColors();
  const { projectMap } = useContext(ProjectsContext);
  const {
    fetchSchdeules,
    dateWindow,
    userIdHoursMap,
    setDateWindow
  } = useContext(ShiftScheduleContext);
  const dispatch = useDispatch();
  const { userMap } = useContext(UsersContext);
  const project = projectMap[projectId];

  const userIsScheduled = useUserIsScheduled();
  const [selectedGroupIdx, setSelectedGroupIdx] = useState(null);
  const [activeDays, setActiveDays] = useState(() => Array(7).fill(false));
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [approved, setApproved] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [lunchStart, setLunchStart] = useState(null);
  const [lunchDurationMinutes, setLunchDurationMinutes] = useState(null);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [variedWorkOrderId, setVariedWorkOrderId] = useState(false);

  const [taskSelectorOpen, setTaskSelectorOpen] = useState(false);
  const { shiftDays } = useShiftApi();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lunchUpdated, setLunchUpdated] = useState(false);
  const [updateApprovedOpen, setUpdateApprovedOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const hasLunch = !!lunchStart || !!lunchDurationMinutes;

  const { workOrders, refresh: getWorkOrders } = useWorkOrders(projectId);
  const groups = useProjectShiftsGroups(projectId);
  const defaultGroup = useDefaultGroup(projectId);
  const selectedGroup =
    selectedGroupIdx === -1 ? defaultGroup : groups[selectedGroupIdx] || null;
  const [variance, weekLunchTimes] = useWeekLunchTimes(selectedGroup);

  const groupUserIds = selectedGroup ? selectedGroup.sharedUsers : [];
  const { removedUserIds, addedUserIds, stayingUserIds } = useUsersDiff(
    groupUserIds,
    sharedUsers
  );

  const save = useWeekUpdater({
    group: selectedGroup,
    weekStartMoment: moment(dateWindow[0]),
    projectId,
    activeDays,
    start,
    end,
    sharedUsers,
    approved,
    taskId,
    lunchStart: lunchUpdated ? lunchStart : undefined,
    lunchDurationMinutes: lunchUpdated ? lunchDurationMinutes : undefined,
    workOrderId,
    variedWorkOrderId
  });

  const trySave = () => {
    if (selectedGroup && selectedGroup.approved) {
      if (!updateApprovedOpen) {
        setUpdateApprovedOpen(true);
      }
    } else {
      save()
        .then(() => {
          if (selectedGroupIdx === -1) {
            onClose();
          }
        })
        .catch(() => {});
    }
  };

  const saveAndSendTexts = sendTexts => {
    save()
      .then(sendTexts)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (groups && open && groupIdxProp !== undefined && groups[groupIdxProp]) {
      setSelectedGroupIdx(groupIdxProp);
    } else {
      setSelectedGroupIdx(null);
    }
  }, [groups, open, groupIdxProp]);

  useEffect(() => {
    const selectedGroup =
      selectedGroupIdx === -1 ? defaultGroup : groups[selectedGroupIdx] || null;

    if (!selectedGroup) {
      setActiveDays([]);
      setStart(null);
      setEnd(null);
      setSharedUsers([]);
      setApproved(false);
      setHasUnsavedChanges(true);
      setLunchStart(null);
      setLunchDurationMinutes(null);
      setLunchUpdated(true);
      setTaskId('');
      setTaskName('');
      setWorkOrderId(null);
      setVariedWorkOrderId(false);
      return;
    }
    setActiveDays(selectedGroup.activeDays);
    setStart(selectedGroup.start);
    setEnd(selectedGroup.end);
    setSharedUsers(selectedGroup.sharedUsers);
    setApproved(selectedGroup.approved);
    setTaskId(selectedGroup.taskId);
    setTaskName(selectedGroup.taskName);
    setLunchStart(selectedGroup.lunchStart);
    setLunchDurationMinutes(selectedGroup.lunchDurationMinutes);
    setWorkOrderId(selectedGroup.workOrderId);
    setVariedWorkOrderId(selectedGroup.variedWorkOrderId);

    const activeDays =
      selectedGroupIdx === -1
        ? [...selectedGroup.activeDays]
        : Array(7)
            .fill(false)
            .map(
              (f, idx) =>
                !!selectedGroup.shifts.find(
                  shift => fromDateString(shift.date).day() === idx
                )
            );
    setActiveDays(activeDays);

    setLunchUpdated(selectedGroupIdx === -1 ? true : false);
    setHasUnsavedChanges(selectedGroupIdx === -1 ? true : false);
  }, [groups, selectedGroupIdx, defaultGroup]);

  const openCreateWoModal = () => {
    if (!projectId) return Toast.show('No project selected.');
    dispatch(
      showModal({
        props: {
          projectId,
          refresh: getWorkOrders
        },
        size: 'md',
        type: 'CREATE_WORK_ORDER'
      })
    );
  };

  const update = (fn, val) => {
    setHasUnsavedChanges(true);
    return fn(val);
  };

  const toggleDay = idx => () => {
    update(setActiveDays, old => {
      const updated = [...old];
      updated[idx] = !updated[idx];
      return updated;
    });
  };

  function addLunch() {
    setLunchStart(
      getDefaultLunch(
        fromTimeString(selectedGroup.start),
        getShiftLengthHours(selectedGroup.shifts[0])
      )
    );
    setLunchDurationMinutes(30);
    setLunchUpdated(true);
  }

  const removeLunch = () => {
    setLunchStart(null);
    setLunchDurationMinutes(null);
    setLunchUpdated(true);
  };

  if (!project) return null;
  const paddingLeft = 10;

  const getUsersFromIds = ids =>
    ids.map(id => userMap[id]).filter(user => user);

  const getDaysText = user => {
    const userDays = userIsScheduled(user);
    const workingDays = userDays
      .map((active, idx) => (active ? idx : null))
      .filter(day => day !== null)
      .map(day => DOWs[day]);
    if (!workingDays.length) return null;
    const hours = userIdHoursMap[user.id] || 0;
    const roundedHours = Math.floor(hours * 100) / 100;
    const hoursText = roundedHours === 1 ? '1 hr' : `${roundedHours} hrs`;
    return `${hoursText} - ${workingDays.join(', ')}`;
  };

  const removeUser = user => () =>
    update(setSharedUsers, old => {
      const idx = old.indexOf(user.id);
      if (idx === -1) return old;
      const copy = [...old];
      copy.splice(idx, 1);
      return copy;
    });

  const copyToNextWeek = () => {
    shiftDays
      .copyToNextWeek(selectedGroup.shifts)
      .then(() => setDateWindow(old => old.map(m => moment(m).add(1, 'week'))))
      .catch(Toast.showErr);
  };

  const loadCommonShift = data => {
    const { start, end, lunchStart, lunchDurationMinutes } = data;
    setStart(start);
    setEnd(end);
    setLunchStart(lunchStart);
    setLunchDurationMinutes(lunchDurationMinutes);

    setLunchUpdated(true);
    setHasUnsavedChanges(selectedGroupIdx === -1 ? true : false);
  };

  const deleteGroupAtIdx = idx => {
    const group = groups[idx];
    if (!group) return Promise.resolve([]);
    return Promise.all(
      group.shifts.map(shift => shiftDays.destroy(shift.id))
    ).then(fetchSchdeules);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => (selectedGroup ? setSelectedGroupIdx(null) : onClose())}
    >
      <UpdateApprovedShift
        open={updateApprovedOpen}
        onClose={() => setUpdateApprovedOpen(false)}
        dateString={moment(dateWindow[0])
          .add(1, 'day')
          .format()}
        removedUserIds={removedUserIds}
        addedUserIds={addedUserIds}
        stayingUserIds={stayingUserIds}
        setOnScheduleChange={sendTexts => {
          setUpdateApprovedOpen(false);
          setLoading(true);
          saveAndSendTexts(sendTexts);
        }}
      />
      <DialogTitle disableTypography>
        <Typography variant="h6" gutterBottom>
          Update Week:
        </Typography>
        <div style={{ paddingLeft }}>
          <Typography variant="h5">
            {project.projectNumber} {project.name}
          </Typography>
          {dateWindow[0] && dateWindow[1] && (
            <Typography>
              {dateWindow[0].format('dddd, MMM Do') +
                ' - ' +
                dateWindow[1].format('dddd, MMM Do')}
            </Typography>
          )}
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {selectedGroupIdx === null ? (
          <>
            <Typography variant="h6">Select Shift:</Typography>
            <List>
              <MenuItem onClick={() => setSelectedGroupIdx(-1)}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="New Shift" />
              </MenuItem>
              {groups.map((group, idx) => (
                <MenuItem key={idx} onClick={() => setSelectedGroupIdx(idx)}>
                  <ListItemIcon>
                    {group.approved ? (
                      <BsLockFill size={24} color={colors.success} />
                    ) : (
                      <BsUnlockFill size={24} color={colors.error} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={group.label.time}
                    secondary={
                      (group.taskId ? group.taskName + '\n' : '') +
                      group.label.days +
                      '\n' +
                      group.label.users
                    }
                    secondaryTypographyProps={{
                      style: { whiteSpace: 'pre-wrap' }
                    }}
                  />
                  <ListItemIcon>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        deleteGroupAtIdx(idx);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                </MenuItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <Spacer />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <CommonShiftsButton
                disabled={loading}
                onSelect={loadCommonShift}
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column'
                }}
              >
                {hasUnsavedChanges && (
                  <Typography variant="caption" color="textSecondary">
                    * Save changes before copying *
                  </Typography>
                )}
                <Button
                  disabled={hasUnsavedChanges || loading}
                  variant="contained"
                  onClick={copyToNextWeek}
                >
                  Copy to next week
                </Button>
              </div>
            </div>

            <Spacer />

            <Typography variant="h6">Select Time:</Typography>
            <div
              style={{ display: 'flex', alignItems: 'flex-end', paddingLeft }}
            >
              <KeyboardTimePicker
                disabled={loading}
                margin="normal"
                label="Start"
                value={start && start.format()}
                onChange={date => update(setStart, moment(date))}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
              <div style={{ fontSize: 40, padding: '0 8px 4px' }}>
                <ArrowRightIcon
                  style={{ display: 'block' }}
                  fontSize="inherit"
                />
              </div>
              <KeyboardTimePicker
                disabled={loading}
                margin="normal"
                label="End"
                value={end && end.format()}
                onChange={date => update(setEnd, moment(date))}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </div>

            <Spacer />

            <Typography variant="h6">Lunch:</Typography>
            {variance && (
              <div style={{ paddingLeft }}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ fontSize: '1rem' }}
                >
                  {weekLunchTimes}
                </Typography>
              </div>
            )}
            {hasLunch ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingLeft
                  }}
                >
                  <KeyboardTimePicker
                    disabled={loading}
                    margin="normal"
                    label="Start"
                    value={lunchStart && lunchStart.format()}
                    onChange={date => {
                      update(setLunchStart, moment(date));
                      setLunchUpdated(true);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                  <div style={{ fontSize: 40, padding: '0 8px 4px' }}>
                    <ArrowRightIcon
                      style={{ display: 'block' }}
                      fontSize="inherit"
                    />
                  </div>
                  <CustomInput
                    disabled={loading}
                    style={{ width: 257 }}
                    type="dropdown"
                    label="Duration"
                    onChange={val => {
                      update(setLunchDurationMinutes, val);
                      setLunchUpdated(true);
                    }}
                    value={lunchDurationMinutes}
                    options={[
                      { value: 30, label: '30 min' },
                      { value: 45, label: '45 min' },
                      { value: 60, label: '1 hr' }
                    ]}
                  />
                  <div>
                    <Button
                      disabled={!hasLunch || loading}
                      variant="contained"
                      onClick={removeLunch}
                    >
                      <Typography variant="button" noWrap>
                        Remove Lunch
                      </Typography>
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <Button disabled={loading} onClick={addLunch}>
                Add Lunch
              </Button>
            )}

            <Spacer />

            <Typography variant="h6" gutterBottom>
              Select Days:
            </Typography>
            <DowButtons
              disabled={loading}
              activeDays={activeDays}
              toggleDay={toggleDay}
              style={{ paddingLeft }}
            />

            <Spacer />

            <Typography variant="h6" gutterBottom>
              Select Crew:
            </Typography>
            <div style={{ paddingLeft }}>
              <div style={{ paddingBottom: 16 }}>
                {!getUsersFromIds(sharedUsers).length && (
                  <Typography component="div">None</Typography>
                )}
                {getUsersFromIds(sharedUsers).map(user => (
                  <CrewItem
                    disabled={loading}
                    key={user.id}
                    onRemove={removeUser(user)}
                    user={user}
                  />
                ))}
              </div>
              <CrewSelector
                disabled={loading}
                onSelect={user => {
                  update(setSharedUsers, old => {
                    const idx = old.indexOf(user.id);
                    if (idx === -1) return [...old, user.id];
                    const copy = [...old];
                    copy.splice(idx, 1);
                    return copy;
                  });
                }}
                getSecondaryText={user => getDaysText(user)}
              />
            </div>

            <Spacer />

            <Typography variant="h6" gutterBottom>
              Approval:
            </Typography>
            <div style={{ paddingLeft }}>
              <ListItem
                disabled={loading}
                button
                onClick={() => update(setApproved, old => !old)}
              >
                <ListItemIcon>
                  {approved ? (
                    <BsLockFill size={24} color={colors.success} />
                  ) : (
                    <BsUnlockFill size={24} color={colors.error} />
                  )}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'button' }}>
                  {approved ? 'Approved' : 'Unapproved'}
                </ListItemText>
              </ListItem>
            </div>

            <Spacer />

            <Typography variant="h6" gutterBottom>
              Work Orders:
            </Typography>
            <div style={{ paddingLeft }}>
              {variedWorkOrderId && (
                <Typography color="error" variant="overline">
                  Shifts have varied work orders
                </Typography>
              )}
              <ListItem disabled={loading} button onClick={openCreateWoModal}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText>Add Work Order</ListItemText>
              </ListItem>
              {workOrders.map(workOrder => (
                <WorkOrderListItem
                  disabled={loading}
                  key={workOrder.id}
                  getWorkOrders={getWorkOrders}
                  onSelect={checked => {
                    setVariedWorkOrderId(false);
                    setWorkOrderId(checked ? workOrder.id : null);
                  }}
                  selected={
                    variedWorkOrderId ? false : workOrderId === workOrder.id
                  }
                  workOrder={workOrder}
                />
              ))}
            </div>

            <Spacer />

            <Typography variant="h6" gutterBottom>
              Task:
            </Typography>
            <TaskSelectorModal
              open={taskSelectorOpen}
              onClose={() => setTaskSelectorOpen(false)}
              projectId={projectId}
              onSelect={task => {
                setTaskId(task.id);
                setTaskName(task.taskName);
                setTaskSelectorOpen(false);
              }}
            />

            <ListItem
              disabled={loading}
              button
              onClick={() => setTaskSelectorOpen(true)}
            >
              <ListItemText primaryTypographyProps={{ variant: 'button' }}>
                {taskName || 'None'}
              </ListItemText>
              {!!taskName && (
                <ListItemIcon>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      setTaskName(null);
                      update(setTaskId, null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
              )}
            </ListItem>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="contained" onClick={onClose}>
          cancel
        </Button>
        {selectedGroupIdx !== null && (
          <>
            <Button
              disabled={loading}
              variant="contained"
              onClick={() => setSelectedGroupIdx(null)}
            >
              back
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={() => update(setActiveDays, () => Array(7).fill(false))}
            >
              Remove Shift
            </Button>
          </>
        )}
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={trySave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Spacer = () => <div style={{ marginTop: 21 }} />;
const DOWs = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
};
