import React from 'react';
import { DragAndDrop } from 'components';
import { useColors } from 'helpers/theme.helper';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from 'helpers/themeSafeMui.helper';

const StatusMapColumn = ({
  mapItems,
  onDrop,
  onFocus,
  focusedId,
  clearItem,
  removeItem,
  rowHeight = 60
}) => {
  const colors = useColors();
  const statuses = useSelector(state => state.projectStatuses);
  if (!statuses.length) return null;
  return (
    <DragAndDrop onDrop={onDrop}>
      {mapItems.map(mapItem => {
        const status = statuses.find(status => status.id === mapItem.statusId);
        return (
          <div
            key={mapItem.id}
            id={mapItem.id}
            value={mapItem}
            style={{
              display: 'flex',
              width: '100%',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: rowHeight - 20,
              border:
                mapItem.id === focusedId ? `1px solid ${colors.primary}` : null
            }}
            onClick={() => onFocus(mapItem)}
          >
            {mapItem.fromDtools ? mapItem.dToolsName : status.status}
            <div onClick={e => e.stopPropagation()}>
              <IconButton onClick={() => removeItem(mapItem)}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={() => clearItem(mapItem)}>
                <ClearIcon />
              </IconButton>
            </div>
          </div>
        );
      })}
    </DragAndDrop>
  );
};

export default StatusMapColumn;
