import React from 'react';
import { Toast, DropZone } from 'components';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import states from 'helpers/states.helper';

export default class Identification extends React.Component {
  state = {
    SSN: '',
    DLNumber: '',
    DLState: '',
    DLFile: null,
    SSCardFile: null,
    SSCardLoading: false,
    DLLoading: false
  };

  componentDidMount() {
    http()
      .get('/users/me/employee-details')
      .then(employeeDetails => {
        this.setState({
          SSN: employeeDetails.SSN || '',
          DLNumber: employeeDetails.DLNumber ? '•••••' : '',
          DLState: employeeDetails.DLState || '',
          DLFile: employeeDetails.DLFile ? '•••••' : '',
          SSCardFile: employeeDetails.SSCardFile
        });
      })
      .catch(err => Toast.show(err.message));
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  uploadDL = files => {
    const file = files[0];
    this.setState({ DLLoading: true });
    http({ withLoader: false })
      .put('/users/me/employee-details', {
        DLFile: {
          base64: file.base64,
          mimeType: file.mimeType
        }
      })
      .then(employeeDetails =>
        this.setState({ DLFile: employeeDetails.DLFile })
      )
      .catch(err => Toast.show(err.message))
      .finally(() => this.setState({ DLLoading: false }));
  };
  uploadSSCard = files => {
    const file = files[0];
    this.setState({ SSCardLoading: true });
    http({ withLoader: false })
      .put('/users/me/employee-details', {
        SSCardFile: {
          base64: file.base64,
          mimeType: file.mimeType
        }
      })
      .then(employeeDetails =>
        this.setState({ SSCardFile: employeeDetails.SSCardFile })
      )
      .catch(err => Toast.show(err.message))
      .finally(() => this.setState({ SSCardLoading: false }));
  };

  removeDL = () => {
    http()
      .put('/users/me/employee-details', {
        DLFile: null
      })
      .then(employeeDetails =>
        this.setState({ DLFile: employeeDetails.DLFile })
      )
      .catch(err => Toast.show(err.message));
  };
  removeSSCard = () => {
    http()
      .put('/users/me/employee-details', {
        SSCardFile: null
      })
      .then(employeeDetails =>
        this.setState({ SSCardFile: employeeDetails.SSCardFile })
      )
      .catch(err => Toast.show(err.message));
  };

  submit = e => {
    e.preventDefault();

    if (!this.state.SSCardFile) {
      return Toast.show('Please upload an image of your social security card.');
    }
    if (!this.state.DLFile) {
      return Toast.show("Please upload an image of your driver's license.");
    }

    http()
      .put('/users/me/employee-details', {
        SSN: this.state.SSN !== '•••••' ? this.state.SSN : undefined,
        DLNumber:
          this.state.DLNumber !== '•••••' ? this.state.DLNumber : undefined,
        DLState: this.state.DLState
      })
      .then(employeeDetails => {
        this.props.history.push('/onboarding/employee-policy');
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <form autoComplete="off" onSubmit={this.submit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DropZone
              loading={this.state.SSCardLoading}
              isUploaded={this.state.SSCardFile}
              onReset={this.removeSSCard}
              onRead={this.uploadSSCard}
              title={'Social Security Card'}
              validExtensions={['jpeg', 'jpg', 'png', 'pdf']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="SSN"
              label="Social Security or Government ID Number"
              autoFocus
              required
              fullWidth
              margin="dense"
              value={this.state.SSN}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <DropZone
              loading={this.state.DLLoading}
              isUploaded={this.state.DLFile}
              onReset={this.removeDL}
              onRead={this.uploadDL}
              title="Driver's License"
              validExtensions={['jpeg', 'jpg', 'png', 'pdf']}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="DLNumber"
              label="Driver's License Number"
              required
              fullWidth
              margin="dense"
              value={this.state.DLNumber}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>State</InputLabel>
              <Select
                value={this.state.DLState}
                onChange={this.handleChange}
                name="DLState"
                required
              >
                {states.map(state => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ float: 'right', marginTop: 30 }}
        >
          Next
        </Button>
        <div style={{ clear: 'both' }} />
      </form>
    );
  }
}
