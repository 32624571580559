import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, BankAccount, NoItemsResult } from 'components';

const BankingTab = props => {
  const { userId } = props;
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getBankAccounts = useCallback(() => {
    http()
      .get(`/users/${userId}/bank-accounts`)
      .then(accounts => setAccounts(accounts))
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  const removeAccount = id => {
    if (id.includes('new_')) {
      setAccounts(accounts.filter(a => a.id !== id));
    } else {
      http()
        .delete(`/users/${userId}/bank-accounts/${id}`)
        .then(() => getBankAccounts())
        .catch(err => Toast.show(err.message));
    }
  };

  const addAccount = () => {
    setAccounts(
      accounts.concat({
        id: 'new_' + new Date().getTime(),
        type: '',
        routingNumber: '',
        accountNumber: '',
        accountNumberConfirmed: '',
        directDepositAcknowledged: '',
        paycheckAllocation: '100',
        allocationType: 'percent',
        file: null
      })
    );
  };

  const agreeAndSave = (e, account) => {
    e.preventDefault();

    if (loading) return;

    if (!account.file) {
      return Toast.show('Please upload an image of a voided check.');
    }

    if (account.accountNumber !== account.accountNumberConfirmed) {
      return Toast.show("Account numbers don't match.");
    }

    setLoading(true);

    http()
      .post(`/users/${userId}/bank-accounts`, {
        type: account.type,
        routingNumber: account.routingNumber,
        accountNumber: account.accountNumber,
        directDepositAcknowledged: new Date(),
        paycheckAllocation: account.paycheckAllocation,
        allocationType: account.allocationType,
        voidCheckFile: {
          base64: account.file.base64,
          mimeType: account.file.mimeType
        }
      })
      .then(() => getBankAccounts())
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="secondary" onClick={addAccount}>
        + New Bank Account
      </Button>
      {accounts.length > 0 ? (
        accounts.map((account, idx) => {
          return (
            <BankAccount
              onChange={value =>
                setAccounts(
                  accounts.map(account =>
                    account.id === value.id ? value : account
                  )
                )
              }
              agreeAndSave={agreeAndSave}
              removeAccount={removeAccount}
              account={account}
              key={account.id}
              idx={idx}
            />
          );
        })
      ) : (
        <NoItemsResult type="bank accounts" />
      )}
    </React.Fragment>
  );
};

export default BankingTab;
