import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Joi from '@hapi/joi';

const parseValue = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  schema: (joi: Joi.Root) => Joi.Schema = joi => joi.any()
) => {
  const [value, setValue] = useState<T>(() => {
    const hasKey = window.localStorage.hasOwnProperty(key);
    const storedValue = parseValue(window.localStorage.getItem(key));
    return hasKey && !schema(Joi).validate(storedValue).error
      ? storedValue
      : initialValue;
  });

  useEffect(() => {
    if (key) window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as [T, Dispatch<SetStateAction<T>>];
};
