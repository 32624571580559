import React from 'react';
import ChangeOrdersTable from './components/ChangeOrderTable.component';
import { useSelector } from 'react-redux';

const ChangeOrdersTab = () => {
  const user = useSelector(state => state.user);
  const isTier2 = user.hasPermission('projects_tier_2');

  return (
    <ChangeOrdersTable
      hiddenColumns={isTier2 ? [] : ['$ Amount', 'add/edit', 'checkable']}
    />
  );
};

export default ChangeOrdersTab;
