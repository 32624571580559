import React from 'react';
import { Toast } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

class CreateProjectStatus extends React.Component {
  state = {
    name: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();

    http()
      .post('/project-statuses', {
        status: this.state.name,
        order: this.props.nextSpot
      })
      .then(() => {
        Toast.show('Project Status Created.');
        this.props.onClose();
        this.props.refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { onClose } = this.props;
    return (
      <form autoComplete="off" onSubmit={this.save}>
        <DialogTitle>New Project Status</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Name"
            fullWidth
            value={this.state.name}
            name="name"
            margin="normal"
            onChange={this.handleChange}
            required
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default CreateProjectStatus;
