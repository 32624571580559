import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, Rollup, CustomInput } from 'components';
import { useSelector } from 'react-redux';
import {
  ProjectDetailsContext,
  RefreshProjectContext
} from 'routes/Projects/context/Project.context';

export default function ProjectDetailsCard() {
  const user = useSelector(state => state.user);
  const tier2 = user.hasPermission('projects_tier_2');
  const { projectDetails } = useContext(ProjectDetailsContext);
  const refreshProject = useContext(RefreshProjectContext);
  const [fields, setFields] = useState({ ...projectDetails });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setFields({ ...projectDetails });
  }, [projectDetails, editing]);

  const getUUID = string => {
    if (!string) return null;
    const match = string.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    if (!match) return null;
    if (!match[0]) return null;
    return match[0];
  };

  const validPlangridLink = Boolean(getUUID(fields.plangridLink));

  const save = () => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        bidDueDate: fields.bidDueDate,
        planRoomLink: fields.planRoomLink || '',
        plangridLink: fields.plangridLink || '',
        customerPO: fields.customerPO || '',
        invoiceType: fields.invoiceType || '',
        retainagePercentage: fields.retainagePercentage || 0,
        start: fields.start,
        end: fields.end,
        estimatedCrew: fields.estimatedCrew || null,
        perDiem: fields.perDiem || '',
        scopeOfWork: fields.scopeOfWork || ''
      })
      .then(res => {
        if (validPlangridLink && !projectDetails.plangridProjectId) {
          return http().post(
            `/plangrid/projects/${projectDetails.projectId}/link/`,
            { plangridProjectId: getUUID(fields.plangridLink) }
          );
        } else {
          return res;
        }
      })
      .then(() => {
        setEditing(false);
        return refreshProject();
      })
      .catch(err => Toast.show(err.message));
  };

  function cancel() {
    setFields({ ...projectDetails });
    setEditing(false);
  }

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Scope of Work</Typography>}
      localStorageKey="project_card_project_details"
    >
      {editing ? (
        <CustomInput
          margin="none"
          value={fields.scopeOfWork}
          multiline
          onChange={scopeOfWork => setFields({ ...fields, scopeOfWork })}
        />
      ) : (
        <Typography
          variant={fields.scopeOfWork ? undefined : 'caption'}
          color={fields.scopeOfWork ? undefined : 'textSecondary'}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {fields.scopeOfWork || '*No scope of work'}
        </Typography>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 16
        }}
      >
        {tier2 && (
          <div>
            {editing ? (
              <>
                <Button
                  style={{ marginRight: 10 }}
                  variant="contained"
                  onClick={cancel}
                >
                  Cancel
                </Button>
                <Button variant="contained" color="secondary" onClick={save}>
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>
    </Rollup>
  );
}
