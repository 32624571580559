import React, { useEffect, useMemo, useState } from 'react';
import { DashboardWrapper, ProjectFilterDropdown, Toast } from 'components';
import ProjectBoardComponent from './components/ProjectBoard.component';
import http from 'helpers/http.helper';
import { Typography } from 'helpers/themeSafeMui.helper';

export const ChecklistStatusMapContext = React.createContext({});

export default function ProjectBoard() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checklistStatusMap, setChecklistStatusMap] = useState({});
  const contextValue = useMemo(
    () => ({ checklistStatusMap, setChecklistStatusMap }),
    [checklistStatusMap, setChecklistStatusMap]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      http().get('/projects'),
      http().get('/projects/checklist-items/status-map')
    ])
      .then(([projects, checklistStatusMap]) => {
        setProjects(projects);
        setChecklistStatusMap(checklistStatusMap);
      })
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }, []);

  return (
    <ChecklistStatusMapContext.Provider value={contextValue}>
      <DashboardWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8
          }}
        >
          <Typography component="h1" variant="h4">
            Project Pipeline
          </Typography>
          <ProjectFilterDropdown
            projects={projects}
            onUpdate={setFilteredProjects}
            localStorageRootKey="project_pipeline"
          />
        </div>
        <ProjectBoardComponent
          filteredProjects={filteredProjects}
          loading={loading}
        />
      </DashboardWrapper>
    </ChecklistStatusMapContext.Provider>
  );
}
