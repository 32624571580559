import React, { useRef, useEffect } from 'react';

const ScheduleTableLoadingCanvas = React.memo(
  ({ color, backgroundColor, height, rowDimensions }) => {
    const { rowWidth, firstWidth, cellWidth, cellHeight } = rowDimensions;
    const ref = useRef(null);

    const dpr = window.devicePixelRatio || 1;

    useEffect(() => {
      if (ref) {
        ref.current.width = Math.floor(rowWidth * dpr);
        ref.current.height = Math.floor(height * dpr);
        const ctx = ref.current.getContext('2d');
        ctx.clearRect(0, 0, rowWidth * dpr, height * dpr);
        for (let y = 0; y < height * dpr; y += cellHeight * dpr) {
          ctx.beginPath();
          ctx.moveTo(0, y);
          ctx.lineTo(firstWidth * dpr, y);
          ctx.lineTo(firstWidth * dpr, y + cellHeight * dpr);
          ctx.moveTo(firstWidth * dpr, y);
          for (
            let x = firstWidth * dpr;
            x < rowWidth * dpr;
            x += cellWidth * dpr
          ) {
            ctx.lineTo(x + cellWidth * dpr, y);
            ctx.lineTo(x + cellWidth * dpr, y + cellHeight * dpr);
            ctx.moveTo(x + cellWidth * dpr, y);
          }
          ctx.strokeStyle = color;
          ctx.stroke();
        }
      }
    });

    return (
      <canvas
        ref={ref}
        style={{ backgroundColor, display: 'block', width: rowWidth, height }}
      />
    );
  }
);

export default ScheduleTableLoadingCanvas;
