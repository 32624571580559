import React from 'react';
import { useTheme } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';

const ScheduleTableBar = ({
  cellWidth,
  padding = 0,
  cellSpan,
  color,
  padStart = true,
  padEnd = true,
  style,
  children,
  ...rest
}) => {
  const colors = useColors();
  const { pxToRem } = useTheme().typography;

  const radius = '30px ';
  let borderRadius = '';
  borderRadius += padStart ? radius : '0 ';
  borderRadius += padEnd ? radius + radius : '0 0 ';
  borderRadius += padStart ? radius : '0 ';

  const shrink =
    padStart && padEnd ? padding * 2 : padStart || padEnd ? padding : 0;

  return (
    <div
      style={{
        top: padding,
        left: padStart ? padding : 0,
        zIndex: 0,
        borderRadius,
        borderStyle: 'solid',
        borderWidth: 3,
        borderColor: colors.grey,
        position: 'absolute',
        height: `calc(100% - ${pxToRem(padding)} * 2)`,
        width: cellWidth * cellSpan - shrink,
        background: color,
        ...style
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ScheduleTableBar;
