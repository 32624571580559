import React, { useState, useEffect, useCallback } from 'react';
import http from 'helpers/http.helper';
import { Toast, NoItemsResult } from 'components';
import { Grid, Typography } from 'helpers/themeSafeMui.helper';
import UserSkill from '../components/UserSkill.component';
import { Rollup } from 'components';

const UserSkills = props => {
  const { userId } = props;
  const [skills, setSkills] = useState([]);
  const [userSkills, setUserSkills] = useState([]);

  const getUserSkills = useCallback(() => {
    http()
      .get(`/users/${userId}/skills`)
      .then(res => setUserSkills(res))
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    getUserSkills();
  }, [getUserSkills]);

  const getSkills = useCallback(() => {
    http()
      .get(`/skills/`)
      .then(res => setSkills(res))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    getSkills();
  }, [getSkills]);

  const getUserSkill = skill => userSkills.find(us => us.skillId === skill.id);
  const getChildrenSkills = parentSkill =>
    skills.filter(skill => skill.parentId === parentSkill.id);

  const parentSkills = skills
    .filter(s => !s.parentId)
    .map(skill => {
      const maxLevel = getChildrenSkills(skill).length * 2;
      const currentLevel = getChildrenSkills(skill)
        .map(skill => getUserSkill(skill))
        .reduce((total, userSkill) => total + userSkill?.skillLevel || 0, 0);
      return { ...skill, currentLevel, maxLevel };
    });

  return (
    <Grid container spacing={2} style={{ position: 'relative' }}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          User Skills
        </Typography>
        {!parentSkills.length && <NoItemsResult type="skills" />}
        {parentSkills
          .sort((a, b) => a.order - b.order)
          .map(parentSkill => (
            <Rollup
              key={parentSkill.id}
              dense={false}
              style={{ flex: 1, margin: '0 10px' }}
              title={
                <>
                  <Grid item xs={6} md={4} lg={2}>
                    <Typography style={{ marginLeft: 10 }} variant="h6">
                      {parentSkill.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {parentSkill.currentLevel} / {parentSkill.maxLevel}
                  </Grid>
                </>
              }
            >
              {getChildrenSkills(parentSkill).length ? (
                getChildrenSkills(parentSkill)
                  .sort((a, b) => a.order - b.order)
                  .map(childSkill => (
                    <div style={{ marginLeft: '24px' }} key={childSkill.id}>
                      <UserSkill
                        userId={userId}
                        skill={childSkill}
                        userSkill={getUserSkill(childSkill)}
                        getUserSkills={getUserSkills}
                      />
                    </div>
                  ))
              ) : (
                <NoItemsResult type="children" />
              )}
            </Rollup>
          ))}
      </Grid>
    </Grid>
  );
};

export default UserSkills;
