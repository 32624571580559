import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const PermissionWrapper = ({
  children,
  permission,
  deniedElement,
  redirect = false
}) => {
  const user = useSelector(state => state.user);

  if (!user.hasPermission(permission)) {
    if (deniedElement) return deniedElement;
    if (redirect) return <Redirect to="/404" />;
    return null;
  }
  return children;
};

export default PermissionWrapper;
