import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  List
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  ProjectContext,
  ServiceItemsContext
} from 'routes/Projects/context/Project.context';
import ServiceItem from '../components/ServiceItem.component';

const ServiceItemsModal = ({ open, onClose, contentOnly = false }) => {
  const { project } = useContext(ProjectContext);
  const { serviceItemTemplates, serviceItems, setServiceItems } = useContext(
    ServiceItemsContext
  );
  const templates = serviceItemTemplates.map(t => ({ ...t, isTemplate: true }));
  const allItems = templates.map(
    template =>
      serviceItems.find(item => item.number === template.number) || template
  );

  const replace = newItem => {
    setServiceItems(old => {
      newItem = { ...newItem };
      const copy = [...old];
      const idx = copy.findIndex(other => other.number === newItem.number);
      if (idx === -1) {
        copy.push(newItem);
      } else {
        copy[idx] = newItem;
      }
      return copy;
    });
  };

  const onCreate = item => {
    let data = {
      number: item.number,
      name: item.name,
      isSpecialWage: item.isSpecialWage
    };
    if (item.isSpecialWage)
      data = {
        ...data,
        base: item.base === '' ? null : item.base,
        fringe: item.fringe === '' ? null : item.fringe
      };

    http()
      .post(`/projects/${project.id}/service-items`, data)
      .then(replace)
      .catch(err => Toast.show(err.message));
  };

  const onEdit = item => {
    let data = {
      number: item.number,
      name: item.name,
      isSpecialWage: item.isSpecialWage
    };
    if (item.isSpecialWage)
      data = {
        ...data,
        base: item.base === '' ? null : item.base,
        fringe: item.fringe === '' ? null : item.fringe
      };

    http()
      .put(`/projects/${project.id}/service-items/${item.id}`, data)
      .then(replace)
      .catch(err => Toast.show(err.message));
  };

  const onDelete = item => () => {
    http()
      .delete(`/projects/${project.id}/service-items/${item.id}`)
      .then(() =>
        setServiceItems(old =>
          old.filter(other => other.number !== item.number)
        )
      )
      .catch(err => Toast.show(err.message));
  };

  const wrapper = content => {
    if (contentOnly) return content;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle>Service Items</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Back</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return wrapper(
    <List>
      {allItems.map((item, idx) => (
        <React.Fragment key={item.number}>
          {idx !== 0 && <Divider />}
          <ServiceItem
            onCreate={onCreate}
            onEdit={onEdit}
            onDelete={onDelete(item)}
            item={item}
          />
        </React.Fragment>
      ))}
    </List>
  );
};

export default ServiceItemsModal;
