import moment from 'moment';

export function toDateString(m: moment.Moment) {
  return (m || moment()).format('YYYY-MM-DD');
}

export function toTimeString(m: moment.Moment) {
  return (m || moment()).format('HH:mmZ');
}

export function fromTimeString(s: string) {
  return moment(s, 'HH:mm:ssZ');
}

export function fromDateString(s: string) {
  return moment(s);
}

export function getShiftLengthHours(shift: any, precise = false) {
  return fromTimeString(shift.end).diff(
    fromTimeString(shift.start),
    'hours',
    precise
  );
}

export function getDefaultLunch(
  start: string | moment.Moment,
  shiftLengthHours = 10
) {
  let m: moment.Moment;
  if (typeof start === 'string') {
    m = fromTimeString(start);
  } else {
    m = moment(start);
  }
  m.add(Math.floor(shiftLengthHours / 2), 'hours');

  return moment(m);
}

export function shortFormatTime(string: string) {
  if (!string) return 'N/A';
  const m = fromTimeString(string);
  let minute = m.format('mm');
  minute = minute === '00' ? '' : ':' + minute;
  const hour = m.format('h');
  const am = m.format('a');
  return hour + minute + am;
}
