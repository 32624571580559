import React from 'react';
import { Grid, Typography, IconButton } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import TrainingModule from './TrainingModule.component';
import moment from 'moment';
import safeParse from 'helpers/safeParse.helper';

export default function TrainingGroup({
  getTrainingGroups,
  trainingGroup,
  moduleFilter = null,
  userId
}) {
  const colors = useColors();
  const [
    open,
    setOpen
  ] = useLocalStorage(
    `user.training_modules.${trainingGroup.id}.open`,
    false,
    joi => joi.boolean()
  );
  const expiresSoon = trainingGroup.trainingModules.some(module =>
    expiresInUnder(module, 90)
  );
  const hasExpired = trainingGroup.trainingModules.some(module =>
    expiresInUnder(module, 0)
  );

  const dividend = trainingGroup.trainingModules.filter(
    el =>
      el.userTrainingRecord &&
      !el.userTrainingRecord.optional &&
      el.userTrainingRecord.dateCompleted
  ).length;
  const divisor = trainingGroup.trainingModules.filter(
    el => !(el.userTrainingRecord && el.userTrainingRecord.optional)
  ).length;
  let percentCompleted = (dividend / divisor) * 100;
  if (divisor === 0) percentCompleted = 100;

  return (
    <Grid item xs={6} key={trainingGroup.id}>
      <Typography variant="h5" gutterBottom style={{ position: 'relative' }}>
        {trainingGroup.name}
        <IconButton
          style={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => setOpen(open => !open)}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      <div
        style={{
          height: 16,
          width: '100%',
          background: colors.grey,
          borderRadius: 16,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            height: '100%',
            width: `${percentCompleted}%`,
            minWidth: 24,
            background: hasExpired
              ? colors.error
              : expiresSoon
              ? colors.yellow
              : colors.success
          }}
        >
          <Typography
            align="right"
            style={{ fontSize: 12, color: 'white', margin: '0 4px' }}
          >
            {Math.floor(percentCompleted)}%
          </Typography>
        </div>
      </div>
      {!!(open || moduleFilter) &&
        trainingGroup.trainingModules
          .filter(moduleFilter || (() => true))
          .map(trainingModule => (
            <TrainingModule
              key={trainingModule.id}
              userId={userId}
              trainingModule={trainingModule}
              onSave={getTrainingGroups}
            />
          ))}
    </Grid>
  );
}

function expiresInUnder(trainingModule, days) {
  const { userTrainingRecord } = trainingModule;
  return (
    userTrainingRecord &&
    userTrainingRecord.dateCompleted &&
    trainingModule.daysToExpire &&
    moment(userTrainingRecord.dateCompleted)
      .add(safeParse(trainingModule.daysToExpire) - days, 'days')
      .isBefore(moment())
  );
}
