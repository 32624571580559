import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Toast, SearchDropdown } from 'components';

const OperationsManagers = props => {
  const [operationsManagers, setOperationsManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    http()
      .get('/operations-managers')
      .then(res => setOperationsManagers(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));
  }, []);

  const archive = id => {
    http()
      .delete(`/operations-managers/${id}`)
      .then(() => {
        setOperationsManagers(operationsManagers.filter(om => om.id !== id));
        Toast.show('Operations manager removed.');
      });
  };

  const create = e => {
    e.preventDefault();

    http()
      .post('/operations-managers', { userId: selectedUser.id })
      .then(res => {
        setOperationsManagers(operationsManagers.concat(res));
        setModalOpen(false);
        Toast.show('Operations manager added.');
      });
  };

  return (
    <div style={{ marginTop: 32 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Operation's Managers
        <IconButton
          color="primary"
          style={{ marginLeft: 10, padding: 0 }}
          onClick={() => setModalOpen(true)}
        >
          <AddIcon />
        </IconButton>
      </Typography>

      <Table>
        <TableBody>
          {operationsManagers.map(om => (
            <TableRow key={om.id}>
              <TableCell>{om.name}</TableCell>
              <TableCell padding="checkbox" className="cell-shrink">
                <IconButton onClick={() => archive(om.id)}>
                  <DeleteIcon color="action" fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Create - Modal */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        TransitionProps={{ onEnter: () => setSelectedUser(null) }}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <form autoComplete="off" onSubmit={create}>
          <DialogTitle>Add Operation's Manager</DialogTitle>
          <DialogContent>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="User"
              itemToString={item => item.name}
              items={users}
              onChange={selected => setSelectedUser(selected)}
              value={selectedUser}
              required
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default OperationsManagers;
