import React, { useState, useContext } from 'react';
import { Paper, IconButton, Typography } from 'helpers/themeSafeMui.helper';
import toCurrency from 'helpers/currency.helper';
import { BarChart, Toast, LastUpdated } from 'components';
import { useColors } from 'helpers/theme.helper';
import EditIcon from '@material-ui/icons/Edit';
import UpdateNumbersModal from '../modals/UpdateNumbers.modal';
import {
  ProjectContext,
  ProjectDetailsContext
} from '../../../context/Project.context';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { ChildProjectsContext } from 'routes/Projects/context/ChildProjectsContext.context';
import useChangeOrderCost from 'routes/Projects/hooks/useChangeOrderCost.hook';
import safeParse from 'helpers/safeParse.helper';

const ProjectGraphs = props => {
  const { user } = props;
  const colors = useColors();
  let { projectDetails, setProjectDetails } = useContext(ProjectDetailsContext);
  let { project } = useContext(ProjectContext);
  const { relatedProjectCount } = useContext(ChildProjectsContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const estimatedCoCost = useChangeOrderCost();

  const contractValue = safeParse(projectDetails.contractValue);
  const coValue = safeParse(projectDetails.coValue);
  const estimatedLaborHours = safeParse(projectDetails.estimatedLaborHours);
  const estimatedCoLaborHours = safeParse(projectDetails.estimatedCoLaborHours);
  const estimatedCost = safeParse(projectDetails.estimatedCost);
  const dollarValue = safeParse(projectDetails.dollarValue);
  const currentLaborHours = safeParse(projectDetails.currentLaborHours);
  const currentlyBilled = safeParse(projectDetails.currentlyBilled);
  const currentCost = safeParse(projectDetails.currentCost);

  const totalEstimatedHours = estimatedLaborHours + estimatedCoLaborHours;
  const totalEstimatedCost = estimatedCost + estimatedCoCost;

  const estimatedProfit = dollarValue - totalEstimatedCost;
  const estimatedPercentProfit = (estimatedProfit / dollarValue) * 100;

  const baseCostUsed = Math.min(currentCost, estimatedCost);
  const coCostUsed = Math.min(
    currentCost > estimatedCost ? currentCost - estimatedCost : 0,
    estimatedCoCost
  );

  const overBudget =
    currentCost - totalEstimatedCost > 0 ? currentCost - totalEstimatedCost : 0;
  const underBudget =
    currentCost - totalEstimatedCost < 0
      ? Math.abs(currentCost - totalEstimatedCost)
      : 0;

  const actualProfit = estimatedProfit - overBudget + underBudget;
  const actualPercentProfit = (actualProfit / dollarValue) * 100;

  const syncWithTsheets = setLoading => {
    setLoading(true);
    http({ withLoader: false })
      .put(`/projects/${projectDetails.projectId}/details/tsheets`)
      .then(res => setProjectDetails(res))
      .then(() => Toast.show('Project hours synced with TSheets.'))
      .catch(e => Toast.show(e.message))
      .finally(() => setLoading(false));
  };

  function estimatedCoCostSegment() {
    return estimatedCoCost < 0
      ? [
          {
            title: `${toCurrency(estimatedCost)} - Estimated Cost`,
            label: `${toCurrency(currentCost)} Used`,
            offLabel: `${toCurrency(
              totalEstimatedCost - currentCost
            )} Remaining`,
            portion: totalEstimatedCost / dollarValue,
            filled: baseCostUsed / totalEstimatedCost
          }
        ]
      : [
          {
            title: `${toCurrency(estimatedCost)} - Estimated Cost`,
            label: `${toCurrency(currentCost)} Used`,
            offLabel: `${toCurrency(
              totalEstimatedCost - currentCost
            )} Remaining`,
            portion: estimatedCost / dollarValue,
            filled: baseCostUsed / estimatedCost
          },
          {
            title: `${toCurrency(estimatedCoCost)} - Estimated CO Cost`,
            label: `${toCurrency(currentCost)} Used`,
            offLabel: `${toCurrency(
              totalEstimatedCost - currentCost
            )} Remaining`,
            portion: estimatedCoCost / dollarValue,
            filled: coCostUsed / estimatedCoCost
          }
        ];
  }

  return (
    <Paper style={{ padding: 16, position: 'relative', overflow: 'hidden' }}>
      <IconButton
        style={{ position: 'absolute', top: 8, right: 8, zIndex: 99 }}
        onClick={() => setModalIsOpen(true)}
      >
        <EditIcon color="action" fontSize="small" />
      </IconButton>

      <UpdateNumbersModal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        projectDetails={projectDetails}
        setProjectDetails={setProjectDetails}
      />

      {!!relatedProjectCount && (
        <Typography variant="caption" color="textSecondary">
          * For Project {project.projectNumber} only. Totals do not include
          related projects *
        </Typography>
      )}

      {user.hasPermission('projects_tier_2') && (
        <>
          <BarChart
            title="Billing"
            segments={
              dollarValue === 0
                ? [
                    {
                      title: `${toCurrency(
                        currentlyBilled
                      )} - Currently Billed`,
                      portion: 1
                    }
                  ]
                : [
                    {
                      title: `${toCurrency(
                        contractValue
                      )} - Base Contract Value`,
                      portion: contractValue / dollarValue
                    },
                    {
                      title: `${toCurrency(coValue)} - CO Value`,
                      portion: coValue / dollarValue,
                      alignRight: true
                    }
                  ]
            }
            filled={{
              portion: dollarValue === 0 ? 1 : currentlyBilled / dollarValue,
              label: `${toCurrency(currentlyBilled)} Currently Billed`,
              offLabel: `${toCurrency(
                dollarValue - currentlyBilled
              )} Left to Bill`
            }}
          />
          <LastUpdated
            lastUpdated={projectDetails.currentlyBilledLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
        </>
      )}

      <BarChart
        title="Labor"
        segments={
          totalEstimatedHours === 0
            ? [
                {
                  title: `${currentLaborHours} hrs - Current Labor Hours`,
                  portion: 1
                }
              ]
            : [
                {
                  title: `${estimatedLaborHours} hrs - Estimated Labor Hours`,
                  portion: estimatedLaborHours / totalEstimatedHours
                },
                {
                  title: `${estimatedCoLaborHours} hrs - Estimated CO Labor Hours`,
                  portion: estimatedCoLaborHours / totalEstimatedHours,
                  alignRight: true
                }
              ]
        }
        filled={{
          portion:
            totalEstimatedHours === 0
              ? 1
              : currentLaborHours / totalEstimatedHours,
          label: `${Number(currentLaborHours)} Current Labor Hours`,
          offLabel: `${totalEstimatedHours -
            currentLaborHours} Labor Hours Remaining`
        }}
      />
      <LastUpdated
        lastUpdated={projectDetails.currentLaborHoursLastUpdate}
        onRefresh={syncWithTsheets}
        toolTipValue="Imported from TSheets"
      />

      {user.hasPermission('projects_tier_2') && (
        <>
          <BarChart
            title="Estimated/Actual Costs"
            segments={
              totalEstimatedCost
                ? [
                    ...estimatedCoCostSegment(),
                    {
                      title: `${toCurrency(
                        estimatedProfit
                      )} (${estimatedPercentProfit.toFixed(
                        1
                      )}%) - Estimated Gross Profit`,
                      label: `${toCurrency(overBudget)} Over Budget`,
                      offLabel: `${toCurrency(
                        actualProfit
                      )} (${actualPercentProfit.toFixed(
                        1
                      )}%) Actual Gross Profit`,
                      portion: estimatedProfit / dollarValue,
                      color: colors.error,
                      filled: overBudget / estimatedProfit
                    }
                  ]
                : [
                    {
                      title: `${toCurrency(currentCost)} - Current Cost`,
                      label: `${toCurrency(currentCost)} - Current Cost`,
                      portion: 1,
                      filled: 1
                    }
                  ]
            }
          />
          <LastUpdated
            lastUpdated={projectDetails.currentCostLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
        </>
      )}
      {user.hasPermission('projects_tier_2') && (
        <>
          <BarChart
            title="Labor Costs"
            segments={[
              {
                title: `${toCurrency(
                  projectDetails.estimatedLaborCost
                )} - Estimated Cost`,
                label: `${toCurrency(projectDetails.laborCost)} Used`,
                offLabel: `${toCurrency(
                  projectDetails.estimatedLaborCost - projectDetails.laborCost
                )} Remaining`,
                portion: 1,
                filled:
                  projectDetails.laborCost / projectDetails.estimatedLaborCost
              }
            ]}
          />
          <LastUpdated
            lastUpdated={projectDetails.materialLaborCostLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
          <BarChart
            title="Material Costs"
            segments={[
              {
                title: `${toCurrency(
                  projectDetails.estimatedMaterialCost
                )} - Estimated Cost`,
                label: `${toCurrency(projectDetails.materialCost)} Used`,
                offLabel: `${toCurrency(
                  projectDetails.estimatedMaterialCost -
                    projectDetails.materialCost
                )} Remaining`,
                portion: 1,
                filled:
                  projectDetails.materialCost /
                  projectDetails.estimatedMaterialCost
              }
            ]}
          />
          <LastUpdated
            lastUpdated={projectDetails.materialLaborCostLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
        </>
      )}
    </Paper>
  );
};

export default connect(state => ({ user: state.user }))(ProjectGraphs);
