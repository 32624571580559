import React from 'react';
import { Dialog, Typography } from 'helpers/themeSafeMui.helper';
import { PhotoGrid } from 'components';

export default function GridPickerModal({ open, onClose, photos, onSelect }) {
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <Typography variant="h4" style={{ paddingLeft: 10 }}>
        Select Photo:
      </Typography>
      <PhotoGrid
        itemSizes={{ xs: 4, sm: 3, md: 2 }}
        photos={photos}
        onPhotoClick={onSelect}
        styles={{
          container: { maxHeight: '70vh', overflow: 'auto' },
          item: { height: '20vh' }
        }}
      />
    </Dialog>
  );
}
