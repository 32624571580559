import React, { useState, useEffect, useCallback } from 'react';
import http from 'helpers/http.helper';
import { Button, Grid, Typography } from 'helpers/themeSafeMui.helper';
import { Toast, LoadingOverlay, NoItemsResult, DropZone } from 'components';
import NewFileModal from './modals/NewFile.modal';
import File from './components/File.component';
import Breadcrumbs from './components/Breadcrumbs.component';
import debounce from 'lodash.debounce';
import { withRouter } from 'react-router-dom';

const FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder';

const DriveFolderViewer = props => {
  const {
    apiPath,
    isTemplate,
    gridSizes = {
      xl: 2,
      lg: 3,
      md: 4,
      xs: 6
    },
    onSelect = null
  } = props;

  const [files, setFiles] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [newFileVisible, setNewFileVisible] = useState(false);
  const [folderTree, setFolderTree] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const esignTemplates = await http().get('/esign-documents', {
          params: { isTemplate: true }
        });

        const templates = await http()
          .get('google/drive/templates')
          .then(res => res.files);

        setTemplates(
          templates.map(file => ({
            ...file,
            esignTemplate: esignTemplates.find(et => et.fileId === file.id)
          }))
        );
      } catch (err) {
        Toast.show(err.message);
      }
    };

    getTemplates();
  }, []);

  const getFiles = useCallback(
    folderId => {
      setLoading(true);
      setFiles([]);
      let stale = false;

      const recursivelyGetFiles = pageToken => {
        if (stale) return;
        return http()
          .get(apiPath, {
            params: {
              pageToken: pageToken,
              folderId: folderId
            }
          })
          .then(res => {
            if (stale) return;
            setFiles(f => f.concat(res.files));

            if (res.nextPageToken) {
              return recursivelyGetFiles(res.nextPageToken);
            } else {
              setFolderTree(ft => ft.concat({ id: res.id, name: res.name }));
            }
          });
      };

      recursivelyGetFiles()
        .catch(err => Toast.show(err.message))
        .finally(() => setLoading(false));

      return () => {
        stale = true;
      };
    },
    [apiPath]
  );

  useEffect(() => {
    setFolderTree([]);
    const cancel = getFiles();
    return () => {
      cancel();
    };
  }, [getFiles, props.location.key]);

  useEffect(() => {
    if (!files || !Array.isArray(files) || !files.length) {
      setStatusMap({});
      setStatusLoading(false);
      return;
    }
    setStatusLoading(true);
    let stale = false;
    http()
      .put('/esign-documents/query', { fileIds: files.map(file => file.id) })
      .then(map => {
        if (!stale) setStatusMap(map);
      })
      .catch(Toast.showErr)
      .finally(() => {
        if (!stale) setStatusLoading(false);
      });
    return () => (stale = true);
  }, [files]);

  const getEsignStatus = fileId => {
    if (statusLoading) return 'LOADING';
    const fromMap = statusMap[fileId];
    return fromMap || 'NO_ESIGN';
  };

  const createFile = files => {
    if (loading) return;
    setLoading(true);

    return Promise.all(
      files.map(async file => {
        const newFile = await http().post(apiPath, {
          name: file.name,
          mimeType: file.mimeType,
          file: file.base64,
          folderId: folderTree[folderTree.length - 1].id,
          templateId: file.templateId
        });

        if (file.esignTemplate) {
          const esignDoc = await http().post('/esign-documents', {
            fileId: newFile.id
          });
          await http().put(`/esign-documents/${esignDoc.id}/copy`, {
            templateId: file.esignTemplate.id
          });
        }

        setFiles(files => [newFile].concat(files));
      })
    )
      .then(() => Toast.show('File created.'))
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const deleteFile = id => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;

    http()
      .delete(`${apiPath}/${id}`)
      .then(() => setFiles(files => files.filter(f => f.id !== id)))
      .then(() => Toast.show('File deleted.'))
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const renameFile = debounce((name, fileId) => {
    http()
      .put(`${apiPath}/${fileId}`, { name })
      .catch(e => Toast.show(e.message));
  }, 1000);

  const getSharingLink = file => async () =>
    http()
      .put(`${apiPath}/${file.id}/link`)
      .catch(err => Toast.show(err.message));

  return (
    <LoadingOverlay loading={loading} style={props.style}>
      <NewFileModal
        templates={templates}
        open={newFileVisible}
        onClose={() => setNewFileVisible(false)}
        onCreate={createFile}
      />

      <Grid container justifyContent="space-between">
        <Grid item>
          <Breadcrumbs
            folderTree={folderTree}
            onChange={updatedFolderTree => {
              setFolderTree(updatedFolderTree.slice(0, -1));
              getFiles(updatedFolderTree.slice(-1)[0].id);
            }}
          />
        </Grid>
        <Grid item>
          {folderTree[0] && (
            <Button
              variant="outlined"
              color="secondary"
              href={`https://drive.google.com/drive/folders/${folderTree[0].id}`}
              target="_blank"
              rel="noopener noreferrer"
              component="a"
            >
              Open Drive Folder
            </Button>
          )}
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginLeft: 8 }}
            onClick={() => setNewFileVisible(true)}
          >
            New File/Folder
          </Button>
        </Grid>
      </Grid>

      <DropZone onRead={createFile} wrapper style={{ height: '100%' }}>
        {!files.length && !loading && (
          <NoItemsResult type="Files" style={{ padding: 50 }} />
        )}

        {/* ==== FOLDERS ==== */}
        {files.some(f => f.mimeType === FOLDER_MIME_TYPE) && (
          <div style={{ margin: '24px 0' }}>
            <Typography variant="body2" gutterBottom>
              <strong>Folders</strong>
            </Typography>
            <Grid container spacing={3}>
              {files
                .filter(file => file.mimeType === FOLDER_MIME_TYPE)
                .map(file => (
                  <Grid item {...gridSizes} key={file.id}>
                    <File
                      folder
                      onRename={renameFile}
                      onDelete={deleteFile}
                      file={file}
                      onChangeFolder={folderId => getFiles(folderId)}
                      getSharingLink={getSharingLink(file)}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}

        {/* ==== FILES ==== */}
        {files.some(f => f.mimeType !== FOLDER_MIME_TYPE) && (
          <div style={{ margin: '24px 0' }}>
            <Typography variant="body2" gutterBottom>
              <strong>Files</strong>
            </Typography>
            <Grid container spacing={3}>
              {files
                .filter(file => file.mimeType !== FOLDER_MIME_TYPE)
                .map(file => (
                  <Grid item {...gridSizes} key={file.id}>
                    <File
                      isTemplate={isTemplate}
                      onRename={renameFile}
                      onDelete={deleteFile}
                      file={file}
                      getSharingLink={getSharingLink(file)}
                      onSelect={onSelect ? () => onSelect(file) : null}
                      esignStatus={getEsignStatus(file.id)}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
      </DropZone>
    </LoadingOverlay>
  );
};

export default withRouter(DriveFolderViewer);
