import React, { useContext } from 'react';
import {
  ButtonBase,
  Divider,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { ProjectsContext } from '../context/projects.context';
import currency from 'helpers/currency.helper';
import getStatusColor from '../helpers/getStatusColor.helper';
import { useColors } from 'helpers/theme.helper';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';

export default function PerDiemCard({ perDiem, onClick }) {
  const { projectsMap } = useContext(ProjectsContext);
  const colors = useColors();
  const project = projectsMap[perDiem.projectId] || {};

  const start = formatDate(perDiem.startDate);
  let end = formatDate(perDiem.endDate);
  if (end === start) end = null;

  return (
    <ButtonBase
      onClick={onClick}
      style={{ display: 'block', width: '100%', textAlign: 'left' }}
    >
      <Paper>
        <div style={{ padding: 10 }}>
          <Typography variant="h6">
            {project.projectNumber} {project.name}
          </Typography>
          <Typography variant="overline" style={{ fontWeight: 500 }}>
            {start + (end ? ` - ${end}` : '')}
          </Typography>
        </div>
        <Divider />
        <div style={{ padding: 10 }}>
          {!!end && (
            <TitleItem title="Daily Amount:">
              {currency(perDiem.dailyAmount)}
            </TitleItem>
          )}
          <TitleItem title="Total Amount:">
            {currency(perDiem.totalAmount)}
          </TitleItem>
          <TitleItem
            title="Status:"
            style={{ color: colors[getStatusColor(perDiem.status)] }}
          >
            {perDiem.status}
          </TitleItem>
          <TitleItem title="Locked:" component="div" style={{ paddingTop: 3 }}>
            {perDiem.locked ? (
              <BsLockFill color={colors.error} size={20} />
            ) : (
              <BsUnlockFill color={colors.success} size={20} />
            )}
          </TitleItem>
          {perDiem.notes && (
            <TitleItem
              title="Notes:"
              variant="caption"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {perDiem.notes}
            </TitleItem>
          )}
        </div>
      </Paper>
    </ButtonBase>
  );
}

function TitleItem({ title, children, ...rest }) {
  return (
    <div style={{ paddingBottom: 10 }}>
      <Typography
        component="div"
        variant="overline"
        style={{ fontWeight: 600, lineHeight: 1 }}
      >
        {title}
      </Typography>
      <div style={{ padding: '0 10px' }}>
        <Typography {...rest}>{children}</Typography>
      </div>
    </div>
  );
}

function formatDate(dateString) {
  return !!dateString && moment(dateString).format('ddd M/D');
}
