import React, { useState } from 'react';
import safeParse from 'helpers/safeParse.helper';
import { TableCell, Tooltip, Typography } from 'helpers/themeSafeMui.helper';
import moment from 'moment';

export const ColumnsContext = React.createContext();

const ColumnsContextProvider = ({ children }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);

  return (
    <ColumnsContext.Provider
      value={{ allColumns, selectedColumns, setSelectedColumns }}
    >
      {children}
    </ColumnsContext.Provider>
  );
};

function makeRenderer(getText, props = {}) {
  return (project, info, cellStyle) => (
    <DenseCell {...props} style={{ ...cellStyle, ...props.style }}>
      {getText(project)}
    </DenseCell>
  );
}

const DenseCell = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <TableCell
      ref={ref}
      padding="none"
      align="right"
      style={{
        fontFamily: 'monospace',
        whiteSpace: 'nowrap',
        ...props.style
      }}
      {...props}
    >
      <div style={{ padding: '0 10px' }}>
        <Typography style={{ fontFamily: 'inherit', maxWidth: '300px' }} noWrap>
          {children}
        </Typography>
      </div>
    </TableCell>
  );
});

const headerCellProps = { align: 'right' };

const allColumns = [
  {
    key: 'projectNumber',
    label: 'Project Number',
    renderer: (project, info, cellStyle) => (
      <Tooltip title={project.name} style={cellStyle}>
        <DenseCell align="left">{project.projectNumber}</DenseCell>
      </Tooltip>
    )
  },

  // Project Manager - should have Project Manager.
  {
    key: 'projectManager',
    label: 'Project Manager',
    isComplete: project => !!project.projectManagerName,
    renderer: makeRenderer(project =>
      project && project.projectManagerName ? project.projectManagerName : '---'
    ),
    headerCellProps
  },

  // Lead Technician - should have Lead Technician.
  {
    key: 'leadTechnicianName',
    label: 'Lead Technician',
    isComplete: project => !!project.leadTechnicianName,
    renderer: makeRenderer(project =>
      project && project.leadTechnicianName ? project.leadTechnicianName : '---'
    ),
    headerCellProps
  },

  // Won - should not be won.
  {
    key: 'bidStatus',
    label: 'Project Won',
    isComplete: project => project.bidStatus === 'won',
    renderer: makeRenderer(project =>
      project && !project.bidStatus
        ? '---'
        : project.bidStatus === 'won'
        ? 'Won'
        : 'Lost'
    ),
    headerCellProps
  },

  // Est Cost - Should be > $0
  {
    key: 'details.estimatedCost',
    label: 'Est Cost',
    isComplete: (project, details) => safeParse(details.estimatedCost) > 0,
    ...numSort(
      project =>
        project.details &&
        project.details.estimatedCost &&
        safeParse(project.details.estimatedCost)
    ),
    renderer: makeRenderer(project =>
      project &&
      project.details &&
      project.details.estimatedCost &&
      safeParse(project.details.estimatedCost)
        ? dollar(safeParse(project.details.estimatedCost))
        : '---'
    ),
    headerCellProps
  },

  // Scope - should not be blank
  {
    key: 'details.scopeOfWork',
    label: 'Scope',
    isComplete: (project, details) =>
      details.scopeOfWork && details.scopeOfWork.trim(),
    extractor: project =>
      project.details &&
      project.details.scopeOfWork &&
      project.details.scopeOfWork.trim(),
    renderer: makeRenderer(project =>
      project && project.details && project.details.scopeOfWork
        ? project.details.scopeOfWork
        : '---'
    ),
    headerCellProps
  },

  // PO - should not be blank
  {
    key: 'details.customerPO',
    label: 'PO',
    isComplete: (project, details) =>
      details.customerPO && details.customerPO.trim(),
    extractor: project => project.details && project.details.customerPO,
    renderer: makeRenderer(project =>
      project && project.details && project.details.customerPO
        ? project.details.customerPO
        : '---'
    ),
    headerCellProps
  },

  // Start - should be completed
  {
    key: 'details.start',
    label: 'Start Date',
    isComplete: (project, details) =>
      details.start && moment(details.start).isValid(),
    ...numSort(
      project =>
        project.details &&
        project.details.start &&
        moment(project.details.start).unix()
    ),
    renderer: makeRenderer(project =>
      project && project.details && project.details.start
        ? moment(project.details.start).format('M/D/YY')
        : '---'
    ),
    headerCellProps
  },

  // End Date - should be completed
  {
    key: 'details.end',
    label: 'End Date',
    isComplete: (project, details) =>
      details.end && moment(details.end).isValid(),
    ...numSort(
      project =>
        project.details &&
        project.details.end &&
        moment(project.details.end).unix()
    ),
    renderer: makeRenderer(project =>
      project && project.details && project.details.end
        ? moment(project.details.end).format('M/D/YY')
        : '---'
    ),
    headerCellProps
  },

  // Est Crew - should be > 0
  {
    key: 'details.estimatedCrew',
    label: 'Est Crew',
    isComplete: (project, details) => safeParse(details.estimatedCrew) > 0,
    ...numSort(
      project =>
        project.details &&
        project.details.estimatedCrew &&
        safeParse(project.details.estimatedCrew)
    ),
    renderer: makeRenderer(project =>
      project && project.details && project.details.estimatedCrew
        ? project.details.estimatedCrew
        : '---'
    ),
    headerCellProps
  },

  // Is site contact filled out
  {
    key: 'customerOnSiteContactName',
    label: 'On Site Contact',
    isComplete: project =>
      project.customerOnSiteContactName &&
      project.customerOnSiteContactName.trim(),
    renderer: makeRenderer(project =>
      project &&
      project.customerOnSiteContactName &&
      project.customerOnSiteContactName.trim()
        ? project.customerOnSiteContactName
        : '---'
    ),
    headerCellProps
  },

  // Is site address filled out
  {
    key: 'address',
    label: 'Job Site Address',
    isComplete: project => project.address && project.address.trim(),
    renderer: makeRenderer(project =>
      project && project.address && project.address.trim()
        ? project.address
        : '---'
    ),
    headerCellProps
  }
];

export default ColumnsContextProvider;

function numSort(extractor) {
  return {
    customSort: (a, b) => extractor(a) - extractor(b)
  };
}

function dollar(number) {
  number = numOrNull(number);
  return number === null || number === 0
    ? '---'
    : `$ ${addCommas(number.toFixed(2))}`;
}

function numOrNull(number) {
  number = Number(number);
  return isNaN(number) ? null : number;
}

function addCommas(string) {
  const [numSide, decSide] = string.split('.');
  const nums = numSide.match(/\d+/)[0] || '';
  const arr = nums.split('');
  let adj = 0;
  for (let i = 3; i < nums.length; i += 3) {
    arr.splice(-(i + adj), 0, ',');
    adj++;
  }
  return `${numSide.replace(nums, arr.join(''))}.${decSide}`;
}
