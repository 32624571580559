import React, { useContext, useState } from 'react';
import StepsRfis from './components/StepsRfis.component';

import PlangridRfis from './components/PlangridRfis.component';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import { FormControlLabel, Switch } from 'helpers/themeSafeMui.helper';

const RfiTab = () => {
  const [viewingArchived, setViewingArchived] = useState(false);
  const { projectDetails } = useContext(ProjectDetailsContext);

  if (!projectDetails.plangridProjectId)
    return (
      <div>
        <StepsRfis />
      </div>
    );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControlLabel
          control={
            <Switch
              checked={viewingArchived}
              onChange={() => setViewingArchived(old => !old)}
              name="viewingArchived"
            />
          }
          label={`${viewingArchived ? '' : 'Not '}Showing Archived`}
          labelPlacement="start"
        />
      </div>
      {viewingArchived ? <StepsRfis hideCreateButton /> : <PlangridRfis />}
    </div>
  );
};

export default RfiTab;
