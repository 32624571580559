import React, { useEffect } from 'react';
import {
  Paper,
  Typography,
  Divider,
  Button
} from 'helpers/themeSafeMui.helper';
import { ActionItem, Toast } from 'components';
import http from 'helpers/http.helper';
import { Link } from 'react-router-dom';
import { useColors } from 'helpers/theme.helper';
import {
  useSetActionItems,
  useSortedUserActionItems
} from 'state/actionItems.state';

const ActionItemsHome = props => {
  const userActionItems = useSortedUserActionItems(props.user.id, 'created');
  const setActionItems = useSetActionItems();
  const colors = useColors();

  useEffect(() => {
    http()
      .get(`/action-items/query?userId=${props.user.id}&completed=false`)
      .then(({ actionItems }) => {
        setActionItems(actionItems);
      })
      .catch(err => Toast.show(err.message));
  }, [props.user.id, setActionItems]);

  return (
    <Paper style={{ padding: '0 16px', maxHeight: 530, overflow: 'auto' }}>
      <Typography
        style={{
          paddingTop: 16,
          backgroundColor: colors.paper,
          position: 'sticky',
          top: 0
        }}
        variant="h5"
        gutterBottom
      >
        Action Items
      </Typography>
      <Divider />

      {userActionItems
        .filter(item => !item.dateCompleted)
        .map(item => (
          <div key={item.id}>
            <ActionItem id={item.id} />
            <Divider />
          </div>
        ))}
      <div
        style={{
          backgroundColor: colors.paper,
          position: 'sticky',
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          marginTop: 16,
          paddingBottom: 16
        }}
      >
        <Button
          variant="text"
          color="secondary"
          component={Link}
          to={`/action-items`}
        >
          View all action items
        </Button>
      </div>
    </Paper>
  );
};

export default ActionItemsHome;
