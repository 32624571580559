import moment from 'moment';

/**
 * @param {Date} startDay
 */
const getTodayIndex = startDay => {
  return moment().diff(startDay, 'days');
};

export default getTodayIndex;
