import React, { useState, useEffect, useCallback } from 'react';
import { DropZone, Toast, NoItemsResult, ExternalLink } from 'components';
import http from 'helpers/http.helper';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import { useColors } from 'helpers/theme.helper';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

const successMessage = `file was uploaded to the drive, check drive to make changes.`;

function WorkOrderFiles(props) {
  const { workOrder, canEdit } = props;
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [usingOneDrive] = useState(() => isUsingOneDrive());
  const colors = useColors();

  const uploadFile = files => {
    const file = files[0];
    setLoading(true);

    http({ withLoader: false })
      .post(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/file`,
        {
          name: file.name,
          file: file.base64
        }
      )
      .then(() => {
        Toast.show(successMessage);
        getWorkOrderDocs();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const openOneDriveSharingLink = () => {
    setLoading(true);
    http()
      .get(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/file/share`
      )
      .then(url => {
        setLoading(false);
        window.open(url, '_blank');
      })
      .catch(err => Toast.show(err.message));
  };

  const removeWorkOrderFile = docId => {
    http()
      .delete(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/file/${docId}`
      )
      .then(() => getWorkOrderDocs())
      .catch(err => Toast.show(err.message));
  };

  const getWorkOrderDocs = useCallback(() => {
    http()
      .get(`/projects/${workOrder.projectId}/work-orders/${workOrder.id}/file`)
      .then(res => setDocuments(res))
      .catch(err => Toast.show(err.message));
  }, [workOrder]);

  useEffect(() => {
    getWorkOrderDocs();
  }, [getWorkOrderDocs]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <DocumentsList
          canEdit={canEdit && !workOrder.techSignOff}
          documents={documents}
          removeWorkOrderFile={removeWorkOrderFile}
        />
        <div style={{ textAlign: 'center', marginTop: 8 }}>
          {workOrder.workOrderFolderId &&
            documents.length > 0 &&
            (usingOneDrive ? (
              <Button
                variant="contained"
                size="small"
                disabled={loading}
                onClick={openOneDriveSharingLink}
              >
                view work order drive folder
              </Button>
            ) : (
              <ExternalLink
                href={`https://drive.google.com/drive/folders/${workOrder.workOrderFolderId}`}
              >
                view work order drive folder
              </ExternalLink>
            ))}
        </div>
      </Grid>

      <Grid item sm={6} xs={12}>
        {canEdit && !workOrder.techSignOff ? (
          <DropZone
            style={{ height: 200 }}
            onRead={uploadFile}
            loading={loading}
            title={`Upload Work Order Document`}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: colors.grey,
              textAlign: 'center',
              height: 200
            }}
          >
            <Typography style={{ margin: 'auto' }}>
              You can not add documents to this work order.
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default WorkOrderFiles;

function DocumentsList(props) {
  const { documents, removeWorkOrderFile, canEdit } = props;

  return (
    <React.Fragment>
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <Typography variant="h5">Uploaded Docs in Drive</Typography>
      </div>

      <Paper>
        <List>
          {!documents.length && <NoItemsResult type="Work Order Documents" />}
          {documents.map(doc => (
            <div key={doc.id}>
              <ListItem>
                <ListItemText>
                  <ExternalLink href={doc.webViewLink}>
                    {doc.name.length > 50
                      ? `${doc.name.substring(0, 50)}...`
                      : doc.name}
                  </ExternalLink>
                </ListItemText>
                <ListItemSecondaryAction>
                  {canEdit && (
                    <IconButton onClick={() => removeWorkOrderFile(doc.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
    </React.Fragment>
  );
}
