import React from 'react';
import ProjectActionItemsComponent from './components/ProjectActionItems.component';
import { Grid } from 'helpers/themeSafeMui.helper';
import ProjectNotesComponent from './components/ProjectNotes.component';
import ProjectGraphs from './components/ProjectGraphs.component';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PlangridCard from 'routes/Projects/routes/Project/cards/Plangrid.card';
import ProjectTeamCard from 'routes/Projects/routes/Project/cards/ProjectTeam.card';
import ProjectDetailsCard from 'routes/Projects/routes/Project/cards/ProjectDetails.card';
import CustomerContactsCard from 'routes/Projects/routes/Project/cards/CustomerContacts.card';
import ProjectScheduleCard from 'routes/Projects/routes/Project/cards/ProjectSchedule.card';
import CustomerBillingCard from 'routes/Projects/routes/Project/cards/CustomerBilling.card';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import BidInfoCard from 'routes/Projects/routes/Project/cards/BidInfo.card';

function ProjectHomeTab(props) {
  const isSmallScreen = useBreakpoint('sm');

  const AIsAndNotes = (
    <Grid container spacing={1}>
      <Grid item lg={6} xs={12}>
        <ProjectActionItemsComponent projectId={props.projectId} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <ProjectNotesComponent />
      </Grid>
    </Grid>
  );

  const graphs = (
    <Grid item md={8} sm={12} container spacing={1}>
      <Grid item xs={12}>
        <div style={{ width: '100%', marginBottom: 8 }}>
          <ProjectGraphs />
        </div>
        {!isSmallScreen && AIsAndNotes}
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {isSmallScreen && graphs}
        <Grid style={{ width: '100%' }} item md={4} sm={12}>
          <CustomerBillingCard />
          <ProjectDetailsCard />
          <ProjectTeamCard />
          <ProjectScheduleCard />
          <PlangridCard />
          <BidInfoCard />
          <CustomerContactsCard />
        </Grid>
        {isSmallScreen && AIsAndNotes}

        {!isSmallScreen && graphs}
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(
  connect(state => ({ user: state.user }))(ProjectHomeTab)
);
