import React, { useState, useEffect } from 'react';
import { Popover, Button, Typography } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, CustomInput } from 'components';

const EditPopover = ({ selectedPerdiems, setPerDiems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const [locked, setLocked] = useState(null);
  const [paidOn, setPaidOn] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!anchorEl) {
      setStatus(null);
      setLocked(null);
      setPaidOn(null);
    }
  }, [anchorEl]);

  useEffect(() => {
    setPaidOn(null);
  }, [status]);

  const update = async () => {
    try {
      if (status === null && locked === null) return;
      const update = {};
      if (status !== null) update.status = status;
      if (locked !== null) update.locked = locked;
      if (status === 'Paid' && paidOn !== null) update.paidOn = paidOn;

      const updated = await Promise.all(
        selectedPerdiems.map(perDiem =>
          http().put(`/per-diems/${perDiem.id}`, update)
        )
      );
      setPerDiems(old =>
        old.map(
          perDiem =>
            updated.find(updatedItem => updatedItem.id === perDiem.id) ||
            perDiem
        )
      );
      setAnchorEl(null);
      Toast.show('Updated.');
    } catch (err) {
      Toast.show(err.message);
    }
  };

  return (
    <>
      <Button onClick={handleClick} variant="contained" color="primary">
        Update Selected
      </Button>
      <Popover
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div style={{ padding: 10, minWidth: 250 }}>
          <Typography variant="h6" gutterBottom>
            {`Update ${selectedPerdiems.length} Item${
              selectedPerdiems.length > 1 ? 's' : ''
            }`}
          </Typography>
          <CustomInput
            type="dropdown"
            value={status}
            onChange={val => setStatus(val)}
            label="Status"
            options={[
              { value: 'Pending', label: 'Pending' },
              { value: 'Approved', label: 'Approved' },
              { value: 'Rejected', label: 'Rejected' },
              { value: 'Paid', label: 'Paid' }
            ]}
          />
          {status === 'Paid' && (
            <CustomInput
              type="date"
              label="Paid On"
              value={paidOn}
              onChange={setPaidOn}
            />
          )}
          <CustomInput
            type="boolean"
            value={locked}
            onChange={val => setLocked(val)}
            label="Locked"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 16
            }}
          >
            <Button
              onClick={update}
              disabled={status === null && locked === null}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
export default EditPopover;
