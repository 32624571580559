import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, makeStyles } from 'helpers/themeSafeMui.helper';

const useTooltipClasses = columnCount =>
  makeStyles(theme => {
    return {
      arrow: {
        color: theme.palette.secondary.main
      },
      tooltip: {
        backgroundColor: theme.palette.secondary.main,
        display: 'grid',
        gridTemplateColumns: `repeat(${columnCount || 2}, auto)`,
        gridColumnGap: theme.typography.pxToRem(5)
      }
    };
  })();

function TooltipTable({ grid, children, ...rest }) {
  const validGrid = !!(grid && grid[0] && grid[0].length);
  const columnCount = validGrid ? grid[0].length : 0;
  const classes = useTooltipClasses(columnCount);

  if (!validGrid) return null;

  const style = { margin: 0, padding: 0 };
  return (
    <Tooltip
      arrow
      classes={classes}
      {...rest}
      title={grid.flat().map((val, idx) => (
        <Typography key={`${val},${idx}`} variant="subtitle2" style={style}>
          {val}
        </Typography>
      ))}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipTable;
