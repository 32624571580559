import React from 'react';
import { IconButton } from 'helpers/themeSafeMui.helper';
import {
  FaCommentsDollar,
  FaFileInvoiceDollar,
  FaHandshake,
  FaQuoteRight,
  FaPhone,
  FaEnvelope,
  FaWalking,
  FaFileAlt,
  FaPencilRuler,
  FaHighlighter,
  FaBook,
  FaClipboardList,
  FaQuestionCircle
} from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';

const icons = [
  { icon: <FaCommentsDollar />, autoFillText: 'Change Order' },
  { icon: <FaFileInvoiceDollar />, autoFillText: 'Create Invoice' },
  { icon: <FaHandshake />, autoFillText: 'Hand Off Meeting' },
  { icon: <FaQuoteRight />, autoFillText: 'Quote Due' },
  { icon: <FaPhone />, autoFillText: 'Phone Call' },
  { icon: <FaEnvelope />, autoFillText: 'Send Email' },
  { icon: <FaWalking />, autoFillText: 'Walk Through' },
  { icon: <BsPeopleFill />, autoFillText: 'Meeting' },
  { icon: <FaFileAlt />, autoFillText: 'Submittals' },
  { icon: <FaPencilRuler />, autoFillText: 'Drawing + Labels' },
  { icon: <FaHighlighter />, autoFillText: 'Asbuilt/Redlines' },
  { icon: <FaBook />, autoFillText: 'O&M Manual' },
  { icon: <FaClipboardList />, autoFillText: 'Warranty' },
  { icon: <FaQuestionCircle />, autoFillText: 'Other Task' }
];

function TitleAutoFill(props) {
  const { autofill, sideEffect = {} } = props;

  return (
    <React.Fragment>
      {icons.map(item => (
        <IconButton
          key={item.autoFillText}
          title={item.autoFillText}
          onClick={() => {
            autofill(item.autoFillText);

            if (typeof sideEffect[item.autoFillText] === 'function')
              sideEffect[item.autoFillText](item.autoFillText);

            if (Array.isArray(sideEffect[item.autoFillText]))
              sideEffect[item.autoFillText].forEach(fn =>
                typeof fn === 'function' ? fn(item.autoFillText) : null
              );
          }}
        >
          {item.icon}
        </IconButton>
      ))}
    </React.Fragment>
  );
}

export default TitleAutoFill;
