import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { fromTimeString, toDateString } from '../helpers/DateTime.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import mapReducer from 'helpers/mapReducer.helper';

export const ShiftScheduleContext = React.createContext();

export default function ShiftScheduleProvider({ children }) {
  const [dateWindow, setDateWindow] = useState(getDefaultDateWindow);
  const [shiftDays, setShiftDays] = useState([]);
  const [shiftUserMap, setShiftUserMap] = useState({});
  const [userIdShiftMap, setUserIdShiftMap] = useState({});
  const [userIdHoursMap, setUserIdHoursMap] = useState({});
  const [dateShiftMap, setDateShiftMap] = useState({});
  const [shiftMap, setShiftMap] = useState({});
  const [hideWeekend, setHideWeekend] = useState(true);
  const [tasksInWindow, setTasksInWindow] = useState([]);
  const [projectIds, setProjectIds] = useState([]);

  const fetchSchdeules = useCallback(() => {
    return Promise.all([
      http().get(`/shift-days${dateWindowQs(dateWindow)}`),
      http().get(`/shift-day-users${dateWindowQs(dateWindow)}`),
      http().get(`/shift-days/related-tasks${dateWindowQs(dateWindow)}`)
    ])
      .then(([days, shiftUsers, tasks]) => {
        const dateShiftMap = days.reduce((map, day) => {
          const dateString = toDateString(moment(day.date));
          if (map[dateString]) {
            map[dateString].push(day);
          } else {
            map[dateString] = [day];
          }
          return map;
        }, {});
        setDateShiftMap(dateShiftMap);

        const shiftUserMap = shiftUsers.reduce((map, shiftUser) => {
          if (map[shiftUser.shiftDayId]) {
            map[shiftUser.shiftDayId].push(shiftUser);
          } else {
            map[shiftUser.shiftDayId] = [shiftUser];
          }
          return map;
        }, {});
        setShiftUserMap(shiftUserMap);

        const userIdShiftMap = shiftUsers.reduce((map, shiftUser) => {
          if (map[shiftUser.userId]) {
            map[shiftUser.userId].push(shiftUser);
          } else {
            map[shiftUser.userId] = [shiftUser];
          }
          return map;
        }, {});
        setUserIdShiftMap(userIdShiftMap);

        const projectIdSet = {};
        const projectIds = [];
        days.forEach(shiftDay => {
          if (projectIdSet[shiftDay.projectId]) return;
          projectIdSet[shiftDay.projectId] = true;
          projectIds.push(shiftDay.projectId);
        });
        setProjectIds(projectIds);

        const shiftMap = days.reduce(mapReducer('id'), {});
        setUserIdHoursMap(getUserIdHoursMap(userIdShiftMap, shiftMap));

        setShiftMap(shiftMap);
        setShiftDays(days);
        setTasksInWindow(tasks);
      })
      .catch(Toast.showErr);
  }, [dateWindow]);

  useEffect(() => {
    fetchSchdeules();
  }, [fetchSchdeules]);

  return (
    <ShiftScheduleContext.Provider
      value={{
        dateWindow,
        setDateWindow,

        projectIds,

        tasksInWindow,
        shiftDays,
        shiftMap,
        dateShiftMap,
        userIdShiftMap,
        userIdHoursMap,

        shiftUserMap,
        fetchSchdeules,

        hideWeekend,
        setHideWeekend
      }}
    >
      {children}
    </ShiftScheduleContext.Provider>
  );
}

function getDefaultDateWindow() {
  return [moment().startOf('week'), moment().endOf('week')];
}

function dateWindowQs(dateWindow) {
  dateWindow = dateWindow.map(toDateString);
  if (!dateWindow || (!dateWindow[0] && !dateWindow[1])) return '';
  let qs = '?';
  let after = '';
  let before = '';
  if (dateWindow[0]) after += `after=${dateWindow[0]}`;
  if (dateWindow[1]) before += `before=${dateWindow[1]}`;
  if (after && before) after += '&';
  return qs + after + before;
}

function getUserIdHoursMap(userIdShiftMap, shiftMap) {
  const userIdHoursMap = {};
  Object.keys(userIdShiftMap).forEach(userId => {
    const shiftsUsers = userIdShiftMap[userId];
    userIdHoursMap[userId] = 0;
    shiftsUsers
      .map(({ shiftDayId }) => shiftMap[shiftDayId])
      .forEach(shift => {
        userIdHoursMap[userId] += getShiftHours(shift);
      });
  });
  return userIdHoursMap;
}

function getShiftHours(shift) {
  if (!shift) return 0;
  return fromTimeString(shift.end).diff(
    fromTimeString(shift.start).add(shift.lunchDurationMinutes || 0, 'minutes'),
    'hours',
    true
  );
}
