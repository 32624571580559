import React, { useState } from 'react';
import http from 'helpers/http.helper';
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Collapse,
  IconButton,
  Divider,
  Button
} from 'helpers/themeSafeMui.helper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocationModal from '../modals/DrugTestLocation.modal';

const DrugTestLocation = ({ location, update, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [editing, setEditing] = useState(false);

  const updateLocation = async updates => {
    const copy = { ...updates };
    delete copy.id;
    delete copy.archived;
    delete copy.created;
    const updated = await http().put(
      `/drug-test-locations/${location.id}`,
      copy
    );
    update(updated);
  };

  const remove = async () => {
    if (!confirmed) return setConfirmed(true);
    await http().delete(`/drug-test-locations/${location.id}`);
    onDelete();
  };

  return (
    <Paper key={location.id}>
      <LocationModal
        open={editing}
        onClose={() => setEditing(false)}
        onSubmit={updateLocation}
        title="Edit Location"
        location={location}
      />
      <div
        style={{
          padding: 16,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Typography variant="body1">{location.area}</Typography>
          <Typography variant="caption">{location.name}</Typography>
        </div>
        <div>
          <IconButton onClick={() => setOpen(open => !open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {confirmed ? (
            <span>
              Are you sure?
              <Button
                variant="contained"
                style={{ marginLeft: 9 }}
                size="small"
                color="primary"
                onClick={remove}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 9 }}
                size="small"
                color="default"
                onClick={() => setConfirmed(false)}
              >
                No
              </Button>
            </span>
          ) : (
            <IconButton onClick={remove}>
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      {open && <Divider />}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Area:</TableCell>
              <TableCell>{location.area}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:</TableCell>
              <TableCell>{location.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>{location.address}</TableCell>
            </TableRow>
            {!!location.address2 && (
              <TableRow>
                <TableCell>Address2:</TableCell>
                <TableCell>{location.address2}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>{location.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hours:</TableCell>
              <TableCell>{location.hours}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost:</TableCell>
              <TableCell>{location.cost}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Notes:</TableCell>
              <TableCell>{location.notes}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </Paper>
  );
};

export default DrugTestLocation;
