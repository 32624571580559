import CanvasUtils from './CanvasUtils.canvas';
import moment from 'moment';

class Week extends CanvasUtils {
  constructor({
    ctx,
    tasks,
    printView,
    rowHeight,
    weekNumber,
    startDate,
    dpr
  }) {
    super(ctx);
    if (dpr) this.dpr = dpr;
    this.tasks = tasks;
    this.printView = printView;
    this.rowHeight = rowHeight;
    this.padding = { x: 5 * this.dpr, y: 4 * this.dpr };
    this.weekNumber = weekNumber;
    this.startDate = startDate;
    this.visibleTasks = printView.taskIds.map(id =>
      tasks.find(task => task.id === id)
    );
    this.cellWidth = 40 * this.dpr;
    this.width = this.cellWidth * 5;
  }

  getTaskLabel(task) {
    const labelId = this.printView.taskLabels[task.id];
    return this.printView.labels.find(label => label.id === labelId);
  }

  printDates() {
    const lightBox = { color: '#cccccc', fill: '#ffffff' };
    const { startDate, cellWidth: w, rowHeight: h, padding } = this;
    for (let i = 1; i < 6; i++) {
      const x = (i - 1) * w;
      const y = h;
      const centerX = x + w / 2;
      const day = moment(startDate).add(i, 'days');

      // Day of week
      this.box(x, y, w, h, lightBox);
      this.cText(day.format('ddd').toUpperCase(), centerX, y + padding.y, {
        size: 0.9
      });

      // date
      this.box(x, y + h, w, h, lightBox);
      this.cText(day.format('M/D'), centerX, y + padding.y + h, {
        size: 0.9
      });
    }
  }

  printTotals() {
    const {
      startDate,
      cellWidth: w,
      rowHeight: h,
      padding,
      visibleTasks
    } = this;
    const lightBox = { color: '#cccccc', fill: '#ffffff' };
    const totals = Array(5).fill(0);
    visibleTasks.forEach(task => {
      for (let idx = 0; idx < 5; idx++) {
        const day = moment(startDate).add(idx + 1, 'days');
        if (this.activeTaskDay(task, day)) totals[idx] += task.techCount;
      }
    });
    for (let idx = 0; idx < 5; idx++) {
      const x = idx * w;
      const rightSide = x + w;
      this.box(x, 0, w, h, lightBox);
      this.rText(totals[idx], rightSide - padding.x, padding.y, {
        size: 1
      });
    }
    this.box(0, 0, this.width, h);
  }

  printTask(task) {
    const { startDate, cellWidth: w, rowHeight: h, padding } = this;
    const lightBox = { color: '#cccccc', fill: '#ffffff' };
    const label = this.getTaskLabel(task);
    for (let i = 1; i < 6; i++) {
      const x = (i - 1) * w;
      const y = 0;
      const rightSide = x + w;
      const day = moment(startDate).add(i, 'days');
      const isActiveDay = this.activeTaskDay(task, day);

      if (isActiveDay && label) {
        this.box(x, y, w, h, { ...lightBox, fill: '#' + label.color });
      } else {
        this.box(x, y, w, h, lightBox);
      }
      if (isActiveDay)
        this.rText(task.techCount, rightSide - padding.x, y + padding.y, {
          size: 1
        });
    }
  }

  activeTaskDay(task, day) {
    const taskStart = moment(task.startDate).startOf('day');
    const taskEnd = moment(task.endDate).endOf('day');
    const isActiveDay = moment(day).isBetween(taskStart, taskEnd, null, '[]');
    return (
      isActiveDay &&
      !(task.scheduleDaysPerWeek === 4 && moment(day).day() === 5)
    );
  }

  printEmptyRow() {
    const { cellWidth: w, rowHeight: h } = this;
    const lightBox = { color: '#cccccc', fill: '#ffffff' };
    for (let i = 0; i < 5; i++) {
      const x = i * w;
      const y = 0;
      this.box(x, y, w, h, lightBox);
    }
  }

  print() {
    const {
      ctx,
      rowHeight,
      weekNumber,
      padding,
      width: weekWidth,
      visibleTasks,
      printView
    } = this;
    const fill = weekNumber % 2 === 0 ? '#cccccc' : '#eeeeee';
    this.box(0, 0, weekWidth, rowHeight, { fill, color: '#cccccc' });
    this.cText(`Week ${weekNumber}`, weekWidth / 2, padding.y);
    this.printDates();
    this.box(0, 0, weekWidth, rowHeight * 3);
    visibleTasks.forEach((task, idx) => {
      const headerHeight = rowHeight * 3;
      ctx.save();
      ctx.translate(0, headerHeight + idx * rowHeight);
      this.printTask(task);
      ctx.restore();
    });
    printView.labels.forEach((l, idx) => {
      const top = (visibleTasks.length + 3) * rowHeight;
      ctx.save();
      ctx.translate(0, top + idx * rowHeight);
      this.printEmptyRow();
      ctx.restore();
    });
    const contentHeight =
      (visibleTasks.length + printView.labels.length) * rowHeight;

    ctx.save();
    ctx.translate(0, contentHeight + rowHeight * 3);
    this.printTotals();
    ctx.restore();

    this.box(0, rowHeight * 3, weekWidth, contentHeight);
  }
}

export default Week;
