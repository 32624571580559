import Table from './DndTable.component';
import TableHead from './DndTableHead.component';
import TableRow from './DndTableRow.component';

const DndTable = {
  Table,
  TableHead,
  TableRow
};

export default DndTable;

/*
import TableCell from '@material-ui/core/TableCell';
import { DndTable } from 'components';

const UsageExample = () => {
  const [values, setValues] = useState([
    { num: 1, id: 'id1' },
    { num: 2, id: 'id2' },
    { num: 3, id: 'id3' }
  ]);

  return (
    <DndTable.Table onDrop={setValues}>
      <DndTable.TableHead>
        <TableCell>A</TableCell>
        <TableCell>B</TableCell>
        <TableCell>C</TableCell>
      </DndTable.TableHead>
      {values.map(value => (
        <DndTable.TableRow value={value} key={value.id} id={value.id}>
          <TableCell>A {value.num}</TableCell>
          <TableCell>B {value.num}</TableCell>
          <TableCell>C {value.num}</TableCell>
        </DndTable.TableRow>
      ))}
    </DndTable.Table>
  );
};
*/
