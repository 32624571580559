import React, { useContext } from 'react';
import AggregateData from './components/AggregateData.component';
import ChildProjectsForm from './components/ChildProjectsForm.component';
import { ChildProjectsContext } from '../../context/ChildProjectsContext.context';
import RelatedProject from './components/RelatedProject.component';
import { Typography } from 'helpers/themeSafeMui.helper';

export default function ChildProjectsTab() {
  const {
    childProjectIds,
    parentProjectIds,
    removeChild,
    siblingIds
  } = useContext(ChildProjectsContext);

  if (parentProjectIds.length) {
    return (
      <div>
        <Typography gutterBottom variant="h5">
          Parent Project:
        </Typography>
        {parentProjectIds.map(projectId => (
          <RelatedProject key={projectId} projectId={projectId} />
        ))}
        {!!siblingIds.length && (
          <>
            <div style={{ padding: 10 }} />
            <Typography gutterBottom variant="h5">
              Sibling Projects:
            </Typography>
            {siblingIds.map(projectId => (
              <RelatedProject key={projectId} projectId={projectId} />
            ))}
          </>
        )}
      </div>
    );
  } else if (childProjectIds.length) {
    return (
      <>
        <Typography gutterBottom variant="h5">
          Aggregate Graphs:
        </Typography>
        <AggregateData />
        <div style={{ paddingTop: 24 }} />
        <Typography gutterBottom variant="h5">
          Child Projects:
        </Typography>
        {childProjectIds.map(projectId => (
          <RelatedProject
            key={projectId}
            projectId={projectId}
            onRemove={() => removeChild(projectId)}
          />
        ))}
        <div style={{ paddingTop: 24 }} />
        <ChildProjectsForm />
      </>
    );
  } else {
    return <ChildProjectsForm />;
  }
}
