export default function trunc(n: number, decimalPlaces = 0) {
  return Math.floor(n * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function truncRound(n: number, decimalPlaces = 0) {
  return Math.round(n * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function truncCeil(n: number, decimalPlaces = 0) {
  return Math.ceil(n * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}
