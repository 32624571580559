import { createSlice, createAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteActionItem,
  setActionItem,
  setActionItems,
  selectByIds,
  selectAll
} from './actionItems.duck';
import moment from 'moment';
import { useCallback } from 'react';

// External Actions
const deleteActionItemExtra = createAction('actionItems/deleteActionItem');
const setActionItemExtra = createAction('actionItems/setActionItem');
const setActionItemsExtra = createAction('actionItems/setActionItems');

// Slice
const userActionItemsSlice = createSlice({
  name: 'userActionItems',
  initialState: {},
  extraReducers: {
    [deleteActionItemExtra]: (state, action) => {
      const { id } = action.payload;
      Object.keys(state).forEach(userId => {
        state[userId] = state[userId].filter(itemId => itemId !== id);
      });
    },
    [setActionItemExtra]: (state, action) => {
      let { actionItem, allActionItems } = action.payload;
      assignToState(state, actionItem);
      allActionItems = { ...allActionItems, [actionItem.id]: actionItem };
      actionItem.assignedUserIds.forEach(userId =>
        sortUser(state, userId, allActionItems)
      );
    },
    [setActionItemsExtra]: (state, action) => {
      let { actionItems, allActionItems } = action.payload;
      actionItems.forEach(item => assignToState(state, item));
      allActionItems = actionItems.reduce(mergeAtId, { ...allActionItems });
      const seen = {};
      const affectedUsers = actionItems
        .map(item => item.assignedUserIds)
        .flat()
        .filter(userId => {
          if (seen[userId]) return false;
          return (seen[userId] = true);
        });
      affectedUsers.forEach(userId => sortUser(state, userId, allActionItems));
    }
  }
});

// Selectors
export const selectUsersActionItems = userId => appState =>
  appState.userActionItems[userId]
    ? selectByIds(appState.userActionItems[userId])(appState).filter(
        item => item
      )
    : [];

// Hooks
export const useUserActionItems = userId => {
  const dispatch = useDispatch();
  const userActionItems = useSelector(selectUsersActionItems(userId));
  const allActionItems = useSelector(selectAll);
  return {
    userActionItems,
    allActionItems,
    setActionItems: useCallback(
      actionItems => dispatch(setActionItems({ actionItems })),
      [dispatch]
    ),
    setActionItem: useCallback(
      actionItem => dispatch(setActionItem({ actionItem })),
      [dispatch]
    ),
    deleteActionItem: useCallback(id => dispatch(deleteActionItem({ id })), [
      dispatch
    ])
  };
};

// Reducer
export default userActionItemsSlice.reducer;

// Helpers
function assignToState(state, actionItem) {
  const { assignedUserIds, id } = actionItem;

  assignedUserIds.forEach(userId => {
    if (!state[userId]) state[userId] = [];
  });

  Object.keys(state).forEach(userId => {
    state[userId] = state[userId].filter(itemId => itemId !== id);
    if (assignedUserIds.includes(userId)) {
      state[userId] = [id, ...state[userId]];
    }
  });
}

function mergeAtId(acc, item) {
  acc[item.id] = item;
  return acc;
}

function sortUser(state, userId, allActionItems) {
  const sorted = sortbyDueDate(state[userId], allActionItems);
  state[userId] = sorted;
}

function sortbyDueDate(itemIds, items) {
  const unSorted = itemIds.map(id => items[id]).filter(i => i);

  return unSorted.sort(desc).map(item => item.id);
}

function desc(a, b) {
  return moment(a.dueDate).isBefore(b.dueDate) ? 1 : -1;
}
