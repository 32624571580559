import { Toast } from 'components';
import http from 'helpers/http.helper';
import { useCallback, useEffect, useState } from 'react';

export default function useWorkOrders(projectId) {
  const [workOrders, setWorkOrders] = useState([]);

  const refresh = useCallback(() => {
    if (!projectId) {
      setWorkOrders([]);
      return;
    }
    http()
      .get(`/projects/${projectId}/work-orders`)
      .then(setWorkOrders)
      .catch(Toast.showErr);
  }, [projectId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { workOrders, refresh };
}
