import React from 'react';
import { connect } from 'react-redux';
import { Toast, ExternalLink } from 'components';
import { Button, Grid } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { setUser } from 'ducks/user.duck';
import GovernmentDocument from '../components/GovernmentDocument.component';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

class GovernmentForms extends React.Component {
  state = {
    w4File: null,
    w4IdahoFile: null,
    i9File: null,
    loading: false,
    isTola: isUsingOneDrive()
  };

  componentDidMount() {
    http()
      .get('/users/me/employee-details')
      .then(employeeDetails => {
        this.setState({
          w4File: employeeDetails.w4File,
          w4IdahoFile: employeeDetails.w4IdahoFile,
          i9File: employeeDetails.i9File
        });
      })
      .catch(err => Toast.show(err.message));
  }

  submit = e => {
    if (e) e.preventDefault();

    if (this.state.isTola) {
      if (!this.state.i9File || !this.state.w4File)
        return Toast.show('All forms must be uploaded before continuing.');
    } else {
      if (!this.state.i9File || !this.state.w4File || !this.state.w4IdahoFile)
        return Toast.show('All forms must be uploaded before continuing.');
    }

    const files = {
      w4File: this.state.w4File.base64 ? this.state.w4File : undefined,
      i9File: this.state.i9File.base64 ? this.state.i9File : undefined
    };

    if (!this.state.isTola)
      files.w4IdahoFile = this.state.w4IdahoFile.base64
        ? this.state.w4IdahoFile
        : undefined;

    this.setState({ loading: true });
    http()
      .put('/users/me/employee-details', files)
      .then(() =>
        http().put('/users/me', {
          onBoardingCompleted: true
        })
      )
      .then(user => this.props.setUser(user))
      .catch(err => Toast.show(err.message))
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <GovernmentDocument
            loading={this.state.loading}
            value={this.state.w4File}
            type="W-4"
            onUpdate={w4File => this.setState({ w4File })}
          />
          <ExternalLink href="https://www.irs.gov/pub/irs-pdf/fw4.pdf">
            Print out the W-4 to complete manually.
          </ExternalLink>
        </Grid>
        {!this.state.isTola && (
          <Grid item xs={6}>
            <GovernmentDocument
              loading={this.state.loading}
              value={this.state.w4IdahoFile}
              type="W-4 Idaho"
              onUpdate={w4IdahoFile => this.setState({ w4IdahoFile })}
            />
            <ExternalLink href="https://tax.idaho.gov/forms/EFO00307_12-15-2020.pdf">
              Print out the Idaho W-4 to complete manually.
            </ExternalLink>
          </Grid>
        )}
        <Grid item xs={6}>
          <GovernmentDocument
            loading={this.state.loading}
            value={this.state.i9File}
            type="I-9"
            onUpdate={i9File => this.setState({ i9File })}
          />
          <ExternalLink href="https://www.uscis.gov/sites/default/files/document/forms/i-9-paper-version.pdf">
            Print out the I-9 to complete manually.
          </ExternalLink>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            onClick={this.submit}
            variant="contained"
            color="primary"
            disabled={this.state.loading}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default connect(state => ({ user: state.user }), { setUser })(
  GovernmentForms
);
