import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from 'helpers/themeSafeMui.helper';
import { useRouteMatch } from 'react-router';
import {
  DropdownButton,
  MaterialsTable,
  PermissionWrapper,
  SearchBar,
  Toast
} from 'components';
import http from 'helpers/http.helper';
import moment from 'moment';
import CreateProjectMaterialModal from './modals/CreateProjectMaterial.modal';
import SiPullWarningModal from './modals/SiPullWarning.modal';
import DeployMaterialModal from './modals/DeployMaterial.modal';
import ReceivedMaterialModal from './modals/ReceiveMaterial.modal';
import ReturnedMaterialModal from './modals/ReturnMaterial.modal';
import PickListModal from './modals/PickList.modal';

const UPDATE_BAR_HEIGHT = 60;
const hiddenColumns = MaterialsTable.presetHiddenColumns.ProjectMaterials;

export default function MaterialsTab() {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const [projectMaterials, setProjectMaterials] = useState([]);
  const [allMaterials, setAllMaterials] = useState([]);
  const [materialStatuses, setMaterialStatuses] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [siPullWarningOpen, setSiPullWarningOpen] = useState(false);

  const [pickListOpen, setPickListOpen] = useState(false);
  const [receiveOpen, setReceiveOpen] = useState(false);
  const [deployOpen, setDeployOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const [editingProjectMaterial, setEditingProjectMaterial] = useState(null);
  const [creatingMaterial, setCreatingMaterial] = useState(null);
  const dropdownRef = useRef(null);

  const closeMaterialModal = () => {
    setEditingProjectMaterial(null);
    setCreatingMaterial(null);
  };

  function tryPullSIMaterials() {
    if (siPullWarningOpen || !projectMaterials.length)
      return PullSIMaterials()
        .then(() => setSiPullWarningOpen(false))
        .catch(Toast.showErr);
    setSiPullWarningOpen(true);
  }

  function PullSIMaterials() {
    if (!projectId) return Promise.resolve();
    setLoading(true);
    return http()
      .put(`/projects/${projectId}/materials/si-material-pull`)
      .then(updated => {
        setProjectMaterials(updated);
        setLoading(false);
        Toast.show('Materials updated.');
      })
      .finally(() => setLoading(false));
  }

  const getMaterialsNoCatch = useCallback(() => {
    if (projectId) {
      return Promise.all([
        http().get(`/projects/${projectId}/materials`),
        http().get(`/projects/${projectId}/materials/statuses`)
      ]).then(([materials, statuses]) => {
        setProjectMaterials(materials);
        setMaterialStatuses(statuses);
      });
    }
    return Promise.resolve();
  }, [projectId]);

  useEffect(() => {
    getMaterialsNoCatch().catch(Toast.showErr);
  }, [getMaterialsNoCatch]);

  useEffect(() => {
    http()
      .get(`/materials`)
      .then(setAllMaterials)
      .catch(Toast.showErr);
  }, []);

  const scrubMaterial = material => ({
    materialId: material.materialId || '',
    description: material.description || '',
    quantity: material.quantity || '',
    manufacturer: material.manufacturer || '',
    model: material.model || '',
    partNumber: material.partNumber || '',
    unitCost: material.unitCost || '',
    unitLabor: material.unitLabor || '',
    specSheet: material.specSheet || '',
    OM: material.OM || ''
  });

  const onSubmit = material => {
    if (!material.materialId) return createAndAddMaterial(material);
    const found = projectMaterials.find(
      other => other.materialId === material.materialId
    );
    if (found) return updateMaterial(material);
    return addMaterial(material);
  };

  const createAndAddMaterial = newMaterial => {
    setLoading(true);
    http()
      .post(`/materials`, {
        manufacturer: newMaterial.manufacturer,
        model: newMaterial.model,
        partNumber: newMaterial.partNumber,
        description: newMaterial.description,
        unitCost: newMaterial.unitCost,
        unitLabor: newMaterial.unitLabor,
        specSheet: newMaterial.specSheet,
        OM: newMaterial.OM
      })
      .then(res => {
        res.materialId = res.id;
        res.quantity = newMaterial.quantity;
        addMaterial(res);
      })
      .catch(() => {
        Toast.showErr();
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const addMaterial = newMaterial => {
    setLoading(true);
    http()
      .post(`/projects/${projectId}/materials`, scrubMaterial(newMaterial))
      .then(getMaterialsNoCatch)
      .then(() => setCreatingMaterial(null))
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const updateMaterial = updatedMaterial => {
    setLoading(true);
    const pmId = updatedMaterial && updatedMaterial.id;
    http()
      .put(
        `/projects/${projectId}/materials/${pmId}`,
        scrubMaterial(updatedMaterial)
      )
      .then(closeMaterialModal)
      .then(getMaterialsNoCatch)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const removeMaterial = () => {
    setLoading(true);
    const pmId = editingProjectMaterial && editingProjectMaterial.id;
    http()
      .delete(`/projects/${projectId}/materials/${pmId}`)
      .then(getMaterialsNoCatch)
      .then(closeMaterialModal)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const onStatusUpdate = () => {
    return getMaterialsNoCatch().then(() => {
      setDeployOpen(false);
      setReceiveOpen(false);
      setReturnOpen(false);
    });
  };

  const openStatusModal = setOpenFn => () => {
    setOpenFn(true);
    if (dropdownRef.current) dropdownRef.current.close();
  };

  const lastUpdated = loading
    ? 'Loading...'
    : projectMaterials && projectMaterials[0] && projectMaterials[0].created
    ? moment(projectMaterials[0].created).format('L LT')
    : 'N / A';

  return (
    <div>
      <SiPullWarningModal
        open={siPullWarningOpen}
        onClose={() => setSiPullWarningOpen(false)}
        onConfirm={tryPullSIMaterials}
        loading={loading}
      />
      <PickListModal
        open={pickListOpen}
        onClose={() => setPickListOpen(false)}
        materialStatuses={materialStatuses}
        materials={projectMaterials}
        loading={loading}
        setLoading={setLoading}
      />
      <CreateProjectMaterialModal
        title={editingProjectMaterial ? 'Update Material' : 'Add Material'}
        materials={loading ? [] : allMaterials}
        projectMaterials={projectMaterials || []}
        open={!!editingProjectMaterial || !!creatingMaterial}
        onClose={closeMaterialModal}
        editingMaterial={editingProjectMaterial}
        setEditingMaterial={setEditingProjectMaterial}
        creatingMaterial={creatingMaterial}
        setCreatingMaterial={setCreatingMaterial}
        onDelete={removeMaterial}
        onSubmit={onSubmit}
        loading={loading}
      />
      <ReceivedMaterialModal
        open={receiveOpen}
        onClose={() => setReceiveOpen(false)}
        materialStatuses={materialStatuses}
        materials={projectMaterials}
        onReceive={onStatusUpdate}
        loading={loading}
        setLoading={setLoading}
      />
      <DeployMaterialModal
        open={deployOpen}
        onClose={() => setDeployOpen(false)}
        materialStatuses={materialStatuses}
        materials={projectMaterials}
        onDeploy={onStatusUpdate}
        loading={loading}
        setLoading={setLoading}
      />
      <ReturnedMaterialModal
        open={returnOpen}
        onClose={() => setReturnOpen(false)}
        materialStatuses={materialStatuses}
        materials={projectMaterials}
        onReturn={onStatusUpdate}
        loading={loading}
        setLoading={setLoading}
      />
      <div
        style={{
          height: UPDATE_BAR_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Typography noWrap>
            <Typography component="span" variant="overline">
              Last updated:{' '}
            </Typography>
            <Typography
              style={{ fontWeight: 600 }}
              component="span"
              variant="caption"
            >
              {lastUpdated}
            </Typography>
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <SearchBar
              disabled={loading}
              onSubmit={setSearch}
              onInput={setSearch}
            />
          </div>

          <PermissionWrapper permission="projects_tier_1.5">
            <div style={{ marginLeft: 16 }}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => setCreatingMaterial({})}
              >
                Add Material
              </Button>
            </div>
            <div style={{ marginLeft: 16 }}>
              <DropdownButton
                ref={dropdownRef}
                buttonText="Update Statuses"
                ButtonProps={{ color: 'primary' }}
                PopoverProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                }}
              >
                <List style={{ padding: 0 }}>
                  <ListItem button onClick={openStatusModal(setReceiveOpen)}>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'button' }}
                    >
                      Receive Materials
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={openStatusModal(setDeployOpen)}>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'button' }}
                    >
                      Deploy Materials
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={openStatusModal(setReturnOpen)}>
                    <ListItemText
                      primaryTypographyProps={{ variant: 'button' }}
                    >
                      Return Materials
                    </ListItemText>
                  </ListItem>
                </List>
              </DropdownButton>
            </div>
            <div style={{ marginLeft: 16 }}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => setPickListOpen(true)}
              >
                Pick List
              </Button>
            </div>
          </PermissionWrapper>

          <PermissionWrapper permission="projects_tier_2">
            <div style={{ marginLeft: 16 }}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={tryPullSIMaterials}
              >
                {loading ? <CircularProgress size={24} /> : 'Pull from SI'}
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div style={{ height: '80vh' }}>
        <MaterialsTable
          onSelect={setEditingProjectMaterial}
          statuses={materialStatuses}
          searchText={search}
          localStorageKey="ProjectMaterials"
          initialRowsPerPage={100}
          onStatusRemoved={onStatusUpdate}
          materials={loading ? [] : projectMaterials}
          removeColumns={hiddenColumns}
          modalResetId={projectId}
        />
      </div>
    </div>
  );
}
