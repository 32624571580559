import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import { CustomInput } from 'components';

const roleNameOptions = ['Collaborator', 'Power Collaborator', 'Admin'];

function CreatePlangridUser({ onSave, open, onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [roleName, setRoleName] = useState(roleNameOptions[0]);

  useEffect(() => {
    if (open) {
      setName('');
      setEmail('');
      setRoleName(roleNameOptions[0]);
    }
  }, [open]);

  const onSubmit = e => {
    e.preventDefault();
    onSave({ email, name, roleName }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={onSubmit}>
        <DialogTitle>Create Plangrid User Option</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              type="text"
              label="Name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              style={{ marginTop: 16 }}
              type="text"
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <CustomInput
              type="dropdown"
              label="Role"
              value={roleName}
              options={roleNameOptions.map(o => ({ value: o, label: o }))}
              onChange={setRoleName}
              required
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreatePlangridUser;
