import React, { useState } from 'react';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import { AiOutlineFile } from 'react-icons/ai';
import { useColors } from 'helpers/theme.helper';
import SmallIcon from './SmallIcon.component';
import { CopyText, CustomInput } from 'components';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import { FaSignature } from 'react-icons/fa';
import { showModal } from 'ducks/modal.duck';

const OneDriveFileCard = ({
  file,
  onSelect,
  onDelete,
  onNameChange,
  showIds,
  openFileModal,
  isTemplate,
  disabled
}) => {
  const colors = useColors();
  const thumbnail =
    file && file.thumbnails && file.thumbnails[0] && file.thumbnails[0].medium;
  const [editingName, setEditingName] = useState(null);
  const dispatch = useDispatch();

  return (
    <div
      style={{
        border: `1px solid ${colors.grey}`,
        borderRadius: 5,
        padding: 10
      }}
    >
      <div style={{ marginBottom: 10 }}>
        {editingName !== null ? (
          <CustomInput
            label="Name"
            value={editingName}
            onChange={setEditingName}
          />
        ) : (
          <Typography>{file.name}</Typography>
        )}
        {file && showIds && (
          <div>
            <CopyText text={file.id} type="File ID">
              ID:
              <Typography noWrap>{file.id}</Typography>
            </CopyText>
          </div>
        )}
      </div>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {editingName !== null ? (
          <>
            <SmallIcon
              disabled={disabled}
              Component={CancelIcon}
              onClick={() => setEditingName(null)}
            />
            <SmallIcon
              disabled={disabled}
              Component={SaveIcon}
              onClick={() =>
                onNameChange(editingName).then(() => setEditingName(null))
              }
            />
          </>
        ) : (
          <>
            {file && file.mimeType === 'application/pdf' && (
              <SmallIcon
                Component={FaSignature}
                style={{
                  fontSize: 18,
                  cursor: 'pointer'
                }}
                title="eSign"
                onClick={() =>
                  dispatch(
                    showModal({
                      type: 'VIEW_EDIT_ESIGN',
                      size: 'xl',
                      props: {
                        fileId: file.id,
                        isTemplate
                      }
                    })
                  )
                }
              />
            )}
            <SmallIcon
              disabled={disabled}
              Component={EditIcon}
              onClick={() => setEditingName(file.name)}
            />
            <SmallIcon
              disabled={disabled}
              Component={DeleteIcon}
              onClick={onDelete}
            />
            <SmallIcon
              disabled={disabled}
              Component={LinkIcon}
              onClick={() => window.open(file.webUrl, '_blank')}
            />
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {thumbnail ? (
          <img
            style={{
              height: 176,
              width: '100%',
              objectFit: 'contain',
              backgroundColor: colors.grey
            }}
            src={thumbnail.url}
            alt="file preview"
          />
        ) : (
          <AiOutlineFile size={176} />
        )}
      </div>
      {file && (
        <Typography
          variant="caption"
          component="div"
          style={{
            marginBottom: 10
          }}
          color="textSecondary"
        >
          Last modified: {moment(file.lastModifiedDateTime).fromNow()}
        </Typography>
      )}
      <Button
        onClick={openFileModal}
        fullWidth
        disabled={disabled}
        variant="contained"
        size="small"
      >
        Open
      </Button>
      {onSelect && (
        <Button
          style={{ marginTop: 10 }}
          onClick={() => onSelect(file)}
          fullWidth
          disabled={disabled}
          variant="contained"
          size="small"
        >
          Select This File
        </Button>
      )}
    </div>
  );
};

export default OneDriveFileCard;
