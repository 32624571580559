import React, { useState } from 'react';
import { Button, IconButton, Typography } from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';

const ConfirmDelete = ({
  onConfirm,
  iconButtonProps = {},
  button = null,
  disabled = false,
  children,
  confirming: confirmingProp = null,
  setConfirming: setConfirmingProp = null,
  message = 'Are you sure?',
  confirmButton = null,
  cancelConfirmButton = null
}) => {
  const [confirmingState, setConfirmingState] = useState(false);

  let confirming;
  let setConfirming;

  if (confirmingProp !== null && setConfirmingProp !== null) {
    confirming = confirmingProp;
    setConfirming = setConfirmingProp;
  } else {
    confirming = confirmingState;
    setConfirming = setConfirmingState;
  }

  return confirming ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!!message && (
        <Typography component="div" style={{ whiteSpace: 'nowrap' }}>
          {message}
        </Typography>
      )}
      <div
        onClick={() => {
          onConfirm();
          setConfirming(false);
        }}
      >
        {confirmButton || (
          <Button
            style={{ marginLeft: 5 }}
            variant="contained"
            size="small"
            color="primary"
          >
            Yes
          </Button>
        )}
      </div>
      <div onClick={() => setConfirming(false)}>
        {cancelConfirmButton || (
          <Button
            style={{ marginLeft: 5 }}
            variant="contained"
            size="small"
            color="default"
          >
            No
          </Button>
        )}
      </div>
    </div>
  ) : button || children ? (
    <div onClick={() => (disabled ? false : setConfirming(true))}>
      {button || children}
    </div>
  ) : (
    <IconButton
      {...iconButtonProps}
      disabled={disabled}
      onClick={() => setConfirming(true)}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export default ConfirmDelete;
