import React from 'react';
import { connect } from 'react-redux';
import { Toast } from 'components';
import {
  Button,
  Typography,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import moment from 'moment';

const policies = [
  {
    label: 'I have reviewed my health insurance marketplace coverage options.',
    link:
      'https://storage.googleapis.com/steps-files/assets/health-plan-notice.pdf',
    name: 'reviewedHealthPlanNotice',
    linkLabel: 'Open'
  },
  {
    label: 'I have read the STI Company Handbook.',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit?usp=sharing',
    name: 'readHandbook',
    linkLabel: 'Open'
  },
  {
    label: 'I have read the STI Safety & Health Manual.',
    link:
      'https://docs.google.com/document/d/1BTucEm4FWKo6BYhNPRxPkfSFDsWny-d8Ezir1fyz87A/',
    name: 'readSafetyHealthManual',
    linkLabel: 'Open'
  },
  {
    label:
      'I understand TSheets Time Card System and may be charged $20 if I fail to use it properly. ',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.xn8li3nb8p0r',
    name: 'understandsTSheets',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand smoking or associated uses are prohibited anytime during my working shift.',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.eiwqrxkd7jz7',
    name: 'understandsSmokingPolicy',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand I am financially responsible for lost or stolen tools as a result of my negligence.',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.nxzo9a75tzam',
    name: 'understandsLostToolPolicy',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand it is a great privilege to use a company vehicle and am responsible for its care. ',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.yl6ozldjyns6',
    name: 'understandsVehiclePolicy',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand I must acquire my own tools as outlined and will have them with me at all times.',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.komiesknuy0t',
    name: 'understandsToolPolicy',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand the company Drug & Alcohol Policy and may be subject to random screenings..',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.m7k9vbdnqs1h',
    name: 'understandsDrugPolicy',
    linkLabel: 'Review'
  },
  {
    label:
      'I understand the company Sexual Harassment Policy and will not encourage such behavior.',
    link:
      'https://docs.google.com/document/d/1oiaU2MJfcLs5Q01WkTIcPS24SfkJJhw95UJPfjUPvao/edit#heading=h.fld33ej82us5',
    name: 'understandsHarassmentPolicy',
    linkLabel: 'Review'
  }
];

class EmployeePolicy extends React.Component {
  state = {
    hasInsurance: '',
    providerName: '',
    wantsInsurance: '',
    reviewedHealthPlanNotice: false,
    readHandbook: false,
    readSafetyHealthManual: false,
    understandsTSheets: false,
    understandsSmokingPolicy: false,
    understandsLostToolPolicy: false,
    understandsVehiclePolicy: false,
    understandsToolPolicy: false,
    understandsDrugPolicy: false,
    understandsHarassmentPolicy: false,
    fullName: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChecked = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  componentDidMount() {
    http()
      .get('/users/me/employee-details')
      .then(employeeDetails => {
        const acknowledged = Boolean(
          employeeDetails.employeePolicyAcknowledged
        );
        this.setState({
          hasInsurance: acknowledged ? employeeDetails.hasInsurance : '',
          providerName: employeeDetails.providerName || '',
          wantsInsurance: acknowledged ? employeeDetails.wantsInsurance : '',
          reviewedHealthPlanNotice: acknowledged,
          readHandbook: acknowledged,
          readSafetyHealthManual: acknowledged,
          understandsTSheets: acknowledged,
          understandsSmokingPolicy: acknowledged,
          understandsLostToolPolicy: acknowledged,
          understandsVehiclePolicy: acknowledged,
          understandsToolPolicy: acknowledged,
          understandsDrugPolicy: acknowledged,
          understandsHarassmentPolicy: acknowledged,
          fullName: acknowledged ? this.props.user.name : ''
        });
      })
      .catch(err => Toast.show(err.message));
  }

  submit = e => {
    e.preventDefault();

    if (this.state.fullName.trim() !== this.props.user.name) {
      return Toast.show(
        'Please type your full name to indicate that you agree with the terms.'
      );
    }

    http()
      .put('/users/me/employee-details', {
        employeePolicyAcknowledged: new Date(),
        hasInsurance: this.state.hasInsurance,
        providerName: this.state.providerName,
        wantsInsurance: this.state.wantsInsurance
      })
      .then(employeeDetails => {
        this.props.history.push('/onboarding/emergency-contact');
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <form autoComplete="off" onSubmit={this.submit}>
        <div
          style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}
        >
          <Typography variant="body1" style={{ marginRight: 16 }}>
            Do you currently have health insurance coverage?
          </Typography>
          <FormControl required style={{ width: 100, marginRight: 24 }}>
            <InputLabel>Yes/No</InputLabel>
            <Select
              value={this.state.hasInsurance}
              onChange={this.handleChange}
              name="hasInsurance"
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          {this.state.hasInsurance && (
            <TextField
              name="providerName"
              label="Provider Name"
              style={{ width: 300 }}
              value={this.state.providerName}
              onChange={this.handleChange}
            />
          )}
        </div>

        <div
          style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 20 }}
        >
          <Typography variant="body1" style={{ marginRight: 16 }}>
            Would you be interested in System Tech&apos;s health insurance
            coverage after 90 days of full time employment?
          </Typography>
          <FormControl required style={{ width: 100 }}>
            <InputLabel>Yes/No</InputLabel>
            <Select
              value={this.state.wantsInsurance}
              onChange={this.handleChange}
              name="wantsInsurance"
              required
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </div>

        {policies.map(policy => (
          <Typography variant="body1" key={policy.name}>
            <Checkbox
              required
              checked={this.state[policy.name]}
              onChange={this.handleChecked}
              name={policy.name}
            />
            {policy.label}{' '}
            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              href={policy.link}
            >
              {policy.linkLabel}
            </Button>
          </Typography>
        ))}

        <Typography variant="body1" style={{ marginTop: 20 }}>
          I have read the System Tech Company Handbook and do understand all
          company policies and procedures as written. I understand it is my
          responsibility to seek out an understanding of all company policies. I
          agree to abide to everything contained herein at all times. I
          understand failure to comply with any of these policies or procedures
          may result in disciplinary actions, termination or legal
          repercussions. I understand that the initial ninety days of my
          employment with System Tech is probationary and that I may be
          terminated at any time during this probation without cause. I also
          understand that policies may change without notice and I will be
          required to abide by such changes. These changes become part of this
          agreement.
        </Typography>

        <TextField
          name="fullName"
          label="Full Name"
          required
          fullWidth
          margin="normal"
          value={this.state.fullName}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {moment().format('M/D/YYYY')}
              </InputAdornment>
            )
          }}
        />
        <Typography>({this.props.user.name})</Typography>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ float: 'right', marginTop: 30 }}
        >
          Next
        </Button>
        <div style={{ clear: 'both' }} />
      </form>
    );
  }
}

export default connect(state => ({ user: state.user }))(EmployeePolicy);
