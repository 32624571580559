import React, { useState, useContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Button, Grid } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { UserContext } from '../context/User.context';
import EditableValue from '../components/EditableValue.component';
import moment from 'moment';

const DrugTests = props => {
  const { userId, currentUser } = props;
  const [drugTests, setDrugTests] = useState([]);

  const getDrugTests = useCallback(() => {
    http()
      .get(`/users/${userId}/drug-tests`)
      .then(res => setDrugTests(res))
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    getDrugTests();
  }, [getDrugTests]);

  const addNewDrugTest = () => {
    http()
      .post(`/users/${userId}/drug-tests`)
      .then(() => getDrugTests())
      .catch(err => Toast.show(err.message));
  };

  return (
    <Grid container spacing={2}>
      {drugTests
        .slice(0)
        .reverse()
        .map(item => (
          <Grid item key={item.id} xs={12}>
            <DrugTest
              drugTest={item}
              userId={userId}
              refresh={getDrugTests}
              isAuthorized={currentUser.hasPermission('users_tier_2')}
            />
          </Grid>
        ))}

      {currentUser.hasPermission('users_tier_2') && (
        <Button variant="outlined" color="secondary" onClick={addNewDrugTest}>
          + Drug Test Request
        </Button>
      )}
    </Grid>
  );
};

function DrugTest(props) {
  const { drugTest, userId, refresh, isAuthorized } = props;
  const colors = useColors();
  const [editing, setEditing] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const [editedDrugTest, setEditedDrugTest] = useState({
    id: drugTest.id,
    drugTestLocation: drugTest.drugTestLocation,
    drugTestAcknowledged: drugTest.drugTestAcknowledged,
    passed: drugTest.passed,
    dateTaken: drugTest.dateTaken
  });

  return (
    <form
      style={{
        border: `1px solid ${colors.grey}`,
        borderRadius: 8,
        padding: 15,
        marginBottom: 15
      }}
      onSubmit={e => {
        e.preventDefault();

        http()
          .put(`/users/${userId}/drug-tests/${drugTest.id}`, {
            dateTaken: editedDrugTest.dateTaken,
            passed: editedDrugTest.passed
          })
          .then(() => {
            refresh();
            setEditing(false);
            setUser({ ...user, drugTestCompleted: editedDrugTest.passed });
          })
          .catch(err => Toast.show(err.message));
      }}
    >
      <EditableValue
        label="Sent To"
        value={`${drugTest.sentTo} - ${moment(drugTest.created).format(
          'MMM Do, h:mma'
        )}`}
        hidden={editing}
      />
      <EditableValue
        label="Location"
        value={editedDrugTest.drugTestLocation}
        hidden={editing}
      />
      <EditableValue
        label="Acknowledged"
        value={editedDrugTest.drugTestAcknowledged}
        type="date"
        hidden={editing}
      />
      <EditableValue
        label="Date Taken"
        value={editedDrugTest.dateTaken}
        type="date"
        editing={editing}
        onChange={dateTaken =>
          setEditedDrugTest({ ...editedDrugTest, dateTaken })
        }
      />
      <EditableValue
        label="Passed"
        value={editedDrugTest.passed}
        type="boolean"
        editing={editing}
        onChange={passed => setEditedDrugTest({ ...editedDrugTest, passed })}
      />

      {isAuthorized && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 16
          }}
        >
          {editing ? (
            <React.Fragment>
              <div>
                <Button
                  variant="outlined"
                  style={{ color: colors.error }}
                  onClick={() =>
                    http()
                      .delete(`/users/${userId}/drug-tests/${drugTest.id}`)
                      .then(() => refresh())
                      .catch(err => Toast.show(err.message))
                  }
                >
                  delete
                </Button>
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setEditedDrugTest(drugTest);
                    setEditing(false);
                  }}
                >
                  cancel
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  style={{ marginLeft: 8 }}
                >
                  save
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: 8 }}
              onClick={() => setEditing(true)}
            >
              edit
            </Button>
          )}
        </div>
      )}
    </form>
  );
}

export default connect(state => ({ currentUser: state.user }))(DrugTests);
