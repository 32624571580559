import React, { useEffect, useCallback, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import http from 'helpers/http.helper';
import {
  Button,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TextField,
  CircularProgress,
  Tooltip,
  ClickAwayListener
} from 'helpers/themeSafeMui.helper';
import { NoItemsResult, SearchDropdown, Toast, TableHeader } from 'components';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';

function WorkOrderLabors(props) {
  const { workOrder, canEdit } = props;
  const [labors, setLabors] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { setProjectDetails } = useContext(ProjectDetailsContext) || {};
  const [tsheetsWarningOpen, setTsheetsWarningOpen] = useState(false);

  const syncFromTsheets = async () => {
    setLoading(true);
    setTsheetsWarningOpen(true);
    try {
      const projectDetails = await http({ withLoader: false }).put(
        `/projects/${workOrder.projectId}/details/tsheets`
      );
      if (setProjectDetails) setProjectDetails(projectDetails);
      if (
        projectDetails.currentLaborHoursByUser &&
        projectDetails.currentLaborHoursByUser.length > 0
      ) {
        const reportWithUsers = projectDetails.currentLaborHoursByUser.map(
          reportItem => {
            const user = getUserFromTsheetsItem(reportItem);
            return { ...reportItem, user };
          }
        );
        setLabors(
          reportWithUsers
            .filter(item => item.user)
            .map(itemWithUser => ({
              hoursWorked: itemWithUser.hours,
              userId: itemWithUser.user.id,
              dateWorked: null
            }))
        );
      }
    } catch (err) {
      Toast.show(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getUserFromTsheetsItem = reportItem => {
    return (
      users.find(
        user =>
          reportItem.email === user.personalEmail ||
          reportItem.email === user.workEmail
      ) || null
    );
  };

  const getLabors = useCallback(() => {
    http()
      .get(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/labors`
      )
      .then(res => {
        setLabors(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  }, [workOrder]);

  const save = e => {
    e.preventDefault();
    setLoading(true);
    return http()
      .put(
        `/projects/${workOrder.projectId}/work-orders/${workOrder.id}/labors`,
        {
          items: labors.map(item => ({
            userId: item.userId,
            hoursWorked: item.hoursWorked,
            dateWorked: item.dateWorked
          }))
        }
      )
      .then(getLabors)
      .then(() => setEditing(false))
      .catch(Toast.showErr)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLabors();

    http()
      .get('/users')
      .then(res => {
        setUsers(res);
      })
      .catch(err => Toast.show(err.message));
  }, [getLabors]);

  return (
    <Grid container component="form" onSubmit={save} spacing={2}>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <div style={{ display: 'flex' }}>
          <Typography gutterBottom variant="h5">
            Labor
            {canEdit && !workOrder.techSignOff && (
              <IconButton
                disabled={loading}
                color="primary"
                onClick={() =>
                  props.showModal({
                    type: 'ADD_WORK_ORDER_LABORS',
                    props: {
                      workOrderId: workOrder.id,
                      projectId: workOrder.projectId,
                      refresh: getLabors
                    }
                  })
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </Typography>
        </div>
        {editing && (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              right: 24,
              top: 18
            }}
          >
            <ClickAwayListener onClickAway={() => setTsheetsWarningOpen(false)}>
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                onClose={() => setTsheetsWarningOpen(false)}
                open={tsheetsWarningOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Hours are only accurate after employees are clocked out"
              >
                <div>
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={syncFromTsheets}
                  >
                    {!!loading && (
                      <CircularProgress size={24} style={{ marginRight: 16 }} />
                    )}
                    Sync from Tsheets
                  </Button>
                </div>
              </Tooltip>
            </ClickAwayListener>
            <Button
              style={{ marginLeft: 8 }}
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={getLabors}
            >
              cancel
            </Button>
            <Button
              disabled={loading}
              style={{ marginLeft: 8 }}
              variant="contained"
              color="secondary"
              // onClick={save}
              type="submit"
            >
              save
            </Button>
          </div>
        )}

        {canEdit && !workOrder.techSignOff && !editing && (
          <Button
            style={{ position: 'absolute', right: 24, top: 18 }}
            variant="contained"
            color="secondary"
            onClick={() => setEditing(true)}
          >
            edit labor
          </Button>
        )}
      </Grid>

      <Grid item xs={12}>
        <LaborTable
          disabled={loading}
          labors={labors}
          setLabors={setLabors}
          editing={editing}
          users={users}
        />
      </Grid>
    </Grid>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(
  WorkOrderLabors
);

function LaborTable(props) {
  const { setLabors, disabled, labors, editing, users } = props;
  const titles = ['Employee Name', 'Date', '# of Hours'];
  const changeTableRow = (index, object) => {
    const tempArray = labors.slice();
    tempArray[index] = object;

    setLabors(tempArray);
  };

  const removeTableRow = idx => () => {
    const copy = [...labors];
    copy.splice(idx, 1);
    setLabors(copy);
  };

  return (
    <Paper>
      <Table>
        <TableHeader titles={titles} />
        <TableBody>
          {labors.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <NoItemsResult type="Labor" />
              </TableCell>
            </TableRow>
          )}
          {labors.map((row, index) => (
            <EditableTableRow
              disabled={disabled}
              key={row.id}
              changeTableRow={object => changeTableRow(index, object)}
              removeTableRow={removeTableRow(index)}
              editing={editing}
              row={row}
              users={users}
            />
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <strong>Total:</strong>
            </TableCell>
            <TableCell>
              <strong>
                {labors
                  .reduce((sum, row) => sum + Number(row.hoursWorked), 0)
                  .toFixed(2)}{' '}
                hours
              </strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

function EditableTableRow(props) {
  const {
    row,
    editing,
    disabled,
    users,
    changeTableRow,
    removeTableRow
  } = props;

  if (editing) {
    return (
      <TableRow>
        <TableCell>
          <SearchDropdown
            label="Employee"
            disabled={disabled}
            itemToString={item => item.name}
            items={users}
            onChange={user =>
              changeTableRow({ ...row, userId: user.id, name: user.name })
            }
            value={users.find(user => user.id === row.userId)}
            required
            fullWidth
            margin="normal"
          />
        </TableCell>
        <TableCell style={{ paddingBottom: 11 }}>
          <DatePicker
            style={{ marginBottom: 0 }}
            disabled={disabled}
            format="MM/DD/YYYY"
            autoOk
            name="dateWorked"
            label="Date Worked"
            required
            fullWidth
            margin="dense"
            value={row.dateWorked}
            onChange={dateWorked =>
              changeTableRow({ ...row, dateWorked: dateWorked })
            }
          />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            disabled={disabled}
            label="Hours Worked"
            value={row.hoursWorked}
            required
            onChange={e =>
              changeTableRow({ ...row, hoursWorked: e.target.value })
            }
            type="number"
            inputProps={{ step: '0.01' }}
            margin="normal"
          />
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton disabled={disabled} onClick={removeTableRow}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        <TableCell>{row.name}</TableCell>
        <TableCell>{moment(row.dateWorked).format('LL')}</TableCell>
        <TableCell>{row.hoursWorked}</TableCell>
      </TableRow>
    );
  }
}
