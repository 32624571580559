import React, { useContext, useEffect, useState } from 'react';
import { ListItemText } from 'helpers/themeSafeMui.helper';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { ProjectsContext } from '../context/Projects.context';
import TaskSelectorModal from '../modals/TaskSelector.modal';
import { ShiftModalContext } from '../context/ShiftModal.context';
import useCreateShiftsFromTask from '../hooks/useCreateShiftsFromTask.hook';
import { VirtualizedSearch } from 'components';

export default function ProjectShiftSearch() {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const { tasksInWindow } = useContext(ShiftScheduleContext);
  const { openWithProps } = useContext(ShiftModalContext);
  const { projects } = useContext(ProjectsContext);
  const [projectId, setProjectId] = useState(null);
  const createShifsFromTask = useCreateShiftsFromTask();

  const openEditWeek = projectId => {
    openWithProps({ projectId }, 'week');
  };

  useEffect(() => {
    const set = {};
    const options = [];
    tasksInWindow.forEach(task => {
      set[task.projectId] = true;
    });
    projects.forEach(project =>
      options.push(
        set[project.id]
          ? { ...project, hasTask: true }
          : { ...project, hasTask: false }
      )
    );
    setOptions(options);
  }, [projects, tasksInWindow]);

  return (
    <>
      <TaskSelectorModal
        onSelect={task => {
          createShifsFromTask(task).then(() => {
            openEditWeek(task.projectId);
          });
          setProjectId(null);
        }}
        projectId={projectId}
        open={!!projectId}
        onClose={() => setProjectId(null)}
      />
      <VirtualizedSearch
        allItems={options}
        label="Search Projects"
        getItemText={project => `${project.projectNumber} ${project.name}`}
        TextFieldProps={{ style: { width: 600, paddingRight: 10 } }}
        onChange={project => {
          if (project) {
            if (project.hasTask) {
              setProjectId(project.id);
            } else {
              openEditWeek(project.id);
            }
          }
          setInputValue('');
        }}
        inputValue={inputValue}
        setInputValue={setInputValue}
        VirtualizedListProps={{ itemHeight: 56 }}
      >
        {({ item, style }) => (
          <ListItemText
            style={{ margin: 0, ...style }}
            primaryTypographyProps={{ noWrap: true }}
            primary={`${item.projectNumber} ${item.name}`}
            secondary={item.hasTask ? 'Has tasks' : undefined}
          />
        )}
      </VirtualizedSearch>
    </>
  );
}
