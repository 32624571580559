import { useContext, useMemo } from 'react';
import {
  ProjectContext,
  ProjectDetailsContext
} from 'routes/Projects/context/Project.context';
import { v4 as uuid } from 'uuid';

const complete = {
  completed: true,
  optional: false,
  color: 'green'
};
const inProgress = {
  completed: false,
  optional: false,
  color: 'yellow'
};
const incomplete = {
  completed: false,
  optional: false,
  color: 'red'
};
const optional = {
  completed: false,
  optional: true,
  color: 'gray'
};

export default function useAutomatedChecklist() {
  const { project } = useContext(ProjectContext);
  const { projectDetails } = useContext(ProjectDetailsContext);

  const automatedItems = useMemo(
    () => [
      projectWonWorkFlow(project, projectDetails),
      projectManagerAssigned(project),
      planGridProjectCreated(project),
      scopeOfWorkEntered(project),
      leadTechAssigned(project)
    ],
    [project, projectDetails]
  );

  return automatedItems;
}

function projectWonWorkFlow(project, details) {
  const workflow = details?.workflows?.find?.(
    workflow => workflow.id === 'project_won'
  );

  const checklistItem = {
    id: uuid(),
    projectId: project.id,
    title: 'Project Won Work Flow',
    order: -5
  };

  if (workflow) return { ...checklistItem, ...inProgress };
  if (project.bidStatus === 'won') return { ...checklistItem, ...complete };
  return { ...checklistItem, ...incomplete };
}

function projectManagerAssigned(project) {
  const checklistItem = {
    id: uuid(),
    projectId: project.id,
    title: 'Project Manager Assigned',
    order: -4
  };

  if (project.projectManagerName) return { ...checklistItem, ...complete };
  return { ...checklistItem, ...incomplete };
}

function planGridProjectCreated(project) {
  const checklistItem = {
    id: uuid(),
    projectId: project.id,
    title: 'PlanGrid Project Created',
    order: -3
  };

  if (project.plangridProjectId) return { ...checklistItem, ...complete };
  return { ...checklistItem, ...optional };
}

function scopeOfWorkEntered(project) {
  const checklistItem = {
    id: uuid(),
    projectId: project.id,
    title: 'Scope of Work Entered',
    order: -2
  };

  if (project.scopeOfWork) return { ...checklistItem, ...complete };
  return { ...checklistItem, ...incomplete };
}

function leadTechAssigned(project) {
  const checklistItem = {
    id: uuid(),
    projectId: project.id,
    title: 'Lead Tech Assigned',
    order: -1
  };

  if (project.leadTechnicianName) return { ...checklistItem, ...complete };
  return { ...checklistItem, ...incomplete };
}

// Project Won Work Flow
// Project Manager Assigned
// PlanGrid Project Created
// Scope of Work Entered
// Lead Tech Assigned
