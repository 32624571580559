import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'helpers/themeSafeMui.helper';
import { ExternalLink } from 'components';

const styles = {
  icon: { verticalAlign: 'middle', marginRight: 2 }
};

function HeaderItem(props) {
  const { Icon, label, externalUrl, internalUrl } = props;

  if (internalUrl) {
    return (
      <Typography variant="body2" color="textSecondary">
        <Icon fontSize="inherit" style={styles.icon} />
        <Link color="inherit" component={RouterLink} to={internalUrl}>
          {label || ''}
        </Link>
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color="textSecondary">
        <Icon fontSize="inherit" style={styles.icon} />
        {externalUrl ? (
          <ExternalLink color="inherit" href={externalUrl}>
            {label || ''}
          </ExternalLink>
        ) : (
          label
        )}
      </Typography>
    );
  }
}

export default HeaderItem;
