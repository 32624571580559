import React from 'react';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import CompanyContextProvider from '../context/Company.context';
import { DashboardWrapper, AppBarRouter } from 'components';
import { Paper } from 'helpers/themeSafeMui.helper';
import CompanyHeader from '../components/CompanyHeader.component';
import HomeTab from '../tabs/Home.tab';
import ProjectsTab from '../tabs/Projects.tab';
import ClosedProjectsTab from '../tabs/ClosedProjects.tab';
import PeopleTab from '../tabs/People.tab';
import DriveTab from '../tabs/Drive.tab';
import IsSupplierWarning from '../components/IsSupplierWarning.component';

function Company(props) {
  const companyId = props.match.params.id;

  const routes = [
    {
      name: 'Home',
      path: `/companies/${companyId}`,
      Component: HomeTab
    },
    {
      name: 'People',
      path: `/companies/${companyId}/people`,
      Component: PeopleTab
    },
    {
      name: 'Open Projects',
      path: `/companies/${companyId}/projects`,
      Component: ProjectsTab
    },
    {
      name: 'Closed Projects',
      path: `/companies/${companyId}/closed-projects`,
      Component: ClosedProjectsTab
    },
    {
      name: 'Drive',
      path: `/companies/${companyId}/drive`,
      Component: DriveTab
    }
  ];

  return (
    <CompanyContextProvider>
      <DashboardWrapper>
        <Paper>
          <IsSupplierWarning />
          <CompanyHeader />
          <AppBarRouter routes={routes} routeProps={{ companyId: companyId }} />
        </Paper>
      </DashboardWrapper>
    </CompanyContextProvider>
  );
}

export default connect(null, { showModal })(Company);
