import React from 'react';
import { Grid, Typography, Divider } from 'helpers/themeSafeMui.helper';
import { HeaderItem } from 'components';
import phoneHelper from 'helpers/phone.helper';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CopyText from 'components/CopyText/CopyText.component';

const PersonDisplay = props => {
  const { image, name, type, phoneNumber, email, Icon } = props;

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 8,
              width: '100%'
            }}
          >
            <div style={{ display: 'flex' }}>
              {image && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="avatar"
                    src={image}
                    style={{
                      display: 'block',
                      marginRight: 12,
                      objectFit: 'cover',
                      width: 100,
                      height: 100
                    }}
                  />
                </div>
              )}
              <div>
                <Typography variant="h6" color="textPrimary">
                  {name}
                </Typography>
                <Typography color="textPrimary">{type}</Typography>
                {phoneNumber && (
                  <HeaderItem
                    Icon={PhoneIcon}
                    externalUrl={`tel:${phoneNumber}`}
                    label={phoneHelper(phoneNumber)}
                  />
                )}

                {email && (
                  <CopyText text={email} type="Email">
                    <HeaderItem
                      Icon={EmailIcon}
                      externalUrl={`mailto:${email}`}
                      label={email}
                    />
                  </CopyText>
                )}
              </div>
            </div>
            {Icon}
          </div>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};

export default PersonDisplay;
