import React from 'react';
import { Typography, Link } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import http from 'helpers/http.helper';
import { Link as RouterLink } from 'react-router-dom';
import { Toast } from 'components';

const OnboardingHeader = props => {
  const { user } = props;
  const colors = useColors();

  const styles = {
    flex: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    link: { color: 'white', cursor: 'pointer', textDecoration: 'underline' }
  };

  const resendInvite = () => {
    http()
      .post(`/users/auth/resend-invite/${user.id}`)
      .then(() => Toast.show('Resent invite to the user.'))
      .catch(err => Toast.show(err.message));
  };

  const _renderStatus = () => {
    if (!user.jobApproved) {
      return (
        <Typography style={styles.flex}>
          Pending Job Approval |&nbsp;
          <Link
            component={RouterLink}
            to={`/users/${user.id}/job-history`}
            style={styles.link}
          >
            Approve
          </Link>
        </Typography>
      );
    } else if (!user.drugTestCompleted) {
      return (
        <Typography style={styles.flex}>
          Drug Screening Pending |&nbsp;
          <Link style={styles.link} onClick={resendInvite}>
            Resend Email |&nbsp;
          </Link>
          <Link
            style={styles.link}
            component={RouterLink}
            to={`/users/${user.id}/drug-screenings`}
          >
            Enter Result
          </Link>
        </Typography>
      );
    } else if (!user.onBoardingCompleted) {
      return (
        <Typography style={styles.flex}>
          Drug Screening Completed |&nbsp;
          <Link style={styles.link} onClick={resendInvite}>
            Resend Email
          </Link>
        </Typography>
      );
    } else {
      return null;
    }
  };

  if (!user.employmentStatus || user.employmentStatus === 'Inactive') {
    return null;
  }

  return (
    <div
      style={{
        background: colors.error,
        color: 'white'
      }}
    >
      {_renderStatus()}
    </div>
  );
};

export default OnboardingHeader;
