import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { CustomInput, Toast, UserSearch, RichTextEditor } from 'components';

const emptyRfi = {
  locked: false,
  title: '',
  questionText: '',
  questionHtml: '',
  answer: '',
  users: [],
  dueDate: ''
};

const RfiModal = ({
  formTitle,
  users,
  rfi = emptyRfi,
  open,
  onSubmit,
  onClose
}) => {
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(rfi.locked);
  const [title, setTitle] = useState(rfi.title);
  const [questionText, setQuestionText] = useState(rfi.questionText);
  const [questionHtml, setQuestionHtml] = useState(rfi.questionHtml);
  const [answer, setAnswer] = useState(rfi.answer);
  const [assignedUserIds, setAssignedToUids] = useState(
    rfi.users.map(user => user.id)
  );
  const [dueDate, setDueDate] = useState(() =>
    rfi.dueDate ? moment(rfi.dueDate).toDate() : null
  );
  const editorRef = useRef(null);

  const onFormSubmit = async e => {
    e.preventDefault();
    if (rfi.locked && locked) {
      return Toast.show("Can't update locked RFI");
    }
    setLoading(true);
    await onSubmit({
      locked,
      title,
      questionText,
      questionHtml,
      answer,
      assignedUserIds,
      dueDate
    });
    setLoading(false);
  };

  const reset = useCallback(() => {
    setLocked(rfi.locked);
    setTitle(rfi.title);
    setQuestionText(rfi.questionText);
    setQuestionHtml(rfi.questionHtml);
    setAnswer(rfi.answer || '');
    setAssignedToUids(rfi.users.map(user => user.id));
    setDueDate(rfi.dueDate ? moment(rfi.dueDate).toDate() : null);
    setLoading(false);
    editorRef.current && editorRef.current.loadHtml(rfi.questionHtml);
  }, [rfi]);

  const closeAndClear = () => {
    reset();
    onClose();
  };

  useEffect(() => reset(), [reset]);

  return (
    <Dialog maxWidth="lg" onClose={closeAndClear} open={open}>
      <DialogTitle>{formTitle}</DialogTitle>
      <form onSubmit={onFormSubmit}>
        <div style={{ padding: 16 }}>
          <TextField
            disabled={loading}
            fullWidth
            style={{ marginBottom: 10 }}
            variant="outlined"
            label="Title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            inputProps={{ style: { fontSize: '1.5em' } }}
          />
          <RichTextEditor
            ref={editorRef}
            placeholder={'Question'}
            initialHtml={rfi.questionHtml}
            onChange={({ html, text }) => {
              setQuestionHtml(html);
              setQuestionText(text);
            }}
          />
          <TextField
            disabled={loading}
            fullWidth
            style={{ marginBottom: 10 }}
            label="Answer"
            value={answer}
            onChange={e => setAnswer(e.target.value)}
          />
          <CustomInput
            disabled={loading}
            label="Due On"
            type="date"
            value={dueDate}
            onChange={val => setDueDate(val)}
          />
          <CustomInput
            disabled={loading}
            label="Locked"
            type="boolean"
            value={locked}
            onChange={val => setLocked(val)}
          />
          <List>
            <ListItem>
              <ListItemText>
                Assigned Users
                <UserSearch
                  onChange={user => {
                    if (user)
                      setAssignedToUids(ids =>
                        ids.includes(user.id) ? ids : [user.id, ...ids]
                      );
                  }}
                />
              </ListItemText>
            </ListItem>
            {assignedUserIds.map(userId => {
              const labelId = `checkbox-list-label-${userId}`;
              const user = users.find(user => user.id === userId);
              if (!user) return null;
              return (
                <ListItem
                  key={userId}
                  dense
                  button
                  onClick={() =>
                    setAssignedToUids(ids => ids.filter(id => id !== userId))
                  }
                  disabled={loading}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={true}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={user.name}
                    secondary={user.email}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
        <DialogActions>
          <Button
            disabled={loading}
            color="primary"
            type="submit"
            variant="contained"
          >
            {loading ? (
              <>
                <CircularProgress size={20} style={{ marginRight: 10 }} />{' '}
                Loading...
              </>
            ) : (
              'Save'
            )}
          </Button>
          <Button
            disabled={loading}
            onClick={closeAndClear}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RfiModal;
