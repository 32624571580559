import React from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { useSelector } from 'react-redux';

const AuthPopup = props => {
  const user = useSelector(state => state.user);
  const open = async () => {
    if (user && !user.isInternal) return;
    const url = await http()
      .get('/google/oauth/url', {
        params: {
          forceConsent: props.forceConsent,
          scopes: props.scopes
        }
      })
      .catch(err => Toast.show(err.message));

    const popup = window.open(
      url,
      null,
      'width=500,height=500,top=100,left=100'
    );

    const interval = setInterval(() => {
      if (popup) {
        try {
          const params = new URL(popup.location).searchParams;
          const code = params.get('code');
          if (!code) return;
          props.onCode(code);
          popup.close();
        } catch (err) {
          // ignore that
        }
      }
    }, 200);

    popup.onbeforeunload = () => {
      clearInterval(interval);
    };
  };

  if (user && !user.isInternal) return <></>;

  return <span onClick={open}>{props.children}</span>;
};

export default AuthPopup;
