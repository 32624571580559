import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AllPerDiem from './routes/AllPerDiem.route';
import MyPerDiem from './routes/MyPerDiem.route';
import { PermissionWrapper } from 'components';
import UsersContextProvider from './context/users.context';
import ProjectsContextProvider from './context/projects.context';

const PerDiemRoute = () => {
  return (
    <Switch>
      <Route path="/per-diems/all" exact>
        <PermissionWrapper redirect permission="per_diem_tier_2">
          <ProjectsContextProvider>
            <UsersContextProvider>
              <AllPerDiem />
            </UsersContextProvider>
          </ProjectsContextProvider>
        </PermissionWrapper>
      </Route>
      <Route path="/per-diems/me">
        <PermissionWrapper redirect permission="per_diem_tier_1">
          <ProjectsContextProvider>
            <UsersContextProvider>
              <MyPerDiem />
            </UsersContextProvider>
          </ProjectsContextProvider>
        </PermissionWrapper>
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};

export default PerDiemRoute;
