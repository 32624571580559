import React, { useState, useEffect } from 'react';
import { Toast, SearchDropdown, AddressPredict, CustomInput } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Grid,
  Link
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

function CreateProject(props) {
  const {
    companyId,
    currentUser,
    personId,
    onCreate,
    onClose,
    projectStatuses,
    adminSettings,
    noRedirect,
    parentId
  } = props;
  const [name, setName] = useState('');
  const [accountManagers, setAccountManagers] = useState([]);
  const [accountManager, setAccountManager] = useState('');
  const [company, setCompany] = useState(null);
  const [status, setStatus] = useState(getDefaultStatus(projectStatuses));
  const [bidDueDate, setBidDueDate] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [people, setPeople] = useState([]);
  const [customerInitialContact, setCustomerInitialContact] = useState(null);
  const [address, setAddress] = useState('');
  const [texasBilling, setTexasBilling] = useState(isUsingOneDrive());
  const [sudoCompany, setSudoCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [suffix, setSuffix] = useState(null);

  useEffect(() => {
    http()
      .get('/account-managers')
      .then(res => {
        setAccountManagers(res);

        const userAm = res.find(am => am.userId === currentUser.id);
        setAccountManager(userAm || null);
      })
      .catch(err => Toast.show(err.message));
  }, [currentUser.id]);

  useEffect(() => {
    http()
      .get('/companies')
      .then(res => {
        setCompanies(res);
        setCompany(res.find(item => item.id === companyId) || null);
        setSudoCompany(
          res.find(item => item.id === adminSettings.sudoCompanyId || null)
        );
      })
      .catch(err => Toast.show(err.message));
  }, [companyId, adminSettings.sudoCompanyId]);

  useEffect(() => {
    if (company) {
      http()
        .get('/people', { params: { companyId: company.id } })
        .then(res => {
          const active = res.filter(person => !person.inactive);
          setPeople(active);

          const contact = active.find(person => person.id === personId);
          setCustomerInitialContact(contact || active[0] || null);
        })
        .catch(err => Toast.show(err.message));
    }
  }, [company, personId]);

  const save = e => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    http()
      .post('/projects', {
        name: name,
        companyId: company.id,
        statusId: status.id,
        bidDueDate: bidDueDate,
        address: address,
        accountManagerId:
          accountManager === 'LEAD' ? undefined : accountManager.id,
        customerInitialContactId: customerInitialContact.id,
        texasBilling,
        parentId,
        suffix
      })
      .then(res => {
        onCreate(res);
        if (!noRedirect) props.history.push(`/projects/${res.id}`);
        onClose();
      })
      .catch(err => {
        Toast.show(err.message);
        setLoading(false);
      });
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>
        Create New Project
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Project Name"
              autoFocus
              required
              fullWidth
              margin="dense"
              onChange={e => setName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Company"
              itemToString={item => item.name}
              items={companies}
              onChange={company => setCompany(company)}
              value={company}
              required
            />

            <Link
              onClick={() => setCompany(sudoCompany)}
              style={{
                cursor: 'pointer',
                textAlign: 'right',
                display: 'block'
              }}
            >
              Multi-bid Project
            </Link>
          </Grid>

          {company && (
            <Grid item xs={12}>
              <SearchDropdown
                fullWidth
                margin="normal"
                label="Initial Point of Contact"
                itemToString={item => item.name}
                items={people}
                onChange={contact => setCustomerInitialContact(contact)}
                value={customerInitialContact}
                required
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <AddressPredict
              required
              label="Job Site Address"
              onChange={address => setAddress(address)}
              value={address}
            />
            {company && (
              <Link
                onClick={() => {
                  setAddress(company.address);
                }}
                style={{
                  marginTop: 8,
                  cursor: 'pointer',
                  textAlign: 'right',
                  display: 'block'
                }}
              >
                Copy From Billing Address
              </Link>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Project Status</InputLabel>
              <Select value={status} onChange={e => setStatus(e.target.value)}>
                {projectStatuses.map(status => (
                  <MenuItem key={status.id} value={status}>
                    {status.status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Account Manager</InputLabel>
              <Select
                value={accountManager}
                onChange={e => setAccountManager(e.target.value)}
              >
                <MenuItem value="LEAD">LEAD</MenuItem>
                {accountManagers.map(manager => (
                  <MenuItem key={manager.id} value={manager}>
                    {`${manager.managerNumber} - ${manager.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker
              format="MM/DD/YYYY"
              autoOk
              label="Bid Due Date"
              fullWidth
              margin="dense"
              value={bidDueDate}
              onChange={bidDueDate => setBidDueDate(bidDueDate)}
            />
          </Grid>

          {parentId ? (
            <Grid item xs={6}>
              <FormControl required fullWidth margin="dense">
                <InputLabel>Suffix</InputLabel>
                <Select
                  value={suffix}
                  onChange={e => setSuffix(e.target.value)}
                >
                  <MenuItem key="FA" value="FA">
                    FA
                  </MenuItem>
                  <MenuItem key="AV" value="AV">
                    AV
                  </MenuItem>
                  <MenuItem key="ELE" value="ELE">
                    ELE
                  </MenuItem>
                  <MenuItem key="CAB" value="CAB">
                    CAB
                  </MenuItem>
                  <MenuItem key="SEC" value="SEC">
                    SEC
                  </MenuItem>
                  <MenuItem key="OLAN" value="OLAN">
                    OLAN
                  </MenuItem>
                  <MenuItem key="WIFI" value="WIFI">
                    WIFI
                  </MenuItem>
                  <MenuItem key="NET" value="NET">
                    NET
                  </MenuItem>
                  <MenuItem key="IT" value="IT">
                    IT
                  </MenuItem>
                  <MenuItem key="SUP" value="SUP">
                    SUP
                  </MenuItem>
                  <MenuItem key="DES" value="DES">
                    DES
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {isUsingOneDrive() ? (
            <Grid item xs={6}>
              <CustomInput
                type="boolean"
                label="Invoiced by System Tech Texas, Inc."
                value={texasBilling}
                onChange={setTexasBilling}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Please wait...' : 'Create'}
        </Button>
      </DialogActions>
    </form>
  );
}

function getDefaultStatus(statuses) {
  return statuses[1] ? statuses[1] : statuses[0];
}

export default connect(state => ({
  projectStatuses: state.projectStatuses,
  currentUser: state.user,
  adminSettings: state.adminSettings
}))(withRouter(CreateProject));
