import React from 'react';
import { useColors } from 'helpers/theme.helper';
import moment from 'moment';
import { Paper, Typography, IconButton } from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';

const RecordList = ({
  messageRecords = [],
  importRecords = [],
  failedUpdates = [],
  removeFailedUpdate
}) => {
  const colors = useColors();
  const getImportDate = dateString => {
    const m = moment(dateString)
      .utcOffset(0, true)
      .local();
    if (!m.isValid()) return 'N/A';
    return m.format('LLL');
  };
  const importType = importRecord => {
    switch (importRecord.type) {
      case 'import':
        return 'Import';
      case 'co_import':
        return 'CO Import';
      default:
        return 'Unknown';
    }
  };
  return messageRecords
    .map(messageRecord => {
      let color = colors.primary;
      let status = messageRecord.Status;
      if (status === 'Pending') color = colors.yellow;
      if (status === 'Imported') {
        status = 'Sent';
        color = colors.success;
      }
      if (status === 'Failed') color = colors.error;
      let type = 'Other';
      if (messageRecord.Type === 'Project') type = 'New SI Project';
      if (messageRecord.Type === 'NewProjectChangeOrderInfo')
        type = 'New Change Order';
      if (messageRecord.Type === 'UpdateProject') type = 'Status Update';

      return (
        <Paper key={messageRecord.Id} style={{ marginTop: 10, padding: 10 }}>
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            Type: {type}
          </Typography>
          <Typography variant="body2">
            Sent: {moment(messageRecord.created).format('LLL')}
          </Typography>
          <Typography variant="body2">
            Received: {getImportDate(messageRecord.ImportedOn)}
          </Typography>
          <Typography variant="body2">
            Status: <span style={{ color }}>{status}</span>
          </Typography>
        </Paper>
      );
    })
    .concat(
      importRecords.map(importRecord => (
        <Paper key={importRecord.id} style={{ marginTop: 10, padding: 10 }}>
          <Typography variant="body2">
            Type:{' '}
            <span style={{ color: colors.success }}>
              {importType(importRecord)}
            </span>
          </Typography>
          <Typography variant="body2">
            Received: {moment(importRecord.created).format('LLL')}
          </Typography>
        </Paper>
      ))
    )
    .concat(
      failedUpdates.map(dtoolsProject => (
        <Paper key={dtoolsProject.Id} style={{ marginTop: 10, padding: 10 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '0 1 100%' }}>
              <Typography variant="body2">
                Type: <span style={{ color: colors.error }}>Failed Import</span>
              </Typography>
              <Typography variant="body2">
                Project: {dtoolsProject.Number} - {dtoolsProject.Name}
              </Typography>
              <Typography variant="caption">
                No project found with number "{dtoolsProject.Number}"
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <IconButton onClick={removeFailedUpdate(dtoolsProject.Id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Paper>
      ))
    );
};

export default RecordList;
