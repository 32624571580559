import React, { useEffect, useState, useCallback } from 'react';
import {
  DashboardWrapper,
  ExternalLink,
  SelectionSortingTable,
  PhotoModal,
  CopyText,
  ColumnsToCSV,
  Toast
} from 'components';
import { Typography, IconButton, TableCell } from 'helpers/themeSafeMui.helper';
import NewUserIcon from '@material-ui/icons/AddCircleOutline';
import { useDispatch } from 'react-redux';
import InviteUserModal from '../modals/InviteUser.modal';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import phoneHelper from 'helpers/phone.helper';
import { getAreas } from 'ducks/areas.duck';
import TrainingUserFilter from '../components/TrainingUserFilter.component';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import ColumnSelector from '../components/ColumnSelector.component';
import moment from 'moment';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import SyncUserTsheets from '../components/SyncUserTsheets.component';
import UserSkillsFilter from '../components/UserSkillsFilter.component';
import http from 'helpers/http.helper';
import mapReducer from 'helpers/mapReducer.helper';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10
  },
  tableWrapper: {
    overflowX: 'auto'
  }
};

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [photoModalUrl, setPhotoModalUrl] = useState(null);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [viewingInactive, setViewingInactive] = useState(false);

  const currentUser = useCurrentUser();
  const [userDetailsMap, setUserDetailsMap] = useState({});

  const fetchUsers = useCallback(
    (skillId, skillLevel) => {
      const params = { includeImage: true, includeInactive: true };
      if (skillId && skillLevel) {
        params.skillId = skillId;
        params.skillLevel = skillLevel;
      }
      http()
        .get('/users', { params })
        .then(res => setUsers(res))
        .catch(Toast.showErr);
      if (currentUser.hasPermission('users_tier_2'))
        http()
          .get('/users/employee-details')
          .then(details => {
            setUserDetailsMap(details.reduce(mapReducer('userId'), {}));
          })
          .catch(Toast.showErr);
    },
    [currentUser]
  );

  useEffect(fetchUsers, [fetchUsers]);

  const [
    visibleColumns,
    setVisibleColumns
  ] = useLocalStorage(
    'user-table-columns',
    ['firstName', 'lastName', 'jobTitle', 'area', 'phoneNumber', 'email'],
    joi =>
      joi
        .array()
        .items(
          joi.valid(
            'firstName',
            'lastName',
            'jobTitle',
            'area',
            'phoneNumber',
            'email',
            'hireDate',
            'latestPromotionDate',
            'hours',
            'wage',
            'shirtSize',
            'birthDate'
          )
        )
  );
  const colors = useColors();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const history = useHistory();

  const allColumns = [
    {
      key: 'firstName',
      label: 'First Name',
      renderer: (user, info, cellStyle) => (
        <TableCell padding="none" style={cellStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              onClick={e => e.stopPropagation()}
              style={{
                cursor: 'default',
                width: 50,
                height: 50,
                marginRight: 16,
                padding: 5
              }}
            >
              {user.image && (
                <img
                  onClick={e => setPhotoModalUrl(user.image)}
                  alt="profile"
                  src={user.image}
                  style={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    width: 40,
                    height: 40
                  }}
                />
              )}
            </div>
            <div>{user.firstName}</div>
          </div>
        </TableCell>
      )
    },
    { key: 'lastName', label: 'Last Name' },
    { key: 'jobTitle', label: 'Job Title' },
    { key: 'area', label: 'Area' },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      modifier: phone => (
        <ExternalLink href={`tel:${phoneHelper(phone)}`}>
          {phoneHelper(phone)}
        </ExternalLink>
      )
    },
    {
      key: 'email',
      label: 'Email',
      modifier: email => (
        <CopyText text={email} type="Email">
          <ExternalLink href={`mailto:${email}`}>{email}</ExternalLink>
        </CopyText>
      )
    },

    // tier 2
    {
      key: 'hireDate',
      label: 'Hire Date',
      extractor: user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.firstJob) return null;
        return moment(details.firstJob.startDate).format('L');
      },
      ...dateSorts(user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.firstJob) return null;
        return details.firstJob.startDate;
      })
    },
    {
      key: 'latestPromotionDate',
      label: 'Latest Promotion Date',
      extractor: user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.activeJob) return null;
        return moment(details.activeJob.startDate).format('L');
      },
      ...dateSorts(user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.activeJob) return null;
        return details.activeJob.startDate;
      })
    },
    {
      key: 'hours',
      label: 'Hours',
      extractor: user => {
        if (!user.totalHours) return 0;
        const sum =
          (user.totalHours.admin || 0) +
          (user.totalHours.travel || 0) +
          (user.totalHours.uncategorized || 0) +
          (user.totalHours.work || 0);
        return Math.floor(sum);
      },
      ...numSorts(user => {
        if (!user.totalHours) return 0;
        const sum =
          (user.totalHours.admin || 0) +
          (user.totalHours.travel || 0) +
          (user.totalHours.uncategorized || 0) +
          (user.totalHours.work || 0);
        return sum;
      })
    },
    {
      key: 'wage',
      label: 'Wage',
      extractor: user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.activeJob) return null;
        const salary = Number(details.activeJob.salary);
        const wage = Number(details.activeJob.wage);
        const { text } = dollar(salary ? salary : wage);
        return text;
      },
      ...numSorts(user => {
        const details = userDetailsMap[user.id];
        if (!details || !details.activeJob) return null;
        const salary = Number(details.activeJob.salary);
        const wage = Number(details.activeJob.wage);
        return salary ? salary : wage;
      })
    },
    {
      key: 'shirtSize',
      label: 'Shirt Size'
    },
    {
      key: 'birthDate',
      label: 'Birth Date',
      extractor: user =>
        user.birthDate ? moment(user.birthDate).format('L') : null,
      ...dateSorts(user => user.birthDate)
    }
  ];

  useEffect(() => {
    dispatch(getAreas());
  }, [dispatch]);

  const padLeft = el => <span style={{ marginLeft: 10 }}>{el}</span>;

  return (
    <DashboardWrapper>
      <div style={styles.header}>
        <PhotoModal
          onClose={() => setPhotoModalUrl(null)}
          open={!!photoModalUrl}
          url={photoModalUrl}
        />
        <InviteUserModal
          open={inviteUserModal}
          refresh={fetchUsers}
          onClose={() => setInviteUserModal(false)}
        />

        <Typography component="h1" variant="h4">
          Users
          {user.hasPermission('create_users') && (
            <IconButton
              color="primary"
              style={{ marginLeft: 10, padding: 0 }}
              onClick={() => setInviteUserModal(true)}
            >
              <NewUserIcon />
            </IconButton>
          )}
        </Typography>

        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => setViewingInactive(old => !old)}
            variant="outlined"
            size="small"
            color="inherit"
            style={{
              marginRight: 16,
              color: viewingInactive ? colors.error : colors.secondary
            }}
          >
            Viewing {viewingInactive ? 'Inactive' : 'Active'} Users
          </Button>
          {user.hasPermission('users_tier_1') && (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/users/employee-training"
            >
              Employee Trainings
            </Button>
          )}
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <TrainingUserFilter
          users={users}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          localStoragePrefix="users."
        />
        {padLeft(<UserSkillsFilter fetchUsers={fetchUsers} />)}
        {user.hasPermission('users_tier_2') && (
          <>
            {padLeft(
              <ColumnSelector
                selected={visibleColumns}
                onSelectedChange={setVisibleColumns}
                allColumns={allColumns}
              />
            )}
            {padLeft(
              <ColumnsToCSV
                title="STEPS-USERS"
                columns={allColumns.filter(
                  (col, idx) => idx === 0 || visibleColumns.includes(col.key)
                )}
                rows={filteredUsers.filter(row =>
                  viewingInactive
                    ? row.employmentStatus === 'Inactive'
                    : row.employmentStatus !== 'Inactive'
                )}
              />
            )}
            {padLeft(
              <SyncUserTsheets
                onSync={fetchUsers}
                users={filteredUsers.filter(row =>
                  viewingInactive
                    ? row.employmentStatus === 'Inactive'
                    : row.employmentStatus !== 'Inactive'
                )}
              />
            )}
          </>
        )}
      </div>
      <SelectionSortingTable
        stickyHeader
        stickyColumns={1}
        columns={allColumns.filter(
          (col, idx) => idx === 0 || visibleColumns.includes(col.key)
        )}
        rows={filteredUsers.filter(row =>
          viewingInactive
            ? row.employmentStatus === 'Inactive'
            : row.employmentStatus !== 'Inactive'
        )}
        rowAction={
          user.hasPermission('users_tier_1')
            ? row => history.push(`/users/${row.id}`)
            : null
        }
        isSelecting={false}
        TableProps={{ size: 'medium' }}
        rootStyles={{ height: `min(calc(100vh - 210px), 1371px)` }}
        localStorageKey="users.table"
      />
    </DashboardWrapper>
  );
}

function dateSorts(pluckDate) {
  return {
    customSort: (a, b) => {
      a = pluckDate(a);
      b = pluckDate(b);
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;
      return moment(a).valueOf() - moment(b).valueOf();
    },
    descSort: (a, b) => {
      a = pluckDate(a);
      b = pluckDate(b);
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;
      return moment(b).valueOf() - moment(a).valueOf();
    }
  };
}

function numSorts(pluckNum) {
  return {
    customSort: (a, b) => {
      a = pluckNum(a);
      b = pluckNum(b);
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;
      return a - b;
    },
    descSort: (a, b) => {
      a = pluckNum(a);
      b = pluckNum(b);
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;
      return b - a;
    }
  };
}

export default Users;

function dollar(number) {
  number = numOrNull(number);
  const text =
    number === null || number === 0
      ? '$ 0'
      : `$ ${addCommas(number.toFixed(2))}`;
  const value = number === null || number === 0 ? null : number;
  return { text, value };
}

function addCommas(string) {
  const [numSide, decSide] = string.split('.');
  const nums = numSide.match(/\d+/)[0] || '';
  const arr = nums.split('');
  let adj = 0;
  for (let i = 3; i < nums.length; i += 3) {
    arr.splice(-(i + adj), 0, ',');
    adj++;
  }
  return `${numSide.replace(nums, arr.join(''))}.${decSide}`;
}

function numOrNull(number) {
  number = Number(number);
  return isNaN(number) ? null : number;
}
