import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ProjectsContext } from '../context/Projects.context';
import safeParse from 'helpers/safeParse.helper';

const default10hr = {
  type: 'default10hr',
  start: moment().set({ h: 7, m: 0, s: 0, millisecond: 0 }),
  end: moment().set({ h: 17, m: 30, s: 0, millisecond: 0 }),
  activeDays: [false, true, true, true, true, false, false],
  shifts: [],
  sharedUsers: [],
  approved: false,
  lunchStart: moment().set({ h: 12, m: 0, s: 0, millisecond: 0 }),
  lunchDurationMinutes: 30,
  taskId: null,
  workOrderId: null,
  variedWorkOrderId: false
};

const default8hr = {
  type: 'default8hr',
  start: moment().set({ h: 7, m: 0, s: 0, millisecond: 0 }),
  end: moment().set({ h: 15, m: 30, s: 0, millisecond: 0 }),
  activeDays: [false, true, true, true, true, true, false],
  shifts: [],
  sharedUsers: [],
  approved: false,
  lunchStart: moment().set({ h: 11, m: 0, s: 0, millisecond: 0 }),
  lunchDurationMinutes: 30,
  taskId: null,
  workOrderId: null,
  variedWorkOrderId: false
};

const getActiveDays = numActiveDays => {
  numActiveDays = numActiveDays || 4;
  return {
    activeDays: Array(7)
      .fill(false)
      .map((f, idx) =>
        idx === 0 ? false : idx <= numActiveDays ? true : false
      )
  };
};

export default function useDefaultGroup(projectId) {
  const [group, setGroup] = useState(null);
  const { projectMap } = useContext(ProjectsContext);

  useEffect(() => {
    const project = projectMap[projectId];
    if (!project) {
      setGroup({ ...default10hr });
    } else {
      setGroup(
        safeParse(project.scheduleHrsPerDay) === 8
          ? {
              ...default8hr,
              ...getActiveDays(project.scheduleDaysPerWeek)
            }
          : {
              ...default10hr,
              ...getActiveDays(project.scheduleDaysPerWeek)
            }
      );
    }
  }, [projectId, projectMap]);

  return group;
}
