import React, { useEffect, useState } from 'react';
import { NoItemsResult, CompactProjectDisplayTile, Toast } from 'components';
import { withRouter } from 'react-router-dom';
import { Typography, Paper } from 'helpers/themeSafeMui.helper';
import Star from '@material-ui/icons/Star';
import { useColors } from 'helpers/theme.helper';
import http from 'helpers/http.helper';
import safeParse from 'helpers/safeParse.helper';
import useFreshFn from 'hooks/useFreshFn.hook';

const FavoriteProjectDisplay = props => {
  const [openId, setOpenId] = useState(null);
  const [favoriteRelatedMap, setFavoriteRelatedMap] = useState({});
  const [updatedTotalsProjects, setUpdatedProjectsTotals] = useState([]);
  const colors = useColors();

  useEffect(() => {
    http()
      .get('/favorite-user-projects/related')
      .then(setFavoriteRelatedMap)
      .catch(Toast.showErr);
  }, []);

  useEffect(() => {
    setUpdatedProjectsTotals(
      props.favoriteProjects.map(fp => {
        const related = favoriteRelatedMap[fp.projectId];
        if (!related || !related.length) return fp;
        const relatedTotal = related.reduce(
          (sum, rp) => sum + safeParse(rp.dollarValue),
          0
        );
        return { ...fp, dollarValue: safeParse(fp.dollarValue) + relatedTotal };
      })
    );
  }, [favoriteRelatedMap, props.favoriteProjects]);

  const toggleExpand = useFreshFn(project => {
    if (!project) return;
    const { id } = project;
    setOpenId(openId => (openId === id ? null : id));
  });

  return (
    <div color={colors.backgroundText}>
      <Typography variant="h5" gutterBottom>
        <Star
          style={{
            color: '#ffd600',
            verticalAlign: 'text-bottom',
            fontSize: 30
          }}
        />{' '}
        Favorite Projects
      </Typography>

      {!props.favoriteProjects.length && (
        <Paper style={{ padding: 10 }}>
          <NoItemsResult type="favorite projects" />
        </Paper>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: 500,
          justifyContent: 'space-between',
          overflowY: 'auto'
        }}
      >
        {updatedTotalsProjects.map(favorite => (
          <div key={favorite.id}>
            <Paper
              style={{
                cursor: 'pointer',
                margin: 10,
                width: 300
              }}
              onClick={() =>
                props.history.push(`/projects/${favorite.projectId}`)
              }
            >
              <CompactProjectDisplayTile
                project={favorite}
                showTooltip={false}
                compact={favorite.id !== openId}
                handleExpand={toggleExpand}
              />
            </Paper>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(FavoriteProjectDisplay);
