import React, { useEffect, useMemo, useState } from 'react';
import http from 'helpers/http.helper';
import { ConfirmDelete, DragAndDrop, FormModal, Toast } from 'components';
import {
  Typography,
  Button,
  IconButton,
  ListItem,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';

export default function ProjectChecklistItems() {
  const [items, setItems] = useState([]);
  const [creatingItem, setCreatingItem] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const formFields = useFormFields();

  useEffect(() => {
    http()
      .get('/project-checklist-items-master')
      .then(users => setItems(users))
      .catch(err => Toast.show(err.message));
  }, []);

  const createChecklistItem = async formItem => {
    try {
      const newItem = await http().post('/project-checklist-items-master', {
        ...formItem,
        order: items.length
      });
      setItems(old => [...old, newItem]);
      setCreatingItem(null);
      Toast.show('Item Created');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const editChecklistItem = async formItem => {
    try {
      if (!editingItem) return;
      const copy = [...items];
      const updated = await http().put(
        `/project-checklist-items-master/${editingItem.id}`,
        {
          ...pluck(
            formItem,
            'title',
            'notes',
            'allowOptional',
            'requiredAtStatusId'
          )
        }
      );
      copy[updated.order] = updated;
      setItems(copy);
      setEditingItem(null);
      Toast.show('Item Updated');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const removeChecklistItem = item => async () => {
    try {
      await http().delete(`/project-checklist-items-master/${item.id}`);
      const copy = [...items];
      copy.splice(item.order, 1);
      await onDrop(copy);
      Toast.show('Item Removed');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const onDrop = async droppedItems => {
    try {
      setItems(droppedItems);
      droppedItems = await Promise.all(
        droppedItems.map((item, order) =>
          http().put(`/project-checklist-items-master/${item.id}`, { order })
        )
      );
      setItems(droppedItems);
    } catch (err) {
      setItems(items);
      Toast.showErr(err);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={() => setCreatingItem(defaultNewItem)}
      >
        Create
      </Button>
      <FormModal
        title="Edit Checklist Item"
        onClose={() => setEditingItem(null)}
        open={!!editingItem}
        onSubmit={editChecklistItem}
        fields={formFields}
        data={editingItem}
      />
      <FormModal
        title="Create Checklist Item"
        onClose={() => setCreatingItem(null)}
        open={!!creatingItem}
        onSubmit={createChecklistItem}
        fields={formFields}
        data={creatingItem}
      />
      <DragAndDrop cardStyles={{ padding: 0, paddingLeft: 10 }} onDrop={onDrop}>
        {items.map(item => (
          <ListItem
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
            key={item.id}
            id={item.id}
            value={item}
          >
            <ListItemText
              primary={item.title}
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                (item.notes || item.status) && (
                  <>
                    {item.status && (
                      <Typography
                        style={{ lineHeight: 1, marginTop: 7 }}
                        component="div"
                        variant="overline"
                      >
                        {item.status}
                      </Typography>
                    )}
                    {item.notes && (
                      <Typography variant="caption">{item.notes}</Typography>
                    )}
                  </>
                )
              }
            />
            {item.allowOptional && (
              <Typography
                style={{ marginRight: 10 }}
                variant="caption"
                color="textSecondary"
              >
                Optional Allowed
              </Typography>
            )}
            <IconButton size="small" onClick={() => setEditingItem(item)}>
              <EditIcon />
            </IconButton>
            <ConfirmDelete
              onConfirm={removeChecklistItem(item)}
              iconButtonProps={{ size: 'small' }}
            />
          </ListItem>
        ))}
      </DragAndDrop>
    </div>
  );
}

const useFormFields = () => {
  const statuses = useSelector(state => state.projectStatuses);
  return useMemo(() => {
    const statusOptions = [
      {
        value: null,
        label: 'None'
      }
    ].concat(
      statuses.map(status => ({
        value: status.id,
        label: status.status
      }))
    );
    return [
      ...formFields,
      {
        label: 'Required At Status',
        key: 'requiredAtStatusId',
        type: 'dropdown',
        customInputProps: { options: statusOptions }
      }
    ];
  }, [statuses]);
};

const formFields = [
  {
    label: 'Title',
    key: 'title',
    type: 'text'
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'text'
  },
  {
    label: 'Can be marked optional',
    key: 'allowOptional',
    type: 'boolean'
  }
];

const defaultNewItem = {
  title: '',
  notes: '',
  allowOptional: false
};

function pluck(object, ...keys) {
  const retVal = {};
  keys.forEach(key => {
    retVal[key] = object[key];
  });
  return retVal;
}
