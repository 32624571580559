import {
  PDFDocument,
  PDFFont,
  PDFPage,
  StandardFonts,
  grayscale
} from 'pdf-lib';
import { createTableData, PdfLibTableCell } from './CreateTable.helper';

export default class PdfLibTable {
  private textContent: string[][] = [];
  cellDataPages: PdfLibTableCell[][][] = [];
  headerFont: PDFFont | null = null;
  cellFont: PDFFont | null = null;
  rowsFontSize = 8;
  headerFontSize = 10;
  padX = 10;
  padY = 5;

  setText(textGrid: string[][]) {
    this.textContent = textGrid.map((row, rowIdx) =>
      row.map(text => {
        const font = rowIdx === 0 ? this.headerFont : this.cellFont;
        if (!font) return text;
        return this.filterText(text, font);
      })
    );
  }

  getAlign = (pageIdx: number, rowIdx: number, colIdx: number) =>
    'c' as 'l' | 'c' | 'r';

  drawCellBackground = (
    cell: PdfLibTableCell,
    page: PDFPage,
    pageIdx: number,
    rowIdx: number,
    colIdx: number
  ) => {
    const fixY = (y: number) => page.getHeight() - y;
    page.drawRectangle({
      x: cell.x,
      y: fixY(cell.y) - cell.height,
      width: cell.width,
      height: cell.height,
      borderWidth: 1,
      borderColor: grayscale(0),
      color: grayscale(1)
    });
  };

  setup(
    x: number,
    y: number,
    width: number,
    noTruncColIdxs: number[] = [],
    maxHeight = -1
  ) {
    this.buildCellData(x, y, width, noTruncColIdxs, maxHeight);
    for (let pageIdx = 0; pageIdx < this.cellDataPages.length; pageIdx++) {
      const page = this.cellDataPages[pageIdx];
      for (let rowIdx = 0; rowIdx < page.length; rowIdx++) {
        const row = page[rowIdx];
        for (let colIdx = 0; colIdx < row.length; colIdx++) {
          const cell = row[colIdx];
          cell.align = this.getAlign(pageIdx, rowIdx, colIdx);
        }
      }
    }
  }

  draw(page: PDFPage, pageIdx = 0) {
    const cellData = this.cellDataPages[pageIdx];
    const fixY = (y: number) => page.getHeight() - y;
    for (let rowIdx = 0; rowIdx < cellData.length; rowIdx++) {
      const row = cellData[rowIdx];
      for (let colIdx = 0; colIdx < row.length; colIdx++) {
        const cell = row[colIdx];
        this.drawCellBackground(cell, page, pageIdx, rowIdx, colIdx);
        const textY = fixY(cell.y) - cell.height + cell.padY;
        const textLeftX = cell.x + cell.padX;
        const textCenterX = cell.x + cell.width / 2 - cell.textWidth / 2;
        const textRightX = cell.x + cell.width - cell.textWidth - cell.padX;
        const textX =
          cell.align === 'l'
            ? textLeftX
            : cell.align === 'c'
            ? textCenterX
            : textRightX;

        page.drawText(cell.text, {
          x: textX,
          y: textY,
          font: cell.font,
          size: cell.fontSize,
          color: grayscale(0),
          lineHeight: 1,
          opacity: 1
        });
      }
    }
  }

  private filterText = (s: string, font: PDFFont) => {
    return (s || '')
      .split('')
      .filter(char => {
        const val = char.charCodeAt(0);
        const charset = font.getCharacterSet();
        return charset.includes(val);
      })
      .join('');
  };

  async embedFonts(doc: PDFDocument) {
    this.headerFont = await doc.embedFont(StandardFonts.Helvetica);
    this.cellFont = this.headerFont;
  }

  getHeightAtPage(pageIdx: number) {
    const page = this.cellDataPages[pageIdx];
    return !page
      ? 0
      : page.reduce((sum, row) => sum + (row[0] ? row[0].height : 0), 0);
  }

  private buildCellData(
    x: number,
    y: number,
    width: number,
    noTruncColIdxs: number[],
    maxHeight: number
  ) {
    if (!this.cellFont || !this.headerFont) {
      return [];
    }
    this.cellDataPages = createTableData({
      font: this.cellFont,
      width,
      maxHeight,
      rows: this.textContent,
      rowsFontSize: this.rowsFontSize,
      headerFont: this.headerFont,
      headerFontSize: this.headerFontSize,
      noTruncColIdxs,
      padX: this.padX,
      padY: this.padY,
      x,
      y
    });
  }
}
