import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { fromDateString, fromTimeString } from '../helpers/DateTime.helper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import usePopper from 'hooks/usePopper.hook';
import { useColors } from 'helpers/theme.helper';
import { ShiftModalContext } from '../context/ShiftModal.context';
import ViewWorkOrderModal from '../modals/ViewWorkOrder.modal';
import CrewSelector from './CrewSelector.component';
import { useGetDaysText } from '../hooks/useUserIsScheduled.hook';
import useDayUpdater from '../hooks/useDayUpdater.hook';
import useWeekUpdater from '../hooks/useWeekUpdater.hook';
import { CopyText } from 'components';

const ShiftCard = React.forwardRef(
  (
    {
      shiftDay,
      lockedOpen = false,
      noEdit = false,
      highlightApproved = true,
      showCrewPhoneNumbers = false,
      withDate = false
    },
    ref
  ) => {
    const colors = useColors();
    const { shiftUserMap } = useContext(ShiftScheduleContext);
    const { openWithProps } = useContext(ShiftModalContext);
    const [open, setOpen] = useState(lockedOpen ? true : false);
    const [workOrderOpen, setWorkOrderOpen] = useState(false);
    const getDaysText = useGetDaysText();
    const [shiftUsers, setShiftUsers] = useState(
      shiftUserMap[shiftDay.id] || []
    );
    const endDivRef = useRef();
    const updateDay = useDayUpdater({
      shiftId: shiftDay.id,
      userIds: shiftUsers.map(u => u.userId || u.id)
    });
    const updateWeek = useWeekUpdater({
      shiftDayId: shiftDay.id,
      projectId: shiftDay.projectId,
      sharedUsers: shiftUsers.map(u => u.userId || u.id)
    });

    const { Popper, handleClick, close } = usePopper();
    const {
      Popper: CrewPopper,
      handleClick: handleCrewClick,
      close: closeCrew,
      isOpen: crewOpen
    } = usePopper();

    useEffect(() => {
      if (!crewOpen) setShiftUsers(shiftUserMap[shiftDay.id] || []);
    }, [crewOpen, shiftUserMap, shiftDay.id]);

    const ellipsis = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };

    const openEditDay = () => {
      close();
      openWithProps({ shiftId: shiftDay.id }, 'day');
    };

    const openEditWeek = () => {
      close();
      openWithProps({ projectId: shiftDay.projectId }, 'week');
    };

    const borderColor =
      highlightApproved && shiftDay.approved ? colors.success : colors.grey;

    let openUserSpots = (shiftDay.taskTechCount || 0) - shiftUsers.length;
    openUserSpots = openUserSpots > 0 ? Array(openUserSpots).fill(null) : [];

    return (
      <Paper
        ref={ref}
        style={{ width: '100%', borderLeft: `6px solid ${borderColor}` }}
      >
        <div
          style={{
            width: '100%',
            padding: 5,
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => !lockedOpen && setOpen(!open)}
        >
          <div style={{ width: 'calc(100% - 30px)' }}>
            <Typography style={open ? {} : ellipsis}>
              {shiftDay.projectNumber} {shiftDay.projectName}
            </Typography>
            {withDate && (
              <Typography
                component="p"
                variant="caption"
                style={{ fontWeight: 500 }}
              >
                {fromDateString(shiftDay.date).format('dddd, MMMM Do')}
              </Typography>
            )}
            {shiftDay.taskName && (
              <Typography
                component="p"
                variant="caption"
                style={{ fontWeight: 500 }}
              >
                {shiftDay.taskName}
              </Typography>
            )}
            <Typography variant="caption" style={{ fontWeight: 500 }}>
              {fromTimeString(shiftDay.start).format('h:mm A')} -{' '}
              {fromTimeString(shiftDay.end).format('h:mm A')}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
            onClick={e => e.stopPropagation()}
          >
            {!lockedOpen && (
              <IconButton size="small" onClick={() => setOpen(open => !open)}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
            {open && !noEdit && (
              <IconButton
                size="small"
                onClick={e => {
                  handleClick(e);
                  e.stopPropagation();
                }}
              >
                <EditIcon />
              </IconButton>
            )}
            <Popper>
              <ListItem button onClick={openEditDay}>
                <ListItemText
                  primary="Edit day"
                  secondary="Only edit this project's shift on this day"
                />
              </ListItem>
              <ListItem button onClick={openEditWeek}>
                <ListItemText
                  primary="Edit week"
                  secondary="Edit all shifts this week for this project"
                />
              </ListItem>
            </Popper>
          </div>
        </div>
        {open && (
          <>
            <Divider />
            <div style={{ padding: 5 }}>
              <CopyText text={shiftDay.projectAddress} type="Address">
                <Typography
                  style={{ marginBottom: 5 }}
                  component="div"
                  variant="caption"
                >
                  {shiftDay.projectAddress}
                </Typography>
              </CopyText>
              {shiftDay.taskId && (
                <>
                  <Typography style={{ fontWeight: 500 }}>Task:</Typography>
                  <div style={{ paddingLeft: 5 }}>{shiftDay.taskName}</div>
                </>
              )}
              <Typography style={{ fontWeight: 500 }}>Crew:</Typography>
              <div style={{ paddingLeft: 5 }}>
                {shiftUsers.length || openUserSpots.length ? (
                  shiftUsers
                    .map(shiftUser => {
                      const name = shiftUser.userName || shiftUser.name;
                      return showCrewPhoneNumbers ? (
                        <CopyText
                          text={shiftUser.phoneNumber}
                          type={`${name}'s phone number`}
                          style={{ marginBottom: 4 }}
                        >
                          <div key={shiftUser.id}>
                            <Typography>{name}</Typography>
                            {showCrewPhoneNumbers && (
                              <Typography
                                style={{ marginLeft: 6 }}
                                variant="caption"
                              >
                                {shiftUser.phoneNumber}
                              </Typography>
                            )}
                          </div>
                        </CopyText>
                      ) : (
                        <div key={shiftUser.id}>
                          {shiftUser.userName || shiftUser.name}
                        </div>
                      );
                    })
                    .concat(
                      noEdit
                        ? []
                        : [
                            <div
                              onClick={() =>
                                !noEdit &&
                                handleCrewClick({
                                  currentTarget: endDivRef.current
                                })
                              }
                              key="empty spots"
                              style={{ cursor: 'pointer' }}
                            >
                              {openUserSpots.map((n, i) => (
                                <div
                                  key={i}
                                  style={{
                                    height: 12,
                                    borderBottom: `1px solid ${colors.paperText}`,
                                    marginBottom: 5,
                                    marginRight: 15
                                  }}
                                />
                              ))}
                              <div ref={endDivRef} />
                            </div>
                          ]
                    )
                ) : (
                  <div style={{ color: colors.error }}>None</div>
                )}
                <CrewPopper>
                  <div style={{ padding: '12px 12px' }}>
                    <CrewSelector
                      onSelect={user => {
                        setShiftUsers(old => {
                          const idx = old.findIndex(
                            other => (other.userId || other.id) === user.id
                          );
                          if (idx === -1) return [...old, user];
                          const copy = [...old];
                          copy.splice(idx, 1);
                          return copy;
                        });
                      }}
                      getSecondaryText={user => getDaysText(user)}
                    />
                    <div style={{ display: 'flex', marginTop: 5 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          updateWeek()
                            .then(() => closeCrew())
                            .catch(() => {})
                        }
                        style={{ marginRight: 10 }}
                      >
                        Update Week
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          updateDay().then(() => closeCrew());
                        }}
                      >
                        Update Day
                      </Button>
                    </div>
                  </div>
                </CrewPopper>
              </div>
              {!!(shiftDay.notes && shiftDay.notes.trim()) && (
                <>
                  <Typography style={{ fontWeight: 500 }}>Notes:</Typography>
                  <div style={{ paddingLeft: 5, whiteSpace: 'pre-wrap' }}>
                    {shiftDay.notes}
                  </div>
                </>
              )}
              {!!shiftDay.workOrderId && (
                <Button
                  style={{ marginTop: 5 }}
                  onClick={() => setWorkOrderOpen(true)}
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Open Work Order
                </Button>
              )}
            </div>
          </>
        )}
        {!!shiftDay && !!shiftDay.workOrderId && (
          <ViewWorkOrderModal
            open={workOrderOpen}
            onClose={() => setWorkOrderOpen(false)}
            workOrder={shiftDay.workOrderId}
            projectId={shiftDay.projectId}
          />
        )}
      </Paper>
    );
  }
);

export default ShiftCard;
