import React, { useState, useEffect } from 'react';
import { CustomInput, Toast } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

const defaultOption = {
  title: '',
  address: '',
  phoneNumber: '',
  order: -1
};

function ShipToOptionModal({
  editing,
  id = null,
  open,
  onClose,
  options,
  onUpdate
}) {
  const [stateOption, setStateOption] = useState({});

  const option = options.find(option => option.id === id);

  useEffect(() => {
    if (open) setStateOption({ ...defaultOption, ...option });
  }, [option, open]);

  const save = e => {
    e.preventDefault();
    const editing = !!id;
    const method = editing ? 'put' : 'post';
    const path = editing ? `/po-ship-to-options/${id}` : '/po-ship-to-options';

    http()
      [method](path, {
        title: stateOption.title,
        address: stateOption.address,
        phoneNumber: stateOption.phoneNumber,
        order: stateOption.order
      })
      .then(() => onUpdate())
      .then(() => onClose())
      .catch(err => Toast.show(err.message));
  };

  const onChange = field => value => {
    setStateOption(s => ({ ...s, [field]: value }));
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>
          {editing ? 'Update' : 'Create'} Ship-To Option
        </DialogTitle>
        <DialogContent>
          <div>
            <CustomInput
              type="text"
              label="Title"
              value={stateOption.title}
              onChange={onChange('title')}
              required
            />
          </div>
          <div>
            <CustomInput
              type="address"
              label="Address"
              value={stateOption.address}
              onChange={onChange('address')}
              required
            />
          </div>
          <div>
            <CustomInput
              type="text"
              label="Phone Number"
              value={stateOption.phoneNumber}
              onChange={onChange('phoneNumber')}
              required
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ShipToOptionModal;
