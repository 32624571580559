import React, { useRef, useState, useEffect } from 'react';
import { Dialog, IconButton } from 'helpers/themeSafeMui.helper';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { useColors } from 'helpers/theme.helper';

const PhotoModal = ({ url: urlProp, open, onClose }) => {
  const imgRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [zoomable, setZoomable] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [url, setUrl] = useState(urlProp);
  const colors = useColors();

  useEffect(() => {
    if (imgRef.current && imageLoaded) {
      const image = imgRef.current;

      if (zoomed) {
        setSize({
          width: image.naturalWidth,
          height: image.naturalHeight
        });
        return;
      }

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const containerWidth = screenWidth - 64;
      const containerHeight = screenHeight - 64;

      const xOverflow = Math.max(0, image.naturalWidth - containerWidth);
      const yOverflow = Math.max(0, image.naturalHeight - containerHeight);

      if (xOverflow === 0 && yOverflow === 0) {
        setZoomable(false);
        setSize({
          width: image.naturalWidth,
          height: image.naturalHeight
        });
        return;
      } else {
        setZoomable(true);
      }

      const scale = Math.min(
        containerWidth / image.naturalWidth,
        containerHeight / image.naturalHeight
      );

      setSize({
        width: image.naturalWidth * scale,
        height: image.naturalHeight * scale
      });
    }
  }, [imageLoaded, zoomed, open]);

  useEffect(() => {
    if (!open) {
      setSize({ width: 0, height: 0 });
      setImageLoaded(false);
    }
  }, [open]);

  useEffect(() => {
    if (urlProp) {
      setImageLoaded(false);
      setUrl(urlProp);
    }
  }, [urlProp]);

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      {zoomable && (
        <IconButton
          style={{
            position: 'fixed',
            top: 10,
            left: 10,
            backgroundColor: colors.paper
          }}
          onClick={() => setZoomed(!zoomed)}
        >
          {zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
        </IconButton>
      )}
      <img
        ref={imgRef}
        alt="modal"
        onLoad={() => setImageLoaded(true)}
        style={{ ...size, transition: 'all 200ms ease-in-out' }}
        src={url}
      />
    </Dialog>
  );
};

export default PhotoModal;
