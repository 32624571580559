import { useRef, useEffect } from 'react';
import { useColors } from 'helpers/theme.helper';

export const useCanvasUtils = (width: number, height: number) => {
  const utilsRef = useRef<null | CanvasUtils>(null);
  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const colors = useColors();
  if (!utilsRef.current) {
    utilsRef.current = new CanvasUtils(
      canvasRef.current ? canvasRef.current.getContext('2d') : null,
      colors.paperText
    );
  }
  useEffect(() => {
    if (utilsRef.current && !utilsRef.current.ctx && canvasRef.current) {
      utilsRef.current.update(
        canvasRef.current.getContext('2d'),
        width,
        height,
        colors.paperText,
        colors.paper
      );
    }
  });

  useEffect(() => {
    if (utilsRef.current) {
      utilsRef.current.setSize(width, height);
    }
  }, [width, height]);

  return {
    canvasUtils: utilsRef.current,
    canvasRef
  };
};

class CanvasUtils {
  dpr: number;
  width = 0;
  height = 0;
  constructor(
    public ctx: CanvasRenderingContext2D | null,
    public color = '#000000',
    public fill = '#ffffff'
  ) {
    this.dpr = window.devicePixelRatio;
  }

  update(
    ctx: CanvasRenderingContext2D | null,
    width: number,
    height: number,
    color = '#000000',
    fill = '#ffffff'
  ) {
    this.dpr = window.devicePixelRatio;
    this.ctx = ctx;
    this.width = width;
    this.height = height;
    if (!this.ctx) return;
    this.ctx.canvas.style.width = `${width}px`;
    this.ctx.canvas.style.height = `${height}px`;
    this.ctx.canvas.width = width * this.dpr;
    this.ctx.canvas.height = height * this.dpr;
    this.color = color;
    this.fill = fill;
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
    if (!this.ctx) return;
    this.ctx.canvas.style.width = `${width}px`;
    this.ctx.canvas.style.height = `${height}px`;
    this.ctx.canvas.width = width * this.dpr;
    this.ctx.canvas.height = height * this.dpr;
  }

  line(x1: number, y1: number, x2: number, y2: number) {
    if (!this.ctx) return;
    [x1, y1, x2, y2] = this.multDpr(x1, y1, x2, y2);
    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.strokeStyle = this.color;
    this.ctx.stroke();
    this.ctx.restore();
  }

  arrow(x1: number, y1: number, x2: number, y2: number, sideLength = 10) {
    if (!this.ctx) return;
    this.line(x1, y1, x2, y2);
    [x1, y1, x2, y2, sideLength] = this.multDpr(x1, y1, x2, y2, sideLength);
    const angle = this.angleBetween(x1, y1, x2, y2);
    this.ctx.save();
    this.ctx.translate(x2, y2);
    this.ctx.rotate(angle);
    this.ctx.fillStyle = this.fill;
    this.ctx.strokeStyle = this.color;
    this.ctx.beginPath();

    this.ctx.moveTo(0, 0);
    this.ctx.rotate(Math.PI / 6);
    this.ctx.rotate(Math.PI * (2 / 3));
    this.ctx.translate(-sideLength, 0);
    this.ctx.lineTo(0, 0);
    this.ctx.rotate(Math.PI * (2 / 3));
    this.ctx.translate(-sideLength, 0);
    this.ctx.lineTo(0, 0);
    this.ctx.rotate(Math.PI * (2 / 3));
    this.ctx.translate(-sideLength, 0);
    this.ctx.lineTo(0, 0);
    this.ctx.fill();
    this.ctx.stroke();
    this.ctx.restore();
  }

  clear() {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, this.width * this.dpr, this.height * this.dpr);
  }

  private angleBetween(cx: number, cy: number, ex: number, ey: number) {
    const dy = ey - cy;
    const dx = ex - cx;
    return Math.atan2(dy, dx) + Math.PI;
  }

  private multDpr(...input: number[]) {
    return input.map(n => n * this.dpr);
  }
}
