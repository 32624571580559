import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import debounce from 'lodash.debounce';

const autoSaveNotes = debounce((projectId, itemId, workCompletedNotes) => {
  http()
    .put(`/projects/${projectId}/work-orders/${itemId}`, {
      workCompletedNotes: workCompletedNotes
    })
    .catch(err => Toast.show(err.message));
}, 1000);

function WorkOrderNotes(props) {
  const { canEdit, item } = props;
  const [workCompletedNotes, setWorkCompletedNotes] = useState(
    item.workCompletedNotes
  );

  useEffect(() => {
    if (workCompletedNotes !== item.workCompletedNotes) {
      autoSaveNotes(item.projectId, item.id, workCompletedNotes);
    }
  }, [item, workCompletedNotes]);

  return (
    <Grid item xs={12}>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <Typography variant="h5" style={{ marginBottom: 16 }}>
          Work Completed
        </Typography>
      </div>

      <AutoSaveField
        canEdit={canEdit && !item.techSignOff}
        label="Work Completed Notes"
        field={workCompletedNotes}
        setField={setWorkCompletedNotes}
      />
    </Grid>
  );
}

export default WorkOrderNotes;

function AutoSaveField(props) {
  const { canEdit, label, field, setField } = props;

  if (canEdit) {
    return (
      <TextField
        label={label}
        fullWidth
        value={field || ''}
        onChange={e => setField(e.target.value)}
        variant="outlined"
        multiline
        rows="6"
      />
    );
  } else {
    return <Typography style={{ whiteSpace: 'pre-line' }}>{field}</Typography>;
  }
}
