import React, { useState, useContext, useRef } from 'react';

import {
  Button,
  Divider,
  Grid,
  Typography,
  IconButton,
  TextField
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import AddPhaseMaterial from '../../modals/AddPhaseMaterial.modal';
import MaterialsEstimateTable from '../tables/MaterialsEstimateTable.component';
import toCurrency from 'helpers/currency.helper';
import { EstimateContext } from 'routes/Projects/context/Estimate.context';
import { calculateMaterialCosts } from '../../helpers/phaseMaterial.helper';
import { useBetterBlur } from 'components';
import { v4 as uuid } from 'uuid';

function PhasePanel(props) {
  const {
    selectedTab,
    tabIndex,
    phase,
    onDelete,
    savePhase,
    duplicatePhase
  } = props;

  const { estimate } = useContext(EstimateContext);
  const phaseMaterialsCosts = phase.materials.map(material =>
    calculateMaterialCosts({ material, estimateDetails: estimate })
  );
  const [triedUpdating, setTriedUpdating] = useState(false);

  // Display Variables
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);

  const [editingTitle, setEditingTitle] = useState(false);
  const blurRef = useRef(null);
  const { blur } = useBetterBlur({
    innerRef: blurRef,
    onFocus: () => setEditingTitle(phase.name),
    onBlur: () => setEditingTitle(false)
  });

  const addMaterial = material => {
    savePhase({
      ...phase,
      materials: [material].concat(phase.materials)
    });
  };
  const deleteMaterial = id => {
    savePhase({
      ...phase,
      materials: phase.materials.filter(m => m.id !== id)
    });
  };

  const onUpdateName = e => {
    if (e) e.preventDefault();
    savePhase({ ...phase, name: editingTitle });
    setEditingTitle(false);
    blur();
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') onUpdateName();
    if (e.key === 'Escape') blur();
  };

  const selectAll = e => {
    e.target.setSelectionRange(0, -1);
  };

  const allPhaseMaterialsHaveIds = phase.materials.reduce(
    (acc, material) => acc && !!material.id,
    true
  );

  if (!allPhaseMaterialsHaveIds) {
    if (!triedUpdating) {
      const updatedMaterials = phase.materials.map(m =>
        m.id ? m : { ...m, id: uuid() }
      );
      savePhase({
        ...phase,
        materials: updatedMaterials
      });
      setTriedUpdating(true);
    }
    return null;
  }

  if (selectedTab === tabIndex)
    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: 16,
          position: 'relative',
          margin: 0
        }}
      >
        <AddPhaseMaterial
          open={showAddMaterialModal}
          phaseMaterials={phase.materials}
          onClose={() => setShowAddMaterialModal(false)}
          onSave={material => addMaterial(material)}
        />
        <div style={{ position: 'absolute', top: 16, right: 16 }}>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </div>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ paddingRight: 30 }}>
            <span
              ref={blurRef}
              style={{ cursor: editingTitle === false ? 'pointer' : null }}
            >
              {editingTitle !== false ? (
                <form onSubmit={onUpdateName} style={{ display: 'flex' }}>
                  <TextField
                    autoFocus
                    multiline
                    value={editingTitle}
                    onChange={e => setEditingTitle(e.target.value)}
                    onFocus={selectAll}
                    onKeyDown={onKeyDown}
                    style={{ flexGrow: 1 }}
                  />
                  <IconButton size="small" type="submit">
                    <SaveIcon />
                  </IconButton>
                  <IconButton size="small" onClick={blur}>
                    <CancelIcon />
                  </IconButton>
                  <div style={{ flexGrow: 2 }}></div>
                </form>
              ) : (
                <>
                  {phase.name}
                  <IconButton size="small" style={{ marginLeft: 25 }}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </span>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Created on: {moment(phase.created).format('LLL')}
          </Typography>
          <Divider style={{ marginTop: 16 }} />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 8 }}
            onClick={() => setShowAddMaterialModal(true)}
          >
            + Material
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => duplicatePhase(phase)}
          >
            Duplicate Phase
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialsEstimateTable
            phase={phase}
            onSave={savePhase}
            onDelete={deleteMaterial}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            Phase Summary
          </Typography>
          <Divider style={{ marginBottom: 16 }} />
          <Typography>
            <strong>Total Labor Hours: </strong>
            {phaseMaterialsCosts.reduce(
              (acc, materialCosts) => materialCosts.hrs + acc,
              0
            )}
          </Typography>

          <Typography>
            <strong>Total Material Cost: </strong>
            {toCurrency(
              phaseMaterialsCosts.reduce(
                (acc, materialCosts) => materialCosts.materials + acc,
                0
              )
            )}
          </Typography>

          <Typography>
            <strong>Total Cost For Phase: </strong>
            {toCurrency(
              phaseMaterialsCosts.reduce(
                (acc, materialCosts) => materialCosts.total + acc,
                0
              )
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  else return null;
}

export default PhasePanel;
