import React from 'react';
import { LinearProgress } from 'helpers/themeSafeMui.helper';
import pubsub from 'helpers/pubsub.helper';

export default class Progress extends React.Component {
  state = { loading: false };

  componentDidMount() {
    this.timeouts = [];
    this.loadingStartSubscription = pubsub.subscribe('LOADING_START', () => {
      const timeout = setTimeout(() => {
        this.setState({ loading: true });
      }, 1000);
      this.timeouts.push(timeout);
    });

    this.loadingEndSubscription = pubsub.subscribe('LOADING_END', () => {
      clearTimeout(this.timeouts[0]);
      this.timeouts.shift();
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.loadingStartSubscription.remove();
    this.loadingEndSubscription.remove();
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{ position: 'fixed', right: 0, left: 0, top: 0, zIndex: 9999 }}
        >
          <LinearProgress color="secondary" />
        </div>
      );
    } else {
      return null;
    }
  }
}
