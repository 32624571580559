class CanvasUtils {
  /** @type {CanvasRenderingContext2D} */
  ctx = null;

  constructor(ctx) {
    this.ctx = ctx;
    this.ctx.textBaseline = 'top';
    this.dpr = window.devicePixelRatio || 1;
    this.ctx.font = `${14 * this.dpr}px Roboto`;
  }

  box(x, y, w, h, options = {}) {
    this.ctx.save();
    if (options.width) this.ctx.lineWidth = options.width;
    if (options.fill) {
      this.ctx.fillStyle = options.fill;
      this.ctx.fillRect(x, y, w, h);
    }
    if (options.color) this.ctx.strokeStyle = options.color;
    this.ctx.strokeRect(x, y, w, h);
    this.ctx.restore();
  }

  fontSize(size = null) {
    if (size !== null) {
      size = (size || 1) * this.dpr * 14;
      this.ctx.font = `${size}px Roboto`;
    }
    return size;
  }

  text(text, x, y, options = {}) {
    this.ctx.save();
    this.ctx.textBaseline = 'top';
    const weight = options.weight || '';
    const size = (options.size || 1) * this.dpr * 14;
    this.ctx.font = `${weight} ${size}px Roboto`;
    if (options.align) this.ctx.textAlign = options.align;
    this.ctx.fillText(text, x, y);
    this.ctx.restore();
  }

  rText(text, x, y, options = {}) {
    this.text(text, x, y, { align: 'right', ...options });
  }

  cText(text, x, y, options = {}) {
    this.text(text, x, y, { align: 'center', ...options });
  }
}

export default CanvasUtils;
