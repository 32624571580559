import diffDays from './diffDays.helper';

export type ScheduleData =
  | {
      type: 'tasks';
      project: any;
      tasks: any[];
      indexes: [number, number][];
      // array of tasks
    }
  | {
      type: 'project';
      project: any;
      indexes: [number, number];
      // single project
    };

export type GetScheduleDataArg = {
  projects: any[];
  tasks: any[];
  startDay: string;
};

const getScheduleData = (args: GetScheduleDataArg): ScheduleData[] => {
  const { projects, tasks, startDay } = args;
  const projectMap: any = {};
  const taskMap: any = {};
  const rows: ScheduleData[] = [];

  projects.forEach(project => (projectMap[project.id] = project));
  tasks.forEach(task => {
    if (!Array.isArray(taskMap[task.projectId])) {
      taskMap[task.projectId] = [task];
    } else {
      taskMap[task.projectId].push(task);
    }
  });

  projects.forEach(({ id: projectId }) => {
    const project = projectMap[projectId];
    if (taskMap[projectId]) {
      rows.push({
        type: 'tasks',
        project,
        tasks: taskMap[projectId],
        indexes: taskMap[projectId].map((task: any) => [
          diffDays(startDay, task.startDate),
          diffDays(startDay, task.endDate)
        ])
      });
    } else {
      rows.push({
        type: 'project',
        project,
        indexes: [
          diffDays(startDay, project.start),
          diffDays(startDay, project.scheduleFloat || project.end)
        ]
      });
    }
  });

  return rows;
};

export default getScheduleData;
