import React, { useState } from 'react';
import { useColors } from 'helpers/theme.helper';
import { Toast, EmailSearch } from 'components';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

const CreateAndSendPDF = props => {
  const { project, quote, onUpdate, onClose } = props;
  const colors = useColors();
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [emailBody, setEmailBody] = useState('');

  const saveAndSend = e => {
    e.preventDefault();
    setIsLoading(true);

    http()
      .post(`/projects/${project.id}/quotes/${quote.id}/send`, {
        emails: contacts.map(c => c.email),
        emailBody: emailBody
      })
      .then(res => {
        Toast.show(
          `The ${quote.type.toLowerCase()} was sent and can be viewed in the project's drive folder.`
        );
        onUpdate(res);
        onClose();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <form autoComplete="off" onSubmit={saveAndSend}>
      <DialogTitle style={{ textAlign: 'center' }}>
        Save and Send PDF
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <EmailSearch
          TextFieldProps={{
            fullWidth: true,
            margin: 'normal',
            label: 'Recipients'
          }}
          required
          onChange={contacts => setContacts(contacts)}
          selected={contacts}
        />

        <TextField
          label="Email Body"
          variant="outlined"
          multiline
          fullWidth
          rows="5"
          value={emailBody}
          onChange={e => setEmailBody(e.target.value)}
          margin="normal"
          required
        />
      </DialogContent>

      <DialogActions style={{ paddingTop: 24 }}>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <div style={{ position: 'relative' }}>
          <Button
            color="primary"
            disabled={isLoading}
            variant="contained"
            type="submit"
          >
            Create &amp; Send
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                color: colors.secondary,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12
              }}
            />
          )}
        </div>
      </DialogActions>
    </form>
  );
};

export default CreateAndSendPDF;
