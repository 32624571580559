export const poKeys = [
  'poNumber',
  'attn',
  'quoteNumber',
  'from',
  'shipping',
  'billTo',
  'billToContactEmail',
  'billToContactPhone',
  'shipTo',
  'shipToContactPhone',
  'notes'
];

export const poKeyLabelMap = {
  date: 'Date',
  attn: 'Attn',
  poNumber: 'PO Number',
  quoteNumber: 'Quote Number',
  from: 'From',
  shipping: 'Shipping',
  billTo: 'Bill To',
  billToContactEmail: 'Billing Contact Email',
  billToContactPhone: 'Billing Contact Phone',
  shipTo: 'Ship To',
  shipToContactPhone: 'Shipping Contact Phone',
  notes: 'Notes'
};
