import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { useSelector } from 'react-redux';

export const UserContext = React.createContext();
export const EmploymentLogContext = React.createContext();
export const NewhireChecklistContext = React.createContext();

export default withRouter(props => {
  const userId = props.match.params.id;
  const currentUser = useSelector(state => state.user);
  const [user, setUser] = useState({});
  const [employmentLogs, setEmploymentLogs] = useState([]);
  const [newhireChecklist, setNewhireChecklist] = useState([]);

  useEffect(() => {
    http()
      .get(`/users/${userId}?includeImage=true`)
      .then(res => setUser(res))
      .catch(err => Toast.show(err.message));
  }, [userId, employmentLogs]);

  useEffect(() => {
    http()
      .get(`/users/${userId}/employment-logs`)
      .then(res => setEmploymentLogs(res))
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    if (currentUser.hasPermission('checklist_tier_1')) {
      http()
        .get(`/users/${userId}/newhire-checklist-items`)
        .then(setNewhireChecklist)
        .catch(Toast.showErr);
    }
  }, [userId, currentUser]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <EmploymentLogContext.Provider
        value={{ employmentLogs, setEmploymentLogs }}
      >
        <NewhireChecklistContext.Provider
          value={{ newhireChecklist, setNewhireChecklist }}
        >
          {props.children}
        </NewhireChecklistContext.Provider>
      </EmploymentLogContext.Provider>
    </UserContext.Provider>
  );
});
