import React from 'react';
import { Esign } from 'components';
import queryString from 'query-string';

const EsignRoute = props => {
  const token = queryString.parse(props.location.search)['document-token'];

  return (
    <div style={{ maxWidth: 1000, margin: '35px auto', padding: '0 15px' }}>
      <Esign token={token} />
    </div>
  );
};

export default EsignRoute;
