import React from 'react';
import { Toast } from 'components';
import { setUser } from 'ducks/user.duck';
import { connect } from 'react-redux';
import http from 'helpers/http.helper';
import queryString from 'query-string';

class AuthenticationWrapper extends React.Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    try {
      const urlToken = queryString.parse(window.location.search).token;

      if (urlToken) {
        window.localStorage.setItem('token', urlToken);
      }

      if (window.localStorage.getItem('token')) {
        const user = await http().get('/users/me');
        await this.props.setUser(user);
      }
    } catch (err) {
      Toast.show(err.message);
      window.localStorage.removeItem('token');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) return null;
    return this.props.children;
  }
}

export default connect(null, { setUser })(AuthenticationWrapper);
