import http from 'helpers/http.helper';
import { useCallback } from 'react';

export default function useShiftApi() {
  return {
    shiftDays: {
      create: useCallback(
        shift =>
          http().post(`/shift-days`, {
            taskId: shift.taskId,
            projectId: shift.projectId,
            date: shift.date,
            start: shift.start,
            end: shift.end,
            notes: shift.notes,
            approved: shift.approved,
            workOrderId: shift.workOrderId,
            lunchStart: shift.lunchStart,
            lunchDurationMinutes: shift.lunchDurationMinutes
          }),
        []
      ),
      destroy: useCallback(id => http().delete(`/shift-days/${id}`), []),
      update: useCallback(
        shift =>
          http().put(`/shift-days/${shift.id}`, {
            taskId: shift.taskId,
            date: shift.date,
            start: shift.start,
            end: shift.end,
            notes: shift.notes,
            approved: shift.approved,
            workOrderId: shift.workOrderId,
            lunchStart: shift.lunchStart,
            lunchDurationMinutes: shift.lunchDurationMinutes
          }),
        []
      ),
      copyToNextWeek: useCallback(
        shifts =>
          http().post(`/shift-days/copy-to-next-week`, {
            shiftIds: shifts.map(shift => shift.id)
          }),
        []
      )
    },
    shiftDayUsers: {
      create: useCallback(
        shiftUser =>
          http().post(`/shift-day-users`, {
            shiftDayId: shiftUser.shiftDayId,
            userId: shiftUser.userId,
            adjustedStart: shiftUser.adjustedStart,
            adjustedEnd: shiftUser.adjustedEnd,
            notes: shiftUser.notes,
            approved: shiftUser.approved
          }),
        []
      ),
      destroy: useCallback(id => http().delete(`/shift-day-users/${id}`), []),
      update: useCallback(
        shiftUser =>
          http().put(`/shift-day-users/${shiftUser.id}`, {
            shiftDayId: shiftUser.shiftDayId,
            adjustedStart: shiftUser.adjustedStart,
            adjustedEnd: shiftUser.adjustedEnd,
            notes: shiftUser.notes,
            approved: shiftUser.approved
          }),
        []
      )
    }
  };
}
