import React, { useState, useEffect } from 'react';
import { Toast, DragAndDrop } from 'components';
import { Typography, Chip, IconButton } from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import AddPermissionLevelIcon from '@material-ui/icons/AddCircleOutline';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { setPermissionLevels } from 'ducks/permissionLevels.duck';
import CreatePermissionLevelModal from '../modals/CreatePermissionLevel.modal';
import EditPermissionLevelModal from '../modals/EditPermissionLevel.modal';

const JobTitles = props => {
  const { permissionLevels, setPermissionLevels } = props;
  const [permissions, setPermissions] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    http()
      .get('/permission-levels/permissions')
      .then(res => setPermissions(res))
      .catch(e => Toast.show(e.message));
  }, []);

  const handleDrop = permissionLevels => {
    setPermissionLevels(permissionLevels);

    Promise.all(
      permissionLevels.map((permissionLevel, idx) =>
        http().put('/permission-levels/' + permissionLevel.id, {
          order: idx
        })
      )
    )
      .then(() => Toast.show('Updated.'))
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      <EditPermissionLevelModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        permissionLevel={selected}
        permissions={permissions}
        onDelete={id =>
          setPermissionLevels(
            permissionLevels.filter(
              permissionLevel => permissionLevel.id !== id
            )
          )
        }
        onUpdate={updated =>
          setPermissionLevels(
            permissionLevels.map(permissionLevel =>
              permissionLevel.id === updated.id ? updated : permissionLevel
            )
          )
        }
      />

      <CreatePermissionLevelModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        permissionLevel={selected}
        permissions={permissions}
        onCreate={permissionLevel =>
          setPermissionLevels([permissionLevel].concat(permissionLevels))
        }
      />

      <Typography component="h1" variant="h5" gutterBottom>
        Jobs Titles
        <IconButton
          color="primary"
          style={{ marginLeft: 10, padding: 0 }}
          onClick={() => setCreateModalOpen(true)}
        >
          <AddPermissionLevelIcon />
        </IconButton>
      </Typography>

      <DragAndDrop onDrop={handleDrop}>
        {permissionLevels.map(permissionLevel => (
          <div
            id={permissionLevel.id}
            key={permissionLevel.id}
            value={permissionLevel}
            style={{ display: 'flex', width: '100%', position: 'relative' }}
          >
            <IconButton
              onClick={() => {
                setEditModalOpen(true);
                setSelected(permissionLevel);
              }}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <EditIcon color="action" fontSize="small" />
            </IconButton>

            <div style={{ marginRight: 16, width: '33.3%', flexShrink: 0 }}>
              <Typography>{permissionLevel.name}</Typography>
              <Typography variant="caption">
                {permissionLevel.tier2 ? 'Tier 2' : 'Tier 1'}
              </Typography>
            </div>

            <div>
              {permissions
                .filter(permission =>
                  permissionLevel.permissions.includes(permission.value)
                )
                .map(permission => (
                  <Chip
                    key={permission.value}
                    label={permission.name}
                    style={{ margin: 2.5 }}
                  />
                ))}
            </div>
          </div>
        ))}
      </DragAndDrop>
    </div>
  );
};

export default connect(
  state => ({ permissionLevels: state.permissionLevels }),
  { setPermissionLevels }
)(JobTitles);
