import React, { useState, useEffect, useCallback } from 'react';
import {
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  DialogActions,
  TextField,
  Link
} from 'helpers/themeSafeMui.helper';
import { DateTimePicker } from '@material-ui/pickers';

import http from 'helpers/http.helper';
import { SearchDropdown, CustomInput, AddressPredict } from 'components';
import { Toast, VirtualizedSearch } from 'components';
export default function CreateWorkOrder(props) {
  const { onClose, projectId, refresh } = props;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [people, setPeople] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});

  const [projectScope, setProjectScope] = useState('');
  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [name, setName] = useState('');
  const [jobsiteAddress, setJobsiteAddress] = useState('');
  const [onSiteContact, setOnSiteContact] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const [enterManually, setEnterManually] = useState(false);

  const extractContact = useCallback(
    team => ({
      name: team.customerOnSiteContactName || '',
      phone:
        (team.customerOnSiteContactPhoneNumbers &&
          team.customerOnSiteContactPhoneNumbers[0] &&
          team.customerOnSiteContactPhoneNumbers[0].phoneNumber) ||
        '',
      email:
        (team.customerOnSiteContactEmails &&
          team.customerOnSiteContactEmails[0] &&
          team.customerOnSiteContactEmails[0].email) ||
        ''
    }),
    []
  );

  useEffect(() => {
    const setDefaultLeadAndContact = users => {
      return http()
        .get(`/projects/${projectId}/teams`)
        .then(res => {
          if (res.leadTechnicianId) {
            setAssignedUser(
              users.find(user => user.id === res.leadTechnicianId)
            );
          }
          if (res.customerOnSiteContactId) {
            setOnSiteContact(extractContact(res));
          }
        });
    };

    http()
      .get(`/people`)
      .then(res => {
        setPeople(res);
      });
    http()
      .get('/users')
      .then(users => {
        setUsers(users);
        return setDefaultLeadAndContact(users);
      })
      .catch(err => Toast.show(err.message));
    http()
      .get(`/projects/${projectId}`)
      .then(project => setJobsiteAddress(project.address || ''))
      .catch(err => Toast.show(err.message));
    http()
      .get(`/projects/${projectId}/details`)
      .then(setProjectDetails)
      .catch(err => Toast.show(err.message));
  }, [projectId, extractContact]);

  const save = async e => {
    e.preventDefault();
    setLoading(true);

    await createOnsiteContact(projectId, onSiteContact);

    http()
      .post(`/projects/${projectId}/work-orders`, {
        name,
        scheduledDateTime: scheduledDateTime,
        assignedUserId: assignedUser.id,
        projectScope: projectScope,
        jobsiteAddress,
        onSiteContactName: onSiteContact.name,
        onSiteContactPhone: onSiteContact.phone,
        onSiteContactEmail: onSiteContact.email
      })
      .then(() => {
        Toast.show('Work order created.');
        refresh();
        onClose();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>
        Create New Work Order
      </DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Work Order Name"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ marginTop: 16 }}>
              <AddressPredict
                required
                label="Job Site Address"
                onChange={jobsiteAddress => setJobsiteAddress(jobsiteAddress)}
                value={jobsiteAddress}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Assigned Technician"
              itemToString={item => item.name}
              items={users}
              value={assignedUser}
              onChange={user => setAssignedUser(user)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            {enterManually ? (
              <>
                <CustomInput
                  label="Job Site Contact Name"
                  value={onSiteContact.name}
                  onChange={name => setOnSiteContact(old => ({ ...old, name }))}
                  required
                />
                <CustomInput
                  label="Job Site Contact Phone Number"
                  value={onSiteContact.phone}
                  onChange={phone =>
                    setOnSiteContact(old => ({ ...old, phone }))
                  }
                  required
                />
                <CustomInput
                  label="Job Site Contact Email"
                  value={onSiteContact.email}
                  onChange={email =>
                    setOnSiteContact(old => ({ ...old, email }))
                  }
                />
              </>
            ) : (
              <>
                <VirtualizedSearch
                  label={'Job Site Contact Name'}
                  allItems={people}
                  getItemText={person => person.name}
                  onChange={value =>
                    setOnSiteContact({
                      name: value.name,
                      phone: value.phoneNumbers?.length
                        ? value.phoneNumbers[0].phoneNumber
                        : '',
                      email: value.emails?.length ? value.emails[0].email : ''
                    })
                  }
                />
                <Button
                  style={{ fontSize: '12px', marginTop: 20 }}
                  onClick={() => setEnterManually(true)}
                >
                  Enter Contact info manually
                </Button>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Project Scope"
              variant="outlined"
              multiline
              fullWidth
              rows="4"
              value={projectScope}
              onChange={e => setProjectScope(e.target.value)}
              margin="normal"
              required
            />
            {projectDetails.scopeOfWork && (
              <Link
                onClick={() => setProjectScope(projectDetails.scopeOfWork)}
                style={{ cursor: 'pointer' }}
              >
                Copy Scope From Project
              </Link>
            )}
          </Grid>

          <Grid item xs={6}>
            <DateTimePicker
              format="MM/DD/YYYY hh:mm a"
              autoOk
              label="Scheduled Date and Time"
              required
              fullWidth
              margin="dense"
              value={scheduledDateTime}
              onChange={scheduledDateTime =>
                setScheduledDateTime(scheduledDateTime)
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          Create
        </Button>
      </DialogActions>
    </form>
  );
}

async function createOnsiteContact(projectId, contact) {
  try {
    const projectTeam = await http().get(`/projects/${projectId}/teams`);
    const project = await http().get(`/projects/${projectId}`);
    if (projectTeam.customerOnSiteContactId) return;
    const peopleData = {
      name: contact.name,
      companyId: project.companyId
    };
    if (contact.phone)
      peopleData.phoneNumbers = [
        {
          type: 'work',
          phoneNumber: contact.phone
        }
      ];
    if (contact.email)
      peopleData.emails = [
        {
          type: 'work',
          email: contact.email
        }
      ];
    const person = await http().post('/people', peopleData);
    const updatedTeam = await http().put(`/projects/${projectId}/teams`, {
      customerOnSiteContactId: person.id
    });
    return updatedTeam;
  } catch (err) {
    return;
  }
}
