import React from 'react';
import { connect } from 'react-redux';
import { DashboardWrapper } from 'components';
import { Grid } from 'helpers/themeSafeMui.helper';
import DashboardLinks from './components/DashboardLinks.component';
import FavoriteProjectDisplay from './components/FavoriteProjectDisplay.component';
import Announcements from './components/Announcements.component';
import ActionItemsHome from './components/ActionItemsHome.component';

function Home({ user, favoriteProjects }) {
  return (
    <DashboardWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardLinks />
        </Grid>

        <Grid item xs={12} md={6}>
          <FavoriteProjectDisplay favoriteProjects={favoriteProjects} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ActionItemsHome user={user} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Announcements />
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
}

export default connect(state => ({
  user: state.user,
  favoriteProjects: state.favoriteProjects
}))(Home);
