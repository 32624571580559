function promiseAllProgress(
  promiseArray: Promise<any>[],
  onProgress: (statuses: boolean[], completeCount: number) => void
) {
  const statuses: boolean[] = Array(promiseArray.length).fill(false);
  let completeCount = 0;
  promiseArray.forEach((p, idx) => {
    p.then(arg => {
      statuses[idx] = true;
      completeCount++;
      onProgress(statuses, completeCount);
      return arg;
    });
  });
  return Promise.all(promiseArray);
}

export default promiseAllProgress;
