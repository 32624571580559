import React, { useRef, useState, useEffect } from 'react';
import { IconButton, Typography } from 'helpers/themeSafeMui.helper';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import toCurrency from 'helpers/currency.helper';
import { useSelector } from 'react-redux';
import { TooltipTable } from '../';
import moment from 'moment';
import NoStyleLink from 'components/NoStyleLink/NoStyleLink.component';

function CompactProjectDisplayTile({
  handleExpand = () => {},
  statusColor = null,
  handleExpandArg = undefined,
  project = null,
  compact = true,
  showTooltip = true,
  link = false,
  extendPath = ''
}) {
  const user = useSelector(state => state.user);
  const [showActionButton, setShowActionButton] = useState(false);
  const textRefName = useRef(null);
  const textRefCompany = useRef(null);

  useEffect(() => {
    const isOverflowing = ref =>
      ref.current && ref.current.scrollWidth > ref.current.clientWidth;

    if (!compact) return setShowActionButton(true);

    if (isOverflowing(textRefName)) {
      setShowActionButton(true);
    }
    if (isOverflowing(textRefCompany)) {
      setShowActionButton(true);
    }
  }, [compact]);

  const textStyle = {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: compact ? 'nowrap' : null,
    textOverflow: compact ? 'ellipsis' : null
  };

  const projectManagerName =
    (project.projectManagerName || '').trim() || 'none';
  const leadTechnicianName =
    (project.leadTechnicianName || '').trim() || 'none';

  const toolTipWrap = element =>
    showTooltip ? (
      <TooltipTable
        grid={[
          ['PM:', projectManagerName],
          ['LT:', leadTechnicianName]
        ]}
        placement={'left'}
      >
        {element}
      </TooltipTable>
    ) : (
      element
    );

  extendPath = makeValidExtendPath(extendPath);
  const linkPath = `/projects/${project.id}` + extendPath;

  const borderLeft = statusColor && `3px solid ${statusColor}`;

  return toolTipWrap(
    React.createElement(
      link ? NoStyleLink : 'div',
      {
        style: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 8,
          width: '100%',
          position: 'relative',
          borderLeft
        },
        to: link ? linkPath : undefined
      },

      <div style={{ width: '90%' }}>
        <Typography ref={textRefName} style={textStyle}>{`${
          project.projectNumber
        } - ${project.name || project.projectName}`}</Typography>
        <Typography
          ref={textRefCompany}
          style={textStyle}
          color="textSecondary"
          variant="body2"
        >
          {project.companyName}
        </Typography>
        {user.hasPermission('projects_tier_2') && (
          <Typography style={textStyle} color="textSecondary" variant="body2">
            {toCurrency(project.dollarValue)}
          </Typography>
        )}
      </div>,

      <div
        style={{
          width: '10%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {handleExpand && showActionButton ? (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleExpand(
                handleExpandArg === undefined ? project : handleExpandArg,
                e
              );
            }}
            size="small"
          >
            {compact ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        ) : (
          <MoreIcon color="action" />
        )}
      </div>,

      project && project.created && (
        <div style={{ position: 'absolute', right: 15, bottom: 5 }}>
          <Typography variant="caption" color="textSecondary">
            {project.bidDueDate
              ? `Bid Date: ${moment(project.bidDueDate).format('l')}`
              : `Created: ${moment(project.created).format('l')}`}
          </Typography>
        </div>
      )
    )
  );
}

function makeValidExtendPath(input) {
  if (typeof input !== 'string') return '';
  if (input === '') return input;
  if (input[0] === '/') return input;
  return '/' + input;
}

export default React.memo(CompactProjectDisplayTile);
