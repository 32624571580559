import React from 'react';
import { CustomInput } from 'components';
import { Typography } from 'helpers/themeSafeMui.helper';

const QuickBooksImport = ({ categories, setCategories }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: 16,
        border: 'solid 1px #ccc',
        borderRadius: 16,
        padding: 16,
        margin: '16px 0'
      }}
    >
      <div style={{ gridColumn: '1 / 5' }}>
        <Typography variant="h6">Map Categories</Typography>
        <Typography variant="caption">
          Any categories assigned to "materials" or "labor" will be added
          together to get total labor and material costs.
        </Typography>
      </div>

      {categories.map(category => (
        <CustomInput
          key={category.name}
          margin="dense"
          type="dropdown"
          label={category.name}
          value={category.value}
          onChange={value =>
            setCategories(
              categories.map(c =>
                c.name === category.name ? { ...c, value } : c
              )
            )
          }
          options={[
            {
              value: 'ignore',
              label: 'Ignore'
            },
            {
              value: 'materials',
              label: 'Materials'
            },
            {
              value: 'labor',
              label: 'Labor'
            }
          ]}
        />
      ))}
    </div>
  );
};

export default QuickBooksImport;
