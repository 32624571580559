import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'ducks/modal.duck';
import { Dialog } from 'helpers/themeSafeMui.helper';

import CreateActionItem from './CreateActionItem/CreateActionItem.modal';
import ErrorModal from './Error/Error.modal';
import CreateProject from './CreateProject/CreateProject.modal';
import CreatePerson from './CreatePerson/CreatePerson.modal';
import CreateWorkOrder from './CreateWorkOrder/CreateWorkOrder.modal';
import CreateTrainingGroup from './CreateTrainingGroup/CreateTrainingGroup.modal';
import CreateTrainingModule from './CreateTrainingModule/CreateTrainingModule.modal';
import CreateWeeklyCheckIn from './CreateWeeklyCheckIn/CreateWeeklyCheckIn.modal';
import EditTrainingModule from './EditTrainingModule/EditTrainingModule.modal';
import EditTrainingGroup from './EditTrainingGroup/EditTrainingGroup.modal';
import CreateProjectStatus from './CreateProjectStatus/CreateProjectStatus.modal';
import EditProjectStatus from './EditProjectStatus/EditProjectStatus.modal';
import TerminateUser from './TerminateUser/TerminateUser.modal';
import EditWeeklyCheckIn from './EditWeeklyCheckIn/EditWeeklyCheckIn.modal';
import ReactivateUser from './ReactivateUser/ReactivateUser.modal';
import ViewEditEsign from './ViewEditEsign/ViewEditEsign.modal';
import WorkOrderSignOff from './WorkOrderSignOff/WorkOrderSignOff.modal';
import AddWorkOrderMaterial from './AddWorkOrderMaterials/AddWorkOrderMaterials.component';
import AddWorkOrderLabors from './AddWorkOrderLabors/AddWorkOrderLabors.modal';
import SignatureModal from './SignatureModal/SignatureModal.modal';
import CreateAndSendPDF from './CreateAndSendPDF/CreateAndSendPDF.modal';
import EditWorkOrderModal from './EditWorkOrder/EditWorkOrder.modal';
import EmailWorkOrderModal from './EmailWorkOrder/EmailWorkOrder.modal';
import SendEmailModal from './SendEmail/SendEmail.modal';

class GlobalModal extends React.Component {
  _renderInnerModal = type => {
    const props = {
      onClose: this.props.closeModal,
      ...this.props.modalProps
    };

    switch (type) {
      case 'ADD_WORK_ORDER_LABORS':
        return <AddWorkOrderLabors {...props} />;
      case 'ADD_WORK_ORDER_MATERIAL':
        return <AddWorkOrderMaterial {...props} />;
      case 'CREATE_ACTION_ITEM':
        return <CreateActionItem {...props} />;
      case 'CREATE_PERSON':
        return <CreatePerson {...props} />;
      case 'CREATE_PROJECT':
        return <CreateProject {...props} />;
      case 'CREATE_WORK_ORDER':
        return <CreateWorkOrder {...props} />;
      case 'CREATE_TRAINING_GROUP':
        return <CreateTrainingGroup {...props} />;
      case 'CREATE_TRAINING_MODULE':
        return <CreateTrainingModule {...props} />;
      case 'CREATE_PROJECT_STATUS':
        return <CreateProjectStatus {...props} />;
      case 'EDIT_TRAINING_MODULE':
        return <EditTrainingModule {...props} />;
      case 'EDIT_TRAINING_GROUP':
        return <EditTrainingGroup {...props} />;
      case 'EDIT_PROJECT_STATUS':
        return <EditProjectStatus {...props} />;
      case 'CREATE_WEEKLY_CHECK_IN':
        return <CreateWeeklyCheckIn {...props} />;
      case 'EDIT_WEEKLY_CHECK_IN':
        return <EditWeeklyCheckIn {...props} />;
      case 'USER_TERMINATION':
        return <TerminateUser {...props} />;
      case 'USER_REACTIVATION':
        return <ReactivateUser {...props} />;
      case 'VIEW_EDIT_ESIGN':
        return <ViewEditEsign {...props} />;
      case 'WORK_ORDER_SIGN_OFF':
        return <WorkOrderSignOff {...props} />;
      case 'CAPTURE_SIGNATURE':
        return <SignatureModal {...props} />;
      case 'CREATE_AND_SEND_PDF':
        return <CreateAndSendPDF {...props} />;
      case 'EDIT_WORK_ORDER':
        return <EditWorkOrderModal {...props} />;
      case 'EMAIL_WORK_ORDER':
        return <EmailWorkOrderModal {...props} />;
      case 'SEND_EMAIL':
        return <SendEmailModal {...props} />;
      default:
        return <ErrorModal {...props} />;
    }
  };

  render() {
    const { fullScreen, size, modalType, closeModal } = this.props;

    return (
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth={size || 'sm'}
        open={Boolean(modalType)}
        onClose={closeModal}
        scroll="body"
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        {modalType ? this._renderInnerModal(modalType) : ''}
      </Dialog>
    );
  }
}

export default connect(
  state => ({
    modalType: state.modal.type,
    modalProps: state.modal.props,
    size: state.modal.size,
    fullScreen: state.modal.fullScreen
  }),
  { closeModal: closeModal }
)(GlobalModal);
