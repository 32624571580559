import React, { useEffect, useState, useCallback } from 'react';
import { useCanvasUtils } from 'hooks/useCanvasUtils.hook';
import { Button, Typography, Divider } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import StatusMapItemModal from '../modals/StatusMapItem.modal';
import StatusMapColumn from './StatusMapColumn.component';
import { makeArrowCoordinates } from '../helpers/makeArrowCoordinates.helper';

const rowHeight = 60;
const rowMargin = 8;

const DToolsStatusMap = () => {
  const [statusMapItems, setStatusMapItems] = useState([]);
  const [modalItem, setModalItem] = useState(null);
  const [sourceItem, setSourceItem] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const { canvasRef, canvasUtils } = useCanvasUtils(100, canvasHeight);

  useEffect(() => {
    canvasUtils.arrow(200, 200, 10, 10);
  }, [canvasUtils]);

  useEffect(() => {
    http()
      .get('/d-tools/status-map-items')
      .then(items => {
        setStatusMapItems(items.sort((a, b) => a.order - b.order));
      })
      .catch(err => Toast.show(err.message));
  }, []);

  const draw = useCallback(() => {
    const arrowCoordinates = makeArrowCoordinates({
      rowHeight,
      rowMargin,
      rowCount: statusMapItems.length,
      canvasWidth: 100
    });
    canvasUtils.clear();
    statusMapItems.forEach(item => {
      const originIdKey = item.fromDtools ? 'dToolsName' : 'statusId';
      const destinationIdKey = item.fromDtools ? 'statusId' : 'dToolsName';
      if (!item[originIdKey] || !item[destinationIdKey]) return;
      const originSideKey = item.fromDtools ? 'right' : 'left';
      const destinationSideKey = item.fromDtools ? 'left' : 'right';

      const originCoords = arrowCoordinates[originSideKey][item.order].out;
      const destinationItem = statusMapItems.find(
        otherItem =>
          otherItem[destinationIdKey] === item[destinationIdKey] &&
          otherItem.id !== item.id &&
          otherItem.fromDtools !== item.fromDtools
      );
      const destinationCoords =
        arrowCoordinates[destinationSideKey][destinationItem.order].in;

      canvasUtils.arrow(
        originCoords.x,
        originCoords.y,
        destinationCoords.x,
        destinationCoords.y
      );
    });
  }, [statusMapItems, canvasUtils]);

  useEffect(() => {
    draw();
  }, [canvasHeight, draw]);

  useEffect(() => {
    let leftHeight = 0;
    let rightHeight = 0;
    const addAmount = rowHeight + rowMargin;
    statusMapItems.forEach(item => {
      if (item.fromDtools) {
        rightHeight += addAmount;
      } else {
        leftHeight += addAmount;
      }
    });
    setCanvasHeight(Math.max(leftHeight, rightHeight));
  }, [statusMapItems]);

  const onItemClick = item => {
    if (!sourceItem) return setSourceItem(item);
    if (sourceItem.fromDtools === item.fromDtools) return setSourceItem(null);
    setPath(sourceItem, item);
    setSourceItem(null);
  };

  const setPath = async (source, destination) => {
    const destKey = source.fromDtools ? 'statusId' : 'dToolsName';
    await update({ ...source, [destKey]: destination[destKey] });
  };

  const update = item => {
    const copy = { ...item };
    delete copy.id;
    delete copy.archived;
    delete copy.created;
    return http()
      .put(`/d-tools/status-map-items/${item.id}`, copy)
      .then(updated =>
        setStatusMapItems(items =>
          items
            .map(oldItem => (oldItem.id !== item.id ? oldItem : updated))
            .sort((a, b) => a.order - b.order)
        )
      )
      .catch(err => Toast.show(err.message));
  };

  const create = item => {
    const copy = { ...item };
    delete copy.id;
    return http()
      .post('/d-tools/status-map-items', copy)
      .then(created => {
        const ordered = setOrders([...statusMapItems, created]);
        setStatusMapItems(ordered);
        ordered.forEach(update);
      })
      .catch(err => Toast.show(err.message));
  };

  const clearItem = async item => {
    const endsHereKey = item.fromDtools ? 'dToolsName' : 'statusId';
    const startsFromKey = !item.fromDtools ? 'dToolsName' : 'statusId';
    const endOnItem = statusMapItems.filter(
      otherItem =>
        otherItem.id !== item.id &&
        otherItem.fromDtools !== item.fromDtools &&
        otherItem[endsHereKey] === item[endsHereKey]
    );
    const updated = await Promise.all(
      endOnItem.map(item => {
        const copy = { ...item };
        delete copy.id;
        delete copy.archived;
        delete copy.created;
        copy[endsHereKey] = null;
        return http().put(`/d-tools/status-map-items/${item.id}`, copy);
      })
    );
    setStatusMapItems(items =>
      items.map(
        oldItem => updated.find(({ id }) => oldItem.id === id) || oldItem
      )
    );
    return update({ ...item, [startsFromKey]: null });
  };

  const removeItem = async item => {
    try {
      await clearItem(item);
      await http().delete(`/d-tools/status-map-items/${item.id}`);
      setStatusMapItems(items => {
        const ordered = setOrders(
          items.filter(otherItem => otherItem.id !== item.id)
        );
        ordered.forEach(update);
        return ordered;
      });
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const onDrop = items => {
    const dropped = setOrders(items);
    setStatusMapItems(old => {
      const updated = old
        // update order
        .map(item => dropped.find(({ id }) => item.id === id) || item)
        // sort by order
        .sort((a, b) => a.order - b.order);
      updated.forEach(update);
      return updated;
    });
  };

  const setOrders = items => {
    const left = items.filter(item => !item.fromDtools);
    const right = items.filter(item => item.fromDtools);
    return [
      ...left.map((item, idx) => ({ ...item, order: idx })),
      ...right.map((item, idx) => ({ ...item, order: idx }))
    ];
  };

  return (
    <div>
      <StatusMapItemModal
        open={!!modalItem}
        onClose={() => setModalItem(null)}
        status={modalItem}
        onSubmit={create}
      />
      <div style={{ paddingTop: 32 }} />
      <Divider />
      <div style={{ paddingTop: 32 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 10
        }}
      >
        <div>
          <Typography gutterBottom variant="h5" component="div">
            STEPS Statuses
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setModalItem({
                fromDtools: false,
                statusId: '',
                dToolsName: '',
                order: 0
              })
            }
          >
            New steps status
          </Button>
        </div>
        <div>
          <Typography gutterBottom variant="h5" component="div" align="right">
            SI Statuses
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setModalItem({
                fromDtools: true,
                statusId: '',
                dToolsName: '',
                order: 0
              })
            }
          >
            New SI status
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 1 50%' }}>
          <StatusMapColumn
            mapItems={statusMapItems.filter(({ fromDtools }) => !fromDtools)}
            onDrop={onDrop}
            onFocus={onItemClick}
            focusedId={sourceItem && sourceItem.id}
            rowHeight={60}
            clearItem={clearItem}
            removeItem={removeItem}
          />
        </div>
        <canvas ref={canvasRef} />
        <div style={{ flex: '0 1 50%' }}>
          <StatusMapColumn
            mapItems={statusMapItems.filter(({ fromDtools }) => fromDtools)}
            onDrop={onDrop}
            onFocus={onItemClick}
            focusedId={sourceItem && sourceItem.id}
            rowHeight={60}
            clearItem={clearItem}
            removeItem={removeItem}
          />
        </div>
      </div>
    </div>
  );
};

export default DToolsStatusMap;
