import http from 'helpers/http.helper';
import React, { useState, useEffect, createContext } from 'react';
import { Toast } from 'components';

const UsersContext = createContext({ inContext: false });

const Provider = ({ children }) => {
  const [users, setUsers] = useState([]);
  return (
    <UsersContext.Provider value={{ users, setUsers, inContext: true }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = (includeInactive = false) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    http()
      .get('/users')
      .then(users =>
        setUsers(
          users.filter(user =>
            includeInactive ? true : user.employmentStatus !== 'Inactive'
          )
        )
      )
      .catch(err => Toast.show(err.message));
  }, [includeInactive]);

  return { users, Provider };
};
