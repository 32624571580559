import React from 'react';
import { AppBar, Tabs, Tab } from 'helpers/themeSafeMui.helper';
import { Link, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const AppBarRouter = props => {
  const { routes, location, routeProps, user, noPad } = props;

  return (
    <React.Fragment>
      <AppBar position="static" color="secondary">
        <Tabs
          indicatorColor="primary"
          value={location.pathname}
          variant="scrollable"
          TabIndicatorProps={{ style: { height: 6 } }}
          scrollButtons="auto"
        >
          {routes
            .filter(
              route => !route.permission || user.hasPermission(route.permission)
            )
            .map(route => (
              <Tab
                style={{
                  fontSize: 12,
                  minWidth: 20,
                  textDecoration: 'none'
                }}
                key={route.name}
                component={Link}
                to={route.path}
                value={route.path}
                label={route.name}
              />
            ))}
        </Tabs>
      </AppBar>

      <div style={{ padding: noPad ? '24px 0' : 24 }}>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.name}
              path={route.path}
              exact
              render={routerProps => (
                <route.Component {...routerProps} {...routeProps} />
              )}
            />
          ))}
          <Redirect to="/404" />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default withRouter(
  connect(state => ({ user: state.user }))(AppBarRouter)
);
