import { useState, useEffect } from 'react';
import http from './http.helper';
import { Toast } from 'components';

const VersionWrapper = ({ children }) => {
  const [versionIsSet, setVersionIsSet] = useState(false);

  useEffect(() => {
    window.sessionStorage.removeItem('stepsVersion');
    http()
      .get('/version')
      .then(version => {
        window.sessionStorage.setItem('stepsVersion', version);
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setVersionIsSet(true));
  }, []);

  return versionIsSet ? children : null;
};

export default VersionWrapper;
