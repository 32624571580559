import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox
} from 'helpers/themeSafeMui.helper';

const CheckList = ({
  allItems,
  currentIds,
  saveGroup,
  stateKey,
  getId,
  getName,
  blur
}) => {
  const [stateIds, setStateIds] = useState(currentIds);

  const check = id => () => {
    const copy = [...stateIds];
    const idx = copy.indexOf(id);
    if (idx < 0) {
      copy.push(id);
    } else {
      copy.splice(idx, 1);
    }
    setStateIds(copy);
  };

  const save = () => {
    saveGroup({ [stateKey]: stateIds }).then(() => {
      blur();
    });
  };

  return (
    <>
      <FormGroup style={{ padding: 10 }} row>
        {allItems.map(item => (
          <FormControlLabel
            key={getId(item)}
            control={
              <Checkbox
                checked={stateIds.includes(getId(item))}
                onChange={check(getId(item))}
                color="primary"
              />
            }
            label={getName(item)}
          />
        ))}
        <div style={{ width: '100%', marginTop: 10 }}>
          <Button color="primary" onClick={save} variant="contained">
            Save
          </Button>
          <Button
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={blur}
            variant="contained"
          >
            Cancel
          </Button>
        </div>
      </FormGroup>
    </>
  );
};

export default CheckList;
