import { IconButton, Paper, Typography } from 'helpers/themeSafeMui.helper';
import { NoItemsResult, Toast } from 'components';
import { useColors } from 'helpers/theme.helper';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DateGrid from './DateGrid.component';
import http from 'helpers/http.helper';
import { v4 as uuid } from 'uuid';
import AddIcon from '@material-ui/icons/Add';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const TaskTableSelector = ({
  project,
  projectScheduleTasks,
  onSelect,
  loading = false
}) => {
  const colors = useColors();
  const [labels, setLabels] = useState([]);
  const [todayXpos, setTodayXpos] = useState(null);
  const containerRef = useRef(null);
  const createDefaultLabels = useCreateDefaultLabels();

  useEffect(() => {
    if (project.id)
      http()
        .get(`/projects/${project.id}/schedule-labels`)
        .then(labels =>
          labels.length ? labels : createDefaultLabels(project.id)
        )
        .then(setLabels)
        .catch(Toast.showErr);
  }, [project.id, createDefaultLabels]);

  const onClick = task => () => {
    if (!loading) onSelect(task);
  };

  useEffect(() => {
    if (containerRef.current && todayXpos)
      containerRef.current.scrollTo(todayXpos, 0);
  }, [todayXpos]);

  const noTasks = !projectScheduleTasks.length;
  const rowHeight = 26;

  const minWeek = projectScheduleTasks.reduce((minWeek, task) => {
    if (!minWeek) return moment(task.startDate).startOf('week');
    const taskStart = moment(task.startDate).startOf('week');
    if (taskStart.isBefore(minWeek)) return taskStart;
    return minWeek;
  }, null);

  const maxWeek = projectScheduleTasks.reduce((maxWeek, task) => {
    if (!maxWeek) return moment(task.endDate).endOf('week');
    const taskEnd = moment(task.endDate).endOf('week');
    if (taskEnd.isAfter(maxWeek)) return taskEnd;
    return maxWeek;
  }, null);

  const labelColors = labels.reduce(
    (acc, label) => Object.assign(acc, { [label.id]: label.color }),
    {}
  );

  const getDayTotal = dayMoment => {
    const total = projectScheduleTasks.reduce(
      (total, task) =>
        taskActiveOnDay(task, dayMoment) ? total + task.techCount : total,
      0
    );
    return total;
  };

  const getColor = (task, dayMoment) =>
    labelColors[task.labelId] && taskActiveOnDay(task, dayMoment)
      ? '#' + labelColors[task.labelId]
      : null;

  const getTextContent = (task, dayMoment) =>
    taskActiveOnDay(task, dayMoment)
      ? task.techCount === 0
        ? '-'
        : task.techCount
      : null;

  const getCellStyle = dayMoment => {
    if (moment().isSame(dayMoment, 'day'))
      return { backgroundColor: colors.paperYellow };
    return {};
  };

  return noTasks ? (
    <Paper style={{ padding: 10, margin: '16px 0' }}>
      <NoItemsResult type="tasks" />
    </Paper>
  ) : (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        display: 'flex',
        overflowX: 'auto',
        margin: '16px 0',
        maxHeight: '60vh'
      }}
    >
      <div style={{ position: 'sticky', left: 0, zIndex: 1 }}>
        <div
          style={{
            height: 79,
            backgroundColor: colors.paper,
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 2,
            border: `1px solid ${colors.grey}`,
            borderWidth: ' 0 1px 1px 0'
          }}
        />
        {projectScheduleTasks.map(task => (
          <div
            onClick={onClick(task)}
            key={task.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: colors.paper,
              paddingLeft: 5,
              paddingRight: 5,
              cursor: loading ? null : 'pointer'
            }}
          >
            <IconButton
              disabled={loading}
              style={{ padding: 0, marginRight: 5 }}
              size="small"
            >
              <AddIcon />
            </IconButton>
            <Typography
              style={{ lineHeight: rowHeight + 'px' }}
              component="div"
              noWrap
            >
              {task.taskName}
            </Typography>
          </div>
        ))}
      </div>

      <DateGrid
        stickyHeader
        firstDay={minWeek}
        lastDay={maxWeek}
        filterDayOfWeek={moment => moment.day() !== 6 && moment.day() !== 0}
        rows={projectScheduleTasks}
        rowHeight={rowHeight}
        getDayTotal={getDayTotal}
        getDayTextContent={getTextContent}
        getCellStyle={getCellStyle}
        getDayColor={getColor}
        setTodayXpos={setTodayXpos}
        cellStyle={{ padding: 2 }}
      />
    </div>
  );
};
export default TaskTableSelector;

function taskActiveOnDay(task, dayMoment) {
  const dow = dayMoment.day();

  // FRI
  if (dow === 5 && task.scheduleDaysPerWeek === 4) return false;

  // SAT
  if (dow === 6) return false;

  // SUN
  if (dow === 0) return false;

  return dayMoment.isBetween(task.startDate, task.endDate, 'day', '[]');
}

function useCreateDefaultLabels() {
  const currentUser = useCurrentUser();

  return useCallback(
    projectId => {
      if (currentUser.hasPermission('projects_tier_1.5')) {
        return createDefaultLabels(projectId);
      }
      return Promise.resolve([]);
    },
    [currentUser]
  );
}

function createDefaultLabels(projectId) {
  const defaults = [
    {
      id: uuid(),
      name: 'Blue Crew',
      color: '74B4FF'
    },
    {
      id: uuid(),
      name: 'Yellow Crew',
      color: 'F8E71C'
    },
    {
      id: uuid(),
      name: 'Green Crew',
      color: '44FF44'
    },
    {
      id: uuid(),
      name: 'Orange Crew',
      color: 'FFBB47'
    },
    {
      id: uuid(),
      name: 'Teal Crew',
      color: '3BFFFF'
    }
  ];
  return Promise.all(
    defaults.map((d, idx) =>
      http().post(`/projects/${projectId}/schedule-labels`, {
        ...d,
        order: idx
      })
    )
  );
}
