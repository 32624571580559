import React, { useState, useCallback } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';

const ToolTip = props => {
  const { value, style, children } = props;
  const [coors, setCoors] = useState(null);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const measuredRef = useCallback(node => {
    if (node) {
      setDimensions({ height: node.offsetHeight, width: node.offsetWidth });
    }
  }, []);

  if (!value) return null;

  return (
    <React.Fragment>
      {coors && (
        <div
          style={{
            background: 'rgba(0, 0, 0, 0.9)',
            position: 'fixed',
            top: coors.y,
            left: coors.x,
            padding: '4px 16px',
            borderRadius: 4,
            zIndex: 1000,
            textAlign: 'center',
            maxWidth: 250
          }}
          ref={measuredRef}
        >
          <Typography variant="caption" style={{ color: 'white' }}>
            {value}
          </Typography>

          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid rgba(0, 0, 0, 0.9)',
              position: 'absolute',
              bottom: -8,
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          />
        </div>
      )}

      <div
        style={style}
        onMouseLeave={() => setCoors(null)}
        onMouseMove={e =>
          setCoors({
            x: e.clientX - dimensions.width / 2,
            y: e.clientY - (dimensions.height + 12)
          })
        }
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default ToolTip;
