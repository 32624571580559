import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from 'helpers/themeSafeMui.helper';
import ProjectAutocomplete from '../components/ProjectAutocomplete.component';
import { ShiftModalContext } from '../context/ShiftModal.context';

export default function ProjectPickerModal({ open, onClose }) {
  const { openWithProps } = useContext(ShiftModalContext);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Select Project</DialogTitle>
      <Divider />
      <DialogContent>
        <ProjectAutocomplete
          onSelect={project => openWithProps({ projectId: project.id }, 'week')}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
