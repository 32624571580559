import React, { useState } from 'react';
import SearchFilter from './SearchFilter.component';
import StatusFilter from './StatusFilter.component';
import {
  Popover,
  Button,
  Badge,
  Typography
} from 'helpers/themeSafeMui.helper';

const FilterPopover = ({
  allPerDiems,
  setFiltered,
  defaultVisibleStatuses = ['Pending'],
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusFilteredPerDiems, setStatusFilteredPerDiems] = useState([]);
  const [statusActiveCount, setStatusActiveCount] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Badge
        overlap="rectangular"
        badgeContent={statusActiveCount + searchActive}
        color="secondary"
      >
        <Button
          variant="contained"
          color="primary"
          {...rest}
          onClick={handleClick}
        >
          Filter Items
        </Button>
      </Badge>
      <Popover
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div style={{ padding: 10 }}>
          <Typography variant="h6" gutterBottom>
            Filter Items
          </Typography>
          <StatusFilter
            setActiveCount={setStatusActiveCount}
            allPerDiems={allPerDiems}
            setFiltered={setStatusFilteredPerDiems}
            defaultStatuses={defaultVisibleStatuses}
          />
          <SearchFilter
            setIsActive={setSearchActive}
            allPerDiems={statusFilteredPerDiems}
            setFiltered={setFiltered}
            variant="outlined"
            fullWidth
          />
        </div>
      </Popover>
    </div>
  );
};
export default FilterPopover;
