import React, { useEffect, useState } from 'react';
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CustomInput } from 'components';
import { useColors } from 'helpers/theme.helper';

const ServiceItem = ({ item, onCreate, onDelete, onEdit }) => {
  const colors = useColors();
  const [editing, setEditing] = useState(false);
  const [base, setBase] = useState(item.base || 0);
  const [fringe, setFringe] = useState(item.fringe || 0);
  const title = `${item.number} - ${item.name}`;
  let secondary = item.isTemplate ? null : item.isSpecialWage ? `` : null;
  if (secondary === '') {
    const both = item.base !== null && item.fringe !== null;
    if (item.base !== null) secondary += `Base: $${item.base}`;
    if (both) secondary += ', ';
    if (item.fringe !== null) secondary += `Fringe: $${item.fringe}`;
  }
  useEffect(() => {
    setEditing(false);
    setBase(item.base || 0);
    setFringe(item.fringe || 0);
  }, [item]);

  const clickAddRemove = () => {
    if (item.isTemplate) {
      if (item.isSpecialWage) return setEditing(old => !old);
      onCreate({ ...item });
    } else {
      onDelete();
    }
  };

  const clickEditSave = () => {
    if (editing) {
      if (item.isTemplate) {
        onCreate({ ...item, base, fringe });
      } else {
        onEdit({ ...item, base, fringe });
      }
    } else {
      setEditing(true);
    }
  };

  return (
    <>
      <ListItem component="div">
        <ListItemIcon>
          {item.isTemplate ? (
            <HighlightOffIcon color="action" />
          ) : (
            <CheckCircleOutlineIcon style={{ color: colors.success }} />
          )}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: item.isTemplate ? 'textSecondary' : 'textPrimary'
          }}
          primary={title}
          secondary={secondary}
        />
        <ListItemSecondaryAction>
          {(editing || (!item.isTemplate && item.isSpecialWage)) && (
            <IconButton onClick={clickEditSave} edge="end">
              {editing ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
          {editing ? (
            <IconButton onClick={() => setEditing(false)} edge="end">
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton onClick={clickAddRemove} edge="end">
              {item.isTemplate ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={editing}>
        <div style={{ display: 'flex', padding: '0 10px' }}>
          <CustomInput
            label="base"
            type="currency"
            value={base}
            onChange={setBase}
          />
          <div style={{ padding: 5 }} />
          <CustomInput
            label="fringe"
            type="currency"
            value={fringe}
            onChange={setFringe}
          />
        </div>
      </Collapse>
    </>
  );
};

export default ServiceItem;
