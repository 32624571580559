import React, { useState } from 'react';
import { Toast } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';

function ReactivateUser(props) {
  const { user, onClose, areas, permissionLevels, setEmploymentLogs } = props;
  const [state, setState] = useState({
    startDate: null,
    employmentStatus: '',
    permissionLevel: '',
    area: '',
    wage: 0,
    salary: 0,
    notes: '',
    compensationType: ''
  });

  const handleChange = e => {
    if (e.target.name === 'compensationType') {
      setState(state => ({ ...state, wage: '', salary: '' }));
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const reactivate = e => {
    e.preventDefault();

    if (!state.permissionLevel) {
      return Toast.show('Permission Level is required.');
    }

    http()
      .post(`/users/${user.id}/employment-logs`, {
        startDate: state.startDate,
        employmentStatus: state.employmentStatus,
        permissionLevelId: state.permissionLevel.id,
        areaId: state.areaId,
        wage: state.wage || undefined,
        salary: state.salary || undefined
      })
      .then(res => {
        Toast.show('User reactivated.');
        setEmploymentLogs(res);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <form autoComplete="off" onSubmit={reactivate}>
      <DialogTitle>Reactivate User</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Employment Status</InputLabel>
              <Select
                value={state.employmentStatus}
                onChange={handleChange}
                name="employmentStatus"
              >
                <MenuItem value="Full Time">Full Time</MenuItem>
                <MenuItem value="Part Time">Part Time</MenuItem>
                <MenuItem value="Variable">Variable</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <KeyboardDatePicker
              format="MM/DD/YYYY"
              autoOk
              name="startDate"
              label="Start Date"
              required
              fullWidth
              margin="dense"
              value={state.startDate}
              onChange={startDate =>
                setState(state => ({ ...state, startDate: startDate }))
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Area</InputLabel>
              <Select
                name="areaId"
                value={state.areaId}
                onChange={handleChange}
              >
                {areas.map(area => (
                  <MenuItem key={area.id} value={area.id}>
                    {area.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Job Title</InputLabel>
              <Select
                value={state.permissionLevel}
                onChange={handleChange}
                name="permissionLevel"
              >
                {permissionLevels.map(permissionLevel => (
                  <MenuItem
                    key={permissionLevel.id}
                    value={permissionLevel}
                    disabled={permissionLevel.superior}
                  >
                    {permissionLevel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl required fullWidth margin="dense">
              <InputLabel>Salary/Hourly</InputLabel>
              <Select
                value={state.compensationType}
                onChange={handleChange}
                name="compensationType"
              >
                <MenuItem value="wage">Hourly</MenuItem>
                <MenuItem value="salary">Salary</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {state.compensationType === 'wage' && (
              <TextField
                label="Wage"
                fullWidth
                value={state.wage}
                name="wage"
                margin="dense"
                type="number"
                inputProps={{ step: '0.01' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                onChange={handleChange}
                required
              />
            )}
            {state.compensationType === 'salary' && (
              <TextField
                label="Salary"
                fullWidth
                value={state.salary}
                name="salary"
                margin="dense"
                type="number"
                inputProps={{ step: '0.01' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                onChange={handleChange}
                required
              />
            )}
          </Grid>

          <Grid item xs={12} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Reactivate
        </Button>
      </DialogActions>
    </form>
  );
}

export default connect(state => ({
  areas: state.areas,
  permissionLevels: state.permissionLevels
}))(ReactivateUser);
