import React, { useEffect, useRef, useState } from 'react';
import Joi from '@hapi/joi';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import CustomInput from 'components/CustomInput/CustomInput.component';

const FieldsSchema = Joi.array().items(
  Joi.any().allow(
    Joi.object({
      label: Joi.string().required(),
      type: Joi.string().required(),
      key: Joi.string().required(),
      customInputProps: Joi.any().optional()
    }),
    Joi.object({
      makeField: Joi.function().optional()
    })
  )
);

export default function FormModal({
  open,
  onClose,
  title,
  onSubmit,
  fields,
  data = null,
  loading = false,
  buttons
}) {
  validateFields(fields);
  const fieldsRef = useRef(fields);
  fieldsRef.current = fields;
  const [stateData, setStateData] = useState(
    data || getDefaultData(fieldsRef.current)
  );

  useEffect(() => {
    setStateData(data || getDefaultData(fieldsRef.current));
  }, [open, data]);

  const handleChange = field => newValue => {
    setStateData(old => ({ ...old, [field.key]: newValue }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {fieldsRef.current.map((field, idx) =>
          field.makeField ? (
            field.makeField({ stateData, setStateData, loading })
          ) : (
            <CustomInput
              disabled={loading}
              label={field.label}
              type={field.type}
              key={field.key}
              autoFocus={idx === 0}
              {...field.customInputProps}
              value={
                stateData[field.key] === undefined ? '' : stateData[field.key]
              }
              onChange={handleChange(field)}
            />
          )
        )}
      </DialogContent>
      <DialogActions>
        {buttons}
        <Button disabled={loading} variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={() => onSubmit(stateData)}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function getDefaultData(fields) {
  return fields.reduce(
    (data, field) => Object.assign(data, { [field.key]: '' }),
    {}
  );
}

function validateFields(fields) {
  const result = FieldsSchema.validate(fields);
  if (result.error) {
    console.error('FormModal field error:');
    console.error(result.error);
  }
  // Joi.attempt(fields, FieldsSchema, {});
}
