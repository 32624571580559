import React from 'react';
import http from 'helpers/http.helper';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Toast } from 'components';
import CreateHRUser from 'routes/AdminSettings/modals/CreateHRUser.modal';

class HumanResources extends React.Component {
  state = {
    hrUsers: [],
    createHrUserModal: false
  };

  componentDidMount() {
    this.getHumanResourcesUsers();
  }

  archive = id => {
    http()
      .delete(`/human-resources/${id}`)
      .then(() => {
        Toast.show(`user was removed from the system as an HR officer.`);
        this.getHumanResourcesUsers();
      });
  };

  getHumanResourcesUsers = () => {
    http()
      .get('/human-resources')
      .then(res => {
        this.setState({ hrUsers: res });
      })
      .catch(err => Toast.show(err.message));
  };
  render() {
    const { hrUsers } = this.state;
    return (
      <div style={{ marginTop: 32 }}>
        <CreateHRUser
          open={this.state.createHrUserModal}
          onClose={() => this.setState({ createHrUserModal: false })}
          refresh={this.getHumanResourcesUsers}
        />

        <Typography component="h1" variant="h5" gutterBottom>
          Human Resource Officers
          <IconButton
            color="primary"
            style={{ marginLeft: 10, padding: 0 }}
            onClick={() => this.setState({ createHrUserModal: true })}
          >
            <AddIcon />
          </IconButton>
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Human Resources Officer Name</TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {hrUsers.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell padding="checkbox" className="cell-shrink">
                  <IconButton onClick={() => this.archive(user.id)}>
                    <DeleteIcon color="action" fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default HumanResources;
