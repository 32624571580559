import React, { useState, useEffect, useContext, useCallback } from 'react';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectDetailsContext,
  ProjectTeamContext
} from 'routes/Projects/context/Project.context';
import {
  Typography,
  CircularProgress,
  IconButton
} from 'helpers/themeSafeMui.helper';
import {
  Rollup,
  PersonDisplay,
  Toast,
  NoItemsResult,
  UserSearch,
  CustomInput,
  ExternalLink
} from 'components';
import { useColors } from 'helpers/theme.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddPlangridProject from '../components/AddPlangridProject.compnent';

export default function PlangridCard() {
  const { project } = useContext(ProjectContext);
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [link, setLink] = useState('');
  const [editingLink, setEditingLink] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const colors = useColors();
  const [loading, setLoading] = useState(true);
  const { id: projectId } = project;

  const removeUser = user => () => {
    const { plangridUserId, id } = user;
    http()
      .delete(`/plangrid/projects/${projectId}/users/${plangridUserId}`)
      .then(() =>
        setProjectUsers(pUsers => pUsers.filter(pUser => pUser.id !== id))
      )
      .catch(err => Toast.show(err.message));
  };

  const addUser = user => {
    http()
      .post(`/plangrid/projects/${projectId}/users`, {
        plangridUserId: user.id
      })
      .then(() => {
        fetchProjectUsers();
      })
      .catch(err => Toast.show(err.message));
  };

  const fetchProjectUsers = useCallback(() => {
    setLoading(true);
    if (projectId) {
      return http()
        .get(`/plangrid/projects/${projectId}/users`)
        .then(res => {
          setProjectUsers(res);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          Toast.show(err.message);
        });
    }
  }, [projectId]);

  const getUUID = string => {
    if (!string) return null;
    const match = string.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    if (!match) return null;
    if (!match[0]) return null;
    return match[0];
  };

  const linkProject = () => {
    const plangridProjectId = getUUID(link);
    if (!plangridProjectId) return Toast.show('Invalid Plangrid link');
    setLoading(true);
    http()
      .post(`/plangrid/projects/${projectDetails.projectId}/link/`, {
        plangridProjectId
      })
      .then(() =>
        http().put(`/projects/${projectDetails.projectId}/details`, {
          plangridLink: link || ''
        })
      )
      .then(setProjectDetails)
      .then(fetchProjectUsers)
      .then(() => setEditingLink(false))
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLink(projectDetails.plangridLink);
  }, [projectDetails]);

  useEffect(() => {
    fetchProjectUsers();
  }, [fetchProjectUsers]);

  if (loading)
    return (
      <Rollup
        style={{ marginBottom: 8 }}
        title={<Typography variant="h5">Plangrid</Typography>}
        localStorageKey="project_card_plangrid"
      >
        <Typography component="h1" variant="h5" gutterBottom>
          PlanGrid Users
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      </Rollup>
    );

  if (projectUsers[0] && projectUsers[0].type === 'no_project')
    return (
      <Rollup
        style={{ marginBottom: 8 }}
        title={<Typography variant="h5">Plangrid</Typography>}
        localStorageKey="project_card_plangrid"
      >
        <AddPlangridProject />
      </Rollup>
    );

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Plangrid</Typography>}
      localStorageKey="project_card_plangrid"
    >
      <AddPlangridProject />
      <div
        style={{
          position: 'relative',
          padding: 16,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {editingLink ? (
          <CustomInput label="Link" value={link} onChange={setLink} />
        ) : link ? (
          <ExternalLink href={link}>Plangrid Link</ExternalLink>
        ) : projectDetails.plangridProjectId ? (
          <ExternalLink
            href={`https://app.plangrid.com/projects/${projectDetails.plangridProjectId}`}
          >
            Plangrid Link
          </ExternalLink>
        ) : (
          'No Project Link'
        )}
        <div>
          {editingLink ? (
            <>
              <IconButton onClick={linkProject}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={() => setEditingLink(false)}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => setEditingLink(true)}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </div>

      <Typography component="h1" variant="h5" gutterBottom>
        PlanGrid Users
      </Typography>
      <div style={{ paddingLeft: 16, paddingBottom: 16 }}>
        {!projectUsers.length && <NoItemsResult type="Plangrid Users" />}

        {projectUsers
          .filter(({ type }) => type === 'steps')
          .map(user => (
            <StepsUserCard
              key={user.id}
              user={user}
              Icon={
                <IconButton onClick={removeUser(user)}>
                  <DeleteIcon />
                </IconButton>
              }
            />
          ))}

        {projectUsers
          .filter(({ type }) => type === 'plangrid')
          .map(user => (
            <PlangridUserCard
              key={user.id}
              user={user}
              Icon={
                <IconButton onClick={removeUser(user)}>
                  <DeleteIcon />
                </IconButton>
              }
            />
          ))}
      </div>
      <Typography component="h1" variant="h5">
        Add Users
      </Typography>
      <div style={{ paddingLeft: 16, paddingBottom: 16 }}>
        <UserSearch
          filter={() => true}
          path="/plangrid/users"
          onChange={user => (user ? addUser(user) : null)}
          renderOption={user => (
            <div
              style={{
                width: '100%',
                borderBottom: `1px solid ${colors.grey}`
              }}
            >
              <StepsUserCard user={user} />
            </div>
          )}
        />
      </div>
    </Rollup>
  );
}

const StepsUserCard = ({ user, ...rest }) => {
  const { projectTeam } = useContext(ProjectTeamContext);
  let type = '';
  let email;
  if (user.id === projectTeam.accountManagerId) type = 'Account Manager';
  if (user.id === projectTeam.projectEstimatorId) type = 'Project Estimator';
  if (user.id === projectTeam.projectManagerId) type = 'Project Manager';
  if (!type) email = user.email;
  return <PersonDisplay name={user.name} type={type} email={email} {...rest} />;
};

const PlangridUserCard = ({ user, ...rest }) => {
  return (
    <PersonDisplay
      name={`${user.first_name} ${user.last_name}`}
      email={user.email}
      {...rest}
    />
  );
};
