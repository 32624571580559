import React, { useState } from 'react';
import { Typography, Popover } from 'helpers/themeSafeMui.helper';
import ScheduleTableCell from './ScheduleTableCell.component';
import ScheduleTableBar from './ScheduleTableBar.component';
import moment from 'moment';
import diffDays from '../helpers/diffDays.helper.js';
import { useColors } from 'helpers/theme.helper';
import safeParse from 'helpers/safeParse.helper';
import Color from 'color';
import getTodayIndex from '../helpers/getTodayIdx.helper';

const ScheduleTableRow = React.memo(
  ({
    project,
    startDay,
    colCount,
    firstCell = null,
    firstCellWidth = 0,
    cellWidth = 50,
    boldBorderBottom = false
  }) => {
    const [detailPos, setDetailPos] = useState(null);
    const colors = useColors();

    let padStart = true;
    let startIdx = diffDays(startDay, project.start);
    let padEnd = true;
    let span = diffDays(project.start, project.scheduleFloat) + 1;

    let validFloat = true;
    if (isNaN(span)) {
      validFloat = false;
      span = diffDays(project.start, project.end) + 1;
      if (isNaN(span)) span = 1;
    }
    if (startIdx < 0) {
      span += startIdx;
      padStart = false;
      startIdx = 0;
    }
    if (startIdx + span > colCount) {
      padEnd = false;
      span = colCount - startIdx;
    }

    const weekends = [0, 6];
    if (project.scheduleDaysPerWeek === 4) weekends.push(5);
    const modOffset = moment(startDay).day();
    const isWeekend = idx => weekends.includes((idx + modOffset) % 7);

    const onClickBar = e => {
      setDetailPos({
        top: e.clientY + 10,
        left: e.clientX
      });
    };

    const weekendBg = new Color(colors.background).alpha(0.9).hsl();
    const tech = project.estimatedCrew === 1 ? 'tech' : 'techs';
    const keyBase = moment(startDay).format('ll');
    const todayBg = colors.paperYellow;
    const todayIdx = getTodayIndex(startDay);
    const barColor =
      project.scheduleType === 'potential'
        ? colors.yellow
        : validFloat
        ? colors.primary
        : colors.error;

    return (
      <div
        style={{
          display: 'flex',
          position: 'relative',
          width: cellWidth * colCount + firstCellWidth
        }}
      >
        {firstCell}
        {Array(colCount)
          .fill(null)
          .map((day, colIdx) => (
            <ScheduleTableCell
              boldBorderBottom={boldBorderBottom}
              key={keyBase + colIdx}
              firstCol={!firstCell && colIdx === 0}
              style={{
                position:
                  startIdx === colIdx || isWeekend(colIdx) ? 'relative' : null,
                background:
                  colIdx === todayIdx
                    ? todayBg
                    : isWeekend(colIdx)
                    ? weekendBg
                    : colors.paper
              }}
              width={cellWidth}
            >
              {startIdx === colIdx && (
                <ScheduleTableBar
                  padStart={padStart}
                  padEnd={padEnd}
                  padding={12}
                  cellWidth={cellWidth}
                  cellSpan={span}
                  color={barColor}
                  onClick={onClickBar}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </ScheduleTableCell>
          ))}
        <Popover
          open={!!detailPos}
          onClose={() => setDetailPos(null)}
          anchorReference="anchorPosition"
          anchorPosition={detailPos}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div style={{ padding: 10 }}>
            <Typography variant="body1">
              {project.projectNumber} - {project.name}
            </Typography>
            <Typography variant="body1">
              Hours:{' '}
              {safeParse(project.estimatedCoLaborHours) +
                safeParse(project.estimatedLaborHours)}
            </Typography>
            <Typography variant="caption">
              {moment(project.start).format('ll')} -{' '}
              {moment(project.scheduleFloat || project.end).format('ll')}
            </Typography>
            {!!project.estimatedCrew && (
              <>
                <br />
                <Typography variant="caption">
                  {project.estimatedCrew} {tech} -{' '}
                  {parseInt(project.scheduleHrsPerDay)}hr days -{' '}
                  {project.scheduleDaysPerWeek} days per week
                </Typography>
              </>
            )}
          </div>
        </Popover>
      </div>
    );
  }
);

export default ScheduleTableRow;
