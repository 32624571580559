import React, { useState, useEffect } from 'react';
import { Button, TextField, Chip } from 'helpers/themeSafeMui.helper';
import Autocomplete from '@material-ui/lab/Autocomplete';

const UserList = ({ group, saveGroup, blur, stateKey, allUsers }) => {
  const fromGroup = allUsers
    ? group[stateKey].map(id => allUsers.find(u => u.id === id))
    : [];

  const [stateUsers, setStateUsers] = useState(fromGroup);

  const save = () => {
    const ids = stateUsers.map(u => u.id);
    saveGroup({ [stateKey]: ids }).then(() => {
      blur();
    });
  };

  useEffect(() => {
    const fromGroup = allUsers
      ? group[stateKey].map(id => allUsers.find(u => u.id === id))
      : [];
    setStateUsers(fromGroup);
  }, [group, allUsers, stateKey]);

  return (
    <div style={{ padding: 10 }}>
      <Autocomplete
        multiple
        options={allUsers}
        autoHighlight
        selectOnFocus
        value={stateUsers}
        onChange={(e, value) => {
          setStateUsers(value);
        }}
        getOptionLabel={user => user.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={params => {
          return (
            <TextField {...params} label="Users" fullWidth margin="dense" />
          );
        }}
      />
      <div>
        <Button color="primary" onClick={save} variant="contained">
          Save
        </Button>
        <Button
          color="primary"
          style={{ marginLeft: 10 }}
          onClick={blur}
          variant="contained"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UserList;
