import React from 'react';
import {
  Button,
  Typography,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, CardWrapper } from 'components';
import queryString from 'query-string';
import jwt from 'jwt-decode';
import phoneHelper from 'helpers/phone.helper';
import { withColors } from 'helpers/theme.helper';

class DrugTestLocation extends React.Component {
  state = {
    drugTestLocation: '',
    completed: false,
    acknowledged1: false,
    acknowledged2: false,
    acknowledged3: false,
    locations: []
  };

  componentDidMount() {
    http()
      .get(`/drug-test-locations`)
      .then(response => {
        this.setState({
          locations: response
        });
      })
      .catch(err => Toast.show(err.message));
  }

  submit = e => {
    e.preventDefault();

    const inviteToken = queryString.parse(this.props.location.search)
      .invite_token;
    let userId, drugTestId;
    try {
      const decoded = jwt(inviteToken);
      userId = decoded.userId;
      drugTestId = decoded.drugTestId;
    } catch (err) {
      return Toast.show('Invalid Token');
    }
    http()
      .put(`/users/${userId}/drug-tests/${drugTestId}`, {
        inviteToken: inviteToken,
        drugTestLocation: this.state.drugTestLocation.id,
        drugTestAcknowledged: new Date()
      })
      .then(() => {
        this.setState({ completed: true });
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChecked = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  _renderLocationInfo = location => {
    const colors = this.props.colors;
    return (
      <div style={{ background: colors.paper, marginBottom: 20, padding: 15 }}>
        <Typography>{location.name}</Typography>
        <Typography>{location.address}</Typography>
        {!!location.address2 && <Typography>{location.address2}</Typography>}
        <Typography>{phoneHelper(location.phone)}</Typography>
        <Typography>Hours: {location.hours}</Typography>
        <Typography>Cost: {location.cost}</Typography>
        {location.notes && (
          <>
            <Typography style={{ fontWeight: 600 }}>Notes:</Typography>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {location.notes}
            </Typography>
          </>
        )}
      </div>
    );
  };

  render() {
    const inviteToken = queryString.parse(this.props.location.search)
      .invite_token;
    const { random } = inviteToken ? jwt(inviteToken) : { random: true };

    if (this.state.completed) {
      return (
        <CardWrapper>
          <Typography variant="body1">
            Submitted successfully. The drug screening must be completed within
            the next 24 hours at the following location. A copy of this
            information will be sent to you via email.
          </Typography>
          {this._renderLocationInfo(this.state.drugTestLocation)}
        </CardWrapper>
      );
    }

    return (
      <CardWrapper>
        <form autoComplete="off" onSubmit={this.submit}>
          <Typography variant="body1">
            Select a drug screening center from the dropdown list below.
          </Typography>

          <FormControl
            required
            fullWidth
            margin="dense"
            style={{ marginBottom: 20 }}
          >
            <InputLabel>Drug Screening Location</InputLabel>
            <Select
              value={this.state.drugTestLocation}
              onChange={this.handleChange}
              name="drugTestLocation"
              required
            >
              {this.state.locations.map(location => (
                <MenuItem key={location.name} value={location}>
                  {location.area}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {this.state.drugTestLocation &&
            this._renderLocationInfo(this.state.drugTestLocation)}

          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Checkbox
                required
                checked={this.state.acknowledged1}
                onChange={this.handleChecked}
                name="acknowledged1"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography>
                I will complete the drug screening at the location selected
                above within 24 hours.
              </Typography>
            </Grid>

            {!random && (
              <React.Fragment>
                <Grid item xs={2}>
                  <Checkbox
                    required
                    checked={this.state.acknowledged2}
                    onChange={this.handleChecked}
                    name="acknowledged2"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    I understand that I, as an applicant, am financially
                    responsible for this test. If or when I am hired, I may be
                    reimbursed for this analysis.
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Checkbox
                    required
                    checked={this.state.acknowledged3}
                    onChange={this.handleChecked}
                    name="acknowledged3"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    I consent for System Tech to perform any background check
                    necessary to ensure the safety of me, my co-workers and
                    System Tech’s customers.
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </form>
      </CardWrapper>
    );
  }
}

export default withColors(DrugTestLocation);
