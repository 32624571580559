import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper
} from 'helpers/themeSafeMui.helper';
import MoreIcon from '@material-ui/icons/MoreVert';
import stable from 'stable';

class SortingTable extends React.Component {
  state = {
    order: this.props.initialOrder || 'desc',
    orderBy: this.props.initialOrderBy || this.props.columns[0].key,
    rowsPerPage: 25,
    page: 0
  };

  handleRequestSort = property => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy, page: 0 });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page, order, orderBy } = this.state;
    const { columns, rowAction } = this.props;

    const rows = stable(this.props.rows, (a, b) => {
      const column = columns.find(column => column.key === orderBy);

      if (column.customSort) {
        return order === 'asc'
          ? column.customSort(a, b)
          : !column.customSort(a, b);
      }

      const orderByA = column.extractor ? column.extractor(a) : a[orderBy];
      const orderByB = column.extractor ? column.extractor(b) : b[orderBy];

      if (order === 'asc') {
        if (!orderByA) return false;
        if (!orderByB) return true;
        return orderByA.toLowerCase() < orderByB.toLowerCase();
      } else {
        if (!orderByA) return true;
        if (!orderByB) return false;
        return orderByA.toLowerCase() > orderByB.toLowerCase();
      }
    });

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper>
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {columns.map(column => (
                  <TableCell
                    key={column.key}
                    sortDirection={orderBy === column.key ? order : false}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={order}
                      onClick={() => this.handleRequestSort(column.key)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow
                    style={{ cursor: rowAction && 'pointer' }}
                    hover={Boolean(rowAction)}
                    key={row.id}
                    onClick={() => rowAction && rowAction(row)}
                  >
                    <TableCell padding="checkbox" className="cell-shrink">
                      <MoreIcon color="action" fontSize="small" />
                    </TableCell>
                    {columns.map(column => {
                      const val = column.extractor
                        ? column.extractor(row)
                        : row[column.key];
                      return (
                        <TableCell
                          key={column.key + row.id}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {column.modifier ? column.modifier(val) : val}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columns.length + (rowAction ? 1 : 0)} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default SortingTable;
