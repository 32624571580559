import React, { useContext } from 'react';
import { TableRow, TableCell, useTheme } from 'helpers/themeSafeMui.helper';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { DraggableContext } from './DndTable.component';

function DndTableRow({ children, value, dragCellProps, ...rest }) {
  const provided = useContext(DraggableContext);
  const theme = useTheme();

  return (
    <TableRow
      {...rest}
      {...provided.draggableProps}
      innerRef={provided.innerRef}
      style={{
        background: theme.palette.background.paper,
        ...rest.style,
        ...provided.draggableProps.style
      }}
    >
      <TableCell {...dragCellProps} {...provided.dragHandleProps}>
        <DragIndicator />
      </TableCell>
      {children}
    </TableRow>
  );
}

export default DndTableRow;
