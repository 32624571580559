import { ImageUploader } from 'components';
import React, { useContext, useEffect } from 'react';
import {
  ProjectContext,
  ProjectPhotosContext,
  RefreshProjectContext
} from 'routes/Projects/context/Project.context';
import ProjectGallery from './components/Gallery.component';
import useCheckIfShouldRefetch from './hooks/useCheckIfShouldRefetch.hook';

export default function ProjectPhotosTab() {
  const { project } = useContext(ProjectContext);
  const { setPhotos, photos } = useContext(ProjectPhotosContext);
  const refreshProject = useContext(RefreshProjectContext);
  const checkIfShouldRefetch = useCheckIfShouldRefetch();

  useEffect(() => {
    let stale = false;
    checkIfShouldRefetch().then(should => {
      if (stale) return;
      if (should) {
        refreshProject();
      }
    });

    return () => {
      stale = true;
    };
  }, [checkIfShouldRefetch, refreshProject, project.id]);

  return (
    <div>
      <ProjectGallery />
      {!!photos.length && <div style={{ paddingTop: 24 }} />}
      <ImageUploader
        url={`/projects/${project.id}/photos`}
        previewType="scroll"
        onSave={uploaded => setPhotos(old => [...uploaded, ...old])}
      />
    </div>
  );
}
