import React, { useState, useContext, useEffect } from 'react';
import { Toast, DropZone, CustomInput, FilePickerButton } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import invoiceTypeOptions from '../../helpers/invoiceTypeOptions.helper';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

const CustomerPO = ({ next }) => {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [customerPO, setCustomerPO] = useState(projectDetails.customerPO || '');
  const [poDriveId, setPoDriveId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [invoiceType, setInvoiceType] = useState(projectDetails.invoiceType);
  const [tamBillableRate, setTamBillableRate] = useState(0);
  const [tamDriveRate, setTamDriveRate] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!projectDetails) return;
    setCustomerPO(projectDetails.customerPO || '');
    setInvoiceType(projectDetails.invoiceType);
    setTamBillableRate(projectDetails.tamBillableRate || 0);
    setTamDriveRate(projectDetails.tamDriveRate || 0);
    setFileId(projectDetails.customerPODriveId || null);
  }, [projectDetails]);

  const save = async e => {
    try {
      e.preventDefault();

      if (!customerPO) {
        return Toast.show('Please enter a customer PO.');
      }

      if (loading) return;
      setLoading(true);

      await http()
        .put(`/projects/${projectDetails.projectId}/details`, {
          customerPO,
          tamBillableRate,
          tamDriveRate,
          invoiceType
        })
        .then(res => setProjectDetails(res));

      let savedFile = null;

      if (file) {
        const projectFolder = await http().get(
          `/projects/${projectDetails.projectId}/docs`
        );

        const accountingFolder = findAccountingFolder(projectFolder);
        const folderId = accountingFolder
          ? accountingFolder.id
          : projectFolder.id;

        savedFile = await saveFile(file, folderId, projectDetails.projectId);
      }

      if (savedFile || poDriveId) {
        http()
          .put(`/projects/${projectDetails.projectId}/details`, {
            customerPODriveId: savedFile ? savedFile.id : poDriveId
          })
          .then(res => {
            setProjectDetails(res);
            next();
          })
          .catch(err => Toast.show(err.message));
      } else {
        if (!projectDetails.customerPODriveId) {
          Toast.show('Customer PO file required');
          setLoading(false);
        } else {
          next();
        }
      }
    } catch (err) {
      Toast.show(err.message);
      setLoading(false);
    }
  };

  const toggleCheckBox = val => () => {
    if (customerPO === val) {
      setCustomerPO(null);
    } else {
      setCustomerPO(val);
    }
  };

  const types = ['Email'];
  const isTamBilling = invoiceType === 'Time & Materials';

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>Customer PO</DialogTitle>
      <DialogContent>
        <FormControlLabel
          style={{ display: 'block' }}
          control={
            <Checkbox
              checked={!types.includes(customerPO)}
              onClick={toggleCheckBox('')}
            />
          }
          label={
            <TextField
              required={!types.includes(customerPO)}
              placeholder="Contract/PO#"
              margin="dense"
              value={customerPO}
              onChange={e => setCustomerPO(e.target.value)}
            />
          }
        />
        {types.map(val => (
          <FormControlLabel
            key={val}
            style={{ display: 'block' }}
            control={
              <Checkbox
                checked={customerPO === val}
                onClick={toggleCheckBox(val)}
              />
            }
            label={val}
          />
        ))}
        <DropZone
          style={{ minHeight: 100 }}
          onRead={files => setFile(files[0])}
          loading={loading}
          title="Upload Contract/PO/Notice to Proceed/Email"
          isUploaded={!!(file || fileId)}
          onReset={() => {
            setFile(null);
            setFileId(null);
          }}
        />

        <FilePickerButton
          onSelect={file => setPoDriveId(file ? file.id : null)}
          apiPath={`/projects/${projectDetails.projectId}/docs`}
          buttonText="Select file from drive"
          style={{ marginTop: 10 }}
        />
      </DialogContent>

      <DialogTitle
        style={{ textAlign: 'center', marginTop: 16, paddingBottom: 0 }}
      >
        Invoice
      </DialogTitle>
      <DialogContent>
        <CustomInput
          margin="dense"
          required
          label="Invoice Type"
          value={invoiceType}
          onChange={setInvoiceType}
          type="dropdown"
          options={invoiceTypeOptions}
        />

        {isTamBilling && (
          <>
            <CustomInput
              margin="dense"
              required
              type="currency"
              label="T&M Hourly Rate"
              value={tamBillableRate}
              onChange={setTamBillableRate}
            />
            <CustomInput
              margin="dense"
              required
              type="currency"
              label="T&M Drive Time Rate"
              value={tamDriveRate}
              onChange={setTamDriveRate}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" type="submit">
          Next
        </Button>
      </DialogActions>
    </form>
  );
};

function findAccountingFolder(projectFolder) {
  if (isUsingOneDrive()) {
    return projectFolder.children.find(
      child => child.name === 'Accounting' && !!child.folder
    );
  }
  return projectFolder.files.find(
    file =>
      file.name === 'Accounting' &&
      file.mimeType === 'application/vnd.google-apps.folder'
  );
}

async function saveFile(file, folderId, projectId) {
  if (isUsingOneDrive()) {
    const formData = new FormData();
    formData.set('name', file.name);
    if (folderId) formData.set('folderId', folderId);
    formData.set('steps_file', file.file);
    return http().post(`/projects/${projectId}/docs`, formData);
  }
  return http().post(`/projects/${projectId}/docs`, {
    name: file.name,
    mimeType: file.mimeType,
    file: file.base64,
    folderId
  });
}

export default CustomerPO;
