export default function mapReducer(pluckOrKey) {
  let pluck;
  if (typeof pluckOrKey === 'string') {
    pluck = item => ({ [item[pluckOrKey]]: item });
  } else if (typeof pluckOrKey === 'function') {
    pluck = pluckOrKey;
  }
  return (map, item) => Object.assign(map, pluck(item));
}

mapReducer.array = keyOrGetKey => {
  let getKey;
  if (typeof keyOrGetKey === 'string') {
    getKey = item => item[keyOrGetKey];
  } else if (typeof keyOrGetKey === 'function') {
    getKey = keyOrGetKey;
  }
  return (map, item) => {
    const key = getKey(item);
    if (map[key]) {
      map[key].push(item);
    } else {
      map[key] = [item];
    }
    return map;
  };
};

mapReducer.set = (keyOrGetKey = i => i) => {
  let getKey;
  if (typeof keyOrGetKey === 'string') {
    getKey = item => item[keyOrGetKey];
  } else if (typeof keyOrGetKey === 'function') {
    getKey = keyOrGetKey;
  }
  return (set, item) => {
    const key = getKey(item);
    set[key] = true;
    return set;
  };
};

mapReducer.value = (keyOrGetKey, valueOrGetValue) => {
  let getKey = keyOrGetKey;
  let getValue = valueOrGetValue;
  if (typeof keyOrGetKey !== 'function') getKey = () => keyOrGetKey;
  if (typeof valueOrGetValue !== 'function') getValue = () => valueOrGetValue;

  return (map, item) =>
    Object.assign(map, { [item[getKey(item)]]: getValue(item) });
};
