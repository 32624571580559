import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from 'helpers/themeSafeMui.helper';
import { CustomInput, PersonDisplay, Toast } from 'components';
import { UsersContext } from '../context/Users.context';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { fromDateString } from '../helpers/DateTime.helper';
import http from 'helpers/http.helper';

const defaultMessages = date => ({
  removed: `You have been removed from a shift on the week of ${date}`,
  added: `You have been added to a shift on the week of ${date}`,
  staying: `A shift that you are scheduled for on the week of ${date} has changed`
});

export default function UpdateApprovedShift({
  open,
  onClose,
  dateString,
  removedUserIds: removedUserIdsProp,
  removedUsersMessage: removedUsersMessageProp,
  addedUserIds: addedUserIdsProp,
  addedUsersMessage: addedUsersMessageProp,
  stayingUserIds: stayingUserIdsProp,
  stayingUsersMessage: stayingUsersMessageProp,
  setOnScheduleChange
}) {
  const formattedDateString = fromDateString(dateString).format('MMM Do');
  const dm = defaultMessages(formattedDateString);

  const [removedUserIds, setRemovedUserIds] = useState(removedUserIdsProp);
  const [removedUsersMessage, setRemovedUsersMessage] = useState(
    removedUsersMessageProp || dm.removed
  );

  const [addedUserIds, setAddedUserIds] = useState(addedUserIdsProp);
  const [addedUsersMessage, setAddedUsersMessage] = useState(
    addedUsersMessageProp || dm.added
  );

  const [stayingUserIds, setStayingUserIds] = useState(stayingUserIdsProp);
  const [stayingUsersMessage, setStayingUsersMessage] = useState(
    stayingUsersMessageProp || dm.staying
  );

  useEffect(() => {
    setRemovedUserIds(removedUserIdsProp);
    setAddedUserIds(addedUserIdsProp);
    setStayingUserIds(stayingUserIdsProp);
    const dm = defaultMessages(formattedDateString);
    setRemovedUsersMessage(removedUsersMessageProp || dm.removed);
    setAddedUsersMessage(addedUsersMessageProp || dm.added);
    setStayingUsersMessage(stayingUsersMessageProp || dm.staying);
  }, [
    removedUserIdsProp,
    addedUserIdsProp,
    stayingUserIdsProp,
    formattedDateString,
    removedUsersMessageProp,
    addedUsersMessageProp,
    stayingUsersMessageProp
  ]);

  const { userMap } = useContext(UsersContext);

  const numberOfUsers =
    removedUserIds.length + addedUserIds.length + stayingUserIds.length;

  const update = () => {
    const send = (userIds, message) =>
      !userIds.length
        ? Promise.resolve([])
        : http()
            .put('/shift-days/text-update-approved', { userIds, message })
            .catch(Toast.showErr);
    return Promise.all([
      send(removedUserIds, removedUsersMessage),
      send(addedUserIds, addedUsersMessage),
      send(stayingUserIds, stayingUsersMessage)
    ]);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Text Schedule Updates:</DialogTitle>
      <Divider />
      <DialogContent>
        {!!removedUserIdsProp.length && (
          <UsersMessageWindow
            title="Send Text Update to Removed Users"
            allUserIds={removedUserIdsProp}
            stateUserIds={removedUserIds}
            setUserIds={setRemovedUserIds}
            userMap={userMap}
            message={removedUsersMessage}
            setMessage={setRemovedUsersMessage}
          />
        )}
        {!!removedUserIdsProp.length &&
          (!!addedUserIdsProp.length || !!stayingUserIdsProp.length) && (
            <Divider style={{ margin: '32px 0' }} />
          )}
        {!!addedUserIdsProp.length && (
          <UsersMessageWindow
            title="Send Text Update to Added Users"
            allUserIds={addedUserIdsProp}
            stateUserIds={addedUserIds}
            setUserIds={setAddedUserIds}
            userMap={userMap}
            message={addedUsersMessage}
            setMessage={setAddedUsersMessage}
          />
        )}
        {!!addedUserIdsProp.length && !!stayingUserIdsProp.length && (
          <Divider style={{ margin: '32px 0' }} />
        )}
        {!!stayingUserIdsProp.length && (
          <UsersMessageWindow
            title="Send Text Update to Current Users"
            allUserIds={stayingUserIdsProp}
            stateUserIds={stayingUserIds}
            setUserIds={setStayingUserIds}
            userMap={userMap}
            message={stayingUsersMessage}
            setMessage={setStayingUsersMessage}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOnScheduleChange(update)}
        >
          Update shift and send text to {numberOfUsers} user
          {plural(numberOfUsers)}
        </Button>
        <Button variant="contained" onClick={onClose}>
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function plural(num) {
  return num === 1 ? '' : 's';
}

function UsersMessageWindow({
  title,
  allUserIds,
  stateUserIds,
  setUserIds,
  userMap,
  message,
  setMessage
}) {
  const toggleUser = userId => () => {
    setUserIds(old =>
      old.includes(userId) ? old.filter(id => id !== userId) : [userId, ...old]
    );
  };
  return (
    <div>
      <Typography variant="h5">{title}</Typography>
      <List
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        {allUserIds.map(userId => {
          const user = userMap[userId];
          if (!user) return null;
          return (
            <ListItem
              key={userId}
              button
              onClick={toggleUser(userId)}
              style={{ width: 400 }}
            >
              <ListItemIcon>
                {stateUserIds.includes(userId) ? (
                  <CheckBox />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
              </ListItemIcon>
              <PersonDisplay
                key={userId}
                name={user.name}
                phoneNumber={user.phoneNumber}
                email={user.email}
              />
            </ListItem>
          );
        })}
      </List>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '0 1 100%' }}>
          <CustomInput
            variant="outlined"
            multiline
            label="Text message"
            value={message}
            onChange={setMessage}
          />
        </div>
        <div>
          <Button
            variant="contained"
            style={{ whiteSpace: 'nowrap', marginLeft: 16 }}
            onClick={() =>
              stateUserIds.length === allUserIds.length
                ? setUserIds([])
                : setUserIds([...allUserIds])
            }
          >
            {stateUserIds.length === allUserIds.length
              ? 'Unselect All'
              : 'Select All'}
          </Button>
        </div>
      </div>
    </div>
  );
}
