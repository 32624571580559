import React, { useState } from 'react';
import {
  Popover,
  Button,
  MenuItem,
  ListItemText,
  List
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import qs from 'query-string';
import moment from 'moment';

const TrainingCertificateDownload = ({
  selectedUsers,
  modules,
  ButtonProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = e => setAnchorEl(e.currentTarget);

  const downloadModule = module => () => {
    setLoading(true);
    setAnchorEl(null);
    http()
      .get(
        `/users/trainings/download?` +
          qs.stringify(
            {
              userIds: selectedUsers.map(user => user.id),
              trainingModuleId: module.id
            },
            { arrayFormat: 'bracket' }
          ),
        {
          responseType: 'blob' // important
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${module.name} ${moment().format('L')}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        variant="contained"
        {...ButtonProps}
        onClick={handleClick}
        disabled={loading || selectedUsers.length === 0}
      >
        Download Certificates
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ width: 300, maxHeight: '90vh', overflowY: 'auto' }}>
          <List style={{ padding: 0 }}>
            {modules.map(module => (
              <MenuItem key={module.id} button onClick={downloadModule(module)}>
                <ListItemText primary={module.name} />
              </MenuItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default TrainingCertificateDownload;
