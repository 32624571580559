import React, { useState, useEffect } from 'react';
import { Toast, SearchDropdown, PhoneEmailField } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

function CreatePerson(props) {
  const { companyProp, onClose, onCreate, companyLocked } = props;
  const [name, setName] = useState('');
  const [company, setCompany] = useState(companyProp || {});
  const [jobTitle, setJobTitle] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([
    { type: '', phoneNumber: '' }
  ]);
  const [emails, setEmails] = useState([{ type: '', email: '' }]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    http()
      .get('/companies')
      .then(res => setCompanies(res))
      .catch(e => Toast.show(e.message));
  }, []);

  const save = e => {
    e.preventDefault();
    setLoading(true);

    http()
      .post('/people', {
        name: name,
        companyId: company.id,
        jobTitle: jobTitle,
        phoneNumbers: phoneNumbers,
        emails: emails
      })
      .then(res => {
        onCreate(res);
        Toast.show('Person created.');
        onClose();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  function copyFromCompany() {
    setJobTitle('Owner');
    setPhoneNumbers([{ type: 'Work', phoneNumber: company.phoneNumber || '' }]);
    setEmails([{ type: 'Work', email: company.billingEmail || '' }]);
  }

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle>New Person</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Name"
          fullWidth
          value={name}
          margin="normal"
          onChange={e => setName(e.target.value)}
          required
        />
        {!companyLocked && (
          <SearchDropdown
            fullWidth
            margin="normal"
            label="Company"
            items={companies}
            onChange={company => setCompany(company)}
            value={company}
            itemToString={item => item.name}
            required
          />
        )}
        <TextField
          label="Job Title"
          fullWidth
          value={jobTitle}
          margin="normal"
          onChange={e => setJobTitle(e.target.value)}
          required
        />

        <PhoneEmailField
          value={phoneNumbers}
          onChange={phoneNumbers => setPhoneNumbers(phoneNumbers)}
          ext
          phone
        />

        <PhoneEmailField
          value={emails}
          onChange={emails => setEmails(emails)}
          email
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={copyFromCompany}>
          Copy from company
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default CreatePerson;
