import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField
} from 'helpers/themeSafeMui.helper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import http from 'helpers/http.helper';
import { CustomInput, Toast } from 'components';
import { ProjectContext } from 'routes/Projects/context/Project.context';

const CreateTaskModal = ({ open, onClose, onCreate, labels, tasks }) => {
  const { project } = useContext(ProjectContext);
  const [taskPresets, setTaskPresets] = useState([]);
  const [taskPreset, setTaskPreset] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [skillOptions, setSkillOptions] = useState([]);
  const [skill, setSkill] = useState(null);
  const [hours, setHours] = useState(0);
  const [techCount, setTechCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [labelId, setLabelId] = useState(null);
  const [linkedTo, setLinkedTo] = useState(null);

  const create = e => {
    e.preventDefault();
    http()
      .post(`/projects/${project.id}/schedule-tasks`, {
        taskName,
        skill,
        hours,
        techCount,
        startDate,
        labelId,
        linkedTo
      })
      .then(onClose)
      .then(() => onCreate())
      .catch(Toast.showErr);
  };

  useEffect(() => {
    setTaskName('');
    setSkill(null);
    setHours(0);
    setTechCount(0);
    setStartDate(null);
    setLabelId(null);
    setLinkedTo(null);
    //get skills
    http()
      .get('/skills')
      .then(res => setSkillOptions(res))
      .catch(Toast.showErr);
    //get task presets
    http()
      .get('/task-presets')
      .then(res => setTaskPresets(res))
      .catch(Toast.showErr);
  }, [open]);

  // if they select a task preset set skill automatically
  useEffect(() => {
    if (taskPreset.length !== 0) {
      setSkill(taskPreset[0].skillId);
      setTaskName(taskPreset[0].name);
    }
  }, [taskPreset]);

  const currentLabel = labels.find(label => label.id === labelId);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={create}>
        <DialogTitle>Create Task</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            inputValue={taskName}
            onInputChange={(event, presetName) => {
              setTaskPreset(
                taskPresets.filter(preset => preset.name === presetName)
              );
              setTaskName(presetName);
            }}
            options={taskPresets.map(option => option.name)}
            renderInput={params => (
              <TextField {...params} label="Task Name" margin="normal" />
            )}
          />
          <CustomInput
            type="dropdown"
            label="Task Skill"
            value={skill}
            onChange={setSkill}
            options={[
              { value: null, label: 'No Label' },
              ...skillOptions.map(skill => ({
                value: skill.id,
                label: skill.name
              }))
            ]}
          />
          <CustomInput
            required
            type="number"
            label="Hours"
            value={hours}
            onChange={setHours}
          />
          <CustomInput
            required
            type="number"
            label="Tech Count"
            value={techCount}
            onChange={setTechCount}
          />
          <CustomInput
            required
            type="date"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <div
            style={{
              borderBottom: currentLabel
                ? `5px solid #${currentLabel.color}`
                : null
            }}
          >
            <CustomInput
              type="dropdown"
              label="Label"
              value={labelId}
              onChange={setLabelId}
              options={[
                { value: null, label: 'No Label' },
                ...labels.map(label => ({
                  value: label.id,
                  label: label.name
                }))
              ]}
            />
          </div>

          <CustomInput
            type="dropdown"
            label="LinkedTo"
            value={linkedTo}
            onChange={setLinkedTo}
            options={[
              { value: null, label: '-' },
              ...tasks.map(t => ({
                value: t.id,
                label: t.taskName
              }))
            ]}
          />
          <InputLabel>
            When linked start time is changed, it will change this task's start
            time
          </InputLabel>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateTaskModal;
