import React from 'react';
import {
  Typography,
  List,
  ListItem,
  Paper,
  Divider
} from 'helpers/themeSafeMui.helper';
import { ItemDisplay, NoItemsResult, ExternalLink } from 'components';
import phoneHelper from 'helpers/phone.helper';

class PersonPhoneNumbers extends React.Component {
  render() {
    const { person, openModal } = this.props;

    return (
      <Paper style={{ padding: 16 }}>
        <Typography variant="h6">Phone Numbers</Typography>
        <Divider />

        <List>
          {person.phoneNumbers.map(item => {
            return (
              <React.Fragment key={item.phoneNumber + item.type}>
                <ListItem
                  style={{ cursor: 'pointer' }}
                  onClick={() => openModal()}
                >
                  <ItemDisplay
                    itemTitle={
                      <>
                        <ExternalLink href={`tel:${item.phoneNumber}`}>
                          {phoneHelper(item.phoneNumber)}
                        </ExternalLink>
                        {item.ext && ` Ext. ${item.ext}`}
                      </>
                    }
                    itemSubTitle={item.type}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
        {!person.phoneNumbers.length && <NoItemsResult type="phone numbers" />}
      </Paper>
    );
  }
}

export default PersonPhoneNumbers;
