import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from 'helpers/themeSafeMui.helper';

const EditFieldPopup = props => {
  const { field, onUpdate, open, onClose, signers } = props;

  const [required, setRequired] = useState(false);
  const [fontSize, setFontSize] = useState(0);
  const [textAlign, setTextAlign] = useState('left');
  const [signer, setSigner] = useState(null);

  useEffect(() => {
    setRequired(field.required);
    setFontSize(field.fontSize);
    setTextAlign(field.textAlign);
    setSigner(signers.find(signer => signer.id === field.signerId));
  }, [field, signers]);

  const onSubmit = e => {
    e.preventDefault();

    onUpdate({
      ...field,
      required,
      fontSize,
      textAlign,
      signerId: signer.id
    });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogContent style={{ width: 250 }}>
          <FormControl required fullWidth margin="dense">
            <InputLabel>Required</InputLabel>
            <Select
              value={required}
              onChange={e => setRequired(e.target.value)}
              required
            >
              <MenuItem value={true}>Required</MenuItem>
              <MenuItem value={false}>Optional</MenuItem>
            </Select>
          </FormControl>
          {(field.type === 'text_field' || field.type === 'auto_date') && (
            <FormControl required fullWidth margin="dense">
              <InputLabel>Font Size</InputLabel>
              <Select
                value={fontSize}
                onChange={e => setFontSize(e.target.value)}
                required
              >
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={16}>18</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={32}>36</MenuItem>
                <MenuItem value={42}>48</MenuItem>
                <MenuItem value={42}>72</MenuItem>
              </Select>
            </FormControl>
          )}
          <FormControl required fullWidth margin="dense">
            <InputLabel>Align</InputLabel>
            <Select
              value={textAlign}
              onChange={e => setTextAlign(e.target.value)}
              required
            >
              <MenuItem value="left">Left</MenuItem>
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="right">Right</MenuItem>
            </Select>
          </FormControl>
          <FormControl required fullWidth margin="dense">
            <InputLabel>Signer</InputLabel>
            <Select
              value={signer}
              onChange={e => setSigner(e.target.value)}
              required
            >
              {signers.map(signer => (
                <MenuItem value={signer} key={signer.name}>
                  {signer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size="small">
            Cancel
          </Button>
          <Button type="submit" color="primary" size="small">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditFieldPopup;
