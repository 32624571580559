import http from 'helpers/http.helper';
import { Toast } from 'components';
import { getFavoriteProjects } from 'ducks/favoriteProjects.duck';
import { getAreas } from 'ducks/areas.duck';
import { getPermissionLevels } from 'ducks/permissionLevels.duck';
import { getProjectStatuses } from 'ducks/projectStatuses.duck';
import { getAdminSettings } from 'ducks/adminSettings.duck';

// Actions
const SET_USER = 'users/SET_USER';
const CLEAR_USER = 'users/CLEAR_USER';

// Reducer
export default function reducer(state = null, action = {}) {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case CLEAR_USER:
      return null;
    default:
      return state;
  }
}

// Action Creators
export const setUser = user => async dispatch => {
  if (user.token) {
    window.localStorage.setItem('token', user.token);
  }

  user.permissionLevel = await http()
    .get('/permission-levels/me')
    .catch(err => Toast.show(err.message));

  user.hasPermission = permission =>
    user.permissionLevel.permissions.includes(permission);

  dispatch({ type: SET_USER, user });
  dispatch(getFavoriteProjects());
  dispatch(getAreas());
  dispatch(getPermissionLevels());
  dispatch(getProjectStatuses());
  dispatch(getAdminSettings());
};

export const clearUser = () => {
  window.localStorage.removeItem('token');

  return { type: CLEAR_USER };
};
