import React, { useContext, useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import Color from 'color';
import { ProjectChangeOrdersContext } from 'routes/Projects/context/Project.context';
import ChangeOrderModal from '../modals/ChangeOrder.modal';
import ChangeOrderRow from './ChangeOrderRow.component';
import { NoItemsResult } from 'components';
import formatValue from 'helpers/formatValue.helper';

const ChangeOrdersTable = ({ hiddenColumns = [] }) => {
  const colors = useColors();
  const headerColor = new Color(colors.grey).alpha(0.2).rgb();
  const { projectChangeOrders } = useContext(ProjectChangeOrdersContext);

  const [modalProps, setModalProps] = useState({
    id: null,
    editing: false,
    open: false
  });

  function closeModal() {
    setModalProps({
      id: null,
      editing: false,
      open: false
    });
  }

  const openCreateModal = () => {
    setModalProps({
      id: null,
      editing: false,
      open: true
    });
  };

  const openEditModal = id => () => {
    setModalProps({
      id,
      editing: true,
      open: true
    });
  };

  const approvedDollarValue = formatValue({
    type: 'currency',
    value: projectChangeOrders.reduce(
      (acc, { price, approved }) => (approved ? price + acc : acc),
      0
    )
  });

  const approvedCost = formatValue({
    type: 'currency',
    value: projectChangeOrders.reduce(
      (acc, { cost, approved }) => (approved ? cost + acc : acc),
      0
    )
  });

  const approvedHours =
    projectChangeOrders.reduce(
      (acc, { hours, approved }) => (approved ? hours + acc : acc),
      0
    ) || '---';

  const isHidden = colName => hiddenColumns.includes(colName);

  return (
    <>
      <ChangeOrderModal onClose={closeModal} {...modalProps} />
      <TableContainer style={{ borderRadius: 10 }}>
        <Table>
          <TableHead style={{ background: headerColor }}>
            <TableRow>
              {!isHidden('CO#') && <TableCell>CO#</TableCell>}
              {!isHidden('Ref#') && <TableCell>Ref#</TableCell>}
              {!isHidden('Name') && <TableCell>Name</TableCell>}
              {!isHidden('Notes') && <TableCell>Notes</TableCell>}
              {!isHidden('$ Amount') && (
                <TableCell style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <div style={{ whiteSpace: 'nowrap' }}>$ Amount</div>
                      <Typography component="div" variant="caption">
                        {approvedDollarValue}
                      </Typography>
                    </div>
                  </div>
                </TableCell>
              )}
              {!isHidden('$ Amount') && (
                <TableCell style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <div style={{ whiteSpace: 'nowrap' }}>Cost</div>
                      <Typography component="div" variant="caption">
                        {approvedCost}
                      </Typography>
                    </div>
                  </div>
                </TableCell>
              )}
              {!isHidden('Hrs.') && (
                <TableCell style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <div>Hrs.</div>
                      <Typography component="div" variant="caption">
                        {approvedHours}
                      </Typography>
                    </div>
                  </div>
                </TableCell>
              )}
              {!isHidden('Approved') && (
                <TableCell padding="checkbox">Approved</TableCell>
              )}
              {!isHidden('Denied') && <TableCell>Denied</TableCell>}
              {!isHidden('add/edit') && (
                <TableCell padding="checkbox">
                  <IconButton onClick={openCreateModal}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!projectChangeOrders.length && (
              <TableRow>
                <TableCell colSpan={9 - hiddenColumns.length}>
                  <NoItemsResult type="Change Orders" />
                </TableCell>
              </TableRow>
            )}
            {projectChangeOrders.map(CO => (
              <ChangeOrderRow
                key={CO.id}
                id={CO.id}
                openEditModal={openEditModal}
                isHidden={isHidden}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChangeOrdersTable;
