import React, { useState, useEffect, useCallback } from 'react';
import { Typography, IconButton, Button } from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector, useDispatch } from 'react-redux';
import AreaModal from '../modals/Area.modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DragAndDrop, Toast, ConfirmDelete } from 'components';
import { getAreas, setAreas } from 'ducks/areas.duck';
import http from 'helpers/http.helper';
import AreaMergeModal from '../modals/AreaMerge.modal';

const Areas = () => {
  const areas = useSelector(state => state.areas);
  const [areaModalOpen, setAreaModalOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState('edit');
  const [editingAreaId, setEditingAreaId] = useState(null);
  const [areaLogMap, setAreaLogMap] = useState({});
  const dispatch = useDispatch();

  const removeArea = area => () => {
    http()
      .delete(`/areas/${area.id}`)
      .then(() => {
        Toast.show('Area removed.');
        dispatch(getAreas());
      })
      .catch(err => Toast.show(err.message));
  };

  const updateOrder = async areas => {
    dispatch(setAreas(areas));
    await Promise.all(
      areas.map((area, idx) =>
        http()
          .put(`/areas/${area.id}`, { order: idx })
          .catch(err => Toast.show(err.message))
      )
    );
    dispatch(getAreas());
  };

  const fetchAreaLogs = useCallback(() => {
    if (areas.length) {
      Promise.all(
        areas.map(area => http().get(`/areas/${area.id}/employment-logs`))
      ).then(areasLogs => {
        const areaLogMap = {};
        areasLogs.forEach((logs, idx) => (areaLogMap[areas[idx].id] = logs));
        setAreaLogMap(areaLogMap);
      });
    }
  }, [areas]);

  useEffect(() => fetchAreaLogs(), [fetchAreaLogs]);

  return (
    <div>
      <AreaModal
        editing={editOrCreate === 'edit'}
        open={areaModalOpen}
        onClose={() => setAreaModalOpen(false)}
        id={editingAreaId}
      />
      <AreaMergeModal
        open={mergeModalOpen}
        onClose={() => setMergeModalOpen(false)}
        areaLogMap={areaLogMap}
        onMerge={fetchAreaLogs}
      />
      <Typography
        style={{ padding: 10, fontWeight: 400 }}
        component="h1"
        variant="h6"
        gutterBottom
      >
        Areas
        <IconButton
          onClick={() => {
            setAreaModalOpen(true);
            setEditOrCreate('create');
            setEditingAreaId(null);
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setMergeModalOpen(true)}
          style={{ marginLeft: 5 }}
        >
          Merge
        </Button>
      </Typography>

      <DragAndDrop onDrop={updateOrder}>
        {areas.map(area => (
          <div
            key={area.id}
            id={area.id}
            value={area}
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: '0 1 100%'
              }}
            >
              <Typography variant="body2">{area.name}</Typography>
              {!!area.description && (
                <Typography variant="caption" style={{ marginLeft: 5 }}>
                  - {area.description}
                </Typography>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: 10
              }}
            >
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {(areaLogMap[area.id] || { length: '?' }).length} job records
              </Typography>
            </div>
            <ConfirmDelete
              iconButtonProps={{ size: 'small' }}
              onConfirm={removeArea(area)}
            />
            <IconButton
              size="small"
              onClick={() => {
                setAreaModalOpen(true);
                setEditOrCreate('edit');
                setEditingAreaId(area.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ))}
      </DragAndDrop>
    </div>
  );
};

export default Areas;
