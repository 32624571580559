import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography
} from 'helpers/themeSafeMui.helper';
import { CustomInput, DriveFolderViewer, IframeModal } from 'components';
import { useColors } from 'helpers/theme.helper';
import http from 'helpers/http.helper';

const TrainingCompleted = ({
  onSave,
  date,
  onSetDate,
  fileId,
  onSetFileId,
  userId,
  userTrainingRecord,
  ...rest
}) => {
  const [selectingFile, setSelectingFile] = useState(false);
  const [changed, setChanged] = useState(false);
  const [viewFileUrl, setViewFileUrl] = useState('');
  const colors = useColors();

  useEffect(() => {
    if (!rest.open) {
      setSelectingFile(false);
      setChanged(false);
    }
  }, [rest.open]);

  const openCurrentFile = () => {
    if (!userTrainingRecord || !userTrainingRecord.id) return;
    const trainingId = userTrainingRecord.id;
    return http()
      .get(`users/${userId}/trainings/${trainingId}/certificate`)
      .then(data => {
        setViewFileUrl(data.webViewLink.replace('view', 'preview'));
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={selectingFile ? 'lg' : 'xs'}
      keepMounted={false}
      {...rest}
    >
      <IframeModal
        open={!!viewFileUrl}
        onClose={() => setViewFileUrl('')}
        url={viewFileUrl}
      />
      <DialogContent>
        <CustomInput
          label="Date Completed"
          type="date"
          value={date}
          onChange={date => {
            setChanged(true);
            onSetDate(date);
          }}
        />
      </DialogContent>

      <DialogContent>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ color: fileId ? colors.success : null }}
        >
          {!fileId && 'no'} certificate file selected
        </Typography>

        {selectingFile ? (
          <DriveFolderViewer
            onSelect={file => {
              setChanged(true);
              setSelectingFile(false);
              onSetFileId(file.id);
            }}
            apiPath={`/users/${userId}/docs`}
          />
        ) : (
          <div>
            <Button
              style={{ marginTop: 3 }}
              variant="contained"
              size="small"
              onClick={() => setSelectingFile(true)}
            >
              {fileId ? 'Change ' : 'Select '}
              Certificate File
            </Button>
            <br />
            {!!fileId && (
              <>
                <Button
                  style={{ marginTop: 10 }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setChanged(true);
                    onSetFileId(null);
                  }}
                >
                  Remove Certificate File
                </Button>
                <br />
                <Button
                  style={{ marginTop: 10 }}
                  variant="contained"
                  size="small"
                  onClick={openCurrentFile}
                >
                  View Certificate File
                </Button>
              </>
            )}
          </div>
        )}
      </DialogContent>

      {changed && (
        <Typography
          align="right"
          variant="caption"
          style={{ color: colors.error, paddingRight: 8 }}
        >
          Changes not saved
        </Typography>
      )}
      <DialogActions>
        <Button variant="contained" onClick={rest.onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrainingCompleted;
