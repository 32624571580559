import React from 'react';
import { Typography, Paper } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import CompletedIcon from '@material-ui/icons/Done';
import { useColors } from 'helpers/theme.helper';

const Signer = props => {
  const { signer } = props;
  const colors = useColors();

  const Row = props => (
    <Typography gutterBottom variant="body2">
      {props.title}: <strong>{props.value}</strong>
    </Typography>
  );

  return (
    <Paper style={{ padding: 8, marginTop: 16 }}>
      <Typography variant="h6" gutterBottom>
        <CompletedIcon
          style={{
            marginRight: 8,
            verticalAlign: 'text-bottom',
            color: signer.completedAt ? colors.success : colors.darkGrey
          }}
        />
        {signer.name}
      </Typography>
      <Row title="Email" value={signer.email} />
      <Row
        title="Sent"
        value={signer.sentAt ? moment(signer.sentAt).format('LLL') : 'Not sent'}
      />
      <Row
        title="Opened"
        value={
          signer.viewedAt ? moment(signer.viewedAt).format('LLL') : 'Not opened'
        }
      />
      <Row
        title="Completed"
        value={
          signer.completedAt
            ? moment(signer.completedAt).format('LLL')
            : 'Not completed'
        }
      />
      <Row
        title="IP Address"
        value={signer.ipAddress ? signer.ipAddress : 'Not registered'}
      />
    </Paper>
  );
};

export default Signer;
