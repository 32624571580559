import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import { UserContext, EmploymentLogContext } from '../context/User.context';
import http from 'helpers/http.helper';
import { Toast, CustomInput } from 'components';
import { useSelector } from 'react-redux';

const UpdateArea = ({ open, onClose, jobLog }) => {
  const { user } = useContext(UserContext);
  const { setEmploymentLogs } = useContext(EmploymentLogContext);

  const [newArea, setNewArea] = useState(null);
  const areas = useSelector(state => state.areas);

  const update = () => {
    if (jobLog && newArea) {
      http()
        .put(`/users/${jobLog.userId}/employment-logs/${jobLog.id}/area`, {
          areaId: newArea.id
        })
        .then(updated => {
          setEmploymentLogs(updated);
          onClose();
          Toast.show('Updated');
        })
        .catch(e => Toast.show(e.message));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update {user.name}'s area</DialogTitle>

      <DialogContent>
        <CustomInput
          type="dropdown"
          label="New Area"
          value={newArea && newArea.id}
          options={areas.map(area => ({ value: area.id, label: area.name }))}
          onChange={id => setNewArea(areas.find(area => area.id === id))}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          cancel
        </Button>
        <Button onClick={update} color="primary">
          update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateArea;
