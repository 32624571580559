import React, { useRef, useEffect, useState } from 'react';
import TaskTable from './TaskTable.canvas';

const PrintViewCanvas = ({ printView, tasks, tableRef }) => {
  const canvasRef = useRef(null);
  const taskTableRef = useRef(null);
  const [canvasStyles, setCanvasStyles] = useState({});

  useEffect(() => {
    if (taskTableRef.current) {
      taskTableRef.current.update({ tasks, printView });
    } else {
      taskTableRef.current = new TaskTable({
        ctx: canvasRef.current.getContext('2d'),
        printView,
        tasks
      });
    }
    if (tableRef) {
      tableRef.current = taskTableRef.current;
    }
    setCanvasStyles(tableRef.current.canvasStyles);
    taskTableRef.current.print();
  }, [printView, tasks, tableRef]);

  return (
    <div style={{ overflow: 'auto' }}>
      <canvas
        style={{
          display: printView.taskIds.length ? 'block' : 'none',
          ...canvasStyles
        }}
        ref={canvasRef}
      />
    </div>
  );
};

export default PrintViewCanvas;
