import React, { useCallback, useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import mapReducer from 'helpers/mapReducer.helper';

export const UsersContext = React.createContext();

export default function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [userMap, setUserMap] = useState({});

  const fetchUsers = useCallback(() => {
    http()
      .get('/users')
      .then(users => {
        setUsers(users);
        setUserMap(users.reduce(mapReducer('id'), {}));
      })
      .catch(Toast.showErr);
  }, []);

  useEffect(() => fetchUsers(), [fetchUsers]);

  return (
    <UsersContext.Provider
      value={{
        users,
        userMap,
        fetchUsers
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
