import React, { useState, useEffect } from 'react';
import { DropZone, Toast, NoItemsResult, CustomInput } from 'components';
import http from 'helpers/http.helper';
import QuickbooksImportItem from '../components/QuickbooksImportItem.component';
import QuickBooksImportCategories from '../components/QuickbooksImportCategories.component';
import { Typography, Paper, Button } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { useSelector } from 'react-redux';

const QuickBooksImport = props => {
  const adminSettings = useSelector(state => state.adminSettings);
  const [uploads, setUploads] = useState([]);
  const [created, setCreated] = useState(() => moment());
  const [csvFile, setCsvFile] = useState(null);
  const [uploadType, setUploadType] = useState('Job Profitability Summary');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    http()
      .get('/projects/quickbooks-import/all')
      .then(res => setUploads(res))
      .catch(e => Toast.show(e.message));
  }, []);

  useEffect(() => {
    if (csvFile && csvFile.base64 && uploadType === 'Profit and Loss by Job') {
      const categories = atob(csvFile.base64)
        .split('\n')
        .map(row => row.split(',')[0])
        .filter(c => c) // removes falsy values
        .map(category => category.replaceAll(/^"|"$/g, '')) // removes quotations
        .map(category => {
          let value = 'ignore';
          if (adminSettings.laborCostCategories.includes(category))
            value = 'labor';
          if (adminSettings.materialCostCategories.includes(category))
            value = 'materials';
          return { name: category, value };
        });
      setCategories(categories);
    }
  }, [csvFile, uploadType, adminSettings]);

  const uploadFile = () => {
    const csv = csvFile && csvFile.base64;
    if (!csv) {
      return Toast.show('CSV not selected.');
    }
    http()
      .post('/projects/quickbooks-import', {
        csv,
        created,
        uploadType,
        materialCategories: categories
          .filter(c => c.value === 'materials')
          .map(c => c.name),
        laborCategories: categories
          .filter(c => c.value === 'labor')
          .map(c => c.name)
      })
      .then(res => {
        Toast.show('Project numbers updated.');
        setUploads(uploads => [res, ...uploads]);
      })
      .catch(e => Toast.show(e.message));
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        <div style={{ display: 'flex', gap: 16, width: 600 }}>
          <CustomInput
            type="date"
            label="Report Created"
            value={created}
            onChange={setCreated}
          />
          <CustomInput
            type="dropdown"
            label="Upload Type"
            value={uploadType}
            onChange={setUploadType}
            options={[
              {
                value: 'Job Profitability Summary',
                label: 'Job Profitability Summary'
              },
              {
                value: 'Profit and Loss by Job',
                label: 'Profit and Loss by Job'
              }
            ]}
          />
        </div>
        <div>
          <Button
            style={{ marginBottom: 8 }}
            variant="contained"
            color="primary"
            disabled={!csvFile}
            onClick={uploadFile}
          >
            upload
          </Button>
        </div>
      </div>

      {uploadType === 'Profit and Loss by Job' && categories.length !== 0 && (
        <QuickBooksImportCategories
          categories={categories}
          setCategories={setCategories}
        />
      )}

      <DropZone
        onRead={files => files[0] && setCsvFile(files[0])}
        onReset={() => setCsvFile(null)}
        title="QuickBooks CSV File"
        validExtensions={['csv']}
        isUploaded={!!csvFile}
      />
      <Paper style={{ padding: 24, marginTop: 20 }}>
        <Typography variant="h5">Past Uploads</Typography>
        {uploads.length === 0 ? (
          <NoItemsResult type={'records'} />
        ) : (
          uploads.map(upload => (
            <QuickbooksImportItem key={upload.id} upload={upload} />
          ))
        )}
      </Paper>
    </div>
  );
};

export default QuickBooksImport;
