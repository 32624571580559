import React, { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  Typography
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import TaskTableSelector from '../components/TaskTableSelector.component';
import useCreateShiftsFromTask from 'routes/ShiftSchedule/hooks/useCreateShiftsFromTask.hook';
import { ProjectContext } from 'routes/Projects/context/Project.context';

export default function AddToShiftSchedule({ open, onClose, tasks }) {
  const [loading, setLoading] = useState(false);
  const { project } = useContext(ProjectContext);
  const createShiftFromTask = useCreateShiftsFromTask();
  const projectMap = { [project.id]: project };

  function addAllTasks() {
    setLoading(true);
    Promise.all(tasks.map(task => createShiftFromTask(task, projectMap)))
      .then(() => {
        onClose();
        Toast.show('All tasks added to shift schedule');
      })
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={onClose}>
      {!!project ? (
        <>
          <DialogTitle variant="h5">Add tasks to shift schedule</DialogTitle>
          {loading && <Typography variant="caption">Loading...</Typography>}
          <div>
            <Button
              onClick={addAllTasks}
              disabled={loading}
              variant="contained"
              color="primary"
            >
              Add All Tasks To shift Schedule
            </Button>
          </div>
          <TaskTableSelector
            project={project}
            projectScheduleTasks={tasks}
            onSelect={task => {
              setLoading(true);
              createShiftFromTask(task, projectMap).then(newShift => {
                if (newShift) Toast.show('Task added to shift schedule');
                setLoading(false);
              });
            }}
          />
        </>
      ) : (
        'Loading'
      )}
    </Dialog>
  );
}
