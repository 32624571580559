import React from 'react';
import { Typography, CircularProgress } from 'helpers/themeSafeMui.helper';
import Dropzone from 'react-dropzone';
import CloudDone from '@material-ui/icons/CloudDone';
import { Toast } from 'components';
import mimeTypes from 'mime-types';
import { withColors } from 'helpers/theme.helper';
import Color from 'color';

class DropZone extends React.Component {
  state = {
    over: false
  };

  getBase64File = files => {
    this.setState({ over: false });

    const readFile = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result.replace(/(data:)(.*)(base64,)/, ''));
        };
        reader.onerror = err => {
          reject(err);
        };
      });

    Promise.all(
      files.map(async file => {
        const extension = file.name
          .split('.')
          .slice(-1)[0]
          .toLowerCase();

        const name = file.name
          .split('.')
          .slice(0, -1)
          .join('.');

        if (
          this.props.validExtensions &&
          !this.props.validExtensions.includes(extension)
        ) {
          throw new Error(
            'File type must be one of the following: ' +
              this.props.validExtensions.join(', ')
          );
        }

        return {
          file,
          base64: await readFile(file),
          extension: extension,
          name: name,
          mimeType: mimeTypes.lookup(extension)
        };
      })
    )
      .then(files => {
        this.props.onRead(files);
      })
      .catch(err => {
        Toast.show(err.message);
      });
  };

  render() {
    const colors = this.props.colors;

    const alpha = color =>
      Color(color)
        .alpha(0.3)
        .toString();

    const styles = {
      background: this.state.over
        ? alpha(colors.darkerGrey)
        : alpha(colors.darkGrey),
      height: '100%',
      minHeight: 200,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...this.props.style
    };

    if (this.props.wrapper) {
      return (
        <Dropzone
          maxSize={50 * 1048576}
          onDropRejected={() =>
            Toast.show(
              'File could not be uploaded. Please verify that your file is not larger than 50MB.'
            )
          }
          onDropAccepted={this.getBase64File}
          noClick
          noKeyboard
          onDragEnter={() => this.setState({ over: true })}
          onDragLeave={() => this.setState({ over: false })}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                position: 'relative',
                ...this.props.style
              }}
            >
              {this.state.over && (
                <div
                  style={{
                    boxShadow: `0px 0px 1px 1px ${colors.primary}`,
                    zIndex: 100,
                    background: colors.primary,
                    opacity: 0.5,
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0
                  }}
                />
              )}
              <input {...getInputProps()} />
              {this.props.children}
            </div>
          )}
        </Dropzone>
      );
    }

    if (this.props.loading) {
      return (
        <div style={styles}>
          <CircularProgress style={{ marginBottom: 10 }} />
          <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
            Uploading...
          </Typography>
        </div>
      );
    }

    if (this.props.isUploaded) {
      return (
        <div
          style={styles}
          onClick={() =>
            window.confirm('Are you sure you want to remove this file?')
              ? this.props.onReset()
              : null
          }
        >
          <CloudDone fontSize="large" />
          <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
            File Uploaded
          </Typography>
          <Typography>Click here to upload a different file.</Typography>
        </div>
      );
    }

    return (
      <Dropzone
        multiple={false}
        disabled={this.props.loading}
        onDrop={this.getBase64File}
        noKeyboard
        onDragEnter={() => this.setState({ over: true })}
        onDragLeave={() => this.setState({ over: false })}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={styles}>
            <input {...getInputProps()} />
            <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
              {this.props.title}
            </Typography>
            <Typography>Choose a file or drag it here.</Typography>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default withColors(DropZone);
