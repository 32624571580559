import React, { useState, useCallback } from 'react';
import {
  Grid,
  DialogTitle,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Link
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import SignaturePad from 'signature_pad';
import trim from 'trim-canvas';
import http from 'helpers/http.helper';
import { Toast } from 'components';

function SignatureModal(props) {
  const { workOrderId, onClose, projectId, refresh } = props;
  const [signaturePad, setSignaturePad] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const signaturePadRef = useCallback(el => {
    if (el) {
      setSignaturePad(new SignaturePad(el));
    }
  }, []);

  const save = () => {
    if (signaturePad.isEmpty()) {
      Toast.show('Please enter a signature');
    } else if (!customerName) {
      Toast.show('Please enter name.');
    } else {
      http()
        .put(`/projects/${projectId}/work-orders/${workOrderId}`, {
          dateOfCustomerAcceptance: moment(),
          signatureBase64: trim(signaturePad.canvas).toDataURL(),
          customerName: customerName
        })
        .then(() => {
          refresh();
          onClose();
        });
    }
  };

  const clearCanvas = () => {
    signaturePad.clear();
  };

  return (
    <div>
      <DialogTitle>Customer Signature</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ padding: 16, position: 'relative' }}>
            <div
              ref={el => {
                if (el) {
                  setWidth(el.offsetWidth);
                  setHeight(el.offsetHeight);
                }
              }}
              style={{
                height: '50vh',
                backgroundColor: '#eef5f9'
              }}
            >
              <canvas
                height={height}
                width={width}
                style={{ display: 'block', margin: '0' }}
                ref={signaturePadRef}
              />
            </div>
            <div style={{ position: 'absolute', bottom: 0, right: 20 }}>
              <Link onClick={clearCanvas}>reset</Link>
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Full Name"
              value={customerName}
              onChange={e => setCustomerName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 16 }}
          onClick={onClose}
        >
          close
        </Button>
        <Button variant="contained" color="secondary" onClick={save}>
          save
        </Button>
      </DialogActions>
    </div>
  );
}

export default SignatureModal;
