import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
  Paper,
  Typography,
  Button,
  Divider,
  CircularProgress,
  IconButton
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import {
  ProjectDetailsContext,
  ProjectChangeOrdersContext,
  RefreshProjectContext
} from '../../../../context/Project.context';
import CachedIcon from '@material-ui/icons/Cached';
import RecordList from './RecordList.component';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export default function DTools({ contentOnly = false }) {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const { setProjectChangeOrders } = useContext(ProjectChangeOrdersContext);
  const refreshProject = useContext(RefreshProjectContext);
  const [messageRecords, setMessageRecords] = useState(null);
  const [importRecords, setImportRecords] = useState(null);
  const [failedUpdates, setFailedUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUser();

  const canUse = currentUser.hasPermission('projects_tier_2');

  const importFromDtools = async () => {
    if (!canUse) return;
    try {
      setLoading(true);
      const {
        failedUpdates,
        projectDetails: detailUpdates,
        changeOrders: changeOrderUpdates,
        newImportRecords
      } = await http().post('/d-tools/import', {
        id: projectDetails.projectId
      });
      if (!newImportRecords.length) {
        setFailedUpdates(failedUpdates);
      }
      detailUpdates.forEach(newProjectDetails => {
        if (newProjectDetails.projectId === projectDetails.projectId)
          setProjectDetails(newProjectDetails);
      });
      setImportRecords(old => [
        ...old,
        ...newImportRecords.filter(
          other => other.projectId === projectDetails.projectId
        )
      ]);
      mergeChangeOrderUpdates(changeOrderUpdates);
      getMigrationMessages();
      refreshProject();
    } catch (err) {
      setLoading(false);
      Toast.show(err.message);
    }
  };

  const mergeChangeOrderUpdates = changeOrderUpdates => {
    setProjectChangeOrders(old => {
      const thisProject = changeOrderUpdates.filter(
        co => co.projectId === projectDetails.projectId
      );
      const updated = old.map(
        oldCo => thisProject.find(co => co.id === oldCo.id) || oldCo
      );
      const newCos = thisProject.filter(
        newCo => !old.find(oldCo => oldCo.id === newCo.id)
      );
      return [...newCos, ...updated];
    });
  };

  const getMigrationMessages = useCallback(() => {
    if (!canUse) return;
    setLoading(true);
    if (projectDetails.projectId)
      http()
        .get(`/d-tools/projects/${projectDetails.projectId}/records`)
        .then(({ messageRecords, importRecords }) => {
          setLoading(false);
          setMessageRecords(messageRecords);
          setImportRecords(importRecords);
        })
        .catch(err => {
          setLoading(false);
          Toast.show(err.message);
        });
  }, [projectDetails.projectId, canUse]);

  const exportToDtoolsProject = () => {
    if (!canUse) return;
    setLoading(true);
    http()
      .post(`/d-tools/projects/${projectDetails.projectId}`)
      .then(res => {
        getMigrationMessages();
        Toast.show(res);
      })
      .catch(err => {
        setLoading(false);
        Toast.show(err.message);
      });
  };

  const removeFailedUpdate = id => async () => {
    if (!canUse) return;
    try {
      await http().put(`/d-tools/import/${id}`);
      setFailedUpdates(updates => updates.filter(update => update.Id !== id));
      Toast.show('Failed import disregarded');
    } catch (err) {
      Toast.show(err.message);
    }
  };

  useEffect(() => {
    getMigrationMessages();
  }, [getMigrationMessages]);

  const alreadyOnDtools =
    !messageRecords ||
    messageRecords.length ||
    !importRecords ||
    importRecords.length;

  return React.createElement(
    contentOnly ? React.Fragment : Paper,
    contentOnly ? {} : { style: { padding: 16 } },
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <Typography component="h1" variant="h5" gutterBottom={!contentOnly}>
            SI Integration
          </Typography>
        </div>
        <IconButton size="small" onClick={getMigrationMessages}>
          <CachedIcon />
        </IconButton>
      </div>
      <Divider />
      <div style={{ paddingTop: 16 }}>
        {loading && <CircularProgress />}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {!loading && !alreadyOnDtools && (
            <Button
              disabled={!canUse}
              onClick={exportToDtoolsProject}
              variant="contained"
              color="primary"
            >
              Export to SI
            </Button>
          )}
          {!loading && (
            <Button
              disabled={!canUse}
              onClick={importFromDtools}
              variant="contained"
              color="primary"
            >
              Import from SI
            </Button>
          )}
        </div>
      </div>
      {!loading && messageRecords && (
        <RecordList
          messageRecords={messageRecords || []}
          importRecords={importRecords || []}
          failedUpdates={failedUpdates}
          removeFailedUpdate={removeFailedUpdate}
        />
      )}
    </>
  );
}
