import React, { useContext } from 'react';
import usePopper from 'hooks/usePopper.hook';
import { useColors } from 'helpers/theme.helper';
import {
  Checkbox,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import {
  ProjectContext,
  ProjectChecklistContext
} from 'routes/Projects/context/Project.context';
import getStatusText from './getStatusText.helper';
import moment from 'moment';
import LockIcon from '@material-ui/icons/Lock';

export default function ChecklistStep({
  item,
  locked = false,
  required,
  width
}) {
  const { setProjectChecklist } = useContext(ProjectChecklistContext);
  const currentUser = useCurrentUser();
  const { project } = useContext(ProjectContext);
  const { Popper, handleClick, close } = usePopper();
  const colors = useColors();

  const canMark = currentUser.hasPermission('checklist_tier_2');
  locked = locked || !canMark;

  const mark = data => async () => {
    if (locked) return;
    try {
      const updated = await http().put(
        `/projects/${project.id}/checklist-items/${item.id}`,
        data
      );

      setProjectChecklist(old => {
        const foundIdx = old.findIndex(other => other.id === updated.id);
        if (foundIdx === -1) return old;
        const copy = [...old];
        copy[foundIdx] = updated;
        return copy;
      });
      close();
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const requiredBorder = `2px solid ${colors.paperText}`;
  const backgroundColor =
    item.completed || item.color === 'green'
      ? colors.success
      : item.color === 'yellow'
      ? colors.lightYellow
      : item.color === 'red'
      ? colors.error
      : colors.grey;
  const opacity = backgroundColor === colors.error && !required ? 0.7 : 1;

  return (
    <>
      <Tooltip title={item.title}>
        <div
          onClick={handleClick}
          style={{
            width,
            cursor: 'pointer',
            padding: 3,
            background: backgroundColor,
            borderRight: `1px solid ${colors.lightGrey}`,
            borderBottom: required ? requiredBorder : null,
            color: '#ffffff',
            opacity
          }}
        >
          <Typography noWrap variant="caption" component="div">
            {item.title}
          </Typography>
        </div>
      </Tooltip>
      <Popper
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div style={{ maxWidth: 400 }}>
          <div style={{ margin: '5px 15px' }}>
            <ListItem style={{ padding: 0 }}>
              {locked && (
                <ListItemIcon style={{ minWidth: 35 }}>
                  <LockIcon />
                </ListItemIcon>
              )}
              <ListItemText>
                <Typography variant="h5">{item.title}</Typography>
              </ListItemText>
            </ListItem>

            {!!item.updatedByName && (
              <>
                <Typography
                  component="p"
                  color="textSecondary"
                  variant="caption"
                >
                  {bold(item.updatedByName)}
                </Typography>
                <Typography
                  component="p"
                  color="textSecondary"
                  variant="caption"
                >
                  {bold(moment(item.updatedOn).format('LLL'))}
                </Typography>
              </>
            )}

            {item.notes && (
              <Typography variant="caption" color="textSecondary">
                {item.notes}
              </Typography>
            )}
          </div>
          <Divider />
          <div style={{ marginBottom: 0 }}>
            {locked ? (
              <ListItem>
                <ListItemText primary={getStatusText(item)} />
              </ListItem>
            ) : (
              <>
                <ListItem
                  button
                  onClick={mark({
                    completed: false,
                    optional: false,
                    color: 'red'
                  })}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: colors.error }}
                      checked={item.color === 'red'}
                    />
                  </ListItemIcon>
                  <ListItemText primary={getStatusText('red')} />
                </ListItem>
                <ListItem
                  button
                  onClick={mark({
                    completed: false,
                    optional: false,
                    color: 'yellow'
                  })}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: colors.lightYellow }}
                      checked={item.color === 'yellow'}
                    />
                  </ListItemIcon>
                  <ListItemText primary={getStatusText('yellow')} />
                </ListItem>
                <ListItem
                  button
                  onClick={mark({
                    completed: true,
                    optional: false,
                    color: 'green'
                  })}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{ color: colors.success }}
                      checked={item.color === 'green'}
                    />
                  </ListItemIcon>
                  <ListItemText primary={getStatusText('green')} />
                </ListItem>
                {item.allowOptional && (
                  <ListItem
                    button
                    onClick={mark(
                      item.optional
                        ? {
                            completed: false,
                            optional: false,
                            color: 'red'
                          }
                        : {
                            completed: false,
                            optional: true,
                            color: 'gray'
                          }
                    )}
                  >
                    <ListItemIcon>
                      <Checkbox
                        style={{ color: colors.action }}
                        checked={item.color === 'gray' || item.optional}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Optional" />
                  </ListItem>
                )}
              </>
            )}
          </div>
        </div>
      </Popper>
    </>
  );
}

function bold(string) {
  return (
    <Typography component="span" variant="inherit" style={{ fontWeight: 500 }}>
      {string}
    </Typography>
  );
}
