import React, { useState, useEffect, useCallback } from 'react';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DragAndDrop, Toast, ConfirmDelete } from 'components';
import http from 'helpers/http.helper';
import ShipToOptionModal from './po-ship-to-option.modal';

const PoShipToOptions = () => {
  const [options, setOptions] = useState([]);
  const [editingOption, setEditingOption] = useState(false);
  const [createOptionOpen, setCreateOptionOpen] = useState(false);

  const getOptions = useCallback(() => {
    return http()
      .get('/po-ship-to-options')
      .then(setOptions)
      .catch(err => Toast.show(err.message));
  }, []);

  const removeOption = option => () => {
    http()
      .delete(`/po-ship-to-options/${option.id}`)
      .then(getOptions)
      .then(() => {
        Toast.show('Option removed.');
      })
      .catch(err => Toast.show(err.message));
  };

  const updateOrder = async options => {
    setOptions(options);
    await Promise.all(
      options.map((option, idx) =>
        http()
          .put(`/po-ship-to-options/${option.id}`, { order: idx })
          .catch(err => Toast.show(err.message))
      )
    );
    return getOptions();
  };

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <div>
      <ShipToOptionModal
        open={!!createOptionOpen || editingOption}
        onClose={() => {
          setEditingOption(null);
          setCreateOptionOpen(false);
        }}
        id={createOptionOpen ? null : editingOption ? editingOption.id : null}
        options={options}
        onUpdate={getOptions}
      />
      <Typography
        style={{ padding: 10, fontWeight: 400 }}
        component="h1"
        variant="h6"
        gutterBottom
      >
        PO Ship-To Options
        <IconButton
          onClick={() => {
            setEditingOption(null);
            setCreateOptionOpen(true);
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Typography>

      <DragAndDrop onDrop={updateOrder}>
        {options.map(option => (
          <div
            key={option.id}
            id={option.id}
            value={option}
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '0 1 100%'
              }}
            >
              <Typography variant="body2">{option.title}</Typography>
              <Typography variant="body2">{option.address}</Typography>
              <Typography variant="body2">{option.phoneNumber}</Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <div>
                <ConfirmDelete
                  iconButtonProps={{ size: 'small' }}
                  onConfirm={removeOption(option)}
                />
              </div>
              <div>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateOptionOpen(false);
                    setEditingOption(option);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </DragAndDrop>
    </div>
  );
};

export default PoShipToOptions;
