import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

type RefType<T> =
  | React.MutableRefObject<T | null>
  | ((instance: T | null) => void)
  | null;
interface ChildFunctionArgs {
  item: any;
  style: React.CSSProperties;
  index: number;
}
export type VirtualizedListChildFunction = (
  args: ChildFunctionArgs
) => JSX.Element;

export interface VirtualizedListProps {
  items: any[];
  itemHeight: number;
  children: VirtualizedListChildFunction;
  ref: RefType<List<any>>;
}

const VirtualizedList: React.FC<VirtualizedListProps> = React.forwardRef<
  List<any>,
  VirtualizedListProps
>(({ items, itemHeight, children }, ref) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={items.length}
          itemSize={itemHeight}
          width={width}
          ref={listRef => {
            if (typeof ref === 'function') {
              ref(listRef);
            } else if (ref !== null) {
              ref.current = listRef;
            }
          }}
        >
          {({ index, style }) => children({ item: items[index], index, style })}
        </List>
      )}
    </AutoSizer>
  );
});

export default VirtualizedList;
