import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  ProjectDetailsContext,
  ProjectScheduleTasksContext
} from 'routes/Projects/context/Project.context';
import { Typography } from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import 'react-calendar/dist/Calendar.css';
import calculateFloat from './helpers/calculateFloat.helper';
import ScheduleForm from './components/ScheduleForm.component';
import safeParse from 'helpers/safeParse.helper';
import TaskTable from './components/TaskTable.component';
import { Button } from '@material-ui/core';
import AddToShiftSchedule from './modals/AddToShiftSchedule.modal';
import useCurrentUser from 'hooks/useCurrentUser.hook';

const ProjectScheduleTab = () => {
  const currentUser = useCurrentUser();
  const { projectScheduleTasks, fetchTasks } = useContext(
    ProjectScheduleTasksContext
  );
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );

  const [editing, setEditing] = useState(false);
  const [taskSelectorOpen, setTaskSelectorOpen] = useState(false);
  const [scheduleFormData, setScheduleFormData] = useState(
    pluckDetails(projectDetails)
  );

  const reset = useCallback(() => {
    setEditing(false);
    setScheduleFormData(pluckDetails(projectDetails));
  }, [projectDetails]);

  useEffect(() => {
    reset();
  }, [reset]);

  const totalHours =
    safeParse(projectDetails.estimatedCoLaborHours) +
    safeParse(projectDetails.estimatedLaborHours);

  const totalTaskHours = projectScheduleTasks.reduce(
    (total, task) => total + safeParse(task.hours),
    0
  );
  const truncate = num => Math.floor(num * 100) / 100;
  const hoursOverEstimaste = truncate((totalHours - totalTaskHours) * -1);
  const hoursUnderEstimaste = truncate(totalHours - totalTaskHours);

  const skipDays = [0, 6];
  if (scheduleFormData.scheduleDaysPerWeek === 4) skipDays.push(5);

  const save = () => {
    const data = { ...scheduleFormData };
    delete data.scheduleWorkDays;
    http()
      .put(`/projects/${projectDetails.projectId}/details/1-5`, data)
      .then(data => setProjectDetails(data))
      .then(fetchTasks)
      .catch(err => Toast.show(err.message));
  };

  const float = calculateFloat({ ...projectDetails, ...scheduleFormData });

  return (
    <div>
      <Typography variant="h5">Total Project Hrs: {totalHours}</Typography>
      <ScheduleForm
        scheduleFormData={scheduleFormData}
        setScheduleFormData={setScheduleFormData}
        editing={editing}
        setEditing={setEditing}
        save={save}
        reset={reset}
        float={float}
      />

      <div style={{ paddingTop: 50, paddingBottom: 10 }}>
        {!!projectScheduleTasks.length && (
          <>
            <Typography variant="h5">
              Total Task Hours: {totalTaskHours}
            </Typography>
            {hoursUnderEstimaste !== 0 &&
              (totalHours - totalTaskHours < 0 ? (
                <Typography variant="subtitle1">
                  {hoursOverEstimaste}{' '}
                  {hoursOverEstimaste === 1 ? 'hour' : 'hours'} over estimate
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  {hoursUnderEstimaste}{' '}
                  {hoursUnderEstimaste === 1 ? 'hour' : 'hours'} under estimate
                </Typography>
              ))}
          </>
        )}
      </div>
      {currentUser.hasPermission('projects_tier_1.5') && (
        <>
          <Button
            style={{ marginBottom: 10 }}
            onClick={() => setTaskSelectorOpen(true)}
            variant="contained"
          >
            Add tasks to shift schedule
          </Button>
          <AddToShiftSchedule
            open={taskSelectorOpen}
            onClose={() => setTaskSelectorOpen(false)}
            tasks={projectScheduleTasks}
          />
        </>
      )}
      <TaskTable />
    </div>
  );
};

function pluckDetails(projectDetails) {
  return {
    scheduleOnMaster: projectDetails.scheduleOnMaster || false,
    start: projectDetails.start || null,
    end: projectDetails.end || null,
    estimatedCrew: projectDetails.estimatedCrew || 0,
    scheduleHrsPerDay: safeParse(projectDetails.scheduleHrsPerDay) || 10,
    scheduleDaysPerWeek: safeParse(projectDetails.scheduleDaysPerWeek) || 4,
    scheduleWorkDays:
      safeParse(projectDetails.scheduleHrsPerDay) &&
      safeParse(projectDetails.scheduleDaysPerWeek)
        ? `${safeParse(projectDetails.scheduleDaysPerWeek)},${safeParse(
            projectDetails.scheduleHrsPerDay
          )}`
        : '4,10'
  };
}

export default ProjectScheduleTab;
