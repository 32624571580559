import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { Toast } from 'components';
import { PDFDocument } from 'pdf-lib';
import React, { useEffect, useState } from 'react';
import PdfLibTable from './Helpers/Table.helper';

type CreatePdfFunction = (doc: PDFDocument) => PDFDocument;
interface PdfLibProps {
  createPdf?: CreatePdfFunction;
}

function PdfLibComponent({ createPdf }: PdfLibProps) {
  const [pdfData, setPdfData] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!createPdf) {
      setPdfData('');
      setLoading(false);
      setIsError(false);
      return;
    }
    let stale = false;
    setLoading(true);
    setIsError(false);
    PDFDocument.create()
      .then(async pdfDoc => {
        const updated = await createPdf(pdfDoc);
        if (stale) return;
        const base64URI = await updated.saveAsBase64({ dataUri: true });
        if (stale) return;
        setPdfData(base64URI);
      })
      .catch(err => {
        setIsError(true);
        console.error(err);
        Toast.show('Error loading PDF');
      })
      .finally(() => {
        if (!stale) setLoading(false);
      });

    return () => {
      stale = true;
    };
  }, [createPdf]);

  return (
    <Paper style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
      {isError ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="overline">Error loading PDF...</Typography>
        </div>
      ) : (
        <iframe
          title="pdf"
          style={{ display: 'block', width: '100%', height: '100%', border: 0 }}
          src={pdfData}
        />
      )}

      {!!loading && (
        <div
          style={{
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: 'rgb(0 0 0 / 18%)'
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
}
PdfLibComponent.PdfLibTable = PdfLibTable;

export default PdfLibComponent;
