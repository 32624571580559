import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, VirtualizedSearch } from 'components';

function AddWorkOrderMaterials(props) {
  const { onClose, workOrderId, projectId, refresh } = props;
  const [materialDescriptions, setMaterialDescriptions] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [itemDescription, setItemDescription] = useState('');

  useEffect(() => {
    // GET ALL MATERIALS
    http()
      .get('/materials')
      .then(res => {
        const descriptions = res.map(material => material.description);
        setMaterialDescriptions(descriptions);
      })
      .catch(err => Toast.show(err.message));
  }, []);

  const save = e => {
    e.preventDefault();

    http()
      .post(`/projects/${projectId}/work-orders/${workOrderId}/materials`, {
        quantity: quantity,
        itemDescription: itemDescription
      })
      .then(() => {
        refresh();
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <>
      <DialogTitle>Add Material to Work Order</DialogTitle>
      <DialogContent>
        <form autoComplete="off" onSubmit={save}>
          <TextField
            fullWidth
            label="Quantity"
            value={quantity}
            required
            onChange={e => setQuantity(e.target.value)}
            type="number"
            inputProps={{ step: '0.01' }}
            margin="normal"
          />
        </form>
        <div style={{ paddingTop: 10 }}>
          <VirtualizedSearch
            label="Item Description"
            allItems={materialDescriptions}
            showInputAsOption
            inputValue={itemDescription}
            setInputValue={setItemDescription}
            onChange={setItemDescription}
            TextFieldProps={{ variant: 'standard' }}
            autoselectTop
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          close
        </Button>
        <Button
          onClick={save}
          variant="contained"
          color="secondary"
          type="submit"
        >
          save
        </Button>
      </DialogActions>
    </>
  );
}

export default AddWorkOrderMaterials;
