import React, { useContext } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { ChildProjectsContext } from 'routes/Projects/context/ChildProjectsContext.context';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import { Link } from 'react-router-dom';

export default function HasRelatedProjectsWarning() {
  const colors = useColors();
  const { relatedProjectCount, loading } = useContext(ChildProjectsContext);
  const { project } = useContext(ProjectContext);

  if (loading || !relatedProjectCount || !project.id) return null;

  return (
    <Link
      style={{ color: 'inherit', textDecoration: 'inherit' }}
      to={`/projects/${project.id}/related-projects`}
    >
      <div
        style={{
          backgroundColor: colors.lightYellow,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Typography variant="overline">
          {project.projectNumber} has related projects
        </Typography>
      </div>
    </Link>
  );
}
