import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from 'helpers/themeSafeMui.helper';
import { UserContext } from '../context/User.context';
import http from 'helpers/http.helper';
import { Toast } from 'components';

const SuspendUser = ({ open, onClose }) => {
  const { user, setUser } = useContext(UserContext);

  const isSuspending = !user.temporarilySuspended;

  const suspend = () => {
    http()
      .post(`users/${user.id}/suspend`, {
        isSuspended: isSuspending
      })
      .then(updated => {
        setUser(updated);
        onClose();
        Toast.show('Updated');
      })
      .catch(e => Toast.show(e.message));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to{' '}
        {isSuspending
          ? `suspend ${user.name}'s STEPS access`
          : `revoke ${user.name}'s suspension`}
        ?
      </DialogTitle>

      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          cancel
        </Button>
        <Button onClick={suspend} color="primary">
          {isSuspending ? 'suspend' : 'revoke'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuspendUser;
