import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export const CompanyContext = React.createContext();
export const CompanyPeopleContext = React.createContext();
export const CompanyProjectsContext = React.createContext();

export default withRouter(props => {
  const companyId = props.match.params.id;
  const [company, setCompany] = useState({});
  const [companyPeople, setCompanyPeople] = useState([]);
  const [companyProjects, setCompanyProjects] = useState([]);

  useEffect(() => {
    http()
      .get(`/companies/${companyId}`)
      .then(res => setCompany(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/projects', { params: { companyId: companyId } })
      .then(res => setCompanyProjects(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/people', { params: { companyId: companyId } })
      .then(res => {
        const active = res.filter(person => !person.inactive);
        const inactive = res.filter(person => person.inactive);
        const sorted = [...active, ...inactive];
        setCompanyPeople(sorted);
      })
      .catch(err => Toast.show(err.message));
  }, [companyId]);

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>
      <CompanyPeopleContext.Provider
        value={{ companyPeople, setCompanyPeople }}
      >
        <CompanyProjectsContext.Provider
          value={{ companyProjects, setCompanyProjects }}
        >
          {props.children}
        </CompanyProjectsContext.Provider>
      </CompanyPeopleContext.Provider>
    </CompanyContext.Provider>
  );
});
