import React, { useEffect, useState } from 'react';
import { DashboardWrapper, Toast } from 'components';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import PerDiemTable from '../components/PerDiemTable.component';
import AddPerDiemModal from '../modals/EditPerDiem.modal';
import { IconButton, Typography, Paper } from 'helpers/themeSafeMui.helper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FilterPopover from '../components/FilterPopoverButton.component';
import EditPopover from '../components/EditPopover.component';

const AllPerDiem = () => {
  const user = useSelector(state => state.user);
  const [perDiems, setPerDiems] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filteredPerDiems, setFilteredPerDiems] = useState([]);
  const [selectedPerDiems, setSelecetedPerDiems] = useState([]);

  const createPerDiem = async data => {
    const copy = { ...data };
    delete copy.locked;
    delete copy.status;
    delete copy.paidOn;
    const newPerdiem = await http().post('/per-diems/', copy);
    setPerDiems(old => [...old, newPerdiem]);
  };

  useEffect(() => {
    if (user.id) {
      http()
        .get(`/per-diems`)
        .then(perDiems => setPerDiems(perDiems))
        .catch(err => Toast.show(err.message));
    }
  }, [user.id]);

  const sumOfSelected = selectedPerDiems.reduce(
    (sum, perDiem) =>
      sum === null
        ? parseFloat(perDiem.totalAmount)
        : sum + parseFloat(perDiem.totalAmount),
    null
  );

  return (
    <DashboardWrapper>
      <AddPerDiemModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create Per-Diem"
        hiddenFields={['status', 'locked']}
        onSubmit={createPerDiem}
        userSelect={true}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h5" component="h1">
            Per Diem List
            <IconButton onClick={() => setCreateModalOpen(true)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {sumOfSelected !== null && (
            <Paper style={{ marginRight: 20, padding: '6px 25px' }}>
              <Typography>
                Selected Total:
                <span style={{ font: 'bold 1.15em monospace', marginLeft: 8 }}>
                  ${sumOfSelected.toFixed(2)}
                </span>
              </Typography>
            </Paper>
          )}
          {!!selectedPerDiems.length && (
            <div style={{ paddingRight: 10 }}>
              <EditPopover
                selectedPerdiems={selectedPerDiems}
                setPerDiems={setPerDiems}
              />
            </div>
          )}
          <FilterPopover
            allPerDiems={perDiems}
            setFiltered={setFilteredPerDiems}
          />
        </div>
      </div>
      <PerDiemTable
        columns={[
          'lastName',
          'legalFirstName',
          'projectNumber',
          'startDate',
          'endDate',
          'dailyAmount',
          'totalAmount',
          'locked',
          'status',
          'paidOn'
        ]}
        setPerDiems={setPerDiems}
        perDiems={filteredPerDiems}
        height="calc(100vh - 160px)"
        setSelected={setSelecetedPerDiems}
      />
    </DashboardWrapper>
  );
};

export default AllPerDiem;
