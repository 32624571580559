import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';

export const EstimateContext = React.createContext(); // Context for the Estimate
export const PhaseContext = React.createContext(); // Context for the Estimate's Phases

const Estimate = props => {
  const [estimate, setEstimate] = useState(props.estimate);
  const [phases, _setPhases] = useState([]);

  const setPhases = phases => {
    const copy = JSON.parse(JSON.stringify(phases));
    copy.sort((a, b) => a.order - b.order);
    _setPhases(copy);
  };

  useEffect(() => {
    http()
      .get(`/projects/${estimate.projectId}/estimates/${estimate.id}/phases`)
      .then(res => {
        setPhases(res);
      });
  }, [estimate]);

  return (
    <EstimateContext.Provider value={{ estimate, setEstimate }}>
      <PhaseContext.Provider value={{ phases, setPhases }}>
        {props.children}
      </PhaseContext.Provider>
    </EstimateContext.Provider>
  );
};

export default Estimate;
