import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  styled,
  Paper
} from 'helpers/themeSafeMui.helper';
import { MaterialCard, MaterialsTable, Toast } from 'components';
import { Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';

const hiddenColumns = MaterialsTable.presetHiddenColumns.Master;
const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function ReplaceMaterialModal({
  open,
  onClose,
  onReplace,
  onSetHidden,
  targetMaterial,
  replacementOptions
}) {
  const [selectedReplacement, setSelectedReplacement] = useState(null);
  const [loading, setLoading] = useState(false);
  const isHidden = !!targetMaterial && !!targetMaterial.conflictHidden;

  useEffect(() => {
    if (open) setSelectedReplacement(null);
  }, [open]);

  function replace() {
    if (!targetMaterial || !selectedReplacement) return;
    setLoading(true);
    onReplace(targetMaterial.id, selectedReplacement.id)
      .then(() => setSelectedReplacement(null))
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }

  function clearReplacement() {
    if (!targetMaterial) return;
    setLoading(true);
    onReplace(targetMaterial.id, null)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }

  function toggleHidden() {
    if (!targetMaterial) return;
    setLoading(true);
    onSetHidden(targetMaterial.id, !isHidden)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }

  return (
    <FullHeightDialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Replace</DialogTitle>
      <Divider />
      {!selectedReplacement ? (
        <>
          <div
            style={{ display: 'flex', justifyContent: 'center', padding: 5 }}
          >
            <Paper>
              <MaterialCard
                style={{ width: 300 }}
                material={targetMaterial}
                hiddienFields={['quantity']}
              />
            </Paper>
          </div>
          <Divider />
          <DialogTitle>With</DialogTitle>
          <Divider />
          <DialogContent style={{ padding: 0 }}>
            <MaterialsTable
              materials={replacementOptions}
              onSelect={setSelectedReplacement}
              localStorageKey="GlobalMaterials"
              removeColumns={hiddenColumns}
              noItemsResultType="Replacement Options"
            />
          </DialogContent>
        </>
      ) : (
        <DialogContent
          style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 5,
              flex: '1 0 auto'
            }}
          >
            <Paper elevation={2}>
              <Typography
                variant="h5"
                style={{ textAlign: 'center', paddingTop: 5 }}
              >
                MATERIAL-A
              </Typography>
              <Divider />
              <MaterialCard
                style={{ width: 300 }}
                material={targetMaterial}
                hiddienFields={['quantity']}
              />
            </Paper>
            <div style={{ padding: '0 16px' }}>
              <Typography variant="h5">Will be replaced with</Typography>
              <div style={{ fontSize: 60 }}>
                <ArrowRightIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    transform: 'translateY(-12px)'
                  }}
                  fontSize="inherit"
                />
              </div>
            </div>
            <Paper elevation={2}>
              <Typography
                variant="h5"
                style={{ textAlign: 'center', paddingTop: 5 }}
              >
                MATERIAL-B
              </Typography>
              <Divider />
              <MaterialCard
                style={{ width: 300 }}
                material={selectedReplacement}
                hiddienFields={['quantity']}
              />
            </Paper>
          </div>
          <Divider />
          <div style={{ padding: 10 }}>
            <Typography
              color="error"
              component="p"
              variant="caption"
              style={{
                margin: 'auto',
                maxWidth: 900,
                whiteSpace: 'pre-wrap',
                fontSize: 12
              }}
            >
              {disclaimerText}
            </Typography>
          </div>
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !targetMaterial}
          onClick={toggleHidden}
        >
          {isHidden && 'un'}hide conflict
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            loading || !targetMaterial || !targetMaterial.conflictReplaceWith
          }
          onClick={clearReplacement}
        >
          Clear Replacement
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedReplacement || loading}
          onClick={replace}
        >
          Replace
        </Button>
        <Button
          variant="contained"
          disabled={!selectedReplacement}
          onClick={() => setSelectedReplacement(null)}
        >
          Reset
        </Button>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </FullHeightDialog>
  );
}
const disclaimerText = `
All references to MATERIAL-A found in current projects will be replaced by references to MATERIAL-B.
Any new material imports that match MATERIAL-A will have a reference to MATERIAL-B.
New imports that have the same Manufacturer, Model, Part Number, and Description as MATERIAL-A will be reference MATERIAL-B.
`.trim();
