import {
  Button,
  IconButton,
  Grid,
  Typography,
  Divider
} from 'helpers/themeSafeMui.helper';
import React, { useState } from 'react';
import EditPoModal from '../modals/EditPo.modal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ConfirmDelete, MaterialsTable, Toast } from 'components';
import { poKeyLabelMap, poKeys } from '../helpers/poFields.helper';
import moment from 'moment';
import http from 'helpers/http.helper';
import { useRouteMatch } from 'react-router-dom';
import EditPoMaterial from '../modals/EditPoMaterial.modal';
import PdfModal from '../modals/Pdf.modal';
import EmailPdfModal from '../modals/SendEmail.modal';

import safeParse from 'helpers/safeParse.helper';

const hiddenColumns = MaterialsTable.presetHiddenColumns.PO;

export default function Po({ getPos, po, items, shipToOptions }) {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const [expanded, setExpanded] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [editingMaterial, setEditingMaterial] = useState(null);

  const poDate = po && po.date ? moment(po.date) : null;
  const dateText = poDate ? poDate.format('ddd, ll') : poDate;
  const total = parseFloat(
    items
      .map(mat => {
        return (
          Math.round(safeParse(mat.unitCost) * safeParse(mat.quantity) * 100) /
          100
        );
      })
      .reduce((sum, num) => sum + num, 0)
      .toFixed(2)
  );

  const deletePo = () => {
    if (!po || !po.id) return;
    http()
      .delete(`/projects/${projectId}/pos/${po.id}`)
      .then(getPos)
      .catch(Toast.showErr);
  };
  const sliceIdx = Math.floor(poKeys.length / 2);
  const leftKeys = poKeys.slice(0, sliceIdx);
  const rightKeys = poKeys.slice(sliceIdx);

  return (
    <>
      <EditPoMaterial
        open={!!editingMaterial}
        onClose={() => setEditingMaterial(null)}
        getPos={getPos}
        poMaterial={editingMaterial}
      />
      <EditPoModal
        open={editOpen}
        onClose={() => setEditOpen(false)}
        getPos={getPos}
        po={po}
        poMaterials={items}
        shipToOptions={shipToOptions}
      />
      <PdfModal
        open={pdfOpen}
        onClose={() => setPdfOpen(false)}
        po={po}
        items={items}
      />
      <EmailPdfModal
        open={emailOpen}
        onClose={() => setEmailOpen(false)}
        po={po}
        projectId={projectId}
        id={po.id}
        items={items}
        onSend={() => {
          getPos();
          setEmailOpen(false);
        }}
      />
      <div
        onClick={() => setExpanded(!expanded)}
        style={{
          display: 'flex',
          padding: 12,
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <div>
          <Typography variant="h6">
            {po.poNumber}
            <Typography
              component="span"
              style={{ marginLeft: 10 }}
              variant="subtitle2"
            >
              {po.to}
            </Typography>
          </Typography>
          <div>
            <Typography component="span" variant="subtitle1">
              {dateText}
            </Typography>
          </div>
          <Typography component="span" variant="subtitle2">
            {`Sub Total: $${total}`}
          </Typography>
          {expanded && !!po.emailSentOn && (
            <Typography
              style={{ marginTop: 4 }}
              color="textSecondary"
              component="div"
              variant="caption"
            >
              Email from{' '}
              <Typography
                style={{ lineHeight: 1 }}
                color="textPrimary"
                component="span"
                variant="overline"
              >
                {po.emailSentByUserName}
              </Typography>{' '}
              sent on{' '}
              <Typography
                style={{ lineHeight: 1 }}
                color="textPrimary"
                component="span"
                variant="overline"
              >
                {moment(po.emailSentOn).format('LLLL')}
              </Typography>
            </Typography>
          )}
        </div>
        <div style={{ flex: '1 0 0' }} />
        {expanded && (
          <div style={{ display: 'flex' }}>
            <div onClick={e => e.stopPropagation()}>
              <ConfirmDelete onConfirm={deletePo}>
                <Button variant="contained" color="primary">
                  Delete PO
                </Button>
              </ConfirmDelete>
            </div>

            <Button
              style={{ margin: '0 10px' }}
              variant="contained"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                setEmailOpen(true);
              }}
            >
              Email PO
            </Button>

            <Button
              style={{ margin: '0 10px' }}
              variant="contained"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                setEditOpen(true);
              }}
            >
              Edit PO
            </Button>
          </div>
        )}
        <div>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              setPdfOpen(true);
            }}
          >
            <PictureAsPdfIcon />
          </IconButton>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      {expanded && (
        <>
          <Divider />
          <Grid container style={{ paddingTop: 14 }}>
            <Grid item xs={12} md={6}>
              <div style={{ paddingLeft: 12 }}>
                <div style={{ padding: '4px 16px 0' }}>
                  {leftKeys.map(key => (
                    <PoField key={key} po={po} fieldKey={key} />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ paddingLeft: 12 }}>
                <div style={{ padding: '4px 16px 0' }}>
                  {rightKeys.map(key => (
                    <PoField key={key} po={po} fieldKey={key} />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 14 }}>
              <Divider />

              <div style={{ maxHeight: '60vh', height: '100%', width: '100%' }}>
                <MaterialsTable
                  localStorageKey="ProjectMaterials"
                  initialRowsPerPage={100}
                  materials={items || []}
                  removeColumns={hiddenColumns}
                  elevation={0}
                  onSelect={setEditingMaterial}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

function PoField({ po, fieldKey, value: valueProp }) {
  const label = poKeyLabelMap[fieldKey];
  const value = (po && po[fieldKey]) || valueProp;
  const noValue = !value || !`${value}`.trim();
  return (
    <div>
      <Typography
        color="textSecondary"
        style={{ fontWeight: 500, display: 'block' }}
        variant="caption"
      >
        {label}:
      </Typography>
      <Typography
        color={noValue ? 'error' : 'textPrimary'}
        style={{ marginLeft: 12, whiteSpace: 'pre-wrap' }}
      >
        {noValue ? 'N/A' : value}
      </Typography>
    </div>
  );
}
