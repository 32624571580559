import React, { useEffect, useState } from 'react';
import { CustomInput, Toast } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { useRouteMatch } from 'react-router';

export default function EditPoMaterial({
  open,
  onClose,
  getPos,
  poMaterial: poMaterialProp
}) {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const [poMaterial, setPoMaterial] = useState({
    quantity: 0,
    manufacturer: '',
    model: '',
    partNumber: '',
    description: '',
    unitCost: '',
    unitLabor: '',
    specSheet: '',
    OM: ''
  });

  useEffect(() => {
    if (open && poMaterialProp)
      setPoMaterial({
        quantity: poMaterialProp.quantity,
        manufacturer: poMaterialProp.manufacturer,
        model: poMaterialProp.model,
        partNumber: poMaterialProp.partNumber,
        description: poMaterialProp.description,
        unitCost: poMaterialProp.unitCost,
        unitLabor: poMaterialProp.unitLabor,
        specSheet: poMaterialProp.specSheet,
        OM: poMaterialProp.OM
      });
  }, [open, poMaterialProp]);

  const editMaterial = () => {
    if (poMaterialProp && projectId)
      http()
        .put(`/projects/${projectId}/pos/item/${poMaterialProp.id}`, {
          ...poMaterial
        })
        .then(getPos)
        .then(onClose)
        .catch(Toast.showErr);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Update PO Material</DialogTitle>
      <Divider />
      <DialogContent>
        <PoField
          label="Quantity"
          dataKey="quantity"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Manufacturer"
          dataKey="manufacturer"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Model"
          dataKey="model"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Part Number"
          dataKey="partNumber"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Description"
          dataKey="description"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Unit Cost"
          dataKey="unitCost"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Unit Labor"
          dataKey="unitLabor"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="Spec Sheet"
          dataKey="specSheet"
          data={poMaterial}
          setData={setPoMaterial}
        />
        <PoField
          label="OM"
          dataKey="OM"
          data={poMaterial}
          setData={setPoMaterial}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={editMaterial}>
          Save
        </Button>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PoField({ label, dataKey, data, setData }) {
  const handleChange = val => setData(old => ({ ...old, [dataKey]: val }));

  return (
    <CustomInput label={label} value={data[dataKey]} onChange={handleChange} />
  );
}
