import React from 'react';
import { Snackbar, IconButton } from 'helpers/themeSafeMui.helper';
import CloseIcon from '@material-ui/icons/Close';
import pubsub from 'helpers/pubsub.helper';

export default class Toast extends React.Component {
  state = {
    message: '',
    open: false
  };

  static show(message) {
    pubsub.publish('TOAST_MESSAGE', message);
  }

  static showErr = err => this.show(err.message);

  componentDidMount() {
    this.subscription = pubsub.subscribe('TOAST_MESSAGE', message => {
      this.setState({ message: message, open: true });
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  close = (e, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ open: false, message: '' });
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.close}
        message={this.state.message}
        disableWindowBlurListener
        action={
          <IconButton color="inherit" onClick={this.close}>
            <CloseIcon />
          </IconButton>
        }
      />
    );
  }
}
