import React from 'react';
import { IconButton, Tooltip } from 'helpers/themeSafeMui.helper';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { ExternalLink } from 'components';

const FeedbackForm = React.forwardRef(({ color = 'white' }, ref) => {
  return (
    <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSfoZ8fQ1fkFf3c_Vq1kMX0Cz5SsF1J_HZPnL18X0rC4YVAlow/viewform">
      <Tooltip title="Feedback Form">
        <IconButton ref={ref} color="inherit" style={{ color }}>
          <FeedbackIcon />
        </IconButton>
      </Tooltip>
    </ExternalLink>
  );
});

export default FeedbackForm;
