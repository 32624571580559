import React, { useState, useEffect } from 'react';
import {
  DashboardWrapper,
  Toast,
  SortingTable,
  ProjectFilterDropdown
} from 'components';
import { Typography } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import moment from 'moment';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10
  }
};

const allColumns = [
  {
    key: 'name',
    label: 'Name',
    extractor: row => {
      const name = `${row.projectNumber} - ${row.name}`;
      return name.length < 35 ? name : name.slice(0, 32) + '...';
    }
  },
  {
    key: 'status',
    label: 'Status',
    customSort: (a, b) => a.statusOrder < b.statusOrder
  },
  { key: 'accountManagerName', label: 'Account Manager' },
  { key: 'projectManagerName', label: 'Project Manager' },
  { key: 'leadTechnicianName', label: 'Lead Tech' },
  {
    key: 'bidDueDate',
    label: 'Bid Due Date',
    customSort: (a, b) => {
      if (!a.bidDueDate) return false;
      if (!b.bidDueDate) return true;
      return moment(a.bidDueDate).isBefore(b.bidDueDate);
    },
    extractor: row => row.bidDueDate && moment(row.bidDueDate).format('LL')
  }
];

const tier1Columns = allColumns.filter(col => col.key !== 'bidDueDate');

function Projects(props) {
  const { history, user } = props;
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const tier2User = user.hasPermission('projects_tier_2');

  useEffect(() => {
    http()
      .get('/projects')
      .then(res => setProjects(res))
      .catch(err => Toast.show(err.message));
  }, []);

  return (
    <DashboardWrapper>
      <div style={styles.header}>
        <Typography component="h1" variant="h4">
          Project List
        </Typography>

        <div style={{ display: 'flex' }}>
          <ProjectFilterDropdown
            projects={projects}
            onUpdate={setFilteredProjects}
            localStorageRootKey="project_list"
          />
        </div>
      </div>

      <SortingTable
        columns={tier2User ? allColumns : tier1Columns}
        rows={filteredProjects}
        rowAction={row => history.push(`/projects/${row.id}`)}
      />
    </DashboardWrapper>
  );
}

export default connect(state => ({ user: state.user }))(Projects);
