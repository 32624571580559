import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export default function NewTabLink(props: LinkProps) {
  const { to } = props;
  return (
    <Link
      {...props}
      onClick={e => e.stopPropagation()}
      target="_blank"
      rel="noopener noreferrer"
      to={to}
    >
      {props.children}
    </Link>
  );
}
