import React from 'react';
import { Toast, CustomInput } from 'components';
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
  IconButton
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import http from 'helpers/http.helper';
import { withRouter } from 'react-router-dom';

const styles = {
  deleteIcon: { position: 'absolute', top: 10, right: 10 }
};

class EditTrainingModule extends React.Component {
  state = {
    name: this.props.trainingModule.name,
    courseLink: this.props.trainingModule.courseLink || '',
    testLink: this.props.trainingModule.testLink || '',
    handoutLink: this.props.trainingModule.handoutLink || '',
    daysToExpire: this.props.trainingModule.daysToExpire || '',
    selectedMoveToGroup: null
  };

  archive = () => {
    const trainingGroup = this.props.trainingGroup;
    const trainingModuleToDelete = this.props.trainingModule;

    const tempList = this.props.trainingModules.filter(
      trainingModule => trainingModule.id !== trainingModuleToDelete.id
    );

    http()
      // First we are deleting the module from the list
      .delete(
        `/training-groups/${trainingGroup.id}/training-modules/${trainingModuleToDelete.id}`
      )
      .then(() => {
        // Next, we are updating the order of the items in the list

        return Promise.all(
          tempList.map((trainingModule, index) =>
            this.updateListItem(trainingModule, index)
          )
        );
      })
      .then(() => this.props.refresh());

    this.props.onClose();
  };

  updateListItem = (trainingModule, index) => {
    http()
      .put(
        `/training-groups/${this.props.trainingGroup.id}/training-modules/${trainingModule.id}`,
        { order: index + 1 }
      )
      .catch(err => Toast.show(err.message));
  };

  moveToGroup = () => {
    const moving = this.props.trainingModule;
    const trainingGroupId = this.state.selectedMoveToGroup;
    http()
      .put(
        `/training-groups/${this.props.trainingGroup.id}/training-modules/${moving.id}`,
        { trainingGroupId }
      )
      .then(() =>
        Promise.all(
          this.props.trainingModules
            .filter(other => other.id !== moving.id)
            .map((module, idx) => this.updateListItem(module, idx))
        )
      )
      .then(() => {
        this.props.refresh();
        this.props.onClose();
        Toast.show('Moved.');
      })
      .catch(err => Toast.show(err.message));
  };

  moveToGroupOptions = () =>
    [
      {
        label: 'Keep',
        value: null
      }
    ].concat(
      this.props.trainingGroups
        .map(group => ({
          label: group.name,
          value: group.id
        }))
        .filter(option => option.value !== this.props.trainingGroup.id)
    );

  save = e => {
    e.preventDefault();
    http()
      .put(
        `/training-groups/${this.props.trainingGroup.id}/training-modules/${this.props.trainingModule.id}`,
        {
          name: this.state.name,
          courseLink: this.state.courseLink,
          testLink: this.state.testLink,
          handoutLink: this.state.handoutLink,
          daysToExpire: this.state.daysToExpire || 0
        }
      )
      .then(() => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  editQuiz = () => {
    http()
      .get(`/training-quizzes?trainingModuleId=${this.props.trainingModule.id}`)
      .then(moduleQuizzes => {
        if (!moduleQuizzes.length)
          return http().post('/training-quizzes', {
            trainingModuleId: this.props.trainingModule.id,
            questions: []
          });
        return moduleQuizzes[0];
      })
      .then(quiz => {
        this.props.history.push(`/training-quizzes/${quiz.id}/edit`);
      })
      .then(this.props.onClose)
      .catch(Toast.showErr);
  };

  removeQuiz = () => {
    http()
      .delete(`/training-quizzes/${this.props.trainingModule.quizId}`)
      .then(this.props.refresh)
      .then(this.props.onClose)
      .catch(Toast.showErr);
  };

  render() {
    const {
      name,
      courseLink,
      testLink,
      handoutLink,
      daysToExpire,
      selectedMoveToGroup
    } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.save} style={{ padding: '16px' }}>
        <div style={styles.deleteIcon}>
          {this.state.showDeleteConfirm ? (
            <Typography>
              Are you sure?{' '}
              <Button color="primary" size="small" onClick={this.archive}>
                Delete
              </Button>
            </Typography>
          ) : (
            <IconButton
              onClick={() => this.setState({ showDeleteConfirm: true })}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>

        <DialogContent style={{ padding: 10 }}>
          <TextField
            label="Training Module Name"
            fullWidth
            required
            value={name}
            name="name"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Days to Expire"
            type="number"
            fullWidth
            value={daysToExpire}
            name="daysToExpire"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            autoFocus
            label="Course Link"
            fullWidth
            required
            value={courseLink}
            name="courseLink"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Testing Link"
            fullWidth
            value={testLink}
            name="testLink"
            margin="normal"
            onChange={this.handleChange}
          />
          <TextField
            label="Handout Link"
            fullWidth
            value={handoutLink}
            name="handoutLink"
            margin="normal"
            onChange={this.handleChange}
          />
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <CustomInput
              type="dropdown"
              label="Move To Different Group:"
              value={selectedMoveToGroup}
              onChange={val => this.setState({ selectedMoveToGroup: val })}
              options={this.moveToGroupOptions()}
            />
            <div>
              <Button
                style={{ marginLeft: 10 }}
                onClick={this.moveToGroup}
                disabled={!selectedMoveToGroup}
                variant="contained"
                size="small"
              >
                Move
              </Button>
            </div>
          </div>
          <Button variant="contained" onClick={this.editQuiz}>
            {this.props.trainingModule.quizId ? 'Edit Quiz' : 'Create Quiz'}
          </Button>
          {this.props.trainingModule.quizId && (
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              onClick={this.removeQuiz}
            >
              remove quiz
            </Button>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default withRouter(EditTrainingModule);
