import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Typography,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip
} from 'helpers/themeSafeMui.helper';
import UsersIcon from '@material-ui/icons/People';
import AdminSettings from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/Contacts';
import CompaniesIcon from '@material-ui/icons/Business';
import ProjectsIcon from '@material-ui/icons/Folder';
import ProjectBoardIcon from '@material-ui/icons/TableChart';
import MessageIcon from '@material-ui/icons/Message';
import TodayIcon from '@material-ui/icons/Today';
import { GoBeaker } from 'react-icons/go';
import { FaWarehouse } from 'react-icons/fa';
import PerDiemIcon from '@material-ui/icons/AttachMoney';
import SchoolIcon from '@material-ui/icons/School';
import ProjectChartsIcon from '@material-ui/icons/BarChart';
import ProjectCompletionsIcon from '@material-ui/icons/DoneAll';
import ShiftScheduleIcon from '@material-ui/icons/EventAvailable';
import useBreakpoint from 'hooks/useBreakpoint.hook';

function SideMenu(props) {
  const { user, open, setSmallScreenOpen, openWidth, closedWidth } = props;
  const isSmallScreen = useBreakpoint('xs');

  const menuItems = [
    { title: 'Home', icon: <HomeIcon />, path: '/home' },
    {
      title: 'Project Board',
      icon: <ProjectBoardIcon />,
      path: '/project-board',
      permission: 'projects_tier_1.5'
    },
    {
      title: 'Project List',
      icon: <ProjectsIcon />,
      path: '/projects',
      permission: 'projects_tier_1'
    },
    {
      title: 'WIP Report',
      icon: <ProjectChartsIcon />,
      path: '/wip-report',
      permission: 'projects_tier_2'
    },
    {
      title: 'Project Completions',
      icon: <ProjectCompletionsIcon />,
      path: '/project-completions',
      permission: 'projects_tier_2'
    },
    {
      title: 'Master Schedule',
      icon: <TodayIcon />,
      path: '/project-schedule',
      permission: 'projects_tier_2'
    },
    {
      title: 'Shift Schedule',
      icon: <ShiftScheduleIcon />,
      path: '/shift-schedule'
    },
    {
      title: 'People',
      icon: <PeopleIcon />,
      path: '/people',
      permission: 'companies_people'
    },
    {
      title: 'Companies',
      icon: <CompaniesIcon />,
      path: '/companies',
      permission: 'companies_people'
    },
    { title: 'Users', icon: <UsersIcon />, path: '/users' },
    {
      title: 'Materials',
      icon: <FaWarehouse size={20} />,
      path: '/materials',
      permission: 'materials_tier_1'
    },
    {
      title: 'Per Diem',
      icon: <PerDiemIcon />,
      path: '/per-diems/all',
      permission: 'per_diem_tier_2'
    },
    {
      title: 'Messaging',
      icon: <MessageIcon />,
      path: '/messaging',
      permission: 'mass_messages'
    },
    {
      title: 'Drug Test Locations',
      icon: <GoBeaker size={20} />,
      path: '/edit-drug-test-locations',
      permission: 'drug_test_admin'
    },
    {
      title: 'Training Groups',
      icon: <SchoolIcon />,
      path: '/training-groups',
      permission: 'training_module_admin'
    },
    {
      title: 'Admin Settings',
      icon: <AdminSettings />,
      path: '/admin-settings',
      permission: 'super_admin'
    }
  ];

  const styles = {
    open: {
      width: openWidth,
      overflowX: 'hidden',
      transition: 'width .3s'
    },
    closed: {
      width: closedWidth,
      overflowX: 'hidden',
      transition: 'width .3s'
    },
    pad: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16
    }
  };

  const items = (
    <List>
      {menuItems
        .filter(
          menuItem =>
            !menuItem.permission || user.hasPermission(menuItem.permission)
        )
        .map(menuItem => (
          <div key={menuItem.title} style={{ whiteSpace: 'nowrap' }}>
            <ListItem
              button
              style={{ padding: 0 }}
              onClick={() => setSmallScreenOpen(false)}
              component={Link}
              to={menuItem.path}
              selected={props.match.path.includes(menuItem.path)}
            >
              <div style={{ width: closedWidth, height: 48, flex: '0 0 auto' }}>
                <Tooltip title={menuItem.title}>
                  <Typography
                    component="div"
                    color="textSecondary"
                    style={{
                      ...styles.pad,
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {menuItem.icon}
                  </Typography>
                </Tooltip>
              </div>

              <ListItemText primary={menuItem.title} />
            </ListItem>
            {menuItem.divide && <Divider />}
          </div>
        ))}
    </List>
  );

  return (
    <Drawer
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      anchor="left"
      onClose={() => setSmallScreenOpen(false)}
      open={open}
      style={open ? styles.open : styles.closed}
      PaperProps={{ style: open ? styles.open : styles.closed }}
    >
      <Toolbar />
      {items}
    </Drawer>
  );
}

export default withRouter(connect(state => ({ user: state.user }))(SideMenu));
