import React, { useContext, useEffect, useState } from 'react';
import { AreaFilterButton, SelectionSortingTable } from 'components';
import moment from 'moment';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { UsersContext } from '../context/Users.context';
import { fromDateString } from '../helpers/DateTime.helper';
import ShiftUserTableCard from '../components/ShiftUserTableCard.component';
import WeekPicker from '../components/WeekPicker.component';
import { Button, Link } from 'helpers/themeSafeMui.helper';
import { Link as RouterLink } from 'react-router-dom';
import ProjectShiftSearch from '../components/ProjectShiftSearch.component';
import useModified from 'hooks/useModified.hook';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

export default function ShiftsByUser() {
  const columns = useUserShiftTableColumns();
  const { users } = useContext(UsersContext);
  const { dateWindow, setDateWindow, hideWeekend, setHideWeekend } = useContext(
    ShiftScheduleContext
  );
  const [
    selectedAreas,
    setSelectedAreas
  ] = useLocalStorage('user_shifts_area_filter', [], joi =>
    joi.array().items(joi.object({ id: joi.string().uuid() }).unknown())
  );

  const filteredUsers = useModified(
    users =>
      (users || []).filter(
        user =>
          !selectedAreas.length ||
          selectedAreas.find(area => area.id === user.areaId)
      ),
    users,
    [selectedAreas]
  );

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ zIndex: 2 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ProjectShiftSearch />
            <Link component={RouterLink} to="/shift-schedule">
              <Button variant="contained">Shift Schedule</Button>
            </Link>
            <div style={{ flex: '0 1 600px' }} />
          </div>
        </div>
        <div style={{ zIndex: 1, display: 'flex', alignItems: 'center' }}>
          <WeekPicker
            weekendHidden={hideWeekend}
            setWeekendHidden={setHideWeekend}
            dateWindow={dateWindow}
            setDateWindow={setDateWindow}
            style={{ flexGrow: 1 }}
          />
          <div style={{ paddingRight: 10 }}>
            <AreaFilterButton
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              buttonText="Filter Users By Area"
            />
          </div>
        </div>
      </div>
      <SelectionSortingTable
        rootStyles={{ height: 'calc(100vh -  205px)' }}
        isSelecting={false}
        rows={filteredUsers || []}
        columns={columns}
      />
    </div>
  );
}

function useUserShiftTableColumns() {
  const { dateWindow, shiftMap, userIdShiftMap, hideWeekend } = useContext(
    ShiftScheduleContext
  );

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const startM = moment(dateWindow[0]);
    const dayMoments = [];
    for (let i = 0; i < 7; i++) {
      if (hideWeekend && (i === 0 || i === 6)) continue;
      const dayMoment = moment(startM).day(i);
      dayMoments.push(dayMoment);
    }

    const dayColumns = dayMoments.map(m => {
      const dayMoment = moment(m);
      return {
        key: dayMoment.format('LL'),
        label: moment(dayMoment).format('ddd Do'),
        extractor: row => row.id,
        modifier: userId => {
          const shiftUsers = shiftsOnDay(
            dayMoment,
            userIdShiftMap[userId] || []
          );
          return (
            <div>
              {shiftUsers.map((shiftUser, idx) => (
                <React.Fragment key={shiftUser.id}>
                  {idx !== 0 && <div style={{ paddingTop: 8 }} />}
                  <ShiftUserTableCard shiftUser={shiftUser} />
                </React.Fragment>
              ))}
            </div>
          );
        }
      };
    });

    setColumns([
      {
        key: 'name',
        label: 'Name'
      },
      ...dayColumns
    ]);
  }, [dateWindow, hideWeekend, shiftMap, userIdShiftMap]);

  return columns;
}

function shiftsOnDay(day, shifts) {
  day = moment(day);
  const same = shifts.filter(shift =>
    fromDateString(shift.date).isSame(day, 'day')
  );
  return same;
}
