import React, { useState, useEffect, useCallback } from 'react';
import { DashboardWrapper, Toast } from 'components';
import http from 'helpers/http.helper';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MaterialsNonConflictRoute from './routes/Materials.route';
import MaterialsConflictsRoute from './routes/Conflicts.route';

function MaterialsRoute() {
  const [materials, setMaterials] = useState([]);
  const [conflicts, setConflicts] = useState([]);
  let match = useRouteMatch();

  const getMaterials = useCallback(() => {
    return Promise.all([
      http().get('/materials'),
      http().get('/materials/conflicts')
    ])
      .then(([materials, conflicts]) => {
        setMaterials(materials);
        setConflicts(conflicts);
      })
      .catch(Toast.showErr);
  }, []);

  const submitMaterial = (method, update) => {
    if (!(method === 'put' || method === 'post')) return null;
    let path = '/materials/';
    if (method === 'put') path = `/materials/${update.id}`;
    return http()
      [method](path, {
        manufacturer: update.manufacturer,
        model: update.model,
        partNumber: update.partNumber,
        description: update.description,
        unitCost: update.unitCost,
        unitLabor: update.unitLabor,
        specSheet: update.specSheet,
        OM: update.OM
      })
      .then(() => {
        return getMaterials();
      });
  };

  const removeMaterial = (material, fetchAfter = true) => {
    return http()
      .delete(`/materials/${material.id}`)
      .then(() => {
        if (fetchAfter) return getMaterials();
      });
  };

  window.deleteAllMats = async () => {
    await Promise.all(materials.map(m => removeMaterial(m, false)));
    await getMaterials();
  };

  const setReplaceWith = (targetId, replacementId) => {
    return http()
      .put('/materials/conflict-replacement', { targetId, replacementId })
      .then(() => {
        return getMaterials();
      });
  };

  const setConflictHidden = (materialId, hidden) => {
    return http()
      .put(`/materials/${materialId}`, { conflictHidden: hidden })
      .then(() => {
        getMaterials();
      });
  };

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  return (
    <DashboardWrapper setContentHeight>
      <Switch>
        <Route path={`${match.url}/conflicts`}>
          <MaterialsConflictsRoute
            conflicts={conflicts}
            setReplaceWith={setReplaceWith}
            setConflictHidden={setConflictHidden}
            removeMaterial={removeMaterial}
          />
        </Route>
        <Route path={`${match.url}/`}>
          <MaterialsNonConflictRoute
            materials={materials}
            submitMaterial={submitMaterial}
            removeMaterial={removeMaterial}
          />
        </Route>
      </Switch>
    </DashboardWrapper>
  );
}

export default MaterialsRoute;
