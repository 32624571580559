import React, { useState, useEffect } from 'react';
import { Button, Typography, InputBase } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';

const EditableTitle = ({
  title,
  canEdit,
  onUpdate,
  inputProps = {},
  inputBaseProps = {},
  typographyProps = {},
  buttonProps = {},
  multiLine = false
}) => {
  const [stateTitle, setStateTitle] = useState(title);
  const [focused, setFocused] = useState(false);
  const colors = useColors();

  useEffect(() => {
    setStateTitle(title);
  }, [title, setStateTitle]);

  return (
    <Typography
      variant="h5"
      {...typographyProps}
      onBlur={() => {
        setFocused(false);
      }}
    >
      {canEdit ? (
        <form
          style={{
            display: 'flex',
            ...(multiLine ? { flexWrap: 'wrap', justifyContent: 'center' } : {})
          }}
          onSubmit={e => {
            e.preventDefault();
            document.activeElement.blur();
            onUpdate(stateTitle);
            setFocused(false);
          }}
        >
          <InputBase
            {...inputBaseProps}
            onFocus={() => setFocused(true)}
            readOnly={false}
            value={stateTitle}
            onBlur={e => {
              e.stopPropagation();
            }}
            onChange={e => setStateTitle(e.target.value)}
            style={{
              ...(inputBaseProps.style ? inputBaseProps.style : {}),
              boxShadow: focused && `0px 0px 2px 0.5px ${colors.primary}`,
              width: '100%'
            }}
            inputProps={{
              ...inputProps,
              style: {
                ...(inputProps.style ? inputProps.style : {}),
                padding: '4px 8px',
                fontSize: '1.5rem'
              }
            }}
          />
          {focused && (
            <>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                style={{
                  margin: '0 0 0 8px',
                  ...(multiLine
                    ? { width: 'calc(50% - 8px)', margin: '8px 4px' }
                    : {})
                }}
                {...buttonProps}
                onFocus={() => setFocused(true)}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  margin: '0 0 0 8px',
                  ...(multiLine
                    ? { width: 'calc(50% - 8px)', margin: '8px 4px' }
                    : {})
                }}
                {...buttonProps}
                onClick={() => {
                  setStateTitle(title);
                  document.activeElement.blur();
                  setFocused(false);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </form>
      ) : (
        title
      )}
    </Typography>
  );
};

export default EditableTitle;
