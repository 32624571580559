import React from 'react';
import { Typography, Link } from 'helpers/themeSafeMui.helper';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';

const FolderPath = ({ disabled, folderPath, setFolderPath, setFolder }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 24
      }}
    >
      <div style={{ marginRight: 10 }}>
        <Typography variant="h6">Current Folder: </Typography>
      </div>
      {folderPath.map((pathItem, idx) => (
        <React.Fragment key={pathItem.id}>
          {idx !== 0 && (
            <ArrowIcon style={{ margin: '0 6px' }} fontSize="small" />
          )}
          <div>
            <Link
              component="button"
              variant="h6"
              style={disabled ? { cursor: 'auto' } : {}}
              underline={disabled ? 'none' : 'hover'}
              onClick={() => {
                if (disabled) return;
                setFolderPath(old => old.slice(0, idx + 1));
                setFolder(pathItem.id);
              }}
            >
              {pathItem.name}
            </Link>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default FolderPath;
