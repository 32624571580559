import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, Rollup } from 'components';
import EditableRow from '../components/EditableRow.component';
import { useSelector } from 'react-redux';
import {
  ProjectDetailsContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import DTools from 'routes/Projects/tabs/Home/components/DTools/DTools.component';

export default function BidInfoCard() {
  const user = useSelector(state => state.user);
  const tier2 = user.hasPermission('projects_tier_2');
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const { project } = useContext(ProjectContext);
  const [fields, setFields] = useState({ ...projectDetails });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setFields({ ...projectDetails });
  }, [projectDetails, editing]);

  const save = () => {
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        bidDueDate: fields.bidDueDate,
        projectCompletionDate: fields.projectCompletionDate || '',
        warrantyExpirationDate: fields.warrantyExpirationDate || '',
        planRoomLink: fields.planRoomLink || '',
        plangridLink: fields.plangridLink || '',
        customerPO: fields.customerPO || '',
        invoiceType: fields.invoiceType || '',
        retainagePercentage: fields.retainagePercentage || 0,
        start: fields.start,
        end: fields.end,
        estimatedCrew: fields.estimatedCrew || null,
        perDiem: fields.perDiem || '',
        scopeOfWork: fields.scopeOfWork || ''
      })
      .then(res => {
        setProjectDetails(res);
        setEditing(false);
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Rollup
      style={{ marginBottom: 8 }}
      title={<Typography variant="h5">Bid Info</Typography>}
      localStorageKey="project_card_bid_info"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        {tier2 && (
          <div>
            {editing ? (
              <Button variant="contained" color="secondary" onClick={save}>
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      </div>

      <EditableRow
        editing={false}
        label="Created Date"
        value={project.created}
        type="date"
        onChange={() => {}}
      />
      {tier2 && (
        <EditableRow
          editing={editing}
          label="Bid Due Date"
          value={fields.bidDueDate}
          type="date"
          onChange={bidDueDate => setFields({ ...fields, bidDueDate })}
        />
      )}
      {tier2 && (
        <EditableRow
          editing={editing}
          label="Project Completion Date"
          value={fields.projectCompletionDate}
          type="date"
          onChange={projectCompletionDate =>
            setFields({ ...fields, projectCompletionDate })
          }
        />
      )}
      {tier2 && (
        <EditableRow
          editing={editing}
          label="Warranty Expiration Date"
          value={fields.warrantyExpirationDate}
          type="date"
          onChange={warrantyExpirationDate =>
            setFields({ ...fields, warrantyExpirationDate })
          }
        />
      )}
      {tier2 && (
        <EditableRow
          editing={editing}
          label="PlanRoom Link"
          value={fields.planRoomLink}
          onChange={planRoomLink => setFields({ ...fields, planRoomLink })}
          type="link"
        />
      )}
      <DTools contentOnly />
    </Rollup>
  );
}
