import React from 'react';
import { TableHead, TableRow, TableCell } from 'helpers/themeSafeMui.helper';

export default function TableHeader(props) {
  const { titles } = props;

  return (
    <TableHead>
      <TableRow>
        {titles.map(header => (
          <TableCell key={header}>{header}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
