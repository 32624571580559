import React, { useState, useEffect, useContext } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Grid, Button } from 'helpers/themeSafeMui.helper';
import { connect, useDispatch } from 'react-redux';
import { setUser } from 'ducks/user.duck';
import { UserContext } from '../context/User.context';
import EditableValue from './EditableValue.component';
import { getAreas } from 'ducks/areas.duck';

const Tier1Details = props => {
  const { currentUser, setCurrentUser } = props;
  const { user, setUser } = useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const dispatch = useDispatch();
  const onBoardingCompleted = !!(user && user.onBoardingCompleted);

  useEffect(() => {
    setEditedUser(user || {});
  }, [editing, user]);

  useEffect(() => {
    dispatch(getAreas());
  }, [dispatch]);

  const save = e => {
    e.preventDefault();

    http()
      .put('/users/' + user.id, {
        firstName: editedUser.firstName,
        middleName: editedUser.middleName || '',
        lastName: editedUser.lastName,
        legalFirstName: editedUser.legalFirstName,
        personalEmail: editedUser.personalEmail,
        workEmail: editedUser.workEmail,
        preferPersonalEmail: editedUser.preferPersonalEmail,
        phoneNumber: editedUser.phoneNumber,

        address: editedUser.address,
        address2: editedUser.address2 || '',
        birthDate: editedUser.birthDate,
        shirtSize: editedUser.shirtSize,
        married: editedUser.married,
        spouseName: editedUser.spouseName || '',
        spousePhone: editedUser.spousePhone || ''
      })
      .then(user => {
        setEditing(false);
        setUser(user);
        Toast.show('Updated.');
        if (user.id === currentUser.id) {
          setCurrentUser(user);
        }
      })
      .catch(e => Toast.show(e.message));
  };

  return (
    <form onSubmit={save}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EditableValue
            label="Preferred First Name"
            value={editedUser.firstName}
            required={true}
            onChange={firstName => setEditedUser({ ...editedUser, firstName })}
            editing={editing}
          />
          <EditableValue
            label="Middle Name"
            value={editedUser.middleName}
            onChange={middleName =>
              setEditedUser({ ...editedUser, middleName })
            }
            editing={editing}
          />
          <EditableValue
            label="Last Name"
            value={editedUser.lastName}
            onChange={lastName => setEditedUser({ ...editedUser, lastName })}
            required={true}
            editing={editing}
          />

          <EditableValue
            label="Legal First Name"
            value={editedUser.legalFirstName}
            onChange={legalFirstName =>
              setEditedUser({ ...editedUser, legalFirstName })
            }
            required={true}
            editing={editing}
          />
          <EditableValue
            label="Personal Email"
            value={editedUser.personalEmail}
            onChange={personalEmail =>
              setEditedUser({ ...editedUser, personalEmail })
            }
            required={true}
            type="email"
            editing={editing}
          />
          <EditableValue
            label="Work Email"
            value={editedUser.workEmail}
            onChange={workEmail => setEditedUser({ ...editedUser, workEmail })}
            required={false}
            type="email"
            editing={editing}
          />
          <EditableValue
            label="Use Personal Email for STEPS"
            value={editedUser.preferPersonalEmail}
            onChange={preferPersonalEmail =>
              setEditedUser({ ...editedUser, preferPersonalEmail })
            }
            required={true}
            type="boolean"
            editing={editing}
          />
          <EditableValue
            label="Phone Number"
            value={editedUser.phoneNumber}
            onChange={phoneNumber =>
              setEditedUser({ ...editedUser, phoneNumber })
            }
            required={true}
            type="tel"
            editing={editing}
          />
        </Grid>
        <Grid item xs={6}>
          <EditableValue
            label="Address"
            value={editedUser.address}
            onChange={address => setEditedUser({ ...editedUser, address })}
            required={onBoardingCompleted}
            type="address"
            editing={editing}
          />
          <EditableValue
            label="Apt/Suite"
            value={editedUser.address2}
            onChange={address2 => setEditedUser({ ...editedUser, address2 })}
            editing={editing}
          />
          <EditableValue
            label="Shirt Size"
            value={editedUser.shirtSize}
            onChange={shirtSize => setEditedUser({ ...editedUser, shirtSize })}
            required={onBoardingCompleted}
            type="dropdown"
            options={[
              { value: 'XS', label: 'XS' },
              { value: 'S', label: 'S' },
              { value: 'M', label: 'M' },
              { value: 'L', label: 'L' },
              { value: 'XL', label: 'XL' },
              { value: '2XL', label: '2XL' },
              { value: '3XL', label: '3XL' },
              { value: '4XL', label: '4XL' },
              { value: '5XL', label: '5XL' }
            ]}
            editing={editing}
          />
          <EditableValue
            label="Married"
            value={editedUser.married}
            onChange={married => setEditedUser({ ...editedUser, married })}
            required={onBoardingCompleted}
            type="boolean"
            editing={editing}
          />
          <EditableValue
            label="Spouse's Name"
            value={editedUser.spouseName}
            onChange={spouseName =>
              setEditedUser({ ...editedUser, spouseName })
            }
            editing={editing}
          />
          <EditableValue
            label="Spouse's Phone"
            value={editedUser.spousePhone}
            onChange={spousePhone =>
              setEditedUser({ ...editedUser, spousePhone })
            }
            type="tel"
            editing={editing}
          />
          <EditableValue
            label="Birth Date"
            value={editedUser.birthDate}
            onChange={birthDate => setEditedUser({ ...editedUser, birthDate })}
            required={onBoardingCompleted}
            type="date"
            editing={editing}
          />
        </Grid>
        {!editing && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        )}
        {editing && (
          <div>
            <Button
              variant="outlined"
              onClick={() => setEditing(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button variant="outlined" color="primary" type="submit">
              Save
            </Button>
          </div>
        )}
      </Grid>
    </form>
  );
};

export default connect(state => ({ currentUser: state.user }), {
  setCurrentUser: setUser
})(Tier1Details);
