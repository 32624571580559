import React, { useState } from 'react';
import Field from './Field.component';
import { Document, Page } from 'react-pdf';
import SignatureSelect from './SignatureSelect.modal';
import stable from 'stable';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const DocumentComponent = props => {
  const { signer, fields, document, onUpdate, onUpdateSigner } = props;

  const [totalPages, setTotalPages] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState(1);
  const [pageWidths, setPageWidths] = useState({});
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [initialsImage, setInitialsImage] = useState(null);

  const scaleToDisplay = field => {
    if (!field) return;
    const scale = num =>
      num && wrapperWidth && pageWidths[field.pageNumber]
        ? Math.round((num * wrapperWidth) / pageWidths[field.pageNumber])
        : num;

    return {
      ...field,
      x: scale(field.x),
      y: scale(field.y),
      width: scale(field.width),
      height: scale(field.height),
      fontSize: scale(field.fontSize)
    };
  };

  const updateField = (value, fieldId) => {
    onUpdate(fields.map(el => (el.id === fieldId ? { ...el, value } : el)));
  };

  return (
    <div ref={el => el && setWrapperWidth(el.clientWidth)}>
      <SignatureSelect
        open={signatureModalOpen}
        onClose={() => setSignatureModalOpen(false)}
        signer={signer}
        onSave={res => {
          setSignatureModalOpen(false);
          setSignatureImage(res.signatureImage);
          setInitialsImage(res.initialsImage);
          onUpdateSigner({ ...signer, name: res.name });
          updateField(
            activeField.type === 'signature'
              ? res.signatureImage
              : res.initialsImage,
            activeField.id
          );
        }}
      />
      <Document
        file={'data:application/pdf;base64,' + document.base64File}
        onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
        loading={null}
      >
        {Array.from({ length: totalPages }).map((x, idx) => (
          <div
            key={idx}
            style={{
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
              position: 'relative',
              userSelect: 'none',
              marginTop: 25
            }}
          >
            {stable(fields, (a, b) => {
              if (a.pageNumber !== b.pageNumber) {
                return a.pageNumber > b.pageNumber;
              }
              if (a.y + 10 > b.y && a.y - 10 < b.y) {
                return a.x > b.x;
              }
              return a.y > b.y;
            })
              .filter(field => field.pageNumber === idx + 1)
              .map(field => (
                <Field
                  key={field.id}
                  signer={signer}
                  field={scaleToDisplay(field)}
                  openSignatureModal={() => {
                    setSignatureModalOpen(true);
                    setActiveField(field);
                  }}
                  signatureImage={signatureImage}
                  initialsImage={initialsImage}
                  onChange={val => updateField(val, field.id)}
                />
              ))}
            <Page
              renderAnnotationLayer={true}
              pageNumber={idx + 1}
              loading={null}
              width={wrapperWidth}
              onLoadSuccess={page => {
                if (page.rotate === 90 || page.rotate === 270) {
                  setPageWidths({
                    ...pageWidths,
                    [idx + 1]: page.originalHeight
                  });
                } else {
                  setPageWidths({
                    ...pageWidths,
                    [idx + 1]: page.originalWidth
                  });
                }
              }}
            />
          </div>
        ))}
      </Document>
    </div>
  );
};

export default DocumentComponent;
