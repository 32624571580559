import React, { useState } from 'react';
import {
  TextField,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import RSA from 'node-rsa';
import http from 'helpers/http.helper';
import LockIcon from '@material-ui/icons/LockOutlined';

const EncryptedFile = props => {
  const { file } = props;
  const [privateKey, setPrivateKey] = useState('');
  const [open, setOpen] = useState(false);

  const openFile = () => {
    if (!window.sessionStorage.getItem('private_rsa_key') && !privateKey) {
      return setOpen(true);
    }
    if (privateKey) {
      window.sessionStorage.setItem('private_rsa_key', privateKey);
    }

    let encryptionKey;
    try {
      const key = new RSA(window.sessionStorage.getItem('private_rsa_key'));
      encryptionKey = key.decrypt(file.encryptedKey, 'utf8');
    } catch (e) {
      Toast.show(e.message);
      window.sessionStorage.removeItem('private_rsa_key');
    }

    http()
      .get(`/encrypted-files/${file.filename}`, {
        headers: {
          'Encryption-Key': encryptionKey,
          'Content-Type': file.mimeType
        },
        responseType: 'blob',
        data: null
      })
      .then(blob => {
        const newWin = window.open(URL.createObjectURL(blob), '_blank');
        setTimeout(() => {
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            Toast.show(
              'Your popup blocker is preventing the file from opening.'
            );
          }
        }, 200);

        setOpen(false);
      })
      .catch(e => Toast.show(e.message));
  };

  if (!file) return null;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Enter Private Key</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            rows={10}
            multiline
            fullWidth
            value={privateKey}
            onChange={e => setPrivateKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={openFile}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Link style={{ cursor: 'pointer' }} onClick={openFile}>
        <LockIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} />
        View
      </Link>
    </React.Fragment>
  );
};

export default EncryptedFile;
