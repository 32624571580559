import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Users from './routes/Users.route';
import User from './routes/User.route';
import Trainings from './routes/Trainings.route';

export default class UsersRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/users" exact component={Users} />
        <Route path="/users/employee-training" exact component={Trainings} />
        <Route path="/users/:id" component={User} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
