import React from 'react';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import RemoveIcon from '@material-ui/icons/HighlightOff';

export default function CrewItem({ onRemove, user, disabled = false }) {
  return (
    <ListItem style={{ padding: 0 }}>
      <ListItemIcon>
        <IconButton disabled={disabled} size="small" onClick={onRemove}>
          <RemoveIcon />
        </IconButton>
      </ListItemIcon>
      <ListItemText>{user.name}</ListItemText>
    </ListItem>
  );
}
