import React, { useState, useEffect, useCallback, useContext } from 'react';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import moment from 'moment';
import { showModal } from 'ducks/modal.duck';
import { Toast, NoItemsResult, LastUpdated } from 'components';
import { Grid, Button, Typography, Divider } from 'helpers/themeSafeMui.helper';
import ManagerApprovedIcon from '@material-ui/icons/SupervisedUserCircle';
import { UserContext } from '../context/User.context';
import { useColors } from 'helpers/theme.helper';

const WeeklyCheckIns = props => {
  const { userId, currentUser, showModal } = props;
  const { user, setUser } = useContext(UserContext);
  const [checkIns, setCheckIns] = useState([]);
  const colors = useColors();

  const getWeeklyCheckIns = useCallback(() => {
    http()
      .get(`/users/${userId}/weekly-check-ins`)
      .then(res => setCheckIns(res))
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    getWeeklyCheckIns();
  }, [getWeeklyCheckIns]);

  const approveItem = (id, isApproved) => {
    const temp = !isApproved;

    http()
      .put(`/users/${userId}/weekly-check-ins/${id}`, {
        managerApproved: temp
      })
      .then(() => getWeeklyCheckIns());
  };

  const syncWithTsheets = setLoading => {
    setLoading(true);
    http({ withLoader: false })
      .put(`/users/${user.id}/tsheets`)
      .then(res => setUser(res))
      .then(() => Toast.show('Hours synced with TSheets.'))
      .catch(e => Toast.show(e.message))
      .finally(() => setLoading(false));
  };

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        {userId === currentUser.id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              showModal({
                type: 'CREATE_WEEKLY_CHECK_IN',
                props: {
                  refresh: getWeeklyCheckIns,
                  userId: userId
                }
              })
            }
          >
            create new OJT report
          </Button>
        ) : (
          <div />
        )}

        <LastUpdated
          lastUpdated={user.totalHoursLastUpdate}
          onRefresh={syncWithTsheets}
          toolTipValue="Imported from TSheets"
        />
      </Grid>

      {user.totalHours && (
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              textAlign: 'center'
            }}
          >
            <div>
              <Typography variant="h2">
                {Math.round(user.totalHours.work)}
              </Typography>
              <Typography variant="h6">Work Hours</Typography>
            </div>
            <div>
              <Typography variant="h2">
                {Math.round(user.totalHours.travel)}
              </Typography>
              <Typography variant="h6">Travel</Typography>
            </div>
            <div>
              <Typography variant="h2">
                {Math.round(user.totalHours.admin)}
              </Typography>
              <Typography variant="h6">Admin</Typography>
            </div>
          </div>
        </Grid>
      )}

      {!checkIns.length && (
        <NoItemsResult type="OTJ reports" style={{ width: '100%' }} />
      )}

      {checkIns.map(item => (
        <Grid item xs={12} key={item.id}>
          <Divider />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8
            }}
          >
            <div>
              <Typography variant="h6">
                {moment(item.firstDayOfWeek).format('LL')}
              </Typography>
              <Typography>Tasks Completed: {item.tasksCompleted}</Typography>
              <Typography>OJT Hours: {item.ojtHours}</Typography>

              {item.managerApproved ? (
                <Typography variant="caption" color="textSecondary">
                  <ManagerApprovedIcon style={{ color: colors.success }} />
                  {' approved by - '}
                  {item.managerName}
                </Typography>
              ) : (
                <Typography variant="caption" color="textSecondary">
                  <ManagerApprovedIcon color="error" />
                  {' waiting on approval from - '}
                  {item.managerName}
                </Typography>
              )}
            </div>

            <div style={{ margin: 'auto 0' }}>
              {!item.managerApproved &&
                (currentUser.id === item.managerId ||
                  currentUser.id === userId) && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      showModal({
                        type: 'EDIT_WEEKLY_CHECK_IN',
                        props: {
                          checkIn: item,
                          refresh: getWeeklyCheckIns
                        }
                      })
                    }
                  >
                    edit
                  </Button>
                )}
              {currentUser.id === item.managerId && (
                <Button
                  style={{ marginLeft: 8 }}
                  variant="contained"
                  color="primary"
                  onClick={() => approveItem(item.id, item.managerApproved)}
                >
                  {item.managerApproved ? 'remove approval' : 'approve item'}
                </Button>
              )}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default connect(state => ({ currentUser: state.user }), { showModal })(
  WeeklyCheckIns
);
