import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const NoStyleLink = React.forwardRef(
  ({ style, children, ...rest }: LinkProps, ref) => {
    return (
      <Link
        style={{
          color: 'inherit',
          textDecoration: 'inherit',
          ...style
        }}
        ref={ref as React.MutableRefObject<any>}
        {...rest}
      >
        {children}
      </Link>
    );
  }
);

export default NoStyleLink;
