import React, { useContext, useState } from 'react';
import { Toast } from 'components';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper,
  TextField,
  Button,
  Checkbox,
  Chip,
  LinearProgress
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import { UsersContext } from 'routes/Users/context/Users.context';
import { Autocomplete } from '@material-ui/lab';
import ConfirmModal from '../modals/Confirm.modal';
import PreviousMessage from './PreviousMessage.component';

const MessageInput = ({ setMessages, formState }) => {
  const areaOptions = useSelector(state => state.areas);
  const currentUser = useSelector(state => state.user);
  const [formContent, setFormContent] = formState;
  const { type, areaIds, title, message, userIds } = formContent;
  const { users: allUsers } = useContext(UsersContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const update = key => value =>
    setFormContent(oldState => ({ ...oldState, [key]: value }));

  const handleSubmit = e => {
    e.preventDefault();
    setModalOpen(true);
  };

  const send = e => {
    setModalOpen(false);
    setLoading(true);
    http()
      .post('/mass-messages', {
        type,
        areaIds,
        title,
        message,
        userIds
      })
      .then(sentMessage => {
        setMessages(messages => [sentMessage, ...messages]);
        setFormContent({
          type: 'sms',
          areaIds: [],
          title: '',
          message: '',
          userIds: []
        });
        setLoading(false);
        Toast.show('Message sent.');
      })
      .catch(e => {
        setLoading(false);
        Toast.show(e.message);
      });
  };

  const toggleArea = newId => {
    if (areaIds.includes(newId)) {
      update('areaIds')(areaIds.filter(el => el !== newId));
    } else {
      update('areaIds')(areaIds.concat(newId));
    }
  };

  const toggleAllAreas = () => {
    setFormContent(old => {
      const anySelected = old.areaIds && old.areaIds.length;
      return {
        ...old,
        areaIds: anySelected ? [] : areaOptions.map(a => a.id)
      };
    });
  };

  const smsProps =
    type === 'sms'
      ? {
          label: `Message ${message.length}/${getMessageAmount(message.length)}`
        }
      : {};

  return (
    <>
      <ConfirmModal
        onClose={() => setModalOpen(false)}
        onConfirm={send}
        open={modalOpen}
        title="Send mass message"
        description="Are you sure you want send this message:"
        confirmButtonText="Send"
        disabled={loading}
      >
        <PreviousMessage
          message={{
            ...formContent,
            recipients: [],
            createdBy: currentUser.id
          }}
          recursive={true}
        />
        {loading && <LinearProgress />}
      </ConfirmModal>
      <Paper style={{ padding: 16 }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>
            Send Message
          </Typography>

          {loading && <LinearProgress />}

          <RadioGroup
            value={type}
            onChange={e => update('type')(e.target.value)}
            style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}
          >
            <FormControlLabel
              disabled={loading}
              value="sms"
              control={<Radio />}
              label="SMS"
            />
            <FormControlLabel
              disabled={loading}
              value="email"
              control={<Radio />}
              label="Email"
            />
          </RadioGroup>

          {areaOptions.map(area => (
            <FormControlLabel
              key={area.id}
              control={
                <Checkbox
                  checked={areaIds.includes(area.id)}
                  onChange={e => toggleArea(area.id)}
                  disabled={loading}
                />
              }
              label={area.name}
            />
          ))}

          <br />
          <Button variant="contained" color="primary" onClick={toggleAllAreas}>
            {areaIds.length ? 'de' : ''}select all areas
          </Button>

          <Autocomplete
            multiple
            options={allUsers}
            autoHighlight
            selectOnFocus
            getOptionLabel={user => user.name}
            disabled={loading}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label="Added Users"
                  fullWidth
                  margin="dense"
                />
              );
            }}
            value={userIds
              .map(id => allUsers.find(user => user.id === id))
              .filter(u => u)}
            onChange={(e, acUsers) => {
              setFormContent(formContent => ({
                ...formContent,
                userIds: acUsers.map(user => user.id)
              }));
            }}
          />

          <TextField
            label={type === 'email' ? 'Subject' : 'Title (not sent to users)'}
            fullWidth
            value={title}
            variant="outlined"
            onChange={e => update('title')(e.target.value)}
            margin="dense"
            disabled={loading}
            required
          />

          <TextField
            label="Message"
            variant="outlined"
            multiline
            fullWidth
            inputProps={{ style: { minHeight: 100 } }}
            value={message}
            onChange={e => update('message')(e.target.value)}
            margin="dense"
            disabled={loading}
            required
            {...smsProps}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            disabled={loading}
            style={{ marginTop: 8 }}
          >
            Send
          </Button>
        </form>
      </Paper>
    </>
  );
};

function getMessageAmount(messageLength) {
  for (let i = 0; i <= messageLength; i++) {
    if (messageLength <= i * 160) {
      if (i === 0) return 160;
      return i * 160;
    }
  }
}

export default MessageInput;
