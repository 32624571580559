import React from 'react';
import { useHistory } from 'react-router-dom';
import { SelectionSortingTable } from 'components';
import moment from 'moment';
import {
  Paper,
  TableCell,
  Tooltip,
  Typography,
  withStyles
} from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import currency from 'helpers/currency.helper';

const NoPaddingTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 220,
    padding: 0
  }
}))(Tooltip);

export default function CompanyProjectsTable({ projects }) {
  const history = useHistory();
  const colors = useColors();
  const user = useCurrentUser();

  const columns = [
    {
      key: 'name',
      label: 'Name',
      extractor: row => `${row.projectNumber} - ${row.name}`
    },
    { key: 'accountManagerName', label: 'Account Manager' },
    { key: 'projectManagerName', label: 'Project Manager' },
    {
      key: 'created',
      label: 'Created On',
      extractor: row => moment(row.created).format('LL')
    },
    ...(user.hasPermission('projects_tier_2')
      ? [
          {
            key: 'dollarValue',
            label: '$ Amount',
            extractor: row => currency(row.dollarValue)
          }
        ]
      : []),
    {
      key: 'bidStatus',
      label: 'Won / Lost',
      renderer: (row, info, style) => (
        <LostReason
          show={row.bidStatus === 'lost'}
          reason={row.lostReason || 'None'}
        >
          <TableCell style={style}>
            {row.bidStatus === 'won' ? (
              <Paper style={{ backgroundColor: colors.success }}>
                <Typography
                  style={{ color: '#fff', fontWeight: 500 }}
                  align="center"
                >
                  Won
                </Typography>
              </Paper>
            ) : row.bidStatus === 'lost' ? (
              <Paper style={{ backgroundColor: colors.error }}>
                <Typography
                  style={{ color: '#fff', fontWeight: 500 }}
                  align="center"
                >
                  Lost
                </Typography>
              </Paper>
            ) : null}
          </TableCell>
        </LostReason>
      )
    }
  ];

  return (
    <div>
      <SelectionSortingTable
        isSelecting={false}
        columns={columns}
        rows={projects}
        rowAction={row => history.push(`/projects/${row.id}`)}
        rootStyles={{ height: 'calc(100vh - 360px)' }}
        stickyHeader
        addEmptyRows={false}
      />
    </div>
  );
}

function LostReason({ children, show, reason }) {
  if (!show) return children;
  return (
    <NoPaddingTooltip
      title={
        <Paper style={{ padding: '8px 10px 5px' }} elevation={3}>
          <Typography
            variant="overline"
            style={{ lineHeight: 1, fontWeight: 600 }}
          >
            Lost Reason:
          </Typography>
          <Typography style={{ paddingLeft: 5, whiteSpace: 'pre-wrap' }}>
            {reason}
          </Typography>
        </Paper>
      }
    >
      {children}
    </NoPaddingTooltip>
  );
}
