import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import { useState } from 'react';
import DriveFolderViewer from '../DriveFolderViewer/DriveFolderViewer.component';

export default function FilePickerButton({
  onSelect,
  apiPath,
  children,
  buttonText,
  ...rest
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {children ? (
        <div onClick={() => setOpen(true)}>{children}</div>
      ) : (
        <Button variant="contained" {...rest} onClick={() => setOpen(true)}>
          {buttonText || 'Select file'}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <div style={{ padding: 12 }}>
          <DialogTitle>Select file</DialogTitle>
          <DriveFolderViewer
            apiPath={apiPath}
            onSelect={file => {
              onSelect(file);
              setOpen(false);
            }}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="contained">
              cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
