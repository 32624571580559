import React, { useEffect, useRef, useState } from 'react';

function BetterBlur({
  onFocus = () => {},
  onBlur = () => {},
  component = 'div',
  rootRef = null,
  focused: providedFocused = false,
  children,
  ...rest
}) {
  const ref = useRef(null);
  const [focused, setFocused] = useState(providedFocused);

  const handleClick = e => {
    const { current: rootDomNode } = rootRef || ref;
    if (!rootDomNode) return;

    let willBeFocused;
    if (rootDomNode === e.target || rootDomNode.contains(e.target)) {
      willBeFocused = true;
    } else {
      willBeFocused = false;
    }
    if (focused !== willBeFocused) {
      if (willBeFocused) onFocus();
      if (!willBeFocused) onBlur();
    }

    setFocused(willBeFocused);
  };

  useEffect(() => {
    // if user closes child and needs to click it again without clicking anything else.
    setFocused(providedFocused);
  }, [providedFocused]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  if (rootRef) {
    // provide a dom ref to avoid nesting in a div / component
    return React.createElement(React.Fragment, {}, children);
  }

  if (typeof component !== 'string') {
    throw new Error('"component" must be a string');
  }

  return React.createElement(component, { ref, ...rest }, children);
}

export default BetterBlur;

/*
const simpleUsageExample = () => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <BetterBlur
      onFocus={() => setOpen(true)}
      onBlur={() => {
        setExpanded(false);
        setOpen(false);
      }}
    >
      <span>click for details</span>
      {open && (
        <>
          <p onClick={() => setExpanded(true)}>
            These are some details. Click to expand
          </p>
          {expanded && (
            <p>
              here are even more details
              <br />
              You can click inside and it wont close
              <br />
              If you click outside it will
            </p>
          )}
        </>
      )}
    </BetterBlur>
  );
};
 */
