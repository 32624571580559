import { matchSorter } from 'match-sorter';
import { useEffect, useState } from 'react';

export function useSearchMaterials({ materials, searchText, active }) {
  const [sortedMaterials, setSortedMaterials] = useState(materials);

  useEffect(() => {
    if (!searchText || !active) {
      setSortedMaterials(materials);
    } else {
      const sorted = matchSorter(materials, searchText, {
        keys: [
          'manufacturer',
          'model',
          'partNumber',
          { key: 'description', threshold: matchSorter.rankings.CONTAINS }
        ]
      });
      setSortedMaterials(sorted);
    }
  }, [materials, searchText, active]);

  return sortedMaterials;
}
