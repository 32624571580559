import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Typography, Button, Grid } from 'helpers/themeSafeMui.helper';
import { Toast, CustomInput } from 'components';
import { setAdminSettings } from 'ducks/adminSettings.duck';
import { connect } from 'react-redux';
import stable from 'stable';

function MiscTab(props) {
  const { adminSettings, setAdminSettings } = props;
  const [customFields, setCustomFields] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [categories, setCategories] = useState({
    customFieldId: '',
    travel: [],
    admin: [],
    work: []
  });
  const { customFieldId } = categories;

  useEffect(() => {
    if (adminSettings.serviceItemCategories) {
      setCategories(adminSettings.serviceItemCategories);
    }
  }, [adminSettings.serviceItemCategories]);

  useEffect(() => {
    http()
      .get('/settings/tsheet-custom-fields')
      .then(res => setCustomFields(res))
      .catch(err => Toast.show(err.message));
  }, []);

  useEffect(() => {
    if (!customFieldId) return;
    http()
      .get('/settings/tsheet-custom-field-items', { params: { customFieldId } })
      .then(res => setServiceItems(res))
      .catch(err => Toast.show(err.message));
  }, [customFieldId]);

  const save = () => {
    http()
      .put('/settings', { serviceItemCategories: categories })
      .then(res => {
        setAdminSettings(res);
        Toast.show('Updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Service Item Categories
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          label="Service Item Field"
          type="dropdown"
          required
          value={customFieldId}
          onChange={customFieldId =>
            setCategories({ ...categories, customFieldId })
          }
          options={customFields.map(el => ({ label: el.name, value: el.id }))}
        />
      </Grid>

      {stable(serviceItems, (a, b) => a.active < b.active).map(serviceItem => (
        <Grid item xs={6} key={serviceItem.id}>
          <CustomInput
            label={serviceItem.name + (serviceItem.active ? '' : ' (inactive)')}
            type="dropdown"
            value={
              categories.travel.includes(serviceItem.id)
                ? 'travel'
                : categories.work.includes(serviceItem.id)
                ? 'work'
                : categories.admin.includes(serviceItem.id)
                ? 'admin'
                : ''
            }
            onChange={val => {
              setCategories({
                customFieldId,
                travel: categories.travel
                  .filter(item => item !== serviceItem.id)
                  .concat(val === 'travel' ? serviceItem.id : []),
                work: categories.work
                  .filter(item => item !== serviceItem.id)
                  .concat(val === 'work' ? serviceItem.id : []),
                admin: categories.admin
                  .filter(item => item !== serviceItem.id)
                  .concat(val === 'admin' ? serviceItem.id : [])
              });
            }}
            options={[
              { label: 'Travel', value: 'travel' },
              { label: 'Work', value: 'work' },
              { label: 'Admin', value: 'admin' }
            ]}
          />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button onClick={save} fullWidth variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default connect(state => ({ adminSettings: state.adminSettings }), {
  setAdminSettings
})(MiscTab);
