import { Paper, Typography } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import React, { useContext } from 'react';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { fromTimeString, shortFormatTime } from '../helpers/DateTime.helper';
import LockIcon from '@material-ui/icons/Lock';
import { useColors } from 'helpers/theme.helper';
import { ShiftModalContext } from '../context/ShiftModal.context';

export default function ShiftUserTableCard({ shiftUser }) {
  const colors = useColors();
  const { shiftMap } = useContext(ShiftScheduleContext);
  const { openWithProps } = useContext(ShiftModalContext);

  const shift = shiftMap[shiftUser.shiftDayId];
  if (!shift) return null;

  const openEditDay = () => {
    openWithProps({ shiftId: shift.id }, 'day');
  };

  return (
    <Paper
      style={{ padding: 5, cursor: 'pointer', position: 'relative' }}
      onClick={openEditDay}
    >
      <Typography style={{ fontWeight: 500 }}>
        {shift.projectNumber} - {shift.projectName}
        {!!shift.taskName && (
          <Typography
            style={{ marginLeft: 5 }}
            variant="caption"
            color="textSecondary"
          >
            {shift.taskName}
          </Typography>
        )}
      </Typography>
      <Typography>{durationText(shift.start, shift.end)}</Typography>
      {shift.approved && (
        <div
          style={{
            position: 'absolute',
            bottom: 3,
            right: 3,
            color: colors.success
          }}
        >
          <LockIcon />
        </div>
      )}
    </Paper>
  );
}

function durationText(start, end) {
  start = moment(fromTimeString(start));
  end = moment(fromTimeString(end));
  start = shortFormatTime(start);
  end = shortFormatTime(end);
  return `${start} - ${end}`;
}
