import React from 'react';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import { Typography, IconButton, Button } from 'helpers/themeSafeMui.helper';
import { Toast, DragAndDrop, DashboardWrapper } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import TrainingModules from './TrainingModules.component';
import { Link } from 'react-router-dom';
import WeeklyQuizzesModal from './WeeklyQuizzes.modal';

class TrainingGroups extends React.Component {
  state = {
    trainingGroups: [],
    weeklyOpen: false
  };

  componentDidMount() {
    this.getTrainingGroups();
  }

  getTrainingGroups = () => {
    http()
      .get('/training-groups')
      .then(trainingGroups => {
        this.setState({
          trainingGroups
        });
      })
      .catch(err => Toast.show(err.message));
  };

  handleGroupDrop = trainingGroups => {
    this.setState({ trainingGroups });

    Promise.all(
      trainingGroups.map((trainingGroup, idx) => {
        return http().put(`/training-groups/${trainingGroup.id}`, {
          order: idx + 1
        });
      })
    )
      .then(() => Toast.show('Updated.'))
      .catch(err => Toast.show(err.message));
  };

  toggleWeeklyOpen = () => {
    this.setState(({ weeklyOpen }) => ({ weeklyOpen: !weeklyOpen }));
  };

  render() {
    const { trainingGroups, weeklyOpen } = this.state;

    return (
      <DashboardWrapper>
        <WeeklyQuizzesModal open={weeklyOpen} onClose={this.toggleWeeklyOpen} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography component="h1" variant="h5" gutterBottom>
              Training Groups
              <IconButton
                color="primary"
                style={{ marginLeft: 10, padding: 0 }}
                onClick={() =>
                  this.props.showModal({
                    type: 'CREATE_TRAINING_GROUP',
                    props: {
                      refresh: this.getTrainingGroups,
                      nextSpot: trainingGroups.length
                    }
                  })
                }
              >
                <AddIcon />
              </IconButton>
            </Typography>
          </div>
          <div>
            <Button variant="contained" onClick={this.toggleWeeklyOpen}>
              Weekly Modules
            </Button>
          </div>
          <div>
            <Link to="/users/employee-training">
              <Button variant="contained">Training Master List</Button>
            </Link>
          </div>
        </div>

        <DragAndDrop onDrop={this.handleGroupDrop}>
          {trainingGroups.map((trainingGroup, index) => (
            <div
              style={{
                width: '100%',
                position: 'relative'
              }}
              key={trainingGroup.id}
              id={trainingGroup.id}
              value={trainingGroup}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography variant="h5">{trainingGroup.name}</Typography>

                <EditIcon
                  style={{
                    marginLeft: 10,
                    cursor: 'pointer'
                  }}
                  color="action"
                  fontSize="small"
                  onClick={() =>
                    this.props.showModal({
                      type: 'EDIT_TRAINING_GROUP',
                      props: {
                        trainingGroup: trainingGroup,
                        refresh: this.getTrainingGroups,
                        trainingGroups: trainingGroups
                      }
                    })
                  }
                />
              </div>
              <TrainingModules
                style={{ border: 'solid red 1px' }}
                trainingGroup={trainingGroup}
                trainingGroups={trainingGroups}
                showModal={this.props.showModal}
                getTrainingGroups={this.getTrainingGroups}
              />
            </div>
          ))}
        </DragAndDrop>
      </DashboardWrapper>
    );
  }
}

export default connect(state => ({ user: state.user }), { showModal })(
  TrainingGroups
);
