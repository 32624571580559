import React from 'react';
import { DashboardWrapper, AppBarRouter } from 'components';
import { Paper } from 'helpers/themeSafeMui.helper';
import ProjectHeader from './components/ProjectHeader.component';
import ProjectContextProvider from '../../context/Project.context';
import ChildProjectsProvider from '../../context/ChildProjectsContext.context';

import HomeTab from '../../tabs/Home/Home.tab';
import TeamTab from '../../tabs/Team/Team.tab';
import DocsTab from '../../tabs/Docs/Docs.tab';
import WorkOrdersTab from '../../tabs/WorkOrders/WorkOrders.tab';
import EmailsTab from '../../tabs/Emails/Emails.tab';
import MultiScopeQuotesTab from '../../tabs/Quotes/MultiScopeQuotes.tab';
import ChangeOrdersTab from '../../tabs/ChangeOrders/ChangeOrders.tab';
import ProjectScheduleTab from '../../tabs/Schedule/ProjectSchedule.tab';
import RfiTab from 'routes/Projects/tabs/Rfis/Rfis.tab';
import ProjectAddMenu from './components/ProjectAddMenu.component';
import ProjectPageTitleUpdater from './components/ProjectPageTitleUpdater.component';
import { connect } from 'react-redux';
import ProjectWorkflows from './workflows';
import ChildProjectsTab from 'routes/Projects/tabs/ChildProjects/ChildProjects.tab';
import HasRelatedProjectsWarning from './components/HasRelatedProjectsWarning.component';
import useBreakpoint from 'hooks/useBreakpoint.hook';
import ProjectPhotosTab from 'routes/Projects/tabs/Photos/ProjectPhotos.tab';
import MaterialsTab from 'routes/Projects/tabs/Materials/Materials.tab';
import EstimatesTab from 'routes/Projects/tabs/Estimates/Estimates.tab';
import PosTab from 'routes/Projects/tabs/PurchaseOrders/PurchaseOrders.tab';
import ProjectChecklist from './components/ProjectChecklist';

const Project = props => {
  const projectId = props.match.params.id;
  const isSmallScreen = useBreakpoint('sm');

  const routes = [
    {
      name: 'Home',
      path: `/projects/${projectId}`,
      Component: HomeTab
    },
    {
      name: 'Team',
      path: `/projects/${projectId}/team`,
      Component: TeamTab
    },
    {
      name: 'Schedule',
      path: `/projects/${projectId}/schedule`,
      Component: ProjectScheduleTab,
      permission: 'projects_tier_1'
    },
    {
      name: 'Drive',
      path: `/projects/${projectId}/docs`,
      Component: DocsTab,
      permission: 'projects_tier_2'
    },
    {
      name: 'Photos',
      path: `/projects/${projectId}/photos`,
      Component: ProjectPhotosTab,
      permission: 'projects_tier_1'
    },
    {
      name: 'Emails',
      path: `/projects/${projectId}/emails`,
      Component: EmailsTab,
      permission: 'projects_tier_2'
    },
    {
      name: 'Estimates',
      path: `/projects/${projectId}/estimates`,
      Component: EstimatesTab,
      permission: 'testing'
    },
    {
      name: 'Quotes',
      path: `/projects/${projectId}/quotes`,
      Component: MultiScopeQuotesTab,
      permission: 'projects_tier_2'
    },
    {
      name: 'Work Orders',
      path: `/projects/${projectId}/work-orders`,
      Component: WorkOrdersTab
    },
    {
      name: 'Change Orders',
      path: `/projects/${projectId}/change-orders`,
      Component: ChangeOrdersTab,
      permission: 'projects_tier_1'
    },
    {
      name: 'Materials',
      path: `/projects/${projectId}/materials`,
      Component: MaterialsTab,
      permission: 'projects_tier_1'
    },
    {
      name: 'Purchase Orders',
      path: `/projects/${projectId}/pos`,
      Component: PosTab,
      permission: 'projects_tier_1.5'
    },
    {
      name: 'RFI',
      path: `/projects/${projectId}/rfis`,
      Component: RfiTab,
      permission: 'projects_tier_1'
    },
    {
      name: 'Related Projects',
      path: `/projects/${projectId}/related-projects`,
      Component: ChildProjectsTab,
      permission: 'projects_tier_1'
    }
  ];

  return (
    <ProjectContextProvider>
      <ChildProjectsProvider>
        <ProjectPageTitleUpdater />
        <DashboardWrapper noPad={isSmallScreen}>
          <Paper style={{ marginBottom: 16 }}>
            <ProjectChecklist />
            <HasRelatedProjectsWarning />
            <ProjectWorkflows />
            <ProjectHeader />
            <AppBarRouter
              routes={routes}
              noPad={isSmallScreen}
              routeProps={{ projectId: projectId }}
            />
            <ProjectAddMenu projectId={projectId} />
          </Paper>
        </DashboardWrapper>
      </ChildProjectsProvider>
    </ProjectContextProvider>
  );
};

export default connect(state => ({ user: state.user }))(Project);
