import React, { useEffect, useState } from 'react';
import { Toast } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  Grid
} from 'helpers/themeSafeMui.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import http from 'helpers/http.helper';
import { useRouteMatch } from 'react-router';
import PoPdf from '../components/PoPdf.component';
import PoFields from '../components/PoFields.component';

export default function EditPoModal({
  open,
  onClose,
  getPos,
  po,
  poMaterials,
  shipToOptions
}) {
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;

  const currentUser = useCurrentUser();
  const [poData, setPoData] = useState({
    to: '',
    attn: '',
    poNumber: '',
    quoteNumber: '',
    from: currentUser.name,
    shipping: '',
    billTo: '',
    billToContactEmail: '',
    billToContactPhone: '',
    shipTo: '',
    shipToContactPhone: '',
    notes: '',
    supplierId: '',
    emailTo: ''
  });

  useEffect(() => {
    if (open && po) {
      setPoData({
        to: po.to || '',
        attn: po.attn || '',
        poNumber: po.poNumber || '',
        quoteNumber: po.quoteNumber || '',
        from: po.from || '',
        shipping: po.shipping || '',
        billTo: po.billTo || '',
        billToContactEmail: po.billToContactEmail || '',
        billToContactPhone: po.billToContactPhone || '',
        shipTo: po.shipTo || '',
        shipToContactPhone: po.shipToContactPhone || '',
        notes: po.notes || '',
        supplierId: po.supplierId || '',
        emailTo: po.emailTo || ''
      });
    }
  }, [open, po]);

  const updatePo = () => {
    if (!projectId || !po || !po.id) return;
    http()
      .put(`/projects/${projectId}/pos/${po.id}`, { ...poData })
      .then(getPos)
      .then(onClose)
      .catch(Toast.showErr);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Edit Purchase Order</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            <PoPdf po={poData} items={poMaterials} debounceDelay={500} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <div style={{ padding: 8 }}>
              <PoFields
                poData={poData}
                setPoData={setPoData}
                shipToOptions={shipToOptions}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={updatePo}>
          Save
        </Button>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
