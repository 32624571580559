import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import OneDriveFolderCard from './components/OneDriveFolderCard.component';
import OneDriveFileCard from './components/OneDriveFileCard.component';
import {
  Button,
  Grid,
  Typography,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import { Toast, NoItemsResult } from 'components';
import FolderPath from './components/FolderPath.component';
import FileModal from './modals/OneDriveFile.modal';
import NewFileModal from './modals/NewFile.modal';

const OneDriveFolderViewer = ({
  apiPath = '/one-drive',
  isTemplate,
  gridSizes = {
    xl: 2,
    lg: 3,
    md: 4,
    xs: 6
  },
  onSelect = null,
  showIds = false
}) => {
  const [folderPath, setFolderPath] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [fileModal, setFileModal] = useState({ file: null, url: null });
  const [templates, setTemplates] = useState([]);

  const [loading, setLoading] = useState(true);
  const [newFileModalOpen, setNewFileModalOpen] = useState(false);

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const esignTemplates = await http().get('/esign-documents', {
          params: { isTemplate: true }
        });

        const templates = await http()
          .get(`${apiPath}/templates`)
          .then(res => res);

        setTemplates(
          templates.map(file => ({
            ...file,
            isTemplate: true,
            esignTemplate: esignTemplates.find(et => et.fileId === file.id)
          }))
        );
      } catch (err) {
        Toast.show(err.message);
      }
    };

    getTemplates();
  }, [apiPath]);

  useEffect(() => {
    http()
      .get(apiPath)
      .then(({ file }) => {
        setFolderPath([{ name: file.name, id: file.id }]);
      })
      .catch(err => Toast.show(err.message));
  }, [apiPath]);

  useEffect(() => {
    setLoading(true);
    http()
      .get(apiPath + (currentFolderId ? `?itemId=${currentFolderId}` : ''))
      .then(({ file, children }) => {
        setCurrentFolder(file);
        setFiles(children.filter(child => child.file));
        setFolders(children.filter(child => child.folder));
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  }, [currentFolderId, apiPath]);

  const openFileModal = fileId => () => {
    setLoading(true);
    http()
      .get(`${apiPath}/embed?itemId=${fileId}`)
      .then(({ file, url }) => {
        setFileModal({ file, url });
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const removeItem = fileId => () => {
    if (!fileId) return Promise.resolve();
    if (!window.confirm('Are you sure you want to this?'))
      return Promise.resolve();
    setLoading(true);
    return http()
      .delete(`${apiPath}/${fileId}`)
      .then(() => {
        const removeFromArr = deleteRest => arr => {
          const idx = arr.findIndex(file => file.id === fileId);
          if (idx === -1) return arr;
          const arrCopy = [...arr];
          arrCopy.splice(idx, deleteRest ? arrCopy.length : 1);
          return arrCopy;
        };
        setFiles(removeFromArr(false));
        setFolders(removeFromArr(false));
        setFolderPath(removeFromArr(true));
        setFileModal(fm =>
          fm.file && fm.file.id === fileId ? { file: null, url: null } : fm
        );
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const updateFileName = fileId => name => {
    if (!fileId) return Promise.resolve();
    setLoading(true);
    return http()
      .put(`${apiPath}/${fileId}`, { name })
      .then(() => {
        const updateName = files => {
          const idx = files.findIndex(file => file.id === fileId);
          if (idx === -1) return files;
          const filesCopy = [...files];
          filesCopy[idx] = { ...filesCopy[idx], name };
          return filesCopy;
        };
        setFiles(updateName);
        setFolders(updateName);
        setFolderPath(updateName);
        setFileModal(fm => ({ ...fm, file: fm.file && { ...fm.file, name } }));
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  const createFiles = files => {
    if (loading) return;
    setLoading(true);

    return Promise.all(
      files.map(async file => {
        const formData = new FormData();
        formData.set('name', file.name);
        if (file.isTemplate) formData.set('templateId', file.id);
        if (currentFolderId) formData.set('folderId', currentFolderId);
        if (!(file.isFolder || file.isTemplate))
          formData.set('steps_file', file);

        const newFile = await http().post(apiPath, formData);

        if (file.esignTemplate) {
          const esignDoc = await http().post('/esign-documents', {
            fileId: newFile.id
          });
          await http().put(`/esign-documents/${esignDoc.id}/copy`, {
            templateId: file.esignTemplate.id
          });
        }

        const update = updatingFiles => old => {
          if (xor(updatingFiles, newFile.file)) return old;
          const idx = old.findIndex(item => item.id === newFile.id);
          if (idx === -1) return [newFile, ...old];
          const copy = [...old];
          copy[idx] = newFile;
          return copy;
        };

        setFolders(update(false));
        setFiles(update(true));
      })
    )
      .then(() => Toast.show('File created.'))
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <div style={{ position: 'relative', padding: 10 }}>
      <FileModal
        open={!!fileModal.url}
        file={fileModal.file}
        url={fileModal.url}
        onClose={() => setFileModal({ file: null, url: null })}
        updateName={updateFileName(fileModal.file && fileModal.file.id)}
        disabled={loading}
      />
      <NewFileModal
        open={newFileModalOpen}
        onClose={() => setNewFileModalOpen(false)}
        createFiles={createFiles}
        templates={templates}
        disabled={loading}
      />
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
            width: 40,
            height: 40,
            display: 'flex'
          }}
        >
          <CircularProgress size={40} />
        </div>
      )}
      <div
        style={{
          opacity: loading ? 0.5 : 1,
          transition: 'opacity 200ms linear',
          minHeight: 40
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={loading}
            onClick={() =>
              currentFolder && window.open(currentFolder.webUrl, '_blank')
            }
            variant="contained"
            style={{ marginRight: 8 }}
          >
            Open One-Drive Folder
          </Button>
          <Button
            onClick={() => setNewFileModalOpen(true)}
            disabled={loading}
            variant="contained"
          >
            New File/Folder
          </Button>
        </div>
        <FolderPath
          disabled={loading}
          folderPath={folderPath}
          setFolderPath={setFolderPath}
          setFolder={setCurrentFolderId}
        />
        {!!folders.length && (
          <div>
            <Typography variant="h5" gutterBottom>
              Folders
            </Typography>
            <Grid container spacing={2}>
              {folders.map(folder => (
                <Grid {...gridSizes} key={folder.id} item>
                  <OneDriveFolderCard
                    showIds={showIds}
                    disabled={loading}
                    folder={folder}
                    onDelete={removeItem(folder.id)}
                    onNameChange={updateFileName(folder.id)}
                    onSelect={onSelect}
                    onEnter={folder => {
                      setCurrentFolderId(folder.id);
                      setFolderPath(old => [
                        ...old,
                        { id: folder.id, name: folder.name }
                      ]);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <div style={{ paddingTop: 24 }} />
          </div>
        )}
        {!!files.length && (
          <div>
            <Typography variant="h5" gutterBottom>
              Files
            </Typography>
            <Grid container spacing={2}>
              {files.map(file => (
                <Grid {...gridSizes} key={file.id} item>
                  <OneDriveFileCard
                    showIds={showIds}
                    file={file}
                    onDelete={removeItem(file.id)}
                    onNameChange={updateFileName(file.id)}
                    openFileModal={openFileModal(file.id)}
                    onSelect={onSelect}
                    disabled={loading}
                    isTemplate={isTemplate}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {!files.length && !loading && (
          <NoItemsResult message="No files found in this folder." />
        )}
      </div>
    </div>
  );
};

function xor(a, b) {
  return !!(!!a ^ !!b);
}

export default OneDriveFolderViewer;
