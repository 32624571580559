import React from 'react';
import { DriveFolderViewer } from 'components';

const DocsTab = props => {
  const { projectId } = props;

  return (
    <DriveFolderViewer
      style={{ padding: 24, margin: -24 }}
      apiPath={`/projects/${projectId}/docs`}
    />
  );
};

export default DocsTab;
