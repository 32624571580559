import React, { useState, useEffect } from 'react';
import { Typography, Link, IconButton } from 'helpers/themeSafeMui.helper';
import { CheckBox, Toast } from 'components';
import moment from 'moment';
import MoreIcon from '@material-ui/icons/MoreVert';
import http from 'helpers/http.helper';
import { Link as RouterLink } from 'react-router-dom';
import ViewActionItemModal from './modals/ViewActionItem.modal';
import EditActionItemModal from './modals/EditActionItem.modal';
import { useColors } from 'helpers/theme.helper';
import { useSelector } from 'react-redux';
import { useActionItem, useSetActionItem } from 'state/actionItems.state';

const ActionItem = React.memo(props => {
  const { id } = props;
  const actionItem = useActionItem(id);
  const setActionItem = useSetActionItem();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const colors = useColors();
  const user = useSelector(state => state.user);

  const toggleCompletion = () => {
    setActionItem({
      ...actionItem,
      dateCompleted: actionItem.dateCompleted ? null : moment()
    });

    http()
      .put(`/action-items/${actionItem.actionItemId || actionItem.id}`, {
        dateCompleted: actionItem.dateCompleted ? null : moment()
      })
      .catch(err => {
        setActionItem({
          ...actionItem,
          dateCompleted: actionItem.dateCompleted ? moment() : null
        });
        Toast.show(err.message);
      });
  };

  useEffect(() => {
    if (!actionItem && id) {
      http()
        .get(`/action-items/${id}`)
        .then(item => setActionItem(item))
        .catch(err => Toast.show(err.message));
    }
  }, [actionItem, id, setActionItem]);

  if (!actionItem) return null;

  const unseenStyles =
    actionItem.assignedUserIds.includes(user.id) && !actionItem.seenByUser
      ? {
          borderLeft: `2px solid ${colors.error}`,
          borderRadius: '3px 0 0 3px'
        }
      : {};

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 8,
        width: '100%',
        ...unseenStyles
      }}
    >
      <ViewActionItemModal
        open={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        id={actionItem.id}
        openEditModal={() => {
          setEditModalOpen(true);
          setViewModalOpen(false);
        }}
      />
      <EditActionItemModal
        open={editModalOpen}
        onClose={() => {
          setViewModalOpen(true);
          setEditModalOpen(false);
        }}
        id={actionItem.id}
      />
      <div
        style={{
          marginRight: 16,
          cursor: 'pointer',
          padding: 8
        }}
      >
        <CheckBox
          checked={actionItem.dateCompleted}
          onClick={toggleCompletion}
        />
      </div>

      <div style={{ flexGrow: 1 }}>
        <div>
          <Typography>
            {!!actionItem.projectNumber && (
              <Typography
                style={{
                  fontSize: '1rem',
                  fontWeight: 500,
                  marginRight: 6,
                  lineHeight: 1
                }}
                variant="overline"
                component="span"
              >
                {actionItem.projectNumber} -
              </Typography>
            )}
            {actionItem.title || actionItem.actionItemTitle}
          </Typography>
          <Typography
            variant="body2"
            color={
              moment(actionItem.dueDate).isBefore(moment()) &&
              !actionItem.dateCompleted
                ? 'error'
                : 'textSecondary'
            }
          >
            {moment(actionItem.dueDate).format('LLL')}
          </Typography>
          {actionItem.actionItemLink && (
            <Link component={RouterLink} to={actionItem.actionItemLink}>
              Go To Item
            </Link>
          )}
        </div>
      </div>

      <IconButton
        style={{ marginLeft: 8 }}
        onClick={() => setViewModalOpen(true)}
      >
        <MoreIcon fontSize="small" />
      </IconButton>
    </div>
  );
});

export default ActionItem;
