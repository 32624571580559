import React from 'react';
import { SearchDropdown, PersonDisplay } from 'components';
import phoneHelper from 'helpers/phone.helper';
import DeleteIcon from '@material-ui/icons/Delete';

export default function TeamDisplay(props) {
  const {
    title,
    readOnly,
    name,
    phoneNumber,
    email,
    image,
    onChange = () => {},
    options = []
  } = props;

  const onDelete = () => {
    onChange({ id: null });
  };

  return name && name.trim().length ? (
    <PersonDisplay
      name={name}
      type={title}
      phoneNumber={phoneHelper(phoneNumber)}
      email={email}
      image={image}
      Icon={
        !readOnly && (
          <DeleteIcon
            color="action"
            style={{ cursor: 'pointer' }}
            onClick={onDelete}
          />
        )
      }
    />
  ) : (
    <SearchDropdown
      label={title}
      onChange={onChange}
      items={options}
      fullWidth
      margin="normal"
      itemToString={item => item.name}
      disabled={readOnly}
    />
  );
}
