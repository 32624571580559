import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from 'helpers/themeSafeMui.helper';
import { ConfirmDelete, CustomInput, Toast } from 'components';
import {
  ProjectContext,
  ProjectChangeOrdersContext,
  ProjectDetailsContext
} from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';

const ChangeOrderModal = ({ id, editing, open, onClose }) => {
  const { projectChangeOrders, setProjectChangeOrders } = useContext(
    ProjectChangeOrdersContext
  );
  const {
    project: { id: projectId }
  } = useContext(ProjectContext);
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );

  const [name, setName] = useState('');
  const [coNumber, setCoNumber] = useState(null);
  const [refNumber, setRefNumber] = useState(null);
  const [cost, setCost] = useState(null);
  const [price, setPrice] = useState(null);
  const [hours, setHours] = useState(null);
  const [notes, setNotes] = useState('');
  const [approved, setApproved] = useState(null);

  const editingCO = projectChangeOrders.find(CO => CO.id === id);

  useEffect(() => {
    if (editingCO) {
      setName(editingCO.name);
      setNotes(editingCO.notes);
      setCoNumber(editingCO.coNumber);
      setRefNumber(editingCO.refNumber);
      setCost(editingCO.cost);
      setPrice(editingCO.price);
      setHours(editingCO.hours);
      setApproved(editingCO.approved);
    } else {
      setName('');
      setNotes('');
      setCoNumber(null);
      setRefNumber(null);
      setCost(null);
      setPrice(null);
      setHours('');
      setApproved(null);
    }
  }, [editingCO, projectChangeOrders.length]);

  const onSubmit = e => {
    if (e && typeof e.preventDefault === 'function') e.preventDefault();
    let path = `/projects/${projectId}/change-orders`;
    const method = editing ? 'put' : 'post';
    if (editing) path += `/${id}`;
    const data = {
      coNumber,
      name,
      notes,
      refNumber,
      price,
      cost,
      hours,
      approved
    };

    if (!data.coNumber) delete data.coNumber;
    if (!data.cost) delete data.cost;
    if (!data.hours) delete data.hours;

    http()
      [method](path, data)
      .then(({ changeOrder: newOrUpdatedCO, projectDetails }) => {
        setProjectChangeOrders(oldCOs => {
          const idx = oldCOs.findIndex(oldCO => oldCO.id === newOrUpdatedCO.id);
          const found = idx !== -1;
          const copy = [...oldCOs];
          copy.splice(found ? idx : copy.length, found ? 1 : 0, newOrUpdatedCO);
          return copy;
        });
        setProjectDetails(projectDetails);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const onDelete = () => {
    http()
      .delete(`/projects/${projectId}/change-orders/${id}`)
      .then(({ changeOrder: { id }, projectDetails }) => {
        setProjectChangeOrders(oldCOs => oldCOs.filter(old => old.id !== id));
        setProjectDetails(projectDetails);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const coNumberText =
    coNumber === null
      ? ''
      : coNumber === -1
      ? ' - Pending'
      : ` - CO#${coNumber}`;

  return (
    <Dialog open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={onSubmit}>
        <DialogTitle>
          {editing ? 'Edit' : 'Create'} Change Order{coNumberText}
        </DialogTitle>
        <DialogContent>
          {editing && projectDetails.dtoolsProjectId && (
            <CustomInput
              type="number"
              label="CO Number"
              value={coNumber === -1 ? null : coNumber}
              onChange={num => num >= 0 && setCoNumber(num)}
            />
          )}
          <CustomInput
            type="text"
            label="Name"
            value={name}
            onChange={val => setName(val)}
            required
          />
          <TextField
            label="Notes"
            value={notes}
            onChange={e => setNotes(e.target.value)}
            fullWidth
            margin="normal"
            multiline
          />
          <TextField
            label="Ref#"
            value={refNumber === null ? '' : refNumber}
            onChange={e => setRefNumber(e.target.value)}
            fullWidth
            margin="normal"
          />
          <CustomInput
            type="currency"
            label="Price"
            value={price === null ? '' : price}
            onChange={setPrice}
            required
          />
          <CustomInput
            type="currency"
            label="Cost"
            value={cost === null ? '' : cost}
            onChange={val => setCost(val)}
            required
          />
          <CustomInput
            type="number"
            label="Hours"
            value={hours === null ? '' : hours}
            onChange={val => setHours(val)}
            required
          />
          <CustomInput
            type="boolean"
            label="Approved"
            value={approved === null ? '' : approved}
            onChange={val => setApproved(val)}
          />
        </DialogContent>
        <DialogActions>
          {editing && (
            <ConfirmDelete
              onConfirm={onDelete}
              button={<Button>delete</Button>}
            />
          )}
          <Button type="submit" onClick={onSubmit}>
            {editing ? 'save' : 'create'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeOrderModal;
