import React, { useState, useEffect } from 'react';
import { showModal } from 'ducks/modal.duck';
import {
  DashboardWrapper,
  Toast,
  CopyText,
  SelectionSortingTable
} from 'components';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import NewIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from 'react-redux';
import phoneHelper from 'helpers/phone.helper';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10
  }
};

const columns = [
  { key: 'name', label: 'Name' },
  { key: 'companyName', label: 'Company' },
  { key: 'jobTitle', label: 'Job Title' },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    extractor: column => {
      const base = column.phoneNumbers[0]
        ? column.phoneNumbers[0].phoneNumber
        : null;
      const ext =
        column.phoneNumbers[0] && column.phoneNumbers[0].ext
          ? ` Ext. ${column.phoneNumbers[0].ext}`
          : '';
      return base ? phoneHelper(base) + ext : base;
    }
  },
  {
    key: 'email',
    label: 'Email',
    extractor: column => (column.emails[0] ? column.emails[0].email : null),
    modifier: email => (
      <CopyText text={email} type="Email">
        {email}
      </CopyText>
    )
  }
];

function People(props) {
  const { history, showModal } = props;
  const [people, setPeople] = useState([]);

  useEffect(() => {
    http()
      .get('/people')
      .then(res => setPeople(res))
      .catch(err => Toast.show(err.message));
  }, []);

  const showCreatePersonModal = () => {
    showModal({
      type: 'CREATE_PERSON',
      props: {
        company: null,
        onCreate: res => setPeople(people.concat(res))
      }
    });
  };

  return (
    <DashboardWrapper>
      <div style={styles.header}>
        <Typography component="h1" variant="h4">
          People
          <IconButton
            color="primary"
            style={{ marginLeft: 4 }}
            onClick={showCreatePersonModal}
          >
            <NewIcon />
          </IconButton>
        </Typography>
      </div>

      <SelectionSortingTable
        isSelecting={false}
        columns={columns}
        rows={people}
        rowAction={row => history.push(`/people/${row.id}`)}
        localStorageKey={'people_table'}
        stickyHeader
        rootStyles={{ height: 'calc(100vh - 162px)' }}
      />
    </DashboardWrapper>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(People);
