import React from 'react';
import { DriveFolderViewer } from 'components';

function DriveTab(props) {
  const { companyId } = props;

  return (
    <DriveFolderViewer
      style={{ padding: 24, margin: -24 }}
      apiPath={`/companies/${companyId}/docs`}
    />
  );
}

export default DriveTab;
