import React from 'react';
import {
  Select,
  MenuItem,
  IconButton,
  Typography
} from 'helpers/themeSafeMui.helper';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';

import { FaFileContract, FaFileSignature } from 'react-icons/fa';
import TimeStampIcon from '@material-ui/icons/DateRange';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextFieldIcon from '@material-ui/icons/TextFields';

const EditMenu = props => {
  const {
    activePage,
    totalPages,
    fieldType,
    onFieldTypeChange,
    signers,
    activeSigner,
    onPageChange,
    onActiveSignerChange
  } = props;

  const fieldTypes = [
    { name: 'Text Field', icon: <TextFieldIcon />, value: 'text_field' },
    {
      name: 'Signature',
      icon: <FaFileSignature style={{ fontSize: '1.5rem' }} />,
      value: 'signature'
    },
    {
      name: 'Initials',
      icon: <FaFileContract style={{ fontSize: '1.5rem' }} />,
      value: 'initials'
    },
    { name: 'Auto Date', icon: <TimeStampIcon />, value: 'auto_date' },
    { name: 'Checkbox', icon: <CheckBoxIcon />, value: 'checkbox' }
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        marginBottom: 8
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginRight: 8 }}
        >
          Page {activePage} of {totalPages}
        </Typography>
        <IconButton
          size="small"
          disabled={activePage === 1}
          onClick={() => onPageChange(activePage - 1)}
        >
          <LeftIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={activePage === totalPages}
          onClick={() => onPageChange(activePage + 1)}
        >
          <RightIcon />
        </IconButton>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}
      >
        <Select
          style={{ minWidth: 250, marginRight: 16 }}
          displayEmpty
          value={activeSigner}
          onChange={e => onActiveSignerChange(e.target.value)}
          disabled={!signers.length}
        >
          <MenuItem value="" disabled>
            {signers.length ? 'Select a signer...' : 'No signers found...'}
          </MenuItem>
          {signers.map(signer => (
            <MenuItem value={signer} key={signer.id}>
              {signer.name}
            </MenuItem>
          ))}
        </Select>

        <ToggleButtonGroup
          size="small"
          value={fieldType}
          exclusive
          onChange={(e, fieldType) => onFieldTypeChange(fieldType)}
        >
          {fieldTypes.map(fieldType => (
            <ToggleButton
              title={fieldType.name}
              value={fieldType.value}
              disabled={!activeSigner}
              key={fieldType.value}
            >
              {fieldType.icon}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default EditMenu;
