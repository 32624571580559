import React, { useState, useEffect, useContext, useCallback } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { UserContext } from './User.context';
import { useSelector } from 'react-redux';

export const TrainingQuizzesContext = React.createContext();

export default function TrainingQuizzesContextProvider({ children }) {
  const { user } = useContext(UserContext);
  const currentUser = useSelector(state => state.user);
  const [quizzes, setQuizzes] = useState([]);
  const [moduleIdMap, setModuleIdMap] = useState({});

  const refresh = useCallback(() => {
    if (!user.id || !currentUser.id) return;
    if (
      currentUser.hasPermission('employee_training') ||
      user.id === currentUser.id
    )
      http()
        .get(`/training-quizzes/review-attempts?userId=${user.id}`)
        .then(quizzes => {
          setQuizzes(quizzes);
          setModuleIdMap(
            quizzes.reduce(
              (map, quiz) => Object.assign(map, { [quiz.moduleId]: quiz }),
              {}
            )
          );
        })
        .catch(err => Toast.show(err.message));
  }, [user, currentUser]);

  useEffect(refresh, [refresh]);

  useEffect(() => {
    if (quizzes) {
      setModuleIdMap(
        quizzes.reduce(
          (map, quiz) => Object.assign(map, { [quiz.trainingModuleId]: quiz }),
          {}
        )
      );
    } else {
      setModuleIdMap({});
    }
  }, [quizzes]);

  return (
    <TrainingQuizzesContext.Provider value={{ quizzes, moduleIdMap, refresh }}>
      {children}
    </TrainingQuizzesContext.Provider>
  );
}
