import { Toast } from 'components';
import safeParse from 'helpers/safeParse.helper';
import moment from 'moment';
import { useContext } from 'react';
import { ProjectsContext } from '../context/Projects.context';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { toDateString, toTimeString } from '../helpers/DateTime.helper';
import useShiftApi from './useShiftApi.hook';

export default function useCreateShiftsFromTask() {
  const { shiftDays } = useShiftApi();
  const { dateWindow, fetchSchdeules } = useContext(ShiftScheduleContext) || {};
  const { projectMap: contextProjectMap } = useContext(ProjectsContext) || {};

  return async (task, projectMap = contextProjectMap) => {
    const activeTaskDaysInWeek = [];
    if (dateWindow) {
      for (let i = 0; i < 7; i++) {
        if (
          moment(dateWindow[0])
            .day(i)
            .isBetween(
              moment(task.startDate),
              moment(task.endDate),
              'day',
              '[]'
            )
        )
          activeTaskDaysInWeek.push(moment(dateWindow[0]).day(i));
      }
    } else if (task.startDate && task.endDate) {
      let current = moment(task.startDate);
      while (current.isSameOrBefore(moment(task.endDate), 'day')) {
        activeTaskDaysInWeek.push(current);
        current = moment(current).add(1, 'day');
      }
    } else {
      return Toast.show('task must have start and end dates to create a shift');
    }

    const project = projectMap[task.projectId];
    let { scheduleHrsPerDay, scheduleDaysPerWeek } = project;
    scheduleHrsPerDay = scheduleHrsPerDay ? safeParse(scheduleHrsPerDay) : 10;
    const defaultTimes =
      scheduleHrsPerDay === 10
        ? [
            moment()
              .hour(7)
              .startOf('hour'),
            moment()
              .hour(17)
              .minutes(30)
              .startOf('minute')
          ]
        : [
            moment()
              .hour(7)
              .startOf('hour'),
            moment()
              .hour(15)
              .minutes(30)
              .startOf('minute')
          ];
    scheduleDaysPerWeek = scheduleDaysPerWeek
      ? safeParse(scheduleDaysPerWeek)
      : 4;
    const skipDays = [0, 6, ...(scheduleDaysPerWeek === 4 ? [5] : [])];
    const newShifts = await Promise.all(
      activeTaskDaysInWeek
        .filter(d => !skipDays.includes(d.day()))
        .map(m =>
          shiftDays
            .create({
              taskId: task.id,
              projectId: task.projectId,
              date: toDateString(m),
              start: toTimeString(defaultTimes[0]),
              end: toTimeString(defaultTimes[1]),
              approved: false
            })
            .catch(Toast.showErr)
        )
    );

    if (fetchSchdeules) await fetchSchdeules();

    return newShifts;
  };
}
