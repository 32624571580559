import React from 'react';
import { SelectionSortingTable } from 'components';
import { useHistory } from 'react-router-dom';

export default function CompletionSortingTable({ columns, projects }) {
  const history = useHistory();

  return (
    <SelectionSortingTable
      columns={columns}
      rows={projects}
      isSelecting={false}
      stickyHeader
      stickyColumns={1}
      TableProps={{ size: 'small' }}
      rootStyles={{
        height: `100%`
      }}
      rowAction={project => history.push(`/projects/${project.id}`)}
      localStorageKey="completion_table"
      checkboxCellProps={{ padding: 'none' }}
      iconProps={{ fontSize: 'inherit' }}
      initialRowsPerPage={50}
    />
  );
}
