import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions
} from 'helpers/themeSafeMui.helper';
import { Toast, CustomInput } from 'components';

const defaultLocation = {
  area: '',
  name: '',
  address: '',
  address2: '',
  phone: '',
  hours: '',
  cost: ''
};

const LocationModal = ({
  open,
  onClose,
  onSubmit,
  title,
  location = defaultLocation
}) => {
  const [state, setState] = useState({ ...location });
  const _makeField = (key, label, props) => (
    <CustomInput
      key={key}
      label={label}
      value={state[key]}
      type="text"
      onChange={value => setState(old => ({ ...old, [key]: value }))}
      fullWidth
      style={{ marginBottom: 16 }}
      {...props}
    />
  );

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await onSubmit({ ...state });
      resetAndClose();
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const resetAndClose = () => {
    setState({ ...location });
    onClose();
  };

  useEffect(() => {
    setState({ ...location });
  }, [location]);

  return (
    <Dialog open={open} onClose={resetAndClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <div style={{ padding: 10 }}>
          {[
            ['area', 'Area'],
            ['name', 'Name'],
            ['address', 'Address', { type: 'address' }],
            ['address2', 'Address line 2'],
            ['phone', 'Phone'],
            ['hours', 'Hours'],
            ['cost', 'Cost'],
            ['notes', 'Notes', { multiline: true }]
          ].map(([key, label, type]) => _makeField(key, label, type))}
        </div>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button onClick={resetAndClose} variant="contained" color="default">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LocationModal;
