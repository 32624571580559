import React from 'react';
import { DashboardWrapper, AppBarRouter } from 'components';
import { Paper } from 'helpers/themeSafeMui.helper';
import UserHeader from '../components/UserHeader.component';
import { connect } from 'react-redux';

import EmploymentDetails from '../tabs/EmploymentDetails.tab';
import EmergencyContact from '../tabs/EmergencyContact.tab';
import Banking from '../tabs/Banking.tab';
import DrugTests from '../tabs/DrugTests.tab';
import Documents from '../tabs/Documents.tab';
import Trainings from '../tabs/Trainings';
import AccountDetails from '../tabs/AccountDetails.tab';
import Notes from '../tabs/Notes.tab';
import WeeklyCheckIns from '../tabs/WeeklyCheckIns.tab';
import UserSkills from '../tabs/UserSkills.tab';
import UserContextProvider from '../context/User.context';
import AccountPhoto from '../tabs/AccountPhoto.tab';
import NewhireChecklist from '../components/NewhireChecklist';
import ActionItems from '../tabs/ActionItems.tab';

function User(props) {
  const userId = props.match.params.id;
  const { currentUser } = props;
  const viewingSelf = currentUser.id === userId;

  const routes = [
    {
      name: 'Account Details',
      path: `/users/${userId}`,
      Component: AccountDetails
    },
    {
      name: 'Account Photo',
      path: `/users/${userId}/photo`,
      Component: AccountPhoto
    },
    {
      name: 'Job History',
      path: `/users/${userId}/job-history`,
      Component: EmploymentDetails
    },
    {
      name: 'Emergency Contact',
      path: `/users/${userId}/emergency-contact`,
      Component: EmergencyContact
    },
    {
      name: 'Banking',
      path: `/users/${userId}/banking`,
      Component: Banking,
      permission: viewingSelf ? null : 'users_tier_2'
    },
    {
      name: 'Drug Tests',
      path: `/users/${userId}/drug-screenings`,
      Component: DrugTests
    },
    {
      name: 'Drive',
      path: `/users/${userId}/documents`,
      Component: Documents,
      permission: viewingSelf ? null : 'drive_admin'
    },
    {
      name: viewingSelf ? 'My Training' : 'Training',
      path: `/users/${userId}/trainings`,
      Component: Trainings
    },
    {
      name: viewingSelf ? 'My Notes' : 'Notes',
      path: `/users/${userId}/notes`,
      Component: Notes
    },
    {
      name: viewingSelf ? 'My Skills' : 'Skills',
      path: `/users/${userId}/skills`,
      Component: UserSkills
    },
    {
      name: 'OJT reports',
      path: `/users/${userId}/ojt-reports`,
      Component: WeeklyCheckIns
    },
    {
      name: 'Action Items',
      path: `/users/${userId}/action-items`,
      Component: ActionItems
    }
  ];

  return (
    <UserContextProvider>
      <DashboardWrapper>
        <Paper>
          <NewhireChecklist />
          <UserHeader />
          <AppBarRouter routes={routes} routeProps={{ userId: userId }} />
        </Paper>
      </DashboardWrapper>
    </UserContextProvider>
  );
}

export default connect(state => ({ currentUser: state.user }))(User);
