import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Projects from './routes/Projects.route';
import Project from './routes/Project/Project.route';

export default class UsersRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/projects" exact component={Projects} />
        <Route path="/projects/:id" component={Project} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}
