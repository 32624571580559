import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled
} from '@material-ui/core';
import { MaterialsTable } from 'components';
import { useContext, useRef, useState } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import PickListPdf from '../../PurchaseOrders/components/PickListPdf.component';

const { MaterialCsvGenerator, presetHiddenColumns } = MaterialsTable;
const hiddenColumns = presetHiddenColumns.StatusUpdates;

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function PickListModal({
  open,
  onClose,
  materialStatuses,
  materials,
  title = 'Pick List',
  noSelect = false,
  noDialog = false,
  loading,
  buttons = null,
  pdfOnly = false
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showingPdf, setShowingPdf] = useState(false);
  const { project } = useContext(ProjectContext);
  const csvRef = useRef(null);

  const downloadCsv = () => {
    if (csvRef.current) {
      csvRef.current.downloadCsv(`${project.projectNumber}_pick_list`);
      onClose('download');
    }
  };

  const addDialog = children =>
    noDialog ? (
      children
    ) : (
      <FullHeightDialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => onClose('click-out')}
      >
        {children}
      </FullHeightDialog>
    );

  const hasRows = noSelect ? !!materials.length : !!selectedRows.length;
  const getMatIds = () =>
    noSelect
      ? materials.map(mat => mat.materialId)
      : selectedRows.map(mat => mat.materialId);

  const filteredStatuses = Object.fromEntries(
    Object.entries(materialStatuses).filter(([key]) =>
      getMatIds().includes(key)
    )
  );

  return addDialog(
    <>
      <MaterialCsvGenerator
        materials={noSelect ? materials : selectedRows}
        ref={csvRef}
      />
      {!!title && (
        <>
          <DialogTitle>Pick List</DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent style={{ padding: 0 }}>
        {showingPdf || pdfOnly ? (
          <PickListPdf
            po={{}}
            items={noSelect ? materials : selectedRows}
            materialStatuses={filteredStatuses}
            altHeader={`${project.name} - ${project.projectNumber}`}
            hideColumnIdxs={hiddenPdfCols}
          />
        ) : (
          <MaterialsTable
            highlightUnordered={false}
            localStorageKey="ProjectMaterials"
            initialRowsPerPage={100}
            materials={materials || []}
            removeColumns={hiddenColumns}
            isSelecting={!noSelect}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            statuses={materialStatuses}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => onClose('cancel')} variant="contained">
          cancel
        </Button>
        {!pdfOnly && (
          <Button
            disabled={loading || !hasRows}
            onClick={() => setShowingPdf(!showingPdf)}
            variant="contained"
            color="primary"
          >
            {showingPdf ? 'hide' : 'show'} Pdf
          </Button>
        )}
        <Button
          disabled={loading || !hasRows}
          onClick={downloadCsv}
          variant="contained"
          color="primary"
        >
          download CSV
        </Button>
        {buttons}
      </DialogActions>
    </>
  );
}

const hiddenPdfCols = []; // this was used in an earlier version but is not currently being used. Consider removing
