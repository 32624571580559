import React from 'react';
import { DragAndDrop } from 'components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox
} from 'helpers/themeSafeMui.helper';

export default function SetVisibleColumnsModal({
  visibleColumns,
  setVisibleColumns,
  alwaysOn = [],
  open,
  setOpen
}) {
  const handleChange = idx => () => {
    const copy = JSON.parse(JSON.stringify(visibleColumns));
    if (alwaysOn.includes(copy[idx][0])) return;
    copy[idx][1] = !copy[idx][1];
    setVisibleColumns(copy);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Visible Columns</DialogTitle>
      <DialogContent>
        <FormGroup>
          <DragAndDrop onDrop={setVisibleColumns}>
            {visibleColumns.map(([viewProp, showing], idx) => (
              <div
                key={viewProp}
                id={viewProp}
                value={[viewProp, showing]}
                wrapperStyle={{ padding: 0 }}
                style={{ width: '100%' }}
              >
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      checked={showing}
                      onChange={handleChange(idx)}
                      color="primary"
                    />
                  }
                  label={unCamelCase(viewProp)}
                />
              </div>
            ))}
          </DragAndDrop>
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}

function unCamelCase(string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
}
