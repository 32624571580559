import React, { useState } from 'react';
import EditTaskPreset from '../modals/EditTaskPreset.modal';
import { Typography, IconButton, Divider } from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';

function TaskPreset({ preset, setPresets, presets, skillOptions }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <EditTaskPreset
        presetId={preset.id}
        initName={preset.name}
        initSkillId={preset.skillId}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        setPresets={setPresets}
        skillOptions={skillOptions}
      />
      <Divider />
      <div
        style={{
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <Typography>{preset.name}</Typography>
          <Typography
            style={{ lineHeight: 1.9 }}
            variant="subtitle2"
            color="textSecondary"
          >
            {preset.employeeSkillName}
          </Typography>
        </div>
        <div>
          <IconButton size="small" onClick={() => setEditModalOpen(true)}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}

export default TaskPreset;
