import React, { useState } from 'react';
import {
  InputAdornment,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  Checkbox
} from 'helpers/themeSafeMui.helper';
import SearchIcon from '@material-ui/icons/Search';
import { NoItemsResult } from 'components';
import useFreshFn from 'hooks/useFreshFn.hook';

export default function ProjectFilterColumn({
  title,
  pluralTitle,
  users,
  selectedItems,
  setSelectedItems,
  noneFlag,
  setNoneFlag
}) {
  const [searchInput, setSearchInput] = useState('');

  const handleClick = useFreshFn(user => {
    if (!user) return;
    const { id } = user;
    setSelectedItems(old =>
      old.includes(id) ? old.filter(el => id !== el) : old.concat(id)
    );
  });

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div style={{ margin: 8, width: 250 }}>
      <Typography gutterBottom align="center">
        {title}
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Filter"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        name="search"
        margin="dense"
        style={{ margin: 0, marginBottom: 10 }}
        fullWidth
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="action" />
            </InputAdornment>
          )
        }}
      />

      <div style={{ height: 200, overflow: 'auto' }}>
        {'none'.includes(searchInput.toLowerCase()) && (
          <ListItem
            onClick={() => setNoneFlag(old => !old)}
            button
            style={{ padding: 0 }}
          >
            <Checkbox checked={noneFlag} />
            <ListItemText primary="None" />
          </ListItem>
        )}
        {!filteredUsers.length && <NoItemsResult type={pluralTitle} />}
        {!noneFlag &&
          filteredUsers.map(user => (
            <SelectableUser
              key={user.id}
              onClick={handleClick}
              checked={selectedItems.includes(user.id)}
              user={user}
            />
          ))}
      </div>
    </div>
  );
}

const SelectableUser = React.memo(({ onClick, checked, user }) => (
  <ListItem
    onClick={() => onClick(user)}
    button
    key={user.id}
    style={{ padding: 0 }}
  >
    <Checkbox checked={checked} />
    <ListItemText primary={user.name} />
  </ListItem>
));
