import http from 'helpers/http.helper';
import { Toast } from 'components';

// Actions
const SET_PERMISSION_LEVELS = 'permission-levels/SET_PERMISSION_LEVELS';

// Reducer
export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SET_PERMISSION_LEVELS:
      return action.permissionLevels;
    default:
      return state;
  }
}

// Action Creators
export const getPermissionLevels = () => async dispatch => {
  const permissionLevels = await http()
    .get('/permission-levels')
    .catch(err => Toast.show(err.message));

  dispatch({ type: SET_PERMISSION_LEVELS, permissionLevels });
};

export const setPermissionLevels = permissionLevels => {
  return { type: SET_PERMISSION_LEVELS, permissionLevels };
};
