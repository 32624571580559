import React from 'react';
import { Toast, AddressPredict, CustomInput } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

export default class NewCompanyModal extends React.Component {
  initialState = {
    name: '',
    address: '',
    phoneNumber: '',
    billingEmail: '',
    isSupplier: false,
    website: '',
    loading: false
  };

  state = this.initialState;

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();
    if (this.state.loading) return;
    this.setState({ loading: true });

    http()
      .post('/companies', {
        name: this.state.name,
        address: this.state.address,
        phoneNumber: this.state.phoneNumber,
        billingEmail: this.state.billingEmail,
        isSupplier: this.state.isSupplier,
        website: this.state.website
      })
      .then(() => {
        Toast.show('Company created.');
        this.props.refresh();
      })
      .catch(err => {
        Toast.show(err.message);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: () => this.setState(this.initialState) }}
      >
        <form autoComplete="off" onSubmit={this.save}>
          <DialogTitle>New Company</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Name"
              fullWidth
              value={this.state.name}
              name="name"
              margin="normal"
              onChange={this.handleChange}
              required
            />
            <AddressPredict
              label="Billing Address"
              onChange={address => this.setState({ address })}
              value={this.state.address}
              required
              margin="normal"
            />
            <TextField
              label="Phone Number"
              fullWidth
              required
              value={this.state.phoneNumber}
              name="phoneNumber"
              margin="normal"
              onChange={this.handleChange}
            />
            <TextField
              label="Billing Email"
              fullWidth
              value={this.state.billingEmail}
              name="billingEmail"
              margin="normal"
              onChange={this.handleChange}
              required
            />
            <TextField
              label="Website"
              fullWidth
              value={this.state.website}
              name="website"
              margin="normal"
              onChange={this.handleChange}
              // required
            />
            <CustomInput
              type="boolean"
              value={this.state.isSupplier}
              label="Is Supplier Company"
              name="isSupplier"
              onChange={isSupplier => this.setState({ isSupplier })}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={this.state.loading}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
