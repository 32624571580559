import React, { useState } from 'react';
import TrainingRecentActionCard from './TrainingRecentActionCard.component';
import { Popover, Button } from 'helpers/themeSafeMui.helper';

const TrainingRecentActionCell = ({ user, items, setViewingUser }) => {
  const displayItem = items[0];
  const [anchorEl, setAnchorEl] = useState(null);

  const stop = e => e.stopPropagation();
  const handleClick = e => setAnchorEl(e.currentTarget);

  if (!displayItem) return null;
  return (
    <div onClick={stop} style={{ width: '100%', height: '100%' }}>
      <div onClick={handleClick}>
        <TrainingRecentActionCard
          type={displayItem.type || 'note'}
          item={displayItem}
          compact
        />
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <TrainingRecentActionCard
          type={displayItem.type || 'note'}
          item={displayItem}
          noPaper
          style={{ width: null, minWidth: 300 }}
        />

        <div
          style={{
            padding: 10,
            paddingTop: 0
          }}
        >
          <Button
            onClick={() => {
              setAnchorEl(null);
              setViewingUser(user);
            }}
            fullWidth
            variant="contained"
            size="small"
          >
            View All for {user.firstName}
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default TrainingRecentActionCell;
