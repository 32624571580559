import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { useContext } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';

export default function SiPullWarningModal({
  open,
  onClose,
  onConfirm,
  loading
}) {
  const { project } = useContext(ProjectContext);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" color="error">
          Warning:
        </Typography>
      </DialogTitle>
      <DialogContent>
        Pulling from SI will replace all Project-Materials for{' '}
        {project.projectNumber}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          variant="contained"
          color="primary"
          style={{ height: '100%' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Pull and Replace'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
