import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled
} from '@material-ui/core';
import { CustomInput, MaterialsTable, Toast } from 'components';
import http from 'helpers/http.helper';
import safeParse from 'helpers/safeParse.helper';
import { useContext, useState } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';

const hiddenColumns = MaterialsTable.presetHiddenColumns.StatusUpdates;

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function ReceivedMaterialModal({
  open,
  onClose,
  materialStatuses,
  materials,
  onReceive,
  loading,
  setLoading
}) {
  const { project } = useContext(ProjectContext);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [location, setLocation] = useState('');

  const prefillQuantity = mat => {
    if (!materialStatuses[mat.materialId]) return safeParse(mat.quantity, 0);
    const ordered = safeParse(materialStatuses[mat.materialId].ordered, 0);
    const val =
      typeof ordered === 'number' && !isNaN(ordered) ? `${ordered}` : '';
    return val;
  };

  const receive = () => {
    const materialIds = selectedMaterials.map(mat => mat.materialId);
    const quantities = selectedMaterials.map(mat => mat.updateQuantity);
    setLoading(true);
    http()
      .post(`/projects/${project.id}/materials/received/batch`, {
        materialIds,
        quantities,
        location
      })
      .then(() => {
        onReceive();
        setLocation('');
      })
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  return (
    <FullHeightDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Receive Materials</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: '12px' }}>
        <CustomInput label="Location" value={location} onChange={setLocation} />
        <MaterialsTable
          highlightUnordered
          localStorageKey="ProjectMaterials"
          initialRowsPerPage={100}
          materials={materials || []}
          removeColumns={hiddenColumns}
          isSelecting
          statuses={materialStatuses}
          isSettingQuantity
          settingQuantityLabel="Receive"
          getAutofillUpdateQuantity={prefillQuantity}
          selectedRows={selectedMaterials}
          setSelectedRows={setSelectedMaterials}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          cancel
        </Button>
        <Button
          disabled={loading}
          onClick={receive}
          variant="contained"
          color="primary"
          style={{ height: '100%' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </FullHeightDialog>
  );
}
