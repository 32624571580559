import React, { useState } from 'react';
import EditEmployeeSkill from '../modals/EditEmployeeSkill.modal';
import EditIcon from '@material-ui/icons/Edit';
import SortUpIcon from '@material-ui/icons/ArrowUpwardRounded';
import SortDownIcon from '@material-ui/icons/ArrowDownwardRounded';
import { Typography, Grid, IconButton } from 'helpers/themeSafeMui.helper';

//child skill component
const ChildSkill = ({ skill, onReorder, updateSkill, deleteSkill }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <Grid container alignItems="center">
      <EditEmployeeSkill
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        skill={skill}
        updateSkill={updateSkill}
        deleteSkill={deleteSkill}
      />
      <IconButton size="small" onClick={() => onReorder(-1)}>
        <SortUpIcon />
      </IconButton>
      <IconButton size="small" onClick={() => onReorder(1)}>
        <SortDownIcon />
      </IconButton>

      <Typography variant="subtitle2" style={{ flex: 1 }}>
        {skill.name}
      </Typography>

      <IconButton size="small" onClick={() => setEditModalOpen(true)}>
        <EditIcon />
      </IconButton>
    </Grid>
  );
};

export default ChildSkill;
