import { Toast } from 'components';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import moment from 'moment';
import { useContext } from 'react';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import {
  fromDateString,
  toDateString,
  toTimeString
} from '../helpers/DateTime.helper';
import useShiftApi from './useShiftApi.hook';
import useProjectShiftsGroups from './useProjectShiftsGroups';

export default function useWeekUpdater({
  group = null,
  shiftDayId = null,
  weekStartMoment,
  projectId,
  taskId = undefined,
  activeDays = undefined,
  start = undefined,
  end = undefined,
  approved = undefined,
  sharedUsers = undefined,
  lunchStart = undefined,
  lunchDurationMinutes = undefined,
  workOrderId = undefined,
  variedWorkOrderId = undefined
}) {
  const { shiftUserMap, fetchSchdeules } = useContext(ShiftScheduleContext);
  const { shiftDays, shiftDayUsers } = useShiftApi();
  const currentUser = useCurrentUser();
  const groups = useProjectShiftsGroups(projectId);

  const isLunchUpdated =
    lunchStart !== undefined || lunchDurationMinutes !== undefined;

  if (shiftDayId) {
    const foundGroup = groups.find(group =>
      group.shifts.find(shift => shift.id === shiftDayId)
    );
    if (foundGroup) {
      group = foundGroup;
      taskId = taskId === undefined ? group.taskId : taskId;
      activeDays = activeDays === undefined ? group.activeDays : activeDays;
      start = start === undefined ? group.start : start;
      end = end === undefined ? group.end : end;
      approved = approved === undefined ? group.approved : approved;
      sharedUsers = sharedUsers === undefined ? group.sharedUsers : sharedUsers;
      lunchStart =
        lunchStart === undefined ? group.lunchStart || null : lunchStart;
      lunchDurationMinutes =
        lunchDurationMinutes === undefined
          ? group.lunchDurationMinutes || null
          : lunchDurationMinutes;
      workOrderId =
        workOrderId === undefined ? group.workOrderId || null : workOrderId;
    }
  }

  if (group) {
    if (!currentUser.hasPermission('shift_admin')) {
      if (group.approved) {
        return () =>
          Promise.reject(
            Toast.show('Not authorized to update approved shift.')
          );
      } else if (approved) {
        return () =>
          Promise.reject(Toast.show('Not authorized to approve shift.'));
      }
    }
    const originalActiveDays = Array(7)
      .fill(false)
      .map((f, idx) =>
        group.shifts.find(shift => fromDateString(shift.date).day() === idx)
      );
    const isTimeUpdated =
      toTimeString(start) !== toTimeString(group.start) ||
      toTimeString(end) !== toTimeString(group.end);
    const isApprovedUpdated = group.approved !== approved;
    const isTaskUpdated = group.taskId !== taskId;
    const isWorkOrderIdUpdated =
      group.workOrderId !== workOrderId ||
      group.variedWorkOrderId !== variedWorkOrderId;

    // DELETED USERS
    const deletedUserIds = group.sharedUsers.filter(
      originalUserId => !sharedUsers.includes(originalUserId)
    );

    // DELETED DAYS
    const deletedDayIdxs = activeDays
      .map((nowActive, idx) =>
        !nowActive && !!originalActiveDays[idx] ? idx : null
      )
      .filter(idx => idx !== null);
    const deletingShifts = group.shifts.filter(shift =>
      deletedDayIdxs.includes(fromDateString(shift.date).day())
    );

    // ADDED DAYS
    const addedDays = activeDays
      .map((nowActive, idx) =>
        !!nowActive && !originalActiveDays[idx] ? idx : null
      )
      .filter(idx => idx !== null)
      .map(day => moment(weekStartMoment).day(day));

    // UPDATED DAYS
    const updatedDays = !(
      isLunchUpdated ||
      isTimeUpdated ||
      isApprovedUpdated ||
      isTaskUpdated ||
      isWorkOrderIdUpdated
    )
      ? []
      : activeDays
          .map((nowActive, idx) =>
            nowActive && originalActiveDays[idx]
              ? originalActiveDays[idx]
              : null
          )
          .filter(idx => idx !== null);

    // ADDED USERS
    const addedUserIds = sharedUsers.filter(
      currentUser => !group.sharedUsers.includes(currentUser)
    );

    return async () => {
      const allShiftUsers = group.shifts
        .map(shift => shiftUserMap[shift.id] || [])
        .flat();

      try {
        // ADDED DAYS
        const addedDaysLunch = isLunchUpdated
          ? [lunchStart && toTimeString(lunchStart), lunchDurationMinutes]
          : [
              group.lunchStart && toTimeString(group.lunchStart),
              group.lunchDurationMinutes
            ];
        const createdResponse = await Promise.all(
          addedDays.map(dayMoment =>
            shiftDays.create({
              projectId,
              taskId,
              date: toDateString(dayMoment),
              start: toTimeString(start),
              end: toTimeString(end),
              lunchStart: addedDaysLunch[0] || null,
              lunchDurationMinutes: addedDaysLunch[1] || null,
              workOrderId: isWorkOrderIdUpdated
                ? workOrderId
                : group.workOrderId || null,
              approved
            })
          )
        );

        // UPDATED DAYS
        const updatedResponse = await Promise.all(
          updatedDays.map(day =>
            shiftDays.update({
              id: day.id,
              taskId,
              projectId,
              date: day.date,
              start: toTimeString(start),
              end: toTimeString(end),
              lunchStart: isLunchUpdated
                ? lunchStart && toTimeString(lunchStart)
                : undefined,
              lunchDurationMinutes: isLunchUpdated
                ? lunchDurationMinutes
                : undefined,
              workOrderId: isWorkOrderIdUpdated ? workOrderId : undefined,
              approved
            })
          )
        );

        // DELETED USERS
        await Promise.all(
          allShiftUsers
            .filter(shiftUser => deletedUserIds.includes(shiftUser.userId))
            .map(shiftUser => shiftDayUsers.destroy(shiftUser.id))
        );

        // DELETED DAYS
        await Promise.all(
          deletingShifts.map(shift => shiftDays.destroy(shift.id))
        );

        // Add current users to added days
        await Promise.all(
          createdResponse
            .map(shift =>
              sharedUsers.map(userId =>
                shiftDayUsers.create({
                  shiftDayId: shift.id,
                  userId
                })
              )
            )
            .flat()
        );

        // ADDED USERS
        const allShiftIds = [
          ...group.shifts.map(getId),
          ...updatedResponse.map(getId)
        ]
          .filter(id => !deletingShifts.find(toDelete => toDelete.id === id))
          .filter((id, idx, arr) => !(arr.indexOf(id) < idx));
        await Promise.all(
          allShiftIds
            .map(shiftDayId =>
              addedUserIds.map(userId =>
                shiftDayUsers.create({
                  shiftDayId,
                  userId
                })
              )
            )
            .flat()
        );
        await fetchSchdeules();

        Toast.show('Schedule Updated.');
      } catch (err) {
        Toast.showErr(err);
        return Promise.reject(err);
      }
    };
  }

  return () => Promise.reject();
}

function getId({ id }) {
  return id;
}
