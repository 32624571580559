import safeParse from 'helpers/safeParse.helper';
import { useContext } from 'react';
import { ProjectChangeOrdersContext } from '../context/Project.context';

export default function useChangeOrderCost() {
  const { projectChangeOrders } = useContext(ProjectChangeOrdersContext);
  const approvedCOs = projectChangeOrders.filter(co => co.approved);
  const cost = approvedCOs.reduce((sum, co) => safeParse(co.cost) + sum, 0);
  return cost;
}
