import toCurrency from 'helpers/currency.helper';

export const calculateMaterialCosts = ({ material, estimateDetails: est }) => {
  const p = parseFloat;
  const n = (val, def) => (isNaN(val) ? def : val);
  const o = val => n(p(val), 0);
  const toD = num => o(num) / 100;
  const materialExtendedCost = material.quantity * material.unitCost;
  const hrs = material.quantity * material.unitLabor;
  const laborCost = p(est.rawHourlyRate) * n(p(est.overtimeMultiplier), 1);
  const laborCostExtended = laborCost * hrs;
  const tax = toD(est.tax) * materialExtendedCost;
  const lineItemPrice = tax + laborCostExtended + materialExtendedCost;
  const overhead = laborCostExtended * toD(est.overhead);
  const breakevenCosts = overhead + lineItemPrice;
  const materials =
    (materialExtendedCost + tax) * (1 + toD(est.materialHandling));
  const labor = (laborCostExtended + overhead) * (1 + toD(est.laborMarkUp));
  const total = materials + labor;

  return {
    materialExtendedCost,
    hrs,
    laborCost,
    laborCostExtended,
    tax,
    lineItemPrice,
    overhead,
    breakevenCosts,
    materials,
    labor,
    total
  };
};

export const getMaterialCostKeys = () => [
  'materialExtendedCost',
  'hrs',
  'laborCost',
  'laborCostExtended',
  'tax',
  'lineItemPrice',
  'overhead',
  'breakevenCosts',
  'materials',
  'labor',
  'total'
];

export const formatMaterialCostKey = (key, value) => {
  const toFixed = n => (isNaN(parseFloat(n)) ? 0 : parseFloat(n)).toFixed(2);
  if (key === 'materialExtendedCost') return toCurrency(value);
  if (key === 'hrs') return toFixed(value);
  if (key === 'laborCost') return toCurrency(value);
  if (key === 'laborCostExtended') return toCurrency(value);
  if (key === 'tax') return toCurrency(value);
  if (key === 'lineItemPrice') return toCurrency(value);
  if (key === 'overhead') return toCurrency(value);
  if (key === 'breakevenCosts') return toCurrency(value);
  if (key === 'materials') return toCurrency(value);
  if (key === 'labor') return toFixed(value);
  if (key === 'total') return toCurrency(value);
  return value;
};

export const getDefaultMaterialView = () => [
  ['quantity', true],
  ['description', true],
  ['partNumber', true],
  ['total', true],
  ['unitCost', true],
  ['unitLabor', true],
  ['materialExtendedCost', false],
  ['hrs', false],
  ['laborCost', false],
  ['laborCostExtended', false],
  ['tax', false],
  ['lineItemPrice', false],
  ['overhead', false],
  ['breakevenCosts', false],
  ['materials', false],
  ['labor', false]
];

export const phaseMaterialPropertiesToString = material => {
  const properties = getDefaultMaterialView().map(([name]) => name);
  const modifier = {
    unitCost: toCurrency,
    materialExtendedCost: toCurrency,
    laborCost: toCurrency,
    laborCostExtended: toCurrency,
    tax: toCurrency,
    lineItemPrice: toCurrency,
    overhead: toCurrency,
    breakevenCosts: toCurrency,
    materials: toCurrency,
    labor: toCurrency,
    total: toCurrency
  };
  const betterStrings = {};
  properties.forEach(prop => {
    if (modifier[prop]) {
      betterStrings[prop] = modifier[prop](material[prop]);
    } else {
      betterStrings[prop] = material[prop].toString();
    }
  });
  return betterStrings;
};

export const calculateEstimateTotals = (phases, estimateDetails) => {
  const allMaterialsStats = phases
    .map(p => JSON.parse(JSON.stringify(p.materials)))
    .flat()
    .map(material => calculateMaterialCosts({ material, estimateDetails }));

  const p = parseFloat;
  const n = (val, def) => (isNaN(val) ? def : val);
  const r = key => allMaterialsStats.reduce((acc, mat) => mat[key] + acc, 0);
  const o = val => n(p(val), 0);
  const toD = num => o(num) / 100;

  const laborSum = r('laborCostExtended');
  const materialSum = r('materialExtendedCost');

  const laborWholesale = laborSum * (1 + toD(estimateDetails.overhead));
  const materialWholesale = materialSum * (1 + toD(estimateDetails.tax));

  const laborRetail = laborWholesale * (1 + toD(estimateDetails.laborMarkUp));
  const materialRetail =
    materialWholesale * (1 + toD(estimateDetails.materialHandling));

  const laborAndMaterials = laborWholesale + materialWholesale;

  const taxes = r('tax');
  const overhead = r('overhead');

  const perdiem = estimateDetails.isPerdiem
    ? (r('hrs') * o(estimateDetails.perdiemRate)) / 8
    : 0;

  const permitsAndFees = o(estimateDetails.permitCost);

  const markUpExpensesAndFees = (perdiem + permitsAndFees) * 0.05;

  const bondingCosts = 0;

  const netProfit =
    laborRetail +
    materialRetail -
    (laborWholesale + materialWholesale) +
    markUpExpensesAndFees -
    (o(estimateDetails.materialDiscount) + o(estimateDetails.laborDiscount));

  const subcontractTotal =
    o(estimateDetails.subcontractAmount) *
    (1 + toD(estimateDetails.subcontractMarkup));

  const totalBidAmount =
    laborAndMaterials +
    perdiem +
    permitsAndFees +
    markUpExpensesAndFees +
    bondingCosts +
    netProfit +
    subcontractTotal;

  const netProfitRatio = n(netProfit / totalBidAmount, 0);

  const grossProfitRatio = n(
    (overhead + netProfit + subcontractTotal) / totalBidAmount,
    0
  );

  const retVal = {
    laborWholesale,
    materialWholesale,
    laborRetail,
    materialRetail,
    laborAndMaterials,
    taxes,
    overhead,
    perdiem,
    permitsAndFees,
    markUpExpensesAndFees,
    bondingCosts,
    netProfit,
    netProfitRatio,
    grossProfitRatio,
    subcontractTotal,
    totalBidAmount
  };

  return retVal;
};

export const getEstimateTotalPropertyOrder = () => [
  'laborWholesale',
  'materialWholesale',
  'laborRetail',
  'materialRetail',
  'laborAndMaterials',
  'taxes',
  'overhead',
  'perdiem',
  'permitsAndFees',
  'markUpExpensesAndFees',
  'bondingCosts',
  'netProfit',
  'netProfitRatio',
  'grossProfitRatio',
  'subcontractTotal',
  'totalBidAmount'
];

export const estimateTotalsToString = estimateTotals => {
  const properties = getEstimateTotalPropertyOrder();
  const modifier = {
    laborWholesale: toCurrency,
    materialWholesale: toCurrency,
    laborRetail: toCurrency,
    materialRetail: toCurrency,
    laborAndMaterials: toCurrency,
    taxes: toCurrency,
    overhead: toCurrency,
    perdiem: toCurrency,
    permitsAndFees: toCurrency,
    markUpExpensesAndFees: toCurrency,
    bondingCosts: toCurrency,
    netProfit: toCurrency,
    netProfitRatio: v => `${(v * 100).toFixed(3)} %`,
    grossProfitRatio: v => `${(v * 100).toFixed(3)} %`,
    subcontractTotal: toCurrency,
    totalBidAmount: toCurrency
  };
  const betterStrings = {};
  properties.forEach(prop => {
    if (modifier[prop]) {
      betterStrings[prop] = modifier[prop](estimateTotals[prop]);
    } else {
      betterStrings[prop] = estimateTotals[prop].toString();
    }
  });
  return betterStrings;
};
