import React from 'react';
import ProfileMenu from './ProfileMenu.component';
import ChangeLog from './ChangeLog.component';
import FeedbackForm from './FeedbackForm.component';
import ActionItems from './ActionItems.component';
import { useColors } from 'helpers/theme.helper';
import { Divider } from 'helpers/themeSafeMui.helper';

export default function ProfileMenuMobile({ ...props }) {
  const colors = useColors();
  const iconColor = colors.action;

  return (
    <ProfileMenu {...props}>
      <div style={{ marginLeft: 8 }}>
        <ChangeLog color={iconColor} />
        <FeedbackForm color={iconColor} />
        <ActionItems color={iconColor} />
      </div>
      <Divider />
    </ProfileMenu>
  );
}
