import React, { useState } from 'react';
import GoogleDriveTab from './GoogleDrive.tab';
import OneDriveTab from './OneDrive.tab';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

const DriveTab = () => {
  const [usingOneDrive] = useState(() => isUsingOneDrive());
  return usingOneDrive ? <OneDriveTab /> : <GoogleDriveTab />;
};

export default DriveTab;
