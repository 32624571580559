import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Paper } from 'helpers/themeSafeMui.helper';
import { NoItemsResult } from 'components';
import http from 'helpers/http.helper';
import WorkOrderContainer from './components/WorkOrderContainer.component';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

const WorkOrders = props => {
  const { projectId } = props;
  const query = qs.parse(props.location.search);
  const [workOrders, setWorkOrders] = useState([]);
  const [users, setUsers] = useState([]);

  const getWorkOrders = useCallback(() => {
    http()
      .get(`/projects/${projectId}/work-orders`)
      .then(res => setWorkOrders(res));
  }, [projectId]);

  const getUsers = useCallback(() => {
    http()
      .get('/users')
      .then(res => setUsers(res));
  }, []);

  useEffect(() => {
    getWorkOrders();
    getUsers();
  }, [getWorkOrders, getUsers, query.r]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!workOrders.length && (
          <Grid item xs={12}>
            <Paper style={{ padding: 16 }}>
              <NoItemsResult type="Work Orders" style={{ padding: 50 }} />
            </Paper>
          </Grid>
        )}
        {workOrders.map(item => (
          <WorkOrderContainer
            getWorkOrders={getWorkOrders}
            key={item.id}
            users={users}
            item={item}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default withRouter(WorkOrders);
