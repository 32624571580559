import React from 'react';
import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from 'helpers/themeSafeMui.helper';
import usePopper from 'hooks/usePopper.hook';
import { useSelector } from 'react-redux';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import mapReducer from 'helpers/mapReducer.helper';

export default function AreaFilterButton({
  buttonText,
  disabled = false,
  selectedAreas,
  setSelectedAreas,
  filledOnNoneSelected = true
}) {
  const areas = useSelector(state => state.areas);
  const { handleClick, Popper } = usePopper();
  const selectedAreasSet = selectedAreas.reduce(mapReducer('id'), {});

  const toggleArea = area => () => {
    setSelectedAreas(selectedAreas => {
      let copy = [...selectedAreas];
      if (selectedAreasSet[area.id]) {
        const idx = copy.findIndex(other => other.id === area.id);
        copy.splice(idx, 1);
      } else {
        copy = [];
        areas.forEach(other => {
          if (selectedAreasSet[other.id]) copy.push(other);
          if (other.id === area.id) copy.push(area);
        });
      }
      return copy;
    });
  };

  const filtersActive = selectedAreas.length;
  const activeFilterText = `(${filtersActive} filter${
    filtersActive === 1 ? '' : 's'
  } active)`;

  return (
    <>
      <Button
        disabled={disabled}
        style={{ whiteSpace: 'pre-wrap' }}
        variant="contained"
        onClick={handleClick}
      >
        <div>
          {buttonText}
          {!!filtersActive ? (
            <Typography variant="caption" component="div">
              {activeFilterText}
            </Typography>
          ) : null}
        </div>
      </Button>
      <Popper>
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
          <ListItem button onClick={() => setSelectedAreas([])}>
            <ListItemText>Clear Filters</ListItemText>
          </ListItem>

          {areas.map(area => (
            <ListItem button key={area.id} onClick={toggleArea(area)}>
              <ListItemIcon>
                {selectedAreasSet[area.id] ||
                (filledOnNoneSelected && filtersActive === 0) ? (
                  <CheckBoxIcon color="action" />
                ) : (
                  <CheckBoxOutline color="action" />
                )}
              </ListItemIcon>
              <ListItemText>{area.name}</ListItemText>
            </ListItem>
          ))}
        </div>
      </Popper>
    </>
  );
}
