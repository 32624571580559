import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './ducks';
import { CssBaseline } from 'helpers/themeSafeMui.helper';
import { Toast, AuthenticationWrapper, Progress } from 'components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from 'helpers/theme.helper';
import './global.styles.scss';
import { pdfjs } from 'react-pdf';
import GlobalModal from 'modals';
import VersionWrapper from 'helpers/frontend-version-helper';
import TolaIcon from 'helpers/tolaIcon.helper';
import { RecoilRoot } from 'recoil';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const App = () => {
  return (
    <VersionWrapper>
      <TolaIcon />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <RecoilRoot>
          <Provider store={store}>
            <ThemeProvider>
              <AuthenticationWrapper>
                <BrowserRouter>
                  <CssBaseline />
                  <GlobalModal />
                  <Toast />
                  <Progress />
                  <Routes />
                </BrowserRouter>
              </AuthenticationWrapper>
            </ThemeProvider>
          </Provider>
        </RecoilRoot>
      </MuiPickersUtilsProvider>
    </VersionWrapper>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
