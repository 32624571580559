import React, { useState, useEffect, useCallback } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import mapReducer from 'helpers/mapReducer.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export const UsersContext = React.createContext();

const UsersContextProvider = ({ includeInactive, children }) => {
  const currentUser = useCurrentUser();
  const [users, setUsers] = useState([]);
  const [userDetailsMap, setUserDetailsMap] = useState({});
  const fetchUsers = useCallback(() => {
    http()
      .get(
        '/users?includeImage=true' +
          (includeInactive ? '&includeInactive=true' : '')
      )
      .then(res => setUsers(res))
      .catch(Toast.showErr);
    if (currentUser.hasPermission('users_tier_2'))
      http()
        .get('/users/employee-details')
        .then(details => {
          setUserDetailsMap(details.reduce(mapReducer('userId'), {}));
        })
        .catch(Toast.showErr);
  }, [includeInactive, currentUser]);

  useEffect(fetchUsers, [fetchUsers]);

  return (
    <UsersContext.Provider
      value={{ users, setUsers, fetchUsers, userDetailsMap }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
