import React from 'react';
import {
  DashboardWrapper,
  Toast,
  SelectionSortingTable,
  CopyText
} from 'components';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import NewIcon from '@material-ui/icons/AddCircleOutline';
import { connect } from 'react-redux';
import NewCompanyModal from '../modals/NewCompany.modal';
import phoneHelper from 'helpers/phone.helper';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10
  }
};

const columns = [
  { key: 'name', label: 'Name' },
  { key: 'address', label: 'Address' },
  { key: 'phoneNumber', label: 'Phone Number', modifier: phoneHelper },
  {
    key: 'billingEmail',
    label: 'Billing Email',
    modifier: email => (
      <CopyText text={email} type="Email">
        {email}
      </CopyText>
    )
  }
];

class Companies extends React.Component {
  state = {
    companies: [],
    newCompanyModal: false
  };

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = () => {
    http()
      .get('/companies')
      .then(res => {
        this.setState({
          companies: res,
          newCompanyModal: false
        });
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <DashboardWrapper>
        <div style={styles.header}>
          <NewCompanyModal
            open={this.state.newCompanyModal}
            refresh={this.getCompanies}
            onClose={() => this.setState({ newCompanyModal: false })}
          />

          <Typography component="h1" variant="h4">
            Companies
            <IconButton
              color="primary"
              style={{ marginLeft: 4 }}
              onClick={() => this.setState({ newCompanyModal: true })}
            >
              <NewIcon />
            </IconButton>
          </Typography>
        </div>

        <SelectionSortingTable
          isSelecting={false}
          columns={columns}
          rows={this.state.companies}
          rowAction={row => this.props.history.push(`/companies/${row.id}`)}
        />
      </DashboardWrapper>
    );
  }
}

export default connect(state => ({ user: state.user }))(Companies);
