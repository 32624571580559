import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { Grid, Button } from 'helpers/themeSafeMui.helper';
import states from 'helpers/states.helper';
import EditableValue from './EditableValue.component';

function Tier2Details(props) {
  const { userId } = props;
  const [editing, setEditing] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [editedDetails, setEditedDetails] = useState({
    SSN: '',
    SSCardFile: undefined,
    DLNumber: '',
    DLState: '',
    DLFile: undefined,
    policyAcknowledged: null,
    hasInsurance: false,
    providerName: '',
    wantsInsurance: false,
    w4File: undefined,
    w4IdahoFile: undefined,
    i9File: undefined
  });

  useEffect(() => {
    setEditing(false);
  }, [userId]);

  useEffect(() => {
    setEditedDetails({
      SSN: employeeDetails.SSN ? '•••••' : '',
      SSCardFile: undefined,
      DLNumber: employeeDetails.DLNumber ? '•••••' : '',
      DLState: employeeDetails.DLState || '',
      DLFile: undefined,
      policyAcknowledged: employeeDetails.employeePolicyAcknowledged || null,
      hasInsurance: employeeDetails.hasInsurance || false,
      providerName: employeeDetails.providerName || '',
      wantsInsurance: employeeDetails.wantsInsurance || false,
      w4File: undefined,
      w4IdahoFile: undefined,
      i9File: undefined
    });
  }, [employeeDetails, editing]);

  useEffect(() => {
    if (!userId) return;
    http()
      .get('/users/' + userId + '/employee-details')
      .then(res => setEmployeeDetails(res))
      .catch(e => Toast.show(e.message));
  }, [userId]);

  const save = e => {
    e.preventDefault();

    http()
      .put('/users/' + userId + '/employee-details', {
        SSN: editedDetails.SSN !== '•••••' ? editedDetails.SSN : undefined,
        SSCardFile: editedDetails.SSCardFile,
        DLNumber:
          editedDetails.DLNumber !== '•••••'
            ? editedDetails.DLNumber
            : undefined,
        DLState: editedDetails.DLState,
        DLFile: editedDetails.DLFile,
        hasInsurance: editedDetails.hasInsurance,
        providerName: editedDetails.providerName || '',
        wantsInsurance: editedDetails.wantsInsurance,
        w4File: editedDetails.w4File,
        w4IdahoFile: editedDetails.w4IdahoFile,
        i9File: editedDetails.i9File
      })
      .then(employeeDetails => {
        setEmployeeDetails(employeeDetails);
        setEditing(false);
      })
      .then(() => Toast.show('Updated.'))
      .catch(e => Toast.show(e.message));
  };

  return (
    <form onSubmit={save}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EditableValue
            editing={editing}
            label="Driver's License Number"
            type="encrypted"
            encryptedValue={employeeDetails.DLNumber}
            value={editedDetails.DLNumber}
            required={true}
            onChange={DLNumber =>
              setEditedDetails({ ...editedDetails, DLNumber })
            }
          />
          <EditableValue
            editing={editing}
            label="Driver's License State"
            value={editedDetails.DLState}
            required={true}
            type="dropdown"
            options={states.map(state => ({ value: state, label: state }))}
            onChange={DLState =>
              setEditedDetails({ ...editedDetails, DLState })
            }
          />
          <EditableValue
            editing={editing}
            label="Driver's License File"
            type="encryptedFile"
            value={editedDetails.DLFile}
            encryptedValue={employeeDetails.DLFile}
            required={true}
            onChange={DLFile => setEditedDetails({ ...editedDetails, DLFile })}
          />
          <EditableValue
            editing={editing}
            label="Social Security Number"
            value={editedDetails.SSN}
            type="encrypted"
            encryptedValue={employeeDetails.SSN}
            required={true}
            onChange={SSN => setEditedDetails({ ...editedDetails, SSN })}
          />
          <EditableValue
            editing={editing}
            label="Social Security Card File"
            type="encryptedFile"
            value={editedDetails.SSCardFile}
            encryptedValue={employeeDetails.SSCardFile}
            required={true}
            onChange={SSCardFile =>
              setEditedDetails({ ...editedDetails, SSCardFile })
            }
          />
          <EditableValue
            editing={editing}
            label="W-4 File"
            type="encryptedFile"
            value={editedDetails.w4File}
            encryptedValue={employeeDetails.w4File}
            required={true}
            onChange={w4File => setEditedDetails({ ...editedDetails, w4File })}
          />
          <EditableValue
            editing={editing}
            label="Idaho W-4 File"
            type="encryptedFile"
            value={editedDetails.w4IdahoFile}
            encryptedValue={employeeDetails.w4IdahoFile}
            required={true}
            onChange={w4IdahoFile =>
              setEditedDetails({ ...editedDetails, w4IdahoFile })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <EditableValue
            editing={editing}
            label="Has Insurance"
            value={editedDetails.hasInsurance}
            required={true}
            type="boolean"
            onChange={hasInsurance =>
              setEditedDetails({ ...editedDetails, hasInsurance })
            }
          />
          <EditableValue
            editing={editing}
            label="Current Insurance Provider"
            value={editedDetails.providerName}
            onChange={providerName =>
              setEditedDetails({ ...editedDetails, providerName })
            }
          />
          <EditableValue
            editing={editing}
            label="Interested in Company Insurance"
            value={editedDetails.wantsInsurance}
            required={true}
            type="boolean"
            onChange={wantsInsurance =>
              setEditedDetails({ ...editedDetails, wantsInsurance })
            }
          />
          <EditableValue
            label="Employee Policy Acknowledged"
            value={editedDetails.policyAcknowledged}
            onChange={policyAcknowledged =>
              setEditedDetails({ ...editedDetails, policyAcknowledged })
            }
            type="dateTime"
            hidden={editing}
          />
          <EditableValue
            editing={editing}
            label="I-9 File"
            type="encryptedFile"
            value={editedDetails.i9File}
            encryptedValue={employeeDetails.i9File}
            required={true}
            onChange={i9File => setEditedDetails({ ...editedDetails, i9File })}
          />
        </Grid>
        {!editing && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        )}
        {editing && (
          <div>
            <Button
              variant="contained"
              onClick={() => setEditing(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </div>
        )}
      </Grid>
    </form>
  );
}

export default Tier2Details;
