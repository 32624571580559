import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { SortingTable } from 'components';
import { CompanyPeopleContext } from '../context/Company.context';
import phoneHelper from 'helpers/phone.helper';

function PeopleTab(props) {
  const { companyPeople } = useContext(CompanyPeopleContext);

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'jobTitle', label: 'Job Title' },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      extractor: column => {
        const base =
          column.phoneNumbers && column.phoneNumbers[0]
            ? column.phoneNumbers[0].phoneNumber
            : '';
        if (!base) return '';
        const ext = column.phoneNumbers[0].ext || null;
        return phoneHelper(base) + (ext ? `, Ext. ${ext}` : '');
      }
    },
    {
      key: 'email',
      label: 'Email',
      extractor: column => (column.emails[0] ? column.emails[0].email : null)
    },
    {
      key: 'inactive',
      label: 'Inactive',
      extractor: person => (person.inactive ? 'Inactive' : 'Active')
    }
  ];

  return (
    <div>
      <SortingTable
        columns={columns}
        rows={companyPeople}
        rowAction={row => props.history.push(`/people/${row.id}`)}
      />
    </div>
  );
}

export default withRouter(PeopleTab);
