import React, { useEffect, useState, useCallback } from 'react';
import { Toast } from 'components';
import {
  Typography,
  CircularProgress,
  Paper,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import moment from 'moment';
import Document from './components/Document.component';

const Esign = props => {
  const { token, onComplete } = props;
  const [document, setDocument] = useState(null);
  const [signer, setSigner] = useState(null);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);

  const openDocument = useCallback(() => {
    setLoading(true);
    http()
      .get('/esign-documents/unknown/open', { params: { token } })
      .then(({ document, signer, fields }) => {
        setDocument(document);
        setSigner(signer);
        setFields(fields);
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    openDocument();
  }, [openDocument]);

  const handleSubmit = e => {
    e.preventDefault();

    const formattedFields = fields.map(f => ({
      id: f.id,
      value: f.value || ''
    }));

    if (onComplete) {
      return onComplete({ token, fields: formattedFields, setLoading });
    }

    setLoading(true);
    http()
      .put(`/esign-documents/${document.id}/sign`, {
        token: token,
        name: signer.name,
        fields: formattedFields
      })
      .then(() => {
        setSigner({ ...signer, completedAt: new Date() });
        // Send to next signer
        return http().put('/esign-documents/' + document.id + '/send');
      })
      .catch(err => Toast.show(err.message))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh'
        }}
      >
        <CircularProgress style={{ marginBottom: 25 }} />
        <Typography variant="h6">LOADING</Typography>
      </div>
    );
  }

  if (signer.completedAt) {
    return (
      <Paper style={{ margin: '100px auto', padding: 35, maxWidth: 500 }}>
        <img
          src="https://storage.googleapis.com/steps-files/assets/logo-with-subtitle.png"
          alt="STEPS Logo"
          style={{ height: 65, display: 'block', margin: '0 auto 25px auto' }}
        />
        <Typography>
          Document submitted on{' '}
          <strong>{moment(signer.completedAt).format('LLL')}</strong>. You will
          receive a copy of the finalized document by email once all parties
          have signed.
        </Typography>
      </Paper>
    );
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', minHeight: '80vh' }}>
      <img
        src="https://storage.googleapis.com/steps-files/assets/logo-with-subtitle.png"
        alt="STEPS Logo"
        style={{ height: 65, display: 'block', margin: '0 auto 15px auto' }}
      />
      <Typography component="h1" variant="h4" gutterBottom>
        eSign Document
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={openDocument}
        style={{ position: 'fixed', top: 25, left: 25, zIndex: 99 }}
        tabIndex={-1}
      >
        Reset Document
      </Button>

      <Typography variant="body2">
        You may withdraw your consent to receive electronic documents, notices
        or disclosures at any time. In order to withdraw consent, you must
        notify the sending party that you wish to withdraw consent and request
        that your future documents, notices and disclosures be provided in paper
        format. To request paper copies of documents; withdraw consent to
        conduct business electronically and receive documents, notices or
        disclosures electronically; or withdraw consent to sign documents
        electronically, please contact the sending party by telephone, postal
        mail or email.
      </Typography>

      <Document
        fields={fields}
        signer={signer}
        document={document}
        onUpdate={setFields}
        onUpdateSigner={setSigner}
      />

      <Typography style={{ marginTop: 25, marginBottom: 10 }} variant="body2">
        The parties agree that this agreement may be electronically signed. The
        parties agree that the electronic signatures appearing on this agreement
        are the same as handwritten signatures for the purposes of validity,
        enforceability and admissibility.
      </Typography>
      <Button variant="contained" color="primary" fullWidth type="submit">
        Agree and Sign Document
      </Button>
    </form>
  );
};

export default Esign;
