import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Toolbar,
  AppBar,
  IconButton,
  Tooltip,
  Typography,
  Dialog
} from 'helpers/themeSafeMui.helper';
import SideMenu from './components/SideMenu.component';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import theme, { useColors, withColors } from 'helpers/theme.helper';
import ProfileMenu from './components/ProfileMenu.component';
import UserActionItems from './components/ActionItems.component';
import GlobalSearch from './components/GlobalSearch.component';
import { Link } from 'react-router-dom';
import ChangeLog from './components/ChangeLog.component';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import FeedbackForm from './components/FeedbackForm.component';
import ProfileMenuMobile from './components/ProfileMenuMobile.component';
import SearchIcon from '@material-ui/icons/Search';
import useBreakpoint from 'hooks/useBreakpoint.hook';

function DashboardWrapper({ setContentHeight, noPad, style, children }) {
  const isSmallScreen = useBreakpoint('xs');
  const [smallScreenOpen, setSmallScreenOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const colors = useColors();
  const appBarRef = useRef(null);
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [sideBarOpen, setSidebarOpen] = useLocalStorage(
    'side-bar-open',
    false,
    joi => joi.boolean()
  );

  const SIDEBAR_OPEN_WIDTH = 235;
  const SIDEBAR_CLOSED_WIDTH = isSmallScreen ? 0 : 70;

  const CONTENT_WIDTH = isSmallScreen
    ? '100%'
    : `calc(100% - ${
        sideBarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH
      }px)`;

  const APP_BAR_HEIGHT = appBarRef.current ? appBarRef.current.scrollHeight : 0;
  const CONTENT_HEIGHT = `calc(100vh - ${APP_BAR_HEIGHT}px)`;

  const toggleSideBar = () => setSidebarOpen(!sideBarOpen);

  const styles = {
    flex: { display: 'flex', minHeight: 'calc(100vh - 64px)' },
    appBar: { zIndex: theme.zIndex.drawer + 1 },
    grow: { flexGrow: 1, display: 'flex', alignItems: 'center' },
    menuIcon: { marginLeft: -12, marginRight: 17 },
    content: {
      color: colors.backgroundText,
      padding: noPad ? 0 : isSmallScreen ? 12 : 20,
      width: CONTENT_WIDTH,
      height: setContentHeight ? CONTENT_HEIGHT : undefined,
      transition: 'width .3s',
      ...style
    },
    logo: {
      width: 100,
      maxWidth: '100%',
      display: 'block'
    }
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" style={styles.appBar} ref={appBarRef}>
        <Toolbar>
          <IconButton
            color="inherit"
            style={styles.menuIcon}
            onClick={() =>
              isSmallScreen ? setSmallScreenOpen(true) : toggleSideBar()
            }
          >
            <MenuIcon />
          </IconButton>

          <div style={styles.grow}>
            <div>
              <Link to="/">
                <img
                  style={styles.logo}
                  src="https://storage.googleapis.com/steps-files/assets/steps-logo-white.png"
                  alt="Steps-logo"
                />
              </Link>
            </div>
            {isUsingOneDrive() && (
              <Typography
                style={{ marginLeft: 10, fontWeight: 500 }}
                variant="h5"
              >
                TOLA
              </Typography>
            )}

            {!isSmallScreen && <GlobalSearch />}
          </div>

          {isSmallScreen ? (
            <>
              <IconButton onClick={() => setSearchOpen(true)}>
                <SearchIcon
                  style={{ color: colors.primaryContrastText }}
                  color="inherit"
                />
              </IconButton>

              <Dialog
                maxWidth="md"
                fullWidth
                open={searchOpen}
                onClose={() => setSearchOpen(false)}
              >
                <GlobalSearch
                  onLinkClick={() => setSearchOpen(false)}
                  forceOpen
                  styles={{
                    search: {
                      marginLeft: 0,
                      maxWidth: null,
                      width: null
                    },
                    searchIcon: {
                      color: colors.paperText
                    },
                    searchInput: {
                      height: 60,
                      color: colors.paperText
                    },
                    paper: {
                      position: 'relative'
                    }
                  }}
                />
              </Dialog>
              <ProfileMenuMobile
                anchor={menuAnchor}
                onClose={() => setMenuAnchor(null)}
              />
            </>
          ) : (
            <>
              <ChangeLog />
              <FeedbackForm />
              <UserActionItems />
              <ProfileMenu
                anchor={menuAnchor}
                onClose={() => setMenuAnchor(null)}
              />
            </>
          )}

          <Tooltip title="My Account">
            <IconButton
              onClick={e => setMenuAnchor(e.currentTarget)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <div style={styles.flex}>
        <SideMenu
          open={isSmallScreen ? smallScreenOpen : sideBarOpen}
          toggleSideBar={toggleSideBar}
          setSmallScreenOpen={setSmallScreenOpen}
          openWidth={SIDEBAR_OPEN_WIDTH}
          closedWidth={SIDEBAR_CLOSED_WIDTH}
        />
        <div style={styles.content}>{children}</div>
      </div>
    </React.Fragment>
  );
}

export default withColors(
  connect(state => ({ user: state.user }))(DashboardWrapper)
);
