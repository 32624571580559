import React from 'react';
import {
  toDollar,
  twoAfterDec,
  trim0s
} from 'helpers/floatStringFormatter.helper';
import ProjectCountCell from '../components/ProjectCountCell.component';
import ConflictReplacementCell, {
  getConflictSortingNumber
} from '../components/ConflictReplacementCell.component';
import ExternalLinkCell from '../components/ExternalLinkCell.component';
import MasterMaterialCell from '../components/MasterMaterialCell.component';
import UpdateQuantityCell from '../components/UpdateQuantityCell.component';
import QuantityCell from '../components/QuantityCell.component';
import StatusDeployedCell from '../components/StatusDeployedCell.component';
import StatusReceivedCell from '../components/StatusReceivedCell.component';
import StatusReturnedCell from '../components/StatusReturnedCell.component';
import OverflowCell from '../components/OverflowCell.component';
import safeParse from 'helpers/safeParse.helper';

const fz = Object.freeze;

const MATERIAL_COLUMNS = fz([
  fz({ key: '_checkbox', IconButtonProps: { tabIndex: -1 } }),
  fz({
    key: 'projectCount',
    label: '',
    headerCellProps: { padding: 'checkbox' },
    renderer: (material, info, style) => (
      <ProjectCountCell cellStyle={style} material={material} />
    )
  }),
  fz({ key: 'manufacturer', label: 'Manufacturer' }),
  fz({
    key: 'model',
    label: 'Model',
    renderer: (material, info, style) => (
      <OverflowCell
        cellStyle={style}
        popoverTitle="Model"
        text={material.model}
        maxWidth={160}
      />
    )
  }),
  fz({
    key: 'partNumber',
    label: 'Part Number',
    renderer: (material, info, style) => (
      <OverflowCell
        cellStyle={style}
        popoverTitle="Part Number"
        text={material.partNumber}
        maxWidth={160}
      />
    )
  }),
  fz({
    key: 'conflictReplaceWith',
    label: 'Conflict Replacement',
    customSort: (a, b) =>
      getConflictSortingNumber(a) - getConflictSortingNumber(b),
    renderer: (material, info, style) => (
      <ConflictReplacementCell material={material} cellStyle={style} />
    )
  }),
  fz({
    key: 'materialId',
    label: 'Master List Material',
    customSort: (a, b) =>
      !a || !b
        ? 0
        : a.manufacturer < b.manufacturer
        ? -1
        : a.manufacturer > b.manufacturer
        ? 1
        : 0,
    renderer: (material, info, style) => (
      <MasterMaterialCell material={material} cellStyle={style} />
    )
  }),
  fz({
    key: 'description',
    label: 'Description',
    renderer: (material, info, style) => (
      <OverflowCell
        cellStyle={style}
        popoverTitle="Description"
        text={material.description}
        maxWidth={200}
      />
    )
  }),
  fz({
    key: 'quantity',
    label: 'Quantity',
    extractor: row => trim0s(row.quantity),
    customSort: numSort('quantity'),
    renderer: (material, info, style) => (
      <QuantityCell cellStyle={style} material={material} />
    )
  }),
  fz({
    key: 'updateQuantity',
    label: '',
    ...numSortExtractor(row => row.updateQuantity || trim0s(row.quantity)),
    renderer: (material, info, style) => (
      <UpdateQuantityCell cellStyle={style} material={material} />
    )
  }),
  fz({
    key: 'unitCost',
    label: 'Unit Cost',
    extractor: row => toDollar(row.unitCost),
    customSort: numSort('unitCost')
  }),
  fz({
    key: 'unitLabor',
    label: 'Unit Labor',
    extractor: row => twoAfterDec(row.unitLabor),
    customSort: numSort('unitLabor')
  }),
  fz({
    key: 'specSheet',
    label: 'SpecSheet',
    renderer: (material, info, style) => (
      <ExternalLinkCell link={material.specSheet} cellStyle={style} />
    )
  }),
  fz({
    key: 'OM',
    label: 'OM',
    renderer: (material, info, style) => (
      <ExternalLinkCell link={material.OM} cellStyle={style} />
    )
  })
]);

export default MATERIAL_COLUMNS;

export const statusColumns = fz([
  fz({
    key: 'received',
    label: 'Received',
    extractor: row => trim0s(row.quantity),
    customSort: numSort('quantity'),
    renderer: (material, info, style) => (
      <StatusReceivedCell material={material} cellStyle={style} />
    )
  }),
  fz({
    key: 'deployed',
    label: 'Deployed',
    extractor: row => trim0s(row.quantity),
    customSort: numSort('quantity'),
    renderer: (material, info, style) => (
      <StatusDeployedCell material={material} cellStyle={style} />
    )
  }),
  fz({
    key: 'returned',
    label: 'Returned',
    extractor: row => trim0s(row.quantity),
    customSort: numSort('quantity'),
    renderer: (material, info, style) => (
      <StatusReturnedCell material={material} cellStyle={style} />
    )
  })
]);

function numSort(key) {
  return (a, b) => {
    a = safeParse(a[key], -Infinity);
    b = safeParse(b[key], -Infinity);
    return a - b;
  };
}

function numSortExtractor(extractor) {
  const customSort = (a, b) => {
    a = safeParse(extractor(a), -Infinity);
    b = safeParse(extractor(b), -Infinity);
    return a - b;
  };
  return { customSort, extractor };
}
