import React, { useCallback, useState } from 'react';
import { Popover } from 'helpers/themeSafeMui.helper';

export default function usePopper() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const close = useCallback(() => setAnchorEl(null), []);

  const Popper = useCallback(
    ({ children, ...props }) => (
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        {...props}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Popover>
    ),
    [anchorEl]
  );

  return {
    handleClick,
    setAnchorEl,
    isOpen: !!anchorEl,
    close,
    Popper
  };
}
