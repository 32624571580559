import React, { useState, useEffect } from 'react';
import { Toast, NoItemsResult } from 'components';
import {
  Typography,
  Button,
  List,
  TextField
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import UserNote from '../components/UserNote.component';

const Notes = props => {
  const { userId } = props;
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    http()
      .get(`/users/${userId}/notes`)
      .then(res => {
        setNotes(res);
      })
      .catch(err => Toast.show(err.message));
  }, [userId]);

  const addNote = note => {
    http()
      .post(`/users/${userId}/notes`, {
        note: note
      })
      .then(res => {
        Toast.show('The note was added.');
        setNotes([res].concat(notes));
        setNote('');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <div style={{ padding: 15 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Notes
        </Typography>
      </div>
      <List>
        {!notes.length && <NoItemsResult type={'User Notes'} />}
        {notes.map(note => (
          <UserNote
            key={note.id}
            note={note}
            userId={userId}
            notes={notes}
            setNotes={setNotes}
          />
        ))}
      </List>
      <TextField
        label="Add User Note"
        multiline
        maxRows="4"
        fullWidth
        style={{ marginBottom: 10, marginTop: 15 }}
        value={note}
        onChange={e => setNote(e.target.value)}
        variant="outlined"
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={() => addNote(note)}
          color="primary"
          variant="outlined"
        >
          Add Note
        </Button>
      </div>
    </div>
  );
};

export default Notes;
