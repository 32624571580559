import React from 'react';
import ProjectStatuses from './components/ProjectStatuses.component';
import DToolsStatusMap from './components/DToolsStatusMap.component';
import StatusMerge from './components/StatusMerge.component';

const ProjectStatusesTab = () => {
  return (
    <>
      <ProjectStatuses />
      <DToolsStatusMap />
      <StatusMerge />
    </>
  );
};

export default ProjectStatusesTab;
