import React, { useState, useContext } from 'react';

import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  useTheme,
  IconButton,
  Tooltip
} from 'helpers/themeSafeMui.helper';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import { useColors } from 'helpers/theme.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import DetailsPanel from './panels/DetailsPanel.component';
import PhasePanel from './panels/PhasePanel.component';
import { PhaseContext } from 'routes/Projects/context/Estimate.context';
import SummaryItemContainer from './SummaryItemContainer.component';
import { calculateMaterialCosts } from '../helpers/phaseMaterial.helper';
import UpdateEstimateNameModal from '../modals/UpdateEstimateName.modal';
import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import { useHistory } from 'react-router-dom';

function EstimateContainer(props) {
  const { estimate, onUpdate } = props;
  const colors = useColors();
  const { phases, setPhases } = useContext(PhaseContext);

  const [isOpen, setIsOpen] = useState(estimate.isOpen || false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [loading, setLoading] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const phasesMaterialsCosts = phases.map(phase =>
    phase.materials.map(material =>
      calculateMaterialCosts({ material, estimateDetails: estimate })
    )
  );
  const history = useHistory();
  const theme = useTheme();

  const ESTIMATE_URL = `/projects/${estimate.projectId}/estimates/${estimate.id}`;

  const duplicateEstimate = () => {
    let {
      id,
      created,
      createdBy,
      projectId,
      archived,
      isOpen,
      ...dupEstimate
    } = estimate;
    setLoading(true);

    http()
      .post(`/projects/${estimate.projectId}/estimates`, {
        ...dupEstimate,
        estimateNumber: estimate.estimateNumber + 1
      })
      .then(newEstimate => {
        const newEstimatePhaseUrl = `/projects/${newEstimate.projectId}/estimates/${newEstimate.id}/phases`;
        const dupedPhases = phases.map(({ name, order, materials }) => ({
          name,
          order,
          materials
        }));
        return Promise.all(
          dupedPhases.map(dup => http().post(newEstimatePhaseUrl, dup))
        );
      })
      .then(() => {
        setLoading(false);
        onUpdate();
      })
      .catch(err => {
        setLoading(false);
        Toast.show(err.message);
      });
  };

  const removeEstimate = () => {
    setLoading(true);
    http()
      .delete(ESTIMATE_URL)
      .then(() => {
        onUpdate();
      })
      .catch(err => {
        setLoading(false);
        Toast.show(err.message);
      });
  };

  const createQuote = async () => {
    try {
      setLoading(true);
      const priceHrs = phasesMaterialsCosts.map(materialsCosts =>
        materialsCosts.reduce(
          ([priceAcc, hrAcc], materialCosts) => [
            materialCosts.total + priceAcc,
            materialCosts.hrs + hrAcc
          ],
          [0, 0]
        )
      );
      const [prices, hrs] = priceHrs.reduce(
        (acc, [price, hr]) => {
          acc[0].push(price);
          acc[1].push(hr);
          return acc;
        },
        [[], []]
      );
      await http().post(
        `/projects/${estimate.projectId}/estimates/${estimate.id}/quote`,
        { phasePrices: prices, phaseHrs: hrs }
      );
      history.push(`/projects/${estimate.projectId}/new-quotes`);
      setLoading(false);
    } catch (err) {
      Toast.show(err.message);
      setLoading(false);
    }
  };

  const saveName = ({ name, estimateNumber }) => {
    setLoading(true);
    http()
      .put(ESTIMATE_URL, { name, estimateNumber })
      .then(() => {
        onUpdate();
        Toast.show('Name updated');
        setEditingName(false);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        Toast.show(err.message);
      });
  };

  const createPhase = phase => {
    let data;
    if (!phase.name) {
      data = { name: 'New Phase' };
    } else {
      data = { name: `${phase.name} - Duplicate`, materials: phase.materials };
    }

    http()
      .post(`${ESTIMATE_URL}/phases`, { ...data, order: phases.length })
      .then(res => {
        setPhases(phases.concat(res));
        setSelectedTab(phases.length + 1);
      })
      .catch(err => Toast.show(err.message));
  };

  const removePhase = phaseId => {
    http()
      .delete(`${ESTIMATE_URL}/phases/${phaseId}`)
      .then(res => {
        setPhases(res);
        setSelectedTab(selectedTab - 1);
      })
      .catch(err => Toast.show(err.message));
  };

  const savePhase = phase => {
    setPhases(
      phases.map(oldPhase => {
        if (oldPhase.id === phase.id) {
          return phase;
        }
        return oldPhase;
      })
    );
    http()
      .put(`${ESTIMATE_URL}/phases/${phase.id}`, {
        name: phase.name,
        materials: phase.materials
      })
      .then(res => {
        setPhases(
          phases.map(phase => {
            if (phase.id === res.id) {
              return res;
            }
            return phase;
          })
        );
      })
      .catch(err => {
        setPhases(phases);
        Toast.show(err.message);
      });
  };

  return (
    <Grid item xs={12}>
      <UpdateEstimateNameModal
        open={editingName}
        onClose={() => setEditingName(false)}
        onSave={saveName}
        name={estimate.name}
        number={estimate.estimateNumber}
      />
      <Paper style={{ padding: 16 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16
          }}
        >
          <div style={{ width: '34%' }}>
            <Typography
              variant="h4"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Tooltip title="Edit Name" placement="top-start" arrow>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEditingName(true)}
                >
                  {estimate.name} - #{estimate.estimateNumber}
                </span>
              </Tooltip>
              {loading ? (
                <IconButton>
                  <CachedIcon fontSize="inherit" />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setEditingName(true)}
                  style={{ marginLeft: 10 }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div style={{ marginRight: 24 }}>
              <Button
                variant="outlined"
                style={{ marginRight: 8 }}
                color="primary"
                onClick={createQuote}
                disabled={loading}
              >
                Create Quote
              </Button>

              <Button
                variant="outlined"
                style={{ color: colors.error, marginRight: 8 }}
                onClick={removeEstimate}
                disabled={loading}
              >
                Remove Estimate
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={duplicateEstimate}
                disabled={loading}
              >
                Duplicate Estimate
              </Button>
            </div>
            {!isOpen ? (
              <DownArrowIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <UpArrowIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </div>
        </div>

        <SummaryItemContainer />

        <Divider />
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          {isOpen && (
            <>
              <div style={{ width: theme.typography.pxToRem(200) }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={selectedTab}
                  onChange={(e, newIndex) => setSelectedTab(newIndex)}
                  style={{
                    borderRight: `1px solid ${colors.grey}`,
                    height: '100%'
                  }}
                >
                  <Tab
                    label="Details"
                    key={'details'}
                    style={{ paddingLeft: 0 }}
                  />

                  {phases.map(phase => (
                    <Tab
                      label={phase.name}
                      key={phase.id}
                      style={{ paddingLeft: 0 }}
                    />
                  ))}

                  <Tab
                    label="+ Phase"
                    style={{
                      paddingLeft: 0,
                      color: `${colors.success}`
                    }}
                    onClick={createPhase}
                  />
                </Tabs>
              </div>
              <div
                style={{
                  width: `calc(100% - ${theme.typography.pxToRem(200)})`
                }}
              >
                <DetailsPanel
                  selectedTab={selectedTab}
                  index={0}
                  estimate={estimate}
                />
                {phases.map((phase, index) => (
                  <PhasePanel
                    key={phase.id}
                    selectedTab={selectedTab}
                    tabIndex={index + 1}
                    onDelete={() => removePhase(phase.id)}
                    savePhase={phaseState => savePhase(phaseState)}
                    duplicatePhase={phaseState => createPhase(phaseState)}
                    projectId={estimate.projectId}
                    phase={phase}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </Paper>
    </Grid>
  );
}

export default EstimateContainer;
