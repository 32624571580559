import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Dialog,
  List,
  ListItem,
  DialogTitle,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import moment from 'moment';
import { UsersContext } from '../context/users.context';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import { useColors } from 'helpers/theme.helper';
import { ProjectsContext } from '../context/projects.context';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ViewPerDiemModal = ({
  open,
  onClose,
  perDiem,
  setPerdiem,
  removePerdiem,
  onEditClick
}) => {
  const [edits, setEdits] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const { usersMap } = useContext(UsersContext);
  const { projectsMap } = useContext(ProjectsContext);
  const colors = useColors();
  const bottomRef = useRef();
  const perDiemId = perDiem && perDiem.id;

  const deletePerdiem = () => {
    if (perDiemId) {
      http()
        .delete(`/per-diems/${perDiemId}`)
        .then(() => {
          clearAndClose();
          removePerdiem(perDiemId);
        })
        .catch(err => Toast.show(err.message));
    }
  };

  const clearAndClose = () => {
    setEdits(null);
    setConfirming(false);
    onClose();
  };

  useEffect(() => {
    if (perDiemId) {
      http()
        .get(`/per-diems/${perDiemId}`)
        .then(perDiem => setPerdiem(perDiem))
        .catch(err => Toast.show(err.message));
      http()
        .get(`/per-diems/${perDiemId}/edits`)
        .then(edits => setEdits(edits.filter(edit => edit.field !== 'notes')))
        .catch(err => Toast.show(err.message));
    }
  }, [perDiemId, setPerdiem]);

  useEffect(() => {
    if (edits && edits.length && bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [edits]);

  const user = perDiem && usersMap[perDiem.userId];
  const project = perDiem && projectsMap[perDiem.projectId];
  const formatDate = date => moment(date).format('ddd, LL');
  const hasEdits = edits && !!edits.length;
  const titleProps = {
    style: {
      fontWeight: 500,
      marginRight: 9
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={clearAndClose}>
      {!perDiem ? null : (
        <>
          <DialogTitle>
            {project && `#${project.projectNumber} - ${project.name}`}
          </DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0 16px'
            }}
          >
            {onEditClick && (
              <IconButton size="small" onClick={() => onEditClick()}>
                <EditIcon />
              </IconButton>
            )}
            {confirming ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Are you sure?
                <div>
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={deletePerdiem}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Yes
                  </Button>
                </div>
                <div>
                  <Button
                    style={{ marginLeft: 5 }}
                    variant="contained"
                    color="default"
                    size="small"
                    onClick={() => setConfirming(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            ) : (
              <IconButton
                style={{ marginLeft: 10 }}
                size="small"
                onClick={() => setConfirming(true)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <List>
            <ListItem>
              <Typography {...titleProps}>Created On:</Typography>
              <Typography>{formatDate(perDiem.created)}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Created By:</Typography>
              <Typography>{user && user.name}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Start Date:</Typography>
              <Typography>{formatDate(perDiem.startDate)}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>End Date:</Typography>
              <Typography>{formatDate(perDiem.endDate)}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Daily Amount:</Typography>
              <Typography>{perDiem.dailyAmount}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Total Amount:</Typography>
              <Typography>{perDiem.totalAmount}</Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Locked:</Typography>
              <Typography>
                {perDiem.locked ? (
                  <BsLockFill color={colors.error} size={20} />
                ) : (
                  <BsUnlockFill color={colors.success} size={20} />
                )}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography {...titleProps}>Status:</Typography>
              <Typography>{perDiem.status}</Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Notes:"
                secondary={perDiem.notes || '---'}
                secondaryTypographyProps={{ style: { whiteSpace: 'pre-wrap' } }}
              />
            </ListItem>
          </List>
          {hasEdits && (
            <>
              <Divider />
              <ListItem dense>
                <ListItemText
                  primary="Edits:"
                  primaryTypographyProps={{ style: { fontWeight: 500 } }}
                />
              </ListItem>
              <Divider />
              <List style={{ maxHeight: 196, overflowY: 'auto' }}>
                {edits.map(edit => {
                  if (!usersMap[edit.editorId]) return null;
                  const field = unCamelCase(edit.field);
                  const { from, to, editorId, created, id } = edit;
                  const editor = usersMap[editorId];
                  return (
                    <ListItem dense key={id}>
                      <ListItemText
                        primary={`${field}: ${from} -> ${to}`}
                        secondary={
                          editor.name + ' - ' + moment(created).format('LLL')
                        }
                      />
                    </ListItem>
                  );
                })}
                <div ref={bottomRef} />
              </List>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

const unCamelCase = str =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

export default ViewPerDiemModal;
