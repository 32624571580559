const pubsub = {
  events: {},

  subscribe: function(eventName, fn) {
    // add event to this.events if it doesn't already exist
    this.events[eventName] = this.events[eventName] || [];
    // add listener to event
    const index = this.events[eventName].push(fn) - 1;

    // return method to remove the listener
    return {
      remove: () => {
        delete this.events[eventName][index];
      }
    };
  },

  publish: function(eventName, data) {
    if (this.events[eventName]) {
      // call each listener in the event array
      this.events[eventName].forEach(fn => fn(data));
    }
  }
};

export default pubsub;
