import React from 'react';
import {
  Toast,
  SearchDropdown,
  PhoneEmailField,
  CustomInput
} from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';

const styles = {
  deleteIcon: { position: 'absolute', top: 10, right: 10 }
};
class EditPersonModal extends React.Component {
  state = {
    name: '',
    company: null,
    companyName: '',
    jobTitle: '',
    emails: [],
    companies: [],
    phoneNumbers: []
  };

  componentDidMount() {
    this.getCompanies();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();

    http()
      .put('/people/' + this.props.person.id, {
        name: this.state.name,
        companyId: this.state.company.id,
        jobTitle: this.state.jobTitle,
        phoneNumbers: this.state.phoneNumbers,
        emails: this.state.emails,
        inactive: this.state.inactive
      })
      .then(() => {
        Toast.show('Person updated.');
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  getCompanies = () => {
    http()
      .get('/companies')
      .then(res => {
        this.setState({ companies: res });
      })
      .catch(err => Toast.show(err.message));
  };

  setPerson = () => {
    const personCompany = this.state.companies.find(
      company => company.id === this.props.person.companyId
    );

    this.setState({
      name: this.props.person.name,
      company: personCompany,
      jobTitle: this.props.person.jobTitle,
      phoneNumbers: this.props.person.phoneNumbers,
      emails: this.props.person.emails,
      inactive: this.props.person.inactive
    });
  };

  archive = () => {
    http()
      .delete('/people/' + this.props.person.id)
      .then(() => {
        Toast.show('Person removed.');
        this.props.refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { open, onClose } = this.props;
    const { name, companies, company, jobTitle } = this.state;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        TransitionProps={{ onEnter: this.setPerson }}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <form autoComplete="off" onSubmit={this.save}>
          {this.props.user.hasPermission('super_admin') && (
            <div style={styles.deleteIcon}>
              {this.state.showDeleteConfirm ? (
                <Typography>
                  Are you sure?{' '}
                  <Button color="primary" size="small" onClick={this.archive}>
                    Delete
                  </Button>
                </Typography>
              ) : (
                <IconButton
                  onClick={() => this.setState({ showDeleteConfirm: true })}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          )}

          <DialogTitle>Edit Person</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Name"
              fullWidth
              value={name}
              name="name"
              margin="normal"
              onChange={this.handleChange}
              required
            />
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Company"
              items={companies}
              onChange={company => this.setState({ company })}
              value={company}
              itemToString={item => item.name}
              required
            />
            <TextField
              label="Job Title"
              fullWidth
              value={jobTitle}
              name="jobTitle"
              margin="normal"
              onChange={this.handleChange}
              required
            />

            <PhoneEmailField
              value={this.state.phoneNumbers}
              onChange={phoneNumbers => this.setState({ phoneNumbers })}
              ext
              phone
            />

            <PhoneEmailField
              value={this.state.emails}
              onChange={emails => this.setState({ emails })}
              email
            />

            <CustomInput
              label="Person Inactive"
              type="boolean"
              value={this.state.inactive}
              onChange={inactive => this.setState({ inactive })}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connect(state => ({ user: state.user }))(EditPersonModal);
