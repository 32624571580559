import React from 'react';
import { Toast } from 'components';
import styled from 'styled-components';
import {
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Switch
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import http from 'helpers/http.helper';

const Logo = styled.img`
  width: 280px;
  max-width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 15px;
`;

const terminationReasons = [
  'Employee provided System Tech with voluntary notice of departure.',
  'Employee failed to show up to work without prior notice or communication. (Handbook Page 15)'
];

class TerminateUserModal extends React.Component {
  state = {
    employmentLog: null,
    terminationReason: '',
    startDate: null,
    isEligibleToRehire: false,
    loading: false,
    notes: ''
  };

  archive = e => {
    e.preventDefault();
    this.setState({ loading: true });

    http()
      .post(`/users/${this.props.user.id}/employment-logs`, {
        employmentStatus: 'Inactive',
        startDate: this.state.startDate,
        wage: 0.0,
        terminationReason: this.state.terminationReason,
        isEligibleToRehire: this.state.isEligibleToRehire,
        notes: this.state.notes || null
      })
      .then(res => {
        Toast.show('Termination request submitted.');
        this.props.setEmploymentLogs(res);
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message))
      .finally(() => this.setState({ loading: false }));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    const { terminationReason } = this.state;
    return (
      <form
        autoComplete="off"
        onSubmit={this.archive}
        style={{ padding: '16px' }}
      >
        <Logo
          src="https://storage.googleapis.com/steps-files/assets/logo-with-subtitle.png"
          alt="STEPS Logo"
        />
        <DialogTitle>Termination form for {this.props.user.name}</DialogTitle>
        <DialogContent style={{ padding: 10 }}>
          <FormControl component="fieldset">
            <FormGroup>
              {terminationReasons.map(reason => (
                <FormControlLabel
                  key={reason}
                  label={reason}
                  control={
                    <Checkbox
                      checked={terminationReason === reason}
                      onClick={this.handleChange}
                      name="terminationReason"
                      value={reason}
                    />
                  }
                />
              ))}
              <FormControlLabel
                label="Employee was terminated for another reason."
                control={
                  <Checkbox
                    checked={Boolean(
                      terminationReason &&
                        !terminationReasons.includes(terminationReason)
                    )}
                    onClick={() => this.setState({ terminationReason: ' ' })}
                  />
                }
              />
            </FormGroup>
          </FormControl>

          {terminationReason &&
            !terminationReasons.includes(terminationReason) && (
              <TextField
                fullWidth
                label="Other Reason"
                placeholder="Type other reason here..."
                multiline
                maxRows="5"
                name="terminationReason"
                value={terminationReason}
                onChange={this.handleChange}
              />
            )}

          <KeyboardDatePicker
            format="MM/DD/YYYY"
            autoOk
            name="startDate"
            label="Date of Termination"
            required
            margin="normal"
            value={this.state.startDate}
            onChange={startDate => this.setState({ startDate })}
          />

          <FormControl style={{ marginTop: 20 }}>
            <FormGroup>
              <FormControlLabel
                label="The Employee will be eligible for rehire"
                labelPlacement="end"
                control={
                  <Switch
                    color="primary"
                    name="isEligibleToRehire"
                    checked={this.state.isEligibleToRehire}
                    onChange={this.handleCheckboxChange}
                    value={this.state.isEligibleToRehire}
                  />
                }
              />
            </FormGroup>
          </FormControl>

          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            label="Additional Notes"
            multiline
            maxRows="5"
            name="notes"
            value={this.state.notes}
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={this.state.loading}
            type="submit"
          >
            Terminate Employee
          </Button>
        </DialogActions>
      </form>
    );
  }
}

export default TerminateUserModal;
