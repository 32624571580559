import React, { useState, useEffect } from 'react';
import {
  Popover,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  InputLabel,
  Input,
  Typography
} from 'helpers/themeSafeMui.helper';
import { CustomInput } from 'components';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

const TrainingUserFilter = ({
  ButtonProps,
  filteredUsers,
  setFilteredUsers,
  users,
  localStoragePrefix = ''
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [allTitles, setAllTitles] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [
    selectedTitles,
    setSelectedTitles
  ] = useLocalStorage(
    localStoragePrefix + 'users.master_training_table.filter.selected_titles',
    [],
    joi => joi.array().items(joi.string())
  );
  const [selectedAreas, setSelectedAreas] = useLocalStorage(
    localStoragePrefix + 'users.master_training_table.filter.selected_areas',
    [],
    joi => joi.array().items(joi.string())
  );
  const [nameText, setNameNext] = useLocalStorage(
    localStoragePrefix + 'users.master_training_table.filter.name_text',
    '',
    joi => joi.string().allow('')
  );

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleTitlesChange = e => setSelectedTitles(e.target.value);
  const handleAreasChange = e => setSelectedAreas(e.target.value);

  useEffect(() => {
    if (!users) return;
    const allTitles = users
      .map(user => user.jobTitle)
      .filter((title, idx, arr) => arr.indexOf(title) === idx);
    const allAreas = users
      .map(user => user.area)
      .filter((area, idx, arr) => area && arr.indexOf(area) === idx);

    setAllTitles(allTitles);
    setAllAreas(allAreas);

    if (allTitles.length)
      setSelectedTitles(old => old.filter(title => allTitles.includes(title)));
    if (allAreas.length)
      setSelectedAreas(old => old.filter(area => allAreas.includes(area)));
  }, [users, setSelectedTitles, setSelectedAreas]);

  useEffect(() => {
    if (!users) return;
    setFilteredUsers(
      users
        .filter(user =>
          selectedTitles.length === 0
            ? true
            : selectedTitles.includes(user.jobTitle)
        )
        .filter(user =>
          selectedAreas.length === 0 ? true : selectedAreas.includes(user.area)
        )
        .filter(user =>
          nameText === ''
            ? true
            : user.name.toLowerCase().indexOf(nameText.toLowerCase()) !== -1
        )
    );
  }, [nameText, selectedTitles, selectedAreas, setFilteredUsers, users]);

  const activeFilters =
    !(nameText === '') +
    !(selectedTitles.length === 0) +
    !(selectedAreas.length === 0);

  return (
    <>
      <Button variant="contained" {...ButtonProps} onClick={handleClick}>
        Filter Users {!!activeFilters && `(${filteredUsers.length})`}
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ width: 300, padding: 10 }}>
          <Typography variant="h6">Filter Users</Typography>
          <CustomInput label="Name" value={nameText} onChange={setNameNext} />
          <FormControl fullWidth>
            <InputLabel id="title-select-label">Job Titles</InputLabel>
            <Select
              labelId="title-select-label"
              multiple
              value={selectedTitles}
              onChange={handleTitlesChange}
              input={<Input />}
              MenuProps={MenuProps}
              renderValue={selected => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map(value => (
                    <Chip style={{ margin: 3 }} key={value} label={value} />
                  ))}
                </div>
              )}
            >
              {allTitles.map(title => (
                <MenuItem key={title} value={title}>
                  <Checkbox checked={selectedTitles.indexOf(title) > -1} />
                  <ListItemText primary={title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ padding: 5 }} />
          <FormControl fullWidth>
            <InputLabel id="area-select-label">Areas</InputLabel>
            <Select
              labelId="area-select-label"
              multiple
              value={selectedAreas}
              onChange={handleAreasChange}
              input={<Input />}
              MenuProps={MenuProps}
              renderValue={selected => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map(value => (
                    <Chip style={{ margin: 3 }} key={value} label={value} />
                  ))}
                </div>
              )}
            >
              {allAreas.map(area => (
                <MenuItem key={area} value={area}>
                  <Checkbox checked={selectedAreas.indexOf(area) > -1} />
                  <ListItemText primary={area} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ paddingTop: 10 }}>
            <Button
              disabled={
                nameText === '' &&
                selectedTitles.length === 0 &&
                selectedAreas.length === 0
              }
              onClick={() => {
                setNameNext('');
                setSelectedTitles([]);
                setSelectedAreas([]);
              }}
              fullWidth
              variant="contained"
              size="small"
            >
              Clear Filters
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default TrainingUserFilter;
