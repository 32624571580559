const fz = Object.freeze;
const HiddenColumns = fz({
  ALL: fz([
    'projectCount',
    'manufacturer',
    'model',
    'partNumber',
    'conflictReplaceWith',
    'materialId',
    'description',
    'quantity',
    'updateQuantity',
    'unitCost',
    'unitLabor',
    'specSheet',
    'OM'
  ]),
  ProjectMaterials: fz(['conflictReplaceWith', 'materialId', 'unitCost']),
  Master: fz(['conflictReplaceWith', 'quantity', 'materialId']),
  ConflictList: fz(['quantity', 'materialId']),
  PO: fz(['conflictReplaceWith']),
  CreatePO: fz([
    'conflictReplaceWith',
    'materialId',
    'description',
    'unitLabor',
    'specSheet',
    'OM'
  ]),
  StatusUpdates: fz([
    'conflictReplaceWith',
    'materialId',
    'description',
    'unitLabor',
    'specSheet',
    'OM',
    'unitCost'
  ])
});
export default HiddenColumns;
