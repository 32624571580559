import CanvasUtils from './CanvasUtils.canvas';

class LabelColumn extends CanvasUtils {
  constructor({ ctx, tasks, printView, rowHeight, dpr }) {
    super(ctx);
    if (dpr) this.dpr = dpr;
    this.tasks = tasks;
    this.printView = printView;
    this.rowHeight = rowHeight;
    this.padding = { x: 5 * this.dpr, y: 4 * this.dpr };
    this.visibleTasks = printView.taskIds.map(id =>
      tasks.find(task => task.id === id)
    );
  }

  measure() {
    this.ctx.save();
    this.fontSize(1);
    const maxTaskWidth = this.visibleTasks.reduce((maxSize, task) => {
      const textWidth = this.ctx.measureText(task.taskName)
        .actualBoundingBoxRight;
      return textWidth > maxSize ? textWidth : maxSize;
    }, 0);

    const maxLabelWidth = this.printView.labels.reduce((maxSize, label) => {
      const textWidth = this.ctx.measureText(label.name).actualBoundingBoxRight;
      return textWidth > maxSize ? textWidth : maxSize;
    }, 0);
    this.ctx.restore();

    let totalHeight = 0;
    totalHeight += this.rowHeight * 3; // week headers
    totalHeight += this.rowHeight * this.visibleTasks.length;
    totalHeight += this.rowHeight * this.printView.labels.length;
    totalHeight += this.rowHeight; // totals row

    this.size = {
      x:
        (maxTaskWidth > maxLabelWidth ? maxTaskWidth : maxLabelWidth) +
        this.padding.x * 2,
      y: totalHeight
    };
    return this.size;
  }

  print() {
    const { visibleTasks, rowHeight, size, padding, printView } = this;
    const lightBox = { color: '#cccccc', fill: '#ffffff' };

    for (let i = 0; i < 3; i++) {
      const y = i * rowHeight;
      this.box(0, y, size.x, rowHeight, lightBox);
    }

    let top = rowHeight * 3;
    visibleTasks.forEach((task, idx) => {
      const y = top + idx * rowHeight;
      this.box(0, y, size.x, rowHeight, lightBox);
      this.text(task.taskName, padding.x, y + padding.y);
    });

    top = rowHeight * 3 + rowHeight * visibleTasks.length;
    printView.labels.forEach((label, idx) => {
      const y = top + idx * rowHeight;
      this.box(0, y, size.x, rowHeight, {
        ...lightBox,
        fill: '#' + label.color
      });
      this.text(label.name, padding.x, y + padding.y);
    });

    top =
      rowHeight * 3 +
      (visibleTasks.length + printView.labels.length) * rowHeight;
    this.box(0, top, size.x, rowHeight, lightBox);

    // Dark outline
    this.box(
      0,
      rowHeight * 3,
      size.x,
      (visibleTasks.length + printView.labels.length) * rowHeight
    );
  }
}

export default LabelColumn;
