import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import http from 'helpers/http.helper';
import { DashboardWrapper, Toast, HeaderItem } from 'components';
import {
  Typography,
  Grid,
  Paper,
  Button,
  DialogActions
} from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import PersonPhoneNumbers from '../components/PersonPhoneNumbers.component';
import PersonEmails from '../components/PersonEmails.component';
import PersonProjects from '../components/PersonProjects.component';
import EditPersonModal from '../modals/EditPerson.modal';
import MergePeopleModal from '../modals/MergePeople.modal';

const styles = {
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  header: { padding: 16, marginBottom: '16px' }
};

function Person(props) {
  const { match, showModal, user } = props;
  const personId = match.params.id;
  const [editPersonModal, setEditPersonModal] = useState(false);
  const [person, setPerson] = useState(null);
  const [projects, setProjects] = useState([]);
  const [mergeOpen, setMergeOpen] = useState(false);

  const getPerson = useCallback(() => {
    http()
      .get(`/people/${personId}`)
      .then(res => setPerson(res))
      .catch(err => Toast.show(err.message));
  }, [personId]);

  const getProjects = useCallback(() => {
    http()
      .get(`/people/${personId}/projects`)
      .then(res => setProjects(res))
      .catch(err => Toast.show(err.message));
  }, [personId]);

  useEffect(() => {
    getPerson();
    getProjects();
  }, [getPerson, getProjects]);

  const openCreateProjectModal = () => {
    showModal({
      type: 'CREATE_PROJECT',
      props: {
        companyId: person.companyId,
        personId: person.id,
        onCreate: getProjects
      }
    });
  };

  const tier1_5 = user.hasPermission('projects_tier_1.5');
  const tier2 = user.hasPermission('projects_tier_2');

  if (!person) return <DashboardWrapper />;
  return (
    <DashboardWrapper>
      <EditPersonModal
        open={editPersonModal}
        refresh={getPerson}
        onClose={() => setEditPersonModal(false)}
        person={person}
      />
      <MergePeopleModal
        onClose={() => setMergeOpen(false)}
        open={mergeOpen}
        personId={person && person.id}
        onMerge={() => {
          getPerson();
          getProjects();
        }}
      />

      <Paper style={styles.header}>
        <div style={styles.headerTitle}>
          <Typography variant="h5">
            {person.name}
            <EditIcon
              style={{ marginLeft: 8, cursor: 'pointer' }}
              size="inherit"
              color="action"
              fontSize="small"
              onClick={() => setEditPersonModal(true)}
            />
          </Typography>
          {(tier1_5 || tier2) && (
            <div>
              {tier2 && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setMergeOpen(true)}
                  style={{ marginRight: 10 }}
                >
                  Merge Contacts
                </Button>
              )}
              {tier1_5 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={openCreateProjectModal}
                >
                  + project
                </Button>
              )}
            </div>
          )}
        </div>

        <div>
          <HeaderItem
            Icon={BusinessIcon}
            internalUrl={`/companies/${person.companyId}`}
            label={person.companyName}
          />
          <HeaderItem Icon={CardTravelIcon} label={person.jobTitle} />
          <DialogActions></DialogActions>
        </div>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PersonPhoneNumbers
            person={person}
            openModal={() => setEditPersonModal(true)}
          />
          <div style={{ height: 16 }} />
          <PersonEmails
            person={person}
            openModal={() => setEditPersonModal(true)}
          />
        </Grid>
        <Grid item xs={6}>
          <PersonProjects projects={projects} personId={person.Id} />
        </Grid>
      </Grid>
    </DashboardWrapper>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(Person);
