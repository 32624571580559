import React from 'react';

export default class BoxResize extends React.Component {
  state = {
    pivot: { x: 0, y: 0 },
    wrapperHeight: 0,
    wrapperWidth: 0
  };

  componentDidMount() {
    document.addEventListener('mouseup', this.onDone);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onDone);
  }

  onDone = e => {
    const { onDone, active } = this.props;
    if (!active) return;

    onDone(this.updateBox(e));
  };

  setPivot = e => {
    this.setState({
      pivot: { x: e.clientX, y: e.clientY },
      wrapperWidth: e.currentTarget.offsetWidth,
      wrapperHeight: e.currentTarget.offsetHeight
    });
  };

  updateBox = e => {
    const { box, active, onUpdate } = this.props;
    if (!active) return;

    let height = box.height + (e.clientY - this.state.pivot.y);
    let width = box.width + (e.clientX - this.state.pivot.x);

    if (height < 15) height = 15;
    if (width < 15) width = 15;

    const maxHeight = this.state.wrapperHeight - box.y;
    const maxWidth = this.state.wrapperWidth - box.x;

    if (height > maxHeight) height = maxHeight;
    if (width > maxWidth) width = maxWidth;

    const updatedBox = {
      ...box,
      height: height,
      width: width
    };

    onUpdate(updatedBox);
    return updatedBox;
  };

  render() {
    return (
      <div onMouseMove={this.updateBox} onMouseDown={this.setPivot}>
        {this.props.children}
      </div>
    );
  }
}
