import React, { useContext } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import { CompanyContext } from '../context/Company.context';

export default function IsSupplierWarning() {
  const colors = useColors();
  const { company } = useContext(CompanyContext);

  if (!company.isSupplier) return null;

  return (
    <div
      style={{
        backgroundColor: colors.lightYellow,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Typography variant="overline" noWrap>
        {company.name} is a supplier company
      </Typography>
    </div>
  );
}
