import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import http from 'helpers/http.helper';
import {
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel
} from 'helpers/themeSafeMui.helper';
import { setUser } from 'ducks/user.duck';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MultiSearchDropdown, Toast, AuthPopup, CustomInput } from 'components';
import TitleAutoFill from './components/TitleAutoFill.component';
import moment from 'moment';
import { useSetActionItem } from 'state/actionItems.state';

const durations = [
  { label: 'Not Applicable', value: 0 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 }
];

function CreateActionItem(props) {
  const {
    actionItemLink,
    projectId,
    refresh,
    onClose,
    currentUser,
    assignedFor,
    presetValues = {}
  } = props;
  const setActionItem = useSetActionItem();
  const [connectedToCalendar, setConnectedToCalendar] = useState(true);
  const [title, setTitle] = useState(presetValues.title || '');
  const [notes, setNotes] = useState(presetValues.notes || '');
  const [assignedUsers, setAssignedUsers] = useState(
    presetValues.assignedUsers || []
  );
  const [duration, setDuration] = useState(presetValues.duration || 0);
  const [dueDate, setDueDate] = useState(presetValues.dueDate || null);
  const [addToGoogleCalendar, setAddToGoogleCalender] = useState(false);
  const [project, setProject] = useState(null);
  const [users, setUsers] = useState([]);
  const [hrUsers, setHrUsers] = useState([]);
  const [finalInvoice, setFinalInvoice] = useState(null);

  useEffect(() => {
    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(err => Toast.show(err.message));

    http()
      .get('/human-resources')
      .then(res => setHrUsers(res))
      .catch(err => Toast.show(err.message));

    if (currentUser.isInternal) {
      http()
        .get('/google/calendars/check-connection-status')
        .then(res => {
          if (res) {
            setConnectedToCalendar(true);
          } else {
            setConnectedToCalendar(false);
          }
        })
        .catch(err => Toast.show(err.message));
    } else {
      setConnectedToCalendar(false);
    }
  }, [currentUser.isInternal]);

  useEffect(() => {
    if (projectId) {
      http()
        .get(`/projects/${projectId}`)
        .then(res => setProject(res))
        .catch(err => Toast.show(err.message));
    }
  }, [projectId]);

  useEffect(() => {
    if (title.includes('Create Invoice')) {
      setFinalInvoice(current => (current === null ? false : current));
    } else {
      setFinalInvoice(null);
    }
  }, [title]);

  const addHrUsers = () => {
    if (!users || !hrUsers) return;
    let usersToAdd = [];
    hrUsers.forEach(hrUser => {
      const fullDetailUser = users.find(user => user.id === hrUser.userId);
      usersToAdd.push(fullDetailUser);
    });
    usersToAdd = usersToAdd.filter(
      // remove every hr person who is already assigned
      user => !assignedUsers.find(otherUser => otherUser.id === user.id)
    );
    setAssignedUsers([...assignedUsers, ...usersToAdd]);
  };

  const moveToCloseout = () => {
    const closeout = props.statuses.find(status =>
      status.status.match(/closeout/i)
    );
    if (!closeout || !projectId) return;
    return http()
      .put(`/projects/${projectId}`, { statusId: closeout.id })
      .then(() => Toast.show('Moved to Closeout'))
      .catch(Toast.showErr);
  };

  const save = async e => {
    e.preventDefault();

    const assignedUserIds = assignedUsers.map(user => user.id);

    http()
      .post('/action-items', {
        projectId: projectId,
        title: title,
        notes: notes,
        dueDate: dueDate,
        duration: duration,
        actionItemLink: actionItemLink,
        assignedUserIds: assignedUserIds,
        assignedFor: assignedFor,
        addToGoogleCalendar: addToGoogleCalendar
      })
      .then(async actionItem => {
        setActionItem(actionItem);
        if (finalInvoice) await moveToCloseout();
        if (typeof refresh === 'function') refresh();

        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  const connectToCalendar = googleAuthCode => {
    http()
      .post('/users/auth/sign-in', { googleAuthCode })
      .then(() => {
        setConnectedToCalendar(true);
        setAddToGoogleCalender(true);
      })
      .catch(err => Toast.show(err.message));
  };

  const handleAutofill = title => {
    if (project) {
      setTitle(`${title}: ${project.projectNumber} - ${project.name}`);
    } else if (assignedFor && !project) {
      setTitle(`${title}`);
    } else {
      setTitle(`${title} - no project`);
    }
  };

  const setDueDateToNow = () => {
    setDueDate(moment());
  };

  return (
    <>
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>Create Action Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new Action Item, enter a title, select the assigned people,
            duration of the item, and the due date.
          </DialogContentText>
          <TitleAutoFill
            autofill={handleAutofill}
            sideEffect={{
              'Create Invoice': [addHrUsers, setDueDateToNow]
            }}
          />
          <TextField
            autoFocus
            label="Action Item Title"
            fullWidth
            required
            value={title}
            onChange={e => setTitle(e.target.value)}
            margin="normal"
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            <MultiSearchDropdown
              items={users}
              onChange={assignedUsers => setAssignedUsers(assignedUsers)}
              selected={assignedUsers}
              itemToString={item => item.name}
              required
              TextFieldProps={{
                label: 'Assigned To',
                fullWidth: true,
                margin: 'normal'
              }}
              rootStyles={{
                width: 'calc(100% - 78px)',
                display: 'inline-block'
              }}
            />

            <Button
              style={{ marginBottom: 8 }}
              onClick={addHrUsers}
              color="primary"
              variant="contained"
            >
              HR
            </Button>
          </div>

          {finalInvoice !== null && projectId && (
            <CustomInput
              type="boolean"
              label="Final Invoice"
              value={finalInvoice}
              onChange={val => {
                if (val) setNotes(notes => 'Final Invoice. ' + notes);
                else setNotes(notes => notes.replace(/Final Invoice\. /, ''));
                setFinalInvoice(val);
              }}
            />
          )}

          <TextField
            label="Notes"
            multiline
            maxRows="4"
            fullWidth
            value={notes}
            onChange={e => setNotes(e.target.value)}
            margin="normal"
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                format="MM/DD/YYYY hh:mm a"
                autoOk
                fullWidth
                label="Due Date"
                value={dueDate}
                onChange={date => setDueDate(date)}
                required
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl required fullWidth margin="normal">
                <InputLabel>Duration</InputLabel>
                <Select
                  value={duration}
                  onChange={e => setDuration(e.target.value)}
                >
                  {durations.map(item => (
                    <MenuItem key={item.label} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ marginTop: 16 }}>
            {connectedToCalendar ? (
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    label="Add to Google Calendar"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={addToGoogleCalendar}
                        onChange={e => setAddToGoogleCalender(e.target.checked)}
                        value={addToGoogleCalendar}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            ) : (
              <AuthPopup
                onCode={connectToCalendar}
                forceConsent
                scopes={['https://www.googleapis.com/auth/calendar']}
              >
                <Button variant="text" color="secondary">
                  Add to Google Calendar
                </Button>
              </AuthPopup>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default connect(
  state => ({ statuses: state.projectStatuses, currentUser: state.user }),
  { setUser }
)(CreateActionItem);
