import React, { useState, useContext } from 'react';
import {
  ListItemText,
  ListItem,
  ListItemIcon
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectRfisContext
} from 'routes/Projects/context/Project.context';
import { Toast, NoItemsResult } from 'components';
import AddIcon from '@material-ui/icons/Add';
import Rfi from './StepsRfi.component';
import { useUsers } from 'hooks/useUsers.hook';
import RfiModal from '../modals/Rfi.modal';

const Rfis = ({ hideCreateButton = false }) => {
  const { project } = useContext(ProjectContext);
  const { projectRfis, setProjectRfis } = useContext(ProjectRfisContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { users } = useUsers();

  const createRfi = async data => {
    try {
      const copy = { ...data };
      if (!copy.dueDate) delete copy.dueDate;
      const newRfi = await http().post(`/projects/${project.id}/rfis`, copy);
      setProjectRfis(old => [newRfi, ...old]);
      setCreateModalOpen(false);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const setRfi = id => updatedRfi =>
    setProjectRfis(old => old.map(rfi => (rfi.id === id ? updatedRfi : rfi)));

  return (
    <>
      <RfiModal
        formTitle="Create RFI"
        users={users}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={createRfi}
      />
      <div style={{ padding: 16, paddingTop: 0 }}>
        {!hideCreateButton && (
          <ListItem button onClick={() => setCreateModalOpen(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: 'button' }}
              primary="Create RFI"
            />
          </ListItem>
        )}
        {projectRfis.map(rfi => (
          <Rfi key={rfi.id} rfi={rfi} setRfi={setRfi(rfi.id)} users={users} />
        ))}
        {!projectRfis.length && <NoItemsResult type="RFIs" />}
      </div>
    </>
  );
};

export default Rfis;
