import React, { useState } from 'react';
import { ListItem, ListItemText } from 'helpers/themeSafeMui.helper';
import ZoomablePdf from './ZoomablePdf.component';
import { PhotoModal } from 'components';

const PlangridFile = ({ type, url, title }) => {
  const [open, setOpen] = useState(false);
  const maxHeight = 150;
  if (type === 'image')
    return (
      <>
        <PhotoModal url={url} open={open} onClose={() => setOpen(false)} />
        <ListItem button onClick={() => setOpen(true)}>
          <div>
            <ListItemText primary={'File Name: ' + title} />
            <img
              alt="Plangrid"
              src={url}
              style={{
                cursor: 'pointer',
                width: '100%',
                objectFit: 'contain',
                maxHeight
              }}
            />
          </div>
        </ListItem>
      </>
    );
  if (type === 'attachment')
    return (
      <>
        <ZoomablePdf url={url} open={open} onClose={() => setOpen(false)} />
        <ListItem button onClick={() => setOpen(true)}>
          <div>
            <ListItemText primary={'File Name: ' + title} />
          </div>
        </ListItem>
      </>
    );
};

export default PlangridFile;
