import { useColors } from 'helpers/theme.helper';
import { Typography } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import React, { useEffect } from 'react';

/**
 * getTotal(date
 * row
 */

const DateGrid = ({
  firstDay,
  lastDay,
  filterDayOfWeek,
  rows,
  colWidth = 50,
  rowHeight = 30,
  getDayTotal,
  getDayTextContent,
  getDayColor,
  cellStyle,
  getCellStyle = () => ({}),
  getRowId = row => row.id,
  isStartOfWeek = dayMoment => dayMoment.day() === 1,
  stickyHeader = false,
  setTodayXpos = false
}) => {
  const colors = useColors();
  firstDay = moment(firstDay).startOf('day');
  lastDay = moment(lastDay).startOf('day');
  const numDays = moment(lastDay).diff(firstDay, 'days') + 1;
  let columnMoments = [];
  for (let i = 0; i < numDays; i++) {
    columnMoments.push(moment(firstDay).add(i, 'days'));
  }

  if (filterDayOfWeek)
    columnMoments = columnMoments.map(m => moment(m)).filter(filterDayOfWeek);

  const todayIdx =
    columnMoments
      .map((m, idx) => (m.isSame(moment(), 'day') ? idx : null))
      .filter(d => d !== null)[0] || null;

  const borderStyle = {
    border: `1px solid ${colors.grey}`,
    borderWidth: '1px 0 0 1px'
  };
  const heavyBoarder = (side, active = true) =>
    active
      ? {
          [`border${side}`]: `2px solid #000`
        }
      : {};
  const headerHeight = rowHeight * (!!getDayTotal ? 3 : 2);

  useEffect(() => {
    if (setTodayXpos && todayIdx) setTodayXpos(todayIdx * colWidth);
  }, [todayIdx, setTodayXpos, colWidth]);

  return (
    <div
      style={{
        display: 'flex',
        ...heavyBoarder('Bottom'),
        ...heavyBoarder('Right'),
        width: columnMoments.length * colWidth,
        height: headerHeight + rows.length * rowHeight
      }}
    >
      {/* COLUMNS */}
      {columnMoments.map((dayMoment, dayIdx) => (
        <div key={dayMoment.format('L')}>
          <div
            style={
              !stickyHeader
                ? {}
                : { position: 'sticky', top: 0, backgroundColor: colors.paper }
            }
          >
            {/* DAY OF WEEK HEADER */}
            <Typography
              component="div"
              style={{
                width: colWidth,
                height: rowHeight,
                ...cellStyle,
                ...getCellStyle(moment(dayMoment)),
                ...borderStyle,
                ...heavyBoarder('Top'),
                ...heavyBoarder('Left', isStartOfWeek(dayMoment))
              }}
              variant="caption"
              align="center"
            >
              {dayMoment.format('ddd')}
            </Typography>

            {/* DATE HEADER */}
            <Typography
              component="div"
              style={{
                padding: '4px 0',
                width: colWidth,
                height: rowHeight,
                ...cellStyle,
                ...getCellStyle(moment(dayMoment)),
                ...borderStyle,
                ...heavyBoarder('Left', isStartOfWeek(dayMoment))
              }}
              variant="caption"
              align="center"
            >
              {dayMoment.format('M/D')}
            </Typography>

            {/* DAY TOTAL */}
            {!!getDayTotal && (
              <div
                style={{
                  width: colWidth,
                  height: rowHeight,
                  ...cellStyle,
                  ...getCellStyle(moment(dayMoment)),
                  ...borderStyle,
                  ...heavyBoarder('Left', isStartOfWeek(dayMoment))
                }}
              >
                {getDayTotal(moment(dayMoment))}
              </div>
            )}
          </div>

          {/* CELLS */}
          {rows.map((row, idx) => (
            <div
              style={{
                width: colWidth,
                height: rowHeight,
                backgroundColor: getDayColor
                  ? getDayColor(row, moment(dayMoment))
                  : null,
                ...borderStyle,
                ...heavyBoarder('Top', idx === 0),
                ...heavyBoarder('Left', isStartOfWeek(dayMoment)),
                ...cellStyle,
                ...getCellStyle(moment(dayMoment))
              }}
              key={getRowId(row)}
            >
              {getDayTextContent
                ? getDayTextContent(row, moment(dayMoment))
                : ''}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DateGrid;
