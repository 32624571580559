import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import {
  CompanyContext,
  CompanyProjectsContext,
  CompanyPeopleContext
} from '../context/Company.context';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import { CopyText, HeaderItem } from 'components';

import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationIcon from '@material-ui/icons/LocationOn';
import ComputerIcon from '@material-ui/icons/Computer';
import phoneHelper from 'helpers/phone.helper';
import EditCompanyModal from '../modals/EditCompany.modal';

const styles = {
  container: { padding: 24 },
  headerTitle: { display: 'flex', justifyContent: 'space-between' }
};

function CompanyHeader(props) {
  const { showModal, user } = props;
  const { company, setCompany } = useContext(CompanyContext);
  const { companyProjects, setCompanyProjects } = useContext(
    CompanyProjectsContext
  );
  const { companyPeople, setCompanyPeople } = useContext(CompanyPeopleContext);
  const [showingEditModal, setShowingEditModal] = useState(false);

  const openCreateProjectModal = () => {
    showModal({
      type: 'CREATE_PROJECT',
      props: {
        companyId: company.id,
        onCreate: res => setCompanyProjects(companyProjects.concat(res))
      }
    });
  };

  const openCreatePersonModal = () => {
    showModal({
      type: 'CREATE_PERSON',
      props: {
        companyProp: company,
        onCreate: res => setCompanyPeople(companyPeople.concat(res))
      }
    });
  };

  const openEditCompanyModal = () => {
    setShowingEditModal(true);
  };

  return (
    <div style={styles.container}>
      <EditCompanyModal
        company={company}
        onCreate={setCompany}
        open={showingEditModal}
        onClose={() => setShowingEditModal(false)}
      />

      <div style={styles.headerTitle}>
        <Typography variant="h5">
          {company.name}
          <EditIcon
            style={{ marginLeft: 8, cursor: 'pointer' }}
            size="inherit"
            color="action"
            fontSize="small"
            onClick={openEditCompanyModal}
          />
        </Typography>

        <div>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={openCreatePersonModal}
          >
            + person
          </Button>
          {user.hasPermission('projects_tier_1.5') && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={openCreateProjectModal}
            >
              + project
            </Button>
          )}
        </div>
      </div>

      <HeaderItem
        Icon={ComputerIcon}
        externalUrl={company.website}
        label={company.website}
      />
      <HeaderItem
        Icon={PhoneIcon}
        externalUrl={`tel:${company.phoneNumber}`}
        label={phoneHelper(company.phoneNumber)}
      />
      <CopyText text={company.billingEmail} type="Email">
        <HeaderItem
          Icon={EmailIcon}
          externalUrl={`mailto:${company.billingEmail}`}
          label={company.billingEmail}
        />
      </CopyText>
      <CopyText text={company.address} type="Company Address">
        <HeaderItem
          Icon={LocationIcon}
          externalUrl={`https://www.google.com/maps/search/?api=1&query=${company.address}`}
          label={company.address}
        />
      </CopyText>
    </div>
  );
}

export default connect(state => ({ user: state.user }), { showModal })(
  CompanyHeader
);
