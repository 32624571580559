import React, { useState } from 'react';
import { Typography, Button, Checkbox } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, ExternalLink } from 'components';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';
import CheckIcon from '@material-ui/icons/CheckCircle';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { useSelector } from 'react-redux';
import TrainingCompletedModal from './TrainingCompleted.modal';
import TrainingQuizButton from './TrainingQuizButton.component';

export default function TrainingModule(props) {
  const { trainingModule, userId, onSave } = props;
  const colors = useColors();
  const [open, setOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [dateCompleted, setDateCompleted] = useState(null);
  const [reviewDateCompleted, setReviewDateCompleted] = useState(null);
  const { userTrainingRecord } = trainingModule;
  const currentUser = useSelector(state => state.user);
  const isAdmin = currentUser.hasPermission('employee_training');
  const isOwnModule = userId === currentUser.id;
  const hasFile = !!(userTrainingRecord && userTrainingRecord.driveFileId);

  const almostOrIsExpired =
    userTrainingRecord &&
    userTrainingRecord.dateCompleted &&
    trainingModule.daysToExpire &&
    moment(userTrainingRecord.dateCompleted)
      .add(trainingModule.daysToExpire - 90, 'days')
      .isBefore(moment());

  const canMarkForReview =
    isOwnModule &&
    !(userTrainingRecord && userTrainingRecord.reviewDateCompleted);

  const handleEnter = () => {
    setFileId((userTrainingRecord || null) && userTrainingRecord.driveFileId);
    setDateCompleted(
      (userTrainingRecord || null) &&
        (userTrainingRecord.reviewDateCompleted
          ? userTrainingRecord.reviewDateCompleted
          : userTrainingRecord.dateCompleted
          ? userTrainingRecord.dateCompleted
          : null)
    );
  };

  const handleReviewEnter = () => {
    setFileId((userTrainingRecord || null) && userTrainingRecord.driveFileId);
    setReviewDateCompleted(
      userTrainingRecord && userTrainingRecord.reviewDateCompleted
        ? userTrainingRecord.reviewDateCompleted
        : moment().startOf('day')
    );
  };

  const save = async () => {
    try {
      if (userTrainingRecord) {
        await http().put(
          `/users/${userId}/trainings/${userTrainingRecord.id}`,
          {
            dateCompleted: dateCompleted,
            driveFileId: fileId
          }
        );
      } else {
        await http().post(`/users/${userId}/trainings`, {
          trainingModuleId: trainingModule.id,
          dateCompleted: dateCompleted,
          driveFileId: fileId
        });
      }

      setOpen(false);
      onSave();
    } catch (e) {
      Toast.show(e.message);
    }
  };

  const markForReview = async () => {
    if (!canMarkForReview) return;
    try {
      await http().put(`/users/${userId}/trainings/review`, {
        trainingModuleId: trainingModule.id,
        reviewDateCompleted,
        driveFileId: fileId
      });

      setReviewOpen(false);
      onSave();
    } catch (e) {
      Toast.show(e.message);
    }
  };

  const toggleOptional = async () => {
    try {
      if (userTrainingRecord) {
        await http().put(
          `/users/${userId}/trainings/${userTrainingRecord.id}`,
          { optional: !userTrainingRecord.optional }
        );
      } else {
        await http().post(`/users/${userId}/trainings`, {
          trainingModuleId: trainingModule.id,
          optional: true
        });
      }
      onSave();
    } catch (e) {
      Toast.show(e.message);
    }
  };

  return (
    <div style={{ marginTop: 8 }}>
      {/* REVIEW DATE COMPLETED MODAL */}
      <TrainingCompletedModal
        userTrainingRecord={userTrainingRecord}
        open={reviewOpen}
        onClose={() => setReviewOpen(false)}
        TransitionProps={{ onEnter: handleReviewEnter }}
        onSave={markForReview}
        date={reviewDateCompleted}
        onSetDate={setReviewDateCompleted}
        fileId={fileId}
        onSetFileId={fileId => setFileId(fileId)}
        userId={userId}
      />

      {/* DATE COMPLETED MODAL */}
      <TrainingCompletedModal
        userTrainingRecord={userTrainingRecord}
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ onEnter: handleEnter }}
        onSave={save}
        date={dateCompleted}
        onSetDate={setDateCompleted}
        fileId={fileId}
        onSetFileId={fileId => setFileId(fileId)}
        userId={userId}
      />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RateReviewIcon
          fontSize="small"
          onClick={() => canMarkForReview && setReviewOpen(true)}
          style={{
            cursor: canMarkForReview ? 'pointer' : null,
            marginRight: 4,
            color:
              userTrainingRecord && userTrainingRecord.reviewDateCompleted
                ? colors.yellow
                : colors.darkGrey
          }}
        />
        <CheckIcon
          fontSize="small"
          onClick={() => isAdmin && setOpen(true)}
          style={{
            cursor: isAdmin ? 'pointer' : null,
            marginRight: 4,
            color:
              userTrainingRecord && userTrainingRecord.dateCompleted
                ? almostOrIsExpired
                  ? colors.error
                  : colors.success
                : colors.darkGrey
          }}
        />
        <Typography variant="h6">{trainingModule.name}</Typography>
      </div>

      <Typography>
        Optional:{' '}
        <Checkbox
          style={{
            width: 0,
            height: 0,
            marginLeft: 3,
            verticalAlign: 'text-top'
          }}
          disabled={!isAdmin}
          checked={!!userTrainingRecord && !!userTrainingRecord.optional}
          onChange={toggleOptional}
        />
      </Typography>

      <Typography>
        Completed:{' '}
        <strong>
          {userTrainingRecord && userTrainingRecord.dateCompleted
            ? moment(userTrainingRecord.dateCompleted).format('LL')
            : 'Not Completed'}
        </strong>
      </Typography>

      <TrainingQuizButton trainingModule={trainingModule} />

      {canMarkForReview && (
        <Button
          variant="contained"
          size="small"
          onClick={() => setReviewOpen(true)}
          style={{ marginBottom: 3 }}
        >
          I have completed this
          {userTrainingRecord && userTrainingRecord.dateCompleted
            ? ' again'
            : ''}
          !
        </Button>
      )}

      {Boolean(
        trainingModule.daysToExpire &&
          userTrainingRecord &&
          userTrainingRecord.dateCompleted
      ) && (
        <Typography>
          Expires:{' '}
          <strong
            style={{
              color: almostOrIsExpired ? colors.error : null
            }}
          >
            {moment(userTrainingRecord.dateCompleted)
              .add(trainingModule.daysToExpire, 'days')
              .format('LL')}
          </strong>
        </Typography>
      )}

      {hasFile && (
        <Typography style={{ color: colors.success }}>
          Certificate file attached
        </Typography>
      )}

      <Typography>
        {trainingModule.courseLink && (
          <ExternalLink href={trainingModule.courseLink}>
            Course Link
          </ExternalLink>
        )}
        {trainingModule.testLink && (
          <span>
            {' || '}
            <ExternalLink href={trainingModule.testLink}>
              Test Link
            </ExternalLink>
          </span>
        )}
        {trainingModule.handoutLink && (
          <span>
            {' || '}
            <ExternalLink href={trainingModule.handoutLink}>
              Handout Link
            </ExternalLink>
          </span>
        )}
      </Typography>
    </div>
  );
}
