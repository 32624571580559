import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from 'helpers/themeSafeMui.helper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import http from 'helpers/http.helper';
import { Toast, CustomInput } from 'components';
import { v4 as uuid } from 'uuid';

const initialState = {
  partNumber: '',
  description: '',
  manufacturer: '',
  quantity: 0,
  unitCost: 0,
  unitLabor: 0,
  specSheet: '',
  OM: ''
};

function AddPhaseMaterial({ open, onClose, onSave }) {
  const [state, setState] = useState(initialState);

  const [typed, setTyped] = useState({
    partNumber: '',
    description: '',
    manufacturer: ''
  });

  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    if (!open) return;
    http()
      .get('/materials')
      .then(res => {
        setMaterials(res);
      })
      .catch(err => Toast.show(err.message));
  }, [open]);

  useEffect(() => {
    setState(initialState);
  }, [open]);

  const makeAutocompleteProps = (replaceAll, field, label) => ({
    options: materials,
    value: state,
    includeInputInList: true,
    onChange: (e, newValue) => {
      if (newValue) {
        // option selected
        const newState = {
          ...state,
          ...(replaceAll ? newValue : { [field]: newValue[field] })
        };
        setState(newState);
      } else {
        // input cleared
        setState({ ...state, [field]: '' });
      }
    },
    getOptionLabel: material => {
      return material[field];
    },
    getOptionSelected: (option, value) =>
      option && value && option[field] === value[field],
    onInputChange: (e, newValue) => {
      setTyped({ ...state, [field]: newValue });
    },
    onClose: e => {
      if (e.type === 'blur') setState({ ...state, [field]: typed[field] });
    },
    renderInput: params => (
      <TextField {...params} fullWidth label={label} margin="normal" />
    )
  });

  const save = e => {
    e.preventDefault();

    const addedMaterial = {
      quantity: state.quantity,
      partNumber: state.partNumber,
      description: state.description,
      manufacturer: state.manufacturer,
      unitCost: state.unitCost,
      unitLabor: state.unitLabor,
      specSheet: state.specSheet || '',
      OM: state.OM || '',
      id: uuid()
    };

    onSave(addedMaterial);
    onClose();
  };

  const seen = {};
  const uniqueManufacturers = materials.filter(material => {
    if (seen[material.manufacturer]) return false;
    seen[material.manufacturer] = true;
    return true;
  });

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form onSubmit={save}>
        <DialogTitle>Add Phase Material</DialogTitle>
        <DialogContent>
          <Autocomplete
            {...makeAutocompleteProps(true, 'description', 'Description')}
          />
          <Autocomplete
            {...makeAutocompleteProps(true, 'partNumber', 'Part Number')}
          />
          <Autocomplete
            {...makeAutocompleteProps(false, 'manufacturer', 'Manufacturer')}
            options={uniqueManufacturers}
          />
          <CustomInput
            required
            type="currency"
            label="Unit Cost"
            value={state.unitCost}
            onChange={val => setState({ ...state, unitCost: val })}
          />
          <CustomInput
            required
            type="number"
            label="Unit Labor"
            value={state.unitLabor}
            onChange={val => setState({ ...state, unitLabor: val })}
          />
          <CustomInput
            required
            type="number"
            label="Quantity"
            value={state.quantity}
            onChange={val => setState({ ...state, quantity: val })}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddPhaseMaterial;
