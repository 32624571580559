import React, { useContext, useEffect, useState } from 'react';
import { MaterialsTable, Toast, CheckBox } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
  Button,
  Grid
} from 'helpers/themeSafeMui.helper';

import useCurrentUser from 'hooks/useCurrentUser.hook';
import http from 'helpers/http.helper';
import { useRouteMatch } from 'react-router';
import PoPdf from '../components/PoPdf.component';
import PoFields from '../components/PoFields.component';
import { StatusesContext } from '../PurchaseOrders.tab';
import safeParse from 'helpers/safeParse.helper';

import { Typography } from 'helpers/themeSafeMui.helper';
import EmailPdfModal from '../modals/SendEmail.modal';

const hiddenColumns = MaterialsTable.presetHiddenColumns.CreatePO;

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});

export default function CreatePoModal({
  open,
  onClose,
  getPos,
  nextPoNumber,
  materials: allMaterials,
  shipToOptions
}) {
  const currentUser = useCurrentUser();

  const defaultData = {
    to: '',
    attn: '',
    poNumber: '',
    quoteNumber: '',
    from: currentUser.name,
    shipping: 'Best Way',
    billTo: 'System Tech Inc, 2854 S Featherly Way, Boise, Idaho, 83709',
    billToContactEmail: 'ap@systemtech.us',
    billToContactPhone: '208 616 1001',
    shipTo: '',
    shipToContactPhone: '',
    notes: ''
  };
  const materialStatuses = useContext(StatusesContext);
  const match = useRouteMatch('/projects/:id');
  const projectId = match && match.params.id;
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materialsWithQuantity, setMaterialsWithQuantity] = useState([]);
  const [phase, setPhase] = useState('select-materials');
  const [poData, setPoData] = useState(defaultData);
  const [doNotEmail, setDoNotEmail] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setPhase('select-materials');
      setSelectedMaterials([]);
    }
  }, [open]);

  useEffect(() => {
    setPoData(old => ({ ...old, poNumber: nextPoNumber }));
  }, [nextPoNumber]);

  useEffect(() => {
    setMaterialsWithQuantity(
      selectedMaterials
        .map(mat => {
          const copy = { ...mat };
          copy.quantity = copy.updateQuantity;
          delete copy.updateQuantity;
          return copy;
        })
        .filter(mat => safeParse(mat.quantity) !== 0)
    );
  }, [selectedMaterials]);

  const createPo = () => {
    if (!projectId) return;
    const copy = { ...poData };

    http()
      .post(`/projects/${projectId}/pos`, {
        projectMaterialIds: materialsWithQuantity.map(pm => pm.id),
        quantities: materialsWithQuantity.map(pm => pm.quantity),
        ...copy
      })
      .then(getPos)
      .then(onClose)
      .then(resetForm)
      .catch(Toast.showErr);
  };

  const finishMaterialSelection = () => {
    if (materialsWithQuantity.length <= 0) {
      setPhase('select-materials');
      setSelectedMaterials([]);
      setMaterialsWithQuantity([]);
      Toast.show('set valid materials');
    } else {
      setPhase('edit-po');
    }
  };

  const prefillQuantity = mat => {
    if (!materialStatuses[mat.materialId]) return safeParse(mat.quantity, 0);
    const ordered = safeParse(materialStatuses[mat.materialId].ordered, 0);
    const remaining = safeParse(mat.quantity, 0) - ordered;
    const val =
      typeof remaining === 'number' && !isNaN(remaining) ? `${remaining}` : '';
    return val;
  };

  const resetForm = () => {
    setDoNotEmail(false);
    setPoData(defaultData);
  };

  return (
    <FullHeightDialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create Purchase Order</DialogTitle>
      <Divider />
      {phase === 'select-materials' && (
        <>
          <DialogContent style={{ padding: 0 }}>
            <MaterialsTable
              highlightUnordered
              localStorageKey="ProjectMaterials"
              initialRowsPerPage={100}
              materials={allMaterials || []}
              removeColumns={hiddenColumns}
              isSelecting
              statuses={materialStatuses}
              isSettingQuantity
              settingQuantityLabel="Order"
              getAutofillUpdateQuantity={prefillQuantity}
              selectedRows={selectedMaterials}
              setSelectedRows={setSelectedMaterials}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={materialsWithQuantity.length <= 0}
              variant="contained"
              color="primary"
              onClick={finishMaterialSelection}
            >
              Confirm Materials
            </Button>
          </DialogActions>
        </>
      )}
      {phase === 'edit-po' && (
        <>
          <DialogContent>
            <Grid style={{ height: '100%' }} container spacing={0}>
              <Grid style={{ height: '100%' }} item xs={12} lg={6}>
                <PoPdf
                  po={poData}
                  items={materialsWithQuantity}
                  debounceDelay={600}
                />
              </Grid>

              <Grid
                style={{ height: '100%', overflowY: 'auto' }}
                item
                xs={12}
                lg={6}
              >
                <div style={{ padding: 8 }}>
                  <PoFields
                    poData={poData}
                    setPoData={setPoData}
                    shipToOptions={shipToOptions}
                  />
                </div>
                <Typography style={{ marginLeft: 5 }}>
                  Online order or transfer
                </Typography>
                <CheckBox
                  checked={doNotEmail}
                  onClick={() => {
                    setDoNotEmail(!doNotEmail);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setPhase('select-materials')}
            >
              Back
            </Button>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!poData.to}
              variant="contained"
              color="primary"
              onClick={() => {
                if (!doNotEmail) setEmailOpen(true);
                else createPo();
              }}
            >
              Create PO
            </Button>
          </DialogActions>
        </>
      )}

      <EmailPdfModal
        open={emailOpen}
        onClose={() => setEmailOpen(false)}
        po={poData}
        projectId={projectId}
        items={materialsWithQuantity}
        onSend={() => {
          getPos();
          setEmailOpen(false);
          onClose();
          resetForm();
        }}
      />
    </FullHeightDialog>
  );
}
