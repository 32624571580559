import { useMediaQuery, useTheme } from 'helpers/themeSafeMui.helper';

export default function useBreakpoint(
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  down = true
) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints[down ? 'down' : 'up'](size));

  return match;
}
