import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled
} from '@material-ui/core';
import { MaterialsTable, Toast, SearchDropdown } from 'components';
import http from 'helpers/http.helper';
import safeParse from 'helpers/safeParse.helper';
import { useContext, useState, useEffect } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';
import PickListModal from './PickList.modal';

const hiddenColumns = MaterialsTable.presetHiddenColumns.StatusUpdates;

const FullHeightDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    height: '100%'
  }
});
export default function DeployMaterialModal({
  open,
  onClose,
  materialStatuses,
  materials,
  onDeploy,
  loading,
  setLoading
}) {
  const { project } = useContext(ProjectContext);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [pickListMaterials, setPickListMaterials] = useState(null);
  const [deployedTo, setDeployedTo] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    http()
      .get(`/users`)
      .then(res => {
        setUsers(res);
      })
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  }, [project.id, setLoading]);

  const prefillQuantity = mat => {
    if (!materialStatuses[mat.materialId]) return safeParse(mat.quantity, 0);
    const ordered = safeParse(materialStatuses[mat.materialId].ordered, 0);
    const val =
      typeof ordered === 'number' && !isNaN(ordered) ? `${ordered}` : '';
    return val;
  };

  const deploy = () => {
    const materialIds = selectedMaterials.map(mat => mat.materialId);
    const quantities = selectedMaterials.map(mat => mat.updateQuantity);
    setLoading(true);
    http()
      .post(`/projects/${project.id}/materials/deployed/batch`, {
        deployedToId: deployedTo?.id,
        materialIds,
        quantities
      })
      .then(onDeploy)
      .catch(Toast.showErr)
      .finally(() => setLoading(false));
  };

  const togglePickList = () => {
    if (!selectedMaterials.length) return;
    if (pickListMaterials && pickListMaterials.length) {
      setPickListMaterials(null);
    } else {
      setPickListMaterials(
        selectedMaterials
          .map(mat => {
            const copy = { ...mat };
            copy.quantity = copy.updateQuantity;
            delete copy.updateQuantity;
            return copy;
          })
          .filter(mat => safeParse(mat.quantity))
      );
    }
  };

  const pickListOpen = !!pickListMaterials && !!pickListMaterials.length;
  const hasSelection = selectedMaterials.some(
    mat => safeParse(mat.updateQuantity, 0) !== 0
  );

  const buttons = (
    <>
      <Button
        disabled={!hasSelection}
        onClick={togglePickList}
        color="primary"
        variant="contained"
      >
        {pickListOpen && 'Close '}Pick List
      </Button>

      <Button
        disabled={loading || !hasSelection}
        onClick={deploy}
        variant="contained"
        color="primary"
        style={{ height: '100%' }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </>
  );

  return (
    <FullHeightDialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Deploy Materials</DialogTitle>
      <Divider />
      <div style={{ padding: '0 8px' }}>
        <SearchDropdown
          fullWidth
          margin="normal"
          label="Deploy to user"
          itemToString={item => item.name}
          items={users}
          onChange={selected => setDeployedTo(selected)}
          value={deployedTo}
          required
        />
      </div>
      {!pickListOpen ? (
        <>
          <DialogContent style={{ padding: 0 }}>
            <MaterialsTable
              highlightUnordered
              localStorageKey="ProjectMaterials"
              initialRowsPerPage={100}
              materials={materials || []}
              removeColumns={hiddenColumns}
              isSelecting
              statuses={materialStatuses}
              isSettingQuantity
              settingQuantityLabel="Deploy"
              getAutofillUpdateQuantity={prefillQuantity}
              selectedRows={selectedMaterials}
              setSelectedRows={setSelectedMaterials}
            />
          </DialogContent>
          <Divider />

          <DialogActions>
            <Button onClick={onClose} variant="contained">
              cancel
            </Button>
            {buttons}
          </DialogActions>
        </>
      ) : (
        <PickListModal
          noDialog
          noSelect
          pdfOnly
          title={null}
          open={pickListOpen}
          onClose={closedBy =>
            closedBy !== 'download' ? setPickListMaterials(null) : null
          }
          materials={pickListOpen ? pickListMaterials : []}
          materialStatuses={materialStatuses}
          buttons={buttons}
        />
      )}
    </FullHeightDialog>
  );
}
