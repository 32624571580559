import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import SelectionSortingTable from '../SortingTable/SelectionSortingTable.component';
import NoItemsResult from '../NoItemsResult/NoItemsResult.component';
import ProjectListModal from './modals/ProjectList.modal';
import { SetProjectsModalMaterialContext } from './components/ProjectCountCell.component';
import { SetMaterialWithReplacementsContext } from './components/ConflictReplacementCell.component';
import MaterialsReplacedByModal from './modals/MaterialList.modal';
import { useSearchMaterials } from './hooks/useSearchMaterials.hook';
import MasterMaterialModal from './modals/MasterMaterial.modal';
import { SetMasterMaterialIdContext } from './components/MasterMaterialCell.component';
import { SetQuantityContext } from './components/UpdateQuantityCell.component';
import mapReducer from 'helpers/mapReducer.helper';
import safeParse from 'helpers/safeParse.helper';
import HiddenColumns from './helpers/hiddenColumns.helper';
import StatusesContext from './context/Statuses.context';
import { HighlightUnorderedContext } from './components/QuantityCell.component';
import OnStatusRemovedContext from './context/OnStatusRemoved.context';
import useFreshFn from 'hooks/useFreshFn.hook';
import MATERIAL_COLUMNS, {
  statusColumns
} from './helpers/materialColumns.helper';
import MaterialCsvGenerator from './components/MaterialCsvGenerator.component';

const emptyMaterials = [];

const MaterialsTableUF = (
  {
    materials: materialsProp,
    onSelect,
    onSelectionChange,
    rootStyles,
    tableContainerStyles,
    paginationContainerStyles,
    localStorageKey = null,
    removeColumns = [],
    noItemsResultType = 'Materials',
    searchText = null,
    initialRowsPerPage = 25,
    getRowStyle,

    statuses = {},
    onStatusRemoved = null,

    isSettingQuantity = false,
    settingQuantityLabel = '',
    getAutofillUpdateQuantity: getAutofillUpdateQuantityProp = null,

    highlightUnordered = false,

    isSelecting = false,
    selectedRows = null,
    setSelectedRows = null,
    elevation
  },
  ref
) => {
  let materials = materialsProp;
  if (!materials || !Array.isArray(materials)) materials = emptyMaterials;
  const sortingTableRef = useRef(null);
  const [masterMaterialId, setMasterMaterialId] = useState(null);
  const [projectsModalMaterial, setProjectsModalMaterial] = useState(null);
  const [materialWithReplacements, setMaterialWithReplacements] = useState(
    null
  );
  const [updatedColumns, setUpdatedColumns] = useState(MATERIAL_COLUMNS);
  const csvRef = useRef({ downloadCsv: () => {} });

  const searchActive = searchText && searchText.length > 1;
  const filteredAndSortedMats = useSearchMaterials({
    materials,
    searchText,
    active: searchActive
  });

  const removeColumnsString = removeColumns.join('-:-');
  const keepUpdateQuantity = !!(
    isSettingQuantity &&
    selectedRows &&
    setSelectedRows
  );
  const addStatusColumns = hasAny(
    statuses,
    materials,
    'received',
    'deployed',
    'returned'
  );

  useImperativeHandle(ref, () => ({
    downloadCsv: fileNamePrefix => {
      csvRef.current.downloadCsv(fileNamePrefix);
    }
  }));

  useEffect(() => {
    // SET COLUMNS
    const removeColumns = removeColumnsString.split('-:-');
    const filtered = MATERIAL_COLUMNS.map(col => ({ ...col })).filter(
      col =>
        !removeColumns.includes(col.key) &&
        (col.key === 'updateQuantity' ? keepUpdateQuantity : true)
    );
    const updated = filtered.map(col =>
      col.key !== 'updateQuantity'
        ? { ...col }
        : { ...col, label: settingQuantityLabel }
    );

    if (addStatusColumns) {
      const quantityIdx = updated.findIndex(col => col.key === 'quantity');
      if (quantityIdx !== -1)
        updated.splice(quantityIdx + 1, 0, ...statusColumns);
    }
    setUpdatedColumns(updated);
  }, [
    keepUpdateQuantity,
    removeColumnsString,
    settingQuantityLabel,
    addStatusColumns
  ]);

  useEffect(() => {
    // SORT BY BEST SEARCH
    if (sortingTableRef.current) {
      if (searchActive) {
        sortingTableRef.current.setUnsortRows(true);
      } else {
        sortingTableRef.current.setUnsortRows(false);
      }
    }
  }, [searchActive]);

  let getAutofillUpdateQuantity = useFreshFn(
    getAutofillUpdateQuantityProp === null
      ? () => {}
      : getAutofillUpdateQuantityProp
  );
  getAutofillUpdateQuantity =
    getAutofillUpdateQuantityProp === null ? null : getAutofillUpdateQuantity;

  useEffect(() => {
    // ADD "updateQuantity"
    if (!(isSettingQuantity && selectedRows && setSelectedRows)) return;
    setSelectedRows(old => {
      let updates = false;
      const updated = old.map(mat => {
        let matUpdate = mat;
        if (!mat.hasOwnProperty('updateQuantity')) {
          updates = true;
          matUpdate = {
            ...mat,
            updateQuantity: getAutofillUpdateQuantity
              ? getAutofillUpdateQuantity(mat)
              : safeParse(mat.quantity, 0)
          };
        }
        return matUpdate;
      });
      return updates ? updated : old;
    });
  }, [
    isSettingQuantity,
    selectedRows,
    setSelectedRows,
    getAutofillUpdateQuantity
  ]);

  const onSetMaterialQuantity = (material, updateQuantity) => {
    if (!(isSettingQuantity && selectedRows && setSelectedRows)) return;
    setSelectedRows(old => {
      const copy = [...old];
      let idx = copy.findIndex(other => other.id === material.id);
      if (idx === -1) {
        copy.unshift({ ...material });
        idx = 0;
      }
      copy[idx] = { ...copy[idx], updateQuantity };
      return copy;
    });
  };

  const getUpdatedMaterials = () => {
    if (!(isSettingQuantity && selectedRows && setSelectedRows)) {
      return filteredAndSortedMats;
    }
    const updatesIdMap = selectedRows.reduce(mapReducer('id'), {});
    const updated = filteredAndSortedMats.map(mat =>
      updatesIdMap[mat.id] ? updatesIdMap[mat.id] : mat
    );
    return updated;
  };

  return (
    <>
      <ProjectListModal
        open={!!projectsModalMaterial}
        onClose={() => setProjectsModalMaterial(null)}
        onProjectSelect={() => setProjectsModalMaterial(null)}
        material={projectsModalMaterial}
      />
      <MaterialsReplacedByModal
        open={!!materialWithReplacements}
        onClose={() => setMaterialWithReplacements(null)}
        material={materialWithReplacements}
      />
      <MasterMaterialModal
        open={!!masterMaterialId}
        onClose={() => setMasterMaterialId(null)}
        materialId={masterMaterialId}
      />
      {filteredAndSortedMats.length ? (
        <StatusesContext.Provider value={statuses}>
          <OnStatusRemovedContext.Provider value={onStatusRemoved}>
            <HighlightUnorderedContext.Provider value={highlightUnordered}>
              <SetQuantityContext.Provider value={onSetMaterialQuantity}>
                <SetMaterialWithReplacementsContext.Provider
                  value={setMaterialWithReplacements}
                >
                  <SetProjectsModalMaterialContext.Provider
                    value={setProjectsModalMaterial}
                  >
                    <SetMasterMaterialIdContext.Provider
                      value={setMasterMaterialId}
                    >
                      <MaterialCsvGenerator
                        materials={
                          selectedRows && selectedRows.length
                            ? selectedRows
                            : getUpdatedMaterials()
                        }
                        ref={csvRef}
                      />
                      <SelectionSortingTable
                        rows={getUpdatedMaterials()}
                        columns={updatedColumns}
                        rowAction={onSelect}
                        onSelectionChange={onSelectionChange}
                        isSelecting={isSelecting}
                        rootStyles={{ height: '100%', ...rootStyles }}
                        stickyHeader
                        addEmptyRows={false}
                        localStorageKey={localStorageKey}
                        tableContainerStyles={tableContainerStyles}
                        paginationContainerStyles={paginationContainerStyles}
                        initialRowsPerPage={initialRowsPerPage}
                        getRowStyle={getRowStyle}
                        elevation={elevation}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        ref={sortingTableRef}
                      />
                    </SetMasterMaterialIdContext.Provider>
                  </SetProjectsModalMaterialContext.Provider>
                </SetMaterialWithReplacementsContext.Provider>
              </SetQuantityContext.Provider>
            </HighlightUnorderedContext.Provider>
          </OnStatusRemovedContext.Provider>
        </StatusesContext.Provider>
      ) : (
        <NoItemsResult type={noItemsResultType} />
      )}
    </>
  );
};
const MaterialsTable = React.forwardRef(MaterialsTableUF);
MaterialsTable.presetHiddenColumns = HiddenColumns;
MaterialsTable.MaterialCsvGenerator = MaterialCsvGenerator;

function hasAny(statuses, materials, ...keys) {
  if (!statuses) return false;
  for (let matIdx = 0; matIdx < materials.length; matIdx++) {
    const material = materials[matIdx];
    const materialId = material.materialId || material.id;
    const status = statuses[materialId];
    if (!status) continue;
    for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
      const key = keys[keyIdx];
      const val = status[key];
      if (typeof val === 'number' && val > 0) return true;
    }
  }
  return false;
}

export default MaterialsTable;
