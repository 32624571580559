import React from 'react';
import { makeStyles, TableCell } from 'helpers/themeSafeMui.helper';
import { ExternalLink } from 'components';

const useStyles = makeStyles({
  cell: { '&:hover': { textDecoration: 'underline' } }
});

export default function ExternalLinkCell({ link, cellStyle }) {
  const { cell } = useStyles();

  return (
    <TableCell
      className={!!link ? cell : undefined}
      style={{ ...cellStyle, padding: 0 }}
      onClick={e => {
        if (!link) return;
        e.stopPropagation();
      }}
    >
      {!link ? null : (
        <ExternalLink
          href={link}
          style={{
            padding: '6px 24px 6px 16px',
            fontSize: 14,
            display: 'block'
          }}
        >
          <div>Open</div>
        </ExternalLink>
      )}
    </TableCell>
  );
}
