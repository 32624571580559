import diffDays from './diffDays.helper';

export default function getTaskIndexMap(startDay, colCount, tasks) {
  const taskMap = {};

  tasks.forEach((task, taskIdx) => {
    let startIdx = diffDays(startDay, task.startDate);
    let span = diffDays(task.startDate, task.endDate) + 1;
    let padStart = true;
    let padEnd = true;

    if (isNaN(span)) {
      span = 1;
    }
    if (startIdx < 0) {
      span += startIdx;
      padStart = false;
      startIdx = 0;
    }
    if (startIdx + span > colCount) {
      padEnd = false;
      span = colCount - startIdx;
    }
    taskMap[startIdx] = {
      task,
      span,
      padStart,
      padEnd,
      taskIdx
    };
  });

  return taskMap;
}
