const signerColors = idx => {
  const colors = [
    'rgb(33, 133, 208)',
    'rgb(242, 113, 28)',
    'rgb(0, 181, 173)',
    'rgb(224, 57, 151)',
    'rgb(100, 53, 201)',
    'rgb(251, 189, 8)',
    'rgb(219, 40, 40)',
    'rgb(181, 204, 24)',
    'rgb(163, 51, 200)',
    'rgb(33, 186, 69)'
  ];
  return colors[idx % colors.length];
};

export default signerColors;
