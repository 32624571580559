import React, { useEffect, useState } from 'react';
import http from 'helpers/http.helper';
import { ConfirmDelete, DragAndDrop, FormModal, Toast } from 'components';
import {
  Typography,
  Button,
  IconButton,
  ListItem,
  ListItemText
} from 'helpers/themeSafeMui.helper';
import EditIcon from '@material-ui/icons/Edit';

export default function NewhireChecklistItems() {
  const [items, setItems] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    http()
      .get('/newhire-checklist-items')
      .then(users => setItems(users))
      .catch(err => Toast.show(err.message));
  }, []);

  const createChecklistItem = async formItem => {
    try {
      const newItem = await http().post('/newhire-checklist-items', {
        ...formItem,
        order: items.length
      });
      setItems(old => [...old, newItem]);
      setIsCreating(false);
      Toast.show('Item Created');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const editChecklistItem = async formItem => {
    try {
      if (!editingItem) return;
      const copy = [...items];
      const updated = await http().put(
        `/newhire-checklist-items/${editingItem.id}`,
        { ...pluck(formItem, 'title', 'notes', 'allowOptional') }
      );
      copy[updated.order] = updated;
      setItems(copy);
      setEditingItem(null);
      Toast.show('Item Updated');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const removeChecklistItem = item => async () => {
    try {
      await http().delete(`/newhire-checklist-items/${item.id}`);
      const copy = [...items];
      copy.splice(item.order, 1);
      await onDrop(copy);
      Toast.show('Item Removed');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const onDrop = async droppedItems => {
    try {
      setItems(droppedItems);
      droppedItems = await Promise.all(
        droppedItems.map((item, order) =>
          http().put(`/newhire-checklist-items/${item.id}`, { order })
        )
      );
      setItems(droppedItems);
    } catch (err) {
      setItems(items);
      Toast.showErr(err);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={() => setIsCreating(true)}
      >
        Create
      </Button>
      <FormModal
        title="Edit Checklist Item"
        onClose={() => setEditingItem(null)}
        open={!!editingItem}
        onSubmit={editChecklistItem}
        fields={formFields}
        data={editingItem}
      />
      <FormModal
        title="Create Checklist Item"
        onClose={() => setIsCreating(false)}
        open={isCreating}
        onSubmit={createChecklistItem}
        fields={formFields}
      />
      <DragAndDrop cardStyles={{ padding: 0, paddingLeft: 10 }} onDrop={onDrop}>
        {items.map(item => (
          <ListItem
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
            key={item.id}
            id={item.id}
            value={item}
          >
            <ListItemText primary={item.title} secondary={item.notes} />
            {item.allowOptional && (
              <Typography
                style={{ marginRight: 10 }}
                variant="caption"
                color="textSecondary"
              >
                Optional Allowed
              </Typography>
            )}
            <IconButton size="small" onClick={() => setEditingItem(item)}>
              <EditIcon />
            </IconButton>
            <ConfirmDelete
              onConfirm={removeChecklistItem(item)}
              iconButtonProps={{ size: 'small' }}
            />
          </ListItem>
        ))}
      </DragAndDrop>
    </div>
  );
}

const formFields = [
  {
    label: 'Title',
    key: 'title',
    type: 'text'
  },
  {
    label: 'Notes',
    key: 'notes',
    type: 'text'
  },
  {
    label: 'Can be marked optional',
    key: 'allowOptional',
    type: 'boolean'
  }
];

function pluck(object, ...keys) {
  const retVal = {};
  keys.forEach(key => {
    retVal[key] = object[key];
  });
  return retVal;
}
