import React from 'react';
import moment from 'moment';
import { getDefaultLunch } from '../helpers/DateTime.helper';
import { TimePickerButton } from 'components';

const commonShifts = [
  {
    name: '10 HR Shift',
    add: 10,
    start: moment()
      .set({ hour: 7 })
      .startOf('hour'),
    lunch: true
  },
  {
    name: '8 HR Shift',
    add: 8,
    start: moment()
      .set({ hour: 7 })
      .startOf('hour'),
    lunch: true
  },
  {
    name: '1 Hr Shift',
    add: 1,
    start: moment()
      .set({ hour: 12 })
      .startOf('hour'),
    lunch: false
  },
  {
    name: '2 Hr Shift',
    add: 2,
    start: moment()
      .set({ hour: 7 })
      .startOf('hour'),
    lunch: false
  },
  {
    name: '4 Hr Shift',
    add: 4,
    start: moment()
      .set({ hour: 7 })
      .startOf('hour'),
    lunch: false
  }
];

export default function CommonShiftsButton({ onSelect, disabled = false }) {
  const select = cShift => start => {
    let end = moment(start).add(cShift.add, 'hours');
    if (cShift.lunch) end = moment(end).add(30, 'minutes');
    const data = {
      start,
      end,
      lunchStart: cShift.lunch
        ? getDefaultLunch(moment(start), cShift.add)
        : null,
      lunchDurationMinutes: cShift.lunch ? 30 : null
    };
    onSelect(data);
    return data;
  };

  return (
    <>
      {commonShifts.map(cShift => (
        <TimePickerButton
          disabled={disabled}
          title={`${cShift.name} start:`}
          key={cShift.add}
          ignoreTypes={['seconds']}
          onTimeSelected={select(cShift)}
          variant="contained"
          color="primary"
        >
          {cShift.name}
        </TimePickerButton>
      ))}
    </>
  );
}
