import { useSelector } from 'react-redux';

interface CurrentUser {
  id: string;
  archived: boolean;
  created: string;
  firstName: string;
  lastName: string;
  legalFirstName: string;
  createdBy: string;
  phoneNumber: string;
  personalEmail: string;
  workEmail: string;
  preferPersonalEmail: boolean;
  middleName: string;
  drugTestCompleted: boolean;
  onBoardingCompleted: boolean;
  temporarilySuspended: boolean;
  passwordChangedAt: string;
  googleDriveFolderId: string;
  address: string;
  address2: string;
  birthDate: string;
  shirtSize: string;
  married: boolean;
  spouseName: string;
  spousePhone: string;
  image: string;
  totalHours: string;
  totalHoursLastUpdate: string;
  password: string;
  googleOauthRefreshToken: string;

  activeLogId: string;
  permissionLevelId: string;
  jobTitle: string;
  employmentStatus: string;
  jobApproved: boolean;
  isEligibleToRehire: boolean;
  area: string;
  areaId: string;

  hasPermission: (permission: string) => boolean;
  permissionLevel: any;
  isInternal: boolean;
  inactive: boolean;
}

export default function useCurrentUser() {
  const currentUser = useSelector((state: any) => state.user);
  return currentUser as CurrentUser;
}
