import CanvasUtils from './CanvasUtils.canvas';
import LabelColumn from './LabelColumn.canvas';
import moment from 'moment';
import Week from './Week.canvas';

class TaskTable extends CanvasUtils {
  constructor({ ctx, tasks, printView }) {
    super(ctx);
    this.printView = printView;
    this.tasks = tasks;
    this.setup();
  }

  update({ tasks, printView }) {
    this.printView = printView;
    this.tasks = tasks;
    this.setup();
  }

  setup(...sliceArgs) {
    const { ctx, tasks, printView } = this;
    this.rowHeight = 20 * this.dpr;
    this.canvasPadding = 20 * this.dpr;
    this.visibleTasks = printView.taskIds.map(id =>
      tasks.find(task => task.id === id)
    );
    this.labelCoulmn = new LabelColumn({
      ctx,
      tasks,
      printView,
      rowHeight: this.rowHeight,
      dpr: this.dpr
    });

    this.labelCoulmn.measure();
    this.createWeeks(...sliceArgs);

    const weeksWidth = this.weeks.reduce((sum, { width }) => sum + width, 0);
    ctx.canvas.width =
      this.labelCoulmn.size.x + weeksWidth + this.canvasPadding * 2;
    ctx.canvas.height = this.labelCoulmn.size.y + this.canvasPadding * 2;

    // applied by the react component
    this.canvasStyles = {
      width: ctx.canvas.width / this.dpr,
      height: ctx.canvas.height / this.dpr
    };
  }

  createWeeks(...sliceArgs) {
    let start, end;
    if (!sliceArgs.length) sliceArgs = [0];
    this.visibleTasks.forEach(task => {
      if (!start) start = moment(task.startDate);
      if (!end) end = moment(task.endDate);
      if (moment(task.startDate).isBefore(start))
        start = moment(task.startDate);
      if (moment(task.endDate).isAfter(end)) end = moment(task.endDate);
    });
    const weeks = [];
    if (start && end) {
      start.startOf('week');
      end.endOf('week');
      let current = moment(start);
      while (current.isBetween(start, end, null, '[]')) {
        current.startOf('week');
        weeks.push(current);
        current = moment(current).add(1, 'week');
      }
    }
    this.weeks = weeks.slice(...sliceArgs).map(
      (startDate, idx) =>
        new Week({
          ctx: this.ctx,
          tasks: this.tasks,
          printView: this.printView,
          rowHeight: this.rowHeight,
          weekNumber: idx + 1 + sliceArgs[0],
          startDate,
          dpr: this.dpr
        })
    );
  }

  printWeeks() {
    this.weeks.forEach((week, idx) => {
      this.ctx.save();
      this.ctx.translate(this.labelCoulmn.size.x + week.width * idx, 0);
      week.print();
      this.ctx.restore();
    });
  }

  getPageSlices(maxWidth) {
    const slices = [];
    let sliceStart = 0;
    for (let i = 1; i <= this.weeks.length; i++) {
      const { x: width } = this.getSize(sliceStart, i);
      if (width > maxWidth || i === this.weeks.length) {
        slices.push([sliceStart, i]);
        sliceStart = i;
      }
    }
    return slices;
  }

  toPdfImageUrls({ maxWidth = 600, canvasDataURLArgs }) {
    this.dpr = 3;
    maxWidth *= this.dpr;
    this.setup();
    const slices = this.getPageSlices(maxWidth);
    const urls = [];
    slices.forEach(sliceArgs => {
      this.setup(...sliceArgs);
      this.print();
      urls.push(this.ctx.canvas.toDataURL(...canvasDataURLArgs));
    });
    // const res = this.ctx.canvas.toDataURL(...canvasDataURLArgs);

    this.dpr = window.devicePixelRatio;
    this.setup();
    this.print();
    return { urls, slices };
  }

  getSize(...sliceAgs) {
    return {
      x:
        this.weeks
          .slice(...sliceAgs)
          .reduce((acc, week) => week.width + acc, 0) +
        this.labelCoulmn.size.x +
        this.canvasPadding * 2,
      y: this.labelCoulmn.size.y + this.canvasPadding * 2
    };
  }

  print() {
    this.ctx.save();
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fillRect(0, 0, 100_000, 100_000);
    this.ctx.restore();

    this.ctx.save();
    this.ctx.translate(this.canvasPadding, this.canvasPadding);
    this.labelCoulmn.print();
    this.printWeeks();
    this.ctx.restore();
  }
}

export default TaskTable;
