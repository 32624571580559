import React from 'react';
import { Typography, Link } from 'helpers/themeSafeMui.helper';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Folder from '@material-ui/icons/Folder';

const Breadcrumbs = props => {
  const { folderTree, onChange } = props;

  return (
    <Typography>
      {Boolean(folderTree.length) && (
        <Folder
          fontSize="small"
          color="action"
          style={{ verticalAlign: 'text-bottom' }}
        />
      )}{' '}
      {folderTree.map((folder, idx) => (
        <span key={folder.id}>
          {idx === folderTree.length - 1 ? (
            folder.name
          ) : (
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => onChange(folderTree.slice(0, idx + 1))}
            >
              {folder.name}
              <ChevronRight
                style={{ verticalAlign: 'bottom' }}
                color="action"
              />
            </Link>
          )}
        </span>
      ))}
    </Typography>
  );
};

export default Breadcrumbs;
