import React, { useContext, useState } from 'react';
import {
  DashboardWrapper,
  DropdownButton,
  PermissionWrapper,
  ProjectFilterDropdown
} from 'components';
import ProjectsContextProvider, {
  ProjectsContext
} from './context/Projects.context';
import ColumnsContextProvider, {
  ColumnsContext
} from './context/Columns.context';
import CompletionSortingTable from './components/CompletiontSortingTable.component';
import StatusFilter from './components/StatusFilter.component';

function Connected() {
  const {
    projects,
    filteredProjects,
    setFilteredProjects,
    detailsMap
  } = useContext(ProjectsContext);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const { allColumns, selectedColumns, setSelectedColumns } = useContext(
    ColumnsContext
  );

  const visableProjects = filteredProjects.filter(project =>
    selectedStatuses.length ? selectedStatuses.includes(project.statusId) : true
  );

  const updatedColumns = selectedColumns.map(column => {
    if (!column.isComplete) return column;

    const sum = visableProjects.reduce(
      (sum, project) =>
        column.isComplete(project, detailsMap[project.id] || {})
          ? sum + 1
          : sum,
      0
    );
    const percent = sum / visableProjects.length;
    const percentText = isNaN(percent) ? '---' : (percent * 100).toFixed(1);
    return {
      ...column,
      headerCellContent: `${column.label} (${percentText}%)`
    };
  });

  return (
    <DashboardWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 10
        }}
      >
        <div>
          <DropdownButton.MultiSelect
            name="Columns"
            allItems={allColumns}
            selectedItems={allColumns}
            setSelectedItems={setSelectedColumns}
            localStorageKey="CompletionTableColumns"
            checkboxPropsMap={{
              projectNumber: { disabled: true, onSelect: () => {} }
            }}
          />
        </div>
        <StatusFilter
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
        />
        <ProjectFilterDropdown
          projects={projects}
          onUpdate={setFilteredProjects}
        />
      </div>
      <div style={{ height: 'calc(100vh - 150px)' }}>
        <CompletionSortingTable
          columns={updatedColumns}
          projects={visableProjects}
        />
      </div>
    </DashboardWrapper>
  );
}

export default function ProjectCompletionTable() {
  return (
    <PermissionWrapper redirect permission="projects_tier_2">
      <ProjectsContextProvider>
        <ColumnsContextProvider>
          <Connected />
        </ColumnsContextProvider>
      </ProjectsContextProvider>
    </PermissionWrapper>
  );
}
