import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import user from './user.duck';
import modal from './modal.duck';
import favoriteProjects from './favoriteProjects.duck';
import areas from './areas.duck';
import permissionLevels from './permissionLevels.duck';
import projectStatuses from './projectStatuses.duck';
import adminSettings from './adminSettings.duck';
import actionItems from './actionItems.duck';
import userActionItems from './userActionItems.duck';

const rootReducer = combineReducers({
  user,
  modal,
  favoriteProjects,
  areas,
  permissionLevels,
  projectStatuses,
  actionItems,
  userActionItems,
  adminSettings
});

export default configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});
