import React from 'react';
import {
  TextField,
  MenuItem,
  Paper,
  ListItemText,
  Chip
} from 'helpers/themeSafeMui.helper';
import DeleteIcon from '@material-ui/icons/Cancel';

const ExistingItems = props => {
  return (
    <React.Fragment>
      {props.items.map(item => (
        <Chip
          style={{
            height: 22,
            margin: 1.5
          }}
          key={item.id}
          label={props.itemToString(item)}
          onDelete={() =>
            props.onChange(props.items.filter(el => el.id !== item.id))
          }
          deleteIcon={<DeleteIcon style={{ height: 18, marginRight: 2 }} />}
        />
      ))}
    </React.Fragment>
  );
};

export default class MultiSearchDropdown extends React.Component {
  state = {
    inputValue: '',
    focused: false
  };

  render() {
    const itemToString = item => (item ? this.props.itemToString(item) : '');

    const rootStyles = this.props.rootStyles || {};

    return (
      <div
        style={{ ...rootStyles, position: 'relative' }}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            this.setState({ inputValue: '' });
          }
        }}
      >
        <TextField
          InputLabelProps={{
            shrink: Boolean(
              this.props.selected.length ||
                this.state.inputValue ||
                this.state.focused
            ),
            required: this.props.required
          }}
          InputProps={{
            startAdornment: Boolean(this.props.selected.length) && (
              <ExistingItems
                items={this.props.selected}
                itemToString={itemToString}
                onChange={this.props.onChange}
              />
            ),
            onBlur: () => this.setState({ focused: false }),
            onFocus: () => this.setState({ focused: true }),
            onKeyDown: e => {
              if (
                e.key === 'Enter' &&
                this.props.stringToItem &&
                this.state.inputValue.length
              ) {
                this.props.onChange([
                  ...this.props.selected,
                  this.props.stringToItem(this.state.inputValue)
                ]);
                this.setState({ inputValue: '' });
                this.inputRef.focus();
              }

              if (
                e.key === 'Backspace' &&
                this.props.selected.length &&
                !this.state.inputValue
              ) {
                this.props.onChange(this.props.selected.slice(0, -1));
              }
            },
            style: {
              display: 'flex',
              flexWrap: 'wrap'
            },
            inputProps: {
              style: { width: 'auto', minWidth: 100, marginLeft: 1.5 }
            }
          }}
          inputRef={el => (this.inputRef = el)}
          onChange={e => this.setState({ inputValue: e.target.value })}
          value={this.state.inputValue}
          {...this.props.TextFieldProps}
        />

        {Boolean(this.state.inputValue) && (
          <Paper
            style={{
              position: 'absolute',
              zIndex: 99,
              left: 0,
              right: 0,
              marginBottom: 8
            }}
          >
            {this.props.items
              .filter(item => {
                const words = this.state.inputValue.toLowerCase().split(' ');
                const searchString = itemToString(item).toLowerCase();

                return words.every(word => searchString.indexOf(word) !== -1);
              })
              .filter(i1 => !this.props.selected.some(i2 => i1.id === i2.id))
              .slice(0, 5)
              .map(item => (
                <MenuItem
                  key={item.id}
                  onClick={e => {
                    this.props.onChange([...this.props.selected, item]);
                    this.setState({ inputValue: '' });
                    this.inputRef.focus();
                  }}
                >
                  <ListItemText>{itemToString(item)}</ListItemText>
                </MenuItem>
              ))}
          </Paper>
        )}
      </div>
    );
  }
}
