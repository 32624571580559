import React from 'react';
import { Parser } from 'json2csv';
import { Button } from 'helpers/themeSafeMui.helper';
import moment from 'moment';

export default function ColumnsToCSV({ button = null, title, columns, rows }) {
  const getLabel = col =>
    removeNewlines((col || '') && (col.csvLabel || col.label || ''));
  const createCsv = () => {
    if (!columns || !columns.length || !rows || !rows.length) return;
    const jsonData = [];
    rows.forEach(row => {
      const item = {};
      columns.forEach(col => {
        const label = getLabel(col);
        item[label] = col.csvExtractor
          ? col.csvExtractor(row)
          : col.extractor
          ? col.extractor(row)
          : row[col.key]
          ? row[col.key]
          : '';
        item[label] = removeNewlines(item[label]);
      });
      jsonData.push(item);
    });
    jsonData.sort((a, b) => {
      const label = getLabel(columns[0]);
      if (a[label] === b[label]) return 0;
      if (a[label] < b[label]) return -1;
      if (a[label] > b[label]) return 1;
      return 0;
    });

    const parser = new Parser({
      fields: columns.map(column => getLabel(column))
    });
    const csv = parser.parse(jsonData);

    let prefix = 'data:text/csv;charset=utf-8,';
    const encodedUri = prefix + encodeURIComponent(csv);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${title}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`
    );
    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  if (button) {
    return React.cloneElement(button, { onClick: createCsv });
  }

  const rowsText = rows
    ? `(${rows.length} row${rows.length !== 1 ? 's' : ''})`
    : null;

  return (
    <Button onClick={createCsv} variant="contained">
      Create CSV {rowsText}
    </Button>
  );
}

function removeNewlines(string) {
  if (typeof string !== 'string') return string;
  return string.replace(/(?:\r\n|\r|\n)/g, ' ');
}
