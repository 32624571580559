import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
  DialogTitle,
  TextField
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { Toast, SearchDropdown } from 'components';
import UsersIcon from '@material-ui/icons/People';
import PeopleIcon from '@material-ui/icons/Contacts';

const SelectSigner = props => {
  const { isTemplate, selectedSigner, onClose, onSubmit } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [users, setUsers] = useState([]);
  const [people, setPeople] = useState([]);

  useEffect(() => {
    http()
      .get('/users')
      .then(res => setUsers(res))
      .catch(e => Toast.show(e.message));

    http()
      .get('/people')
      .then(res => setPeople(res))
      .catch(e => Toast.show(e.message));
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={Boolean(selectedSigner)}
      onClose={onClose}
      PaperProps={{ style: { overflow: 'visible' } }}
    >
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          onSubmit({ email, name, id: selectedSigner });
          setName('');
          setEmail('');
          onClose();
        }}
        onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
      >
        <DialogTitle>
          {selectedSigner === 'NEW' ? 'Add' : 'Set'} Document Signer
        </DialogTitle>
        {isTemplate ? (
          <DialogContent>
            <TextField
              fullWidth
              margin="normal"
              label="Signer Role"
              autoFocus
              onChange={e => setName(e.target.value)}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="Name"
              autoFocus
              items={[...users, ...people]}
              itemToString={item => item.name}
              itemRender={item => (
                <span>
                  {item.companyId ? (
                    <PeopleIcon
                      fontSize="small"
                      color="action"
                      style={{ verticalAlign: 'text-bottom', marginRight: 8 }}
                    />
                  ) : (
                    <UsersIcon
                      fontSize="small"
                      color="action"
                      style={{ verticalAlign: 'text-bottom', marginRight: 8 }}
                    />
                  )}
                  {item.name}
                </span>
              )}
              onChange={person => {
                const emails = person.email
                  ? [person.email]
                  : person.emails.map(email => email.email);
                setName(person.name);
                setEmails(emails);
                setEmail(emails[0]);
              }}
            />

            <FormControl fullWidth margin="normal" disabled={!name}>
              <InputLabel>Email</InputLabel>
              <Select value={email} onChange={e => setEmail(e.target.value)}>
                {emails.map(email => (
                  <MenuItem value={email} key={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {selectedSigner === 'NEW' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SelectSigner;
