import React, { useState, useEffect } from 'react';
import { Toast } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

function UpdateAccountManager({ accountManager, open, onUpdate, onClose }) {
  const [managerNumber, setManagerNumber] = useState('0');

  useEffect(() => {
    setManagerNumber(accountManager ? accountManager.managerNumber : '0');
  }, [accountManager]);

  const save = e => {
    e.preventDefault();
    if (!accountManager) return;

    http()
      .put(`/account-managers/${accountManager.id}`, {
        managerNumber
      })
      .then(res => {
        Toast.show(`${res.name} was updated to "${res.managerNumber}".`);
        onClose();
        onUpdate();
      })
      .catch(err => Toast.show(err.message));
  };

  const updateManagerNumber = e => {
    if (e.target.value.length < 2) setManagerNumber(e.target.value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>Update Account Manager</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {accountManager && accountManager.name}
          </DialogContentText>

          <TextField
            type="text"
            label="Account Manager Number"
            value={managerNumber}
            name="managerNumber"
            onChange={updateManagerNumber}
            required
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UpdateAccountManager;
