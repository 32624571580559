import { useCallback, useContext } from 'react';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { fromDateString } from '../helpers/DateTime.helper';

export default function useUserIsScheduled() {
  const { userIdShiftMap } = useContext(ShiftScheduleContext);
  const userIsScheduled = useCallback(
    userOrId => {
      const userId = userOrId.id || userOrId;
      const shifts = userIdShiftMap[userId] || [];
      const scheduledDays = Array(7).fill(false);
      return scheduledDays.map((f, dow) => {
        return !!shifts.filter(
          shift => fromDateString(shift.date).day() === dow
        ).length;
      });
    },
    [userIdShiftMap]
  );
  return userIsScheduled;
}

export const useGetDaysText = () => {
  const userIsScheduled = useUserIsScheduled();
  const { userIdHoursMap } = useContext(ShiftScheduleContext);

  return useCallback(
    user => {
      const userDays = userIsScheduled(user);
      const workingDays = userDays
        .map((active, idx) => (active ? idx : null))
        .filter(day => day !== null)
        .map(day => DOWs[day]);
      if (!workingDays.length) return null;
      const hours = userIdHoursMap[user.id] || 0;
      const roundedHours = Math.floor(hours * 100) / 100;
      const hoursText = roundedHours === 1 ? '1 hr' : `${roundedHours} hrs`;
      return `${hoursText} - ${workingDays.join(', ')}`;
    },
    [userIsScheduled, userIdHoursMap]
  );
};

const DOWs = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
};
