import React from 'react';
import { ButtonBase, Paper, Typography } from 'helpers/themeSafeMui.helper';
import CrossIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { useColors } from 'helpers/theme.helper';
import moment from 'moment';

export default function DowButtons({
  activeDays,
  toggleDay,
  style,
  disabled = false
}) {
  const colors = useColors();

  return (
    <div style={style}>
      {Array(7)
        .fill(false)
        .map((f, idx) => (
          <ButtonBase
            disabled={disabled}
            style={{ width: 'calc((100% / 7) - 10px)', margin: '0 5px' }}
            key={idx}
            onClick={toggleDay(idx)}
          >
            <Paper
              style={{
                opacity: disabled ? 0.3 : 1,
                width: '100%',
                padding: 10,
                display: 'flex',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              {activeDays[idx] ? (
                <CheckIcon style={{ color: colors.success }} color="inherit" />
              ) : (
                <CrossIcon style={{ color: colors.error }} color="inherit" />
              )}
              <Typography style={{ marginLeft: 5 }}>
                {moment()
                  .day(idx)
                  .format('ddd')}
              </Typography>
            </Paper>
          </ButtonBase>
        ))}
    </div>
  );
}
