import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import useTimer from 'hooks/useTimer.hook';
import { Typography } from 'helpers/themeSafeMui.helper';

function formatSeconds(seconds) {
  if (seconds === null) return null;
  const m = moment.duration({ seconds });
  const displayMinutes = String(m.get('minutes')).padStart(2, '0');
  const displaySeconds = String(m.get('seconds')).padStart(2, '0');
  return `${displayMinutes}:${displaySeconds}`;
}

export default function Timer({ resetId, limit = null, onTimeExceeded }) {
  const [timePassed, setTimePassed] = useState(0);
  const { startTimer, clearTimer } = useTimer(setTimePassed);
  const exceededThisId = useRef(false);

  useEffect(() => {
    if (limit === null) {
      setTimePassed(null);
      clearTimer();
    } else {
      setTimePassed(0);
      startTimer();
    }
    exceededThisId.current = false;
  }, [resetId, limit, startTimer, clearTimer]);

  useEffect(() => {
    if (limit !== null && timePassed > limit) {
      setTimePassed(0);
      clearTimer();
      if (!exceededThisId.current) {
        // cant exceed > once for same ID
        exceededThisId.current = true;
        onTimeExceeded();
      }
    }
  }, [onTimeExceeded, timePassed, limit, clearTimer]);

  if (limit === null) return null;

  const almostExpired = limit - timePassed <= 15;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 24
      }}
    >
      <Typography style={{ marginRight: 10 }}>Time Remaining:</Typography>
      <Typography
        style={{ fontSize: 16, fontFamily: 'monospace' }}
        variant="overline"
        color={almostExpired ? 'error' : 'textPrimary'}
      >
        {formatSeconds(limit - timePassed)}
      </Typography>
    </div>
  );
}
