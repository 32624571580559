import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  ListItem,
  ListItemIcon,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import { GroupedColumns } from 'components';
import { toDateString } from 'routes/ShiftSchedule/helpers/DateTime.helper';
import useProjectShiftsOnDays from '../hooks/useProjectShiftsOnDays.hook';
import ShiftCard from 'routes/ShiftSchedule/components/ShiftCard.component';
import { ShiftScheduleContext } from 'routes/ShiftSchedule/context/ShiftSchedule.context';
import { useColors } from 'helpers/theme.helper';
import AddIcon from '@material-ui/icons/Add';
import { ShiftModalContext } from 'routes/ShiftSchedule/context/ShiftModal.context';
import { ProjectsContext } from 'routes/ShiftSchedule/context/Projects.context';

export default function ShiftScheduleColumns({
  showLeadTechColumn,
  sortProjectIds
}) {
  const { projectMap } = useContext(ProjectsContext);
  const { dateWindow, hideWeekend } = useContext(ShiftScheduleContext);
  const { openWithProps } = useContext(ShiftModalContext);
  const [dayMoments, setDayMoments] = useState([]);
  const colors = useColors();

  useEffect(() => {
    let dayMoments = [moment(dateWindow[0])];
    for (
      let i = 1;
      dayMoments[dayMoments.length - 1].isBefore(dateWindow[1], 'day');
      i++
    ) {
      dayMoments.push(moment(dateWindow[0]).add(i, 'days'));
    }
    if (hideWeekend)
      dayMoments = dayMoments.filter(m => m.day() !== 0 && m.day() !== 6);

    setDayMoments(dayMoments);
  }, [dateWindow, hideWeekend]);

  const [projectShiftsOnDays, allProjectIds] = useProjectShiftsOnDays(
    dayMoments,
    sortProjectIds
  );

  const numDays = dayMoments.length;
  const groupPadding = 16;
  const flexCenter = { display: 'flex', justifyContent: 'center' };
  const ltColumnWidth = 200;
  const dayColumnWidth = !!showLeadTechColumn
    ? `calc((100% / ${numDays}) - (${ltColumnWidth}px / ${numDays}))`
    : `calc(100% / ${numDays})`;

  return (
    <GroupedColumns
      groupIds={['header', ...allProjectIds]}
      style={{
        width: `100%`,
        overflowY: 'auto',
        display: 'flex'
      }}
    >
      {!!showLeadTechColumn && (
        <GroupedColumns.Column style={{ width: ltColumnWidth, minWidth: 160 }}>
          <GroupedColumns.Group
            groupId="header"
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: colors.background,
              zIndex: 1
            }}
          >
            <div style={{ position: 'absolute', width: '100%', bottom: 0 }}>
              <Typography style={{ fontWeight: 500 }}>
                Lead Technician
              </Typography>
            </div>
          </GroupedColumns.Group>
          {allProjectIds.map(projectId => {
            const project = projectMap[projectId];
            if (!project) return null;
            return (
              <GroupedColumns.Group
                groupId={projectId}
                key={projectId}
                style={{ padding: '0 5px' }}
                innerProps={{ style: { position: 'sticky', top: 56 } }}
              >
                <div style={{ padding: `${groupPadding}px 0` }}>
                  <Divider />
                </div>
                {project.leadTechnicianName || 'No Lead Tech'}
              </GroupedColumns.Group>
            );
          })}
        </GroupedColumns.Column>
      )}
      {projectShiftsOnDays.map((projectShiftsOnDay, idx) => (
        <GroupedColumns.Column
          style={{ width: dayColumnWidth, minWidth: 160 }}
          key={toDateString(dayMoments[idx])}
        >
          <GroupedColumns.Group
            groupId="header"
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: colors.background,
              zIndex: 1
            }}
          >
            <div style={{ padding: '0 5px' }}>
              <div style={{ flex: '0 0 auto' }}>
                <Typography>
                  {moment(dayMoments[idx]).format('ddd Do')}
                </Typography>
              </div>
              <Paper style={{ flex: '0 0 auto' }}>
                <ListItem
                  dense
                  style={{ margin: 0, ...flexCenter }}
                  button
                  onClick={() => openWithProps({}, 'project_picker')}
                >
                  <ListItemIcon style={flexCenter}>
                    <AddIcon />
                  </ListItemIcon>
                </ListItem>
              </Paper>
            </div>
          </GroupedColumns.Group>
          {projectShiftsOnDay.map((projectShifts, idx) => (
            <GroupedColumns.Group
              groupId={projectShifts.projectId}
              key={projectShifts.projectId}
              style={{ padding: '0 5px' }}
              innerProps={{ style: { position: 'sticky', top: 56 } }}
            >
              <div style={{ padding: `${groupPadding}px 0` }}>
                <Divider />
              </div>
              {projectShifts.shifts.map((shift, idx) => (
                <React.Fragment key={shift.id}>
                  {idx !== 0 && <div style={{ marginTop: 10 }} />}
                  <ShiftCard shiftDay={shift} />
                </React.Fragment>
              ))}
            </GroupedColumns.Group>
          ))}
        </GroupedColumns.Column>
      ))}
    </GroupedColumns>
  );
}
