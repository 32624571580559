import { useEffect, useRef, useState } from 'react';

function useModified<T, V>(
  modifier: (arg: T) => V,
  val: T,
  otherDeps: Array<any> = []
): V {
  const setValRef = useRef(modifier);
  setValRef.current = modifier;
  const [stateVal, setStateVal] = useState(() => modifier(val));

  useEffect(() => {
    setStateVal(setValRef.current(val));
    // modifier & useEffect closures will always be up to date.
    // using otherDeps to recalculate value only if modifier requires it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, ...otherDeps]);

  return stateVal;
}

export default useModified;
