import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthenticatedRoute } from 'components';

import SignIn from './SignIn/SignIn.route';
import GoogleAuthCallback from './GoogleAuthCallback/GoogleAuthCallback.route';
import ForgotPassword from './ForgotPassword/ForgotPassword.route';
import ResetPassword from './ResetPassword/ResetPassword.route';
import Home from './Home/Home.route';
import DrugTestLocation from './DrugTestLocation/DrugTestLocation.route';
import Onboarding from './Onboarding';
import Users from './Users';
import Themes from './Themes';
import AdminSettings from './AdminSettings/AdminSettings.route';
import Companies from './Companies/index.js';
import People from './People';
import CatchAll from './CatchAll/CatchAll.route';
import Projects from './Projects';
import ProjectBoard from './ProjectBoard/ProjectBoard.route';
import ActionItems from './ActionItems/ActionItems.route';
import Esign from './Esign/Esign.route';
import QuoteApproval from './QuoteApproval/QuoteApproval.route';
import MultiScopeQuoteApproval from './QuoteApproval/MultiScopeQuoteApproval.route';
import Messaging from './Messaging/Messaging.route';
import MasterSchedule from './MasterSchedule/MasterSchedule.route';
import EditDrugTestLocations from './EditDrugTestLocations/EditDrugTestLocations.route';
import PerDiem from './PerDiem';
import PrintRfi from './Rfi/PrintRfi.route';
import TrainingGroups from './TrainingGroups/TrainingGroups.route';
import TrainingQuizzes from './TrainingQuizzes';
import ProjectChartsRoute from './ProjectCharts/ProjectCharts.route';
import ProjectCompletionTable from './ProjectCompletionTable';
import ShiftSchedule from './ShiftSchedule/routes';
import MaterialsRoute from './Materials';

const IndexRoute = () => {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={SignIn} />
      <AuthenticatedRoute
        path="/google-auth-callback"
        exact
        component={GoogleAuthCallback}
      />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route
        path="/onboarding/drug-test-location"
        exact
        component={DrugTestLocation}
      />
      <Route path="/esign" component={Esign} />
      <Route path="/quote-approval" component={QuoteApproval} />
      <Route path="/multi-quote-approval" component={MultiScopeQuoteApproval} />
      <AuthenticatedRoute path="/onboarding" secure component={Onboarding} />
      <AuthenticatedRoute path="/home" secure exact component={Home} />
      <AuthenticatedRoute path="/users" secure component={Users} />
      <AuthenticatedRoute path="/themes" secure component={Themes} />
      <AuthenticatedRoute path="/companies" secure component={Companies} />
      <AuthenticatedRoute path="/people" secure component={People} />
      <AuthenticatedRoute path="/materials" secure component={MaterialsRoute} />
      <AuthenticatedRoute
        path="/admin-settings"
        secure
        component={AdminSettings}
      />
      <AuthenticatedRoute path="/projects" secure component={Projects} />
      <AuthenticatedRoute
        path="/project-schedule"
        secure
        exact
        component={MasterSchedule}
      />
      <AuthenticatedRoute
        path="/project-board"
        secure
        exact
        component={ProjectBoard}
      />
      <AuthenticatedRoute
        path="/action-items"
        secure
        exact
        component={ActionItems}
      />
      <AuthenticatedRoute
        path="/messaging"
        secure
        exact
        component={Messaging}
      />
      <AuthenticatedRoute
        path="/edit-drug-test-locations"
        secure
        exact
        component={EditDrugTestLocations}
      />
      <AuthenticatedRoute path="/per-diems" secure component={PerDiem} />
      <AuthenticatedRoute
        path="/training-groups"
        secure
        component={TrainingGroups}
      />
      <AuthenticatedRoute
        path="/training-quizzes"
        secure
        component={TrainingQuizzes}
      />
      <AuthenticatedRoute
        path="/shift-schedule"
        secure
        component={ShiftSchedule}
      />

      <AuthenticatedRoute path="/rfi" secure component={PrintRfi} />
      <AuthenticatedRoute
        path="/wip-report"
        secure
        component={ProjectChartsRoute}
      />
      <AuthenticatedRoute
        path="/project-completions"
        secure
        component={ProjectCompletionTable}
      />
      <Route path="/404" component={CatchAll} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default IndexRoute;
