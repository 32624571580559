import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import moment from 'moment';
// import { useUsers } from 'hooks/useUsers.hook';

export default function SmsQueueModal({ onClose, open }) {
  const [messages, setMessages] = useState([]);
  // const { users } = useUsers();

  const getMessages = useCallback(() => {
    if (open)
      http()
        .get('/sms-queue')
        .then(setMessages)
        .catch(Toast.showErr);
  }, [open]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Sms Queue</DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Paper elevation={0} style={{ position: 'sticky', top: 0 }}>
          <Message
            noFormat
            message={{
              created: 'Created At',
              attempted: 'Attempted At',
              attemptCount: 'Attempt Count',
              sent: 'Sent At',
              to: 'To',
              text: 'Text'
            }}
          />
        </Paper>
        {messages.map(message => (
          <Message key={message.id} message={message} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} autoFocus>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={getMessages}
          color="primary"
          autoFocus
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const time = dateString => moment(dateString).format('M/D h:mm:ss a');

const Message = ({ message, noFormat = false }) => {
  const format = v => (noFormat ? v : v ? time(v) : 'N / A');
  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="caption">{format(message.created)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">{format(message.attempted)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">{message.attemptCount}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">{format(message.sent)}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">{message.to}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          style={{
            width: '100%',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          variant="caption"
          noWrap
        >
          {message.text}
        </Typography>
      </Grid>
    </Grid>
  );
};
