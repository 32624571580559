import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import http from 'helpers/http.helper';
import {
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Paper,
  Switch,
  FormControlLabel
} from 'helpers/themeSafeMui.helper';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Toast } from 'components';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';

function SendEmail(props) {
  const { project, onClose, replyingTo } = props;
  const [subject, setSubject] = useState(replyingTo ? replyingTo.subject : '');
  const [content, setContent] = useState('');
  const [selectedEmails, setSelectedEmails] = useState([]);
  const adminSettings = useSelector(state => state.adminSettings);
  const user = useSelector(state => state.user);
  const [people, setPeople] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [useSignature, setUseSignature] = useLocalStorage(
    'email.useSignature',
    false,
    joi => joi.boolean()
  );

  useEffect(() => {
    if (project) {
      Promise.all([http().get('/people'), http().get('/users')])
        .then(([people, users]) => {
          if (project.companyId !== adminSettings.sudoCompanyId) {
            const active = people.filter(person => !person.inactive);
            setPeople(
              active.filter(person => person.companyId === project.companyId)
            );
          } else {
            setPeople(people);
          }
          setUsers(users);
        })
        .catch(err => Toast.show(err.message));
    }
  }, [project, adminSettings.sudoCompanyId]);

  useEffect(() => {
    setSelectedEmails(
      replyingTo
        ? [
            {
              name: 'Sender',
              address: replyingTo.senderEmail,
              type: 'person'
            }
          ]
        : []
    );
  }, [setSelectedEmails, replyingTo]);

  const send = async () => {
    try {
      await http().post(`/projects/${project.id}/emails/send`, {
        subject,
        body: content,
        useSignature,
        to: selectedEmails.map(e => e.address)
      });
      Toast.show('Message sent');
    } catch (err) {
      Toast.show(err.message);
      onClose();
    }
  };

  const emailSelected = (e, values) => {
    setSelectedEmails(values.filter(v => v.address));
  };

  const onAutoselectClose = e => {
    try {
      const { value } = e.target;
      if (value.trim() === '') return;
      if (value.search(/\S+@\S+\.\S/) === -1)
        return Toast.show('Not a valid email');
      setSelectedEmails([
        ...selectedEmails,
        {
          name: value.trim(),
          address: value.trim(),
          type: 'Work'
        }
      ]);
    } catch (err) {}
  };

  const peopleEmails =
    people &&
    people
      .map(person =>
        person.emails.map(email => ({
          name: person.name,
          address: email.email,
          type: 'person'
        }))
      )
      .flat();

  const userEmails =
    users &&
    users.map(user => ({
      name: user.firstName,
      address: user.email,
      type: 'user'
    }));

  const allEmails = peopleEmails &&
    userEmails && [...peopleEmails, ...userEmails];

  const sender = replyingTo
    ? [
        {
          name: 'Sender',
          address: replyingTo.senderEmail,
          type: 'person'
        }
      ]
    : [];

  allEmails && allEmails.push(...sender);

  const emailForm = (
    <form
      style={{
        maxHeight: '100%',
        width: replyingTo ? '50%' : null,
        overflowY: 'auto',
        padding: '0.5em'
      }}
      autoComplete="off"
      onSubmit={async e => {
        e.preventDefault();
        setDisabled(true);
        await send();
        onClose();
      }}
    >
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new Email, enter a subject, select the recipients, and fill
          out the email content.
        </DialogContentText>

        <TextField
          autoFocus
          label="Subject"
          fullWidth
          required
          value={subject}
          onChange={e => setSubject(e.target.value)}
          margin="normal"
          disabled={!!replyingTo || disabled}
        />

        <Autocomplete
          multiple
          options={allEmails || []}
          getOptionLabel={option => `${option.name} <${option.address}>`}
          getOptionSelected={(option, other) =>
            option.address === other.address && option.name === other.name
          }
          value={selectedEmails}
          disabled={disabled}
          onChange={emailSelected}
          defaultValue={sender}
          freeSolo
          onClose={onAutoselectClose}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Recipients"
                fullWidth
                margin="normal"
                type="email"
              />
            );
          }}
        />

        <TextField
          label="Email Body"
          multiline
          fullWidth
          required
          value={content}
          onChange={e => setContent(e.target.value)}
          disabled={disabled}
        />

        <div
          style={{
            padding: '10px 0'
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={useSignature}
                onChange={() => setUseSignature(!useSignature)}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Use Signature"
          />
        </div>

        <br />
        {useSignature && (
          <Typography variant="caption">
            {user.name}
            <br />
            {user.jobTitle} | {user.phoneNumber} | {user.email}
            <br />
            Integrated Technology Solutions | systemtech.us
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={disabled} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          type="submit"
        >
          Send
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
      {emailForm}
      {replyingTo && (
        <div
          style={{
            width: '50%',
            maxHeight: '100%',
            overflowY: 'auto',
            padding: '0.5em',
            position: 'relative'
          }}
        >
          <Paper
            style={{
              padding: '3em 1em 0.25em',
              position: 'sticky',
              top: '-2em'
            }}
          >
            <DialogContentText style={{ marginBottom: 0 }}>
              Responding To:
            </DialogContentText>
          </Paper>
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{ __html: replyingTo.emailContent }}
          />
        </div>
      )}
    </div>
  );
}

export default SendEmail;
