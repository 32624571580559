import React, { useState, useEffect } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  Tooltip,
  Badge
} from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';

const ChangeLog = React.forwardRef(({ color = null }, ref) => {
  const [open, setOpen] = useState(false);
  const [cacheBuster, setCacheBuster] = useState(random);
  const [newVersion, onOpen] = useLastOpened();
  const colors = useColors();

  useEffect(() => {
    setCacheBuster(random());
  }, [open]);

  return (
    <>
      <Tooltip title="Change Log">
        <IconButton
          ref={ref}
          onClick={() => {
            onOpen();
            setOpen(true);
          }}
          style={{ color: color || colors.primaryContrastText }}
        >
          <Badge
            overlap="rectangular"
            badgeContent={newVersion ? 1 : 0}
            color="error"
          >
            <InfoIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Change Log</DialogTitle>
        <Divider />
        <iframe
          style={{ height: '60vh' }}
          width="100%"
          frameBorder="0"
          title="change log"
          src={`https://docs.google.com/document/d/e/2PACX-1vRDT17b20GRGAQjfz3uZJbwx5IYFrrWdO90icga0rZENx5v_Xn-NMBPcZ81S6IqM6nL5cylnE_0Q1vL/pub?embedded=true&random=${cacheBuster}`}
        />
      </Dialog>
    </>
  );
});

function useLastOpened() {
  const [newVersion, setNewVersion] = useState(() => {
    const currentVersion = window.sessionStorage.getItem('stepsVersion');
    let lastOpenedVersion = window.localStorage.getItem(
      'lastOpenedChangeLogVersion'
    );
    if (!lastOpenedVersion) {
      window.localStorage.setItem('lastOpenedChangeLogVersion', currentVersion);
      lastOpenedVersion = currentVersion;
    }

    return lastOpenedVersion !== currentVersion;
  });
  const onOpen = () => {
    const currentVersion = window.sessionStorage.getItem('stepsVersion');
    window.localStorage.setItem('lastOpenedChangeLogVersion', currentVersion);
    setNewVersion(false);
  };

  return [newVersion, onOpen];
}

function random() {
  return Math.floor(Math.random() * 1000);
}

export default ChangeLog;
