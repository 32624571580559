import React from 'react';
import moment from 'moment';
import checkboxBase64 from 'assets/checkbox.base64';

const Field = props => {
  const {
    field,
    onChange,
    openSignatureModal,
    signatureImage,
    initialsImage
  } = props;

  const handleChange = e => {
    // Stop overflow from happening.
    if (
      (e.target.scrollHeight > e.target.clientHeight &&
        e.target.value.split('\n').length > 1) ||
      e.target.scrollWidth > e.target.clientWidth
    ) {
      return;
    }

    onChange(e.target.value);
  };

  const styles = {
    height: field.height,
    width: field.width,
    top: field.y,
    left: field.x,
    position: 'absolute',
    background: field.value
      ? 'transparent'
      : field.required
      ? 'rgba(255, 0, 0, 0.4)'
      : 'rgba(51, 204, 51, 0.5)',
    zIndex: 1,
    overflow: 'hidden',
    textAlign: field.textAlign,
    fontFamily: 'Courier New',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    outline: 'none'
  };

  const actionStyles = !field.value
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: styles.height / 2
      }
    : {};

  const imageStyles = { maxHeight: '100%', maxWidth: '100%' };

  // Handles required validation.
  const Hidden = () => (
    <input
      value={field.value || ''}
      onChange={() => {}}
      style={{ opacity: 0, pointerEvents: 'none', width: 0, height: 0 }}
      required={field.required}
      tabIndex={-1}
    />
  );

  if (field.type === 'text_field') {
    return (
      <textarea
        required={field.required}
        value={field.value || ''}
        onChange={handleChange}
        style={{
          ...styles,
          resize: 'none',
          border: 'none',
          fontSize: field.fontSize
        }}
      />
    );
  } else if (field.type === 'auto_date') {
    return (
      <div
        tabIndex={0}
        style={{
          ...styles,
          ...actionStyles,
          fontSize: field.fontSize
        }}
        onKeyDown={e =>
          e.key === 'Enter' ? onChange(moment().format('L')) : null
        }
        onClick={() => onChange(moment().format('L'))}
      >
        {field.value ? field.value : 'Click to Date'}
        <Hidden />
      </div>
    );
  } else if (field.type === 'signature') {
    return (
      <div
        tabIndex={0}
        style={{
          ...styles,
          ...actionStyles
        }}
        onKeyDown={e =>
          e.key === 'Enter'
            ? signatureImage
              ? onChange(signatureImage)
              : openSignatureModal()
            : null
        }
        onClick={
          signatureImage ? () => onChange(signatureImage) : openSignatureModal
        }
      >
        {field.value ? (
          <img alt="signature" src={field.value} style={imageStyles} />
        ) : (
          'Click to Sign'
        )}
        <Hidden />
      </div>
    );
  } else if (field.type === 'initials') {
    return (
      <div
        tabIndex={0}
        style={{
          ...styles,
          ...actionStyles
        }}
        onKeyDown={e =>
          e.key === 'Enter'
            ? initialsImage
              ? onChange(initialsImage)
              : openSignatureModal()
            : null
        }
        onClick={
          initialsImage ? () => onChange(initialsImage) : openSignatureModal
        }
      >
        {field.value ? (
          <img alt="initials" src={field.value} style={imageStyles} />
        ) : (
          'Initials'
        )}
        <Hidden />
      </div>
    );
  } else if (field.type === 'checkbox') {
    return (
      <div
        tabIndex={0}
        style={{
          ...styles,
          cursor: 'pointer'
        }}
        onKeyDown={e =>
          e.key === 'Enter' ? onChange(field.value ? '' : checkboxBase64) : null
        }
        onClick={() => onChange(field.value ? '' : checkboxBase64)}
      >
        {Boolean(field.value) && (
          <img alt="checkbox" src={field.value} style={imageStyles} />
        )}
        <Hidden />
      </div>
    );
  } else {
    console.error('Field not rendered.', field);
    return null;
  }
};

export default Field;
