import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  IconButton,
  Paper,
  useTheme
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import NotificationGroup from '../components/NotificationGroup.component';
import { Toast } from 'components';
import GroupUsers from '../components/NotificationGroupResultUsers.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const NotificationGroups = () => {
  const [jobTitles, setJobTitle] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [groupUsers, setGroupUsers] = useState({});
  const [open, setOpen] = useState(null);
  const theme = useTheme();

  const handleErr = err => Toast.show(err.message);

  useEffect(() => {
    http()
      .get('/notification-groups')
      .then(groups => {
        setGroups(groups);
      })
      .catch(handleErr);
    http()
      .get('/permission-levels')
      .then(jobTitles => {
        setJobTitle(jobTitles);
      })
      .catch(handleErr);
    http()
      .get('/permission-levels/permissions')
      .then(permissions => {
        setPermissions(permissions);
      })
      .catch(handleErr);
    http()
      .get('/notification-groups/types')
      .then(types => {
        setNotificationTypes(types);
      })
      .catch(handleErr);
    http()
      .get('/users')
      .then(users => {
        setAllUsers(users.filter(u => u.employmentStatus !== 'Inactive'));
      })
      .catch(handleErr);
  }, []);

  const saveGroup = group => updates => {
    return http()
      .put(`/notification-groups/${group.id}`, updates)
      .then(update)
      .catch(handleErr);
  };

  const removeGroup = group => () => {
    return http()
      .delete(`/notification-groups/${group.id}`)
      .then(() => {
        update(group, true);
      })
      .catch(handleErr);
  };

  const addGroup = type => () => {
    return http()
      .post('/notification-groups', { type })
      .then(update)
      .then(() => setOpen(type))
      .catch(handleErr);
  };

  const update = (updated, remove = false) => {
    const copy = [...groups];
    const idx = copy.indexOf(copy.find(group => group.id === updated.id));
    if (remove) {
      copy.splice(idx, 1);
    } else if (idx === -1) {
      copy.splice(0, 0, updated);
    } else {
      copy.splice(idx, 1, updated);
    }
    fetchGroupUsers(updated.type);
    setGroups(copy);
  };

  const expand = type => () => {
    if (open === type) return setOpen(null);
    setOpen(type);
  };

  const fetchGroupUsers = useCallback(type => {
    http()
      .get(`/notification-groups/users/${type}`)
      .then(userIds => {
        setGroupUsers(groupUsers => ({ ...groupUsers, [type]: userIds }));
      });
  }, []);

  useEffect(() => {
    if (open && !groupUsers[open]) fetchGroupUsers(open);
  }, [open, groupUsers, fetchGroupUsers]);

  return (
    <>
      <Typography component="h1" variant="h5" gutterBottom>
        Notification Groups
      </Typography>
      {notificationTypes
        .map(notificationType => [
          <Paper
            key={notificationType.name}
            style={{ padding: 10 }}
            elevation={3}
          >
            <Typography
              variant="h5"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: open === notificationType.value ? null : 'pointer'
              }}
              onClick={() => {
                if (open !== notificationType.value)
                  expand(notificationType.value)();
              }}
            >
              {notificationType.name}
              <div>
                {open === notificationType.value && (
                  <IconButton onClick={addGroup(notificationType.value)}>
                    <AddIcon />
                  </IconButton>
                )}
                <IconButton onClick={expand(notificationType.value)}>
                  {open === notificationType.value ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
            </Typography>
            {open === notificationType.value && (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: theme.typography.pxToRem(300),
                    position: 'relative'
                  }}
                >
                  <GroupUsers
                    allUsers={allUsers}
                    ids={groupUsers[notificationType.value] || []}
                  />
                </div>
                <div
                  style={{
                    flex: `1 0 calc(100% - ${theme.typography.pxToRem(300)})`
                  }}
                >
                  {(() => {
                    const filtered = groups.filter(
                      g => g.type === notificationType.value
                    );
                    if (!filtered.length) return <NoGroupResult />;
                    return filtered
                      .map((group, idx, filtered) => [
                        <NotificationGroup
                          key={group.id}
                          group={group}
                          jobTitles={jobTitles}
                          permissions={permissions}
                          saveGroup={saveGroup(group)}
                          removeGroup={removeGroup(group)}
                          allUsers={allUsers}
                          number={filtered.length - idx}
                        />,
                        <div
                          style={{ marginTop: 10 }}
                          key={group.id + ' gap'}
                        />
                      ])
                      .flat()
                      .slice(0, -1);
                  })()}
                </div>
              </div>
            )}
          </Paper>,
          <div
            style={{ marginTop: 10 }}
            key={notificationType.value + ' gap'}
          />
        ])
        .flat()
        .slice(0, -1)}
    </>
  );
};

function NoGroupResult() {
  return (
    <Paper style={{ padding: 10, paddingTop: 30, paddingBottom: 30 }}>
      <Typography variant="subtitle1">No Filters Applied</Typography>
    </Paper>
  );
}

export default NotificationGroups;
