import React from 'react';
import { Link } from 'helpers/themeSafeMui.helper';

const ExternalLink = ({ href, children, ...props }) => {
  let formattedHref = href;

  if (
    !(
      href.startsWith('http') ||
      href.startsWith('mailto') ||
      href.startsWith('tel')
    )
  ) {
    formattedHref = `https://${href}`;
  }

  return (
    <Link
      {...props}
      onClick={e => e.stopPropagation()}
      target="_blank"
      rel="noopener noreferrer"
      href={formattedHref}
    >
      {children}
    </Link>
  );
};

export default ExternalLink;
