import { Toast } from 'components';
import http from 'helpers/http.helper';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from 'helpers/themeSafeMui.helper';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

export default function StatusMerge() {
  const statuses = useSelector(state => state.projectStatuses);
  const [projectMap, setProjectMap] = useState({});
  const [fromId, setFromId] = useState('');
  const [toId, setToId] = useState('');

  const fetchProjects = useCallback(() => {
    return http()
      .get('/projects')
      .then(projects => {
        const map = {};
        projects.forEach(project => {
          if (map[project.statusId]) {
            map[project.statusId].push(project);
          } else {
            map[project.statusId] = [project];
          }
        });
        setProjectMap(map);
      });
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const mergeStatuses = async () => {
    const projects = projectMap[fromId];
    try {
      await Promise.all(
        projects.map(({ id }) =>
          http().put(`/projects/${id}`, { statusId: toId })
        )
      );
      await fetchProjects();
      setFromId('');
      setToId('');
      Toast.show('Merge succeeded');
    } catch (err) {
      Toast.showErr(err);
    }
  };

  const projectCountText = status => {
    if (projectMap[status.id] === undefined) return '0 projects';
    if (projectMap[status.id].length === 1) return '1 project';
    return `${projectMap[status.id].length} projects`;
  };

  const statusesSelected = fromId && toId;

  const selectOptions = [
    { value: '', label: '---' },
    ...statuses.map(status => ({
      value: status.id,
      label: status.status,
      description: projectCountText(status)
    }))
  ];

  return (
    <div>
      <div style={{ paddingTop: 32 }} />
      <Divider />
      <div style={{ paddingTop: 32 }} />

      <Typography component="h1" variant="h5">
        Status Merge
      </Typography>

      <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
        <Typography>Move all projects from status</Typography>
        <SelectWithOptionDescription
          style={{ minWidth: 100, margin: '0 10px' }}
          value={fromId}
          onChange={setFromId}
          options={selectOptions}
        />

        <Typography>to status</Typography>
        <SelectWithOptionDescription
          style={{ minWidth: 100, margin: '0 10px' }}
          value={toId}
          onChange={setToId}
          options={selectOptions}
        />
      </div>

      {statusesSelected && (
        <Typography
          component="div"
          style={{ marginBottom: 5 }}
          color="error"
          variant="caption"
        >
          {projectCountText(statuses.find(s => s.id === fromId))} will be
          affected. This action cannot be undone.
        </Typography>
      )}

      <Button
        onClick={mergeStatuses}
        variant="contained"
        color="primary"
        disabled={!statusesSelected}
      >
        Merge Statuses
      </Button>
    </div>
  );
}

function SelectWithOptionDescription({
  value,
  label,
  options,
  onChange,
  style
}) {
  const [id] = useState(() => uuid());
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <FormControl style={style}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        MenuProps={{ MenuListProps: { style: { padding: 0 } } }}
        labelId={id}
        value={value}
        onChange={handleChange}
      >
        {options.map(({ value, label, description }) => (
          <MenuItem dense key={label} value={value}>
            <ListItemText
              style={{ marginTop: 0, marginBottom: 0 }}
              primary={label}
              secondary={description}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
