import React, { useContext, useState } from 'react';
import { Button, Typography } from 'helpers/themeSafeMui.helper';
import { ChildProjectsContext } from '../../../context/ChildProjectsContext.context';
import {
  ProjectContext,
  ProjectTeamContext
} from 'routes/Projects/context/Project.context';
import { useDispatch } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export default function ChildProjectsForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { project: currentProject } = useContext(ProjectContext);
  const { projectTeam: currentProjectTeam } = useContext(ProjectTeamContext);
  const [selectedProject, setSelectedProject] = useState(null);
  const currentUser = useCurrentUser();
  const { fetchRelatedProjects, addToProjectMap } = useContext(
    ChildProjectsContext
  );

  function createProject() {
    const onCreate = newProject => {
      setLoading(true);
      addToProjectMap(newProject);
      setSelectedProject(null);
      fetchRelatedProjects();
      setLoading(false);
      return;
    };
    dispatch(
      showModal({
        type: 'CREATE_PROJECT',
        props: {
          companyId: currentProject.companyId,
          personId: currentProjectTeam.customerInitialContactId,
          noRedirect: true,
          parentId: currentProject.id,
          onCreate
        }
      })
    );
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Add Child Project
      </Typography>
      {currentUser.hasPermission('projects_tier_1.5') && (
        <Button
          onClick={createProject}
          style={{ marginBottom: 16 }}
          variant="contained"
          disabled={selectedProject || loading}
        >
          Create New Project as child
        </Button>
      )}
    </div>
  );
}
