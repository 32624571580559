import React, { useState } from 'react';
import { Paper, Typography, IconButton } from 'helpers/themeSafeMui.helper';
import moment from 'moment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const QuickbooksImportItem = ({
  upload: { created, updated, failed, createdByName, uploadType }
}) => {
  const [open, setOpen] = useState(false);
  const listStyle = {
    flex: '0 1 100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: 'rgba(128, 128, 128, 0.2)',
    padding: 10
  };
  return (
    <Paper elevation={2} style={{ padding: '0 10px', marginTop: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '0 1 100%' }}>
          <Typography variant="caption">
            {uploadType} - {moment(created).format('ll')} - {createdByName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      {open && (
        <div style={{ display: 'flex', height: 200, paddingBottom: 10 }}>
          <div style={listStyle}>
            <Typography variant="body1">Updated</Typography>
            {updated.map(updated => (
              <Typography key={updated} variant="caption" component="p">
                {updated}
              </Typography>
            ))}
          </div>
          <div style={{ width: 20 }}></div>
          <div style={listStyle}>
            <Typography variant="body1">Failed</Typography>
            {failed.map(failed => (
              <Typography key={failed} variant="caption" component="p">
                {failed}
              </Typography>
            ))}
          </div>
        </div>
      )}
    </Paper>
  );
};

export default QuickbooksImportItem;
