import React, { useState, useContext, useEffect } from 'react';
import { Toast, CustomInput } from 'components';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import {
  ServiceItemsContext,
  ProjectDetailsContext,
  ProjectContext
} from 'routes/Projects/context/Project.context';
import ServiceItemsModal from '../../modals/ServiceItems.modal';
import { useSetActionItem } from 'state/actionItems.state';

const defaults = [
  { number: 300, name: 'Drive Time', isSpecialWage: false },
  { number: 568, name: 'Reg Tech', isSpecialWage: false },
  { number: 900, name: 'Office Hourly', isSpecialWage: false },
  { number: 915, name: 'PM Hourly', isSpecialWage: false }
];

const TSheets = ({ prev, next, notifyOM, setNotifyOM }) => {
  const { project } = useContext(ProjectContext);
  const { serviceItems, setServiceItem } = useContext(ServiceItemsContext);
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const setActionItem = useSetActionItem();
  const [perDiem, setPerDiem] = useState(projectDetails.perDiem);
  const [extraNotes, setExtraNotes] = useState('');
  const [withPerDiem, setWithPerDiem] = useState(projectDetails.perDiem > 0);
  const [defaultsSet, setDefaultsSet] = useState(false);

  const save = async e => {
    try {
      e.preventDefault();

      await http()
        .put(`/projects/${project.id}/details`, {
          perDiem: withPerDiem ? perDiem : 0
        })
        .then(res => setProjectDetails(res));

      const hrUsers = await http().get('/human-resources');

      await http()
        .post('/action-items', {
          projectId: project.id,
          title: `Add QB/TSheets: ${project.projectNumber} - ${project.name}`,
          notes: makeNote(),
          dueDate: new Date(),
          duration: 0,
          actionItemLink: `/projects/${project.id}`,
          assignedUserIds: hrUsers.map(el => el.userId)
        })
        .then(actionItem => setActionItem(actionItem));

      next();
    } catch (err) {
      Toast.show(err.message);
    }
  };

  function makeNote() {
    let note = `Service Items: ${serviceItems.map(i => i.number).join(', ')}`;
    const specialWages = serviceItems.filter(i => i.isSpecialWage);
    for (let special of specialWages) {
      note += `\n${special.number} Special Wage:`;
      if (special.base) note += ` Base $${special.base}.`;
      if (special.fringe) note += ` Fringe $${special.fringe}.`;
    }
    if (withPerDiem) note += `\nPer Diem: $${perDiem}`;
    if (extraNotes) note += `\nNotes: ${extraNotes}`;
    return note;
  }

  useEffect(() => {
    Promise.all(
      defaults.map(item =>
        http()
          .post(`/projects/${project.id}/service-items`, item)
          .then(setServiceItem)
          .catch(Toast.showErr)
      )
    ).then(() => setDefaultsSet(true));
  }, [project.id, setServiceItem]);

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle style={{ textAlign: 'center' }}>TSheets</DialogTitle>
      <DialogContent>
        {defaultsSet ? <ServiceItemsModal contentOnly /> : <CircularProgress />}

        <FormControlLabel
          style={{ display: 'block' }}
          label="Per Diem"
          control={
            <Checkbox
              onChange={e => {
                setWithPerDiem(e.target.checked);
                if (e.target.checked && perDiem <= 0) setPerDiem(50);
              }}
              checked={withPerDiem}
            />
          }
        />

        {withPerDiem && (
          <CustomInput
            type="currency"
            value={perDiem}
            required
            label="Per Diem Amount"
            onChange={setPerDiem}
          />
        )}

        <CustomInput
          type="boolean"
          label="Notify Operations Manager"
          value={notifyOM}
          onChange={setNotifyOM}
        />

        <TextField
          label="Notes"
          value={extraNotes}
          fullWidth
          onChange={e => setExtraNotes(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="contained" onClick={prev}>
          Back
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Next
        </Button>
      </DialogActions>
    </form>
  );
};

export default TSheets;
