import useModified from 'hooks/useModified.hook';
import moment, { Moment } from 'moment';

interface UseTaskTechsOnDaysProps {
  tasks: any[];
  startDay: Moment;
  colCount: number;
}

const useTaskTechsOnDays = ({
  tasks,
  startDay,
  colCount
}: UseTaskTechsOnDaysProps) => {
  const techsOnDays = useModified(
    tasks => {
      const techsOnDays: number[] = Array(colCount)
        .fill(null)
        .map((n, idx) => {
          const currentDay = moment(startDay).add(idx, 'days');
          return tasks.reduce(
            (sum: number, task) =>
              currentDay.isBetween(task.startDate, task.endDate, 'days', '[]')
                ? task.techCount + sum
                : sum,
            0
          );
        });
      return techsOnDays;
    },
    tasks,
    [startDay, colCount]
  );

  return techsOnDays;
};

export default useTaskTechsOnDays;
