import React, { useState } from 'react';
import {
  Popover,
  Button,
  ListItemText,
  ListItem,
  ListItemIcon,
  Typography,
  Divider
} from 'helpers/themeSafeMui.helper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function ColumnSelector({
  allColumns,
  selected,
  onSelectedChange
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = e => setAnchorEl(e.currentTarget);

  const handleColumnClick = column => () => {
    if (selected.includes(column.key)) {
      onSelectedChange(selected.filter(col => col !== column.key));
    } else {
      onSelectedChange([...selected, column.key]);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleClick}>
        Select Columns
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div style={{ minWidth: 300 }}>
          <Typography style={{ margin: 10 }} variant="h6">
            Selected Columns
          </Typography>
          <Divider />
          {allColumns.map((column, idx) => (
            <ListItem
              button
              disabled={idx === 0}
              key={column.key}
              onClick={handleColumnClick(column)}
            >
              <ListItemIcon>
                {selected.includes(column.key) ? (
                  <CheckBoxIcon color="primary" />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={column.label} />
            </ListItem>
          ))}
        </div>
      </Popover>
    </>
  );
}
