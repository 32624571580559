import React from 'react';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';
import { Typography, IconButton } from 'helpers/themeSafeMui.helper';
import { Toast, DragAndDrop } from 'components';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  getProjectStatuses,
  setProjectStatuses
} from 'ducks/projectStatuses.duck';

class ProjectStatuses extends React.Component {
  handleDrop = projectStatuses => {
    this.props.setProjectStatuses(projectStatuses);

    Promise.all(
      projectStatuses.map((projectStatus, idx) =>
        http().put(`/project-statuses/${projectStatus.id}`, {
          order: idx + 1
        })
      )
    )
      .then(() => Toast.show('Updated.'))
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { projectStatuses } = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" gutterBottom>
          Project Statuses
          <IconButton
            color="primary"
            style={{ marginLeft: 10, padding: 0 }}
            onClick={() =>
              this.props.showModal({
                type: 'CREATE_PROJECT_STATUS',
                props: {
                  refresh: this.props.getProjectStatuses,
                  nextSpot: projectStatuses.length + 1
                }
              })
            }
          >
            <AddIcon />
          </IconButton>
        </Typography>

        <DragAndDrop onDrop={this.handleDrop}>
          {projectStatuses.map(status => (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              key={status.id}
              id={status.id}
              value={status}
            >
              <Typography>{status.status}</Typography>
              <EditIcon
                style={{ marginLeft: 10, cursor: 'pointer' }}
                color="action"
                fontSize="small"
                onClick={() =>
                  this.props.showModal({
                    type: 'EDIT_PROJECT_STATUS',
                    props: {
                      status: status,
                      refresh: this.props.getProjectStatuses,
                      projectStatuses: projectStatuses
                    }
                  })
                }
              />
            </div>
          ))}
        </DragAndDrop>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ user: state.user, projectStatuses: state.projectStatuses }),
  { showModal, getProjectStatuses, setProjectStatuses }
)(ProjectStatuses);
