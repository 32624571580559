import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  CircularProgress,
  Paper
} from 'helpers/themeSafeMui.helper';
import { MaterialCard, Toast } from 'components';
import { Button } from '@material-ui/core';
import http from 'helpers/http.helper';

export default function MasterMaterialModal({ open, onClose, materialId }) {
  const [material, setMaterial] = useState(null);

  useEffect(() => {
    if (open && materialId) {
      setMaterial([]);
      http()
        .get(`/materials/${materialId}/replaced`)
        .then(setMaterial)
        .catch(Toast.showErr);
    }
  }, [materialId, open]);

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Material details on master list:</DialogTitle>
      <Divider />

      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 8,
            flex: '0 0 auto'
          }}
        >
          <Paper elevation={2}>
            {!material ? (
              <div
                style={{
                  width: 300,
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 16
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <MaterialCard
                style={{ width: 300 }}
                material={material}
                hiddienFields={['quantity']}
              />
            )}
          </Paper>
        </div>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
