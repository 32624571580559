import React from 'react';
import { Toast, SearchDropdown } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

export default class NewHRUser extends React.Component {
  initialState = {
    accountManager: null,
    name: '',
    managerNumber: 0,
    users: []
  };

  state = this.initialState;

  handleEnter = () => {
    http()
      .get('/users')
      .then(response => {
        this.setState({
          ...this.initialState,
          users: response
        });
      })
      .catch(err => Toast.show(err.message));
  };

  save = e => {
    e.preventDefault();

    http()
      .post('/human-resources', {
        userId: this.state.selected.id
      })
      .then(res => {
        Toast.show(
          `${res.name} was added to the system as a Human Resources Officer.`
        );
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: this.handleEnter }}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <form autoComplete="off" onSubmit={this.save}>
          <DialogTitle>New Human Resources Officer</DialogTitle>
          <DialogContent>
            <SearchDropdown
              fullWidth
              margin="normal"
              label="User"
              itemToString={item => item.name}
              items={this.state.users}
              onChange={selected => this.setState({ selected })}
              value={this.state.selected}
              required
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
