import React from 'react';
import {
  Paper,
  Typography,
  IconButton,
  Button,
  Divider
} from 'helpers/themeSafeMui.helper';

import { Toast } from 'components';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import NewIcon from '@material-ui/icons/AddCircleOutline';
import NewAnnouncementModal from '../modals/NewAnnouncement.modal';
import ViewAnnouncementsModal from '../modals/ViewAnnouncementsModal.modal';
import AnnouncementDisplay from './AnnouncementDisplay.component';

class Announcements extends React.Component {
  state = {
    announcements: [],
    announcement: '',
    title: '',
    newAnnouncementModal: false,
    viewAnnouncementsModal: false
  };

  componentDidMount() {
    this.getAnnouncements();
  }

  toggleAnnouncementModal = () => {
    this.setState({
      viewAnnouncementsModal: !this.state.viewAnnouncementsModal
    });
  };

  getAnnouncements = () => {
    http()
      .get('/announcements')
      .then(res => {
        this.setState({
          announcements: res,
          newAnnouncementModal: false
        });
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { user } = this.props;

    return (
      <Paper style={{ padding: 16 }}>
        <NewAnnouncementModal
          open={this.state.newAnnouncementModal}
          refresh={this.getAnnouncements}
          onClose={() => this.setState({ newAnnouncementModal: false })}
        />

        <ViewAnnouncementsModal
          open={this.state.viewAnnouncementsModal}
          refresh={this.getAnnouncements}
          onClose={() => this.setState({ viewAnnouncementsModal: false })}
          announcements={this.state.announcements}
        />

        <Typography variant="h5" gutterBottom>
          Announcements
          {user.hasPermission('announcements') && (
            <IconButton
              color="primary"
              onClick={() => this.setState({ newAnnouncementModal: true })}
            >
              <NewIcon size="small" />
            </IconButton>
          )}
        </Typography>

        <Divider />

        {this.state.announcements.slice(0, 3).map(announcement => {
          return (
            <AnnouncementDisplay
              key={announcement.id}
              announcement={announcement}
              refresh={this.getAnnouncements}
            />
          );
        })}
        <Button
          variant="text"
          color="secondary"
          style={{ display: 'block', margin: 'auto', marginTop: 16 }}
          onClick={this.toggleAnnouncementModal}
        >
          View all announcements
        </Button>
      </Paper>
    );
  }
}

export default connect(state => ({ user: state.user }))(Announcements);
