import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  MenuItem,
  Select,
  Button
} from 'helpers/themeSafeMui.helper';
import docsIcon from 'assets/google_doc.png';
import sheetsIcon from 'assets/google_sheet.png';
import folderIcon from 'assets/folder.png';
import { DropZone } from 'components';
import stable from 'stable';

const NewFileModal = props => {
  const { open, onClose, onCreate, templates } = props;
  const [template, setTemplate] = useState(null);

  const types = [
    {
      mimeType: 'application/vnd.google-apps.document',
      name: 'Google Doc',
      icon: docsIcon
    },
    {
      mimeType: 'application/vnd.google-apps.spreadsheet',
      name: 'Google Sheet',
      icon: sheetsIcon
    },
    {
      mimeType: 'application/vnd.google-apps.folder',
      name: 'Folder',
      icon: folderIcon
    }
  ];

  return (
    <Dialog maxWidth="sm" fullWidth open={open} scroll="body" onClose={onClose}>
      <DialogTitle>Create a new...</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-around">
          {types.map(type => (
            <Grid
              key={type.name}
              item
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onCreate([
                  {
                    mimeType: type.mimeType,
                    name: `New ${type.name}`
                  }
                ]);
                onClose();
              }}
            >
              <Typography align="center" variant="body2">
                <img src={type.icon} alt={type.name} style={{ height: 70 }} />
                <br />
                {type.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogTitle>Create From Template</DialogTitle>
      <DialogContent style={{ display: 'flex' }}>
        <Select
          style={{ flexGrow: 1, marginRight: 16 }}
          displayEmpty
          value={template || ''}
          onChange={e => setTemplate(e.target.value)}
        >
          <MenuItem value="" disabled>
            Select Template
          </MenuItem>
          {stable(templates, (a, b) => a.name > b.name).map(template => (
            <MenuItem key={template.id} value={template}>
              {template.name} {template.esignTemplate && '(eSign)'}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onCreate([
              {
                name: template.name,
                templateId: template.id,
                esignTemplate: template.esignTemplate
              }
            ]);
            onClose();
          }}
        >
          create
        </Button>
      </DialogContent>

      <DialogTitle>Upload a file</DialogTitle>
      <DialogContent style={{ marginBottom: 16 }}>
        <DropZone
          onRead={files => {
            onCreate(files);
            onClose();
          }}
          title="User Document"
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewFileModal;
