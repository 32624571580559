import React from 'react';
import BoxDraw from './BoxDraw.component';
import BoxMove from './BoxMove.component';
import BoxResize from './BoxResize.component';

const Editor = props => {
  const {
    editMode,
    onCreate,
    onUpdate,
    onUpdateEnd,
    children,
    activeField
  } = props;

  return (
    <BoxDraw active={editMode === 'create'} onDrawn={onCreate}>
      <BoxMove
        active={editMode === 'move'}
        onUpdate={onUpdate}
        box={activeField}
        onDone={onUpdateEnd}
      >
        <BoxResize
          active={editMode === 'resize'}
          onUpdate={onUpdate}
          box={activeField}
          onDone={onUpdateEnd}
        >
          {children}
        </BoxResize>
      </BoxMove>
    </BoxDraw>
  );
};

export default Editor;
