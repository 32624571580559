import React, { useEffect, useState } from 'react';
import mapReducer from 'helpers/mapReducer.helper';
import { useSelector } from 'react-redux';
import { Paper, Typography } from 'helpers/themeSafeMui.helper';

export default function SelectedStatuses({
  selectedStatuses: selectedStatusIds
}) {
  const statuses = useSelector(state => state.projectStatuses);
  const [sortedStatuses, setSortedStatuses] = useState([]);

  useEffect(() => {
    const statusMap = statuses.reduce(mapReducer('id'), {});
    const sortedStatuses = selectedStatusIds
      .map(id => statusMap[id])
      .filter(s => s)
      .sort((a, b) => a.order - b.order);

    setSortedStatuses(sortedStatuses);
  }, [statuses, selectedStatusIds]);

  if (!sortedStatuses.length) return null;

  return (
    <Paper
      elevation={1}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 8,
        alignItems: 'center'
      }}
    >
      <Typography
        style={{
          margin: '8px 12px',
          fontWeight: 500,
          fontSize: '1em'
        }}
      >
        Active Statuses:
      </Typography>
      {sortedStatuses.map(status => (
        <Typography
          variant="caption"
          component="div"
          key={status.id}
          style={{ margin: '8px 12px', lineHeight: 1 }}
        >
          {status.status}
        </Typography>
      ))}
    </Paper>
  );
}
