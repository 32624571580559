import React, { useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import toCurrency from 'helpers/currency.helper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { DragAndDrop } from 'components';

const LineItem = props => {
  const { value, onDelete, onUpdate, readOnly } = props;
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState(value.description);
  const [cost, setCost] = useState(value.cost);

  const saveItem = () => {
    onUpdate({ description, cost });
    setEditing(false);
  };

  return (
    <div style={{ width: '100%' }}>
      {editing ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
            style={{ marginRight: 8, flex: 1 }}
          />
          <TextField
            fullWidth
            value={cost}
            onChange={e => setCost(e.target.value)}
            style={{ marginRight: 8, width: 150 }}
            type="number"
            inputProps={{ step: '0.01' }}
          />
          <Button variant="contained" color="secondary" onClick={saveItem}>
            Save
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          {!readOnly && (
            <EditIcon
              fontSize="small"
              color="action"
              onClick={() => setEditing(true)}
              style={{ cursor: 'pointer', marginRight: 8 }}
            />
          )}

          <Typography style={{ flex: 1 }}>{value.description}</Typography>
          <Typography style={{ width: 150 }}>
            {toCurrency(value.cost)}
          </Typography>

          {!readOnly && (
            <DeleteIcon
              fontSize="small"
              color="action"
              style={{ cursor: 'pointer' }}
              onClick={onDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};

const QuotePrices = props => {
  const {
    readOnly,
    values,
    onChange,
    isMultiScope = false,
    isApproved = false,
    approvedScopeIndices = []
  } = props;
  const colors = useColors();
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState('');

  const addItem = () => {
    onChange(values.concat({ description, cost }));
    setDescription('');
    setCost('');
  };

  const removeItem = deleteIndex => () => {
    onChange(values.filter((item, index) => deleteIndex !== index));
  };

  const updateItem = updateIndex => val => {
    onChange(values.map((item, index) => (updateIndex === index ? val : item)));
  };

  const withCheckbox =
    isMultiScope && isApproved && approvedScopeIndices.length === values.length;

  const approvedPrice = () => {
    if (!withCheckbox) return 0;
    return values.reduce(
      (sum, item, idx) =>
        approvedScopeIndices[idx] ? sum + Number(item.cost) : sum,
      0
    );
  };

  return (
    <div style={{ marginTop: 8, marginBottom: 24 }}>
      <Typography
        variant="h6"
        style={{
          padding: '0 8px',
          background: colors.secondary,
          color: colors.secondaryContrastText,
          marginBottom: 16
        }}
      >
        Pricing:
      </Typography>

      {readOnly ? (
        values.map((item, index) => (
          <div
            key={item.description}
            style={{
              paddingLeft: 16,
              display: 'flex',
              backgroundColor: index % 2 ? colors.lightGrey : null
            }}
          >
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography>
                {index + 1}. {item.description}
              </Typography>
            </div>
            <div
              style={{
                width: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: 25
              }}
            >
              <Typography>{toCurrency(item.cost)}</Typography>
            </div>
            {withCheckbox && (
              <div style={{ width: 150, display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={approvedScopeIndices[index]}
                      disabled={true}
                      color="primary"
                    />
                  }
                  label={
                    approvedScopeIndices[index] ? 'Approved' : 'Not Approved'
                  }
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <DragAndDrop onDrop={onChange}>
          {values.map((item, index) => (
            <LineItem
              id={item.description}
              key={item.description}
              index={index}
              value={item}
              readOnly={readOnly}
              onDelete={removeItem(index)}
              onUpdate={updateItem(index)}
            />
          ))}
        </DragAndDrop>
      )}

      {!readOnly && (
        <div style={{ display: 'flex', marginTop: 16 }}>
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            label="Add line item pricing"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <TextField
            style={{ width: 150, marginLeft: 16 }}
            variant="outlined"
            margin="none"
            label="Price"
            value={cost}
            onChange={e => setCost(e.target.value)}
            type="number"
            inputProps={{ step: '0.01' }}
          />
          <Button
            disabled={!description.length || !cost}
            variant="contained"
            color="secondary"
            onClick={addItem}
            style={{ marginLeft: 24 }}
          >
            Add
          </Button>
        </div>
      )}

      <div
        style={{
          padding: '0 8px',
          background: colors.secondary,
          color: colors.secondaryContrastText,
          marginTop: 16,
          display: 'flex'
        }}
      >
        <Typography variant="h6" style={{ flex: 1 }}>
          Total cost as outlined:
        </Typography>
        <Typography variant="h6" style={{ width: 150 }}>
          {toCurrency(values.reduce((sum, item) => sum + Number(item.cost), 0))}
        </Typography>
      </div>

      {withCheckbox && (
        <div
          style={{
            padding: '0 8px',
            background: colors.background,
            marginTop: 16,
            display: 'flex'
          }}
        >
          <Typography variant="h6" style={{ flex: 1 }}>
            Total approved cost:
          </Typography>
          <Typography variant="h6" style={{ width: 150 }}>
            {toCurrency(approvedPrice())}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default QuotePrices;
