import mapReducer from 'helpers/mapReducer.helper';
import useModified from 'hooks/useModified.hook';
import { Moment } from 'moment';
import { useContext } from 'react';
import { ProjectsContext } from 'routes/ShiftSchedule/context/Projects.context';
import { ShiftScheduleContext } from 'routes/ShiftSchedule/context/ShiftSchedule.context';
import { toDateString } from 'routes/ShiftSchedule/helpers/DateTime.helper';

interface ProjectShifts {
  projectId: string;
  shifts: any[];
}

type ProjectShiftsOnDay = ProjectShifts[];

type SortProjectIds = (projectIds: string[]) => string[];

export default function useProjectShiftsOnDays(
  dayMoments: Moment[],
  sortProjectIds: SortProjectIds | null = null
) {
  const { dateShiftMap } = useContext(ShiftScheduleContext);
  const { filteredProjectMap } = useContext(ProjectsContext);

  const [projectShiftsOnDays, allProjectIds] = useModified(
    dayMoments => {
      const filteredShiftsOnDays = dayMoments.map(dayMoment => {
        const allShifts = dateShiftMap[toDateString(dayMoment)] || [];
        const filteredShifts = allShifts.filter(
          (shift: any) => filteredProjectMap[shift.projectId]
        );
        return filteredShifts;
      });
      const projectShiftsOnDays = filteredShiftsOnDays.map(day => {
        const projectIdMap = day.reduce(mapReducer.array('projectId'), {});
        return Object.keys(projectIdMap).map(projectId => ({
          projectId,
          shifts: projectIdMap[projectId]
        }));
      });

      let allProjectIds: string[] = [];
      const allProjectIdsSet: any = {};
      filteredShiftsOnDays.forEach(day =>
        day.forEach((shift: any) => {
          if (!allProjectIdsSet[shift.projectId]) {
            allProjectIds.push(shift.projectId);
            allProjectIdsSet[shift.projectId] = true;
          }
        })
      );
      if (sortProjectIds) allProjectIds = sortProjectIds(allProjectIds);
      return [projectShiftsOnDays, allProjectIds];
    },
    dayMoments,
    [filteredProjectMap, dateShiftMap, sortProjectIds]
  );

  return [projectShiftsOnDays, allProjectIds] as [
    ProjectShiftsOnDay[],
    string[]
  ];
}
