import React, {
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import usePopper from 'hooks/usePopper.hook';
import {
  Divider,
  makeStyles,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';

const useStyles = makeStyles({
  text: {
    '&:hover': { textDecoration: 'underline' }
  }
});

const TextOverflowPopover = React.forwardRef(
  (
    {
      text,
      popoverTitle,
      style = {},
      TypographyProps = {},
      setIsOverflowing: setIsOverflowingProp
    },
    forwardedRef
  ) => {
    const { Popper, handleClick: popperHandleClick, close } = usePopper();
    const [isOverflowing, setIsOverflowing] = useState(false);
    const ref = useRef(null);
    const { text: textClass } = useStyles();

    useLayoutEffect(() => {
      let isOverflowing = false;
      if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
        isOverflowing = true;
      }
      setIsOverflowing(isOverflowing);
      if (setIsOverflowingProp) setIsOverflowingProp(isOverflowing);
    }, [text, setIsOverflowingProp]);

    const openPopper = e => {
      if (ref.current) popperHandleClick({ currentTarget: ref.current });
    };

    const handleClick = e => {
      if (isOverflowing) {
        openPopper(e);
      }
    };

    useImperativeHandle(forwardedRef, () => ({
      openPopper,
      closePopper: close
    }));

    return (
      <>
        <Typography
          noWrap
          {...TypographyProps}
          style={{ cursor: isOverflowing ? 'pointer' : undefined, ...style }}
          ref={ref}
          className={isOverflowing ? textClass : undefined}
          onClick={e => {
            handleClick(e);
            if (TypographyProps.onClick) TypographyProps.onClick(e);
          }}
        >
          {text}
        </Typography>
        {isOverflowing && (
          <Popper onClick={e => e.stopPropagation()}>
            <Paper style={{ maxWidth: 350 }}>
              {popoverTitle && (
                <>
                  <Typography variant="h6" style={{ padding: '5px 16px' }}>
                    {popoverTitle}
                  </Typography>
                  <Divider />
                </>
              )}
              <Typography
                style={{
                  padding: '10px 16px',
                  fontSize: 14,
                  whiteSpace: 'pre-wrap'
                }}
              >
                {text}
              </Typography>
            </Paper>
          </Popper>
        )}
      </>
    );
  }
);

export default TextOverflowPopover;
