import React from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';

const Users = ({ allUsers: users, ids }) => {
  const sorted = [...users].sort((a, b) =>
    a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
  );
  let groupUsers = sorted.filter(u => ids.indexOf(u.id) >= 0);

  if (groupUsers.length === 0)
    groupUsers = [{ id: 'none', name: 'No users selected' }];

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        top: 0,
        bottom: 0,
        overflowY: 'auto'
      }}
    >
      <Typography style={{ padding: 7 }} variant="h5" align="center">
        Current Users:
      </Typography>
      {groupUsers.map(user => (
        <div key={user.id}>{user.name}</div>
      ))}
    </div>
  );
};

export default Users;
