import React, { useState, useRef } from 'react';
import {
  Paper,
  Typography,
  useTheme,
  IconButton
} from 'helpers/themeSafeMui.helper';
import { useBetterBlur } from 'components';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import EditIcon from '@material-ui/icons/Edit';

const TitleList = ({ children, title, list, icon, color }) => {
  const [editing, setEditing] = useState(false);
  const rootRef = useRef(null);
  const theme = useTheme();
  const { blur } = useBetterBlur({
    innerRef: rootRef,
    onFocus: () => setEditing(true),
    onBlur: () => setEditing(false),
    ignoreSelectors: ['.MuiAutocomplete-option']
  });

  const nonEmptyList = list.length > 0 ? list : ['Nothing Selected'];
  const toRem = theme.typography.pxToRem;

  return (
    <Paper
      innerRef={rootRef}
      elevation={0}
      style={{ cursor: editing ? null : 'pointer' }}
    >
      <Typography
        style={{
          padding: `${toRem(5)} ${toRem(10)}`,
          display: 'flex',
          alignItems: 'center'
        }}
        variant="h5"
      >
        {title}
        {!editing && (
          <IconButton style={{ padding: 0, marginLeft: 10 }}>
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      <div
        style={{
          background: theme.palette.grey[100]
        }}
      >
        {editing ? (
          children(blur)
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {nonEmptyList.map(item => (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: `${theme.typography.pxToRem(5)} 0`,
                  marginRight: 15
                }}
                key={item}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color || theme.palette.success.main,
                    padding: `0 ${theme.typography.pxToRem(7)}`
                  }}
                >
                  {icon ? (
                    React.cloneElement(icon)
                  ) : (
                    <CheckCircleOutlinedIcon />
                  )}
                </div>
                <Typography variant="body1">{item}</Typography>
              </span>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
};

export default TitleList;
