import React, { useContext } from 'react';
import { makeStyles, TableCell, Typography } from 'helpers/themeSafeMui.helper';

export const SetMasterMaterialIdContext = React.createContext(() => {
  console.error('outside of context');
});
const useStyles = makeStyles({
  cell: { '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }
});

export default function MasterMaterialCell({ material, cellStyle }) {
  const setMasterMaterialId = useContext(SetMasterMaterialIdContext);
  const { cell } = useStyles();
  return (
    <TableCell
      className={cell}
      style={cellStyle}
      onClick={e => {
        setMasterMaterialId(
          material.materialReplacement || material.materialId
        );
        e.stopPropagation();
      }}
    >
      <Typography style={{ fontSize: 14 }}>View</Typography>
    </TableCell>
  );
}
