import React from 'react';
import {
  Typography,
  Chip,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel
} from 'helpers/themeSafeMui.helper';
import PropTypes from 'prop-types';

const styles = {
  flexWrap: { display: 'flex', flexWrap: 'wrap' },
  permissionChip: { margin: 2.5 }
};

export default function ChipSelect(props) {
  return (
    <FormControl fullWidth margin={props.margin} required={props.required}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        multiple
        value={props.selected}
        name={props.name}
        onChange={props.handleChange}
        renderValue={selected => (
          <div style={styles.flexWrap}>
            {selected.length ? (
              selected.map(selected => (
                <Chip
                  style={styles.permissionChip}
                  key={selected.value}
                  label={selected.name}
                />
              ))
            ) : (
              <Typography color="textSecondary" variant="body1">
                {props.placeholder}
              </Typography>
            )}
          </div>
        )}
      >
        {props.options.map(option => (
          <MenuItem key={option.value} value={option}>
            <Checkbox
              checked={props.selected.some(el => el.value === option.value)}
            />
            <ListItemText
              primary={option.name}
              secondary={option.description}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ChipSelect.propTypes = {
  margin: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selected: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool
};
