import React, { useState, useContext } from 'react';

import { Toast, DropZone, FilePickerButton } from 'components';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import { CustomInput } from 'components';

import http from 'helpers/http.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';

const TaxExempt = ({ prev, next }) => {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [taxExempt, setTaxExempt] = useState('Is not tax exempt');

  const save = async e => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    if (taxExempt === 'Is tax exempt' && !file) {
      Toast.show('Tax Exempt file required');
      setLoading(false);
    } else {
      await http()
        .put(`/projects/${projectDetails.projectId}/details`, {
          taxExempt: taxExempt === 'Is tax exempt' ? true : false
        })
        .then(res => setProjectDetails(res));

      if (file) {
        const projectFolder = await http().get(
          `/projects/${projectDetails.projectId}/docs`
        );

        const accountingFolder = findAccountingFolder(projectFolder);
        const folderId = accountingFolder
          ? accountingFolder.id
          : projectFolder.id;

        await saveFile(file, folderId, projectDetails.projectId);
      }

      next();
    }
  };

  return (
    <form autoComplete="off" onSubmit={save}>
      <DialogTitle
        style={{ textAlign: 'center', marginTop: 16, paddingBottom: 0 }}
      >
        Tax Exempt
      </DialogTitle>

      <DialogContent>
        <CustomInput
          margin="dense"
          label="Tax exempt"
          value={taxExempt}
          onChange={setTaxExempt}
          type="dropdown"
          options={[
            { label: 'Is not tax exempt', value: 'Is not tax exempt' },
            { label: 'Is tax exempt', value: 'Is tax exempt' }
          ]}
        />
      </DialogContent>
      {taxExempt === 'Is tax exempt' && (
        <DialogContent>
          <DropZone
            style={{ minHeight: 100 }}
            onRead={files => setFile(files[0])}
            loading={loading}
            title="Upload File"
            isUploaded={!!(file || fileId)}
            onReset={() => {
              setFile(null);
              setFileId(null);
            }}
          />

          <FilePickerButton
            apiPath={`/projects/${projectDetails.projectId}/docs`}
            buttonText="Select file from drive"
            style={{ marginTop: 10 }}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button color="primary" variant="contained" onClick={prev}>
          Back
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

function findAccountingFolder(projectFolder) {
  if (isUsingOneDrive()) {
    return projectFolder.children.find(
      child => child.name === 'Accounting' && !!child.folder
    );
  }
  return projectFolder.files.find(
    file =>
      file.name === 'Accounting' &&
      file.mimeType === 'application/vnd.google-apps.folder'
  );
}

async function saveFile(file, folderId, projectId) {
  if (isUsingOneDrive()) {
    const formData = new FormData();
    formData.set('name', file.name);
    if (folderId) formData.set('folderId', folderId);
    formData.set('steps_file', file.file);
    return http().post(`/projects/${projectId}/docs`, formData);
  }
  return http().post(`/projects/${projectId}/docs`, {
    name: file.name,
    mimeType: file.mimeType,
    file: file.base64,
    folderId
  });
}

export default TaxExempt;
