import React from 'react';
import { connect } from 'react-redux';
import { Toast, BankAccount } from 'components';
import { Button } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

class DirectDeposit extends React.Component {
  state = {
    accounts: [],
    fullName: '',
    loading: false
  };

  componentDidMount() {
    this.getBankAccounts();
  }

  getBankAccounts = () => {
    http()
      .get('/users/me/bank-accounts')
      .then(accounts => {
        accounts = accounts.map(account => ({
          ...account,
          accountNumberConfirmed: account.accountNumber,
          paycheckAllocation: account.paycheckAllocation || '',
          allocationType: account.allocationType || ''
        }));

        this.setState({ accounts }, () => {
          if (!accounts.length) {
            this.addAccount();
          }
        });
      })
      .catch(err => Toast.show(err.message));
  };

  addAccount = () => {
    this.setState({
      accounts: this.state.accounts.concat({
        id: 'new_' + new Date().getTime(),
        type: '',
        routingNumber: '',
        accountNumber: '',
        accountNumberConfirmed: '',
        directDepositAcknowledged: '',
        paycheckAllocation: '100',
        allocationType: 'percent',
        file: null
      })
    });
  };

  removeAccount = id => {
    if (id.includes('new_')) {
      this.setState({ accounts: this.state.accounts.filter(a => a.id !== id) });
    } else {
      http()
        .delete('/users/me/bank-accounts/' + id)
        .then(() => this.getBankAccounts())
        .catch(err => Toast.show(err.message));
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  agreeAndSave = (e, account) => {
    e.preventDefault();

    if (this.state.loading) return;

    if (!account.file) {
      return Toast.show('Please upload an image of a voided check.');
    }

    if (account.accountNumber !== account.accountNumberConfirmed) {
      return Toast.show("Account numbers don't match.");
    }

    this.setState({ loading: true });

    http()
      .post('/users/me/bank-accounts', {
        type: account.type,
        routingNumber: account.routingNumber,
        accountNumber: account.accountNumber,
        directDepositAcknowledged: new Date(),
        paycheckAllocation: account.paycheckAllocation,
        allocationType: account.allocationType,
        voidCheckFile: {
          base64: account.file.base64,
          mimeType: account.file.mimeType
        }
      })
      .then(() => this.getBankAccounts())
      .catch(err => Toast.show(err.message))
      .finally(() => this.setState({ loading: false }));
  };

  continue = () => {
    if (this.state.loading) return;

    if (this.state.accounts.filter(a => !a.id.includes('new_')).length < 1) {
      return Toast.show(
        'Please add a bank account and click "Agree and Save" to continue.'
      );
    }
    this.props.history.push('/onboarding/government-forms');
  };

  render() {
    const { accounts } = this.state;

    return (
      <React.Fragment>
        {accounts.map((account, idx) => {
          return (
            <BankAccount
              onChange={value =>
                this.setState({
                  accounts: accounts.map(account =>
                    account.id === value.id ? value : account
                  )
                })
              }
              agreeAndSave={this.agreeAndSave}
              removeAccount={this.removeAccount}
              account={account}
              key={account.id}
              idx={idx}
            />
          );
        })}

        <div
          style={{
            display: 'flex',
            justifyContent: accounts.length < 2 ? 'space-between' : 'flex-end'
          }}
        >
          {accounts.length < 2 && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={this.addAccount}
            >
              Add Another Account
            </Button>
          )}

          <Button onClick={this.continue} variant="contained" color="primary">
            Next
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({ user: state.user }))(DirectDeposit);
