import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Typography } from 'helpers/themeSafeMui.helper';
import JobLog from '../components/JobLog.component';
import { useColors } from 'helpers/theme.helper';
import { EmploymentLogContext, UserContext } from '../context/User.context';
import UpdateArea from '../modals/UpdateArea.modal';

const EmploymentDetails = props => {
  const { userId } = props;
  const colors = useColors();
  const { user } = useContext(UserContext);
  const { employmentLogs, setEmploymentLogs } = useContext(
    EmploymentLogContext
  );
  const [viewAll, setViewAll] = useState(false);
  const [updateAreaModalOpen, setUpdateAreaModalOpen] = useState(false);

  const activeLog = employmentLogs.find(log => log.id === user.activeLogId);
  const pendingLogs = employmentLogs.filter(log => log.isApproved === null);
  const previousLogs = employmentLogs.filter(
    log => log.isApproved !== null && user.activeLogId !== log.id
  );

  const addNew = () => {
    setEmploymentLogs(
      [
        {
          id: 'NEW',
          isApproved: null,
          areaId: activeLog?.areaId,
          employmentStatus: activeLog?.employmentStatus,
          permissionLevelId: activeLog?.permissionLevelId,
          salary: activeLog?.salary,
          wage: activeLog?.wage
        }
      ].concat(employmentLogs)
    );
  };

  return (
    <Grid container spacing={2} style={{ position: 'relative' }}>
      {!pendingLogs.length && (
        <div style={{ position: 'absolute', top: 0, right: 10 }}>
          {activeLog && props.currentUser.hasPermission('create_users') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdateAreaModalOpen(true)}
              style={{ marginRight: 10 }}
            >
              Update Area
            </Button>
          )}

          <Button variant="contained" color="primary" onClick={addNew}>
            add new
          </Button>
        </div>
      )}

      {activeLog && (
        <UpdateArea
          open={updateAreaModalOpen}
          onClose={() => setUpdateAreaModalOpen(false)}
          jobLog={activeLog}
        />
      )}

      {pendingLogs.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Pending Employment Changes
          </Typography>
          {pendingLogs.map(log => (
            <JobLog
              onCancel={() =>
                setEmploymentLogs(
                  employmentLogs.filter(log => log.id !== 'NEW')
                )
              }
              setEmploymentLogs={setEmploymentLogs}
              key={log.id}
              color={colors.yellow}
              employmentLog={log}
              userId={userId}
            />
          ))}
        </Grid>
      )}
      {activeLog && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Current Job
          </Typography>
          <JobLog
            setEmploymentLogs={setEmploymentLogs}
            color={colors.success}
            employmentLog={activeLog}
            userId={userId}
          />
        </Grid>
      )}

      {previousLogs.length > 0 && viewAll && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Previous Jobs
          </Typography>
          {previousLogs.map(log => (
            <JobLog
              key={log.id}
              setEmploymentLogs={setEmploymentLogs}
              color={colors.error}
              employmentLog={log}
              userId={userId}
            />
          ))}
        </Grid>
      )}

      {!viewAll && previousLogs.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => setViewAll(true)}
        >
          View Previous Jobs
        </Button>
      )}
    </Grid>
  );
};

export default connect(state => ({ currentUser: state.user }))(
  EmploymentDetails
);
