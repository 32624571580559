import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import {
  fromDateString,
  fromTimeString,
  toDateString
} from '../helpers/DateTime.helper';
import { UsersContext } from '../context/Users.context';

export interface ShiftGroup {
  start: moment.Moment;
  end: moment.Moment;
  shifts: any[];
  sharedUsers: string[];
  label: { time: string; days: string; users: string };
  approved: boolean;
  activeDays: boolean[];
  taskId: string;
  taskName: string;
  lunchStart: moment.Moment;
  lunchDurationMinutes: number;
  workOrderId: null | string;
  variedWorkOrderId: boolean;
}

type ArrayMap = {
  [key: string]: any[];
};

export default function useProjectShiftsGroups(projectId: string) {
  const [shiftGroups, setShiftGroups] = useState([] as ShiftGroup[]);

  const { userMap } = useContext(UsersContext);
  const { dateWindow, dateShiftMap, shiftUserMap } = useContext(
    ShiftScheduleContext
  );

  useEffect(() => {
    if (!projectId) return;

    const dayMoments = Array(7)
      .fill(null)
      .map((n, idx) => moment(dateWindow[0]).day(idx));

    // Build shift set: { [`startTime-endTime-taskId-${approved ? 'a' :'u'}`]: shift[] }
    const shiftSet = {} as ArrayMap;
    const dateStrings = dayMoments.map(toDateString);
    dateStrings.forEach(dateString => {
      const shifts = (dateShiftMap[dateString] || []) as any[];
      const projectShifts = shifts.filter(
        shift => shift.projectId === projectId
      );
      projectShifts.forEach(shift => {
        const timeKey = `${shift.start}-${shift.end}-${shift.taskId || 'NA'}-${
          shift.approved ? 'a' : 'u'
        }`;

        if (shiftSet[timeKey]) {
          shiftSet[timeKey].push(shift);
        } else {
          shiftSet[timeKey] = [shift];
        }
      });
    });

    const shiftGroups = [] as ShiftGroup[];
    for (let key in shiftSet) {
      const shifts = shiftSet[key];
      const userGroups = getUserGroups(shifts, shiftUserMap);
      userGroups.forEach(({ sharedUsers, shifts }) => {
        const variedWorkOrderId = shifts.some(
          shift => shift.workOrderId !== shifts[0].workOrderId
        );
        shiftGroups.push({
          start: fromTimeString(shifts[0].start),
          end: fromTimeString(shifts[0].end),
          sharedUsers,
          shifts,
          taskId: shifts[0].taskId,
          taskName: shifts[0].taskName,
          approved: shifts[0].approved,
          label: makeLabel(
            fromTimeString(shifts[0].start),
            fromTimeString(shifts[0].end),
            shifts,
            sharedUsers.map(id => userMap[id]).filter(user => user)
          ),
          activeDays: Array(7)
            .fill(false)
            .map(
              (f, idx) =>
                !!shifts.find(shift => fromDateString(shift.date).day() === idx)
            ),
          lunchStart:
            shifts[0].lunchStart && fromTimeString(shifts[0].lunchStart),
          lunchDurationMinutes: shifts[0].lunchDurationMinutes,
          workOrderId: shifts[0].workOrderId,
          variedWorkOrderId
        });
      });
    }
    setShiftGroups(shiftGroups);
  }, [dateWindow, dateShiftMap, projectId, shiftUserMap, userMap]);

  return shiftGroups;
}

function getUserGroups(shifts: any[], shiftUserMap: ArrayMap) {
  const usersShiftsSet: ArrayMap = {};
  shifts.forEach(shift => {
    const shiftUsers = shiftUserMap[shift.id] || [];
    const key = getUserGroupKey(shiftUsers);
    if (usersShiftsSet[key]) {
      usersShiftsSet[key].push(shift);
    } else {
      usersShiftsSet[key] = [shift];
    }
  });
  const res = [] as { shifts: any[]; sharedUsers: string[] }[];
  Object.keys(usersShiftsSet).forEach(key => {
    res.push({
      shifts: usersShiftsSet[key],
      sharedUsers: key.split(':').filter(id => id)
    });
  });

  return res;
}

function getUserGroupKey(shiftUsers: any[]) {
  return shiftUsers
    .map(shiftUser => shiftUser.userId)
    .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
    .join(':');
}

function m(shift: any) {
  return fromDateString(shift.date);
}

function makeLabel(
  start: moment.Moment,
  end: moment.Moment,
  shifts: any[],
  users: any[]
) {
  const format = 'h:mm A';
  const time = `${start.format(format)} - ${end.format(format)}`;
  const days = shifts
    .map(m)
    .map(m => m.format('ddd'))
    .join(', ');
  return {
    time,
    days,
    users: users.map(user => user.name).join(', ')
  };
}
