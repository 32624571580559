import React, { useContext } from 'react';
import { makeStyles, TableCell } from 'helpers/themeSafeMui.helper';
import safeParse from 'helpers/safeParse.helper';
import { CustomInput } from 'components';

export const SetQuantityContext = React.createContext(() => {
  console.error('outside of context');
});
const useStyles = makeStyles(theme => ({
  cell: {
    '&:hover': { color: theme.palette.primary.main },
    color: theme.palette.action.active,
    cursor: 'pointer'
  }
}));

export default function UpdateQuantityCell({ material, cellStyle }) {
  const setQuantity = useContext(SetQuantityContext);
  let updateQuantity = material
    ? material.updateQuantity === ''
      ? ''
      : safeParse(material.updateQuantity, null) === null
      ? ''
      : safeParse(material.updateQuantity, 0)
    : 0;
  const { cell } = useStyles();

  return (
    <TableCell
      padding="none"
      className={cell}
      style={cellStyle}
      onClick={e => e.stopPropagation()}
    >
      <div
        style={{
          width: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{ flex: '0 0 100px', textAlign: 'right', paddingLeft: 16 }}>
          <CustomInput
            margin="none"
            type="number"
            value={updateQuantity}
            onChange={newVal => setQuantity(material, newVal)}
          />
        </div>
      </div>
    </TableCell>
  );
}
