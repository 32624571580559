import { useCallback, useEffect, useRef } from 'react';

export default function useTimer(
  onSecondChange: (secondsSinceStart: number) => any
) {
  const startTimeRef = useRef<null | number>(null);
  const pauseTimeRef = useRef<null | number>(null);
  const intervalRef = useRef<null | number>(null);
  const timeoutRef = useRef<null | number>(null);
  const callbackRef = useRef(onSecondChange);
  callbackRef.current = onSecondChange;

  useEffect(() => {
    return () => {
      if (typeof intervalRef.current === 'number')
        clearInterval(intervalRef.current);
      if (typeof timeoutRef.current === 'number')
        clearTimeout(timeoutRef.current);
    };
  }, []);

  const startTimer = useCallback(() => {
    if (typeof intervalRef.current === 'number') {
      clearInterval(intervalRef.current);
    }
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    const callback = () => {
      if (startTimeRef.current) {
        callbackRef.current(
          Math.floor((Date.now() - startTimeRef.current) / 1000)
        );
      }
    };
    const startInterval = () => {
      intervalRef.current = window.setInterval(() => {
        callback();
      }, 1000);
    };

    if (pauseTimeRef.current && startTimeRef.current) {
      const timeToNextSecondChange =
        1000 - ((pauseTimeRef.current - startTimeRef.current) % 1000);
      const timeSincePause = Date.now() - pauseTimeRef.current;
      startTimeRef.current += timeSincePause;
      pauseTimeRef.current = null;
      timeoutRef.current = window.setTimeout(() => {
        startInterval();
        callback();
      }, timeToNextSecondChange);
    } else {
      startTimeRef.current = Date.now();
      startInterval();
    }
  }, []);

  const clearTimer = useCallback(() => {
    if (typeof intervalRef.current === 'number') {
      clearInterval(intervalRef.current);
    }
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const pauseTimer = useCallback(() => {
    if (typeof intervalRef.current === 'number') {
      clearInterval(intervalRef.current);
    }
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!pauseTimeRef.current) pauseTimeRef.current = Date.now();
  }, []);

  return {
    startTimer,
    clearTimer,
    pauseTimer
  };
}
