import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Typography } from 'helpers/themeSafeMui.helper';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    flexDirection: 'column',
    marginTop: '15px'
  },
  searchIcon: {
    fontSize: 35
  }
};
const NoItemsResult = ({ message = null, type, ...props }) => {
  const text = message || `No ${type} found.`;
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <SearchIcon color="action" style={styles.searchIcon} />
      <Typography color="textSecondary">{text}</Typography>
    </div>
  );
};

export default NoItemsResult;
