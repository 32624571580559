import { Toast } from 'components';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { useContext } from 'react';
import { ShiftScheduleContext } from '../context/ShiftSchedule.context';
import { fromTimeString, toTimeString } from '../helpers/DateTime.helper';
import useShiftApi from './useShiftApi.hook';

export default function useDayUpdater({
  shiftId,
  taskId = undefined,
  start = undefined,
  end = undefined,
  notes = undefined,
  approved = undefined,
  userIds = undefined,
  workOrderId = undefined,
  lunchStart = undefined,
  lunchDurationMinutes = undefined
}) {
  const currentUser = useCurrentUser();
  const { shiftMap, shiftUserMap, fetchSchdeules } = useContext(
    ShiftScheduleContext
  );
  const { shiftDays, shiftDayUsers } = useShiftApi();
  const shift = shiftMap[shiftId] || null;
  const shiftUsers = shiftUserMap[shiftId] || [];
  const originalUserIds = shiftUsers.map(shiftUser => shiftUser.userId);

  if (shift) {
    taskId = taskId === undefined ? shift.taskId : taskId;
    start = start === undefined ? fromTimeString(shift.start) : start;
    end = end === undefined ? fromTimeString(shift.end) : end;
    notes = notes === undefined ? shift.notes || '' : notes;
    approved = approved === undefined ? shift.approved : approved;
    userIds =
      userIds === undefined
        ? shiftUsers.map(shiftUser => shiftUser.userId)
        : userIds;
    workOrderId = workOrderId === undefined ? shift.workOrderId : workOrderId;
    lunchStart =
      lunchStart === undefined
        ? shift.lunchStart && fromTimeString(shift.lunchStart)
        : lunchStart;
    lunchDurationMinutes =
      lunchDurationMinutes === undefined
        ? shift.lunchDurationMinutes
        : lunchDurationMinutes;
  }

  // DELETED USERS
  const deletedUserIds = originalUserIds.filter(
    originalUserId => !userIds.includes(originalUserId)
  );

  // ADDED USERS
  const addedUserIds = userIds.filter(id => !originalUserIds.includes(id));

  return async () => {
    if (!shift) {
      return Promise.reject();
    }

    if (!currentUser.hasPermission('shift_admin')) {
      if (shift.approved) {
        Toast.show('Not authorized to update approved shift.');
        return Promise.reject();
      } else if (approved) {
        Toast.show('Not authorized to approve shift.');
        return Promise.reject();
      }
    }
    try {
      // DELETED USERS
      await Promise.all(
        shiftUsers
          .filter(shiftUser => deletedUserIds.includes(shiftUser.userId))
          .map(shiftUser => shiftDayUsers.destroy(shiftUser.id))
      );

      // ADDED USERS
      await Promise.all(
        addedUserIds.map(userId =>
          shiftDayUsers.create({
            shiftDayId: shift.id,
            userId
          })
        )
      );

      await shiftDays.update({
        id: shift.id,
        date: shift.date,
        start: toTimeString(start),
        end: toTimeString(end),
        taskId,
        notes,
        approved,
        workOrderId,
        lunchStart: lunchStart ? toTimeString(lunchStart) : null,
        lunchDurationMinutes
      });
      await fetchSchdeules();

      Toast.show('Schedule Updated.');
    } catch (err) {
      Toast.showErr(err);
      return Promise.reject();
    }
  };
}
