import http from 'helpers/http.helper';
import { Toast } from 'components';

// Actions
const SET_FAVORITE_PROJECTS = 'favorite-projects/SET_FAVORITE_PROJECTS';

// Reducer
export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SET_FAVORITE_PROJECTS:
      return action.favoriteProjects;
    default:
      return state;
  }
}

// Action Creators
export const getFavoriteProjects = () => async (dispatch, getState) => {
  const { user } = getState();

  const favoriteProjects = await http()
    .get('/favorite-user-projects', { params: { userId: user.id } })
    .catch(err => Toast.show(err.message));

  dispatch({ type: SET_FAVORITE_PROJECTS, favoriteProjects });
};
