import React, { useState } from 'react';
import RemoveIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FilterNone';
import { Typography } from 'helpers/themeSafeMui.helper';
import EditFieldPopup from './EditFieldPopup.component';
import signatureBase64 from 'assets/signature.base64.js';
import initialsBase64 from 'assets/initials.base64.js';
import checkboxBase64 from 'assets/checkbox.base64.js';
import signerColors from '../helpers/signerColors.helper';

const styles = {
  wrapper: props => ({
    position: 'absolute',
    top: props.top,
    left: props.left,
    height: props.height,
    zIndex: props.hovered ? 10 : 1
  }),
  topCaption: {
    position: 'absolute',
    top: -17,
    height: 17,
    left: 0,
    whiteSpace: 'nowrap',
    background: 'rgba(255, 255, 255, .9)',
    minWidth: '100%'
  },
  bottomCaption: {
    background: 'rgba(255, 255, 255, .9)',
    position: 'absolute',
    bottom: -17,
    left: 0,
    height: 17,
    minWidth: '100%',
    display: 'flex'
  },
  actionIcon: {
    cursor: 'pointer',
    fontSize: 15,
    marginRight: 5
  },
  field: props => ({
    position: 'relative',
    width: props.width,
    height: props.height,
    background: props.required
      ? 'rgba(255, 0, 0, 0.3)'
      : 'rgba(0, 255, 0, 0.3)',
    cursor: !props.disabled && 'move',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: props.textAlign,
    lineHeight: 1.2,
    fontFamily: 'Courier New'
  }),
  resizeHandle: props => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 10px 10px',
    borderColor: 'transparent transparent rgba(0, 0, 0, 0.25) transparent',
    cursor: !props.disabled && 'se-resize'
  }),
  signerColor: props => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -5,
    width: 4,
    background: props.signerColor
  })
};

const Field = props => {
  const {
    disabled,
    field,
    onRemove,
    signers,
    onUpdate,
    onCopy,
    onResizing,
    onMoving
  } = props;

  const [hovered, setHovered] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [resizeHandelRef, setResizeHandleRef] = useState(null);

  const onMouseDown = e => {
    if (disabled) return;

    if (e.target === resizeHandelRef) {
      onResizing(field);
    } else {
      onMoving(field);
    }
  };

  const signerIndex = signers.findIndex(s => s.id === field.signerId);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={styles.wrapper({
        top: field.y,
        left: field.x,
        height: field.height,
        hovered: hovered
      })}
    >
      <EditFieldPopup
        field={field}
        onUpdate={onUpdate}
        open={editPopupOpen}
        onClose={() => setEditPopupOpen(false)}
        signers={signers}
      />

      {hovered && (
        <div style={styles.topCaption}>
          <Typography variant="caption">
            <strong>
              {field.signerName} - {field.required ? 'required' : 'optional'}
            </strong>
          </Typography>
        </div>
      )}
      {hovered && (
        <div style={styles.bottomCaption}>
          <div
            title="Delete"
            style={styles.actionIcon}
            onClick={() => onRemove(field)}
          >
            <RemoveIcon fontSize="inherit" color="inherit" />
          </div>
          <div
            title="Edit"
            style={styles.actionIcon}
            onClick={() => {
              setEditPopupOpen(true);
              setHovered(false);
            }}
          >
            <EditIcon fontSize="inherit" color="inherit" />
          </div>
          <div
            title="Copy"
            style={styles.actionIcon}
            onClick={() => {
              setHovered(false);
              onCopy(field);
            }}
          >
            <CopyIcon fontSize="inherit" color="inherit" />
          </div>
        </div>
      )}

      <div
        style={styles.signerColor({
          signerColor: signerColors(signerIndex)
        })}
      />

      <div
        style={styles.field({
          width: field.width,
          height: field.height,
          textAlign: field.textAlign,
          disabled: disabled,
          signerColor: signerColors(signerIndex),
          required: field.required
        })}
        onMouseDown={onMouseDown}
      >
        {(field.type === 'checkbox' ||
          field.type === 'initials' ||
          field.type === 'signature') && (
          <img
            draggable={false}
            style={{ maxWidth: '100%', maxHeight: '100%', padding: 1 }}
            alt={field.type}
            src={
              field.type === 'checkbox'
                ? checkboxBase64
                : field.type === 'initials'
                ? initialsBase64
                : signatureBase64
            }
          />
        )}
        {(field.type === 'auto_date' || field.type === 'text_field') && (
          <div
            style={{
              fontSize: field.scaledFontSize,
              textAlign: field.textAlign,
              color: 'black'
            }}
          >
            {field.type === 'auto_date' && 'DD/MM/YYYY'}
            {field.type === 'text_field' && 'Text Field'}
          </div>
        )}
        <div
          style={styles.resizeHandle({ disabled: disabled })}
          ref={setResizeHandleRef}
        />
      </div>
    </div>
  );
};

export default Field;
