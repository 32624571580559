import moment from 'moment';
import { shortFormatTime } from '../helpers/DateTime.helper';
/**
 * @param {import("./useProjectShiftsGroups").ShiftGroup} group
 */

export default function useWeekLunchTimes(group) {
  if (!group) return [false, ''];
  let variance = false;
  let last = null;
  const shifts = [...group.shifts];
  const shiftByDow = group.activeDays.map(a => (a ? shifts.shift() : null));

  let text = group.activeDays.reduce((acc, isActive, idx) => {
    if (!isActive) return acc;
    const shift = shiftByDow[idx];
    if (!shift) return acc;
    let time = shortFormatTime(shift.lunchStart);
    time += shift.lunchDurationMinutes
      ? `-${shift.lunchDurationMinutes}min`
      : '';
    if (last === null) last = time;
    if (last !== time) {
      variance = true;
      acc = acc.slice(0, acc.length - 1);
      acc += ': ' + last + ', ';
      last = time;
    }

    acc +=
      moment()
        .day(idx)
        .format('dd') + '-';

    return acc;
  }, '');

  text = text.slice(0, text.length - 1) + ': ' + last;
  return [variance, text];
}
