import React from 'react';
import CopyIcon from '@material-ui/icons/FileCopy';
import { Toast } from 'components';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  underline: {
    lineHeight: 0,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
});

function CopyText({
  children,
  afterIconChildren = null,
  icon,
  iconProps = {},
  iconSpanProps,
  text,
  style,
  type = 'Text'
}) {
  const { underline } = useStyles();
  const copy = e => {
    e.stopPropagation();
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Toast.show(`${type} copied.`);
  };
  icon = icon || (
    <CopyIcon
      style={{ marginLeft: 10, lineHeight: 1, ...iconProps.style }}
      fontSize="inherit"
      color="action"
      {...iconProps}
    />
  );
  if (!text) return children;
  return (
    <span style={{ display: 'flex', alignItems: 'center', ...style }}>
      {children}
      <span {...iconSpanProps} className={underline} onClick={copy}>
        {icon}
      </span>
      {afterIconChildren}
    </span>
  );
}

export default CopyText;
