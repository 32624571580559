import React, { useState, useEffect, useContext, useCallback } from 'react';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectTeamContext
} from 'routes/Projects/context/Project.context';
import {
  Paper,
  Typography,
  CircularProgress,
  IconButton
} from 'helpers/themeSafeMui.helper';
import { PersonDisplay, Toast, NoItemsResult, UserSearch } from 'components';
import { useColors } from 'helpers/theme.helper';
import DeleteIcon from '@material-ui/icons/Delete';

const PlangridUsers = () => {
  const { project } = useContext(ProjectContext);
  const [projectUsers, setProjectUsers] = useState([]);
  const colors = useColors();
  const [loading, setLoading] = useState(true);
  const { id: projectId } = project;

  const removeUser = user => () => {
    const { plangridUserId, id } = user;
    http()
      .delete(`/plangrid/projects/${projectId}/users/${plangridUserId}`)
      .then(() =>
        setProjectUsers(pUsers => pUsers.filter(pUser => pUser.id !== id))
      )
      .catch(err => Toast.show(err.message));
  };

  const addUser = user => {
    http()
      .post(`/plangrid/projects/${projectId}/users`, {
        plangridUserId: user.id
      })
      .then(() => {
        fetchProjectUsers();
      })
      .catch(err => Toast.show(err.message));
  };

  const fetchProjectUsers = useCallback(() => {
    setLoading(true);
    if (projectId) {
      http()
        .get(`/plangrid/projects/${projectId}/users`)
        .then(res => {
          setProjectUsers(res);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          Toast.show(err.message);
        });
    }
  }, [projectId]);

  useEffect(() => {
    fetchProjectUsers();
  }, [fetchProjectUsers]);

  if (loading)
    return (
      <Paper style={{ padding: 16 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          PlanGrid Users
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      </Paper>
    );

  if (projectUsers[0] && projectUsers[0].type === 'no_project')
    return (
      <Paper style={{ padding: 16 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          PlanGrid Users
        </Typography>
        <Typography variant="caption">
          PlanGrid project either not created or not linked to this project
        </Typography>
      </Paper>
    );

  return (
    <Paper style={{ padding: 16 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        PlanGrid Users
      </Typography>
      <div style={{ paddingLeft: 16, paddingBottom: 16 }}>
        {!projectUsers.length && <NoItemsResult type="Plangrid Users" />}

        {projectUsers
          .filter(({ type }) => type === 'steps')
          .map(user => (
            <StepsUserCard
              key={user.id}
              user={user}
              Icon={
                <IconButton onClick={removeUser(user)}>
                  <DeleteIcon />
                </IconButton>
              }
            />
          ))}

        {projectUsers
          .filter(({ type }) => type === 'plangrid')
          .map(user => (
            <PlangridUserCard
              key={user.id}
              user={user}
              Icon={
                <IconButton onClick={removeUser(user)}>
                  <DeleteIcon />
                </IconButton>
              }
            />
          ))}
      </div>
      <Typography component="h1" variant="h5">
        Add Users
      </Typography>
      <div style={{ paddingLeft: 16, paddingBottom: 16 }}>
        <UserSearch
          filter={() => true}
          path="/plangrid/users"
          onChange={user => (user ? addUser(user) : null)}
          renderOption={user => (
            <div
              style={{
                width: '100%',
                borderBottom: `1px solid ${colors.grey}`
              }}
            >
              <StepsUserCard user={user} />
            </div>
          )}
        />
      </div>
    </Paper>
  );
};

const StepsUserCard = ({ user, ...rest }) => {
  const { projectTeam } = useContext(ProjectTeamContext);
  let type = '';
  let email;
  if (user.id === projectTeam.accountManagerId) type = 'Account Manager';
  if (user.id === projectTeam.seniorProjectManagerId)
    type = 'Senior Project Manager';
  if (user.id === projectTeam.projectManagerId) type = 'Project Manager';
  if (!type) email = user.email;
  return <PersonDisplay name={user.name} type={type} email={email} {...rest} />;
};

const PlangridUserCard = ({ user, ...rest }) => {
  return (
    <PersonDisplay
      name={`${user.first_name} ${user.last_name}`}
      email={user.email}
      {...rest}
    />
  );
};

export default PlangridUsers;
