import React from 'react';
import { Grid } from 'helpers/themeSafeMui.helper';
import cloudinaryImageTransform from 'helpers/cloudinaryImageTransform.helper';

export default function PhotoGrid({
  styles: stylesProp = {},
  photos,
  onPhotoClick,
  itemSizes,
  ...rest
}) {
  const styles = {
    container: {
      ...stylesProp.container
    },
    item: {
      cursor: !!onPhotoClick ? 'pointer' : null,
      padding: 3,
      ...stylesProp.item
    }
  };

  return (
    <Grid style={styles.container} container {...rest}>
      {photos.map((photo, idx) => (
        <Grid key={photo.id} style={styles.item} item {...itemSizes}>
          <img
            style={{ objectFit: 'fill', width: '100%', height: '100%' }}
            onClick={() => !!onPhotoClick && onPhotoClick(photo, idx)}
            src={
              photo.isCloudinaryPhoto
                ? cloudinaryImageTransform(photo.url)
                : photo.url
            }
            alt="Project"
          />
        </Grid>
      ))}
    </Grid>
  );
}
