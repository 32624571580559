import { CustomInput, Toast } from 'components';
import http from 'helpers/http.helper';
import {
  Button,
  DialogActions,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';

const PotentialPosWorkflow = ({ completeWorkflow }) => {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [estimatedLaborHours, setEstimatedLaborHours] = useState(
    projectDetails.estimatedLaborHours || null
  );
  const [estimatedCrew, setEstimatedCrew] = useState(
    projectDetails.estimatedCrew || null
  );
  const [start, setStart] = useState(projectDetails.start || null);
  const [end, setEnd] = useState(projectDetails.end || null);
  const [scheduleOnMaster, setScheduleOnMaster] = useState(
    projectDetails.scheduleOnMaster || null
  );

  useEffect(() => {
    setEstimatedLaborHours(projectDetails.estimatedLaborHours || null);
    setEstimatedCrew(projectDetails.estimatedCrew || null);
    setStart(projectDetails.start || null);
    setEnd(projectDetails.end || null);
    setScheduleOnMaster(projectDetails.scheduleOnMaster || null);
  }, [
    projectDetails.estimatedLaborHours,
    projectDetails.estimatedCrew,
    projectDetails.start,
    projectDetails.end,
    projectDetails.scheduleOnMaster
  ]);

  const onSubmit = e => {
    e.preventDefault();
    http()
      .put(`/projects/${projectDetails.projectId}/details`, {
        estimatedLaborHours,
        estimatedCrew,
        start,
        end,
        scheduleOnMaster
      })
      .then(setProjectDetails)
      .then(completeWorkflow)
      .catch(Toast.showErr);
  };

  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <CustomInput
          type="number"
          required
          label="Estimated Labor Hours"
          value={estimatedLaborHours}
          onChange={setEstimatedLaborHours}
        />
        <CustomInput
          type="number"
          required
          label="Estimated Crew"
          value={estimatedCrew}
          onChange={setEstimatedCrew}
        />
        <CustomInput
          type="date"
          required
          label="Start"
          value={start}
          onChange={setStart}
        />
        <CustomInput
          type="date"
          required
          label="End"
          value={end}
          onChange={setEnd}
        />
        <CustomInput
          type="boolean"
          required
          label="Include on Master Schedule"
          value={scheduleOnMaster}
          onChange={setScheduleOnMaster}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default PotentialPosWorkflow;
