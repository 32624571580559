import React, { useContext } from 'react';
import usePopper from 'hooks/usePopper.hook';
import {
  NewhireChecklistContext,
  UserContext
} from 'routes/Users/context/User.context';
import { useColors } from 'helpers/theme.helper';
import {
  Checkbox,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';

export default function ChecklistStep({ item, locked = false }) {
  const { setNewhireChecklist } = useContext(NewhireChecklistContext);
  const currentUser = useCurrentUser();
  const { user } = useContext(UserContext);
  const { Popper, handleClick, close } = usePopper();
  const colors = useColors();

  const canMark = currentUser.hasPermission('checklist_tier_2');
  locked = locked || !canMark;

  const mark = data => async () => {
    if (locked) return;
    try {
      const updated = await http().put(
        `/users/${user.id}/newhire-checklist-items/${item.id}`,
        data
      );

      setNewhireChecklist(old => {
        const foundIdx = old.findIndex(other => other.id === updated.id);
        if (foundIdx === -1) return old;
        const copy = [...old];
        copy[foundIdx] = updated;
        return copy;
      });
      close();
    } catch (err) {
      Toast.showErr(err);
    }
  };

  if (item.optional) return null;

  return (
    <>
      <Tooltip title={item.title}>
        <div
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            flexGrow: 1,
            background: item.completed ? colors.success : colors.grey,
            borderRight: `1px solid ${colors.lightGrey}`
          }}
        />
      </Tooltip>
      <Popper>
        <div style={{ maxWidth: 400 }}>
          <div style={{ margin: '5px 15px' }}>
            <Typography variant="h5">{item.title}</Typography>
            {item.notes && (
              <Typography variant="caption" color="textSecondary">
                {item.notes}
              </Typography>
            )}
          </div>
          <Divider />
          <div style={{ marginBottom: 0 }}>
            <ListItem
              button={!locked}
              onClick={mark({ completed: !item.completed })}
            >
              {!locked && (
                <ListItemIcon>
                  <Checkbox
                    checked={item.completed}
                    name="checkedB"
                    color="primary"
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  !locked || item.completed ? 'Completed' : 'Not Completed'
                }
              />
            </ListItem>
            {item.allowOptional && !locked && (
              <ListItem
                disabled={locked}
                button
                onClick={mark({ optional: !item.optional })}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={item.optional}
                    name="checkedB"
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary="Optional" />
              </ListItem>
            )}
          </div>
        </div>
      </Popper>
    </>
  );
}
