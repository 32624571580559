import http from 'helpers/http.helper';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import React, { useCallback, useState } from 'react';
import EditPerDiemModal from '../modals/EditPerDiem.modal';
import PerDiemCard from './PerDiemCard.component';

export default function PerDiemList({ setPerDiems, perDiems = [] }) {
  const [editModalItem, setEditModalItem] = useState(null);
  const user = useCurrentUser();
  const hiddenEditFields = user.hasPermission('per_diem_tier_2')
    ? []
    : ['status', 'locked', 'paidOn'];

  const setPerdiem = useCallback(
    updatedPerDiem => {
      if (updatedPerDiem) {
        setPerDiems(old =>
          old.map(oldPerDiem =>
            oldPerDiem.id === updatedPerDiem.id ? updatedPerDiem : oldPerDiem
          )
        );
      }
    },
    [setPerDiems]
  );

  const edit = async data => {
    const copy = { ...data };
    hiddenEditFields.forEach(field => delete copy[field]);
    const edited = await http().put(
      `/per-diems/${editModalItem.id}`,
      user.hasPermission('per_diem_tier_2')
        ? copy
        : {
            startDate: copy.startDate,
            endDate: copy.endDate,
            dailyAmount: copy.dailyAmount,
            totalAmount: copy.totalAmount,
            notes: copy.notes
          }
    );
    setPerdiem(edited);
  };

  return (
    <>
      <EditPerDiemModal
        open={!!editModalItem}
        onClose={() => setEditModalItem(null)}
        title="Edit Per-Diem"
        hiddenFields={hiddenEditFields}
        onSubmit={edit}
        perDiem={editModalItem}
      />
      {perDiems.map(perDiem => (
        <React.Fragment key={perDiem.id}>
          <div style={{ paddingTop: 12 }} />
          <PerDiemCard
            perDiem={perDiem}
            onClick={() => setEditModalItem(perDiem)}
          />
        </React.Fragment>
      ))}
    </>
  );
}
