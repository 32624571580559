import http from 'helpers/http.helper';
import { Toast } from 'components';

// ACTIONS
const SET_ADMIN_SETTINGS = 'admin-settings/SET_ADMIN_SETTINGS';

// REDUCER
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_ADMIN_SETTINGS:
      return action.adminSettings;
    default:
      return state;
  }
}

// ACTION CREATORS
export const getAdminSettings = () => async dispatch => {
  const adminSettings = await http()
    .get('/settings')
    .catch(err => Toast.show(err.message));

  dispatch({ type: SET_ADMIN_SETTINGS, adminSettings });
};

export const setAdminSettings = adminSettings => {
  return { type: SET_ADMIN_SETTINGS, adminSettings };
};
