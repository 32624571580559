import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NoItemsResult, ActionItem } from 'components';
import { showModal } from 'ducks/modal.duck';
import {
  Typography,
  Paper,
  IconButton,
  Divider
} from 'helpers/themeSafeMui.helper';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {
  ProjectActionItemsContext,
  RefreshProjectContext
} from 'routes/Projects/context/Project.context';

const ProjectActionItemsComponent = props => {
  const { showModal, projectId } = props;
  const { projectActionItems } = useContext(ProjectActionItemsContext);
  const refreshProject = useContext(RefreshProjectContext);

  return (
    <Paper style={{ padding: 16 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Action Items
        </Typography>

        <div>
          <IconButton
            style={{ padding: 0 }}
            onClick={() =>
              showModal({
                type: 'CREATE_ACTION_ITEM',
                props: {
                  projectId: projectId,
                  actionItemLink: props.location.pathname,
                  refresh: refreshProject
                }
              })
            }
          >
            <AddIcon color="action" />
          </IconButton>
        </div>
      </div>

      {projectActionItems.length === 0 && <NoItemsResult type="Action Items" />}

      {projectActionItems.map(id => (
        <div key={id}>
          <ActionItem id={id} />
          <Divider />
        </div>
      ))}
    </Paper>
  );
};

export default withRouter(
  connect(state => ({ user: state.user }), { showModal })(
    ProjectActionItemsComponent
  )
);
