import React, { useContext, useRef, useCallback } from 'react';
import { Paper } from 'helpers/themeSafeMui.helper';
import toCurrency from 'helpers/currency.helper';
import { BarChart, LastUpdated } from 'components';
import { useColors } from 'helpers/theme.helper';
import {
  ProjectContext,
  ProjectDetailsContext
} from '../../../context/Project.context';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import { ChildProjectsContext } from 'routes/Projects/context/ChildProjectsContext.context';

export default function AggregateDataGraphs() {
  const { project } = useContext(ProjectContext);
  const { projectDetails: currentProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const { childProjects, summaryDetails, syncWithTsheets } = useContext(
    ChildProjectsContext
  );
  const user = useCurrentUser();
  const getColor = useGetColor();
  const colors = useColors();
  if (!summaryDetails) return null;

  const contractValue = summaryDetails.contractValue || 0;
  const coValue = summaryDetails.coValue || 0;
  const estimatedLaborHours = summaryDetails.estimatedLaborHours || 0;
  const estimatedCoLaborHours = summaryDetails.estimatedCoLaborHours || 0;
  const estimatedCost = summaryDetails.estimatedCost || 0;
  const dollarValue = Number(summaryDetails.dollarValue) || 0;
  const currentLaborHours = summaryDetails.currentLaborHours || 0;
  const currentlyBilled = summaryDetails.currentlyBilled || 0;
  const currentCost = summaryDetails.currentCost || 0;

  const totalEstimatedHours =
    Number(estimatedLaborHours) + Number(estimatedCoLaborHours);
  const estimatedProfit = dollarValue - estimatedCost;
  const estimatedPercentProfit = (estimatedProfit / dollarValue) * 100;
  const overBudget =
    currentCost - estimatedCost > 0 ? currentCost - estimatedCost : 0;
  const actualProfit = estimatedProfit - overBudget;
  const actualPercentProfit = (actualProfit / dollarValue) * 100;

  const allProjects = [
    { ...project, details: currentProjectDetails },
    ...childProjects
  ];

  // BILLING
  const billingSegments =
    dollarValue === 0
      ? [
          {
            title: `${toCurrency(currentlyBilled)} - Currently Billed`,
            portion: 1
          }
        ]
      : [
          {
            title: `${toCurrency(contractValue)} - Base Contract Value`,
            portion: contractValue / dollarValue
          },
          {
            title: `${toCurrency(coValue)} - CO Value`,
            portion: coValue / dollarValue,
            alignRight: true
          }
        ];

  // LABOR
  const laborSegments =
    totalEstimatedHours === 0
      ? [
          {
            title: `${currentLaborHours} hrs - Current Labor Hours`,
            portion: 1
          }
        ]
      : [
          {
            title: `${estimatedLaborHours} hrs - Estimated Labor Hours`,
            portion: estimatedLaborHours / totalEstimatedHours
          },
          {
            title: `${estimatedCoLaborHours} hrs - Estimated CO Labor Hours`,
            portion: estimatedCoLaborHours / totalEstimatedHours,
            alignRight: true
          }
        ];

  const allProjectHoursTotal = allProjects.reduce((total, project) => {
    if (!project.details) return total;
    const estimatedLaborHours = project.details.estimatedLaborHours || 0;
    const estimatedCoLaborHours = project.details.estimatedCoLaborHours || 0;
    const projectCurrentLaborHours = project.details.currentLaborHours || 0;
    const projectEstimatedHours =
      Number(estimatedLaborHours) + Number(estimatedCoLaborHours);
    return total + Math.max(projectCurrentLaborHours, projectEstimatedHours);
  }, 0);
  const laborByProjectSegments = allProjects
    .map((project, idx) => {
      if (!project.details) return null;
      const estimatedLaborHours = project.details.estimatedLaborHours || 0;
      const estimatedCoLaborHours = project.details.estimatedCoLaborHours || 0;
      const projectCurrentLaborHours = project.details.currentLaborHours || 0;
      const projectEstimatedHours =
        Number(estimatedLaborHours) + Number(estimatedCoLaborHours);

      const projectTitle = `${project.projectNumber} ${project.name}`;

      if (projectEstimatedHours === 0) {
        const percentOfTotal = projectCurrentLaborHours / allProjectHoursTotal;
        const hoursText = `${projectCurrentLaborHours} Current Labor Hours`;
        return {
          title: projectTitle,
          label: `${projectTitle} - ${hoursText}`,
          filledText: hoursText,
          filledStyle: { color: null },
          portion: percentOfTotal,
          filled: 1,
          color: getColor(idx)
        };
      }
      const percentOfTotal =
        Math.max(projectEstimatedHours, projectCurrentLaborHours) /
        allProjectHoursTotal;
      const filled = projectCurrentLaborHours / projectEstimatedHours;
      const hoursUsedText = `${projectCurrentLaborHours} Current Labor Hours`;
      const hoursRemainingText = `${projectEstimatedHours -
        projectCurrentLaborHours} Labor Hours Remaining`;
      return {
        title: projectTitle,
        label: `${projectTitle} - ${hoursUsedText} ${percent(filled)}`,
        offLabel: `${projectTitle} - ${hoursRemainingText} ${percent(
          1 - filled
        )}`,
        filledText: `${projectCurrentLaborHours} hrs ${percent(filled)}`,
        filledStyle: { color: null },
        portion: percentOfTotal,
        filled: Math.min(filled, 1),
        color: getColor(idx)
      };
    })
    .filter(segment => segment)
    .filter(segment => segment.portion);

  // COST
  const costSegments = estimatedCost
    ? [
        {
          title: `${toCurrency(estimatedCost)} - Estimated Cost`,
          label: `${toCurrency(currentCost)} Used`,
          offLabel: `${toCurrency(estimatedCost - currentCost)} Remaining`,
          portion: estimatedCost / dollarValue,
          filled: currentCost / estimatedCost
        },
        {
          title: `${toCurrency(
            estimatedProfit
          )} (${estimatedPercentProfit.toFixed(1)}%) - Estimated Gross Profit`,
          label: `${toCurrency(overBudget)} Over Budget`,
          offLabel: `${toCurrency(actualProfit)} (${actualPercentProfit.toFixed(
            1
          )}%) Actual Gross Profit`,
          portion: estimatedProfit / dollarValue,
          color: colors.error,
          filled: overBudget / estimatedProfit
        }
      ]
    : [
        {
          title: `${toCurrency(currentCost)} - Current Cost`,
          label: `${toCurrency(currentCost)} - Current Cost`,
          portion: 1,
          filled: 1
        }
      ];

  const maxDollarTotal = allProjects.reduce((total, project) => {
    if (!project.details) return total;
    const estimatedCost = project.details.estimatedCost || 0;
    const currentCost = project.details.currentCost || 0;

    const maxDollar = Math.max(currentCost, estimatedCost);
    return total + maxDollar;
  }, 0);
  const costSegmentsByProject = allProjects
    .map((project, idx) => {
      if (!project.details) return null;
      const estimatedCost = Number(project.details.estimatedCost) || 0;
      const currentCost = Number(project.details.currentCost) || 0;

      const maxDollar = Math.max(currentCost, estimatedCost);
      const percentOfTotal = maxDollar / maxDollarTotal;
      const projectTitle = `${project.projectNumber} ${project.name}`;
      const usedText = `${projectTitle} - ${toCurrency(currentCost)} Used`;
      if (!estimatedCost)
        return {
          title: usedText,
          label: usedText,
          offLabel: projectTitle,
          filledText: `${toCurrency(currentCost)} Used`,
          filledStyle: { color: null },
          portion: percentOfTotal,
          filled: 1,
          color: getColor(idx)
        };

      const filled = currentCost / estimatedCost;
      return {
        title: `${usedText}  ${percent(filled)}`,
        label: `${usedText}  ${percent(filled)}`,
        offLabel: `${projectTitle} - ${toCurrency(
          estimatedCost - currentCost
        )} Remaining ${percent(1 - filled)}`,
        filledText: `${toCurrency(currentCost)} Used ${percent(filled)}`,
        filledStyle: { color: null },
        portion: percentOfTotal,
        filled: Math.min(filled, 1),
        color: getColor(idx)
      };
    })
    .filter(seg => seg);

  return (
    <Paper style={{ padding: 16, position: 'relative' }}>
      {user.hasPermission('projects_tier_2') && (
        <>
          <BarChart
            title="Billing"
            segments={billingSegments}
            filled={{
              portion: dollarValue === 0 ? 1 : currentlyBilled / dollarValue,
              label: `${toCurrency(currentlyBilled)} Currently Billed`,
              offLabel: `${toCurrency(
                dollarValue - currentlyBilled
              )} Left to Bill`
            }}
          />
          <LastUpdated
            lastUpdated={summaryDetails.currentlyBilledLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
        </>
      )}

      <BarChart
        title="Labor"
        segments={laborSegments}
        filled={{
          portion:
            totalEstimatedHours === 0
              ? 1
              : currentLaborHours / totalEstimatedHours,
          label: `${Number(currentLaborHours)} Current Labor Hours`,
          offLabel: `${totalEstimatedHours -
            currentLaborHours} Labor Hours Remaining`
        }}
      />
      <LastUpdated
        lastUpdated={summaryDetails.currentLaborHoursLastUpdate}
        onRefresh={syncWithTsheets}
        toolTipValue="Imported from TSheets"
      />

      {user.hasPermission('projects_tier_2') && (
        <>
          <BarChart title="Estimated/Actual Costs" segments={costSegments} />
          <LastUpdated
            lastUpdated={summaryDetails.currentCostLastUpdate}
            toolTipValue="Imported from QuickBooks"
          />
        </>
      )}

      <BarChart title="Labor by Project" segments={laborByProjectSegments} />

      {user.hasPermission('projects_tier_2') && (
        <>
          <div style={{ paddingBottom: 21 }} />
          <BarChart
            title="Estimated/Actual Costs by Project"
            segments={costSegmentsByProject}
          />
        </>
      )}
    </Paper>
  );
}

function useGetColor() {
  const foundColors = useRef({ ...colors });
  const getColor = useCallback(key => {
    if (!foundColors.current[key]) foundColors.current[key] = createColor();
    return foundColors.current[key];
  }, []);
  return getColor;
}

const colors = {
  // Blue
  0: '#74B4FF',

  // Yellow
  1: '#F8E71C',

  // Green
  2: '#44FF44',

  // Orange
  3: '#FFBB47',

  // Teal
  4: '#3BFFFF'
};

function createColor() {
  const R = hexComponent();
  const G = hexComponent();
  const B = hexComponent();
  return '#' + R + G + B;
}

function hexComponent(min = 128) {
  const maxRand = 256 - min;
  const dec = min + Math.floor(Math.random() * maxRand);
  let hex = dec.toString(16);
  if (hex.length === 1) hex = '0' + hex;
  return hex;
}

function percent(num) {
  const parsed = Number(num) * 100;
  if (isNaN(parsed)) return '';
  return `(${parsed.toFixed(1)}%)`;
}
