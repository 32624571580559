import React, { useState } from 'react';
import http from 'helpers/http.helper';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog
} from 'helpers/themeSafeMui.helper';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MultiSearchDropdown, Toast } from 'components';
import { useActionItem, useSetActionItem } from 'state/actionItems.state';

const durations = [
  { label: 'Not Applicable', value: 0 },
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 }
];

const EditActionItemModal = props => {
  const { onClose, open, id } = props;
  const actionItem = useActionItem(id);
  const setActionItem = useSetActionItem();

  const [title, setTitle] = useState(actionItem.title);
  const [notes, setNotes] = useState(actionItem.notes);
  const [dueDate, setDueDate] = useState(actionItem.dueDate);
  const [duration, setDuration] = useState(actionItem.duration);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const handleEnter = () => {
    http()
      .get('/users')
      .then(res => {
        setUsers(res);
        setAssignedUsers(
          res.filter(user => actionItem.assignedUserIds.includes(user.id))
        );
      })
      .catch(err => Toast.show(err.message));
  };

  const save = e => {
    e.preventDefault();

    http()
      .put(`/action-items/${actionItem.id}`, {
        title: title,
        notes: notes,
        dueDate: dueDate,
        duration: duration,
        assignedUserIds: assignedUsers.map(el => el.id)
      })
      .then(res => {
        setActionItem(res);
        onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Dialog
      TransitionProps={{ onEnter: handleEnter }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <form autoComplete="off" onSubmit={save}>
        <DialogTitle>Edit Action Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Action Item Title"
            fullWidth
            required
            value={title}
            name="title"
            margin="normal"
            onChange={e => setTitle(e.target.value)}
          />

          <MultiSearchDropdown
            items={users}
            onChange={assignedUsers => setAssignedUsers(assignedUsers)}
            selected={assignedUsers}
            itemToString={item => item.name}
            required
            TextFieldProps={{
              label: 'Assigned To',
              fullWidth: true,
              margin: 'normal'
            }}
          />

          <TextField
            name="notes"
            label="Notes"
            multiline
            maxRows="4"
            fullWidth
            style={{ marginBottom: 10, marginTop: 15 }}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                format="MM/DD/YYYY hh:mm a"
                autoOk
                fullWidth
                label="Due Date"
                value={dueDate}
                onChange={date => setDueDate(date)}
                required
                margin="normal"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl required fullWidth margin="normal">
                <InputLabel>Duration</InputLabel>
                <Select
                  value={duration}
                  onChange={e => setDuration(e.target.value)}
                >
                  {durations.map(item => (
                    <MenuItem key={item.label} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditActionItemModal;
