import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText
} from 'helpers/themeSafeMui.helper';
import { clearUser } from 'ducks/user.duck';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function ProfileMenu({ anchor, onClose, children, ...props }) {
  const [signOutOpen, setSignOutOpen] = useState(false);

  const toggleSignOut = () => {
    setSignOutOpen(old => !old);
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={signOutOpen} onClose={toggleSignOut}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleSignOut} color="default">
            Cancel
          </Button>
          <Button onClick={props.clearUser} color="primary" autoFocus>
            Sign out
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={anchor}
        MenuListProps={{ style: { padding: 0 } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchor)}
        onClose={onClose}
      >
        {children}
        <MenuItem
          component={Link}
          to={`/users/${props.user.id}`}
          onClick={onClose}
        >
          My Account
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/users/${props.user.id}/trainings`}
          onClick={onClose}
        >
          My Training
        </MenuItem>
        <MenuItem component={Link} to={`/themes`} onClick={onClose}>
          Theme
        </MenuItem>
        <MenuItem onClick={toggleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default connect(state => ({ user: state.user }), { clearUser })(
  ProfileMenu
);
