import React from 'react';
import { connect } from 'react-redux';
import { Toast, SearchDropdown, AddressPredict } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

class EditProjectModal extends React.Component {
  state = {
    projectNumber: '',
    name: '',
    company: null,
    companyId: '',
    statusId: '',
    companies: [],
    accountManagers: [],
    accountManager: '',
    status: '',
    address: ''
  };

  componentDidMount() {
    this.getAccountManagers();

    http()
      .get('/companies')
      .then(response => {
        this.setState({
          companies: response
        });
      })
      .catch(err => Toast.show(err.message));
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();

    http()
      .put('/projects/' + this.props.project.id, {
        name: this.state.name,
        companyId: this.state.company.id,
        statusId: this.state.status.id,
        address: this.state.address,
        projectNumber: this.state.projectNumber
      })
      .then(res => {
        Toast.show(this.state.name + ' was updated.');
        this.props.setProject(res);
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  setProject = () => {
    this.setState({
      name: this.props.project.name,
      companyId: this.props.companyId,
      company: this.state.companies.find(current => {
        return current.id === this.props.project.companyId;
      }),
      status: this.props.projectStatuses.find(current => {
        return current.id === this.props.project.statusId;
      }),
      address: this.props.project.address || '',
      projectNumber: this.props.project.projectNumber
    });
  };

  getAccountManagers = () => {
    http()
      .get('/account-managers')
      .then(res => {
        this.setState({ accountManagers: res });
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: this.setProject }}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <form autoComplete="off" onSubmit={this.save}>
          <DialogTitle style={{ textAlign: 'center' }}>
            Edit Project
          </DialogTitle>

          <DialogContent style={{ overflow: 'visible' }}>
            <Grid container spacing={3}>
              {this.props.user.hasPermission('super_admin') && (
                <Grid item xs={12}>
                  <TextField
                    name="projectNumber"
                    label="Project Number"
                    autoFocus
                    required
                    fullWidth
                    margin="dense"
                    value={this.state.projectNumber}
                    onChange={this.handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Project Name"
                  autoFocus
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <InputLabel>Project Status</InputLabel>
                  <Select
                    value={this.state.status}
                    onChange={this.handleChange}
                    name="status"
                  >
                    {this.props.projectStatuses.map(status => (
                      <MenuItem key={status.id} value={status}>
                        {status.status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <SearchDropdown
                  fullWidth
                  label="Company"
                  itemToString={item => item.name}
                  items={this.state.companies}
                  onChange={company => this.setState({ company })}
                  value={this.state.company}
                  required
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12}>
                <AddressPredict
                  required
                  label="Job Site Address"
                  onChange={address => this.setState({ address })}
                  value={this.state.address}
                />
              </Grid>

              <Grid item xs={12} />
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default connect(state => ({
  user: state.user,
  projectStatuses: state.projectStatuses
}))(EditProjectModal);
