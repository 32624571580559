import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AddressPredict, DropZone } from 'components';

const CustomInput = props => {
  const {
    value,
    type,
    label,
    options,
    required,
    onChange,
    margin,
    disabled,
    FormControlProps,
    ...rest
  } = props;

  if (type === 'dropdown') {
    return (
      <FormControl
        {...FormControlProps}
        required={required}
        fullWidth
        margin={margin || 'normal'}
      >
        {label && <InputLabel shrink>{label}</InputLabel>}
        <Select
          {...rest}
          value={
            rest.getBooleanValue ? rest.getBooleanValue(value) : value || ''
          }
          onChange={e => onChange(e.target.value)}
          required={required}
          disabled={disabled}
        >
          {options.map((option, idx) => (
            <MenuItem
              key={`${option.value},${option.label},${idx}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === 'boolean') {
    return (
      <FormControl
        required={required}
        fullWidth
        margin={margin || 'normal'}
        {...FormControlProps}
      >
        {label && <InputLabel shrink>{label}</InputLabel>}
        <RadioGroup
          value={typeof value === 'boolean' ? value.toString() : null}
          onChange={e => onChange(e.target.value === 'true')}
          required={required}
          style={{ flexDirection: 'row', marginTop: 16, marginBottom: -10 }}
        >
          <FormControlLabel
            disabled={disabled}
            value="true"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            disabled={disabled}
            value="false"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    );
  } else if (type === 'date') {
    return (
      <KeyboardDatePicker
        label={label}
        format="MM/DD/YYYY"
        autoOk
        value={value}
        InputLabelProps={{ shrink: Boolean(label) }}
        fullWidth
        margin={margin || 'normal'}
        required={required}
        onChange={date => onChange(date)}
        disabled={disabled}
        {...rest}
      />
    );
  } else if (type === 'address') {
    return (
      <AddressPredict
        fullWidth
        InputLabelProps={{ shrink: Boolean(label) }}
        label={label}
        margin={margin || 'normal'}
        value={value || ''}
        onChange={val => onChange(val)}
        required={required}
      />
    );
  } else if (type === 'currency') {
    return (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: Boolean(label) }}
        label={label}
        margin={margin || 'normal'}
        value={value}
        onChange={e => onChange(e.target.value)}
        required={required}
        type="number"
        disabled={disabled}
        inputProps={{ step: '0.01' }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        {...rest}
      />
    );
  } else if (type === 'percent') {
    return (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: Boolean(label) }}
        label={label}
        margin={margin || 'normal'}
        value={value}
        onChange={e => onChange(e.target.value)}
        required={required}
        type="number"
        disabled={disabled}
        inputProps={{ step: '0.01' }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        {...rest}
      />
    );
  } else if (type === 'encryptedFile') {
    return (
      <FormControl
        {...FormControlProps}
        required={required}
        fullWidth
        margin={margin || 'normal'}
      >
        {label && <InputLabel shrink>{label}</InputLabel>}
        <DropZone
          style={{ minHeight: 100, marginTop: 16 }}
          isUploaded={value}
          onReset={() => onChange(null)}
          onRead={files =>
            onChange({ base64: files[0].base64, mimeType: files[0].mimeType })
          }
          title={label}
          validExtensions={['jpeg', 'jpg', 'png', 'pdf']}
        />
      </FormControl>
    );
  } else {
    return (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: Boolean(label) }}
        label={label}
        margin={margin || 'normal'}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        required={required}
        type={type}
        disabled={disabled}
        {...rest}
      />
    );
  }
};

export default CustomInput;
