import isUsingOneDrive from 'helpers/isUsingOneDrive.helper';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectContext } from 'routes/Projects/context/Project.context';

const ProjectPageTitleUpdater = () => {
  const { project } = useContext(ProjectContext);
  const [isTola] = useState(isUsingOneDrive);
  const initialTitle = `STEPS ${
    isTola ? 'TOLA' : ''
  } - System Tech Employee & Project System`;

  const name =
    project.projectNumber &&
    project.name &&
    `${project.projectNumber} - ${project.name}`;

  useEffect(() => {
    if (name) document.title = name;
    return () => {
      document.title = initialTitle;
    };
  }, [name, initialTitle]);
  return <></>;
};

export default ProjectPageTitleUpdater;
