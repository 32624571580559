import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import AttemptQuiz from './routes/AttemptQuiz.route';
import Edit from './routes/EditQuiz.route';
import ReviewQuiz from './routes/ReviewQuiz.route';

export default function TrainingQuizzes() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:quizId/edit`} exact>
        <Edit />
      </Route>
      <Route path={`${match.url}/:quizId/attempt`} exact>
        <AttemptQuiz />
      </Route>
      <Route path={`${match.url}/:quizId/review-attempt`} exact>
        <ReviewQuiz />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
}
