import { useCallback } from 'react';
import http from 'helpers/http.helper';
import { Toast } from 'components';
import { useSetActionItems } from 'state/actionItems.state';

export default function UseMarkAllComplete(userId) {
  const setActionItems = useSetActionItems();

  return useCallback(
    async function markAllComplete() {
      try {
        const items = await http().put('/action-items/mark-all-complete', {
          userId
        });
        setActionItems(items);
      } catch (err) {
        Toast.showErr(err);
      }
    },
    [setActionItems, userId]
  );
}
