import React from 'react';
import { percents } from 'helpers/projectPercents.helper';
import ProjectBoardColumn from './ProjectBoardColumn.component';

const BreakoutPercentColumns = React.memo(
  ({ breakout, projects, loading, statusId }) => {
    if (!breakout)
      return (
        <div
          style={{
            width: 300,
            flexShrink: 0,
            overflowY: 'auto',
            height: '100%'
          }}
        >
          <ProjectBoardColumn
            statusId={statusId}
            loading={loading}
            projects={projects}
          />
        </div>
      );

    return percents.map((percent, idx) => (
      <div
        key={percent}
        style={{
          width: 300,
          flexShrink: 0,
          overflowY: 'auto',
          height: '100%'
        }}
      >
        <ProjectBoardColumn
          statusId={statusId + `-${percent}%`}
          loading={loading}
          projects={projects.filter(
            project =>
              (idx === 0 && !project.probability) ||
              project.probability === percent
          )}
        />
      </div>
    ));
  }
);

export default BreakoutPercentColumns;
