import { useCallback } from 'react';
import isEqual from 'lodash.isequal';
import {
  atomFamily,
  DefaultValue,
  GetRecoilValue,
  selector,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';

interface ActionItem {
  id: string;
  archived: boolean;
  created: string;
  createdBy: string | null;
  dateCompleted: string | null;
  projectId: string | null;
  title: string;
  dueDate: string;
  duration: string | null;
  notes: string | null;
  actionItemLink: string | null;
  assignedUserIds: string[];
  seenByIds: string[];
}

type SortByKey = 'created' | 'dueDate';

interface SortByDateOptions {
  get: GetRecoilValue;
  userId: string;
  sortByKey: SortByKey;
  sortOrder?: 'asc' | 'desc';
}

type SortedActionItemsByUserOptions = [string, SortByKey, ('asc' | 'desc')?];

function sortByDate({
  get,
  userId,
  sortByKey,
  sortOrder = 'asc'
}: SortByDateOptions) {
  const ids = get(ActionItemByUserAtomFamily(userId));
  const actionItems = ids.map(id => get(ActionItemAtomFamily(id)) || id);
  actionItems.sort((a, b) => {
    if (typeof a === 'string' && typeof b === 'string') return 0;
    if (typeof a === 'string') return 1;
    if (typeof b === 'string') return 1;

    return sortOrder === 'asc'
      ? new Date(a[sortByKey]).getTime() - new Date(b[sortByKey]).getTime()
      : new Date(b[sortByKey]).getTime() - new Date(a[sortByKey]).getTime();
  });
  return actionItems.map(item => (typeof item === 'string' ? item : item.id));
}

const ActionItemAtomFamily = atomFamily<ActionItem | null, string>({
  key: 'action-item-atom-family',
  default: null
});

const ActionItemByUserAtomFamily = atomFamily<string[], string>({
  key: 'action-item-by-user-atom-family',
  default: []
});

const SortedActionItemsByUserSelectorFamily = selectorFamily<
  (ActionItem | null)[],
  SortedActionItemsByUserOptions
>({
  key: 'action-item-by-user-atom-family',
  get: ([userId, sortByKey, sortOrder]) => ({ get }) =>
    sortByDate({ get, userId, sortByKey, sortOrder }).map(id =>
      get(ActionItemAtomFamily(id))
    )
});

const ActionItemsSelectorFamily = selectorFamily<
  (ActionItem | null)[],
  string[]
>({
  key: 'action-items-selector-family',
  get: ids => ({ get }) => ids.map(id => get(ActionItemAtomFamily(id)))
});

const setActionItemsSelector = selector<ActionItem[]>({
  key: 'set-action-items-selector',
  get: () => [],
  set: ({ set, get }, actionItems) => {
    if (actionItems instanceof DefaultValue) return;

    type UserActionItemUpdates = {
      [userId: string]: string[];
    };
    const userActionItemUpdates: UserActionItemUpdates = {};

    for (let actionItem of actionItems) {
      const found = get(ActionItemAtomFamily(actionItem.id));
      if (isEqual(found, actionItem)) return;
      set(ActionItemAtomFamily(actionItem.id), actionItem);

      const getBefore = (userId: string) =>
        userActionItemUpdates[userId]
          ? userActionItemUpdates[userId]
          : get(ActionItemByUserAtomFamily(userId));

      if (!found) {
        // ADD NEW ACTION ITEM
        actionItem.assignedUserIds.forEach(userId => {
          let before = getBefore(userId);
          if (before.includes(actionItem.id)) return;
          const updated = [...before, actionItem.id];
          userActionItemUpdates[userId] = updated;
        });
      } else {
        // UPDATE ACTION ITEM
        const old = found;
        const replacement = actionItem;
        // ADD ACTION ITEMS TO USERS' LISTS
        const addedUserIds = replacement.assignedUserIds.filter(
          newUser => !old.assignedUserIds.includes(newUser)
        );
        addedUserIds.forEach(userId => {
          let before = getBefore(userId);
          if (before.includes(actionItem.id)) return;
          const updated = [...before, actionItem.id];
          userActionItemUpdates[userId] = updated;
        });
        // REMOVE ACTION ITEMS FROM USERS' LISTS
        const removedUserIds = old.assignedUserIds.filter(
          oldUser => !replacement.assignedUserIds.includes(oldUser)
        );
        removedUserIds.forEach(userId => {
          const before = getBefore(userId);
          const idx = before.indexOf(actionItem.id);
          if (idx === -1) return;
          const copy = [...before];
          copy.splice(idx, 1);
          userActionItemUpdates[userId] = copy;
        });
      }
    }
    for (let userId of Object.keys(userActionItemUpdates)) {
      set(ActionItemByUserAtomFamily(userId), userActionItemUpdates[userId]);
    }
  }
});

const deleteActionItemsSelector = selector<ActionItem[]>({
  key: 'delete-action-items-selector',
  get: () => [],
  set: ({ set, get, reset }, actionItems) => {
    if (actionItems instanceof DefaultValue) return;

    for (let actionItem of actionItems) {
      const found = get(ActionItemAtomFamily(actionItem.id));
      reset(ActionItemAtomFamily(actionItem.id));
      if (!found) continue;

      found.assignedUserIds.forEach(userId =>
        set(ActionItemByUserAtomFamily(userId), old => {
          const idx = old.indexOf(actionItem.id);
          if (idx === -1) return old;
          const copy = [...old];
          copy.splice(idx, 1);
          return copy;
        })
      );
    }
  }
});

// USER ACTION ITEMS
export const useUserActionItems = (userId: string) =>
  useRecoilValue(ActionItemByUserAtomFamily(userId));

export const useSortedUserActionItems = (
  userId: string,
  sortByKey: SortByKey,
  sortOrder: 'asc' | 'desc' = 'asc'
) =>
  useRecoilValue(
    SortedActionItemsByUserSelectorFamily([userId, sortByKey, sortOrder])
  );

// ACTION ITEMS
export const useActionItems = (ids: string[]) =>
  useRecoilValue(ActionItemsSelectorFamily(ids));

export const useActionItem = (ids: string) =>
  useRecoilValue(ActionItemAtomFamily(ids));

// SET ACTION ITEMS
export const useSetActionItems = () =>
  useSetRecoilState(setActionItemsSelector);

export const useSetActionItem = () => {
  const setActionItems = useSetRecoilState(setActionItemsSelector);
  return useCallback((actionItem: ActionItem) => setActionItems([actionItem]), [
    setActionItems
  ]);
};

// DELETE ACTION ITEMS
export const useDeleteActionItems = () =>
  useSetRecoilState(deleteActionItemsSelector);

export const useDeleteActionItem = () => {
  const deleteActionItem = useSetRecoilState(deleteActionItemsSelector);
  return useCallback(
    (actionItem: ActionItem) => deleteActionItem([actionItem]),
    [deleteActionItem]
  );
};
