import React, { useState } from 'react';
import { IframeModal } from 'components';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

const TRAINING_CALENDAR_URL =
  'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FBoise&showNav=1&showPrint=0&showCalendars=0&title=Training%20Calendar&mode=WEEK&showDate=1&showTitle=1&showTabs=1&src=Y184dXRwMzQ3NmFpYmF2NmZkNXZnZHNqbm40c0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udXNhLm9mZmljaWFsI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%238E24AA&color=%23009688';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      '&:hover span': {
        textDecoration: 'underline'
      }
    }
  })
);

export default function TrainingCalendar() {
  const [open, setOpen] = useState(false);
  const { wrapper } = useStyles();
  return (
    <>
      <IframeModal
        open={open}
        onClose={() => setOpen(false)}
        url={TRAINING_CALENDAR_URL}
      />

      <Typography
        color="primary"
        className={wrapper}
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <CalendarTodayIcon
          color="action"
          name="Training Calendar"
          style={{ fontSize: '2.4em' }}
        />
        <br />
        <Typography variant="caption">Training Calendar</Typography>
      </Typography>
    </>
  );
}
