import React from 'react';
import { DriveFolderViewer } from 'components';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';

const TemplateDocs = props => {
  const colors = useColors();
  return (
    <div style={{ margin: -24 }}>
      <Typography
        style={{
          padding: 8,
          textAlign: 'center',
          color: '#FFFFFF',
          background: colors.darkGrey
        }}
      >
        * This folder should only contain individual template files. Sub folders
        will not work.
      </Typography>
      <DriveFolderViewer
        isTemplate
        style={{ padding: 24 }}
        apiPath={'/template-docs'}
      />
    </div>
  );
};

export default TemplateDocs;
