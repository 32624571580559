import React from 'react';
import { Button, TextField } from 'helpers/themeSafeMui.helper';
import { Link } from 'react-router-dom';
import http from 'helpers/http.helper';
import { Toast, CardWrapper } from 'components';

export default class ForgotPassword extends React.Component {
  state = {
    email: ''
  };

  submit = e => {
    e.preventDefault();

    http()
      .post('/users/auth/forgot-password', {
        email: this.state.email
      })
      .then(res => {
        this.props.history.push({
          pathname: '/',
          state: { message: res.message }
        });
      })
      .catch(err => Toast.show(err.message));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <CardWrapper>
        <form autoComplete="off" onSubmit={this.submit}>
          <TextField
            name="email"
            label="Email Address"
            autoFocus
            required
            fullWidth
            margin="normal"
            value={this.state.email}
            onChange={this.handleChange}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Send password reset link
          </Button>

          <Button
            component={Link}
            fullWidth
            to="/"
            size="small"
            color="primary"
            style={{ marginTop: 8 }}
          >
            I know my password.
          </Button>
        </form>
      </CardWrapper>
    );
  }
}
