import {
  Button,
  IconButton,
  Paper,
  Typography
} from 'helpers/themeSafeMui.helper';
import { DragAndDrop, NoItemsResult, Toast } from 'components';
import { useColors } from 'helpers/theme.helper';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  ProjectContext,
  ProjectScheduleTasksContext
} from 'routes/Projects/context/Project.context';
import DateGrid from './DateGrid.component';
import Labels from './Labels.component';
import http from 'helpers/http.helper';
import { v4 as uuid } from 'uuid';
import EditIcon from '@material-ui/icons/Edit';
import CreateTaskModal from '../modals/CreateTask.modal';
import EditTaskModal from '../modals/EditTask.modal';
import DownloadPreview from '../modals/DownloadPreview.component';
import useCurrentUser from 'hooks/useCurrentUser.hook';
import LinkIcon from '@material-ui/icons/Link';

const TaskTable2 = () => {
  const colors = useColors();
  const currentUser = useCurrentUser();
  const [labels, setLabels] = useState([]);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [todayXpos, setTodayXpos] = useState(null);
  const containerRef = useRef(null);
  const {
    projectScheduleTasks,
    setProjectScheduleTasks,
    fetchTasks
  } = useContext(ProjectScheduleTasksContext);
  const createDefaultLabels = useCreateDefaultLabels();
  const { project } = useContext(ProjectContext);

  useEffect(() => {
    if (project.id)
      http()
        .get(`/projects/${project.id}/schedule-labels`)
        .then(labels =>
          labels.length ? labels : createDefaultLabels(project.id)
        )
        .then(setLabels)
        .catch(Toast.showErr);
  }, [project.id, createDefaultLabels]);

  useEffect(() => {
    if (containerRef.current && todayXpos)
      containerRef.current.scrollTo(todayXpos, 0);
  }, [todayXpos]);

  const noTasks = !projectScheduleTasks.length;
  const rowHeight = 26;

  const minWeek = projectScheduleTasks.reduce((minWeek, task) => {
    if (!minWeek) return moment(task.startDate).startOf('week');
    const taskStart = moment(task.startDate).startOf('week');
    if (taskStart.isBefore(minWeek)) return taskStart;
    return minWeek;
  }, null);

  const maxWeek = projectScheduleTasks.reduce((maxWeek, task) => {
    if (!maxWeek) return moment(task.endDate).endOf('week');
    const taskEnd = moment(task.endDate).endOf('week');
    if (taskEnd.isAfter(maxWeek)) return taskEnd;
    return maxWeek;
  }, null);

  const labelColors = labels.reduce(
    (acc, label) => Object.assign(acc, { [label.id]: label.color }),
    {}
  );

  const getDayTotal = dayMoment => {
    const total = projectScheduleTasks.reduce(
      (total, task) =>
        taskActiveOnDay(task, dayMoment) ? total + task.techCount : total,
      0
    );
    return total;
  };

  const getColor = (task, dayMoment) =>
    labelColors[task.labelId] && taskActiveOnDay(task, dayMoment)
      ? '#' + labelColors[task.labelId]
      : null;

  const getTextContent = (task, dayMoment) =>
    taskActiveOnDay(task, dayMoment)
      ? task.techCount === 0
        ? '-'
        : task.techCount
      : null;

  const onDrop = dropped => {
    const ordered = dropped.map((task, order) => ({ ...task, order }));
    setProjectScheduleTasks(ordered);
    Promise.all(
      ordered.map(task =>
        http().put(`/projects/${task.projectId}/schedule-tasks/${task.id}`, {
          order: task.order
        })
      )
    )
      .then(fetchTasks)
      .catch(Toast.showErr);
  };
  const getCellStyle = dayMoment => {
    if (moment().isSame(dayMoment, 'day'))
      return { backgroundColor: colors.paperYellow };
    return {};
  };

  const getLinked = task => {
    return projectScheduleTasks.find(t => t.id === task.linkedTo);
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <CreateTaskModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onCreate={fetchTasks}
        labels={labels}
        tasks={projectScheduleTasks}
      />
      <EditTaskModal
        open={!!editingTask}
        onClose={() => setEditingTask(null)}
        onUpdate={fetchTasks}
        task={editingTask}
        tasks={projectScheduleTasks}
        labels={labels}
      />
      <DownloadPreview
        open={downloadModalOpen}
        onClose={() => setDownloadModalOpen(false)}
        labels={labels}
        tasks={projectScheduleTasks}
        project={project}
      />
      {currentUser.hasPermission('projects_tier_1.5') && (
        <Button variant="contained" onClick={() => setCreateModalOpen(true)}>
          Create Task
        </Button>
      )}
      {noTasks ? (
        <Paper style={{ padding: 10, margin: '16px 0' }}>
          <NoItemsResult type="tasks" />
        </Paper>
      ) : (
        <>
          <Button
            variant="contained"
            onClick={() => setDownloadModalOpen(true)}
            style={{ marginLeft: 10 }}
          >
            Download
          </Button>
          <div
            ref={containerRef}
            style={{
              position: 'relative',
              display: 'flex',
              overflowX: 'auto',
              margin: '16px 0',
              maxHeight: '60vh'
            }}
          >
            <div style={{ position: 'sticky', left: 0, zIndex: 1 }}>
              <div
                style={{
                  height: 79,
                  backgroundColor: colors.paper,
                  position: 'sticky',
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  border: `1px solid ${colors.grey}`,
                  borderWidth: ' 0 1px 1px 0'
                }}
              />
              <DragAndDrop
                cardStyles={{
                  boxShadow: 0,
                  margin: 0,
                  padding: 0,
                  paddingRight: 8,
                  border: `1px solid ${colors.grey}`,
                  height: rowHeight,
                  whiteSpace: 'nowrap'
                }}
                onDrop={onDrop}
              >
                {projectScheduleTasks.map(task => (
                  <div value={task} key={task.id} id={task.id}>
                    <Typography
                      style={{ lineHeight: rowHeight + 'px' }}
                      component="div"
                    >
                      {task.taskName}
                      {getLinked(task) && (
                        <Typography
                          variant="caption"
                          style={{ verticalAlign: 'middle', color: 'grey' }}
                        >
                          {'   '}
                          <LinkIcon
                            fontSize="small"
                            style={{ verticalAlign: 'middle' }}
                          />
                          {'   '}

                          {getLinked(task).taskName}
                        </Typography>
                      )}

                      <IconButton
                        onClick={() => setEditingTask(task)}
                        style={{ marginLeft: 5 }}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Typography>
                  </div>
                ))}
              </DragAndDrop>
            </div>

            <DateGrid
              stickyHeader
              firstDay={minWeek}
              lastDay={maxWeek}
              filterDayOfWeek={moment =>
                moment.day() !== 6 && moment.day() !== 0
              }
              rows={projectScheduleTasks}
              rowHeight={rowHeight}
              getDayTotal={getDayTotal}
              getDayTextContent={getTextContent}
              getCellStyle={getCellStyle}
              getDayColor={getColor}
              setTodayXpos={setTodayXpos}
              cellStyle={{ padding: 2 }}
            />
          </div>
        </>
      )}
      <Typography variant="h5">Labels</Typography>
      <Labels labels={labels} setLabels={setLabels} />
    </div>
  );
};
export default TaskTable2;

function taskActiveOnDay(task, dayMoment) {
  const dow = dayMoment.day();

  // FRI
  if (dow === 5 && task.scheduleDaysPerWeek === 4) return false;

  // SAT
  if (dow === 6) return false;

  // SUN
  if (dow === 0) return false;

  return dayMoment.isBetween(task.startDate, task.endDate, 'day', '[]');
}

function useCreateDefaultLabels() {
  const currentUser = useCurrentUser();

  return useCallback(
    projectId => {
      if (currentUser.hasPermission('projects_tier_1.5')) {
        return createDefaultLabels(projectId);
      }
      return Promise.resolve([]);
    },
    [currentUser]
  );
}

function createDefaultLabels(projectId) {
  const defaults = [
    {
      id: uuid(),
      name: 'Blue Crew',
      color: '74B4FF'
    },
    {
      id: uuid(),
      name: 'Yellow Crew',
      color: 'F8E71C'
    },
    {
      id: uuid(),
      name: 'Green Crew',
      color: '44FF44'
    },
    {
      id: uuid(),
      name: 'Orange Crew',
      color: 'FFBB47'
    },
    {
      id: uuid(),
      name: 'Teal Crew',
      color: '3BFFFF'
    }
  ];
  return Promise.all(
    defaults.map((d, idx) =>
      http().post(`/projects/${projectId}/schedule-labels`, {
        ...d,
        order: idx
      })
    )
  );
}
