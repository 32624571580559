import React, { useContext, useState } from 'react';
import { Button } from 'helpers/themeSafeMui.helper';
import { ProjectDetailsContext } from 'routes/Projects/context/Project.context';
import http from 'helpers/http.helper';
import { CustomInput, Toast } from 'components';

export default function AddPlangridProject() {
  const { projectDetails, setProjectDetails } = useContext(
    ProjectDetailsContext
  );
  const [link, setLink] = useState('');
  const [editingLink, setEditingLink] = useState(false);
  const [loading, setLoading] = useState(false);

  if (projectDetails.plangridProjectId) return null;

  const createPlangridProject = async () => {
    try {
      setLoading(true);
      const result = await http().post(
        `/plangrid/projects/${projectDetails.projectId}`
      );
      setProjectDetails(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Toast.show(err.message);
    }
  };

  const getUUID = string => {
    if (!string) return null;
    const match = string.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    if (!match) return null;
    if (!match[0]) return null;
    return match[0];
  };

  const linkProject = () => {
    const plangridProjectId = getUUID(link);
    if (!plangridProjectId) return Toast.show('Invalid Plangrid link');
    http()
      .post(`/plangrid/projects/${projectDetails.projectId}/link/`, {
        plangridProjectId
      })
      .then(() =>
        http().put(`/projects/${projectDetails.projectId}/details`, {
          plangridLink: link || ''
        })
      )
      .then(setProjectDetails)
      .catch(Toast.showErr);
  };

  if (editingLink)
    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ display: 'flex', flex: '0 1 100%' }}>
          <CustomInput label="Plangrid Link" value={link} onChange={setLink} />
        </div>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="primary"
          onClick={linkProject}
        >
          Save
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          onClick={() => setEditingLink(false)}
        >
          Cancel
        </Button>
      </div>
    );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={createPlangridProject}
      >
        Create New PlanGrid Project
      </Button>
      <Button
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={() => setEditingLink(true)}
      >
        Link Existing Project
      </Button>
    </div>
  );
}
