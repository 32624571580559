import React, { useState } from 'react';
import { DashboardWrapper } from 'components';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useSelector } from 'react-redux';
import ThemeItem from './components/ThemeItem.component';

function Themes() {
  const user = useSelector(state => state.user);
  const [themes, setThemes] = useState([
    {
      themeTitle: 'Theme 1',
      colors: {
        primary: '#e94c62',
        secondary: '#c3405b'
      }
    },
    {
      themeTitle: 'Theme 2',
      colors: {
        primary: '#9f50b0',
        secondary: '#7f46a1'
      }
    },
    {
      themeTitle: 'Theme 3',
      colors: {
        primary: '#489789',
        secondary: '#397a6b'
      }
    },
    {
      themeTitle: 'Theme 4',
      colors: {
        primary: '#1a75bd',
        secondary: '#21548c'
      }
    },
    {
      themeTitle: 'Theme 5',
      colors: {
        primary: '#795548',
        secondary: '#5d4037'
      }
    },
    {
      themeTitle: 'Theme 6',
      colors: {
        primary: '#ea4634',
        secondary: '#d43e2e'
      }
    },
    {
      themeTitle: 'Theme 7',
      colors: {
        primary: '#5f7c8b',
        secondary: '#455a63'
      }
    },
    {
      themeTitle: 'Theme 8',
      colors: {
        primary: '#000000',
        secondary: '#525252'
      }
    }
  ]);

  const editTheme = idx => newTheme => {
    const copy = JSON.parse(JSON.stringify(themes));
    copy.splice(idx, 1, newTheme);
    setThemes(copy);
  };

  const editable = user.hasPermission('super_admin');

  return (
    <DashboardWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h2">Themes</Typography>
      </div>
      <ThemeItem isCurrentTheme={true} editable={editable} />
      {themes.map((theme, idx) => (
        <ThemeItem
          key={theme.themeTitle}
          theme={theme}
          editTheme={editTheme(idx)}
          editable={editable}
        />
      ))}
    </DashboardWrapper>
  );
}

export default Themes;
