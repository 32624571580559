import React, { useState, useEffect } from 'react';
import http from 'helpers/http.helper';
import {
  TextField,
  Typography,
  Grid,
  Button,
  Divider
} from 'helpers/themeSafeMui.helper';
import { Toast, SearchDropdown } from 'components';
import { setAdminSettings } from 'ducks/adminSettings.duck';
import { connect } from 'react-redux';
import Areas from '../../components/Areas.component';
import PoShipToOptions from './po-ship-to-options';

function MiscTab(props) {
  const { adminSettings, setAdminSettings } = props;
  const [i9DocumentId, setI9DocumentId] = useState('');
  const [w4DocumentId, setW4DocumentId] = useState('');
  const [w4IdahoDocumentId, setW4IdahoDocumentId] = useState('');
  const [sudoCompany, setSudoCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [weeklyZoomLink, setWeeklyZoomLink] = useState('');

  useEffect(() => {
    http()
      .get('/companies')
      .then(res => {
        setCompanies(res);
        const sudo = res.find(
          company => company.id === adminSettings.sudoCompanyId
        );
        setSudoCompany(sudo || null);
      })
      .catch(err => Toast.show(err.message));
  }, [adminSettings.sudoCompanyId]);

  useEffect(() => {
    setI9DocumentId(adminSettings.i9DocumentId || '');
    setW4DocumentId(adminSettings.w4DocumentId || '');
    setW4IdahoDocumentId(adminSettings.w4IdahoDocumentId || '');
    setWeeklyZoomLink(adminSettings.weeklyZoomLink || '');
  }, [adminSettings]);

  const save = () => {
    http()
      .put('/settings', {
        i9DocumentId: i9DocumentId || undefined,
        w4DocumentId: w4DocumentId || undefined,
        w4IdahoDocumentId: w4IdahoDocumentId || undefined,
        sudoCompanyId: sudoCompany.id,
        weeklyZoomLink: weeklyZoomLink || undefined
      })
      .then(res => {
        setAdminSettings(res);
        Toast.show('Miscellaneous Settings were updated.');
      })
      .catch(err => Toast.show(err.message));
  };

  const syncFeenicsUsers = () => {
    const areYouSure = window.confirm(
      'Are you sure you want to sync all Feenics users.'
    );
    if (!areYouSure) return;

    http()
      .put('/users/assign-feenics')
      .then(res => {
        alert(
          `Feenics users synced. The following emails were not found in steps.\n${JSON.stringify(
            res
          )}`
        );
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5" gutterBottom>
        Miscellaneous Settings
      </Typography>

      <Divider />
      <Typography
        style={{ padding: 10, fontWeight: 400 }}
        component="h1"
        variant="h6"
        gutterBottom
      >
        Templates
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            label="W-4 Template ID"
            fullWidth
            value={w4DocumentId}
            onChange={e => setW4DocumentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            label="W-4 Idaho Template ID"
            fullWidth
            value={w4IdahoDocumentId}
            onChange={e => setW4IdahoDocumentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            label="I-9 Template ID"
            fullWidth
            value={i9DocumentId}
            onChange={e => setI9DocumentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchDropdown
            fullWidth
            margin="normal"
            label="Sudo Company"
            itemToString={item => item.name}
            items={companies}
            onChange={company => setSudoCompany(company)}
            value={sudoCompany}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            label="Weekly Zoom Link"
            fullWidth
            value={weeklyZoomLink}
            onChange={e => setWeeklyZoomLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button onClick={save} variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>

      <div style={{ padding: 10 }} />
      <Divider />
      <Areas />

      <div style={{ padding: 10 }} />
      <Divider />
      <PoShipToOptions />
      <Divider />
      <Typography
        style={{ padding: 10, fontWeight: 400 }}
        component="h1"
        variant="h6"
        gutterBottom
      >
        Sync Feenics Users
      </Typography>
      <Button onClick={syncFeenicsUsers} variant="contained" color="primary">
        Sync
      </Button>
    </React.Fragment>
  );
}

export default connect(state => ({ adminSettings: state.adminSettings }), {
  setAdminSettings
})(MiscTab);
