const phone = number => {
  let [base, ext] = String(number)
    .split(',')
    .map(s => s.replace(/\D/g, ''));
  const lineNumber = base.slice(-4);
  const prefix = base.slice(-7, -4);
  const areaCode = base.slice(-10, -7);
  const countryCode = base.slice(0, -10);

  base = [countryCode, areaCode, prefix, lineNumber].filter(el => el).join('.');
  return ext ? `${base}, ${ext}` : base;
};

export default phone;
