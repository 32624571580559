import React from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  Divider,
  DialogActions
} from 'helpers/themeSafeMui.helper';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import AnnouncementDisplay from '../components/AnnouncementDisplay.component';

export default class ViewAnnouncementsModal extends React.Component {
  initialState = {
    title: '',
    announcement: ''
  };

  state = this.initialState;

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  save = e => {
    e.preventDefault();

    http()
      .post('/announcements', {
        announcement: this.state.announcement,
        title: this.state.title
      })
      .then(res => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <Dialog
        maxWidth="md"
        scroll="body"
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: () => this.setState(this.initialState) }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Announcements</div>
            <Button onClick={this.props.onClose} color="default">
              Close
            </Button>
          </div>
        </DialogTitle>
        <Divider />

        {this.props.announcements.map(announcement => {
          return (
            <AnnouncementDisplay
              key={announcement.id}
              announcement={announcement}
              refresh={this.props.refresh}
            />
          );
        })}

        <DialogActions />
      </Dialog>
    );
  }
}
