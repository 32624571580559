import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@material-ui/core';
import { twoAfterDec, trim0s } from 'helpers/floatStringFormatter.helper';
import { ConfirmDelete, CustomInput, VirtualizedSearch } from 'components';
import { useEffect, useState } from 'react';

export default function ProjectMaterialModal({
  title,
  materials,
  open,
  onClose,
  onSubmit,
  onDelete,
  loading,
  projectMaterials,
  editingMaterial,
  setEditingMaterial,
  creatingMaterial,
  setCreatingMaterial
}) {
  const [divEl, setDivEl] = useState(null);

  const [stateMaterial, setStateMaterial] = useState({});

  useEffect(() => {
    if (editingMaterial || creatingMaterial) {
      setStateMaterial(
        editingMaterial
          ? { ...scrubMaterial(editingMaterial), id: editingMaterial.id }
          : { ...scrubMaterial(creatingMaterial), id: null }
      );
    } else if (open) {
      setStateMaterial({});
    }
  }, [editingMaterial, creatingMaterial, open]);

  const findInProjectMaterials = materialId =>
    projectMaterials.find(other => other.materialId === materialId) || null;

  const selectAutofillMaterial = material => {
    const materialId = material.fromInput
      ? stateMaterial.materialId || null
      : material.id;

    const found = findInProjectMaterials(materialId);
    const foundId = (found && found.id) || null;
    const stateMaterialId = stateMaterial && stateMaterial.id;

    function formatMaterial(old) {
      const val = (key, def) =>
        material.fromInput
          ? material[key] || old[key] || def
          : material[key] || def;

      const format = (key, formatFn) => {
        const pulled = val(key, 0);
        if (material.fromInput) return pulled;
        return formatFn(pulled);
      };

      return {
        id: foundId,
        materialId,
        quantity: val('quantity', 0),
        manufacturer: val('manufacturer', ''),
        model: val('model', ''),
        partNumber: val('partNumber', ''),
        description: val('description', ''),
        unitCost: format('unitCost', twoAfterDec),
        unitLabor: format('unitLabor', trim0s),
        specSheet: val('specSheet', ''),
        OM: val('OM', '')
      };
    }

    if (foundId !== stateMaterialId) {
      if (found) {
        setEditingMaterial(found);
        setCreatingMaterial(null);
      } else {
        setEditingMaterial(null);
        setCreatingMaterial(material);
      }
    } else {
      setStateMaterial(old => formatMaterial(old));
    }
  };

  const customField = (label, key, type = 'number', props) => {
    const defaultValue = type === 'number' ? 0 : '';
    const value = stateMaterial[key] ? stateMaterial[key] : defaultValue;
    return (
      <CustomInput
        label={label}
        type={type}
        value={value}
        onChange={text => setStateMaterial({ ...stateMaterial, [key]: text })}
        {...props}
      />
    );
  };

  const searchField = (label, key, allItems = materials) => {
    return (
      <VirtualizedSearch
        TextFieldProps={{ style: { marginBottom: 10 }, disabled: loading }}
        label={label}
        allItems={allItems}
        getItemText={item => (item ? item[key] : '')}
        onChange={selectAutofillMaterial}
        makeItemFromInput={text => ({
          ...stateMaterial,
          fromInput: true,
          [key]: text
        })}
        showInputAsOption
        autoselectTop
        boundariesElement={divEl}
        inputValue={stateMaterial[key] || ''}
        setInputValue={text =>
          setStateMaterial({ ...stateMaterial, [key]: text })
        }
      />
    );
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <div ref={setDivEl}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {searchField('Part Number', 'partNumber')}
          {customField('Model', 'model', 'text')}
          {customField('Manufacturer', 'manufacturer', 'text')}
          {customField('Description', 'description', 'text', {
            multiline: true
          })}
          {customField('Quantity', 'quantity')}
          {customField('Unit Cost', 'unitCost')}
          {customField('Unit Labor', 'unitLabor')}
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          {!creatingMaterial && (
            <div style={{ marginRight: 6 }}>
              <ConfirmDelete
                onConfirm={onDelete}
                iconButtonProps={{ size: 'small' }}
              />
            </div>
          )}
          <Button onClick={onClose} variant="contained">
            close
          </Button>
          <Button onClick={() => setStateMaterial({})} variant="contained">
            clear
          </Button>
          <Button
            onClick={() => onSubmit(stateMaterial)}
            disabled={loading}
            variant="contained"
            color="primary"
          >
            save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

const scrubMaterial = material => ({
  materialId: material.materialId || material.id || '',
  description: material.description || '',
  quantity: material.quantity || '',
  manufacturer: material.manufacturer || '',
  model: material.model || '',
  partNumber: material.partNumber || '',
  unitCost: material.unitCost || '',
  unitLabor: material.unitLabor || '',
  specSheet: material.specSheet || '',
  OM: material.OM || ''
});
