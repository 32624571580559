import React from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  IconButton,
  Button,
  InputAdornment
} from 'helpers/themeSafeMui.helper';
import { DropZone, EncryptedValue, EncryptedFile } from 'components';
import { useColors } from 'helpers/theme.helper';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import toCurrency from 'helpers/currency.helper';

const DIRECT_DEPOSIT_AGREEMENT = `This authorizes System Tech Inc (the "Company") to send
credit entries (and appropriate debit and adjustment entries),
electronically or by any other commercially accepted method to my
(our) account(s) indicated above and to other accounts I (we) identify
in the future (the "Accountant"). This authorizes the
financial institution holding the Account to post all such entries. I
agree that the ACH transactions authorized herein shall comply with
all applicable U.S. Law. This authorization will be in effect until
the Company receives a written termination notice from myself and has
a reasonable opportunity to act on it.`;

const BankAccount = props => {
  const { account, onChange } = props;
  const colors = useColors();

  const handleChange = e => {
    onChange({ ...account, [e.target.name]: e.target.value });
  };

  const containerStyle = {
    border: `1px solid ${colors.grey}`,
    borderRadius: 8,
    marginTop: 15,
    marginBottom: 15,
    position: 'relative'
  };

  if (!account.id.includes('new_')) {
    return (
      <Grid container spacing={3} style={containerStyle}>
        <IconButton
          onClick={() =>
            window.confirm(
              'Are you sure you want to delete this bank account?'
            ) && props.removeAccount(account.id)
          }
          style={{ position: 'absolute', top: 2, right: 2 }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <Grid item xs={12}>
          <Typography>
            Account Type: <strong>{account.type.toUpperCase()}</strong>
          </Typography>
          <Typography>
            Routing Number:{' '}
            <strong>
              <EncryptedValue value={account.routingNumber} />
            </strong>
          </Typography>
          <Typography>
            Account Number:{' '}
            <strong>
              <EncryptedValue value={account.accountNumber} />
            </strong>
          </Typography>
          <Typography>
            Amount of Paycheck:{' '}
            <strong>
              {account.allocationType === 'percent'
                ? account.paycheckAllocation + '%'
                : toCurrency(account.paycheckAllocation)}
            </strong>
          </Typography>
          <Typography>
            Void Check File:{' '}
            <strong>
              <EncryptedFile file={account.voidCheckFile} />
            </strong>
          </Typography>
          <Typography
            variant="caption"
            style={{
              background: '#f2f2f2',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: 3,
              marginTop: 8,
              display: 'block'
            }}
          >
            {DIRECT_DEPOSIT_AGREEMENT}
          </Typography>
          <Typography align="right" variant="caption">
            Acknowledged:{' '}
            {moment(account.directDepositAcknowledged).format('LLL')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <form autoComplete="off" onSubmit={e => props.agreeAndSave(e, account)}>
      <Grid container spacing={3} style={containerStyle}>
        <Grid item xs={6}>
          <DropZone
            isUploaded={account.file}
            onReset={() => onChange({ ...account, file: null })}
            onRead={files => onChange({ ...account, file: files[0] })}
            title="Void Check"
            validExtensions={['jpeg', 'jpg', 'png', 'pdf']}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl required fullWidth margin="dense">
            <InputLabel>Account Type</InputLabel>
            <Select
              value={account.type}
              onChange={handleChange}
              name="type"
              required
            >
              <MenuItem value="checking">Checking</MenuItem>
              <MenuItem value="savings">Savings</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="routingNumber"
            label="Routing Number"
            required
            fullWidth
            margin="dense"
            value={account.routingNumber}
            onChange={handleChange}
          />
          <TextField
            name="accountNumber"
            label="Account Number"
            required
            fullWidth
            margin="dense"
            value={account.accountNumber}
            onChange={handleChange}
          />
          <TextField
            name="accountNumberConfirmed"
            label="Confirm Account Number"
            error={account.accountNumberConfirmed !== account.accountNumber}
            required
            fullWidth
            margin="dense"
            value={account.accountNumberConfirmed}
            onChange={handleChange}
          />

          <FormControl required fullWidth margin="dense">
            <InputLabel>Paycheck Allocation</InputLabel>
            <Select
              value={account.allocationType}
              onChange={handleChange}
              name="allocationType"
              required
            >
              <MenuItem value="percent">
                Deposit a PERCENTAGE of my paycheck into this account.
              </MenuItem>
              <MenuItem value="cents">
                Deposit a FIXED AMOUNT of my paycheck into this account.
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            name="paycheckAllocation"
            type="number"
            inputProps={{ step: '0.01' }}
            label="Amount of Paycheck"
            required
            fullWidth
            margin="dense"
            InputProps={{
              startAdornment:
                account.allocationType === 'cents' ? (
                  <InputAdornment position="start">$</InputAdornment>
                ) : null,
              endAdornment:
                account.allocationType === 'percent' ? (
                  <InputAdornment position="end">%</InputAdornment>
                ) : null
            }}
            value={account.paycheckAllocation}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="caption"
            style={{
              display: 'block',
              background: '#f2f2f2',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
          >
            {DIRECT_DEPOSIT_AGREEMENT}
          </Typography>

          <Button
            type="submit"
            color="secondary"
            variant="outlined"
            size="small"
            style={{ float: 'right' }}
          >
            Agree and Save
          </Button>
          {props.idx !== 0 && (
            <Button
              onClick={() => props.removeAccount(account.id)}
              color="inherit"
              variant="outlined"
              size="small"
              style={{ float: 'right', color: colors.error, marginRight: 10 }}
            >
              Remove Account
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default BankAccount;
