import React, { useContext } from 'react';
import {
  Paper,
  Typography,
  Divider,
  List,
  ListItem
} from 'helpers/themeSafeMui.helper';
import { PersonDisplay, NoItemsResult } from 'components';
import { withRouter } from 'react-router-dom';
import { CompanyPeopleContext } from '../context/Company.context';
import MoreIcon from '@material-ui/icons/MoreVert';

function PersonListItem(props) {
  const { person, handleClick } = props;
  const phoneNumber = getPersonPhoneNumber();
  const email =
    (person.emails && person.emails[0] && person.emails[0].email) || '';

  function getPersonPhoneNumber() {
    const base =
      person.phoneNumbers && person.phoneNumbers[0]
        ? person.phoneNumbers[0].phoneNumber
        : '';
    if (!base) return '';
    const ext = person.phoneNumbers[0].ext || null;
    return `${base}, ${ext}`;
  }

  let jobTitle = person.jobTitle;
  if (person.inactive)
    jobTitle = (
      <Typography color="error">
        {jobTitle ? `${jobTitle} (Inactive)` : '(Inactive)'}
      </Typography>
    );

  return (
    <React.Fragment key={person.id}>
      <ListItem style={{ cursor: 'pointer' }} onClick={handleClick}>
        <PersonDisplay
          name={person.name}
          type={jobTitle}
          phoneNumber={phoneNumber}
          email={email}
          Icon={<MoreIcon color="action" style={{ cursor: 'pointer' }} />}
        />
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

function CompanyPeople(props) {
  const { companyPeople } = useContext(CompanyPeopleContext);

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6">People</Typography>
      <Divider />

      <List style={{ padding: 0 }}>
        {companyPeople.length > 0 ? (
          companyPeople.map(person => {
            return (
              <PersonListItem
                key={person.id}
                person={person}
                handleClick={() => props.history.push(`/people/${person.id}`)}
              />
            );
          })
        ) : (
          <NoItemsResult type="people" />
        )}
      </List>
    </Paper>
  );
}

export default withRouter(CompanyPeople);
