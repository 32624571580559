import { PDFFont } from 'pdf-lib';

export interface PdfLibTableCell {
  x: number;
  y: number;
  width: number;
  height: number;
  textWidth: number;
  textHeight: number;
  padX: number;
  padY: number;
  bg: string;
  text: string;
  fontSize: number;
  align: 'l' | 'r' | 'c';
  font: PDFFont;
}

interface CreateTableDataProps {
  font: PDFFont;
  width: number;
  maxHeight?: number;
  headerFont?: PDFFont;
  headerFontSize?: number;
  rows: string[][];
  rowsFontSize: number;
  noTruncColIdxs: number[];
  padX: number;
  padY: number;
  x: number;
  y: number;
}

export function createTableData({
  font,
  width,
  maxHeight = -1,
  rows,
  rowsFontSize,
  headerFont = font,
  headerFontSize = rowsFontSize,
  noTruncColIdxs,
  padX,
  padY,
  x,
  y
}: CreateTableDataProps) {
  const colWidths = [] as number[];
  const longestRowLength = rows.reduce(
    (longest, row) => (row.length > longest ? row.length : longest),
    0
  );

  for (let colIdx = 0; colIdx < longestRowLength; colIdx++) {
    let widestInColWidth = 0;
    for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
      const row = rows[rowIdx];
      const cellFont = rowIdx === 0 ? headerFont : font;
      const cellFontSize = rowIdx === 0 ? headerFontSize : rowsFontSize;
      const colText = row[colIdx];
      if (colText) {
        const measuredWidth =
          cellFont.widthOfTextAtSize(colText, cellFontSize) + padX * 2 + 1;
        if (measuredWidth > widestInColWidth) widestInColWidth = measuredWidth;
      }
    }
    colWidths.push(widestInColWidth);
  }

  const truncdColWidths = [] as number[];
  const sum = colWidths.reduce((sum, num) => sum + num, 0);
  // (extraSpace < 0) = sum too big
  const extraSpace = width - sum;

  const variableSum = colWidths
    .filter((w, idx) => !noTruncColIdxs.includes(idx))
    .reduce((sum, num) => sum + num, 0);

  colWidths.forEach((thisWidth, idx) => {
    if (noTruncColIdxs.includes(idx)) {
      truncdColWidths.push(thisWidth);
      return;
    }
    const percentOfVariable = thisWidth / variableSum;
    const alterAmount = percentOfVariable * extraSpace;
    const newWidth = thisWidth + alterAmount;
    truncdColWidths.push(newWidth < 0 ? 0 : newWidth);
  });

  const tableDataPages: PdfLibTableCell[][][] = [];
  const pages = getPages({
    allRows: rows,
    font,
    maxHeight,
    headerFont,
    rowsFontSize,
    headerFontSize,
    padY
  });

  for (let pageIdx = 0; pageIdx < pages.length; pageIdx++) {
    const rows = pages[pageIdx];

    const tableDataRows: PdfLibTableCell[][] = [];
    let yOffset = y;
    let xOffset = x;
    let rowHeight = 0;

    for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
      // FOR EACH ROW
      tableDataRows.push([]);
      const row = tableDataRows[rowIdx];
      const cellFont = rowIdx === 0 ? headerFont : font;
      const fontSize = rowIdx === 0 ? headerFontSize : rowsFontSize;
      const textHeight = cellFont.heightAtSize(fontSize, { descender: false });
      rowHeight = textHeight + padY * 2;
      xOffset = x;

      for (let colIdx = 0; colIdx < truncdColWidths.length; colIdx++) {
        // FOR EACH COL
        const colWidth = truncdColWidths[colIdx];
        const maxTextWidth = colWidth - padX * 2;
        const fullText = rows[rowIdx][colIdx];
        if (typeof fullText !== 'string') continue;

        const { text, width: textWidth } = getTruncdText(
          fullText,
          cellFont,
          fontSize,
          maxTextWidth
        );
        row.push({
          y: yOffset,
          x: xOffset,
          width: colWidth,
          height: rowHeight,
          textWidth,
          textHeight,
          padX,
          padY,
          bg: '#ffffff',
          text,
          fontSize,
          align: 'c',
          font: cellFont
        });
        xOffset += colWidth;
      }

      yOffset += rowHeight;
    }

    tableDataPages.push(tableDataRows);
  }

  return tableDataPages;
}

function getTruncdText(
  text: string,
  font: PDFFont,
  fontSize: number,
  maxWidth: number
) {
  let truncd = text;
  let width = font.widthOfTextAtSize(truncd, fontSize);
  if (width < maxWidth) return { text: truncd, width };
  for (let truncAmount = 1; truncAmount <= text.length; truncAmount++) {
    truncd = text.slice(0, -truncAmount);
    width = font.widthOfTextAtSize(truncd.trim() + '...', fontSize);
    if (width < maxWidth) break;
  }
  return { text: truncd.trim() + '...', width };
}

interface GetPagesProps {
  allRows: string[][];
  font: PDFFont;
  maxHeight?: number;
  headerFont?: PDFFont;
  rowsFontSize: number;
  headerFontSize?: number;
  padY: number;
}

function getPages({
  allRows,
  font,
  maxHeight = -1,
  headerFont = font,
  rowsFontSize,
  headerFontSize = rowsFontSize,
  padY
}: GetPagesProps) {
  const pages: string[][][] = [];
  let page: string[][] = [];
  let pageHeight = 0;
  const getHeight = (header = false) => {
    const cellFont = header ? headerFont : font;
    const fontSize = header ? headerFontSize : rowsFontSize;
    const textHeight = cellFont.heightAtSize(fontSize, { descender: false });
    return textHeight + padY * 2;
  };
  const headerHeight = getHeight(true);
  const rowHeight = getHeight(false);
  const addPage = () => {
    pages.push([]);
    const newPage = pages[pages.length - 1];
    newPage.push([...allRows[0]]);
    page = newPage;
    pageHeight = headerHeight;
  };
  addPage();
  for (let allRowsIdx = 1; allRowsIdx < allRows.length; allRowsIdx++) {
    if (
      maxHeight !== -1 &&
      pageHeight + rowHeight > maxHeight &&
      page.length > 1
    ) {
      addPage();
      allRowsIdx--;
      continue;
    }
    const row = [...allRows[allRowsIdx]];
    pageHeight += rowHeight;
    page.push(row);
  }

  return pages;
}
