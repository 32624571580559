import React, { useState } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import { useColors } from 'helpers/theme.helper';
import RefreshIcon from '@material-ui/icons/Autorenew';
import moment from 'moment';
import styled, { keyframes, css } from 'styled-components';
import { ToolTip } from 'components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${props =>
    props.isLoading &&
    css`
      ${rotate} 2s linear infinite
    `};
  cursor: pointer;
  font-size: 18px;
  margin-right: 2px;
`;

const LastUpdated = props => {
  const { onRefresh, lastUpdated, toolTipValue } = props;
  const [loading, setLoading] = useState(false);
  const colors = useColors();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 4,
        cursor: 'default'
      }}
    >
      {onRefresh && (
        <Spinner
          onClick={() => !loading && onRefresh(setLoading)}
          isLoading={loading}
        >
          <RefreshIcon fontSize="inherit" style={{ display: 'block' }} />
        </Spinner>
      )}
      <ToolTip value={toolTipValue}>
        <Typography variant="caption">
          Last updated:{' '}
          <span
            style={{
              color:
                !lastUpdated ||
                moment()
                  .subtract(1, 'week')
                  .isAfter(lastUpdated)
                  ? colors.error
                  : 'inherit'
            }}
          >
            {lastUpdated ? moment(lastUpdated).format('L') : 'N/A'}
          </span>
        </Typography>
      </ToolTip>
    </div>
  );
};

export default LastUpdated;
