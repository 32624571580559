import React from 'react';
import { Grid, Typography, Link } from 'helpers/themeSafeMui.helper';
import { CustomInput, ExternalLink } from 'components';
import formatValue from 'helpers/formatValue.helper';

function EditableRow(props) {
  const { label, editing, hidden, onLinkClick, error = false, ...rest } = props;

  if (hidden) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <Typography
          color={error && editing ? 'error' : 'inherit'}
          style={{ marginTop: 16, textAlign: 'right' }}
        >
          {props.label}:
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {editing ? (
          <div style={{ marginTop: 11 }}>
            <CustomInput margin="none" error={error} {...rest} />
          </div>
        ) : (
          <Typography color="textSecondary" style={{ marginTop: 16 }}>
            {(() => {
              if (onLinkClick)
                return (
                  <Link style={{ cursor: 'pointer' }} onClick={onLinkClick}>
                    open
                  </Link>
                );
              if (props.type === 'link' && props.value)
                return <ExternalLink href={props.value}>open</ExternalLink>;
              return formatValue(props);
            })()}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default EditableRow;
