import React, { useRef } from 'react';
import {
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  Typography,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import PrintViewCanvas from '../canvas';
import DownloadIcon from '@material-ui/icons/GetApp';
import jsPDF from 'jspdf';

const DownloadPreview = ({ open, onClose, project, tasks, labels }) => {
  const tableRef = useRef(null);
  const taskLabels = tasks.reduce(
    (acc, task) => Object.assign(acc, { [task.id]: task.labelId }),
    {}
  );

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'px',
      format: 'a4'
    });

    const leftJustifyX = 43.58818;
    const scaleDown = 0.583;
    const pdfResolution = { x: 631, y: 446 };
    const { urls, slices } = tableRef.current.toPdfImageUrls({
      maxWidth: pdfResolution.x * 0.8 * (1 / scaleDown),
      canvasDataURLArgs: ['image/png', 1.0]
    });
    slices.forEach((sliceArgs, idx) => {
      if (idx > 0) {
        doc.addPage();
        doc.setPage(idx + 1);
      }
      const totalSize = tableRef.current.getSize(...sliceArgs);
      totalSize.x *= scaleDown;
      totalSize.y *= scaleDown;
      let [x, y] = [
        pdfResolution.x / 2 - totalSize.x / 2,
        pdfResolution.y / 2 - totalSize.y / 2
      ];
      if (sliceArgs[1] - sliceArgs[0] < 4) x = leftJustifyX;
      doc.addImage(urls[idx], 'PNG', x, y, totalSize.x, totalSize.y);
      doc.setFontSize(20);
      doc.text(`#${project.projectNumber} - ${project.name}`, 20, 20, {
        baseline: 'middle'
      });
      doc.setFontSize(10);
      doc.text(`${idx + 1}/${slices.length}`, 20, pdfResolution.y - 20, {
        baseline: 'middle'
      });
    });
    doc.save('schedule_px.pdf');
  };

  if (!tasks || !tasks.length)
    return (
      <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
        <DialogTitle>No tasks for selected project</DialogTitle>
        <DialogActions>
          <Button variant="contained" color="default" onClick={onClose}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    );

  const primaryButtonProps = {
    variant: 'contained',
    color: 'primary'
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          * Tasks without labels will not appear in download *
        </Typography>
      </DialogContent>
      <PrintViewCanvas
        printView={{
          labels,
          taskLabels,
          taskIds: tasks
            .filter(task => taskLabels[task.id])
            .map(task => task.id)
        }}
        tasks={tasks}
        tableRef={tableRef}
      />
      <DialogActions>
        <Button {...primaryButtonProps} onClick={downloadPDF}>
          <DownloadIcon />
        </Button>
        <Button variant="contained" color="default" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadPreview;
