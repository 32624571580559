import { Popover } from '@material-ui/core';
import { Button } from 'helpers/themeSafeMui.helper';
import React, { useImperativeHandle, useState } from 'react';
import MultiSelectContents from './components/MultiSelectContents.component';

function DropdownButtonUF(
  { buttonText = 'Select', children, PopoverProps = {}, ButtonProps = {} },
  ref
) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => ({
    close: handleClose
  }));

  return (
    <>
      <Button variant="contained" {...ButtonProps} onClick={handleClick}>
        {buttonText}
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        {...PopoverProps}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {children}
      </Popover>
    </>
  );
}

const DropdownButton = React.forwardRef(DropdownButtonUF);

DropdownButton.MultiSelect = ({
  allItems,
  selectedItems,
  setSelectedItems,
  name = 'Items',
  localStorageKey = null,
  checkboxPropsMap = {},
  DropdownButtonProps = {}
}) => {
  return (
    <DropdownButton
      PopoverProps={{ keepMounted: true }}
      buttonText={`Select ${name}`}
      {...DropdownButtonProps}
    >
      <MultiSelectContents
        allItems={allItems}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        name={name}
        localStorageKey={localStorageKey}
        checkboxPropsMap={checkboxPropsMap}
      />
    </DropdownButton>
  );
};

export default DropdownButton;
