import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Dialog } from 'helpers/themeSafeMui.helper';
import WorkOrderContainerComponent from 'routes/Projects/tabs/WorkOrders/components/WorkOrderContainer.component';
import { UsersContext } from '../context/Users.context';
import http from 'helpers/http.helper';
import { Toast } from 'components';

export default function ViewWorkOrderModal({
  workOrder: workOrderProp,
  projectId = null,
  open,
  onClose,
  getWorkOrders = () => {}
}) {
  const { users } = useContext(UsersContext);
  const [workOrder, setWorkOrder] = useState(null);

  useEffect(() => {
    if (!open) return;
    if (typeof workOrderProp === 'string') {
      http()
        .get(`/projects/${projectId}/work-orders/${workOrderProp}`)
        .then(setWorkOrder)
        .catch(Toast.showErr);
    } else if (workOrderProp !== null && typeof workOrderProp === 'object') {
      setWorkOrder(workOrderProp);
    }
  }, [open, workOrderProp, projectId]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      {workOrder ? (
        <WorkOrderContainerComponent
          item={workOrder}
          users={users}
          getWorkOrders={getWorkOrders}
          defaultOpen
        />
      ) : (
        <div
          style={{
            height: '30vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}
