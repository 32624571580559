import isUsingOneDrive from './isUsingOneDrive.helper';

interface UserAssociation {
  baseKey: string;
  name: string;
  pluralName: string;
  editPermission: string | null;
}

export const accountManager: UserAssociation = Object.freeze({
  baseKey: 'accountManager',
  name: 'Account Manager',
  pluralName: 'Account Managers',
  editPermission: null
});

export const seniorProjectManager: UserAssociation = Object.freeze({
  baseKey: 'seniorProjectManager',
  name: 'Senior Project Manager',
  pluralName: 'Senior Project Managers',
  editPermission: 'projects_tier_2'
});

export const projectManager: UserAssociation = Object.freeze({
  baseKey: 'projectManager',
  name: 'Project Manager',
  pluralName: 'Project Managers',
  editPermission: 'projects_tier_2'
});

export const leadTechnician: UserAssociation = Object.freeze({
  baseKey: 'leadTechnician',
  name: 'Lead Technician',
  pluralName: 'Lead Technicians',
  editPermission: 'projects_tier_1.5'
});

export const projectEstimator: UserAssociation = Object.freeze({
  baseKey: 'projectEstimator',
  name: 'Project Estimator',
  pluralName: 'Project Estimators',
  editPermission: 'projects_tier_2'
});

export const projectForeman: UserAssociation = Object.freeze({
  baseKey: 'projectForeman',
  name: 'Project Foreman',
  pluralName: 'Project Foremen',
  editPermission: 'projects_tier_2'
});

const TOLA_ASSOCIATIONS: Readonly<UserAssociation[]> = Object.freeze([
  accountManager,
  projectEstimator,
  projectManager,
  projectForeman,
  leadTechnician
]);

const STI_ASSOCIATIONS: Readonly<UserAssociation[]> = Object.freeze([
  accountManager,
  seniorProjectManager,
  projectManager,
  leadTechnician
]);

export default function userAssociations() {
  return isUsingOneDrive() ? TOLA_ASSOCIATIONS : STI_ASSOCIATIONS;
}
