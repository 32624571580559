import React, { useState, useEffect, useCallback } from 'react';
import { Toast, NoItemsResult, ActionItem } from 'components';
import { Typography, Button, List } from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { showModal } from 'ducks/modal.duck';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

const ActionItems = props => {
  const { userId } = props;
  const [actionItems, setActionItems] = useState([]);
  const dispatch = useDispatch();

  const fetchActionItems = useCallback(() => {
    http()
      .get(`/action-items`, {
        params: { assignedFor: userId }
      })
      .then(res => {
        setActionItems(res);
      })
      .catch(err => Toast.show(err.message));
  }, [userId]);

  useEffect(() => {
    fetchActionItems();
  }, [fetchActionItems]);

  const openCreateModal = async () => {
    dispatch(
      showModal({
        type: 'CREATE_ACTION_ITEM',
        props: { assignedFor: userId, refresh: fetchActionItems }
      })
    );
  };

  return (
    <div style={{ padding: 15 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Action Items
        </Typography>
      </div>
      <List>
        {!actionItems.length && <NoItemsResult type={'User ActionItems'} />}
        {actionItems.map(actionItem => (
          <ActionItem key={actionItem.id} id={actionItem.id} />
        ))}
      </List>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={openCreateModal} color="primary" variant="outlined">
          Add Action Item
        </Button>
      </div>
    </div>
  );
};

export default connect(state => ({ user: state.user }), { showModal })(
  ActionItems
);
