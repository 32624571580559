import React, { useState } from 'react';
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle
} from 'helpers/themeSafeMui.helper';
import 'cropperjs/dist/cropper.css';
import { useColors } from 'helpers/theme.helper';

const ImageCropperModal = ({
  title,
  open,
  onClose,
  onCrop,
  CropperProps = {},
  outputOptions = {
    maxWidth: 350,
    maxHeight: 350,
    imageSmoothingQuality: 'high'
  }
}) => {
  const [file, setFile] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [over, setOver] = useState(false);
  const colors = useColors();

  const onDrop = files => {
    const reader = new FileReader();
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const crop = () => {
    onCrop(cropper.getCroppedCanvas(outputOptions).toDataURL());
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {file ? (
          <Cropper
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            src={file}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={1}
            {...CropperProps}
            checkOrientation={false}
            onInitialized={instance => {
              setCropper(instance);
            }}
          />
        ) : (
          <Dropzone
            multiple={false}
            disabled={false}
            onDrop={onDrop}
            noKeyboard
            onDragEnter={() => setOver(true)}
            onDragLeave={() => setOver(false)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  style={{
                    width: 300,
                    height: 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: over ? colors.grey : colors.lightGrey
                  }}
                >
                  <div>
                    <Typography
                      variant="h6"
                      style={{ textTransform: 'uppercase' }}
                    >
                      Photo Upload
                    </Typography>
                    <Typography>Choose a photo or drag it here.</Typography>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        {cropper && (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setFile(null);
                setCropper(null);
              }}
            >
              Select New Image
            </Button>
            <Button variant="contained" onClick={() => cropper.reset()}>
              Reset View
            </Button>
            <Button variant="contained" color="primary" onClick={crop}>
              Crop
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropperModal;
