import React, { useRef, useState } from 'react';
import { TableCell, makeStyles } from 'helpers/themeSafeMui.helper';
import { TextOverflowPopover } from 'components';

const useStyles = makeStyles({
  cell: { '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }
});

export default function OverflowCell({
  text,
  cellStyle,
  maxWidth,
  popoverTitle = null
}) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const { cell } = useStyles();

  return (
    <TableCell
      className={isOverflowing ? cell : undefined}
      style={cellStyle}
      onClick={e => {
        if (!isOverflowing) return;
        const { openPopper } = textRef.current || {};
        if (openPopper) {
          e.stopPropagation();
          openPopper();
        }
      }}
    >
      <TextOverflowPopover
        ref={textRef}
        text={text}
        popoverTitle={popoverTitle}
        style={{ fontSize: 14, maxWidth }}
        setIsOverflowing={setIsOverflowing}
      />
    </TableCell>
  );
}
