import React from 'react';
import { Toast } from 'components';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

export default class NewAnnouncement extends React.Component {
  initialState = {
    title: '',
    announcement: ''
  };

  state = this.initialState;

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  save = e => {
    e.preventDefault();

    http()
      .post('/announcements', {
        announcement: this.state.announcement,
        title: this.state.title
      })
      .then(res => {
        this.props.refresh();
        this.props.onClose();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionProps={{ onEnter: () => this.setState(this.initialState) }}
      >
        <form autoComplete="off" onSubmit={this.save}>
          <DialogTitle>New Announcement</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              label="Title"
              fullWidth
              value={this.state.title}
              name="title"
              margin="normal"
              onChange={e => this.handleChange(e)}
              required
            />

            <div style={{ marginBottom: 10 }}>
              <TextField
                name="announcement"
                label="Announcement"
                multiline
                rows={4}
                value={this.state.announcement}
                onChange={this.handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="default">
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Post
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
