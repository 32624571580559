import React from 'react';
import { Step, HorizontalScroll } from 'components';
import { useColors } from 'helpers/theme.helper';
import { connect } from 'react-redux';
import ProbabilitySlider from './ProbabilitySlider.component';

const ProjectStatusBar = props => {
  const { activeIndex, onClick, projectStatuses } = props;
  const colors = useColors();
  const width = 275;
  const showProbability = status =>
    status.status === 'Potential POs' && activeIndex === status.order;

  const getColors = status => {
    if (activeIndex < status.order)
      return {
        color: colors.darkGrey,
        textColor: '#ffffff'
      };
    if (activeIndex > status.order)
      return {
        color: colors.primary,
        textColor: colors.primaryContrastText
      };
    if (status.status.match(/\bssa\b/i))
      return {
        color: colors.success,
        textColor: '#ffffff'
      };
    return {
      color: colors.secondary,
      textColor: colors.secondaryContrastText
    };
  };

  return (
    <HorizontalScroll
      initialPosition={width * (activeIndex - 1)}
      wrapperStyle={{ display: 'flex' }}
    >
      {projectStatuses.map((status, idx) => (
        <div key={status.id}>
          <Step
            onClick={() => onClick(status.id)}
            height={40}
            margin={4}
            first={idx === 0}
            last={idx === projectStatuses.length - 1}
            style={{ width: width, cursor: 'pointer', flexShrink: 0 }}
            {...getColors(status)}
          >
            {status.status}
          </Step>
          {showProbability(status) && <ProbabilitySlider />}
        </div>
      ))}
    </HorizontalScroll>
  );
};

export default connect(state => ({ projectStatuses: state.projectStatuses }))(
  ProjectStatusBar
);
