import React, { useState } from 'react';
import { Toast, DropZone, Esign } from 'components';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';

const GovernmentDocument = props => {
  const { type, onUpdate, value, loading, noEsign = false } = props;
  const [signerToken, setSignerToken] = useState('');

  const handleRead = files => {
    const file = files[0];
    onUpdate({
      base64: file.base64,
      mimeType: file.mimeType
    });
  };

  const onSigned = ({ token, fields, setLoading }) => {
    setLoading(true);
    http()
      .post(`/users/me/onboarding-esign`, { token, fields })
      .then(res => {
        setSignerToken('');
        onUpdate({
          base64: res.base64,
          mimeType: res.mimeType
        });
      })
      .catch(err => {
        Toast.show(err.message);
        setLoading(false);
      });
  };

  const getEsignToken = () => {
    http()
      .get(`/users/me/onboarding-esign`, { params: { type } })
      .then(res => setSignerToken(res.signerToken))
      .catch(err => Toast.show(err.message));
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={Boolean(signerToken)}
        disableBackdropClick
        disableEscapeKeyDown
        scroll="body"
      >
        <DialogTitle>{type}</DialogTitle>
        <Divider />
        <DialogContent style={{ marginTop: 25 }}>
          {signerToken && <Esign token={signerToken} onComplete={onSigned} />}
        </DialogContent>
        <DialogActions />
      </Dialog>

      {!noEsign && (
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={getEsignToken}
          style={{ marginBottom: 16 }}
        >
          Complete {type} Electronically
        </Button>
      )}

      <DropZone
        style={{ height: 200 }}
        isUploaded={value}
        onReset={() => onUpdate(null)}
        onRead={handleRead}
        loading={loading}
        title={`Upload ${type} File`}
        validExtensions={['pdf', 'jpg', 'png']}
      />
    </div>
  );
};

export default GovernmentDocument;
