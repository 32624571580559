import React, { useState } from 'react';
import {
  Divider,
  Typography,
  Chip,
  Button,
  DialogTitle,
  DialogContent,
  IconButton,
  Dialog
} from 'helpers/themeSafeMui.helper';
import { connect } from 'react-redux';
import { setUser } from 'ducks/user.duck';
import moment from 'moment';
import http from 'helpers/http.helper';
import { Toast, CheckBox, AuthPopup } from 'components';
import { useColors } from 'helpers/theme.helper';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import {
  useActionItem,
  useDeleteActionItem,
  useSetActionItem
} from 'state/actionItems.state';

function ViewActionItem(props) {
  const { onClose, open, openEditModal, id } = props;
  const [addedToCalendar, setAddedToCalendar] = useState(false);
  const [connectedToCalendar, setConnectedToCalendar] = useState(true);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const colors = useColors();
  const actionItem = useActionItem(id);
  const setActionItem = useSetActionItem();
  const onDeleteActionItem = useDeleteActionItem();

  const assignedToCurrentUser =
    actionItem && actionItem.assignedUserIds.includes(props.currentUser.id);

  const markAsRead = read => () => {
    http()
      .put(`/action-items/view`, { ids: [actionItem.id], seen: read })
      .then(([updated]) => {
        setActionItem(updated);
      })
      .catch(err => Toast.show(err.message));
  };

  const handleEnter = () => {
    if (props.currentUser.isInternal) {
      http()
        .get('/google/calendars/check-connection-status')
        .then(res => {
          if (res) {
            setConnectedToCalendar(true);
          } else {
            setConnectedToCalendar(false);
          }
        })
        .catch(err => Toast.show(err.message));
    } else {
      setConnectedToCalendar(false);
    }

    if (assignedToCurrentUser && !actionItem.seenByUser) markAsRead(true)();

    Promise.all(
      actionItem.assignedUserIds.map(id => http().get(`/users/${id}`))
    )
      .then(users => setAssignedUsers(users))
      .catch(err => Toast.show(err.message));
  };

  const addToCalendar = () => {
    http()
      .post(`/google/calendars`, {
        title: actionItem.title,
        notes: actionItem.notes,
        duration: actionItem.duration || undefined,
        dueDate: actionItem.dueDate,
        actionItemId: actionItem.id
      })
      .then(() => {
        setAddedToCalendar(true);
        Toast.show('Added event to Calendar');
      })
      .catch(err => Toast.show(err.message));
  };

  const connectToCalendar = googleAuthCode => {
    http()
      .post('/users/auth/sign-in', { googleAuthCode })
      .then(user => props.setUser(user))
      .then(() => addToCalendar())
      .catch(err => Toast.show(err.message));
  };

  const toggleCompletion = () => {
    setActionItem({
      ...actionItem,
      dateCompleted: actionItem.dateCompleted ? null : moment()
    });

    http()
      .put(`/action-items/${actionItem.id}`, {
        dateCompleted: actionItem.dateCompleted ? null : moment()
      })
      .then(res => setActionItem(res))
      .catch(err => Toast.show(err.message));
  };

  const deleteActionItem = async () => {
    try {
      await http().delete(`/action-items/${actionItem.id}`);
      onClose();
      onDeleteActionItem(actionItem);
    } catch (err) {
      Toast.show(err.message);
    }
  };

  const createdByCurrentUser =
    props.currentUser && props.currentUser.id === actionItem.createdBy;

  if (!actionItem) return null;

  return (
    <Dialog
      TransitionProps={{ onEnter: handleEnter }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <DialogTitle variant="h5">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <CheckBox
            style={{ marginRight: 8 }}
            checked={actionItem.dateCompleted}
            onClick={toggleCompletion}
          />
          <div style={{ flex: 1, justifySelf: 'start' }}>
            {actionItem.title}
          </div>

          <div>
            {connectedToCalendar && !addedToCalendar && (
              <IconButton onClick={addToCalendar}>
                <CalendarIcon color="action" fontSize="small" />
              </IconButton>
            )}
            {!connectedToCalendar && (
              <AuthPopup
                onCode={connectToCalendar}
                forceConsent
                scopes={['https://www.googleapis.com/auth/calendar']}
              >
                <IconButton>
                  <CalendarIcon color="action" fontSize="small" />
                </IconButton>
              </AuthPopup>
            )}

            {createdByCurrentUser && (
              <IconButton onClick={openEditModal}>
                <EditIcon color="action" fontSize="small" />
              </IconButton>
            )}

            {createdByCurrentUser && (
              <IconButton onClick={deleteActionItem}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Typography style={{ marginBottom: 16, whiteSpace: 'pre-wrap' }}>
          <strong>Details: </strong>
          {actionItem.notes}
        </Typography>

        <Typography style={{ marginBottom: 16 }}>
          <strong>Assigned By:</strong> {actionItem.assignedBy}
        </Typography>

        <div style={{ marginBottom: 16 }}>
          <Typography>
            <strong>Assigned To: </strong>
          </Typography>
          {assignedUsers.map(user => (
            <Chip
              style={{ margin: 3 }}
              size="small"
              key={user.id}
              label={user.name}
            />
          ))}
        </div>

        {actionItem.dateCompleted ? (
          <Typography style={{ marginBottom: 16 }}>
            <strong>Completed: </strong>
            {moment(actionItem.dateCompleted).format('LLL')}
          </Typography>
        ) : (
          <Typography style={{ marginBottom: 16 }}>
            <strong>Due On: </strong>
            <span
              style={{
                color:
                  moment(actionItem.dueDate).isBefore(moment()) && colors.error
              }}
            >
              {moment(actionItem.dueDate).format('LLL')}
            </span>
          </Typography>
        )}

        <Typography style={{ marginBottom: 16 }}>
          <strong>Duration: </strong>
          {actionItem.duration
            ? moment.duration(actionItem.duration, 'minutes').humanize()
            : 'Not Applicable'}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8
          }}
        >
          <div>
            {!actionItem.dateCompleted && (
              <Button
                color="secondary"
                variant="contained"
                onClick={toggleCompletion}
              >
                Mark Complete
              </Button>
            )}
            {assignedToCurrentUser && (
              <Button
                style={{ marginLeft: actionItem.dateCompleted ? null : 8 }}
                color="secondary"
                variant="contained"
                onClick={markAsRead(!actionItem.seenByUser)}
              >
                Mark {actionItem.seenByUser ? 'unread' : 'read'}
              </Button>
            )}
          </div>

          <div>
            {Boolean(actionItem.actionItemLink) && (
              <Button
                color="primary"
                variant="text"
                component={Link}
                onClick={onClose}
                to={actionItem.actionItemLink}
                style={{ marginRight: 8 }}
              >
                Go To Item
              </Button>
            )}

            <Button color="primary" variant="outlined" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default connect(
  state => ({
    currentUser: state.user
  }),
  { setUser }
)(ViewActionItem);
