import React, { useContext, useState } from 'react';
import { showModal } from 'ducks/modal.duck';
import { Typography, Button } from 'helpers/themeSafeMui.helper';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import LocationIcon from '@material-ui/icons/LocationOn';
import { CopyText, HeaderItem } from 'components';
import { connect } from 'react-redux';
import OnboardingHeader from './OnboardingHeader.component';
import phoneHelper from 'helpers/phone.helper';
import { UserContext, EmploymentLogContext } from '../context/User.context';
import SuspendUserModal from '../modals/SuspendUser.modal';

function UserHeader(props) {
  const [open, setOpen] = useState(false);
  const { setEmploymentLogs } = useContext(EmploymentLogContext);
  const { currentUser } = props;
  const { user } = useContext(UserContext);
  const canReactivate =
    user.isEligibleToRehire || currentUser.hasPermission('super_admin');

  return (
    <div>
      <SuspendUserModal open={open} onClose={() => setOpen(false)} />
      <OnboardingHeader user={user} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: 24
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {user.image && (
            <img
              alt="profile"
              src={user.image}
              style={{
                display: 'block',
                marginRight: 12,
                objectFit: 'cover',
                width: 100,
                height: 100
              }}
            />
          )}
          <div>
            <Typography variant="h5">
              {user.firstName} {user.lastName}
            </Typography>
            <div style={{ display: 'flex' }}>
              <HeaderItem Icon={CardTravelIcon} label={user.jobTitle} />
              <HeaderItem Icon={LocationIcon} label={user.area} />
            </div>

            <CopyText text={user.email} type="Email">
              <HeaderItem
                externalUrl={`mailto:${user.email}`}
                Icon={EmailIcon}
                label={user.email}
              />
            </CopyText>
            <CopyText text={user.phoneNumber} type="Phone Number">
              <HeaderItem
                externalUrl={`tel:${user.phoneNumber}`}
                Icon={PhoneIcon}
                label={phoneHelper(user.phoneNumber)}
              />
            </CopyText>
          </div>
        </div>

        <div>
          {user.employmentStatus !== 'Inactive' &&
            currentUser.hasPermission('create_users') && (
              <Button
                variant="outlined"
                color="secondary"
                style={{ marginLeft: 8 }}
                onClick={setOpen.bind(null, true)}
              >
                {user.temporarilySuspended
                  ? 'revoke suspension'
                  : 'suspend user'}
              </Button>
            )}
          {user.employmentStatus !== 'Inactive' ? (
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginLeft: 8 }}
              onClick={() =>
                props.showModal({
                  type: 'USER_TERMINATION',
                  props: {
                    user,
                    setEmploymentLogs
                  }
                })
              }
            >
              deactivate user
            </Button>
          ) : (
            <Button
              disabled={!canReactivate}
              variant="outlined"
              color="secondary"
              style={{ marginLeft: 8 }}
              onClick={() =>
                props.showModal({
                  type: 'USER_REACTIVATION',
                  props: {
                    user,
                    setEmploymentLogs
                  }
                })
              }
            >
              {canReactivate
                ? `reactivate${
                    user.isEligibleToRehire ? '' : ' ineligible'
                  } user`
                : 'User is ineligible to rehire'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(
  state => ({
    currentUser: state.user
  }),
  { showModal }
)(UserHeader);
