import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent
} from 'helpers/themeSafeMui.helper';
import TrainingRecentActionCard from '../components/TrainingRecentActionCard.component';

const TrainingCommunicationsModal = ({ open, onClose, user, items }) => {
  if (!items || !items.length || !user) return null;

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Notes / Communications: {user.name}</DialogTitle>

      <DialogContent>
        {items.map((item, idx) => (
          <React.Fragment key={item.id}>
            {idx === 0 ? null : <div style={{ padding: 5 }} />}
            <TrainingRecentActionCard
              item={item}
              type={item.type || 'note'}
              width={null}
            />
          </React.Fragment>
        ))}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary" autoFocus>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrainingCommunicationsModal;
