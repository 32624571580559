import React from 'react';
import { Typography, Divider } from 'helpers/themeSafeMui.helper';
import Tier1Details from '../components/Tier1Details.component';
import Tier2Details from '../components/Tier2Details.component';
import { connect } from 'react-redux';

const AccountDetails = props => {
  const { userId } = props;

  return (
    <React.Fragment>
      <Typography variant="h6">Tier 1 Account Information</Typography>
      <Divider />
      <Tier1Details />

      {(props.currentUser.hasPermission('users_tier_2') ||
        props.currentUser.id === userId) && (
        <div style={{ marginTop: 32, marginBottom: 32 }}>
          <Typography variant="h6">Tier 2 Account Information</Typography>
          <Divider />
          <Tier2Details userId={userId} />
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(state => ({ currentUser: state.user }))(AccountDetails);
