import React, { useState, useContext } from 'react';
import { Toast } from 'components';
import http from 'helpers/http.helper';
import {
  ProjectContext,
  ProjectTeamContext
} from 'routes/Projects/context/Project.context';
import { useSelector } from 'react-redux';

import CustomerPO from './0-CustomerPO.component';
import ContractValues from './1-ContractValues.component';
import TSheets from './2-TSheets.component';
import TaxExempt from './3-TaxExempt.component';
import { useSetActionItem } from 'state/actionItems.state';

const ProjectWon = ({ completeWorkflow }) => {
  const { project, setProject } = useContext(ProjectContext);
  const { projectTeam } = useContext(ProjectTeamContext);
  const projectStatuses = useSelector(state => state.projectStatuses);
  const setActionItem = useSetActionItem();
  const [step, setStep] = useState(0);
  const [notifyOM, setNotifyOM] = useState(false);

  const moveToOperationsHandoff = async () => {
    try {
      const operationsHandoff = projectStatuses.find(
        ({ status }) => status.toLowerCase() === 'operations hand off'
      );

      await http()
        .put(`/projects/${project.id}`, {
          statusId: operationsHandoff && operationsHandoff.id,
          bidStatus: 'won'
        })
        .then(res => setProject(res));

      if (notifyOM) {
        const operationManagers = await http().get('/operations-managers');

        await http()
          .post('/action-items', {
            projectId: project.id,
            title: `Schedule handoff for ${project.projectNumber} - ${project.name}`,
            dueDate: new Date(),
            duration: 0,
            actionItemLink: `/projects/${project.id}`,
            assignedUserIds: operationManagers
              .map(om => om.userId)
              .concat(projectTeam.accountManagerId)
          })
          .then(actionItem => setActionItem(actionItem));

        await http()
          .post('/action-items', {
            projectId: project.id,
            title: `Complete Pre-Lien Paperwork for ${project.projectNumber} - ${project.name}`,
            dueDate: new Date(),
            duration: 0,
            actionItemLink: `/projects/${project.id}`,
            assignedUserIds: operationManagers
              .map(om => om.userId)
              .concat(projectTeam.accountManagerId)
          })
          .then(actionItem => setActionItem(actionItem));
      }

      Toast.show('Project updated.');
      completeWorkflow();
    } catch (err) {
      Toast.show(err.message);
    }
  };

  return (
    <>
      {step === 0 && <CustomerPO next={() => setStep(1)} />}
      {step === 1 && (
        <ContractValues prev={() => setStep(0)} next={() => setStep(2)} />
      )}
      {step === 2 && (
        <TSheets
          notifyOM={notifyOM}
          setNotifyOM={setNotifyOM}
          prev={() => setStep(1)}
          next={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <TaxExempt prev={() => setStep(2)} next={moveToOperationsHandoff} />
      )}
    </>
  );
};

export default ProjectWon;
