import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useColors } from 'helpers/theme.helper';
import {
  Typography,
  Button,
  InputBase,
  CircularProgress
} from 'helpers/themeSafeMui.helper';
import { IframeModal, Toast } from 'components';
import DriveFolderIcon from 'assets/drive_folder.png';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import { FaSignature } from 'react-icons/fa';
import { connect } from 'react-redux';
import { showModal } from 'ducks/modal.duck';

const File = props => {
  const colors = useColors();
  const {
    file,
    onDelete,
    onChangeFolder,
    folder,
    onRename,
    showModal,
    isTemplate,
    user,
    getSharingLink,
    onSelect = null,
    esignStatus
  } = props;
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [fileName, setFileName] = useState(file.name);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (fileName !== file.name) {
      onRename(fileName, file.id);
    }
  }, [fileName, file, onRename]);

  const copyShareableLink = async () => {
    const link = await getSharingLink();
    const textArea = document.createElement('textArea');
    document.body.append(textArea);
    textArea.value = link;
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    Toast.show('Sharable link copied to clipboard.');
  };

  const getEsignColor = () => {
    switch (esignStatus) {
      case 'NO_ESIGN':
        return colors.action;
      case 'NOT_SENT':
        return colors.lightYellow;
      case 'SENT':
        return colors.error;
      case 'COMPLETED':
        return colors.success;
      case 'ERR':
        return '#ff00ff';
      default:
        return colors.action;
    }
  };

  const canEsign = file.mimeType === 'application/pdf';
  const esignStatusLoading = esignStatus === 'LOADING';

  return (
    <div
      style={{
        height: '100%',
        border: `1px solid ${colors.grey}`,
        borderRadius: 8,
        padding: 16
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 8 }}
      >
        <img
          src={file.iconLink}
          alt="Document Icon"
          style={{ marginRight: 8 }}
        />
        <InputBase
          value={fileName}
          onChange={e => setFileName(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={{
            boxShadow: focused && `0px 0px 2px 0.5px ${colors.primary}`,
            width: '100%'
          }}
          inputProps={{ style: { padding: 0 } }}
        />
        <LinkIcon
          style={{ fontSize: 18, cursor: 'pointer', marginLeft: 8 }}
          titleAccess="Copy shareable link"
          color="action"
          onClick={copyShareableLink}
        />
      </div>

      {!folder && (
        <div
          style={{
            width: '100%',
            height: 200,
            backgroundImage: `url(${file.thumbnailLink})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundColor: colors.grey,
            backgroundPosition: 'center',
            margin: '8px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!file.thumbnailLink && (
            <img src={DriveFolderIcon} height={150} alt="Drive Folder Icon" />
          )}
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Last modified: {moment(file.modifiedTime).fromNow()}
        </Typography>
        <div>
          {canEsign &&
            (esignStatusLoading ? (
              <CircularProgress style={{ marginRight: 8 }} size={18} />
            ) : (
              <FaSignature
                style={{
                  fontSize: 18,
                  cursor: 'pointer',
                  color: getEsignColor(),
                  marginRight: 8
                }}
                title="eSign"
                onClick={() =>
                  showModal({
                    type: 'VIEW_EDIT_ESIGN',
                    size: 'xl',
                    props: {
                      fileId: file.id,
                      isTemplate: isTemplate
                    }
                  })
                }
              />
            ))}
          {user.hasPermission('drive_admin') && (
            <DeleteIcon
              style={{ fontSize: 18, cursor: 'pointer' }}
              titleAccess="Delete"
              color="action"
              onClick={() => onDelete(file.id)}
            />
          )}
        </div>
      </div>

      <Button
        size="small"
        variant="outlined"
        fullWidth
        color="primary"
        onClick={() =>
          folder ? onChangeFolder(file.id) : setViewModalVisible(true)
        }
      >
        Open
      </Button>
      {onSelect && (
        <Button
          style={{ marginTop: 5 }}
          size="small"
          variant="outlined"
          fullWidth
          color="primary"
          onClick={onSelect}
        >
          Select
        </Button>
      )}

      <IframeModal
        open={viewModalVisible}
        onClose={() => setViewModalVisible(false)}
        url={file.webViewLink.replace('view', 'preview')}
      />
    </div>
  );
};

export default connect(state => ({ user: state.user }), { showModal })(File);
