import React, { useContext } from 'react';
import {
  Typography,
  List,
  ListItem,
  Paper,
  Divider
} from 'helpers/themeSafeMui.helper';
import { withRouter } from 'react-router-dom';
import { NoItemsResult, ProjectDisplayTile } from 'components';
import { CompanyProjectsContext } from '../context/Company.context';

function ProjectListItem(props) {
  const { project, handleClick } = props;
  return (
    <React.Fragment>
      <ListItem style={{ cursor: 'pointer' }} onClick={handleClick}>
        <ProjectDisplayTile
          projectTitle={`${project.projectNumber} - ${project.name}`}
          projectCompany={project.companyName}
          projectValue={project.dollarValue}
          project={project}
          showTooltip={true}
        />
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

function CompanyProjects(props) {
  const { companyProjects } = useContext(CompanyProjectsContext);

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6">Open Projects</Typography>
      <Divider />

      <List style={{ padding: 0 }}>
        {companyProjects.length > 0 ? (
          companyProjects
            .filter(project => !project.closed)
            .map(project => {
              return (
                <ProjectListItem
                  key={project.id}
                  project={project}
                  handleClick={() =>
                    props.history.push(`/projects/${project.id}`)
                  }
                />
              );
            })
        ) : (
          <NoItemsResult type="projects" />
        )}
      </List>
    </Paper>
  );
}

export default withRouter(CompanyProjects);
