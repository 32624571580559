import React from 'react';
import http from 'helpers/http.helper';
import { Toast, SearchDropdown } from 'components';
import {
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography
} from 'helpers/themeSafeMui.helper';
import { KeyboardDatePicker } from '@material-ui/pickers';

class EditWeeklyCheckIn extends React.Component {
  state = {
    manager: null,
    ojtHours: this.props.checkIn.ojtHours,
    tasksCompleted: this.props.checkIn.tasksCompleted,
    firstDayOfWeek: this.props.checkIn.firstDayOfWeek,
    users: []
  };

  componentDidMount() {
    this.getUsers();
    this.setManager();
  }

  getUsers = () => {
    http()
      .get('/users')
      .then(res => this.setState({ users: res }));
  };

  setManager = () => {
    http()
      .get(`/users/${this.props.checkIn.managerId}`)
      .then(res => {
        this.setState({ manager: res });
      });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  save = e => {
    e.preventDefault();

    http()
      .put(
        `/users/${this.props.checkIn.userId}/weekly-check-ins/${this.props.checkIn.id}`,
        {
          managerId: this.state.manager.id,
          ojtHours: this.state.ojtHours,
          firstDayOfWeek: this.state.firstDayOfWeek,
          tasksCompleted: this.state.tasksCompleted
        }
      )
      .then(() => {
        Toast.show('Weekly Check-In was updated.');
        this.props.onClose();
        this.props.refresh();
      })
      .catch(err => Toast.show(err.message));
  };

  render() {
    const { onClose } = this.props;
    const {
      users,
      ojtHours,
      manager,
      tasksCompleted,
      firstDayOfWeek
    } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.save}>
        <DialogTitle>Edit Weekly Check-In</DialogTitle>
        <DialogContent style={{ overflow: 'visible' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SearchDropdown
                fullWidth
                margin="normal"
                label="Lead/Manager"
                items={users}
                onChange={manager => this.setState({ manager })}
                value={manager}
                itemToString={item => item.name}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Tasks Completed"
                multiline
                fullWidth
                maxRows="4"
                value={tasksCompleted}
                onChange={this.handleChange}
                name="tasksCompleted"
                margin="normal"
                required
                inputProps={{ minLength: 50 }}
                helperText={<HelperText count={tasksCompleted.length} />}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="ojtHours"
                label="OJT Hours worked this week"
                required
                fullWidth
                margin="dense"
                value={ojtHours}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <KeyboardDatePicker
                format="MM/DD/YYYY"
                autoOk
                name="startDayOfWeek"
                label="First Day of the Week"
                required
                fullWidth
                margin="dense"
                value={firstDayOfWeek}
                onChange={firstDayOfWeek => this.setState({ firstDayOfWeek })}
              />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    );
  }
}

function HelperText(props) {
  const { count } = props;

  return (
    <Typography
      variant="caption"
      style={count < 50 ? { color: 'red' } : { color: 'inherit' }}
    >
      character count: {count}/50
    </Typography>
  );
}

export default EditWeeklyCheckIn;
