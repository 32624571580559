import React, { useContext } from 'react';
import { Typography } from 'helpers/themeSafeMui.helper';
import toCurrency from 'helpers/currency.helper';
import {
  EstimateContext,
  PhaseContext
} from '../../../context/Estimate.context';
import {
  calculateEstimateTotals,
  estimateTotalsToString
} from '../helpers/phaseMaterial.helper';

export default function SummaryItemContainer(props) {
  const { estimate } = useContext(EstimateContext);
  const { phases } = useContext(PhaseContext);

  const data = calculateEstimateTotals(phases, estimate);
  const stringData = estimateTotalsToString(data);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <SummaryItem display="Bid Amount" data={stringData.totalBidAmount} />
      <SummaryItem display="Net Profit" data={stringData.netProfitRatio} />
      <SummaryItem display="Gross Profit" data={stringData.grossProfitRatio} />
      <SummaryItem display="Labor Retail" data={stringData.laborRetail} />
      <SummaryItem
        display="Material Retail"
        data={toCurrency(data.materialRetail)}
        endItem
      />
    </div>
  );
}

function SummaryItem(props) {
  const { display, data, endItem } = props;

  return (
    <div
      style={{
        flexGrow: 1,
        padding: '0px 16px 10px',
        borderRight: !endItem && '1px solid #B2B2B2'
      }}
    >
      <Typography align="center">
        <strong>{display}: </strong>
        {data}
      </Typography>
    </div>
  );
}
