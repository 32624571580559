import React, { useState, useContext } from 'react';
import { Toast, NoItemsResult } from 'components';
import {
  Paper,
  Typography,
  Button,
  List,
  TextField
} from 'helpers/themeSafeMui.helper';
import http from 'helpers/http.helper';
import { connect } from 'react-redux';
import ViewAllNotesModal from '../modals/ViewAllNotes.modal';
import ProjectNote from './ProjectNote.component';
import {
  ProjectNotesContext,
  ProjectContext
} from '../../../context/Project.context';

const ProjectNotesComponent = props => {
  const [note, setNote] = useState('');
  const [viewAllNotesModal, setViewAllNotesModal] = useState(false);
  const { projectNotes: notes, setProjectNotes: setNotes } = useContext(
    ProjectNotesContext
  );
  const { project } = useContext(ProjectContext);

  const addNote = note => {
    http()
      .post(`/projects/${project.id}/notes`, {
        note: note
      })
      .then(res => {
        Toast.show('The note was added.');
        setNotes([res].concat(notes));
        setNote('');
      })
      .catch(err => Toast.show(err.message));
  };

  return (
    <Paper style={{ padding: 15 }}>
      <ViewAllNotesModal
        open={viewAllNotesModal}
        onClose={() => setViewAllNotesModal(false)}
        addNote={addNote}
        notes={notes}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Notes
        </Typography>
        <div>
          <Button
            color="secondary"
            size="small"
            onClick={() => setViewAllNotesModal(true)}
          >
            View All
          </Button>
        </div>
      </div>

      {!notes.length && <NoItemsResult type={'Project Notes'} />}

      {notes && (
        <List>
          {notes.slice(0, 3).map(note => (
            <ProjectNote key={note.id} note={note} />
          ))}
        </List>
      )}
      <TextField
        label="Project Note"
        multiline
        maxRows="4"
        fullWidth
        style={{ marginBottom: 10, marginTop: 15 }}
        value={note}
        onChange={e => setNote(e.target.value)}
        variant="outlined"
      />
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={() => addNote(note)}
          color="primary"
          variant="outlined"
        >
          Add Note
        </Button>
      </div>
    </Paper>
  );
};

export default connect(state => ({ user: state.user }))(ProjectNotesComponent);
